import React, { useEffect, useRef, useState } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";

const CashBank = () => {
  const [bank, setBank] = useState({});
  const [cash, setCash] = useState({});
  const [grandCredit, setGrandCredit] = useState(0);
  const [grandDebit, setGrandDebit] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedbankcateogry, setselectedbankcateogry] = useState("");
  const [allbanks, setallbanks] = useState([]);
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedbank, setselectedbank] = useState("");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  let authtoken = localStorage.getItem("userToken");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    getAllBanks();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDateChange = () => {
    const data = {
      bank_account_id: selectedbank,
    };
    setLoading(true);
    fetch(`${baseUrl}/cash_and_bank_balance_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setBank(data.payload.bank);
            setCash(data.payload.cash);
            let cashtCredit = data.payload.cash?.account_data?.reduce(
              (a, c) => a + parseInt(c.credit),
              0
            );
            let cashDebit = data.payload.cash?.account_data?.reduce(
              (a, c) => a + parseInt(c.debit),
              0
            );
            let bankCredit = data.payload.bank?.account_data?.reduce(
              (a, c) => a + parseInt(c.credit),
              0
            );
            let bankDebit = data.payload.bank?.account_data?.reduce(
              (a, c) => a + parseInt(c.debit),
              0
            );
            setGrandCredit(cashtCredit + bankCredit);
            setGrandDebit(cashDebit + bankDebit);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label className="form-control-label">
              <b>
                Select Bank Category{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <select
              className="form-select form-control"
              name="sub_account_id"
              value={selectedbankcateogry}
              onChange={(e) => {
                setselectedbankcateogry(e.target.value);
                let anserr = allbanks.filter(
                  (item) => item.id == e.target.value
                );

                if (anserr.length != 0) {
                  setallsubaccounts([...anserr[0].bank_accounts]);
                } else {
                  setallsubaccounts([]);
                }
              }}
              style={{ backgroundColor: "white" }}
              aria-label="Default select example"
            >
              <option acode="" selected value="">
                Select...
              </option>
              {allbanks.map((item) => (
                <option value={item.id}>{item.title}</option>
              ))}
            </select>
          </div>

          <div className="col-md-4 ">
            <label className="form-control-label">
              <b>
                Select Bank <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              className="form-select form-control"
              name="sub_account_id"
              value={selectedbank}
              onChange={(e) => {
                setselectedbank(e.target.value);
              }}
              style={{ backgroundColor: "white" }}
              aria-label="Default select example"
            >
              <option acode="" selected value="">
                Select...
              </option>
              {allsubaccounts.map((item) => (
                <option value={item.id}>
                  {item.bank_name}&nbsp;&nbsp;{`(${item.account_head})`}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 mt-7">
            <button className="btn btn-primary" onClick={handleDateChange}>
              Show
            </button>
            {bank.account_chart_code || bank.account_chart_code ? (
              <ReactToPrint
                documentTitle={`Cash and Bank Balances Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue mx-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        <hr />
        <div className="row mt-9">
          <div className="col-md-1 mr-8">
            <img
              style={{ height: 100, width: 110, paddingLeft: "20px" }}
              src={icon}
              alt="logo"
            />
          </div>
          <div className="col-md-8">
            <h1>{unitName}</h1>
            <h3>Cash and Bank Balances Report</h3>
          </div>
        </div>
        <hr />
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <>
              <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>A/C Code</th>
                      <th>Account Name</th>
                      <th className="text-center">Debit</th>
                      <th className="text-center">Credit</th>
                    </tr>
                  </thead>

                  <tbody>
                    {/* ///for band//// */}
                    {bank.account_chart_code ? (
                      <>
                        <>
                          <tr id="tbl">
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              Total Assets
                            </td>
                            <td
                              style={{ color: "black", textAlign: "start" }}
                            ></td>
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {grandDebit}
                            </td>
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {grandCredit}
                            </td>
                          </tr>
                          <>
                            <tr id="tbl">
                              <td
                                style={{
                                  color: "darkgreen",
                                  textAlign: "start",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                14
                              </td>
                              <td
                                style={{
                                  color: "darkgreen",
                                  textAlign: "start",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                  position: "relative",
                                  right: "30px",
                                }}
                              >
                                Cash & Bank Balances
                              </td>
                              <td
                                style={{
                                  color: "darkgreen",
                                  textAlign: "center",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {grandDebit}
                              </td>
                              <td
                                style={{
                                  color: "darkgreen",
                                  textAlign: "center",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {grandCredit}
                              </td>
                            </tr>
                            <tr id="tbl">
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "start",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  position: "relative",
                                  left: "20px",
                                }}
                              >
                                {bank.account_chart_code}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "start",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {bank.account_chart}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              ></td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              ></td>
                            </tr>
                            {bank?.account_data?.length > 0
                              ? bank.account_data.map((subs) =>
                                  subs.debit != 0 || subs.credit != 0 ? (
                                    <tr id="tbl">
                                      <td
                                        style={{
                                          color: "black",
                                          textAlign: "start",
                                          position: "relative",
                                          left: "50px",
                                        }}
                                      >
                                        {subs.account_code}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          textAlign: "start",
                                          position: "relative",
                                          left: "50px",
                                        }}
                                      >
                                        {subs.account_name}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          textAlign: "center",
                                        }}
                                      >
                                        {subs.debit}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          textAlign: "center",
                                        }}
                                      >
                                        {subs.credit}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                                )
                              : ""}
                          </>
                        </>
                        <tr id="tbl">
                          <td
                            style={{ color: "black", paddingLeft: "30px" }}
                            className="text-start"
                          ></td>
                          <td
                            style={{
                              color: "black",
                              paddingLeft: "200px",
                              fontWeight: "bold",
                            }}
                            className="text-start"
                          >
                            Control Accounts Total :-
                          </td>

                          <td
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              borderTop: "2px solid black",
                              borderBottom: "2px solid black",
                            }}
                            className="text-center"
                          >
                            {bank?.account_data?.reduce(
                              (a, c) => a + parseInt(c.debit),
                              0
                            )}
                          </td>
                          <td
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              borderTop: "2px solid black",
                              borderBottom: "2px solid black",
                            }}
                            className="text-center"
                          >
                            {bank?.account_data?.reduce(
                              (a, c) => a + parseInt(c.credit),
                              0
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}

                    {/* ///for Cash */}
                    {cash.account_chart_code ? (
                      <>
                        <>
                          <>
                            <tr id="tbl">
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "start",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  position: "relative",
                                  left: "20px",
                                }}
                              >
                                {cash.account_chart_code}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "start",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {cash.account_chart}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              ></td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              ></td>
                            </tr>
                            {cash?.account_data?.length > 0
                              ? cash.account_data.map((subs) =>
                                  subs.debit != 0 || subs.credit != 0 ? (
                                    <tr id="tbl">
                                      <td
                                        style={{
                                          color: "black",
                                          textAlign: "start",
                                          position: "relative",
                                          left: "50px",
                                        }}
                                      >
                                        {subs.account_code}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          textAlign: "start",
                                          position: "relative",
                                          left: "50px",
                                        }}
                                      >
                                        {subs.account_name}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          textAlign: "center",
                                        }}
                                      >
                                        {subs.debit}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          textAlign: "center",
                                        }}
                                      >
                                        {subs.credit}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                                )
                              : ""}
                          </>
                        </>
                        <tr id="tbl">
                          <td
                            style={{ color: "black", paddingLeft: "30px" }}
                            className="text-start"
                          ></td>
                          <td
                            style={{
                              color: "black",
                              paddingLeft: "200px",
                              fontWeight: "bold",
                            }}
                            className="text-start"
                          >
                            Control Accounts Total :-
                          </td>

                          <td
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              borderTop: "2px solid black",
                              borderBottom: "2px solid black",
                            }}
                            className="text-center"
                          >
                            {cash?.account_data?.reduce(
                              (a, c) => a + parseInt(c.debit),
                              0
                            )}
                          </td>
                          <td
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              borderTop: "2px solid black",
                              borderBottom: "2px solid black",
                            }}
                            className="text-center"
                          >
                            {cash?.account_data?.reduce(
                              (a, c) => a + parseInt(c.credit),
                              0
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mt-9 d-flex">
            <div>
              <img
                style={{ height: 100, width: 110, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="ml-4">
              <h1>{unitName}</h1>
              <h3>Cash and Bank Balances Report</h3>
            </div>
          </div>
          <hr />
          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>A/C Code</th>
                  <th>Account Name</th>
                  <th className="text-center">Debit</th>
                  <th className="text-center">Credit</th>
                </tr>
              </thead>

              <tbody>
                {/* ///for band//// */}
                {bank.account_chart_code ? (
                  <>
                    <>
                      <tr id="tbl">
                        <td
                          className="text-primary"
                          style={{
                            paddingLeft: "20px",
                            fontSize: "1.3rem",
                            fontWeight: "bold",
                          }}
                        >
                          Total Assets
                        </td>
                        <td style={{ color: "black", textAlign: "start" }}></td>
                        <td
                          className="text-primary"
                          style={{
                            paddingLeft: "20px",
                            fontSize: "1.3rem",
                            fontWeight: "bold",
                          }}
                        >
                          {grandDebit}
                        </td>
                        <td
                          className="text-primary"
                          style={{
                            paddingLeft: "20px",
                            fontSize: "1.3rem",
                            fontWeight: "bold",
                          }}
                        >
                          {grandCredit}
                        </td>
                      </tr>
                      <>
                        <tr id="tbl">
                          <td
                            style={{
                              color: "darkgreen",
                              textAlign: "start",
                              fontSize: "1.1rem",
                              fontWeight: "bold",
                            }}
                          >
                            14
                          </td>
                          <td
                            style={{
                              color: "darkgreen",
                              textAlign: "start",
                              fontSize: "1.1rem",
                              fontWeight: "bold",
                              position: "relative",
                              right: "30px",
                            }}
                          >
                            Cash & Bank Balances
                          </td>
                          <td
                            style={{
                              color: "darkgreen",
                              textAlign: "center",
                              fontSize: "1.1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {grandDebit}
                          </td>
                          <td
                            style={{
                              color: "darkgreen",
                              textAlign: "center",
                              fontSize: "1.1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {grandCredit}
                          </td>
                        </tr>
                        <tr id="tbl">
                          <td
                            style={{
                              color: "black",
                              textAlign: "start",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              position: "relative",
                              left: "20px",
                            }}
                          >
                            {bank.account_chart_code}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "start",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {bank.account_chart}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          ></td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          ></td>
                        </tr>
                        {bank?.account_data?.length > 0
                          ? bank.account_data.map((subs) =>
                              subs.debit != 0 || subs.credit != 0 ? (
                                <tr id="tbl">
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "start",
                                      position: "relative",
                                      left: "50px",
                                    }}
                                  >
                                    {subs.account_code}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "start",
                                      position: "relative",
                                      left: "50px",
                                    }}
                                  >
                                    {subs.account_name}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                    }}
                                  >
                                    {subs.debit}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                    }}
                                  >
                                    {subs.credit}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            )
                          : ""}
                      </>
                    </>
                    <tr id="tbl">
                      <td
                        style={{ color: "black", paddingLeft: "30px" }}
                        className="text-start"
                      ></td>
                      <td
                        style={{
                          color: "black",
                          paddingLeft: "200px",
                          fontWeight: "bold",
                        }}
                        className="text-start"
                      >
                        Control Accounts Total :-
                      </td>

                      <td
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                        className="text-center"
                      >
                        {bank?.account_data?.reduce(
                          (a, c) => a + parseInt(c.debit),
                          0
                        )}
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                        className="text-center"
                      >
                        {bank?.account_data?.reduce(
                          (a, c) => a + parseInt(c.credit),
                          0
                        )}
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )}

                {/* ///for Cash */}
                {cash.account_chart_code ? (
                  <>
                    <>
                      <>
                        <tr id="tbl">
                          <td
                            style={{
                              color: "black",
                              textAlign: "start",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              position: "relative",
                              left: "20px",
                            }}
                          >
                            {cash.account_chart_code}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "start",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {cash.account_chart}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          ></td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          ></td>
                        </tr>
                        {cash?.account_data?.length > 0
                          ? cash.account_data.map((subs) =>
                              subs.debit != 0 || subs.credit != 0 ? (
                                <tr id="tbl">
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "start",
                                      position: "relative",
                                      left: "50px",
                                    }}
                                  >
                                    {subs.account_code}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "start",
                                      position: "relative",
                                      left: "50px",
                                    }}
                                  >
                                    {subs.account_name}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                    }}
                                  >
                                    {subs.debit}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                    }}
                                  >
                                    {subs.credit}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            )
                          : ""}
                      </>
                    </>
                    <tr id="tbl">
                      <td
                        style={{ color: "black", paddingLeft: "30px" }}
                        className="text-start"
                      ></td>
                      <td
                        style={{
                          color: "black",
                          paddingLeft: "200px",
                          fontWeight: "bold",
                        }}
                        className="text-start"
                      >
                        Control Accounts Total :-
                      </td>

                      <td
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                        className="text-center"
                      >
                        {cash?.account_data?.reduce(
                          (a, c) => a + parseInt(c.debit),
                          0
                        )}
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                        className="text-center"
                      >
                        {cash?.account_data?.reduce(
                          (a, c) => a + parseInt(c.credit),
                          0
                        )}
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default CashBank;
