import React from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import { useEffect } from "react";
import { Spinner, Table } from "react-bootstrap";
import { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";

const pageStyle = `
  @page {
    size: landscape !important;
}
`;

const Eobi = () => {
  const [loading, setLoading] = useState(false);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [campusId, setCampusId] = useState("");
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  let authtoken = localStorage.getItem("userToken");
  const [reportData, setreportData] = useState([]);

  useEffect(() => {
    // getAllCampus();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getEobi = async () => {
    setLoading(true);

    const data = {
      campus_id: campusId,
    };

    await fetch(`${baseUrl}/staff-eobi-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        console.log(data, "======");
        if (data.metadata) {
          if (data.metadata.success) {
            setreportData(data.payload);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label>
              <b> Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  All Campuses
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 mt-9">
            <button className="btn btn-primary" onClick={() => getEobi()}>
              Show
            </button>
            {reportData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`EOBI Report`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              class="text-center mt-5"
              variant="info"
              animation="border"
            />
          </div>
        ) : (
          <Table responsive className="mt-5">
            <thead>
              <tr>
                <th className="">Name</th>
                <th className="">Scale</th>
                <th className="">Designation</th>
                <th className="">Account No</th>
                <th className="">Joining Date</th>
                <th className="">Qualification</th>
                <th className="">Amount</th>
                <th className="">Eobi No</th>
                <th className="">Remarks</th>
              </tr>
            </thead>
            {reportData.length > 0
              ? reportData.map((item) =>
                  item.employees.length > 0 ? (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <p
                            className="text-start text-primary pt-2"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "500",
                              width: "max-content",
                            }}
                          >
                            {item.Campus}
                          </p>
                        </div>
                      </div>
                      <tbody>
                        {item.employees.length > 0
                          ? item.employees.map((subitem) => (
                              <tr>
                                <td>{subitem.full_name}</td>
                                <td>{subitem.pay_scale?.payscale}</td>
                                <td>{subitem.designation?.name}</td>
                                <td>{subitem.account_no}</td>
                                <td>{subitem.joining_date}</td>
                                <td>{subitem.qualification}</td>
                                <td>{subitem.salary_deduction?.eobi}</td>
                                <td>{subitem.eobi_no}</td>
                                <td></td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </>
                  ) : null
                )
              : null}
          </Table>
        )}

        {/* ///for print /////// */}

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <table className="tabler">
            <tr>
              <td className="">Name</td>
              <td className="">Scale</td>
              <td className="">Designation</td>
              <td className="">Account No</td>
              <td className="" style={{ width: "200px" }}>
                Joining Date
              </td>
              <td className="">Qualification</td>
              <td className="">Amount</td>
              <td className="">Eobi No</td>
              <td className="" style={{ width: "300px" }}>
                Remarks
              </td>
            </tr>
            {reportData.length > 0
              ? reportData.map((item) =>
                  item.employees.length > 0 ? (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <p
                            className="text-start text-primary pt-2"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "500",
                              width: "max-content",
                              marginLeft: "10px",
                            }}
                          >
                            {item.Campus}
                          </p>
                        </div>
                      </div>
                      <tbody>
                        {item.employees.length > 0
                          ? item.employees.map((subitem) => (
                              <tr>
                                <td>{subitem.full_name}</td>
                                <td>{subitem.pay_scale?.payscale}</td>
                                <td>{subitem.designation?.name}</td>
                                <td>{subitem.account_no}</td>
                                <td>{subitem.joining_date}</td>
                                <td>{subitem.qualification}</td>
                                <td>{subitem.salary_deduction?.eobi}</td>
                                <td>{subitem.eobi_no}</td>
                                <td style={{ width: "400px" }}></td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </>
                  ) : null
                )
              : null}
          </table>
        </div>
      </Paper>
    </>
  );
};

export default Eobi;
