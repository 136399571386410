import React from "react";
import { Table } from "react-bootstrap";

const StaffLoandPrint = ({ reportData, icon, unitName, sessionYear }) => {
  return (
    <>
      <div className="p-3 mt-4" style={{ display: "flex" }}>
        <div>
          <img style={{ height: 100, width: 110 }} src={icon} />
        </div>
        <div className="col-md-10 pl-5">
          <h1>{unitName}</h1>
          <h5>List of Staff Loan From {sessionYear}</h5>
        </div>
      </div>
      <div id="tableboot" style={{ marginTop: "10px", padding: "5px" }}>
        <Table responsive>
          <thead>
            <tr id="tbl">
              <th>Code</th>
              <th>Name</th>
              <th>Scale</th>
              <th>Designation</th>
              <th>Account No</th>
              <th>Joining Date</th>
              <th>QualificationS</th>
              <th>Loan Installment</th>
              <th>Remaining Loan</th>
            </tr>
          </thead>

          <tbody>
            {!!reportData &&
              reportData.map((staff, index) => (
                <tr id="tbl">
                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff.emp_code}
                  </td>
                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff.full_name}
                  </td>
                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff.pay_scale_id}
                  </td>

                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff?.designation.name}
                  </td>
                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff?.account_no}
                  </td>
                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff?.joining_date}
                  </td>
                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff?.qualification}
                  </td>
                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff?.loans[0].monthly_loan_installment}
                  </td>
                  <td
                    style={{
                      color: "black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                    className="text_align_start"
                  >
                    {staff?.remaining_amount}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default StaffLoandPrint;
