import React, { useState, useEffect, useRef } from "react";
import { baseUrl } from "../../../../services/config";
import { Paper } from "@material-ui/core";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";

const HostelStudentReport = () => {
  const pageStyle = `
  @page {
    size: A4 landscape !important;
}
`;

  let authtoken = localStorage.getItem("userToken");

  const [hostelIDs, setHostelIDs] = useState([]);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const onBeforeGetContentResolve = React.useRef(null);
  const componentRef = useRef(null);

  const [loading, setloading] = useState(false);
  const [reportDetails, setreportDetails] = useState([]);

  const [allsessions, setallsessions] = useState([]);
  const [sessionname, setsessionname] = useState([]);
  const [selectedsession, setselectedsession] = useState("");
  const [selectedHostel, setselectedHostel] = useState("");
  const [hostelName, setHostelName] = useState([]);

  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    getHostelID();
    getAllSessions();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getHostelID = async () => {
    await fetch(`${baseUrl}/hostel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHostelIDs(data.payload);
        console.log(data.payload);
      })
      .catch((err) => {
        console.log("hostel", err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "session data");
        if (data.metadata) {
          if (data.metadata.success) {
            setallsessions(data.payload);

            data.payload.map((session, index) => {
              if (session.active_financial_year == "1") {
                setselectedsession(session.id);
              }
            });
          }
        }
        // setallsessions(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeHostel = async () => {
    if (selectedsession == "") {
      alert.error("Please Select Session");
      return;
    }

    if (selectedHostel == "") {
      alert.error("Please Select Hostel");
      return;
    }

    const sessionTemp = allsessions.find((session) => {
      return session.id == selectedsession;
    });
    setsessionname(sessionTemp.year);

    const hostelData = hostelIDs.find((hostel) => {
      return hostel.id == selectedHostel;
    });
    setHostelName(hostelData.name);

    setloading(true);
    const data = {
      year_id: selectedsession,
      hostel_id: selectedHostel,
    };

    await fetch(`${baseUrl}/hostel_student_report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setloading(false);
        setIsVisible(true);
        setreportDetails(data.payload);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const printingDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  return (
    <Paper className="mb-4 p-3 mt-4">
      <div className="row mt-5">
        <div className="col-md-3 ml-5">
          <b>
            <label>Select Session</label>
          </b>
          <select
            style={{ height: 40 }}
            className="form-select"
            value={selectedsession}
            name="campus_id"
            // value={selectedcampus}
            onChange={(e) => setselectedsession(e.target.value)}
            aria-label="Default select example"
          >
            <option selected value="">
              {" "}
              Select Year
            </option>
            {allsessions.map((campus, index) => (
              <option key={index} value={campus.id}>
                {campus.year}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <b>
            <label>Select Hostel</label>
          </b>
          <select
            style={{ height: 40 }}
            className="form-select"
            name="campus_id"
            onChange={(e) => setselectedHostel(e.target.value)}
            aria-label="Default select example"
          >
            <option selected value="">
              {" "}
              Select Hostel
            </option>
            {hostelIDs.map((hostel, index) => (
              <option key={index} value={hostel.id}>
                {hostel.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 mt-9 d-flex mb-5">
          <button
            onClick={onChangeHostel}
            className="btn btn-primary me-2"
            id="myBtn"
          >
            Show
          </button>
          {reportDetails && reportDetails.hostel_fees ? (
            <ReactToPrint
              documentTitle={`Hostel Student Report`}
              onAfterPrint={handleAfterPrint}
              pageStyle={pageStyle}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint={true}
              trigger={() => (
                <button
                  disabled={printloading ? true : false}
                  className="btn btn-success button_blue button_blue ml-4"
                >
                  {printloading ? <span>Printing..</span> : "Print"}
                </button>
              )}
              content={() => componentRef.current}
            />
          ) : null}
        </div>
      </div>

      {loading ? (
        <div
          className="mt-5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner variant="info" animation="border" />
        </div>
      ) : null}

      {isVisible ? (
        <>
          <div className="row p-3">
            <img
              style={{
                height: 100,
                width: 110,
              }}
              src={icon}
            />

            <div className="col-md-6">
              <h1>{unitName}</h1>
              <h5>
                <b>
                  List of {hostelName} For the Academic Session {sessionname}
                </b>
              </h5>
            </div>
          </div>

          <ColoredLine color={"#FF0000"} />
          <Table responsive>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "start",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    textAlign: "start",
                  }}
                >
                  Adm.ID
                </th>
                <th
                  style={{
                    textAlign: "start",
                  }}
                >
                  Student Name
                </th>
                <th
                  style={{
                    textAlign: "start",
                  }}
                >
                  Father's Name
                </th>
                <th
                  style={{
                    textAlign: "start",
                  }}
                >
                  Class
                </th>
                <th
                  style={{
                    textAlign: "start",
                  }}
                >
                  Section
                </th>
                <th
                  style={{
                    textAlign: "start",
                  }}
                >
                  Mobile No
                </th>
                <th
                  style={{
                    textAlign: "start",
                  }}
                >
                  Hostel Fee
                </th>
              </tr>
            </thead>
            <tbody>
              {reportDetails?.hostel_fees &&
                reportDetails?.hostel_fees.map((report, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {report.student.admission_id}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {report.student.name}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {report.student.father_name}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {report.student.student_class_name}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {report.student.global_section_name}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {report.student.mobile_no}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {report.amount}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : null}

      <div
        ref={componentRef}
        id="printSection"
        style={{
          visibility: "hidden",
          margin: "0",
          padding: "0",
        }}
      >
        <div className="logocontainer" style={{ height: "100px" }}>
          <div className="d-flex mb-5">
            <div className="">
              <img
                // className='col-md-2'
                style={{ height: 90, width: 90 }}
                src={icon}
              />
            </div>
            <div
              style={{
                pasection_compareingTop: "10px",
                marginLeft: "10px",
              }}
            >
              <h1>{unitName}</h1>
              <p style={{ fontSize: "16px" }}>
                List of {hostelName} For the Academic Session {sessionname}
              </p>
              <p> Printing Date:- {printingDate()}</p>
            </div>
          </div>
        </div>
        <Table responsive style={{ whiteSpace: "normal", padding: "1px" }}>
          <thead style={{ whiteSpace: "normal", padding: "1px" }}>
            <tr>
              <th
                style={{
                  textAlign: "start",
                  whiteSpace: "normal",
                  padding: "1px",
                  borderTop: "1px solid black",
                }}
              >
                Sr.
              </th>
              <th
                style={{
                  textAlign: "start",
                  whiteSpace: "normal",
                  padding: "1px",
                  borderTop: "1px solid black",
                }}
              >
                Adm.ID
              </th>
              <th
                style={{
                  textAlign: "start",
                  whiteSpace: "normal",
                  padding: "1px",
                  borderTop: "1px solid black",
                }}
              >
                Student Name
              </th>
              <th
                style={{
                  textAlign: "start",
                  whiteSpace: "normal",
                  padding: "1px",
                  borderTop: "1px solid black",
                }}
              >
                Father's Name
              </th>
              <th
                style={{
                  textAlign: "start",
                  whiteSpace: "normal",
                  padding: "1px",
                  borderTop: "1px solid black",
                }}
              >
                Class
              </th>
              <th
                style={{
                  textAlign: "start",
                  whiteSpace: "normal",
                  padding: "1px",
                  borderTop: "1px solid black",
                }}
              >
                Section
              </th>
              <th
                style={{
                  textAlign: "start",
                  whiteSpace: "normal",
                  padding: "1px",
                  borderTop: "1px solid black",
                }}
              >
                Mobile No
              </th>
              <th
                style={{
                  textAlign: "start",
                  whiteSpace: "normal",
                  padding: "1px",
                  borderTop: "1px solid black",
                }}
              >
                Hostel Fee
              </th>
            </tr>
          </thead>
          <tbody style={{ whiteSpace: "normal", padding: "1px" }}>
            {reportDetails?.hostel_fees &&
              reportDetails?.hostel_fees.map((report, index) => (
                <tr style={{ whiteSpace: "normal", padding: "1px" }}>
                  <td
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {report.student.admission_id}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {report.student.name}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {report.student.father_name}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {report.student.student_class_name}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {report.student.global_section_name}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {report.student.mobile_no}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {report.amount}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end ml-15">
          <div>
            <p>
              <b style={{ fontSize: "18px" }} className="text-primary">
                Total Hostel Fee:
              </b>{" "}
              <b style={{ fontSize: "18px" }}>
                {reportDetails.total_fee_amount}
              </b>
            </p>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default HostelStudentReport;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
