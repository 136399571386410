import React, { useState, useEffect, useRef } from "react";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { PremissionChecker } from "../../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../../components/usePagination/usePagination";

const DailyTestSystem = () => {
  const user = useSelector((state) => state.user.user);
  const authtoken = localStorage.getItem("userToken");

  const [allSessions, setAllSessions] = useState([]);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [currentSession, setCurrentSession] = useState("");
  const [allSubjects, setAllSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [educationType, setEducationType] = useState(1);

  const [selectedTestType, setSelectedTestType] = useState("");

  const [allClasses, setAllClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  const [allSections, setAllSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [testDetails, setTestDetails] = useState([]);

  const [editTestModal, setEditTestModal] = useState(false);
  const [editTestID, setEditTestID] = useState([]);
  const [editTestType, setEditTestType] = useState("");
  const [editTotalMarks, setEditTotalMarks] = useState("");
  const [editDate, setEditDate] = useState("");

  const [deleteTestModal, setDeleteTestModal] = useState(false);
  const [deleteableTest, setDeleteableTest] = useState("");

  const [loading, setloading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [editTestLoading, setEditTestLoading] = useState(false);
  const alert = useAlert();

  const [testTypeData, setTestTypeData] = useState([]);
  const [testTypeModal, setTestTypeModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [testTypeName, setTestTypeName] = useState("");

  const [editTestTypeModal, setEditTestTypeModal] = useState(false);
  const [editTestTpyeId, setEditTestTypeId] = useState([]);
  const [editTestTypeName, setEditTestTypeName] = useState("");

  const [deleteTestTypeModal, setdeleteTestTypeModal] = useState(false);
  const [deleteableTestType, setDeleteableTestType] = useState([]);
  const [deleteloading, setdeleteloading] = useState(false);

  const [selectedTests, setSelectedTests] = useState([]);
  const [mainCheckBoxChecked, setmaincheckboxChecked] = useState(false);
  const searchClicked = useRef(false);
  const firstTimeLoad = useRef(false);

  const [sendTestMessageModal, setSendTestMessageModal] = useState(false);
  useEffect(() => {
    if (!firstTimeLoad.current) {
      firstTimeLoad.current = true;
      getAllSessions();
      getTestType();
      getAllSubjects();
    }

    if (searchClicked.current) {
      searchClicked.current = false;
      setAllCheckboxes(true);
    } else {
      checkAllCheckBoxes();
    }
  }, [[], selectedTests]);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllSessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setCurrentSession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTestType = async () => {
    await fetch(`${baseUrl}/test-type`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setTestTypeData(data.payload);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const getAllSubjects = async () => {
    await fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllSubjects(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onCampusChange = async (id) => {
    setSelectedCampus(id);
    getCampusClasses(id);
    setAllClasses([]);
    setSelectedClass("");
    setAllSections([]);
    setSelectedSection("");
  };

  const getCampusClasses = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setAllClasses(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setSelectedClass(id);
    setAllSections([]);
    setSelectedSection("");

    fetch(`${baseUrl}/class_section/${selectedCampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllSections([...data.payload]);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedCampus}/${id}${
        selectedClass ? "/" + selectedClass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          setAllSections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Main functions
  const getTestResult = () => {
    setTestDetails([]);
    setloading(true);

    fetch(
      `${baseUrl}/test/${currentSession != "" ? currentSession : null}/${
        selectedCampus != "" ? selectedCampus : null
      }/${selectedClass != "" ? selectedClass : null}/${educationType}/${
        selectedSection != "" ? selectedSection : null
      }/${selectedSubject != "" ? selectedSubject : null}/${
        selectedTestType != "" ? selectedTestType : null
      }/${currentDate != "" ? currentDate : null}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setTestDetails(data.payload);
          setloading(false);
        } else if (!data.metadata.success) {
          alert.error(data.metadata.message);
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendTestModal = () => {
    if (selectedTests == "") {
      return alert.error("Please Select Some Students");
    }
    setSendTestMessageModal(true);
  };

  const sendBulkTestSMS = () => {
    if (selectedTests == "") {
      return alert.error("Please Select Some Tests");
    }

    setSendLoading(true);
    let data = {
      test_ids: selectedTests,
    };

    fetch(`${baseUrl}/multiple-tests-send-sms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          alert.success("Test Message Sent Successfully");
          setSendLoading(false);
          setSendTestMessageModal(false);
        } else if (!data.metadata.success) {
          alert.error(data.metadata.message);
          setSendLoading(false);
          setSendTestMessageModal(false);
        }
      })
      .catch((err) => {
        setSendLoading(false);
        setSendTestMessageModal(false);
        console.log(err);
      });
  };

  // Handle Test Checkbox
  const handleMainCheckboxClick = () => {
    if (mainCheckBoxChecked) setAllCheckboxes(false);
    else setAllCheckboxes(true);
  };

  const setAllCheckboxes = (value) => {
    setmaincheckboxChecked(value);
    if (value) {
      setSelectedTests([]);
    }

    testDetails.map((item, i) => {
      item.checked = value;
      if (!value) {
        RemoveSelectedTest(item);
      } else {
        AddSelectedTest(item);
      }
    });
  };

  const checkAllCheckBoxes = () => {
    if (testDetails.length == selectedTests.length) {
      setmaincheckboxChecked(true);
    } else if (mainCheckBoxChecked) {
      setmaincheckboxChecked(false);
    }
  };

  const handleChallansCheckBoxClick = (e, index) => {
    testDetails.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;

        if (item.checked) {
          AddSelectedTest(item);
        } else {
          RemoveSelectedTest(item);
        }
      }
    });
  };

  const AddSelectedTest = (item) => {
    setSelectedTests((prevSelectedTests) => [...prevSelectedTests, item.id]);
  };

  const RemoveSelectedTest = (item) => {
    setSelectedTests((prevSelectedTests) =>
      prevSelectedTests.filter((id) => id !== item.id)
    );
  };

  // Handle Update and Delete Tests
  const getEditTest = async (id) => {
    await fetch(`${baseUrl}/test/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditTestType(data.payload[0]?.test_type_id);
          setEditTotalMarks(data.payload[0]?.full_marks);
          setEditDate(data.payload[0]?.date);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateTestHandler = async () => {
    if (editTestType == "" || editTotalMarks == 0 || editDate == "") {
      setisallfields(true);
      return;
    }
    setEditTestLoading(true);
    setisallfields(false);
    const data = {
      test_type_id: editTestType,
      full_marks: editTotalMarks,
      date: editDate,
    };

    await fetch(`${baseUrl}/test/${editTestID}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setEditTestLoading(false);
            setEditTestModal(false);
            alert.success("Test successfully Updated");
            getTestResult();
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setEditTestLoading(false);
            setEditTestModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditTestLoading(false);
            setEditTestModal(false);
          }
        })
      )
      .catch((err) => {
        setEditTestLoading(false);
        setEditTestModal(false);
        console.log(err);
      });
  };

  const deleteTestHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/test/${deleteableTest}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            getTestResult();
            setdeleteloading(false);
            setDeleteTestModal(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setdeleteloading(false);
            setDeleteTestModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setdeleteloading(false);
          setDeleteTestModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setDeleteTestModal(false);
      });
  };

  // Test Type Function
  const testTypeHandler = async () => {
    if (testTypeName == "") {
      return alert.error("Test Type Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: testTypeName,
    };
    setSubmitLoading(false);
    await fetch(`${baseUrl}/test-type`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Test Type Successfully Added");
            setTestTypeName("");
            getTestType();
            setTestTypeModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setTestTypeModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setTestTypeModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const getEditTestType = async (id) => {
    await fetch(`${baseUrl}/test-type/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditTestTypeName(data.payload?.name);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateTestTypeHandler = async () => {
    if (editTestTypeName == "") {
      return alert.error("Test Type Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: editTestTypeName,
    };
    setSubmitLoading(false);
    await fetch(`${baseUrl}/test-type/${editTestTpyeId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Test Type Successfully Updated");
            getTestType();
            setEditTestTypeModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setEditTestTypeModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setEditTestTypeModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const deleteTestTypeHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/test-type/${deleteableTestType.id}}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.payload);
            getTestType();
            setdeleteloading(false);
            setdeleteTestTypeModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setdeleteloading(false);
            setdeleteTestTypeModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setdeleteloading(false);
          setdeleteTestTypeModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteTestTypeModal(false);
      });
  };

  // Variables & Function For Pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(testDetails.length / PER_PAGE);
  const _DATA = usePagination(testDetails, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {PremissionChecker(user, "exam.add_test_type") && (
          <div>
            <button
              className="btn btn-primary mr-2"
              onClick={() => setTestTypeModal(true)}
            >
              Add Test Type
            </button>
          </div>
        )}

        <div className="row mt-5">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              onChange={(e) => setCurrentSession(e.target.value)}
              className="form-select h-40px"
              value={currentSession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allSessions.map((session, index) => (
                <option key={index} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              className="form-select h-40px"
              name="campus_id"
              value={selectedCampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Campus</option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              className="form-select h-40px"
              name="class_id"
              value={selectedClass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Class</option>
              {allClasses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              className="form-select h-40px"
              name="campus_id"
              value={educationType}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value={1}>Education</option>
              <option value={2}>Hifz</option>
            </select>
          </div>
        </div>

        <div className="row mt-3 mb-5">
          <div className="col-md-3">
            <label>
              <b>Select Section</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>

            <select
              className="form-select h-40px"
              name="section_id"
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Section</option>
              {allSections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Subject</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Subject</option>
              {allSubjects.map((sub, index) => (
                <option key={index} value={sub.id}>
                  {sub.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Test Type</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              onChange={(e) => {
                setSelectedTestType(e.target.value);
              }}
              className="form-select h-40px"
              value={selectedTestType}
              aria-label="Default select example"
            >
              <option value="">Select Test Type</option>
              {testTypeData.map((test, index) => (
                <option key={index} value={test.id}>
                  {test?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>
                Select Date{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <input
              value={currentDate}
              className="form-control"
              onChange={(e) => setCurrentDate(e.target.value)}
              type="date"
            />
          </div>
        </div>

        <div className="d-flex mt-5">
          <button className="btn btn-primary w-205px" onClick={getTestResult}>
            Get All Test
          </button>

          <button
            className="btn btn-primary w-205px mx-2"
            onClick={sendTestModal}
            disabled={sendLoading ? true : false}
          >
            {sendLoading ? "Sending..." : "Send Test SMS"}
          </button>
        </div>

        <div className="mt-5">
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: "5px" }}>
                  <input
                    onClick={(e) => {
                      handleMainCheckboxClick();
                    }}
                    checked={mainCheckBoxChecked}
                    placeholder="Checkbox"
                    type="checkbox"
                    readOnly
                  />
                </th>
                <th className="text-center">Sr.No</th>
                <th className="text-center">Test Type</th>
                <th className="text-center">Class Name</th>
                <th className="text-center">Section Name</th>
                <th className="text-center">Subject</th>
                <th className="text-center">Total Marks</th>
                <th className="text-center">Date</th>
                <th className="text-center">Edit</th>
                <th className="text-center">Delete</th>
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((testList, index) => (
                <tr key={index}>
                  <td style={{ width: "5px" }}>
                    <input
                      type="checkbox"
                      value={testList.id}
                      key={testList.id}
                      onClick={(e) => {
                        handleChallansCheckBoxClick(e, testList.id);
                      }}
                      checked={testList.checked ?? false}
                      style={{ border: "10px" }}
                      readOnly
                    />
                  </td>
                  <td className="text-center">{testList?.id}</td>
                  <td className="text-center">{testList?.test_type?.name}</td>
                  <td className="text-center">
                    {testList?.student_class?.name}
                  </td>
                  <td className="text-center">
                    {testList?.global_section?.name}
                  </td>
                  <td className="text-center">{testList?.subject?.name}</td>
                  <td className="text-center">{testList?.full_marks}</td>
                  <td className="text-center">{testList?.date}</td>

                  <td className="text-center">
                    <a
                      className={
                        "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      }
                      onClick={() => {
                        getEditTest(testList?.id);
                        setEditTestID(testList?.id);
                        setEditTestModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary ">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                  <td className="text-center">
                    <a
                      className={
                        "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      }
                      onClick={() => {
                        setDeleteableTest(testList?.id);
                        setDeleteTestModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Delete-user.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row justify-content-center">
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>

          <Pagination
            color="primary"
            count={count}
            size="large"
            page={page}
            variant="text"
            shape="round"
            onChange={handleChange}
          />
        </div>

        {/* ------------------------Test Start----------------------- */}

        {/* Update Test */}
        <Modal show={editTestModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Test
            </Modal.Title>
          </Modal.Header>

          {editTestLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Test Updating Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-4">
                  <label>
                    <b>Select Test Type</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      setEditTestType(e.target.value);
                    }}
                    className="form-select h-40px"
                    value={editTestType}
                    aria-label="Default select example"
                  >
                    <option value="">Select Test Type</option>
                    {testTypeData.map((test, index) => (
                      <option key={index} value={test.id}>
                        {test?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4">
                  <label>
                    <b>Enter Total Marks</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={editTotalMarks}
                    onChange={(e) => setEditTotalMarks(e.target.value)}
                    name="total_marks"
                    placeholder="Enter Total Marks"
                  />
                </div>

                <div className="col-md-4">
                  <label>
                    <b>
                      Select Date{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    value={editDate}
                    className="form-control"
                    onChange={(e) => setEditDate(e.target.value)}
                    type="date"
                  />
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={updateTestHandler}
              disabled={editTestLoading ? true : false}
            >
              Update Test
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setEditTestModal(false);
                setisallfields(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Test */}
        <Modal show={deleteTestModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this test?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Fee Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button onClick={deleteTestHandle} className="btn button_blue">
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setDeleteTestModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
        {/* ------------------------Test End----------------------- */}

        {/* ------------------------Test Type Start----------------------- */}
        {/* Add Test Type  */}
        <Modal show={testTypeModal} size="md">
          <Modal.Header>
            <Modal.Title>Add New Test Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Test Type Name <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control"
                value={testTypeName}
                onChange={(e) => setTestTypeName(e.target.value)}
                placeholder="Enter Test Type Name"
              />
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Type Name</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {testTypeData.map((courseData, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{courseData?.id}</td>
                    <td style={{ textAlign: "start" }}>{courseData?.name}</td>
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setEditTestTypeId(courseData?.id);
                          getEditTestType(courseData?.id);
                          setEditTestTypeModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary ">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setDeleteableTestType(courseData);
                          setdeleteTestTypeModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button onClick={testTypeHandler} className="btn btn-primary">
                Add Test Type
              </button>
            )}

            <button
              onClick={() => setTestTypeModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Test Type */}
        <Modal show={editTestTypeModal} size="md">
          <Modal.Header>
            <Modal.Title>Update Test Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Test Type Name <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control"
                value={editTestTypeName}
                onChange={(e) => setEditTestTypeName(e.target.value)}
                placeholder="Enter Test Type Name"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button
                onClick={updateTestTypeHandler}
                className="btn btn-primary"
              >
                Update
              </button>
            )}

            <button
              onClick={() => setEditTestTypeModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Test Type */}
        <Modal show={deleteTestTypeModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this test type?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Fee Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={deleteTestTypeHandle}
                  className="btn button_blue"
                >
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setdeleteTestTypeModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
        {/* ------------------------Test Type End----------------------- */}

        {/* Comfirmation SMS Modal Start*/}
        <Modal show={sendTestMessageModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to send test result message?
            </Modal.Title>
          </Modal.Header>
          {sendLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Message Sending Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={sendBulkTestSMS}
                  className="btn button_blue"
                  disabled={sendLoading ? true : false}
                >
                  {sendLoading ? "Sending..." : "Send"}
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setSendTestMessageModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
        {/* ------------------------Comfirmation SMS Modal End----------------------- */}
      </Paper>
    </div>
  );
};

export default DailyTestSystem;
