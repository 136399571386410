import { Paper } from "@material-ui/core";
import React from "react";
import { Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ViewStudentPrintWise = ({ show, reload, hide, challanList }) => {
  // const challanList = useSelector(state => state.students.studentChallansDetails)
  console.log("this is challan list", challanList);

  // const history = useHistory()

  // useEffect(() => {
  //     if (challanList.length <= 0) {
  //         history.push('/student-wise-print')
  //     }
  // }, [])

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Fee Challan Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className='col-md-6'>

                        <input
                            // onChange={(e) => setsearchtext(e.target.value)}
                            style={{ marginTop: 30, marginLeft: 1 }}
                            className="form-control"
                            placeholder="Search"
                        />
                    </div> */}

        <Table responsive>
          <thead>
            <tr id="tbl">
              <th>Fee Challan Id</th>
              <th>Fee Month</th>
              <th>Fee Name</th>
              <th>Amount</th>
              {/* <th>Delete</th> */}
              {/* <th>SignUp/Reset</th> */}

              {/* <th>Stuck Off</th> */}
            </tr>
          </thead>
          <tbody>
            {challanList &&
              challanList.map((feec) => (
                <tr>
                  <td className="text_align_start ">{feec.fee_challan_id}</td>

                  <td className="text_align_start ">{feec.fee_month}</td>
                  <td className="text_align_start ">{feec.fee_name}</td>
                  <td className="text_align_start ">{feec.amount}</td>
                  {/* 
                                        <td className="text_align_start">
                                            <a
                                                style={{}}
                                                className="btn btn-icon btn-light btn-hover-primary btn-sm "

                                            >
                                                <span className="svg-icon text_align_start svg-icon-md svg-icon-danger">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Devices/Display2.svg"
                                                        )}
                                                    />
                                                </span>
                                            </a>
                                        </td> */}
                </tr>
              ))}
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <button onClick={hide} className="btn btn-danger">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewStudentPrintWise;
