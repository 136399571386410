import React, { useState, useEffect } from "react";
import { useFormik, Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormikController from "../../Staff/Formik/FormikController";
import authToken from "../../../../services/authToken";
import { baseUrl } from "../../../../services/config";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import Select from "react-select";

import { useAlert } from "react-alert";

import {
  Input,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";

const AddCampus = ({ show, onHide }) => {
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [name, setname] = useState("");
  const [code, setcode] = useState("");
  const [area, setarea] = useState("");
  const [city, setcity] = useState("");
  const [province, setprovince] = useState("");
  const [contact, setcontact] = useState("");
  const [state, setstate] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [addloading, setaddloading] = useState(false);
  const [sub_AccountData, setSub_AccountData] = useState({
    label: "Select Account",
    value: "",
  });
  const [loading, setloading] = useState(false);
  const [modalvisible, setmodalvisible] = useState(false);
  let [authtoken, setauthtoken] = useState(localStorage.getItem("userToken"));
  const dispatch = useDispatch();
  const campuses = useSelector((state) => state.campuses.campuses);
  // const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const [isallfields, setisallfields] = useState(false);
  const [allBanks, setAllBanks] = useState([]);
  const [bankIdsArray, setBankIdsArray] = useState([]);
  const [wellFareError, setWellfareError] = useState(false);
  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");

  let [errorcreate, seterrorcreate] = useState("Please Fill All The Fields");
  const history = useHistory();
  const alert = useAlert();
  const [wellfare, setWellfare] = useState([]);

  const initialValues = {
    isSubmited: false,
    name: "",
    first_name: "",
    last_name: "",
    cod: "",
    area: "",
    province: "",
    phone: "",
    city: "",
    email: "",
    password: "",
  };

  useEffect(() => {
    getAllBanks();
    accounts();
  }, []);

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        if (data.metadata) {
          if (data.metadata.success) {
            data.payload.forEach((item) => {
              item["label"] = item.title;
              item["options"] = item.bank_accounts;
              item.bank_accounts.forEach((subitem) => {
                subitem.label = subitem.bank_name;
                subitem.value = subitem.id;
              });
            });
            setAllBanks(data.payload);
          }
        }
        console.log(data.payload, "changed data ");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const accounts = () => {
    fetch(`${baseUrl}/subaccount`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let arr = [];
        console.log(data);
        data.payload.forEach((element) => {
          arr.push({
            label: element.title + `(${element.acode})`,
            value: element.id,
            account_chart_id: element.account_chart_id,
          });
        });
        setWellfare(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const accountFunc = (e) => {
    setWellfareError(false);
    setSub_AccountData(e);
    console.log(e);
  };

  const AddCampusSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),

    cod: Yup.string()
      .required("code is required ")
      .matches(/^[a-zA-Z]*$/, "please Add data in ALphabet only"),

    area: Yup.string().required("required field"),

    province: Yup.string().required("required field"),

    phone: Yup.string()

      .required("required field")
      .matches(
        /^(3)([0-9]{9})$/,
        "Mobile Number  Must start with 3 and must have 13 digits"
      ),

    city: Yup.string().required("required field"),

    email: Yup.string()
      .required("Please Enter your Email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email Format Must Be correct"
      ),

    password: Yup.string()
      .required("password is required field")
      .min(8, "Must be greater then 8 characters"),
  });

  useEffect(() => {
    setauthtoken(localStorage.getItem("userToken"));
    GetAllCampuses();
  }, []);

  const GetAllCampuses = async () => {
    setloading(true);
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((campuses) => {
        dispatch({
          type: "GET_ALL_CAMPUS",
          payload: campuses.payload,
        });
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const AddCampusHandler = async (values) => {
    let idzzz = [];
    bankIdsArray.map((item) => {
      idzzz.push(item.value);
    });
    setaddloading(true);
    const data = {
      first_name: values.name,
      last_name: "Campus",
      name: values.name,
      code: values.cod.toUpperCase(),
      area: values.area,
      city: values.city,
      province: values.province,
      contact: "+92" + values.phone,
      email: values.email,
      password: values.password,
      bank_account_ids: idzzz,
      welfare_account_id: sub_AccountData.value,
      type: type,
    };

    console.log(data);

    fetch(`${baseUrl}/campus`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              setmodalvisible(false);
              alert.success("Campus Added");
              setaddloading(false);
              GetAllCampuses();
              if (onHide == undefined) {
                history.push("/campus");
              } else {
                onHide(false);
              }
            }

            if (!data.metadata.success) {
              if (data.metadata.message) {
                Object.values(data.metadata.message).map((msg) => {
                  alert.error(msg, {
                    type: "error",
                    transition: "fade",
                  });
                });
                setmodalvisible(false);
                setaddloading(false);
                if (onHide == undefined) {
                  history.push("/campus");
                } else {
                  onHide(false);
                }
              }
            }
          } else {
            setmodalvisible(false);
            alert.error("Something went wrong");
            setaddloading(false);
            if (onHide == undefined) {
              history.push("/campus");
            } else {
              onHide(false);
            }
          }
        })
      )
      .catch((err) => {
        setmodalvisible(false);
        console.log(err);
        setaddloading(false);
        alert.error("Something went wrong");
        if (onHide == undefined) {
          history.push("/campus");
        } else {
          onHide(false);
        }
      });
  };

  return (
    <div>
      <Modal
        size="lg"
        show={show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Add Campus
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addloading ? (
            <b style={{ color: "green" }}>
              Campus Adding Please Wait {` `}{" "}
              <span className="spinner-border"></span>
            </b>
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={AddCampusSchema}
              onSubmit={(values) => {
                if (sub_AccountData.value == "") {
                  setWellfareError(true);
                  return;
                }
                if (type == "") {
                  setTypeError(true);
                  return;
                }
                AddCampusHandler(values);
              }}
            >
              {(props) => {
                const {
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  isSubmitting,
                  isValid,
                  handleChange,
                  handleBlur,
                } = props;

                return (
                  <>
                    <Form
                      autoComplete="off"
                      onSubmit={handleSubmit}
                      className="form form-label-right"
                    >
                      <div className="row mt-3">
                        <div className="col-md-6 ">
                          <label>
                            Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            name="name"
                            component={Input}
                            placeholder="Name"
                            maxLength={40}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>
                            Code <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            style={{ textTransform: "uppercase" }}
                            name="cod"
                            component={Input}
                            placeholder="Code"
                            maxLength={3}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6 ">
                          <label>
                            Area <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            name="area"
                            component={Input}
                            placeholder="Area"
                            maxLength={120}
                          />
                        </div>

                        <div className="col-md-6 ">
                          <label>
                            City <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            name="city"
                            component={Input}
                            placeholder="city"
                            maxLength={120}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <label>
                            Province <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            name="province"
                            component={Input}
                            placeholder="Province"
                            maxLength={120}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>
                            Contact <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            name="phone"
                            component={Input}
                            placeholder="Contact"
                            maxLength={10}
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <label>
                            Bank <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            options={allBanks}
                            isMulti
                            onChange={(e) => setBankIdsArray(e)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>
                            Welfare Account{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={sub_AccountData}
                            onChange={(e) => accountFunc(e)}
                            className="disselect"
                            placeholder="Select Account"
                            options={wellfare}
                          />
                          {wellFareError && (
                            <b className="text-danger">
                              Welfare Account is required
                            </b>
                          )}
                        </div>
                        <div className="col-md-6 mt-5">
                          <label>
                            Type <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: 40 }}
                            className="form-select"
                            name="type"
                            value={type}
                            aria-label="Default select example"
                            onChange={(e) => {
                              setTypeError(false);
                              setType(e.target.value);
                            }}
                          >
                            <option value="">Select Type...</option>
                            <option value="campus">Campus</option>
                            <option value="hostel">Hostel</option>
                          </select>
                          {typeError && (
                            <b className="text-danger">Type is required</b>
                          )}
                        </div>

                        <div className="col-md-6 mt-5">
                          <label>
                            Email <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            autocomplete="off"
                            name="email"
                            component={Input}
                            placeholder="E mail"
                            type="text"
                            maxLength={40}
                          />
                        </div>
                        <div className="col-md-6 mt-5">
                          <label>
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            autocomplete="off"
                            name="password"
                            component={Input}
                            placeholder="Password"
                            type="text"
                            maxLength={50}
                          />
                        </div>
                      </div>
                    </Form>
                    {isallfields ? (
                      <>
                        <Alert
                          variant="danger"
                          onClose={() => setisallfields(false)}
                          dismissible
                        >
                          <Alert.Heading>
                            Oh snap! You got an error!
                          </Alert.Heading>
                          <p>{errorcreate}</p>
                        </Alert>
                      </>
                    ) : null}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        className="btn btn-success button_blue button_blue"
                        onClick={() => handleSubmit()}
                        type="submit"
                        value="Submit"
                      >
                        {addloading ? (
                          <span className="spinner-border"></span>
                        ) : (
                          <>Submit</>
                        )}
                      </button>

                      <button
                        onClick={() => {
                          if (onHide == undefined) {
                            history.push("/campus");
                          } else {
                            onHide(false);
                          }
                        }}
                        className="btn btn-danger btn_width ml-3"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                );
              }}
            </Formik>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCampus;
