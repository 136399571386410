import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import EditAppointedStaff from "../AppointedStaff/EditAppointedStaff/EditAppointedStaff";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Pagination from "../../../../components/Pagination/Pagination";
import SVG from "react-inlinesvg";
import DefineSallary from "../AppointedStaff/DefineSallary/DefineSallary";
import { useHistory } from "react-router-dom";
import GenerateSallary from "../AppointedStaff/GenerateSallary/GenerateSallary";
import ViewStaffDetails from "../AppointedStaff/ViewStaffDetails/ViewStaffDetails";
import Paper from "@material-ui/core/Paper";
import { useAlert } from "react-alert";

const EMP_LIST = [
  {
    id: 1,
    title: "Inservice",
  },
  {
    id: 2,
    title: "Retired",
  },
  {
    id: 3,
    title: "Transfered",
  },
  {
    id: 4,
    title: "Struck off",
  },
];

const StaffListStatusWise = () => {
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const history = useHistory();

  const alert = useAlert();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  // console.log(user.user.campus.id)
  const [searchtext, setsearchtext] = useState("");
  const [generateModal, setgenerateModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [currentObject, setcurrentObject] = useState({});
  const [AllStaff, setAllStaff] = useState([]);
  const [viewModal, setviewModal] = useState(false);
  const [defineSalaryModal, setdefineSalaryModal] = useState(false);
  const [viewLoading, setviewLoading] = useState(false);
  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [campusID, setcampusID] = useState("");
  const [selectedServiceType, setselectedServiceType] = useState("");
  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      getAllStaff(campusidget);
    }
  }, []);

  const onCampusSelect = (e) => {
    let id = "";
    if (e == undefined) {
      id = campusID;
    } else {
      id = e;
    }
    setcampusID(id);

    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
  };

  const getAllStaff = async () => {
    setloading(true);
    setAllStaff([]);

    await fetch(`${baseUrl}/emp_appointed/${campusID}/${selectedServiceType}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAllStaff(data.payload);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const openEditModal = (staff) => {
    setcurrentObject(staff);
    seteditModal(true);
  };

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const currentPosts = AllStaff.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  const gotoDetails = (id) => {
    setviewLoading(true);
    fetchDetails(id);
  };

  const fetchDetails = async (id) => {
    await fetch(`${baseUrl}/emp-salary-detail/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then(async (res) => {
        res.json().then((data) => {
          // console.log(data)
          setcurrentObject(data.payload);
          setviewModal(true);
          setviewLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setviewLoading(false);
      });
  };

  const opensalarygenerate = (staff) => {
    setcurrentObject(staff);
    setgenerateModal(true);
  };

  const searchEmploye = () => {
    setAllStaff([]);
    fetch(`${baseUrl}/employees_search_by_code/${searchtext}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          if (data.payload.length === 0) {
            alert.error("No Record Found!!");
            return;
          }
          let arr = [];
          arr.push(data.payload);
          setAllStaff(arr);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg[0]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row mt-5">
          <div className="col-md-4">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                value={allcampuses.length == 0 ? "" : previousselectedcampus}
                onChange={(e) => onCampusSelect(e.target.value)}
                aria-label="Default select example"
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {allcampuses.map((campus, index) => (
                  <option key={index} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <label>
              <b>Select Service Type</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="emp_id"
              value={selectedServiceType}
              onChange={(e) => setselectedServiceType(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Service Type
              </option>
              {EMP_LIST.map((campus, index) => (
                <>
                  <option key={index} value={campus.id}>
                    {campus.title}
                  </option>
                </>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-9">
            <button
              onClick={getAllStaff}
              className="btn btn-primary"
              id="myBtn"
            >
              Show
            </button>
          </div>

          <div className="d-flex form-group  flex-row  col-sm-6 mt-6">
            <div className="form-group w-75 flex-column d-flex">
              <input
                style={{ height: 40 }}
                onChange={(e) => setsearchtext(e.target.value)}
                id="searchInput"
                placeholder="Search By Emp Code ..."
              />
            </div>
            <div className="form-group col-sm-3 flex-column d-flex">
              <button onClick={searchEmploye} className="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </div>

        <Table responsive>
          <thead>
            <tr className="text-center">
              <th style={{ textAlign: "start" }}>Sr. no</th>
              <th style={{ textAlign: "start" }}>Full Name</th>
              <th style={{ textAlign: "start" }}>Email</th>
              <th style={{ textAlign: "start" }}>Cnic No</th>
              <th style={{ textAlign: "start" }}>Emp Code</th>
              <th style={{ textAlign: "start" }}>Mobile No</th>
              <th style={{ textAlign: "start" }}>Joining Date</th>
              <th style={{ textAlign: "center" }}>Edit</th>
              <th style={{ textAlign: "center" }}>View</th>
            </tr>
          </thead>
          <tbody>
            {currentPosts.map((staf, i) => (
              <tr key={staf.id}>
                {/* <td style={{ textAlign: "start" }}>{staf.full_name}</td>
                                    <td style={{ textAlign: "start" }}>{staf.email}</td> */}
                <td>{i + 1}</td>
                <td
                  title={staf.full_name.length > 15 ? staf.full_name : null}
                  style={{ textAlign: "start", cursor: "pointer" }}
                >
                  {staf.full_name.length > 15
                    ? staf.full_name.substring(0, 12) + "..."
                    : staf.full_name}
                </td>
                {staf.email != null ? (
                  <td
                    title={staf.email.length > 15 ? staf.email : null}
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {staf.email.length > 15
                      ? staf.email.substring(0, 12) + "..."
                      : staf.email}
                  </td>
                ) : (
                  <td></td>
                )}
                <td style={{ textAlign: "start" }}>{staf.cnic_no}</td>
                <td style={{ textAlign: "start" }}>{staf.emp_code}</td>

                <td style={{ textAlign: "start" }}>{staf.mobile_no}</td>
                <td style={{ textAlign: "start" }}>{staf.joining_date}</td>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    onClick={() => {
                      openEditModal(staf);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-primary"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      />
                    </span>
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>
                  {staf.pay_scale_id !== null ? (
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      onClick={() => {
                        gotoDetails(staf.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        className="svg-icon svg-icon-md svg-icon-primary"
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Info-circle.svg"
                          )}
                        />
                      </span>
                    </a>
                  ) : (
                    <span
                      title="You need to Define Salary First"
                      className="svg-icon svg-icon-md svg-icon-primary"
                    >
                      Not
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Pagination
          postsPerPage={postPerPage}
          paginate={paginate}
          totalPosts={AllStaff.length}
        />
        <Modal show={editModal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Staff Details
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditAppointedStaff
              staffDetails={currentObject}
              show={() => seteditModal()}
              reload={getAllStaff}
              campusID={campusID}
            />
          </Modal.Body>
        </Modal>

        <Modal show={defineSalaryModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Define And Update Salary
              {currentObject.full_name} - {currentObject.emp_code}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <DefineSallary
              staffDetails={currentObject}
              show={() => setdefineSalaryModal()}
              reload={() => getAllStaff()}
            />
          </Modal.Body>
        </Modal>

        <Modal size="lg" show={generateModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Sallary
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GenerateSallary
              reload={() => getAllStaff()}
              show={() => setgenerateModal()}
              empDetails={currentObject}
            />
          </Modal.Body>
        </Modal>

        {/* View Modal  */}
        <Modal onHide={() => setviewModal(false)} show={viewModal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              View Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewStaffDetails EmpDetails={currentObject} />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setviewModal(false)}
              className="btn btn-danger btn_width"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
};

export default StaffListStatusWise;
