import { Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { useAlert } from "react-alert";
import { Spinner, Table } from 'react-bootstrap';
import { baseUrl } from '../../../../services/config';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';
import AdmitModal from './AdmitModal';

const HostelAdmit = () => {
  const alert = useAlert();

  const [loading,setLoading]=useState(false)
  const [searchtext,setsearchtext]=useState("")
  const [singleStudent,setSingleStudent]=useState({})
  const [studentsData,setstudentsData]=useState([])
  const [admitModal,setAdmitModal]=useState(false)
  let authtoken = localStorage.getItem("userToken");

  const search = (e) => {
    e.preventDefault()
    setLoading(true)
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_name_or_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false)
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("Match not found");
            }
            setstudentsData(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          setLoading(false)

          console.log("err is re", err);
        });
    }
  };

  const onAdmitClick=(item)=>{
    setSingleStudent(item)
    setAdmitModal(true)
  }
  const onHide=()=>{
    setAdmitModal(false)
  }
  return (
    <>
    <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >

<div >
          <form className="row" onSubmit={search}>


      <div className="col-md-4 mt-2" >
        <input
          onChange={(e) => setsearchtext(e.target.value)}
          className="form-control"
          placeholder="Enter Name or Admission ID"
        />
      </div>
      <div className="col-md-2 mt-2">
        <button type="submit" className="btn btn-primary">
          Search
        </button>
      </div>
</form>
          </div>

          {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
        <div id="tableboot" className='mt-5'>
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>Adm-Id</th>
                      <th>Student Name</th>
                      <th>Father Name</th>
                      <th>Mobile No</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Admit</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!!studentsData &&
                      studentsData.map((student, index) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.admission_id}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.name}

                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.father_name}

                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.mobile_no}

                          </td>

                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.student_class?.name}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.global_section?.name}

                          </td>

                       
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                              <a
                        style={{ cursor: 'pointer', marginLeft: ' 0px' }}
                        className='btn btn-icon btn-light btn-hover-primary btn-sm '
                        onClick={() => onAdmitClick(student)}
                      >
                        <span className='svg-icon svg-icon-md svg-icon-primary'>
                          <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Flag.svg')} />
                        </span>
                      </a>
                          </td>
                       
                        
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
               )}
      </Paper>
      <AdmitModal
      show={admitModal}
      onHide={setAdmitModal}
      singleStudent={singleStudent}
      />
    </>
  )
}

export default HostelAdmit