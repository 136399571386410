import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";

import { useParams } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import { upperCase } from "lodash";
const MonthlyChallanPrint = () => {
  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const feechallans = useSelector(
    (state) => state.campuses.monthlypaidchallans
  );
  console.log(feechallans);

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  //print states
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  console.log("ye he to challans hain", feechallans);

  const getFeeAmount = (challans) => {
    let sum = 0;
    challans.forEach((element) => {
      sum += parseInt(element.amount);
    });

    return sum;
    // const sum = challans.reduce((accumulator, object) => {
    //   return accumulator + object.amount;
    // }, 0);
    // console.log(sum)
  };
  const getIssueDate = (arr) => {
    // let result = Math.max(...arr.map(o => o.challan_no))
    // const result = arr.reduce((p, c) => (p.challan_no > c.challan_no ? p : c));

    const result = arr[arr.length - 1];
    // console.log(result);
    return result;
  };

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const getMonthName3 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };
  const getMonthName2 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  return (
    <div>
      <div style={{ position: "fixed", right: "8px", top: "20%" }}>
        <ReactToPrint
          documentTitle={`Challan Copy of ${
            feechallans ? feechallans.length : 0
          }`}
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint={true}
          trigger={() => (
            <button
              disabled={loading ? true : false}
              className="btn btn-success button_blue button_blue"
            >
              {loading ? <span>Printing..</span> : "Print"}
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>

      <div>
        {feechallans.map((item) => (
          <div className="row-printer">
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">
                <h1>Bank Copy</h1>
              </div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>
            {/* 2nd voucher */}
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">
                <h1>Office Copy</h1>
              </div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>
            {/* 3rd voucher */}
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">
                <h1>Student Copy</h1>
              </div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        {feechallans?.map((selectedStudent, i) => (
          <>
            <div className="mainPrintDiv">
              <div className="secondmain">
                <div className="myprintBox1">
                  <div className="text-center">
                    {selectedStudent?.student?.campus?.print_account_no &&
                      selectedStudent.student?.campus?.print_account_no.map(
                        (acc) => (
                          <>
                            <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                            <br />
                          </>
                        )
                      )}

                    <b className="mytextfont">
                      {selectedStudent.student?.campus?.name}
                    </b>
                    <br></br>
                  </div>

                  <div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Due Date:</b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>{getMonthName2(selectedStudent?.due_date)}</p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">
                          Issue Date:
                        </b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>{getMonthName2(selectedStudent?.issue_date)}</p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Admission:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          <b>
                            {selectedStudent.student.admission_id == null
                              ? selectedStudent.student.registration_id
                              : selectedStudent.student.admission_id}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Name:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent.student?.name}
                          <br></br>
                          {selectedStudent.student.gender?.toLowerCase() ==
                          "male"
                            ? "s/o"
                            : "d/o"}{" "}
                          {selectedStudent.student?.father_name}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Class:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent?.student.education_type == 2
                            ? "Hifz-"
                            : ""}
                          {selectedStudent.student?.student_class.name +
                            " (" +
                            selectedStudent?.student?.global_section?.name +
                            ")"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="endContent2">
                    <div className="endconheading2">
                      <div>
                        <b>Inv No.</b>
                      </div>
                      <div>
                        <b>Description.</b>
                      </div>
                      <div>
                        <b>Amount.</b>
                      </div>
                    </div>
                    {selectedStudent.fee_challan_details.map((copy, index) => (
                      <>
                        <div className="endcontentMain2">
                          <div>
                            <p>{copy.fee_challan.challan_no}</p>
                          </div>

                          <div>
                            <p>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </p>
                          </div>
                          <div>
                            <p>{copy.amount}</p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="my__total__bottom4"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <b className="givingFont">Total Amount</b>
                    <p
                      className="btn btn-white givingFont totalFontSizer"
                      style={{ border: "1px solid black", marginLeft: "100px" }}
                    >
                      {getFeeAmount(selectedStudent?.fee_challan_details)}
                    </p>
                  </div>
                </div>

                <div className="myprintBox2">
                  <div className="text-center">
                    {selectedStudent?.student?.campus?.print_account_no &&
                      selectedStudent.student?.campus?.print_account_no.map(
                        (acc) => (
                          <>
                            <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                            <br />
                          </>
                        )
                      )}

                    <b className="mytextfont">
                      {selectedStudent?.student?.campus?.name}
                    </b>
                    <br></br>
                  </div>

                  <div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Due Date:</b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>{getMonthName2(selectedStudent?.due_date)}</p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">
                          Issue Date:
                        </b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>{getMonthName2(selectedStudent?.issue_date)}</p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Admission:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          <b>
                            {selectedStudent.student.admission_id == null
                              ? selectedStudent.student.registration_id
                              : selectedStudent.student.admission_id}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Name:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent.student.name}
                          <br></br>
                          {selectedStudent.student.gender?.toLowerCase() ==
                          "male"
                            ? "s/o"
                            : "d/o"}{" "}
                          {selectedStudent.student?.father_name}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Class:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent?.student.education_type == 2
                            ? "Hifz-"
                            : ""}
                          {selectedStudent.student.student_class.name +
                            " (" +
                            selectedStudent?.student.global_section.name +
                            ")"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="endContent2">
                    <div className="endconheading2">
                      <div>
                        <b>Inv No.</b>
                      </div>
                      <div>
                        <b>Description.</b>
                      </div>
                      <div>
                        <b>Amount.</b>
                      </div>
                    </div>
                    {selectedStudent.fee_challan_details.map((copy, index) => (
                      <>
                        <div className="endcontentMain2">
                          <div>
                            <p>{copy.fee_challan.challan_no}</p>
                          </div>

                          <div>
                            <p>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </p>
                          </div>
                          <div>
                            <p>{copy.amount}</p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="my__total__bottom4"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <b className="givingFont">Total Amount</b>
                    <p
                      className="btn btn-white givingFont totalFontSizer"
                      style={{ border: "1px solid black", marginLeft: "100px" }}
                    >
                      {getFeeAmount(selectedStudent?.fee_challan_details)}
                    </p>
                  </div>
                </div>
                <div className="myprintBox3">
                  <div className="text-center">
                    {selectedStudent.student?.campus?.print_account_no &&
                      selectedStudent?.student.campus?.print_account_no.map(
                        (acc) => (
                          <>
                            <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                            <br />
                          </>
                        )
                      )}

                    <b className="mytextfont">
                      {selectedStudent.student.campus.name}
                    </b>
                    <br></br>
                  </div>

                  <div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Due Date:</b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>{getMonthName2(selectedStudent.due_date)}</p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">
                          Issue Date:
                        </b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>{getMonthName2(selectedStudent?.issue_date)}</p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Admission:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          <b>
                            {selectedStudent.student.admission_id == null
                              ? selectedStudent.student.registration_id
                              : selectedStudent.student.admission_id}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Name:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent.student.name}
                          <br></br>
                          {selectedStudent.student.gender?.toLowerCase() ==
                          "male"
                            ? "s/o"
                            : "d/o"}{" "}
                          {selectedStudent?.student.father_name}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Class:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent?.student.education_type == 2
                            ? "Hifz-"
                            : ""}
                          {selectedStudent.student.student_class.name +
                            " (" +
                            selectedStudent?.student.global_section.name +
                            ")"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="endContent2">
                    <div className="endconheading2">
                      <div>
                        <b>Inv No.</b>
                      </div>
                      <div>
                        <b>Description.</b>
                      </div>
                      <div>
                        <b>Amount.</b>
                      </div>
                    </div>
                    {selectedStudent.fee_challan_details.map((copy, index) => (
                      <>
                        <div className="endcontentMain2">
                          <div>
                            <p>{copy.fee_challan.challan_no}</p>
                          </div>

                          <div>
                            <p>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </p>
                          </div>
                          <div>
                            <p>{copy.amount}</p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="my__total__bottom4"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <b className="givingFont">Total Amount</b>
                    <p
                      className="btn btn-white givingFont totalFontSizer"
                      style={{ border: "1px solid black", marginLeft: "100px" }}
                    >
                      {getFeeAmount(selectedStudent?.fee_challan_details)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default MonthlyChallanPrint;
