import { Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import "./feeDetails.css";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
const FeeDetails = () => {
  const [admission_id, setadmission_id] = useState("");
  const [Amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [show, setShow] = useState(false);

  const [sessionData, setSessionData] = useState([]);
  const [feeDetails, setFeeDetails] = useState([]);
  const [year_id, setYear_id] = useState("");

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  let authtoken = localStorage.getItem("userToken");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    sessions();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    fetch(`${baseUrl}/student_fee_detail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({ admission_id, year_id }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setStudentData(data.payload.student);
            setFeeDetails(data.payload.fee_challan_detail);
            setAmount(data.payload.total_amount);
            setShow(true);
          } else {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setShow(false);
      });
  };
  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYear_id(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}`;
  };

  const yearSplice = (item) => {
    return item.slice(0, 4);
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const currentDate = new Date();
  const displayDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row p-5">
          <div className="col-md-3">
            <b>
              <label>Enter Student Admission No.</label>
            </b>
            <input
              className="form-control"
              onChange={(e) => setadmission_id(e.target.value)}
              placeholder="Admission Id"
            />
          </div>
          <div className="col-md-3">
            <b>
              <label>Select Session</label>
            </b>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="year_id"
              value={year_id}
              aria-label="Default select example"
              onChange={(e) => setYear_id(e.target.value)}
            >
              {sessionData &&
                sessionData.map((item) => (
                  <option value={item.id}>{item.year}</option>
                ))}
            </select>
          </div>
          <div className="col-md-2 d-flex mt-9">
            <button className="btn btn-primary mr-4" onClick={handleSubmit}>
              Search
            </button>
            {feeDetails?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Student Fee Details`}
                pageStyle="auto"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "0px" }}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        <br />

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <>
            {show ? (
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Admission No:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.admission_id}</h6>
                    </div>
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Class:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.student_class?.name}</h6>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Student Name:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.name}</h6>
                    </div>
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Section:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.global_section?.name}</h6>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Father Name:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.father_name}</h6>
                    </div>
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Session:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.session?.year}</h6>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div id="tableboot">
                  <Table responsive>
                    <thead>
                      <tr id="tbl">
                        <th>Bank</th>
                        <th>Month</th>
                        <th>Due Date</th>
                        <th>Receiving Date</th>
                        <th>Fee Type</th>
                        <th>Remarks</th>
                        <th>VNo</th>
                        <th>Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {feeDetails &&
                        feeDetails.map((item) => (
                          <tr id="tbl">
                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.fee_challan?.bank_account?.bank_name}{" "}
                              {`(${item.fee_challan?.bank_account?.account_head})`}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {getMonthName(item.fee_month)},
                              {yearSplice(item.fee_month)}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.fee_challan?.due_date}
                            </td>

                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.fee_challan.received_date}
                            </td>

                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.fee_name}
                            </td>

                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.fee_challan?.remarks}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.fee_challan?.voucher?.voucher_no}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.amount}
                            </td>
                          </tr>
                        ))}

                      <tr id="tbl">
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          Total Amount
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {Amount}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            ) : null}
          </>
        )}
      </Paper>
      {/* //for print */}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <>
          {show ? (
            <>
              <div className="p-3 d-flex">
                <img style={{ height: 120, width: 120 }} src={icon} />

                <div className="col-md-6 mr-5">
                  <h1>{unitName}</h1>
                  <h4>
                    Student Fee Details
                    {/* Of {fullCampus.name} As Per {formattedDate} */}
                  </h4>
                  <h5>Printing Date: {displayDate}</h5>
                </div>
              </div>
              <div className="container" style={{ marginTop: "20px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary">Admission No:-</h5>
                    <h6>&nbsp; &nbsp; {studentData?.admission_id}</h6>
                  </div>
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary">Class:-</h5>
                    <h6>&nbsp; &nbsp; {studentData?.student_class?.name}</h6>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary">Student Name:-</h5>
                    <h6>&nbsp; &nbsp; {studentData?.name}</h6>
                  </div>
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary">Section:-</h5>
                    <h6>&nbsp; &nbsp; {studentData?.global_section?.name}</h6>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary">Father Name:-</h5>
                    <h6>&nbsp; &nbsp; {studentData?.father_name}</h6>
                  </div>
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary">Session:-</h5>
                    <h6>&nbsp; &nbsp; {studentData?.session?.year}</h6>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th style={{ color: "black", fontSize: "16px" }}>Bank</th>
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Month
                      </th>
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Due Date
                      </th>
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Receiving Date
                      </th>
                      <th style={{ color: "black", fontSize: "16px" }}>
                        Fee Type
                      </th>
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Remarks
                      </th>
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        VNo
                      </th>
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {feeDetails &&
                      feeDetails.map((item) => (
                        <tr id="tbl">
                          <td
                            style={{
                              color: "black",
                              fontSize: "14px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                            }}
                            className="text_align_start"
                          >
                            {item.fee_challan?.bank_account?.bank_name}{" "}
                            {`(${item.fee_challan?.bank_account?.account_head})`}
                          </td>
                          <td
                            style={{
                              color: "black",
                              fontSize: "14px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                            }}
                            className="text-center"
                          >
                            {getMonthName(item.fee_month)},
                            {yearSplice(item.fee_month)}
                          </td>
                          <td
                            style={{
                              color: "black",
                              fontSize: "14px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                            }}
                            className="text-center"
                          >
                            {item.fee_challan?.due_date}
                          </td>

                          <td
                            style={{
                              color: "black",
                              fontSize: "14px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                            }}
                            className="text-center"
                          >
                            {item.fee_challan.received_date}
                          </td>

                          <td
                            style={{
                              color: "black",
                              fontSize: "14px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                            }}
                            className="text_align_start"
                          >
                            {item.fee_name}
                          </td>

                          <td
                            style={{
                              color: "black",
                              fontSize: "14px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                            }}
                            className="text-center"
                          >
                            {item.fee_challan?.remarks}
                          </td>

                          <td
                            style={{
                              color: "black",
                              fontSize: "14px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                            }}
                            className="text-center"
                          >
                            {item.fee_challan?.voucher?.voucher_no}
                          </td>
                          <td
                            style={{
                              color: "black",
                              fontSize: "14px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                            }}
                            className="text-center"
                          >
                            {item.amount}
                          </td>
                        </tr>
                      ))}

                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>

                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>

                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>

                      <td
                        colSpan={2}
                        style={{
                          color: "black",
                          fontSize: "18px",
                          borderTop: "2px solid balck",
                          borderBottom: "2px solid balck",
                        }}
                        className="text_align_start"
                      >
                        <b>Total Amount:</b> {Amount}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </>
          ) : null}
        </>
      </div>
    </>
  );
};

export default FeeDetails;
