import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../services/config";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../_metronic/_partials/controls";
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import AddNotification from "./AddNotification";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import EditNotification from "./EditNotification";
import { useSelector } from "react-redux";
const Notifications = () => {
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };
  let authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusData2, setCampusData2] = useState([]);
  const [campusNotifications, setCampusNotifications] = useState([]);
  const [type, setType] = useState([]);
  const [campusId, setCampusId] = useState("");
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [loading, setloading] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    getAllCampus();
    getAllType();
  }, []);

  const getAllCampus = () => {
    // await fetch(`${baseUrl}/campus`, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    // setCampusData(data.payload);

    let arr = [];
    campusData.map((item) => {
      arr.push({ label: item.name, value: item.id });
    });
    setCampusData2(arr);
    // })
    // .catch((err) => {
    //   console.log("vehicle", err);
    // });
  };

  const getAllType = async () => {
    await fetch(`${baseUrl}/notification-types`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setType(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getNotifications = () => {
    setloading(true);
    fetch(`${baseUrl}/notifications/campus/${campusId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.payload.forEach((item, i) => {
          item["sr_no"] = i + 1;
        });
        setCampusNotifications(data.payload);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);

        console.log("vehicle", err);
      });
  };
  const options = {
    custom: true,
    totalSize: campusNotifications.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const updatingQueryParams = (page) => {
    console.log(page);
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };
  const columns = [
    {
      dataField: "sr_no",
      text: "Sr.No",
    },
    {
      dataField: "notification_type.name",
      text: "Notification Type",
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "start_date",
      text: "Start Date",
    },
    {
      dataField: "end_date",
      text: "End Date",
    },
    {
      dataField: "date_time",
      text: "Created At",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.NotiFormatter,
      formatExtraData: {
        openEditCustomerDialog: (item) => {
          setSelectedObject(item);
          setEditModal(true);
        },
        openDeleteCustomerDialog: (id) => {
          setSelectedId(id);
          setDeleteModalOpen(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "200px",
      },
    },
  ];
  return (
    <>
      <Card style={{ marginTop: "10px", padding: 0 }}>
        <CardHeader title="Notifications list">
          <CardHeaderToolbar>
            <button
              onClick={() => {
                setaddModal(true);
              }}
              type="button"
              className="btn btn-primary"
            >
              New Notification
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <div className="container">
          <div className="row mt-2">
            <div className="col-sm-4">
              <label>
                <b>Select Campus</b>
              </label>
              <div className="d-flex flex-column">
                <select
                  style={{ height: 40 }}
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCampusId(e.target.value)}
                >
                  <option selected value="">
                    {" "}
                    Select Campus
                  </option>
                  {campusData &&
                    campusData.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <button
                disabled={loading}
                className="btn btn-primary mt-9"
                onClick={getNotifications}
              >
                Show
              </button>
            </div>
          </div>
        </div>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <>
                  <Pagination
                    updatingQueryParams={updatingQueryParams}
                    onPaginationSelectChange={onPaginationSelectChange}
                    isLoading={loading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      keyField="id"
                      data={campusNotifications}
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                  {/* )} */}
                </>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
      <AddNotification
        reloader={getNotifications}
        type={type}
        campusData={campusData2}
        open={addModal}
        close={() => setaddModal(false)}
      />
      <EditNotification
        selected={selectedObject}
        reloader={getNotifications}
        type={type}
        campusData={campusData2}
        open={editModal}
        close={() => setEditModal(false)}
      />
      <DeleteModal
        visible={deleteModalOpen}
        HideModal={() => setDeleteModalOpen(false)}
        method={"DELETE"}
        url={`${baseUrl}/notifications/${selectedId}`}
        reload={getNotifications}
      />
    </>
  );
};

export default Notifications;
