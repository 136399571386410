import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Divider } from "antd";
import "./comparative.css";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
const ComparativeExpense = () => {
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [allMonths, setAllMonths] = useState([]);
  const [allSubAccounts, setAllSubAccounts] = useState([]);
  const [indexMonth, setIndexMonth] = useState([]);
  const [grandSum, setGrandSum] = useState(0);
  const [currentindex, setcurrentindex] = useState(0);
  const [compared, setCompared] = useState([]);
  const monthTotals = {};
  useEffect(() => {
    sessions();
    // getAllCampus();
  }, []);
  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const showResults = () => {
    setLoading(true);
    const data = {
      year_id: yearId,
      campus_id: campusId,
    };
    fetch(`${baseUrl}/comparative_expense_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setLoading(false);
          setReportData(data.payload);
          dataChange(data.payload);
          dateGetter(data.payload);
          console.log(data, "data Checking");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataChange = (data) => {
    if (data.length > 0) {
      let subAccounts = [];

      data.forEach((accountGroup) => {
        accountGroup.account_chart.forEach((account) => {
          account.month_data.forEach((month) => {
            month.sub_account.forEach((subAccount) => {
              subAccounts.push({
                // account_chart: account.account_chart,
                account_group: accountGroup.account_group,
                // month_year: month.month_year,
                sub_account: subAccount.sub_account,
                // debit: subAccount.debit,
                sub_account_months: getAccountMonth(
                  account.month_data,
                  subAccount.sub_account
                ),
              });
            });
          });
        });
      });

      const uniqueArray = subAccounts.filter((item, index) => {
        return (
          index ===
          subAccounts.findIndex((obj) => {
            return JSON.stringify(obj) === JSON.stringify(item);
          })
        );
      });

      let sum2 = 0;
      uniqueArray.forEach((element) => {
        element.sub_account_months.map((suitem) => {
          sum2 += parseInt(suitem.debit);
        });
      });
      setGrandSum(sum2);
      setAllSubAccounts(uniqueArray);
      console.log(uniqueArray, "changed data");
    }
  };

  const dateGetter = (data) => {
    if (data.length > 0) {
      let months = [];

      data.forEach((accountGroup) => {
        accountGroup.account_chart.forEach((account) => {
          account.month_data.forEach((month) => {
            months.push({
              month_year: month.month_year,
            });
          });
        });
      });

      months.sort((a, b) => {
        // Convert month_year strings to Date objects for comparison
        const dateA = new Date(a.month_year);
        const dateB = new Date(b.month_year);

        // Compare the Date objects
        return dateA - dateB;
      });

      const uniqueArray = months.filter((item, index) => {
        return (
          index ===
          months.findIndex((obj) => {
            return JSON.stringify(obj) === JSON.stringify(item);
          })
        );
      });

      setAllMonths(uniqueArray);
      const indexArray = uniqueArray.map((item) => item.month_year);
      setIndexMonth(indexArray);
      console.log(indexArray, "index");
      console.log(uniqueArray, "this is months");
    }
  };

  const array = [
    {
      account_group: "School Expense",
      sub_account: "Basic Pay",
      sub_account_months: [
        {
          debit: "30458.00",
          month: "March-2023",
          name: "Basic Pay",
        },
        {
          debit: "30458.00",
          month: "April-2023",
          name: "Basic Pay",
        },
      ],
    },
    {
      account_group: "School Expense",
      sub_account: "Basic Pay",
      sub_account_months: [
        {
          debit: "30458.00",
          month: "March-2023",
          name: "Basic Pay",
        },
        {
          debit: "30458.00",
          month: "April-2023",
          name: "Basic Pay",
        },
      ],
    },
    {
      account_group: "School Expense",
      sub_account: "Hostel Allowance",
      sub_account_months: [
        {
          debit: "30458.00",
          month: "March-2023",
          name: "Hostel Allowance",
        },
        {
          debit: "30458.00",
          month: "April-2023",
          name: "Hostel Allowance",
        },
      ],
    },
    {
      account_group: "Utilities",
      sub_account: "Basic Pay",
      sub_account_months: [
        {
          debit: "30458.00",
          month: "March-2023",
          name: "Basic Pay",
        },
        {
          debit: "30458.00",
          month: "April-2023",
          name: "Basic Pay",
        },
      ],
    },
  ];
  const getAccountMonth = (here, compare) => {
    let arr = [];
    let empty = [];
    here.forEach((item) => {
      item.sub_account.forEach((subItem) => {
        if (subItem.sub_account == compare) {
          empty.push({
            month: item.month_year,
            name: subItem.sub_account,
            debit: subItem.debit,
          });
        }
      });
    });
    arr.push({
      name: compare,
    });
    console.log(arr);
    return empty;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label>
              <b>
                Select Session{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option selected value="">
                  Select Session
                </option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <label>
              <b>
                Select Campus{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <button
              disabled={loading}
              className="btn btn-primary mt-9"
              onClick={showResults}
            >
              Show
            </button>
          </div>
        </div>
        <Divider />
        <div id="tableboot">
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th className="text-start"></th>
                {allMonths &&
                  allMonths.map((month) => (
                    <>
                      <th>{month.month_year}</th>
                    </>
                  ))}
                {allMonths && allMonths.length > 0 ? (
                  <th className="text-end">Total</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>

            <tbody>
              {/* <tr id="tbl">
                <td className="text-start"></td>
                {allMonths &&
                  allMonths.map((month) => (
                    <>
                      <td className="text-start" style={{ color: "black" }}>
                        {month.month_year}
                      </td>
                    </>
                  ))}
              </tr> */}
              {allSubAccounts.map((data) => (
                <tr>
                  <td className="text-start" style={{ color: "black" }}>
                    {data.sub_account}
                  </td>
                  {indexMonth.map((header) => {
                    const matchingMonth = data.sub_account_months.find(
                      (monthData) => monthData.month === header
                    );
                    if (matchingMonth) {
                      const debit = parseFloat(matchingMonth.debit);
                      if (monthTotals[header]) {
                        monthTotals[header] += debit;
                      } else {
                        monthTotals[header] = debit;
                      }
                    }
                    return (
                      <td
                        key={header}
                        className="text-start"
                        style={{ color: "black" }}
                      >
                        {matchingMonth ? matchingMonth.debit : "-"}
                      </td>
                    );
                  })}
                  <td className="text-end" style={{ color: "black" }}>
                    {" "}
                    {data.sub_account_months.reduce(
                      (a, c) => a + parseInt(c.debit),
                      0
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                {indexMonth && indexMonth.length > 0 ? (
                  <td
                    className="text-start"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Total
                  </td>
                ) : (
                  ""
                )}
                {indexMonth.map((header) => (
                  <td
                    className="text-start"
                    style={{ color: "black", fontWeight: "bold" }}
                    key={header}
                  >
                    {monthTotals[header] ? monthTotals[header].toFixed(2) : ""}
                  </td>
                ))}
                {grandSum != 0 ? (
                  <td
                    className="text-end"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    {grandSum}
                  </td>
                ) : (
                  ""
                )}
              </tr>
            </tbody>
          </Table>
          {/* <table border="1px solid black">
        <tr>
          <td className="yy"></td>
          {indexMonth.map((heaf) => (
            <>
              <td>{heaf}</td>
            </>
          ))}
        </tr>
        {allSubAccounts.map((data) => (
          <tr>
            <td>{data.sub_account}</td>
            {indexMonth.map(header => {
              const matchingMonth = data.sub_account_months.find(monthData => monthData.month === header);
              return (
                <td key={header}>
                  {matchingMonth ? matchingMonth.debit : ""}
                </td>
              );
            })}
          </tr>
        ))}
      </table> */}
        </div>
      </Paper>
    </>
  );
};

export default ComparativeExpense;
