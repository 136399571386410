import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Tab, Tabs } from "react-bootstrap";
// import { toAbsoluteUrl } from '../../../_metronic/_helpers';

import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import Barcode from "react-barcode";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import Pagination from "../../../../components/Pagination/Pagination";

const icon =
  "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";

function GetAllemploy({ show, designations, getStudentsForFeeGenerate }) {
  console.log(show, "show");
  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [postPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [allcampusstudent, setallcampusstudent] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [campusid, setcampusid] = useState("9");

  const [campuses, setallcampuses] = useState([]);

  useEffect(() => {
    GetAllemploy();
  }, []);

  const alert = useAlert();

  const GetAllemploy = async () => {
    if (show) {
      let data = {
        designation_ids: designations,
        campusid,
      };
      fetch(`${baseUrl}/get_employee_list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setallcampuses(data.payload);
          let checkboxes = data.payload;
          data.payload.map((item, index) => {
            item["checked"] = false;
            checkboxes[index] = item;
          });
          setallcampusstudent([...checkboxes]);
        })
        .catch((err) => {
          console.log("vehicle", err);
        });
    }
  };
  if (show) {
    GetAllemploy();
  }
  const onchangecampus = (e) => {
    const id = e.target.value;
    setcampusid(e.target.value);
    let data = {
      campus_id: e.target.value,
    };

    console.log(data);
    fetch(`${baseUrl}/all_fee_generate_std_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("students", data);
        let checkboxes = data.payload;
        data.payload.map((item, index) => {
          item["checked"] = false;
          checkboxes[index] = item;
        });
        setallcampusstudent([...checkboxes]);
      })
      .catch((err) => {
        console.log(err);
        console.log("students", err);
      });
  };
  const PRINTCOUNT = [1, 2, 3];

  let array = [
    { id: 1, name: "Junaid", class: "5th", section: "Hifz" },
    { id: 2, name: "Ahmad", class: "6th", section: "ali" },
  ];

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = allcampusstudent.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    allcampusstudent.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    getStudentsForFeeGenerate(studentarray, campusid);
    setallcampusstudent([...middlearray]);
  };
  const allcheckboxChecked = () => {
    let middlearray = [];
    let studentarray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      allcampusstudent.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
      getStudentsForFeeGenerate(studentarray, campusid);
    } else {
      setcheckallcheckbox(true);
      allcampusstudent.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
      getStudentsForFeeGenerate(studentarray, campusid);
    }

    setallcampusstudent([...middlearray]);
  };

  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>Student Name</th>
            <th>father Name</th>
            <th>CNIC</th>
            <th>Student Class</th>
            <th>Class Section</th>
            <th>
              <input
                onClick={(e) => {
                  allcheckboxChecked();
                }}
                checked={checkallcheckbox}
                style={{}}
                placeholder="Checkbox"
                type="checkbox"
              />{" "}
              <span style={{ marginleft: "15px" }}>Check All</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((feechallan, index) => (
            <tr key={feechallan.id}>
              <td style={{ textAlign: "start" }}>{feechallan.name}</td>
              <td style={{ textAlign: "start" }}>{feechallan.father_name}</td>
              <td style={{ textAlign: "start" }}>{feechallan.cnic_no}</td>
              <td style={{ textAlign: "start" }}>
                {feechallan.student_class_name}
              </td>
              <td style={{ textAlign: "start" }}>
                {feechallan.global_section_name}
              </td>

              <td style={{ textAlign: "start" }}>
                <input
                  key={feechallan.id}
                  onClick={(e) => {
                    checkBoxClick(e, feechallan.id);
                  }}
                  value={allcheckboxes[index]}
                  checked={feechallan.checked}
                  type="checkbox"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        paginate={paginate}
        postsPerPage={postPerPage}
        totalPosts={allcampusstudent.length}
      />
    </div>
  );
}

export default GetAllemploy;
