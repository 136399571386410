import React, { useState, useEffect, useRef } from "react";
import { Table, Modal, Alert } from "react-bootstrap";
import "./style.css";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import EditStaff from "./EditStaff/EditStaff";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Appointment from "./Appointment/Appointment";
import AddNewStaff from "./addNewStaff";
import { useSelector, useDispatch } from "react-redux";

import Paper from "@material-ui/core/Paper";
import { PremissionChecker } from "../../../../services/PremissionChecker";

export default function StaffRegister() {
  const myRef = React.createRef();
  const alert = useAlert();
  const [searchtext, setsearchtext] = useState("");

  const authtoken = localStorage.getItem("userToken");
  const [appointmentModal, setappointmentModal] = useState(false);
  const [designations, setdesignations] = useState([]);
  const [selectedStaff, setselectedStaff] = useState({});
  //modals and loading
  const [registerModal, setregisterModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [addloading, setaddloading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [editModal, seteditModal] = useState(false);
  //states
  const [deleteId, setdeleteId] = useState("");
  const [applied_for, setapplied_for] = useState("");
  const [full_name, setfull_name] = useState("");
  const [father_name, setfather_name] = useState("");
  const [nationality, setnationality] = useState("");
  const [religion, setreligion] = useState("");
  const [experience, setexperience] = useState("");
  const [cnic_no, setcnic_no] = useState("");
  const [qualification, setqualification] = useState("");
  const [gender, setgender] = useState("");
  const [marital_status, setmarital_status] = useState("");
  const [dob, setdob] = useState("");
  const [remarks, setremarks] = useState("");
  const [mobile_no, setmobile_no] = useState("");
  const [phone, setphone] = useState("");
  const [address, setaddress] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [allStaff, setallStaff] = useState([]);
  const [show, setShow] = useState(false);
  const [showerror, setshowerror] = useState("Please fill All fields");
  const [buttondisable, setbuttondisable] = useState(true);
  const [currentObject, setcurrentObject] = useState({});
  const user = useSelector((state) => state.user.user);
  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [campusID, setcampusID] = useState("");

  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      getAllStaff(campusidget);
    }

    // getAllCampus();
    // getAllStaff()
    getAlldesignations();
    // getAllsession()
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  // console.log(user.user.campus.id)

  const getAllStaff = (e) => {
    let id;
    if (e == undefined) {
      id = campusID;
    } else {
      id = e;
      setcampusID(id);
    }

    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });

    setloading(true);
    fetch(`${baseUrl}/employees_index/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallStaff(data.payload);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const getAlldesignations = () => {
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(banks)
        setdesignations(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getAllsession = () => {
  //     fetch(`${baseUrl}/session`, {
  //         method: 'GET',
  //         headers: {
  //             'Authorization': `Bearer ${authtoken}`
  //         }
  //     })
  //         .then((res) => res.json())
  //         .then((data) => {
  //             // console.log(banks)
  //             setsession(data.payload)

  //         })
  //         .catch((err) => {

  //             console.log(err)
  //         })
  // }

  const checkerrors = () => {
    if (applied_for == "") {
      setShow(true);
      setshowerror("Please add Possition");
      return false;
    }
    if (full_name == "") {
      setShow(true);
      setshowerror("Please add Full Name");
      return false;
    }
    if (father_name == "") {
      setShow(true);
      setshowerror("Please add Father Name");
      return false;
    }
    if (nationality == "") {
      setShow(true);
      setshowerror("Please add Nationality");
      return false;
    }
    if (religion == "") {
      setShow(true);
      setshowerror("Please add religion");
      return false;
    }
    if (experience == "") {
      setShow(true);
      setshowerror("Please add Your Experience");
      return false;
    }
    if (cnic_no == "") {
      setShow(true);
      setshowerror("Please add CNIC");
      return false;
    }
    if (qualification == "") {
      setShow(true);
      setshowerror("Please add Qualification");
      return false;
    }

    if (gender == "") {
      setShow(true);
      setshowerror("Please Select Gender");
      return false;
    }
    if (marital_status == "") {
      setShow(true);
      setshowerror("Please add Your Marital Status");
      return false;
    }
    if (dob == "") {
      setShow(true);
      setshowerror("Please add Your Date Of Birth");
      return false;
    }
    if (remarks == "") {
      setShow(true);
      setshowerror("Please add Your Remarks");
      return false;
    }

    if (mobile_no == "") {
      setShow(true);
      setshowerror("Please add Your Mobile No");
      return false;
    }
    if (phone == "") {
      setShow(true);
      setshowerror("Please add Your Phone No");
      return false;
    }
    if (address == "") {
      setShow(true);
      setshowerror("Please add Your Address");
      return false;
    }
    if (email == "") {
      setShow(true);
      setshowerror("Please add Your Email");
      return false;
    } else {
      var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = String(email).search(filter) != -1;
      console.log(res);
      if (res == false) {
        setShow(true);
        setshowerror("Please Enter valid  Email");
        return false;
      }
    }
    return true;
  };

  const StaffHandler = () => {
    let res = checkerrors();
    if (res == true) {
      setShow(false);
      AddStaff();
    }

    // if (
    //     applied_for == "" && full_name == "" && father_name == "" &&
    //     nationality == "" && religion == "" && experience == "" && cnic_no == "" &&
    //     qualification == "" && gender == "" && marital_status == "" && dob == "" &&
    //     remarks == "" && mobile_no == "" && phone == "" && address == "" && email == ""
    // ) {
    //     setShow(true)

    // } else {

    //    // AddStaff()
    //     setShow(false)

    // }
  };

  const AddStaff = () => {
    setaddloading(true);
    const data = {
      // campus_id: user.campus.id,
      campus_id: campusID,
      designation_id: applied_for,
      full_name,
      father_name,
      nationality,
      religion,
      experience,
      cnic_no,
      qualification,
      gender,
      marital_status,
      dob,
      remarks,
      mobile_no,
      phone,
      address,
      email,
    };

    console.log(data);
    fetch(`${baseUrl}/employees`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.success("New Staff Added");
            setregisterModal(false);
            setaddloading(false);
            getAllStaff();
            clearStates();
          } else if (data.metadata.responseCode == 422) {
            setregisterModal(false);
            setaddloading(false);

            const msgArray = Object.values(data.metadata.message);
            msgArray.map((err) => alert.error(err[0]));
          }
        });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          alert.error("Something went wrong!", err);
          setregisterModal(false);

          setaddloading(false);
        }
      });
  };

  const clearStates = () => {
    setapplied_for("");
    setfull_name("");
    setfather_name("");
    setnationality("");
    setreligion("");
    setexperience("");
    setcnic_no("");
    setqualification("");
    setgender("");
    setmarital_status("");
    setdob("");
    setremarks("");
    setmobile_no("");
    setphone("");
    setaddress("");
    setemail("");
  };

  const editMiddle = (staff) => {
    setselectedStaff(staff);
    seteditModal(true);
  };

  const deleteMiddle = (id) => {
    setdeleteId(id);
    setdeleteModal(true);
  };
  const deleteHandler = () => {
    setdeleteLoading(true);
    fetch(`${baseUrl}/employees/${deleteId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.success("Staff Deleted Successfully");
            setdeleteLoading(false);
            setdeleteModal(false);
            getAllStaff();
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setdeleteLoading(false);
        setdeleteModal(false);
        alert.error("Something went wrong", err);
      });
  };

  const middleAppointment = (staff) => {
    console.log(staff);
    setcurrentObject(staff);
    setappointmentModal(true);
  };

  let dataSearch = allStaff.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.full_name?.toLowerCase().includes(text) ||
          user?.reg_code?.toLowerCase().includes(text)
      : true;
  });

  // const loginAccount=JSON.parse(localStorage.getItem("userDetails")).role[0]

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {PremissionChecker(user, "staff_member.create") ? (
          <button
            onClick={() => setregisterModal(true)}
            className="btn btn-success button_blue"
          >
            Add New Staff
          </button>
        ) : null}

        <div className="d-flex form-group  flex-row  col-sm-12">
          <div
            style={{ padding: "0px" }}
            class="form-group col-sm-6 flex-column d-flex"
          >
            <select
              style={{ marginTop: 30, height: 40 }}
              className="form-select"
              name="campus_id"
              value={allcampuses.length == 0 ? "" : previousselectedcampus}
              onChange={(e) => getAllStaff(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {allcampuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div class="form-group col-sm-6 flex-column d-flex">
            <input
              style={{ marginTop: 30, height: 40 }}
              onChange={(e) => setsearchtext(e.target.value)}
              id="searchInput"
              placeholder="Search ..."
            />
          </div>
        </div>

        {PremissionChecker(user, "staff_member.list") ? (
          <Table responsive>
            <thead>
              <tr className="text-center">
                <td>Applied_for</td>
                <td>Full Name</td>
                <td>Regestration No</td>
                <td>Mobile No</td>
                <td>Qualification</td>
                <td>Address</td>
                <td>Experience</td>
                {PremissionChecker(user, "staff_member.can_appoint") ? (
                  <td>Appointment</td>
                ) : null}
                {PremissionChecker(user, "staff_member.update") ? (
                  <td>Edit</td>
                ) : null}
                {PremissionChecker(user, "staff_member.delete") ? (
                  <td>Delete</td>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {dataSearch.map((staf) => (
                <tr>
                  <td>{staf.designation_id}</td>

                  <td
                    title={staf.full_name.length > 15 ? staf.full_name : null}
                    style={{ cursor: "pointer" }}
                  >
                    {staf.full_name.length > 15
                      ? staf.full_name.substring(0, 12) + "..."
                      : staf.full_name}
                  </td>
                  <td>{staf.reg_code}</td>
                  <td>{"+92" + staf.mobile_no}</td>
                  <td>{staf.qualification}</td>
                  <td>{staf.address}</td>
                  <td>{staf.experience}</td>
                  {PremissionChecker(user, "staff_member.can_appoint") ? (
                    <td>
                      {staf.job_status_name == "inservice" ? (
                        <>Done</>
                      ) : (
                        <span
                          onClick={() => middleAppointment(staf)}
                          style={{ cursor: "pointer" }}
                          className={"svg-icon svg-icon-md svg-icon-primary"}
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Code/Time-schedule.svg"
                            )}
                          />
                        </span>
                      )}
                    </td>
                  ) : null}

                  {PremissionChecker(user, "staff_member.update") ? (
                    <td>
                      <span
                        onClick={() => editMiddle(staf)}
                        style={{ cursor: "pointer" }}
                        className="svg-icon svg-icon-md svg-icon-primary"
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </td>
                  ) : null}

                  {PremissionChecker(user, "staff_member.delete") ? (
                    <td>
                      <span
                        onClick={() => deleteMiddle(staf.id)}
                        style={{ cursor: "pointer" }}
                        className="svg-icon svg-icon-md svg-icon-danger"
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        />
                      </span>
                    </td>
                  ) : null}
                </tr>
                // : null
                /*: null*/
                /*: null*/
                /*: null*/
                /*: null*/
                /*: null*/
                /*: null*/
                /*: null*/
               /*: null*/))}
            </tbody>
          </Table>
        ) : null}

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Modal size="xl" show={appointmentModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Appointment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Appointment
              staffDetail={currentObject}
              show={() => setappointmentModal()}
              reload={() => getAllStaff()}
            />
          </Modal.Body>
        </Modal>
        <AddNewStaff
          getAllStaff={getAllStaff}
          show={registerModal}
          onHide={setregisterModal}
          campusid={campusID}
        />

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Delete Staff
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {deleteLoading ? (
              <b style={{ color: "green" }}>
                Staff Deleting Please Wait {`  `}{" "}
                {/* <span className="spinner-border"></span> */}
              </b>
            ) : (
              <> Are you sure you want to delete this?</>
            )}
          </Modal.Body>
          <Modal.Footer>
            {deleteLoading ? (
              <b style={{ color: "green" }}>
                <span className="spinner-border"></span>
              </b>
            ) : (
              <>
                <button
                  onClick={deleteHandler}
                  className="btn btn-success button_blue"
                >
                  Delete
                </button>
                <button
                  onClick={() => setdeleteModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={editModal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Edit Staff
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditStaff
              StaffDetails={selectedStaff}
              show={() => seteditModal()}
              reload={() => getAllStaff()}
              campusid={campusID}
            />
          </Modal.Body>
        </Modal>
      </Paper>
    </div>
  );
}
