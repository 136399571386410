import { Paper } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "../../../../services/config";
import MySelect from "../../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

const ResultSequence = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [allsessions, setallsessions] = useState([]);
  const [currentsession, setcurrentsession] = useState("");
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState("");
  const [allexamslist, setallexamslist] = useState([]);
  const [allclasses, setallclasses] = useState([]);
  const [selectedExamType, setselectedExamType] = useState("");
  const [allsubjects, setallsubjects] = useState([]);
  const [selectedclass, setselectedclass] = useState("");
  const [optionSelected, setoptionSelected] = useState([]);
  const [selectedsubjects, setselectedsubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [educationType, setEducationType] = useState(1);

  useEffect(() => {
    getAllSessions();
    // getAllCampus();
  }, []);

  ///getting years
  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
            // getAllExamsList(session.id)
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //on year change
  const onChangeSession = (id) => {
    // getAllExamsList(id)
    setcurrentsession(id);
  };

  //getting campus

  const getAllCampus = async () => {
    console.log("campus geeting");
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  ///on campus change
  const onCampusChange = async (id) => {
    setselectedcampus(id);
    getAllExamsList(id);
  };

  ///getting exams
  const getAllExamsList = (id) => {
    const data = {
      year_id: currentsession,
      campus_id: id,
    };

    fetch(`${baseUrl}/exam_for_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("get exam", data);

        setallexamslist([...data.payload]);
        // getassignedExamsList(data.payload)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///on class change
  const onClassChange = (id) => {
    setselectedclass(id);
    getAllSubjects();

    // fetch(`${baseUrl}/class_section/${selectedcampus}/${id}`, {
    //     method: "GET",
    //     headers: {
    //         Authorization: `Bearer ${authtoken}`,
    //     },
    // })
    //     .then((res) => res.json())
    //     .then((data) => {
    //         setallCampusSections([...data.payload]);

    //     })
    //     .catch((err) => {
    //         console.log("section error", err);
    //     });
  };

  //get all subjects
  const getAllSubjects = () => {
    fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsubjects(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///handle submit
  const handleSubmit = () => {
    setLoading(true);
    const fdata = new FormData();
    fdata.append("exam_id", selectedExamType);
    fdata.append("campus_id", selectedcampus);
    fdata.append("student_class_id", selectedclass);
    fdata.append("education_type", educationType);
    for (let i = 0; i < selectedsubjects.length; i++) {
      fdata.append("subject_ids[]", selectedsubjects[i]);
    }

    fetch(`${baseUrl}/result_sequence`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: fdata,
    })
      .then((resp) => {
        setLoading(false);
        resp.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success(data.metadata.message);
            } else if (!data.metadata.success) {
              Object.values(data.metadata.message).map((msg) => {
                alert.error(msg);
              });
            }
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        alert.error("Opps something wents wronge");
      });
  };

  //multiselect
  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const Options = [];
  allsubjects.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const handleChangeSubjects = (selected) => {
    console.log(selected);
    if (optionSelected.length == 30 && selected.length == 30) {
      setoptionSelected([]);
      return;
    }
    setoptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setselectedsubjects([...valuearray]);
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "10px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => onChangeSession(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Exam</b>
            </label>
            <select
              onChange={(e) => {
                setselectedExamType(e.target.value);
                let finded = allexamslist.find(
                  (item) => item.id == e.target.value
                );
                setallclasses(finded.student_classes);
              }}
              class="form-select"
              style={{ height: 40 }}
              // value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Exam</option>
              {allexamslist.map((exam) => (
                <option
                  // selected={exam.active_academic_year == "1" ? true : false}
                  value={exam.id}
                >
                  {exam?.exam_name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              // value={selectedclass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Class
              </option>
              {allclasses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => setEducationType(e.target.value)}
            >
              <option value=""> Select Education Type</option>
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-6">
            <label>
              <b>
                Select subjects <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <MySelect
              options={Options}
              isMulti
              name="selectedOptions"
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChangeSubjects}
              allowSelectAll={true}
              value={optionSelected}
            />
          </div>
          <div className="col-md-2 mt-9">
            <button
              disabled={loading}
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default ResultSequence;
