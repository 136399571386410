import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../_metronic/_partials/controls";
import toast,{Toaster} from 'react-hot-toast'
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
// Validation schema


export function AddStaff({ saveCustomer, customer, actionsLoading, onHide,reload,editItem }) {
  const [loading,setLoading]=useState(false)

    const authtoken = localStorage.getItem("userToken");
const alert=useAlert()
    const CustomerEditSchema = Yup.object().shape({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string().required("Email is required"),
      password: Yup.string().min(6,'Must be greater then 6 digits').required("Password is required"),
    });
    
    const editinitialValues = {
        first_name:editItem?.user?.first_name,
        last_name:editItem?.user?.last_name,
        email: editItem?.user?.email,
        password: ""
   
    }
    const addinitialValues = {
        first_name: "",
        last_name: "",
        email: "",
        password: ""
    }
    const campusId=JSON.parse(localStorage.getItem("userDetails")).campus_id
    
    const handleSubmit =(values)=>{
        setLoading(true)
    console.log('this is values',values)
    const data={
        campus_id:campusId,
        first_name:values.first_name,
        last_name:values.last_name,
        email:values.email,
        password:values.password
    }
    const editUrl=`update/staff-member/${editItem.id}?_method=PUT`
    const addUrl=`staffmember`

    fetch(`${baseUrl}/${editItem?editUrl:addUrl} `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body:JSON.stringify(data) ,
      }).then((resp) => {
        setLoading(false)
        resp.json().then((data) => {
        if(data.metadata){
            if(data.metadata.success){
                reload()
             onHide()

                 alert.success("Created Successfully")
               }else if(!data.metadata.success){
                Object.values(data.metadata.message).map(msg => {
                    alert.error(msg)
               })
               }
        
        }
         
        });
      }).catch(err => {
        setLoading(false)
        console.log(err)
        alert.error('Opps something wents wronge')
        onHide()
      })
    }   


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem !='' ?editinitialValues:addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
            handleSubmit(values);
          }}
      >
        {({values}) => (
          <>
              <Form  className="form form-label-right">
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
                <div className="form-group row">    
                  {/* First Name */}
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label>Enter First Name</label>
                    <Field
                     component={Input}
                      placeholder="Enter First Name"
                      value={values.first_name}
                      name="first_name"
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label>Enter Last Name</label>
                    <Field
                    // style={{ height: "8rem" }}
                    value={values.last_name}
                    name="last_name"
                    component={Input}
                    placeholder="Enter Last Name"
                  />
                  </div>
                </div>
                <div className="form-group row">    
                  {/* First Name */}
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label>Enter Email</label>
                    <Field
                     component={Input}
                      placeholder="Enter Email"
                      value={values.email}
                      name="email"
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label>Enter Password</label>
                    <Field
                    // style={{ height: "8rem" }}
                    value={values.pasword}
                    name="password"
                    component={Input}
                    placeholder="Enter Password"
                  />
                  </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                disabled={loading}
                type="submit"
                // onClick={() =>  handleSubmit(values)}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
            </Form>

          </>
        )}
      </Formik>
    </>
  );
}
