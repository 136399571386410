import React, { useState } from 'react'
import { baseUrl } from '../../../services/config'
import { useSelector } from 'react-redux'
import { useAlert } from 'react-alert'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import { Input, DatePickerField, Select } from "../../../_metronic/_partials/controls";


import { useFormik, Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";



const EditSubAccount = ({ getChartOfAcoounts, show, selectedAccount }) => {
    // const authtoken = useSelector(state => state.user.authtoken)
    const authtoken = localStorage.getItem('userToken')

    const alert = useAlert()

    const [loading, setloading] = useState(false)
    const [ispasswordchange, setispasswordchange] = useState(true)

    const classes = ["form-control"];


    const initialValues = {

        "acode": selectedAccount.acode,
        "title": selectedAccount.title,



    };

    const EditSubAccountSchema = Yup.object().shape({

        acode: Yup.string()
            .required('Acode is required')
            .matches(
                /^[0-9]{8}$/,
                "Must Have 8 Numbers"
            ),

        // acode: Yup.string()
        //     .required('Acode is required'),

        title: Yup.string()
            .required(' Title required field'),





    });









    const editAccount = async (values) => {
        setloading(true);

        const data = {
            acode: values.acode,
            title: values.title

        }


        fetch(`${baseUrl}/subaccount/${selectedAccount.id}?_method=PUT`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authtoken}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json().then((data) => {
                show(false)
                setloading(false);
                if (data.metadata) {
                    if (data.metadata.success) {

                        getChartOfAcoounts()
                    }
                }

            }))
            .catch(err => {
                show(false)
                setloading(false);
                console.log(err)
            })
    }








    return (
        <div>
            {
                loading ? <b>Account Updating Please Wait {` `} <span className="spinner-border"></span></b> :

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={EditSubAccountSchema}

                        onSubmit={(values) => {
                            editAccount(values)
                        }}

                    >
                        {({ handleSubmit, handleChange, setFieldValue, isSubmitting, isValid, errors, touched, values }) => (

                            <>
                                <Form autoComplete="off">

                                    <div className="row mt-3">
                                        <div className="col-md-6 ">
                                            <label>Title <span style={{ color: "red" }}>*</span></label>
                                            <Field
                                                name="title"
                                                value={values.title}
                                                // defaultValue={values.title}
                                                component={Input}
                                                // value={values.title}
                                                placeholder="Title"

                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label>acode <span style={{ color: "red" }}>*</span></label>
                                            <Field
                                                value={values.acode}
                                                name="acode"
                                                // value={values.acode}

                                                component={Input}
                                                placeholder="acode"
                                                maxLength={8}

                                            />
                                        </div>


                                    </div>



                                </Form>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button className="btn btn-success  button_blue" onClick={() => handleSubmit()} type="submit" value="Submit">
                                        {
                                            loading ? <span className="spinner-border"></span> : <>Submit</>
                                        }

                                    </button>

                                    <button onClick={() => show(false)} className="btn btn-danger btn_width ml-3" >Cancel</button>
                                </div>


                            </>
                        )}
                    </Formik>
            }


        </div >
    )
}

export default EditSubAccount
