import { Paper } from "@material-ui/core";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { DatePicker, Divider } from "antd";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;
const BalanceSheet = () => {
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [reportData, setReportData] = useState([]);
  const [campusId, setCampusId] = useState("");

  const [loading, setLoading] = useState(false);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    sessions();
    // getAllCampus();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const handleGetData = () => {
    setLoading(true);
    const data = {
      year_id: yearId,
      campus_id: campusId,
    };
    fetch(`${baseUrl}/account_balance_sheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.payload) {
          if (data.metadata.success) {
            setReportData(data.payload);
          } else {
            alert.error("Something wents wronge");
          }
        }
        console.log("this is data", data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const getCreditSum = (item) => {
    let sum = 0;
    item.account_group.forEach((element) => {
      element.account_chart.map((suitem) => {
        sum += parseInt(suitem.credit);
      });
    });
    return sum;
  };
  const getDebitSum = (item) => {
    let sum = 0;
    item.account_group.forEach((element) => {
      element.account_chart.map((suitem) => {
        sum += parseInt(suitem.debit);
      });
    });
    return sum;
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="d-flex">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="mt-9 d-flex">
            <div className="ml-4">
              <button className="btn btn-primary" onClick={handleGetData}>
                Show
              </button>
            </div>
            {reportData?.balance_sheet?.length > 0 ? (
              <div className="col-md-3">
                <ReactToPrint
                  documentTitle={`Profit and Loss Report`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      // onClick={() => console.log("dfsdfsdfd")}
                      disabled={printloading ? true : false}
                      className="btn btn-success button_blue button_blue"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="row mt-9">
          <div className="col-md-1 mr-8">
            <img
              style={{ height: 100, width: 110, paddingLeft: "20px" }}
              src={icon}
              alt="logo"
            />
          </div>
          <div className="col-md-8">
            <h1>{unitName}</h1>
            <h3>Balance Sheet For the financial Year {reportData?.session}</h3>
          </div>
        </div>
        <br />
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <>
              <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>A/C Code</th>
                      <th>Account Name</th>
                      <th className="text-center">Debit</th>
                      <th className="text-center">Credit</th>
                    </tr>
                  </thead>

                  <tbody>
                    {reportData &&
                      reportData.balance_sheet?.map((item, index) => (
                        <>
                          <tr id="tbl">
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {item.title}
                            </td>
                            <td
                              style={{ color: "black", textAlign: "start" }}
                            ></td>
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {getDebitSum(item)}
                            </td>
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {getCreditSum(item)}
                            </td>
                          </tr>
                          {item.account_group.length > 0
                            ? item.account_group.map((subitem) => (
                                <>
                                  <tr id="tbl">
                                    <td
                                      style={{
                                        color: "darkgreen",
                                        textAlign: "start",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {subitem.acode}
                                    </td>
                                    <td
                                      style={{
                                        color: "darkgreen",
                                        textAlign: "start",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                        position: "relative",
                                        right: "30px",
                                      }}
                                    >
                                      {subitem.title}
                                    </td>
                                    <td
                                      style={{
                                        color: "darkgreen",
                                        textAlign: "center",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {subitem.account_chart.reduce(
                                        (a, c) => a + parseInt(c.debit),
                                        0
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        color: "darkgreen",
                                        textAlign: "center",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {subitem.account_chart.reduce(
                                        (a, c) => a + parseInt(c.credit),
                                        0
                                      )}
                                    </td>
                                  </tr>
                                  {subitem.account_chart.length > 0
                                    ? subitem.account_chart.map((subs) =>
                                        subs.debit != 0 || subs.credit != 0 ? (
                                          <tr id="tbl">
                                            <td
                                              style={{
                                                color: "black",
                                                textAlign: "start",
                                              }}
                                            >
                                              {subs.acode}
                                            </td>
                                            <td
                                              style={{
                                                color: "black",
                                                textAlign: "start",
                                              }}
                                            >
                                              {subs.title}
                                            </td>
                                            <td
                                              style={{
                                                color: "black",
                                                textAlign: "center",
                                              }}
                                            >
                                              {subs.debit}
                                            </td>
                                            <td
                                              style={{
                                                color: "black",
                                                textAlign: "center",
                                              }}
                                            >
                                              {subs.credit}
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )
                                      )
                                    : ""}
                                </>
                              ))
                            : ""}
                        </>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mt-9 d-flex">
            <div>
              <img
                style={{ height: 100, width: 110, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="ml-4">
              <h1>{unitName}</h1>
              <h3>
                Balance Sheet For the financial Year {reportData?.session}
              </h3>
            </div>
          </div>
          <br />
          <div className="mt-5">
            <>
              <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>A/C Code</th>
                      <th>Account Name</th>
                      <th className="text-center">Debit</th>
                      <th className="text-center">Credit</th>
                    </tr>
                  </thead>

                  <tbody>
                    {reportData &&
                      reportData.balance_sheet?.map((item, index) => (
                        <>
                          <tr id="tbl">
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {item.title}
                            </td>
                            <td
                              style={{ color: "black", textAlign: "start" }}
                            ></td>
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {getDebitSum(item)}
                            </td>
                            <td
                              className="text-primary"
                              style={{
                                paddingLeft: "20px",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {getCreditSum(item)}
                            </td>
                          </tr>
                          {item.account_group.length > 0
                            ? item.account_group.map((subitem) => (
                                <>
                                  <tr id="tbl">
                                    <td
                                      style={{
                                        color: "darkgreen",
                                        textAlign: "start",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {subitem.acode}
                                    </td>
                                    <td
                                      style={{
                                        color: "darkgreen",
                                        textAlign: "start",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                        position: "relative",
                                        right: "30px",
                                      }}
                                    >
                                      {subitem.title}
                                    </td>
                                    <td
                                      style={{
                                        color: "darkgreen",
                                        textAlign: "center",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {subitem.account_chart.reduce(
                                        (a, c) => a + parseInt(c.debit),
                                        0
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        color: "darkgreen",
                                        textAlign: "center",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {subitem.account_chart.reduce(
                                        (a, c) => a + parseInt(c.credit),
                                        0
                                      )}
                                    </td>
                                  </tr>
                                  {subitem.account_chart.length > 0
                                    ? subitem.account_chart.map((subs) =>
                                        subs.debit != 0 || subs.credit != 0 ? (
                                          <tr id="tbl">
                                            <td
                                              style={{
                                                color: "black",
                                                textAlign: "start",
                                              }}
                                            >
                                              {subs.acode}
                                            </td>
                                            <td
                                              style={{
                                                color: "black",
                                                textAlign: "start",
                                              }}
                                            >
                                              {subs.title}
                                            </td>
                                            <td
                                              style={{
                                                color: "black",
                                                textAlign: "center",
                                              }}
                                            >
                                              {subs.debit}
                                            </td>
                                            <td
                                              style={{
                                                color: "black",
                                                textAlign: "center",
                                              }}
                                            >
                                              {subs.credit}
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )
                                      )
                                    : ""}
                                </>
                              ))
                            : ""}
                        </>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default BalanceSheet;
