import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import CustomSelect from "../Student/Form/CustomSelect";

import { useAlert } from "react-alert";


const HostelStudents = () => {
  const [hostelIDs, setHostelIDs] = useState([]);
  let [anyhostel, setanyhostel] = useState("");
  const [loading,setLoading]=useState(false)
  const [studentsData,setstudentsData]=useState([])
  const [searchtext,setsearchtext]=useState("")
  useEffect(() => {
    getHostelID();
  }, []);

  const alert = useAlert();
  let authtoken = localStorage.getItem("userToken");
  const getHostelID = async () => {
    await fetch(`${baseUrl}/hostel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHostelIDs(data.payload);
      })
      .catch((err) => {
        console.log("hostel", err);
      });
  };
  const hosteloptions = [];
  hosteloptions[0] = { value: "", label: "Select ..." };
  hostelIDs.map((item, index) => {
    hosteloptions[index + 1] = { value: item.id, label: item.name };
  });

  const handleHostelChange=(id)=>{
    console.log(id)
    const data={
      hostel_id:id
    }
    fetch(`${baseUrl}/students-by-hostel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body:JSON.stringify(data) ,
    }).then((resp) => {
      setLoading(false)
      resp.json().then((data) => {
      if(data.metadata){
          if(data.metadata.success){
            setstudentsData(data.payload)
            console.log(data.payload)
             }
      }
       
      });
    }).catch(err => {
      setLoading(false)
    })
  }
  const search = (e) => {
    e.preventDefault()
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/hostel/student/search`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("Match not found");
            }
            setstudentsData(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="form-group row">
          <div className="col-lg-4">
            <label>Hostel </label>
            <CustomSelect
              className="input"
              onChange={(evt) => {
                handleHostelChange(evt.value);
              }}
              placeholder="Select Hostel"
              options={hosteloptions}
            />
          </div>
          <div className="col-md-6">
          <form onSubmit={search}>

<div className="row mt-7">
      <div className="col-md-8 mt-2" >
        <input
          onChange={(e) => setsearchtext(e.target.value)}
          className="form-control"
          placeholder="Enter Name or Admission ID"
        />
      </div>
      <div className="col-md-4 mt-2">
        <button type="submit" className="btn btn-primary">
          Search
        </button>
      </div>
    </div>
</form>
          </div>
        </div>
        {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
        <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>Adm-Id</th>
                      <th>Student Name</th>
                      <th>Father Name</th>
                      <th>Mobile No</th>
                      <th>Class</th>
                      <th>Section</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!!studentsData &&
                      studentsData.map((student, index) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.admission_id}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.name}

                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.father_name}

                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.mobile_no}

                          </td>

                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.student_class?.name}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.global_section?.name}

                          </td>

                       
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                          </td>

                          <td style={{ color: "black" }}>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
               )}
      </Paper>
    </>
  );
};

export default HostelStudents;
