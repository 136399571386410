import React, { useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import "./paySalaryPrint.css";
import { baseUrl } from "../../../../services/config";
import { upperCase } from "lodash";
const PaySalaryModal = ({
  show,
  paidStaff,
  bankDetail,
  showCheque,
  grandTotal,
  selecteddate,
}) => {
  // console.log(bankDetail);
  console.log("paid staff", paidStaff);
  const pageStyle = `
  @page {
    size: legal portrait !important;
    margin-bottom: 1in;
  }
  `;

  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const changeDate1 = () => {
    var yourDate = new Date(selecteddate);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var year = yourDate.getFullYear();
    var monthIndex = yourDate.getMonth();
    var day = yourDate.getDate();

    var formattedDate = months[monthIndex] + " " + day + ", " + year;

    return formattedDate;
  };
  const formatdate2 = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };
  function inWords(num) {
    let a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lac "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    console.log(str);
    return str;
  }
  let letDoSum = (arr) => {
    let sum = 0;
    arr.map((item) => {
      sum += parseInt(item.net_pay);
    });
    return sum;
  };
  let numer = 0;
  return (
    <>
      {/* <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      > */}
      <div>
        <Table>
          <thead>
            <tr>
              <th>Bank : {bankDetail.bank_name}</th>
            </tr>
            <tr>
              <th>Branch :{bankDetail.bank_branch}</th>
            </tr>
            <tr>
              <th>Cheque No:{showCheque}</th>
            </tr>
          </thead>
        </Table>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th style={{ textAlign: "center" }}>Code</th>
            <th style={{ textAlign: "center" }}>Name</th>
            <th style={{ textAlign: "center" }}>Father name</th>
            <th style={{ textAlign: "center" }}>Cnic No</th>
            <th style={{ textAlign: "center" }}>Account No</th>
            <th style={{ textAlign: "center" }}>Net Pay</th>
          </tr>
        </thead>
        <tbody>
          {paidStaff.map((staff, index) => {
            return staff.map((item) => (
              <tr key={item.id}>
                <td>{item.employee.id}</td>
                <td>{item.employee.emp_code}</td>
                <td>{item.employee.full_name}</td>
                <td>{item.employee.father_name}</td>
                <td>{item.employee.cnic_no}</td>
                <td>{item.employee.account_no}</td>
                <td>{item.net_pay}</td>
              </tr>
            ));
          })}
        </tbody>
      </Table>
      {/* </Paper> */}
      <div style={{ justifyContent: "flex-end" }} className="row">
        <button
          onClick={(e) => {
            e.preventDefault();
            show(false);
          }}
          className="btn btn-danger btn_width col-md-2 ml-5"
        >
          CLOSE
        </button>

        <ReactToPrint
          documentTitle={`Pay Salary`}
          pageStyle={pageStyle}
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint={true}
          trigger={() => (
            <button
              // onClick={() => console.log("dfsdfsdfd")}
              disabled={printloading ? true : false}
              style={{ marginLeft: "10px" }}
              className="btn btn-success button_blue button_blue "
            >
              {printloading ? <span>Printing..</span> : "Print"}
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div className="row p-3">
          <img
            // className='col-md-2'
            style={{ height: 100, width: 100 }}
            src={icon}
          />

          <div className="col-md-8">
            <h1>{unitName}</h1>
            <p className="paybodyFont">
              <b>PAY SHEET FOR THE MONTH OF {changeDate1()}</b>
            </p>
            <div className="row cheqanddated">
              <div className="col-md-6 text-end">
                <b className="paybodyFont">Cheque No:-</b>&nbsp;&nbsp;&nbsp;
                <span className="paybodyFont">{showCheque}</span>
              </div>
              <div className="col-md-6 text-center">
                <b className="paybodyFont">Dated:-</b> &nbsp;&nbsp;&nbsp;
                <span className="paybodyFont">{formatdate2()}</span>
              </div>
            </div>
          </div>
        </div>

        <table style={{ width: "100%", marginTop: "10px" }}>
          <thead>
            <tr className="payheadFont">
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  color: "black",
                }}
              >
                <b>SR.No.</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  color: "black",
                }}
              >
                <b>CODE</b>
              </td>
              <td
                style={{
                  textAlign: "start",
                  border: "1px solid black",
                  paddingLeft: "5px",
                  color: "black",
                }}
              >
                <b>NAME OF THE EMPLOYEE</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  color: "black",
                }}
              >
                <b>PAID</b>
              </td>
              <td
                style={{
                  textAlign: "start",
                  border: "1px solid black",
                  paddingLeft: "5px",
                  color: "black",
                }}
              >
                <b>ACCOUNT NO.</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {paidStaff.map((staff, index) => {
              return (
                <>
                  {staff.map((item, i) => (
                    <tr key={item.id} className="paybodyFont">
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          color: "black",
                        }}
                      >
                        {(numer += 1)}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          color: "black",
                        }}
                      >
                        {item.employee.emp_code}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          border: "1px solid black",
                          paddingLeft: "5px",
                          color: "black",
                        }}
                      >
                        {item.employee.full_name}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          color: "black",
                        }}
                      >
                        {item.net_pay}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          border: "1px solid black",
                          paddingLeft: "5px",
                          color: "black",
                        }}
                      >
                        {item.employee.account_no}
                      </td>
                    </tr>
                  ))}
                  {paidStaff.length != index + 1 ? (
                    <tr className="paybodyFont">
                      <td style={{ textAlign: "center", color: "black" }}></td>
                      <td style={{ textAlign: "center", color: "black" }}></td>
                      <td style={{ textAlign: "end", color: "black" }}>
                        <b>Sub Total</b>
                      </td>
                      <td style={{ textAlign: "center", color: "black" }}>
                        <b>{letDoSum(staff)}</b>
                      </td>
                      <td style={{ textAlign: "start", color: "black" }}></td>
                    </tr>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </tbody>
        </table>
        <div className="totalOfPaidSalary">
          <div className="d-flex">
            <p>
              <b>In Words:</b>
            </p>
            <p style={{ paddingLeft: "15px" }}>
              {" "}
              {inWords(parseInt(grandTotal))}
            </p>
          </div>
          <div className="d-flex">
            <p>
              <b>Total:</b>
            </p>
            <p style={{ paddingLeft: "15px" }}> {grandTotal}</p>
          </div>
        </div>
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="col-md-4">
            <p>
              <b
                style={{
                  color: "black",
                  fontSize: "16px",
                  paddingLeft: "15px",
                }}
              >
                Accountant
              </b>
            </p>
          </div>
          <div className="col-md-4">
            <p>
              <b style={{ color: "black", fontSize: "16px" }}>Principal</b>
            </p>
          </div>
          <div className="col-md-4">
            <p>
              <b style={{ color: "black", fontSize: "16px" }}>
                Chief Executive{" "}
              </b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaySalaryModal;
