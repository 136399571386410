import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import PromoteStudentModal from "./PromoteStrudentModal";
import usePagination from "../../../../components/usePagination/usePagination";

export default function PromoteStudent() {
  const alert = useAlert();
  // configgration
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);

  //store data
  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);

  //arrays
  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [allcampus_classes, setallcampus_classes] = useState([]);
  const [allcampus_classe_sections, setallcampus_classe_sections] = useState(
    []
  );
  const [allsessions, setallsessions] = useState([]);
  const [allstudents, setallstudents] = useState([]);
  const [studentsForFeePromote, setstudentsForFeePromote] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [educationType, setEducationType] = useState(1);

  //objects

  //strings
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  const [selectedcampus, setselectedcampus] = useState("");
  const [selectedclass, setselectedclass] = useState("");
  const [selectedsection, setselectedsection] = useState("");
  const [currentsession, setcurrentsession] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  const [searchtext, setsearchtext] = useState("");

  //booleans
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [openmodal, setopenmodal] = useState(false);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      onchangecampus(campusidget);
    }
    // getAllCampus();
    getAllSessions();
  }, []);

  //functions
  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("campus", err);
      });
  };
  const onchangecampus = (id) => {
    setallcampus_classes([]);
    setallcampus_classe_sections([]);
    setallstudents([]);
    setselectedcampus(id);
    setselectedclass("");
    setselectedsection("");
    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampus_classes([...data.payload]);
        console.log("class", data);
      })
      .catch((err) => {
        console.log(err);
        console.log("class", err);
      });
  };

  const onchangeclassfunc = (id) => {
    setallcampus_classe_sections([]);
    setselectedsection("");
    setselectedclass(id);
    setallstudents([]);

    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampus_classe_sections([...data.payload]);
        console.log("section", data);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };
  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setallcampus_classe_sections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeclasssection = (id) => {
    setselectedsection(id);

    fetchStudents(selectedcampus, selectedclass, id, currentsession);
  };
  const fetchStudents = (campusid, classid, sectionid, sessionid) => {
    setloader(true);
    setallstudents([]);
    setstudentsForFeePromote([]);
    let data = {
      campus_id: campusid,
      student_class_id: classid,
      global_section_id: sectionid,
      education_type: educationType,
      year_id: sessionid,
    };

    setpreviousselectedcampus(campusid);
    setselectedsection(sectionid);
    setselectedclass(classid);
    setcurrentsession(sessionid);

    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: campusid,
      },
    });

    console.log(data);
    fetch(`${baseUrl}/student_list_to_jump`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("students", data);
        setloader(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setallstudents([...data.payload]);
            let checkboxes = data.payload;
            data.payload.map((item, index) => {
              item["checked"] = false;
              checkboxes[index] = item;
            });
            setallstudents([...checkboxes]);
            setcheckallcheckbox(false);
          } else {
            alert.show("Error Occured");
          }
        } else {
          alert.show("Error Occured");
        }
      })
      .catch((err) => {
        setloader(false);
        alert.show("Error Occured");
        console.log(err);
        console.log("students", err);
      });
  };

  const changesession = (id) => {
    setcurrentsession(id);

    if (selectedsection != "") {
      fetchStudents(selectedcampus, selectedclass, selectedsection, id);
    }
  };

  //paginaytion work
  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;
  // const currentPosts = allstudents.slice(indexOfFirstPost, indexOfLastPost);

  let dataSearch = allstudents.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.name?.toLowerCase().includes(text) ||
          user?.admission_id?.toLowerCase().includes(text)
      : true;
  });
  const currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pagenumber) => setCurrentPage(pagenumber);
  //check box work
  const allcheckboxChecked = () => {
    let middlearray = [];
    let studentarray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      allstudents.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
    } else {
      setcheckallcheckbox(true);
      allstudents.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
    }
    console.log(studentarray);
    console.log(middlearray);
    setstudentsForFeePromote([...studentarray]);
    setallstudents([...middlearray]);
  };
  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    allstudents.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    setstudentsForFeePromote([...studentarray]);
    setallstudents([...middlearray]);
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(dataSearch.length / PER_PAGE);
  const _DATA = usePagination(dataSearch, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {studentsForFeePromote.length == 0 ? (
          <button
            onClick={() => alert.show("Please Select Some Students")}
            className="btn btn-success button_blue"
          >
            Promote
          </button>
        ) : (
          <button
            onClick={() => setopenmodal(true)}
            className="btn btn-success button_blue"
          >
            Promote
          </button>
        )}
        <br />
        <br />

        <div className="form-group col-sm-12 flex-row d-flex">
          <div className="form-group col-sm-6 flex-column d-flex">
            <label>
              <b>
                Select Session <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              onChange={(e) => changesession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option
                  key={index}
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex flex-column col-sm-6  ">
            <label>
              <b>Search</b>
            </label>
            <input
              onChange={(e) => setsearchtext(e.target.value)}
              id="searchInput"
              className="form-control "
              placeholder="Enter Challan Number or Name or Addmission ID"
            />
          </div>
        </div>
        {currentsession != "" ? (
          <div>
            <div className="row">
              <div className="form-group col-sm-3 flex-column d-flex">
                <label>
                  <b>Select Campus </b>
                </label>
                <select
                  onChange={(e) => onchangecampus(e.target.value)}
                  className="form-select"
                  style={{ height: 40 }}
                  value={allcampuses.length == 0 ? "" : previousselectedcampus}
                  aria-label="Default select example"
                >
                  <option selected value="">
                    Select Campus
                  </option>

                  {allcampuses.map((campus, index) => (
                    <option key={campus.id} value={campus.id}>
                      {campus.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-sm-3 flex-column d-flex">
                <label>
                  <b>Select Class </b>
                </label>
                <select
                  onChange={(e) => onchangeclassfunc(e.target.value)}
                  className="form-select"
                  style={{ height: 40 }}
                  value={selectedclass}
                  aria-label="Default select example"
                >
                  <option selected value="">
                    Select Class
                  </option>

                  {allcampus_classes.map((item) => (
                    <option name={item.name} key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label>
                  <b>Select Education Type</b>
                </label>
                <select
                  className="form-select"
                  name="campus_id"
                  value={educationType}
                  style={{ height: 40 }}
                  aria-label="Default select example"
                  onChange={(e) => {
                    setEducationType(e.target.value);
                    getSectionsListOnTypeChange(e.target.value);
                  }}
                >
                  <option value=""> Select Education Type</option>
                  <option selected value={1}>
                    Education
                  </option>
                  <option value={2}>Hifz</option>
                </select>
              </div>
              <div className="form-group col-sm-3 flex-column d-flex">
                <label>
                  <b>Select Section </b>
                </label>
                <select
                  onChange={(e) => onchangeclasssection(e.target.value)}
                  className="form-select"
                  style={{ height: 40 }}
                  value={selectedsection}
                  aria-label="Default select example"
                >
                  <option selected value="">
                    Select Section
                  </option>
                  {allcampus_classe_sections.map((sec, index) => (
                    <option key={index} value={sec.id}>
                      {sec.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {loader ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "15px",
                }}
              >
                {" "}
                <span className="spinner-border"></span>{" "}
              </div>
            ) : (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Father Name</th>
                    <th>Addmission ID</th>
                    <th>Student Class</th>
                    <th>Class Section</th>
                    <th>Monthly Fee</th>
                    <th>
                      <input
                        onClick={(e) => {
                          allcheckboxChecked();
                        }}
                        checked={checkallcheckbox}
                        style={{}}
                        placeholder="Checkbox"
                        type="checkbox"
                      />{" "}
                      <span style={{ marginleft: "15px" }}>Check All</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {_DATA.currentData().map((feechallan, index) => (
                    <tr key={feechallan.id}>
                      {/* <td style={{ textAlign: "start" }}>{feechallan.name}</td> */}

                      <td
                        title={
                          feechallan.name.length > 15 ? feechallan.name : null
                        }
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {feechallan.name.length > 15
                          ? feechallan.name.substring(0, 12) + "..."
                          : feechallan.name}
                      </td>
                      <td
                        title={
                          feechallan.father_name.length > 15
                            ? feechallan.father_name
                            : null
                        }
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {feechallan.father_name.length > 15
                          ? feechallan.father_name.substring(0, 12) + "..."
                          : feechallan.father_name}
                      </td>

                      {/* <td style={{ textAlign: "start" }}>{feechallan.father_name}</td> */}
                      <td style={{ textAlign: "start" }}>
                        {feechallan.admission_id}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {feechallan.student_class_name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {feechallan.global_section_name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {feechallan?.liable_fees[0]?.amount}
                        {/* {feechallan?.liable_fees.map((monthly_fee, index) => (
                          <>{monthly_fee?.amount}</>
                        ))} */}
                      </td>

                      <td style={{ textAlign: "start" }}>
                        <input
                          key={feechallan.id}
                          onClick={(e) => {
                            checkBoxClick(e, feechallan.id);
                          }}
                          checked={feechallan.checked}
                          value={allcheckboxes[index]}
                          type="checkbox"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Pagination
              color="primary"
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        ) : null}
      </Paper>

      <PromoteStudentModal
        show={openmodal}
        onHide={setopenmodal}
        students={studentsForFeePromote}
        reload={fetchStudents}
      />
    </div>
  );
}
