import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const ProjectedMonthlyIncomeExport = ({
  reportData,
  averageIncomeData,
  feeBreakUpData,
  GrandMonthlyFee,
  GrandHostelFee,
  GrandStudentCount,
  GrandAverageFee,
  GrandTotal,
  Count,
  isVisibleProjected,
  isVisibleAverage,
  isVisibleFeeBreakUp,
}) => {
  const tableRef = useRef(null);
  const tableRef1 = useRef(null);
  const tableRef2 = useRef(null);

  const getMonthlyFeePA = (data) => {
    let sumHostelFee = 0;
    data.campus_data.map((subitem) => {
      sumHostelFee += parseInt(subitem.monthly_fee);
    });
    return sumHostelFee;
  };

  const getTotalStudentCountPA = (data) => {
    let sumStudentCount = 0;
    data.campus_data.map((subitem) => {
      sumStudentCount += parseInt(subitem.total_student_count);
    });
    return sumStudentCount;
  };

  const getHostelFeePA = (data) => {
    let sumHostelFee = 0;
    data.campus_data.map((subitem) => {
      sumHostelFee += parseInt(subitem.hostel_fee);
    });
    return sumHostelFee;
  };

  const getStudentCount = (data) => {
    let sumStudentCount = 0;
    data.campus_data.map((subitem) => {
      sumStudentCount += parseInt(subitem.countStudent);
    });
    return sumStudentCount;
  };

  const getSumTotal = (data) => {
    let sumTotal = 0;
    data.campus_data.map((subitem) => {
      sumTotal += parseInt(subitem.countStudent * subitem.amount);
    });
    return sumTotal;
  };

  return (
    <>
      {/* Export for Projected Monthly Income Report */}

      <DownloadTableExcel
        filename="Projected Monthly Income Report"
        sheet="Projected Monthly Income Report"
        currentTableRef={tableRef.current}
      >
        {isVisibleProjected && (
          <button className="btn btn-primary mx-2">Export</button>
        )}
      </DownloadTableExcel>
      <div id="tableboot mt-5">
        <Table ref={tableRef} style={{ display: "none" }}>
          <thead>
            <tr id="tbl">
              <th className="text-center">Campus Name</th>
              <th className="text-center">Class Name</th>
              <th className="text-center">Monthly Fee</th>
              <th className="text-center">Hostel Fee</th>
            </tr>
          </thead>

          <tbody>
            {reportData &&
              reportData.all_campus_data &&
              reportData.all_campus_data.map((item, key) => (
                <>
                  <tr
                    style={{
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "1.2em",
                        borderTop: "1px solid black",
                      }}
                      className="text_align_center text-primary"
                    >
                      {item.campus}
                    </td>
                    <td
                      style={{
                        fontSize: "1.2em",
                        borderTop: "1px solid black",
                      }}
                      className="text_align_center text-primary"
                    >
                      ----
                    </td>
                    <td
                      style={{ fontSize: "1.2rem" }}
                      className="text-center text-primary"
                    >
                      {getMonthlyFeePA(item)}
                    </td>
                    <td
                      style={{ fontSize: "1.2rem" }}
                      className="text-center text-primary"
                    >
                      {getHostelFeePA(item)}
                    </td>
                  </tr>
                  {item.campus_data.map((subitem, key) => (
                    <tr key={key}>
                      <td></td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.Class.name}
                      </td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.monthly_fee}
                      </td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.hostel_fee}
                      </td>
                    </tr>
                  ))}
                </>
              ))}
            <tr id="tbl">
              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                }}
                className="text-center text-primary"
              >
                Grand Total :-
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                ----
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                {GrandMonthlyFee}
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                {GrandHostelFee}
              </td>
            </tr>

            <tr id="tbl">
              <td
                colSpan={2}
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                }}
                className="text-center text-primary"
              >
                Sum of Monthly Fee & Hostel Fee :-
              </td>

              <td
                colSpan={2}
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                {GrandMonthlyFee + GrandHostelFee}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      {/* Export for Aveage Income Report Fee Break Up */}
      <DownloadTableExcel
        filename="Aveage Income Report Fee Break Up Report"
        sheet="Aveage Income Report Fee Break Up Report"
        currentTableRef={tableRef1.current}
      >
        {isVisibleAverage && (
          <button className="btn btn-primary mx-2">Export</button>
        )}
      </DownloadTableExcel>
      <div id="tableboot mt-5">
        <Table ref={tableRef1} style={{ display: "none" }}>
          <thead>
            <tr id="tbl">
              <th className="text-center">Campus Name</th>
              <th className="text-center">Class Name</th>
              <th className="text-center">Monthly Fee</th>
              <th className="text-center">Hostel Fee</th>
            </tr>
          </thead>

          <tbody>
            {reportData &&
              reportData.all_campus_data &&
              reportData.all_campus_data.map((item, key) => (
                <>
                  <tr
                    style={{
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "1.2em",
                        borderTop: "1px solid black",
                      }}
                      className="text_align_center text-primary"
                    >
                      {item.campus}
                    </td>
                    <td
                      style={{
                        fontSize: "1.2em",
                        borderTop: "1px solid black",
                      }}
                      className="text_align_center text-primary"
                    >
                      ----
                    </td>
                    <td
                      style={{ fontSize: "1.2rem" }}
                      className="text-center text-primary"
                    >
                      {getMonthlyFeePA(item)}
                    </td>
                    <td
                      style={{ fontSize: "1.2rem" }}
                      className="text-center text-primary"
                    >
                      {getHostelFeePA(item)}
                    </td>
                  </tr>
                  {item.campus_data.map((subitem, key) => (
                    <tr key={key}>
                      <td></td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.Class.name}
                      </td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.monthly_fee}
                      </td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.hostel_fee}
                      </td>
                    </tr>
                  ))}
                </>
              ))}
            <tr id="tbl">
              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                }}
                className="text-center text-primary"
              >
                Grand Total :-
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                ----
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                {GrandMonthlyFee}
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                {GrandHostelFee}
              </td>
            </tr>

            <tr id="tbl">
              <td
                colSpan={2}
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                }}
                className="text-center text-primary"
              >
                Sum of Monthly Fee & Hostel Fee :-
              </td>

              <td
                colSpan={2}
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                {GrandMonthlyFee + GrandHostelFee}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      {/* Export for Aveage Income Report Fee Break Up */}
      <DownloadTableExcel
        filename="Expected Monthly Income Report (Monthly Fee Break Up)"
        sheet="Expected Monthly Income Report (Monthly Fee Break Up)"
        currentTableRef={tableRef2.current}
      >
        {isVisibleFeeBreakUp && (
          <button className="btn btn-primary mx-2">Export</button>
        )}
      </DownloadTableExcel>
      <div id="tableboot mt-5">
        <Table ref={tableRef2} style={{ display: "none" }}>
          <thead>
            <tr id="tbl">
              <th className="text-center">Campus Name</th>
              <th className="text-center">Monthly Fee</th>
              <th className="text-center">Student Count</th>
              <th className="text-center">Total Amount</th>
            </tr>
          </thead>

          <tbody>
            {feeBreakUpData &&
              feeBreakUpData.all_campus_data &&
              feeBreakUpData.all_campus_data.map((item, key) => (
                <>
                  <tr
                    style={{
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "1.2em",
                        borderTop: "1px solid black",
                      }}
                      className="text_align_center text-primary"
                    >
                      {item.campus_name}
                    </td>
                    <td
                      style={{ fontSize: "1.2rem" }}
                      className="text-center text-primary"
                    >
                      ----
                    </td>
                    <td
                      style={{ fontSize: "1.2rem" }}
                      className="text-center text-primary"
                    >
                      {getStudentCount(item)}
                    </td>
                    <td
                      style={{ fontSize: "1.2rem" }}
                      className="text-center text-primary"
                    >
                      {getSumTotal(item)}
                    </td>
                  </tr>
                  {item.campus_data.map((subitem, key) => (
                    <tr key={key}>
                      <td></td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.amount}
                      </td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.countStudent}
                      </td>
                      <td className="text-center" style={{ fontSize: "1rem" }}>
                        {subitem.amount * subitem.countStudent}
                      </td>
                    </tr>
                  ))}
                </>
              ))}

            <tr id="tbl">
              <td
                style={{
                  fontSize: "1.3rem",
                  // paddingLeft: "300px",
                  fontWeight: "bold",
                }}
                className="text-center text-primary"
              >
                Grand Total :-
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                ----
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                {GrandStudentCount}
              </td>

              <td
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
                className="text-center text-primary"
              >
                {GrandTotal}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ProjectedMonthlyIncomeExport;
