import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
export default function GenrateFee() {

    const history = useHistory()

    const gotostudent = () => {
        history.push('/genratestudentwisefee')
    }

    const gotoCampus = () => {
        history.push('/genratecampuswisefee')
    }
    const gotoclass = () => {
        history.push('/genrateclasswisefee')
    }


    return (
        <div>

            <div className="row">
                <div onClick={gotostudent} style={{ cursor: 'pointer' }} className="card col-sm-2 ml-4">

                    <h3 style={{ color: '#7E82AE' }}>Student Wise</h3>
                </div>

                <div
                    onClick={gotoCampus}
                    style={{ cursor: 'pointer' }} className="card col-sm-2 ml-4">
                    <h3 style={{ color: '#7E82AE' }}>Campus Wise</h3>

                </div>
                <div
                    onClick={gotoclass}
                    style={{ cursor: 'pointer' }} className="card col-sm-2 ml-4">
                    <h3 style={{ color: '#7E82AE' }}>Class Wise</h3>
                </div>

                <div style={{ cursor: 'pointer' }} className="card col-sm-2 ml-4">
                    <h3 style={{ color: '#7E82AE' }}>Section Wise</h3>
                </div>
            </div>


        </div>
    )
}
