import React, { useEffect, useRef, useState } from "react";
import { Card } from "../../../_metronic/_partials/controls";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const UnitSettings = () => {
  useEffect(() => {
    getSettingData();
  }, []);

  const authtoken = localStorage.getItem("userToken");

  const [fineAmount, setFineAmount] = useState("");
  const [unitName, setUnitName] = useState("");
  const [gpFundYear, setGpFundYear] = useState("");
  const [directorNo, setDirectorNo] = useState("");
  const [alphanumericAdmNo, setAlphanumericAdmNo] = useState(false);
  const [directorSign, setDirectorSign] = useState(false);
  const [sendMsg, setSendMsg] = useState(false);
  const [useReadmissionFee, setUseReadmissionFee] = useState(false);
  const [SMSApiLogin, setSMSApiLogin] = useState("");
  const [SMSApiPassword, setSMSApiPassword] = useState("");
  const [headOfficeUrl, setHeadOfficeUrl] = useState("");
  const [saveBtn, setSaveBtn] = useState(true);

  const [logoUpload, setLogoUpload] = useState(null);
  const [startLogoUpload, setStartLogoUpload] = useState(null);

  const file1Ref = useRef(null);
  const file2Ref = useRef(null);

  const alert = useAlert();

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);

        setFineAmount(data.payload.settings.late_fee_fine);
        setUnitName(data.payload.settings.unit_name);
        setGpFundYear(data.payload.settings.gp_fund_years);
        setDirectorNo(data.payload.settings.director_number);
        setAlphanumericAdmNo(data.payload.settings.alphanumeric_adm_no);
        setDirectorSign(data.payload.settings.director_sign);
        setSendMsg(data.payload.settings.send_message);
        setUseReadmissionFee(data.payload.settings.use_readmission_fee);
        setSMSApiLogin(data.payload.settings.sms_api_login);
        setSMSApiPassword(data.payload.settings.sms_api_password);
        setHeadOfficeUrl(data.payload.settings.head_office_url);

        // setImageUpload(data.payload[0].logo_file);
        setSaveBtn(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const testcheck = () => {
    console.log(logoUpload);
  };

  const handleLogoUpload = (e) => {
    setLogoUpload({
      image: e.target.files[0],
    });
  };

  const handleStartLogoUpload = (e) => {
    setStartLogoUpload({
      image: e.target.files[0],
    });
  };

  // const SendMessage = async () => {
  //   await fetch(`${baseUrl}/send-DN`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data.payload);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const update_setting = async () => {
    if (fineAmount == "") {
      alert.error("Please Enter Fine Amount");
      return;
    }

    setSaveBtn(true);

    const data = new FormData();

    console.log(directorNo);

    data.append("late_fee_fine", fineAmount);
    data.append("unit_name", unitName);
    data.append("gp_fund_years", gpFundYear);
    data.append("director_number", directorNo);
    data.append("alphanumeric_adm_no", alphanumericAdmNo);
    data.append("director_sign", directorSign);
    data.append("send_message", sendMsg);
    data.append("use_readmission_fee", useReadmissionFee);
    data.append("sms_api_login", SMSApiLogin);
    data.append("sms_api_password", SMSApiPassword);
    data.append("head_office_url", headOfficeUrl);

    if (logoUpload) {
      data.append("logo_file", logoUpload.image);
    }
    if (startLogoUpload) {
      data.append("start_logo_file", startLogoUpload.image);
    }

    // console.log(data);

    await fetch(`${baseUrl}/update-settings`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.payload);
            console.log(data);
            setSaveBtn(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Card style={{ margin: "10px", padding: 0 }}>
        <form className="m-10">
          <div className="mb-3">
            <label className="form-label" htmlFor="fineAmount">
              <b>Fine Amount</b>
            </label>
            <input
              className="form-control"
              id="fineAmount"
              value={fineAmount}
              placeholder="Enter Fine Amount"
              onChange={(e) => setFineAmount(e.target.value)}
              required
            ></input>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="unitName">
              <b>Unit Name</b>
            </label>
            <input
              className="form-control"
              id="unitName"
              value={unitName}
              placeholder="Enter Unit Name"
              onChange={(e) => setUnitName(e.target.value)}
              required
            ></input>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="gpFundYear">
              <b>GP Fund Deduction Years (From Joining Date)</b>
            </label>
            <input
              className="form-control"
              id="gpFundYear"
              value={gpFundYear}
              placeholder="Enter GP Fund Years"
              onChange={(e) => setGpFundYear(e.target.value)}
              required
            ></input>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="directorNo">
              <b>Director Number</b>
            </label>
            <input
              type="number"
              className="form-control"
              id="directorNo"
              value={directorNo}
              placeholder="Enter Director Number"
              onChange={(e) => setDirectorNo(e.target.value)}
              required
            ></input>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="smsapilogin">
              <b>SMS API Login</b>
            </label>
            <input
              className="form-control"
              id="smsapilogin"
              value={SMSApiLogin}
              placeholder="Enter SMS API Login"
              onChange={(e) => setSMSApiLogin(e.target.value)}
            ></input>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="smsapipassword">
              <b>SMS API Password</b>
            </label>
            <input
              className="form-control"
              type="password"
              id="smsapipassword"
              value={SMSApiPassword}
              placeholder="Enter Unit Name"
              onChange={(e) => setSMSApiPassword(e.target.value)}
            ></input>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="headofficeurl">
              <b>Head Office Url</b>
            </label>
            <input
              className="form-control"
              type="text"
              id="headofficeurl"
              value={headOfficeUrl}
              placeholder="Enter Head Office URL"
              onChange={(e) => setHeadOfficeUrl(e.target.value)}
            ></input>
          </div>

          <div className="my-4">
            <input
              type="checkbox"
              className="form-checkbox"
              id="alphanumericadm"
              checked={alphanumericAdmNo ? alphanumericAdmNo == 1 : false}
              onChange={(e) => setAlphanumericAdmNo(e.target.checked ? 1 : 0)}
            ></input>
            <label htmlFor="alphanumericadm" className="form-check-label">
              Alphanumeric Admission No
            </label>
          </div>

          <div className="my-4">
            <input
              type="checkbox"
              className="form-checkbox"
              id="directorSign"
              checked={directorSign ? directorSign == 1 : false}
              onChange={(e) => setDirectorSign(e.target.checked ? 1 : 0)}
            ></input>
            <label htmlFor="directorSign" className="form-check-label">
              Director Signature
            </label>
          </div>

          <div className="my-4">
            <input
              type="checkbox"
              className="form-checkbox"
              id="sendMsg"
              checked={sendMsg ? sendMsg == 1 : false}
              onChange={(e) => setSendMsg(e.target.checked ? 1 : 0)}
            ></input>
            <label htmlFor="sendMsg" className="form-check-label">
              Send Message
            </label>
          </div>

          <div className="my-4">
            <input
              type="checkbox"
              className="form-checkbox"
              id="useReadmissionFee"
              checked={useReadmissionFee ? useReadmissionFee == 1 : false}
              onChange={(e) => setUseReadmissionFee(e.target.checked ? 1 : 0)}
            ></input>
            <label htmlFor="useReadmissionFee" className="form-check-label">
              Use Re-admission Fee
            </label>
          </div>

          <div className="my-4">
            {logoUpload ? (
              <>
                <img
                  src={URL.createObjectURL(logoUpload.image)}
                  height="60px"
                  width="60px"
                />

                <span
                  onClick={() => setLogoUpload(null)}
                  style={{ cursor: "pointer" }}
                  className="svg-icon svg-icon-md svg-icon-danger p-3"
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Deleted-file.svg"
                    )}
                  />
                </span>
                {/* <button >Remove</button> */}
              </>
            ) : (
              <>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="file_1"
                  ref={file1Ref}
                  accept="image/png, image/jpeg, image/svg"
                  onChange={(e) => handleLogoUpload(e)}
                />
                <label
                  className="btn btn-icon btn-light btn-sm"
                  htmlFor="file_1"
                >
                  {
                    // student.picture ?
                    //   <img
                    //     src={student?.picture}
                    //     height="60px"
                    //     width="60px"
                    //   />
                    //   :
                    <span className="svg-icon svg-icon-md svg-icon-info">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}
                      ></SVG>
                    </span>
                  }
                </label>
                <label className="form-label">School Logo</label>
              </>
            )}
          </div>

          <div className="my-4">
            {startLogoUpload ? (
              <>
                <img
                  src={URL.createObjectURL(startLogoUpload.image)}
                  height="60px"
                  width="60px"
                />

                <span
                  onClick={() => setStartLogoUpload(null)}
                  style={{ cursor: "pointer" }}
                  className="svg-icon svg-icon-md svg-icon-danger p-3"
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Deleted-file.svg"
                    )}
                  />
                </span>
                {/* <button >Remove</button> */}
              </>
            ) : (
              <>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="file_2"
                  ref={file2Ref}
                  accept="image/png, image/jpeg, image/svg"
                  onChange={(e) => handleStartLogoUpload(e)}
                />
                <label
                  className="btn btn-icon btn-light btn-sm"
                  htmlFor="file_2"
                >
                  {
                    // student.picture ?
                    //   <img
                    //     src={student?.picture}
                    //     height="60px"
                    //     width="60px"
                    //   />
                    //   :
                    <span className="svg-icon svg-icon-md svg-icon-info">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}
                      ></SVG>
                    </span>
                  }
                </label>
                <label className="form-label">Start Logo</label>
              </>
            )}
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={update_setting}
            disabled={saveBtn ? true : false}
          >
            Save
          </button>

          {/* <button type="button" className="btn btn-primary" onClick={SendMessage}>
            Send DN Message
          </button> */}
        </form>
      </Card>
    </>
  );
};

export default UnitSettings;
