import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import ClearIcon from '@material-ui/icons/Clear';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from 'react-bootstrap/SelectableContext'
import { InputGroup, FormControl, ButtonToolbar, Form, Button, Modal } from 'react-bootstrap'
import TextInput from '../../components/TextInput';


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: 'green'
    },
    input: {
        display: 'none',
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];



export default function SendSmsToStaff() {
    const classes = useStyles();
    const [modalvisible, setmodalvisible] = useState(false)


    const AddNewCampusHandler = () => {

    }


    return (
        <Paper className={classes.root}>

            <div style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
                {/* <InputGroup size="md">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
        </InputGroup> */}
                <Button
                    style={{ margin: 5 }}
                    onClick={() => setmodalvisible(true)}
                    size="small" variant="outline-success" className={classes.button}>
                    Send New Message
                </Button>
            </div>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Code</StyledTableCell>
                        <StyledTableCell align="center">Name</StyledTableCell>
                        <StyledTableCell align="right">MobileNo</StyledTableCell>
        

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell align="left">{row.fat}</StyledTableCell>
                            <StyledTableCell align="center" component="th" scope="row">
                                {row.name}
                            </StyledTableCell>

                            <StyledTableCell align="right">{row.carbs}</StyledTableCell>
    
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>


            <Modal
                size="lg"
                show={modalvisible}
                onHide={() => setmodalvisible(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Send Message
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={AddNewCampusHandler}>

                        <TextInput label="Account Head" handleChange={(e) => console.log(e.target.value)} />
                        <TextInput label="Account Head" handleChange={(e) => console.log(e.target.value)} />
                        <TextInput label="Account Head" handleChange={(e) => console.log(e.target.value)} />
                    
                            
                     
                        <Button variant="success" onClick={() => alert('work')} type="submit" value="Submit">Submit</Button>

                        <Button variant="danger" style={{ margin: 10 }} type="reset" value="Reset" >Reset</Button>

                        <Button onClick={() => setmodalvisible(false)} variant="outline-warning" style={{ float: 'right' }}  >Cancel</Button>
                    </form>

                </Modal.Body>
            </Modal>

        </Paper>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

}));