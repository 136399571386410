import { Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import ReactToPrint from "react-to-print";
import StudentConcessionPrint from "./StudentConcessionPrint";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import { upperCase } from "lodash";

const StudentConcession = () => {
  const [allconcessions, setallconcessions] = useState([]);
  const [feetypes, setfeetypes] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");
  const [loading, setLoading] = useState(false);
  const [concessionId, setConcessionId] = useState("");
  const [feeId, setFeeId] = useState("");
  const [reportData, setReportData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [feeTotal, setFeeTotal] = useState(0);
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Student Concession Fee",
    sheet: "StudentConcessionFee",
  });
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [sessionName, setSessionName] = useState([]);
  const [FeeTypeName, setFeeTypeName] = useState([]);
  const [concessionName, setConcessionName] = useState([]);

  useEffect(() => {
    getSettingData();
    getConcessionTypes();
    getFeeTypes();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFeeTypes = () => {
    fetch(`${baseUrl}/feestypes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setfeetypes(data.payload);
        });
      })
      .catch((err) => {
        alert.error(err);
      });
  };

  const getConcessionTypes = () => {
    fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallconcessions(data.payload);
            console.log(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };
  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const search = () => {
    if (feeId == "") {
      alert.error("Fee Type is required");
      return;
    }

    // const sessionTemp = sessionData.find((session) => {
    //   return session.id == yearId;
    // });
    // setSessionName(sessionTemp.year);

    // const FeeNameTemp = feetypes.find((feeType) => {
    //   return feeType.id == feeId;
    // });
    // setFeeTypeName(FeeNameTemp.name);

    // const ConcessionNameTemp = allconcessions.find((concession) => {
    //   return concession.id == concessionId;
    // });
    // setConcessionName(ConcessionNameTemp.title);

    setLoading(true);
    const data = {
      year_id: yearId,
      fees_type_id: feeId,
      concession_id: concessionId,
    };

    fetch(`${baseUrl}/concession-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        resp.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              console.log("data", data.payload);
              getConGrand(data.payload);
              getFeeGrand(data.payload);
              setReportData(data.payload);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getConfeeSum = (currentCampus) => {
    let sum = 0;

    currentCampus.classes.map((subitem) => {
      subitem.sections.map((student) => {
        student.students.map((bacha) => {
          sum += parseInt(bacha.student_liable_fees[0]?.concession_amount);
        });
      });
    });

    return sum;
  };
  const getfeeSum = (currentCampus) => {
    let sum = 0;

    currentCampus.classes.map((subitem) => {
      subitem.sections.map((student) => {
        student.students.map((bacha) => {
          sum += parseInt(bacha.student_liable_fees[0]?.amount);
        });
      });
    });

    return sum;
  };

  const getConGrand = (array) => {
    if (array) {
      let sum = 0;
      array.map((item) => {
        item.classes.map((subitem) => {
          subitem.sections.map((student) => {
            student.students.map((bacha) => {
              sum += parseInt(bacha.student_liable_fees[0]?.concession_amount);
            });
          });
        });
      });
      setGrandTotal(sum);
    }
  };

  const getFeeGrand = (array) => {
    if (array) {
      let sum = 0;
      array.map((item) => {
        item.classes.map((subitem) => {
          subitem.sections.map((student) => {
            student.students.map((bacha) => {
              sum += parseInt(bacha.student_liable_fees[0]?.amount);
            });
          });
        });
      });
      setFeeTotal(sum);
    }
  };
  let num2 = 0;
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-sm-4">
            <label className="form-control-label">
              <b>
                Select Session <span style={{ color: "red" }}>*</span>{" "}
              </b>
            </label>
            <select
              style={{
                backgroundColor: "white",
              }}
              className="form-control mb-2 "
              aria-label="Default select example"
              name="year_id"
              value={yearId}
              onChange={(e) => setYearId(e.target.value)}
            >
              <option selected>Select Session</option>
              {sessionData &&
                sessionData.map((item) => (
                  <option value={item.id}>{item.year}</option>
                ))}
            </select>
          </div>
          <div className="col-sm-4">
            <label className="form-control-label">
              <b>
                Fee Type <span style={{ color: "red" }}>*</span>{" "}
              </b>
            </label>
            <select
              className="form-control mb-2 "
              aria-label="Default select example"
              value={feeId}
              onChange={(e) => setFeeId(e.target.value)}
            >
              <option selected>Select Fee Type </option>
              {feetypes.map((feetype) => (
                <option key={feetype.id} value={feetype.id}>
                  {feetype.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-4">
            <label className="form-control-label">
              <b>Concession Type</b>
            </label>
            <select
              style={{
                backgroundColor: "white",
              }}
              value={concessionId}
              onChange={(e) => setConcessionId(e.target.value)}
              className="form-control mb-2 "
              aria-label="Default select example"
            >
              <option value="">Select Concession Type </option>

              {allconcessions.map((feetype) => (
                <option key={feetype.id} value={feetype.id}>
                  {feetype.title}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 mt-7">
            <button className="btn btn-primary" onClick={search}>
              Search
            </button>
            <ReactToPrint
              documentTitle={`Student Concession Fee`}
              pageStyle="auto"
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint={true}
              trigger={() => (
                <button
                  // onClick={() => console.log("dfsdfsdfd")}
                  disabled={printloading ? true : false}
                  className="btn btn-success button_blue button_blue mx-2"
                >
                  {printloading ? <span>Printing..</span> : "Print"}
                </button>
              )}
              content={() => componentRef.current}
            />
            <button className="btn btn-primary" onClick={onDownload}>
              {" "}
              Export{" "}
            </button>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Sr.No</th>
                  <th className="text-center">Adm-Id</th>
                  <th>Name</th>
                  <th>Father Name</th>
                  <th>Remarks</th>
                  <th className="text-center">Mobile</th>
                  <th className="text-center">Emp-Code</th>
                  <th className="text-center">Fee</th>
                  <th className="text-center">Concession</th>
                </tr>
              </thead>
              {reportData.length > 0
                ? reportData.map((item) =>
                    getfeeSum(item) > 0 && getConfeeSum(item) > 0 ? (
                      <>
                        <tr className="mx-4" style={{ marginTop: "40px" }}>
                          <td colSpan={9} className="text_align_start">
                            <h3 className="text-primary">{item.Campus}</h3>
                          </td>
                        </tr>
                        {item?.classes?.map((subitem) =>
                          subitem?.sections?.map((session) => (
                            <>
                              {session.students.length > 0 ? (
                                <>
                                  <tr>
                                    <td
                                      colSpan={9}
                                      className="text_align_start"
                                    >
                                      <h4 className="">
                                        Class:-{subitem.Class?.name}(
                                        {session.Section?.name})
                                      </h4>
                                    </td>
                                  </tr>
                                  <tbody>
                                    {session?.students.map((student) => (
                                      <tr id="tbl">
                                        <td
                                          style={{ color: "black" }}
                                          className="text-center"
                                        >
                                          {(num2 += 1)}
                                        </td>
                                        <td
                                          style={{ color: "black" }}
                                          className="text-center"
                                        >
                                          {student.admission_id}
                                        </td>
                                        <td
                                          style={{ color: "black" }}
                                          className="text_align_start"
                                        >
                                          {student.name}
                                        </td>
                                        <td
                                          style={{ color: "black" }}
                                          className="text_align_start"
                                        >
                                          {student.father_name}
                                        </td>
                                        <td
                                          style={{ color: "black" }}
                                          className="text_align_start"
                                        >
                                          {student?.concession_remarks}
                                        </td>
                                        <td
                                          style={{ color: "black" }}
                                          className="text-center"
                                        >
                                          {student.mobile_no}
                                        </td>
                                        <td
                                          style={{ color: "black" }}
                                          className="text-center"
                                        >
                                          {student.employee_id}
                                        </td>

                                        <td
                                          style={{ color: "black" }}
                                          className="text-center"
                                        >
                                          {
                                            student?.student_liable_fees[0]
                                              ?.amount
                                          }
                                        </td>
                                        <td
                                          style={{ color: "black" }}
                                          className="text-center"
                                        >
                                          {
                                            student.student_liable_fees[0]
                                              ?.concession_amount
                                          }
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </>
                              ) : null}
                            </>
                          ))
                        )}
                        <tr id="tbl">
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          ></td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          ></td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          ></td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          ></td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          ></td>
                          <td
                            style={{ fontSize: "1.3rem" }}
                            colSpan={2}
                            className="text_align_start text-primary"
                          >
                            Total
                          </td>
                          <td
                            style={{ color: "black", fontSize: "1.3rem" }}
                            className="text-center"
                          >
                            {getfeeSum(item)}
                          </td>
                          <td
                            style={{ color: "black", fontSize: "1.3rem" }}
                            className="text-center"
                          >
                            {getConfeeSum(item)}
                          </td>
                        </tr>
                      </>
                    ) : null
                  )
                : ""}

              <tr id="tbl">
                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>
                <td
                  style={{ fontSize: "1.3rem" }}
                  className="text_align_start text-primary"
                  colSpan={2}
                >
                  Grand Total
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: "1.3rem",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                  className="text-center"
                >
                  {feeTotal}
                  {/* {getfeeSum(item)} */}
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: "1.3rem",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                  className="text-center"
                >
                  {grandTotal}
                </td>
              </tr>
            </Table>
          </div>
        )}
      </Paper>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden", padding: "10px" }}
        className="tablePrint"
      >
        <div className="p-3" style={{ display: "flex" }}>
          <img
            // className='col-md-2'
            style={{ height: 100, width: 100 }}
            src={icon}
          />
          <div className="col-md-8">
            <h1>{unitName}</h1>
            <h4>Student Fee Concession Report For the Session {sessionName}</h4>
            <div className="d-flex justify-content-between">
              <div>
                <h5>Fee Type:- {FeeTypeName}</h5>
              </div>
              <div>
                <h5>Concession Type:- {concessionName}</h5>
              </div>
            </div>
          </div>
        </div>
        <StudentConcessionPrint
          reportData={reportData}
          getfeeSum={getfeeSum}
          getConfeeSum={getConfeeSum}
          feeTotal={feeTotal}
          grandTotal={grandTotal}
        />
      </div>
      <div ref={tableRef} style={{ display: "none" }}>
        <StudentConcessionPrint
          reportData={reportData}
          getfeeSum={getfeeSum}
          getConfeeSum={getConfeeSum}
          feeTotal={feeTotal}
          grandTotal={grandTotal}
        />
      </div>
    </>
  );
};

export default StudentConcession;
