import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Tab,
  Tabs,
  Alert,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import "./styles.css";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import "../../../components/PrintChallanModal/printChallan.style.css";
import "./printstyle.css";
import { Paper } from "@material-ui/core";
const icon =
  "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";
function RollBack({ orientation = "portrait" }) {
  const banksloaded = useRef(false);

  useEffect(() => {
    // getAllCampus();
    if (!banksloaded.current) {
      banksloaded.current = true;
      setreceivingdate(currentDate);
      setsearchreceivingdate(currentDate);
      getAllBanks();
    }

    // selectedChallansArray = [...selectedChallans];
    if (searchClicked.current) {
      setAllCheckboxes(true);
      searchClicked.current = false;
    } else {
      checkAllCheckBoxes();
    }
  }, [[], selectedChallans]);

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }
  const currentDate = new Date().toISOString().slice(0, 10);
  // const currentDates = new Date().toISOString().slice(0, 10);

  const authtoken = localStorage.getItem("userToken");

  // Our New Variable
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedbankcateogry, setselectedbankcateogry] = useState(1);
  const [selectedbank, setselectedbank] = useState(1);
  const [receivingdate, setreceivingdate] = useState();

  const [searchSubAccounts, setsearchsubaccounts] = useState([]);
  const [searchBank, setsearchBank] = useState(1);
  const [searchBankCategory, setSearchBankCategory] = useState(1);
  const [searchreceivingdate, setsearchreceivingdate] = useState();

  const [allbanks, setallbanks] = useState([]);
  const [totalfee, setTotalfee] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  // const [searchType, setSearchtype] = useState("adm");
  const [loading, setloading] = useState(false);
  const [studentchallans, setstudentchallans] = useState([]);
  const [selectedChallans, setSelectedChallans] = useState([]);
  // const [checkedItems, setCheckedItems] = useState([]);
  const [mainCheckBoxChecked, setmaincheckboxChecked] = useState(false);
  const [searchBtn, setSearchBtn] = useState(false);
  const [searchBankScrollBtn, setSearchBankScrollBtn] = useState(false);
  const [rollbackBtn, setRollBackBtn] = useState(false);
  const [updateBtn, setUpdateBtn] = useState(false);
  const alert = useAlert();
  const searchClicked = useRef(false);
  const searchInputRef = useRef(null);
  const challanUpdateType = useRef("studentWise");
  const [isVisible, setIsVisible] = useState(false);
  const [isStdDetailsVisible, setStdDetailsVisible] = useState(false);
  const [studentDetails, setStudentDetails] = useState([]);

  const testcheck = () => {
    console.log(selectedChallans);
    // console.log(selectedChallanDetails);
  };

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          getAllSubAccounts(data.payload, 1);
          getSearchSubAccounts(data.payload, 1);
          searchInputRef.current.focus();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSubAccounts = (data, selectedBank) => {
    let tempBanks = data.filter((item) => item.id == selectedBank);

    if (tempBanks.length != 0) {
      setallsubaccounts([...tempBanks[0].bank_accounts]);
    } else {
      setallsubaccounts([]);
      setselectedbankcateogry(0);
    }
  };

  const getSearchSubAccounts = (data, selectedBank) => {
    let tempBanks = data.filter((item) => item.id == selectedBank);

    if (tempBanks.length != 0) {
      setsearchsubaccounts([...tempBanks[0].bank_accounts]);
    } else {
      setsearchsubaccounts([]);
      setSearchBankCategory(0);
    }
  };

  const handleMainCheckboxClick = () => {
    if (mainCheckBoxChecked) setAllCheckboxes(false);
    else setAllCheckboxes(true);
  };

  const setAllCheckboxes = (value) => {
    setmaincheckboxChecked(value);
    if (value) setSelectedChallans([]);

    studentchallans.map((item, i) => {
      item.checked = value;
      if (!value) RemoveSelectedChallan(item);
      else {
        AddSelectedChallan(item);
      }
    });
  };

  const checkAllCheckBoxes = () => {
    if (studentchallans.length == selectedChallans.length) {
      setmaincheckboxChecked(true);
    } else if (mainCheckBoxChecked) {
      setmaincheckboxChecked(false);
    }
  };

  const handleChallansCheckBoxClick = (e, index) => {
    studentchallans.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;

        if (item.checked) {
          AddSelectedChallan(item);
        } else {
          RemoveSelectedChallan(item);
        }
      }
    });
  };

  const AddSelectedChallan = (item) => {
    setSelectedChallans((prevSelectedChallans) => [
      ...prevSelectedChallans,
      item.id,
    ]);
  };

  const RemoveSelectedChallan = (item) => {
    setSelectedChallans((prevSelectedChallans) =>
      prevSelectedChallans.filter((id) => id !== item.id)
    );
  };

  const handleSearchInput = (e) => {
    console.log(e.target.value);
    setSearchValue(e.target.value);
  };

  const handleChallansSearch = async () => {
    if (searchValue == "") {
      alert.show("Please Enter Required Value");
      searchInputRef.current.focus();
      return;
    }

    setTotalfee(0);
    setloading(true);
    setSearchBtn(true);
    setIsVisible(false);
    setStdDetailsVisible(false);

    let data = {
      search_value: searchValue,
      // search_type: searchType,
      // challan_status: "1",
    };

    await fetch(`${baseUrl}/search-rollback-challans`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setSearchBtn(false);
        if (data.metadata) {
          if (data.metadata.success) {
            // if (data.payload == "") {
            //   setstudentchallans([]);
            //   alert.error("Student Record Not Found");
            //   searchInputRef.current.focus();
            //   searchInputRef.current.value = "";
            //   setSearchValue("");

            //   return;
            // }

            setstudentchallans([]);
            let chk = true;

            if (data.payload.student.length == 0) {
              alert.error("No Student Record Found");
              chk = false;
            }

            if (data.payload.fee_challans_data.length == 0 && chk) {
              alert.error("No Paid Challans Found");
              chk = false;
            }

            if (!chk) {
              searchInputRef.current.focus();
              searchInputRef.current.value = "";
              setSearchValue("");
              return;
            }

            setIsVisible(true);
            setStdDetailsVisible(true);
            console.log(data);
            document.getElementById("rollbackBtn").focus();

            updateNetFee(data.payload.fee_challans_data);

            setStudentDetails(data.payload.student[0]);
            setstudentchallans(data.payload.fee_challans_data);
            searchClicked.current = true;
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };

  const updateNetFee = (data) => {
    var totalfee = 0;
    data.map((challanData) => {
      totalfee =
        totalfee +
        parseFloat(challanData.payable == null ? 0 : challanData.payable);
    });

    setTotalfee(totalfee);
  };

  const handleReceivingValidations = () => {
    if (selectedChallans.length == 0) {
      alert.error("Please select some challans");
      return false;
    }
    if (selectedbankcateogry == 0) {
      alert.error("Please select bank / branch");
      return false;
    }

    if (receivingdate == "") {
      alert.error("Date Not Set");
      return false;
    }

    return true;
  };

  const handleUpdateChallan = async (bankwise) => {
    if (!handleReceivingValidations()) return;

    setloading(true);
    setUpdateBtn(true);

    let data = {
      challan_ids: selectedChallans,
      bank_account_id: selectedbankcateogry,
      receiving_date: receivingdate,
    };

    // console.log(data);

    await fetch(`${baseUrl}/update-challan`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setUpdateBtn(false);

        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data);
            alert.success(data.payload);

            setSelectedChallans([]);
            // searchClicked.current = true;
            if (challanUpdateType.current == "studentWise") {
              searchInputRef.current.focus();
              handleChallansSearch();
            } else {
              const unupdatedStudentChallans = studentchallans.filter(
                (studentChallan) =>
                  !selectedChallans.some(
                    (selectedChallan) => selectedChallan === studentChallan.id
                  )
              );

              if (unupdatedStudentChallans.length > 0) {
                // Update the state with the filtered array
                setstudentchallans(unupdatedStudentChallans);
                updateNetFee(unupdatedStudentChallans);
              } else {
                setTotalfee(0);
                setIsVisible(false);
                setstudentchallans([]);
              }
            }
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };

  const handleRollbackChallan = async () => {
    // if (!handleReceivingValidations()) return;
    if (selectedChallans.length == 0) {
      alert.error("Please select some challans");
      return false;
    }

    setloading(true);
    setRollBackBtn(true);
    setIsVisible(false);
    setStdDetailsVisible(false);

    let data = {
      challan_ids: selectedChallans,
    };

    // console.log(data);

    await fetch(`${baseUrl}/roll-back-challan`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setRollBackBtn(false);
        // setReceiveBtn(false);

        if (data.metadata) {
          if (data.metadata.success) {
            setIsVisible(true);
            setStdDetailsVisible(true);
            console.log(data);
            alert.success(data.payload);
            const updatedStudentChallans = studentchallans.filter(
              (studentChallan) =>
                !selectedChallans.some(
                  (selectedChallan) => selectedChallan === studentChallan.id
                )
            );

            // Update the state with the filtered array
            setstudentchallans(updatedStudentChallans);
            setSelectedChallans([]);
            // searchClicked.current = true;
            if (updatedStudentChallans.length == 0) {
              searchInputRef.current.value = "";
              setSearchValue("");
              setTotalfee(0);
              setIsVisible(false);
              setStdDetailsVisible(false);
            } else {
              updateNetFee(updatedStudentChallans);
            }

            searchInputRef.current.focus();
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setSelectedChallans([]);
            searchInputRef.current.value = "";
            setSearchValue("");
            setTotalfee(0);
            setIsVisible(false);
            setStdDetailsVisible(false);
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };

  const handleSelect = (key) => {
    setstudentchallans([]);
    setSelectedChallans([]);
    setIsVisible(false);
    setStdDetailsVisible(false);

    if (key == 1) {
      challanUpdateType.current = "studentWise";
      searchInputRef.current.focus();
    } else if (key == 2) {
      challanUpdateType.current = "bankWise";
    }
  };

  const handleBankScrollValidations = () => {
    if (searchBankCategory == 0) {
      alert.error("Please select bank / branch");
      return false;
    }

    if (searchreceivingdate == "") {
      alert.error("Please select a date");
      return false;
    }

    return true;
  };

  const handleBankScrollSearch = async () => {
    if (!handleBankScrollValidations()) return;

    setIsVisible(false);
    setTotalfee(0);
    setloading(true);
    setSearchBankScrollBtn(true);

    let data = {
      bank_account_id: searchBankCategory,
      receiving_date: searchreceivingdate,
    };

    console.log(data);
    await fetch(`${baseUrl}/search-bank-wise-challans`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setSearchBankScrollBtn(false);

        if (data.metadata) {
          if (data.metadata.success) {
            if (data.payload == "") {
              setstudentchallans([]);
              alert.error("Record Not Found");
              return;
            }
            setIsVisible(true);
            console.log(data);
            document.getElementById("rollbackBtn").focus();

            updateNetFee(data.payload);
            setstudentchallans([]);
            setstudentchallans(data.payload);
            searchClicked.current = true;
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="form-group col-md-12">
            <Tabs fill className="mb-12" onSelect={handleSelect}>
              <Tab eventKey={1} title="Student Wise">
                <label
                  className="form-control-label"
                  style={{ marginLeft: "10px" }}
                >
                  <b>
                    Enter Admission No.
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </b>
                </label>
                <div className="d-flex form-group col-md-7">
                  <input
                    className="form-control"
                    style={{ width: "80%" }}
                    type="text"
                    ref={searchInputRef}
                    onChange={handleSearchInput}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleChallansSearch();
                      }
                    }}
                    id="searchInput"
                    placeholder="Enter Admission Number"
                  />
                  <button
                    className="btn btn-primary ml-5"
                    onClick={handleChallansSearch}
                    disabled={searchBtn ? true : false}
                  >
                    Search
                  </button>
                </div>
              </Tab>
              <Tab eventKey={2} title="Bank Wise">
                <div className="row">
                  {/* Search Bank Scroll */}

                  <div className="col-md-3">
                    <label>
                      <b>
                        Select Bank
                        <span style={{ color: "red", fontSize: 12 }}>
                          *
                        </span>{" "}
                      </b>
                    </label>
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setsearchBank(e.target.value);
                        setSearchBankCategory(0);
                        getSearchSubAccounts(allbanks, e.target.value);
                      }}
                      name="searchBank"
                      id="searchBank"
                      required
                      value={searchBank}
                    >
                      <option>Select...</option>
                      {allbanks.map((bankData) => (
                        <option value={bankData.id}>{bankData.title}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>
                      <b>
                        Select Branch
                        <span style={{ color: "red", fontSize: 12 }}>*</span>
                      </b>
                    </label>
                    <select
                      class="form-control"
                      // New OnChange
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value == "Select...") {
                          value = 0;
                        }
                        setSearchBankCategory(value);
                      }}
                      name="searchBankBranches"
                      id="searchBankBranches"
                      required
                      value={searchBankCategory}
                    >
                      <option>Select...</option>
                      {searchSubAccounts.map((item) => (
                        <option value={item.id}>{item.bank_name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>
                      <b>
                        Select Date
                        <span style={{ color: "red", fontSize: 12 }}>*</span>
                      </b>
                    </label>
                    <input
                      type="date"
                      max="9999-11-30"
                      defaultValue={currentDate}
                      value={searchreceivingdate}
                      className="form-control"
                      name="searchreceivingdate"
                      id="searchreceivingdate"
                      onChange={(e) => {
                        setsearchreceivingdate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-3 mt-9">
                    <Button
                      className="btn btn-primary"
                      onClick={handleBankScrollSearch}
                      disabled={searchBankScrollBtn ? true : false}
                    >
                      Search Bank Scroll
                    </Button>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : null}

          {isStdDetailsVisible ? (
            <>
              {/* <div className="m-3 w-50 mt-3 mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6>
                      <b>Student Name:- </b> {studentDetails?.name}
                    </h6>
                  </div>
                  <div>
                    <h6>
                      <b> Admission ID:- </b> {studentDetails?.admission_id}
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <h6>
                      <b>Class:- </b>{" "}
                      {studentDetails?.student_class?.name +
                        " " +
                        studentDetails?.global_section?.name}
                    </h6>
                  </div>
                  <div>
                    <h6>
                      <b> Campus:- </b> {studentDetails?.campus?.name}
                    </h6>
                  </div>
                </div>
              </div> */}
              <div className="pl-9 pb-4">
                <Table className="table table-borderless">
                  <thead>
                    <tr id="tbl">
                      <th style={{ fontSize: 15, padding: "1px" }}>
                        <span style={{ color: "#121291" }}>Student Name:</span>{" "}
                        {studentDetails?.name}
                      </th>
                      <th style={{ fontSize: 15, padding: "1px" }}>
                        <span style={{ color: "#121291" }}>Class:</span>{" "}
                        {studentDetails?.student_class?.name +
                          " " +
                          studentDetails?.global_section?.name}
                      </th>
                      <th style={{ fontSize: 15, padding: "1px" }}>
                        <span style={{ color: "#121291" }}>Campus: </span>{" "}
                        {studentDetails?.campus?.name}
                      </th>
                    </tr>
                    <tr id="tbl">
                      <th style={{ fontSize: 15, padding: "1px" }}>
                        <span style={{ color: "#121291" }}>Father Name:</span>{" "}
                        {studentDetails?.father_name}
                      </th>
                      <th style={{ fontSize: 15, padding: "1px" }}>
                        <span style={{ color: "#121291" }}>
                          {studentDetails?.admission_id == null
                            ? "Registeration ID:"
                            : "Admission ID:"}
                        </span>{" "}
                        {studentDetails?.admission_id == null
                          ? studentDetails?.registration_id
                          : studentDetails?.admission_id}
                      </th>
                    </tr>
                  </thead>
                </Table>
              </div>
            </>
          ) : null}

          <div>
            {isVisible ? (
              <div>
                <Table responsive>
                  <thead>
                    <tr className="text-center">
                      <th style={{ width: "5px" }}>
                        <input
                          onClick={(e) => {
                            handleMainCheckboxClick();
                          }}
                          checked={mainCheckBoxChecked}
                          style={{}}
                          placeholder="Checkbox"
                          type="checkbox"
                        />
                      </th>
                      <th>Challan No</th>
                      <th>Bank Name </th>
                      <th colSpan={3}>Bank Branch</th>
                      <th>Received Date</th>
                      <th>Amount</th>
                      <th>Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentchallans.map((challandata) => (
                      <tr>
                        <td style={{ width: "5px" }}>
                          <input
                            type="checkbox"
                            value={challandata.id}
                            key={challandata.id}
                            onClick={(e) => {
                              handleChallansCheckBoxClick(e, challandata.id);
                              // MoreAddOption(e);
                            }}
                            // checked={isChecked}
                            checked={challandata.checked ?? false}
                            style={{ border: "10px" }}
                          ></input>
                        </td>
                        <td>{challandata.challan_no}</td>
                        <td>{challandata.bank_account.account_title}</td>
                        {/* <td>{studentDetails.name}</td> */}
                        {/* <td>
                          {studentDetails.student_class.name} -
                          {studentDetails.global_section.name}
                        </td> */}
                        <td colSpan={3}>
                          {challandata.bank_account.bank_name}
                        </td>

                        <td>{challandata.received_date}</td>
                        <td>{challandata.payable}</td>
                        <td>
                          {getMonthName(
                            challandata.fee_challan_details[0].fee_month
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div style={{ margin: "30px auto" }}>
                  <div className="d-flex">
                    <div className="form-group col-sm-4 flex-column">
                      <label className="form-control-label">
                        <b>
                          Select Bank
                          <span style={{ color: "red", fontSize: 12 }}>*</span>
                        </b>
                      </label>
                      <select
                        class="form-control"
                        onChange={(e) => {
                          setselectedbank(e.target.value);
                          setselectedbankcateogry(0);
                          getAllSubAccounts(allbanks, e.target.value);
                        }}
                        name="mainBank"
                        id="mainBank"
                        required
                        value={selectedbank}
                      >
                        <option>Select...</option>
                        {allbanks.map((bankData) => (
                          <option value={bankData.id}>{bankData.title}</option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-sm-4 flex-column">
                      <label className="form-control-label">
                        <b>
                          Select Bank Branch
                          <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <select
                        class="form-control"
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value == "Select...") {
                            value = 0;
                          }
                          setselectedbankcateogry(value);
                        }}
                        name="bankBranches"
                        id="bankBranches"
                        required
                        value={selectedbankcateogry}
                      >
                        <option>Select...</option>
                        {allsubaccounts.map((item) => (
                          <option value={item.id}>{item.bank_name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-sm-2 flex-column">
                      <label className="form-control-label">
                        <b>
                          Received Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <input
                        type="date"
                        max="9999-11-30"
                        defaultValue={currentDate}
                        value={receivingdate}
                        className="form-control"
                        name="receivingdate"
                        id="receivingdate"
                        onChange={(e) => {
                          setreceivingdate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group col-sm-2 flex-column">
                      <label className="form-control-label">
                        <b>Net Fee</b>
                      </label>
                      <input
                        disabled
                        id="myInput"
                        className="form-control"
                        value={totalfee}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{ justifyContent: "end", paddingRight: "10px" }}
                  >
                    <div>
                      <Button
                        onClick={handleUpdateChallan}
                        id="updatechallanBtn"
                        disabled={updateBtn ? true : false}
                        className="mr-4"
                      >
                        Update Challan
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={handleRollbackChallan}
                        id="rollbackBtn"
                        disabled={rollbackBtn ? true : false}
                      >
                        Roll Back Fee
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Paper>
    </>
  );
}

export default RollBack;
