import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../../services/config";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import SVG from "react-inlinesvg";
import { Table, Modal, Alert, Spinner } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../../components/usePagination/usePagination";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { MdDelete } from "react-icons/md";
import { FaFileDownload } from "react-icons/fa";
import MySelect from "./../../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { PremissionChecker } from "../../../../services/PremissionChecker";

const StudentResources = () => {
  const user = useSelector((state) => state.user.user);
  const authtoken = localStorage.getItem("userToken");

  const [loading, setloading] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editResourcesLoading, setEditResourcesLoading] = useState(false);
  const alert = useAlert();

  const [studentResourcesData, setStudentResourcesData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [classesData, setClassesData] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [title, setTtile] = useState("");
  const [campusId, setCampusId] = useState("");
  const [optionSelected, setOptionSelected] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");

  const [editResourcesModal, setEditResourcesModal] = useState(false);
  const [editResourcesID, setEditResourcesID] = useState([]);
  const [editTitle, setEditTitle] = useState("");
  const [editCampusId, setEditCampusId] = useState("");
  const [editOptionSelected, setEditOptionSelected] = useState([]);
  const [editClassIds, setEditClassIds] = useState([]);
  const [editFiles, setEditFiles] = useState([]);
  const [editExpiryDate, setEditExpiryDate] = useState("");

  const [deleteResourcesModal, setDeleteResourcesModal] = useState(false);
  const [deleteableResources, setDeleteableResources] = useState("");
  const [deleteloading, setdeleteloading] = useState(false);

  useEffect(() => {
    getClasses();
    getStudentResources();
  }, []);

  const getClasses = async () => {
    await fetch(`${baseUrl}/studentclasses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setClassesData(data.payload);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const getStudentResources = async () => {
    setloading(true);

    await fetch(`${baseUrl}/student-resource`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata.success) {
            setStudentResourcesData(data.payload);
            setloading(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setloading(false);
          } else {
            alert.show("Something Went Wrong");
            setloading(false);
          }
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  // Handle Add, Update and Delete Branch Resources
  const addResourcesHandler = async () => {
    if (title == "" || campusId == "" || classIds == []) {
      setisallfields(true);
      return;
    }
    setAddLoading(true);
    setisallfields(false);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("campus_id", campusId);
    for (let i = 0; i < classIds.length; i++) {
      formData.append(`student_class_ids[${i}]`, classIds[i]);
    }
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    await fetch(`${baseUrl}/student-resource`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "testing");
        if (data.metadata) {
          setAddLoading(false);
          if (data.metadata.success) {
            alert.success("Student Resources Successfully Added");
            setTtile("");
            setCampusId("");
            setClassIds([]);
            setOptionSelected([]);
            setFiles([]);
            getStudentResources();
            setAddModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setAddModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setAddModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAddLoading(false);
      });
  };

  const getEditResources = async (id) => {
    await fetch(`${baseUrl}/student-resource/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditTitle(data.payload?.title);
          setEditCampusId(data.payload?.campus_id);
          setEditOptionSelected(
            data.payload?.student_classes.map((item) => ({
              value: item.student_class.id,
              label: item.student_class.name,
            }))
          );
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateResourcesHandler = async () => {
    if (
      editTitle == "" ||
      editCampusId == "" ||
      editClassIds == [] ||
      files == []
    ) {
      setisallfields(true);
      return;
    }
    setEditResourcesLoading(true);
    setisallfields(false);

    let actualClasses = [];
    editOptionSelected.map((item, index) => {
      actualClasses[index] = item.value;
    });
    const formData = new FormData();
    if (editClassIds.length > 0) {
      for (let i = 0; i < editClassIds.length; i++) {
        formData.append(`student_class_ids[${i}]`, editClassIds[i]);
      }
    } else {
      for (let i = 0; i < actualClasses.length; i++) {
        formData.append(`student_class_ids[${i}]`, actualClasses[i]);
      }
    }
    formData.append("title", editTitle);
    formData.append("campus_id", editCampusId);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    await fetch(`${baseUrl}/student-resource/${editResourcesID}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setEditResourcesLoading(false);
            setEditResourcesModal(false);
            alert.success("Student Resources successfully Updated");
            setFiles([]);
            setEditClassIds([]);
            getStudentResources();
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setEditResourcesLoading(false);
            setEditResourcesModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditResourcesLoading(false);
            setEditResourcesModal(false);
          }
        })
      )
      .catch((err) => {
        setEditResourcesLoading(false);
        setEditResourcesModal(false);
        console.log(err);
      });
  };

  const deleteResourcesHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/student-resource/${deleteableResources}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            setStudentResourcesData([]);
            getStudentResources();
            setdeleteloading(false);
            setDeleteResourcesModal(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setdeleteloading(false);
            setDeleteResourcesModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setdeleteloading(false);
          setDeleteResourcesModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setDeleteResourcesModal(false);
      });
  };

  const showViewModal = (resources) => {
    setSelectedResources(resources);
    setShowModal(true);
  };

  // Handle Multiple Classes
  const Options = [];
  classesData.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const handleChangeClasses = (selected) => {
    setOptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setClassIds([...valuearray]);
  };

  const handleEditClasses = (selected) => {
    setEditOptionSelected([...selected]);

    let valuearray = [];
    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    console.log(valuearray);
    setEditClassIds([...valuearray]);
  };

  // Handle File Upload
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeImage = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  // Variables & Function For Pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(studentResourcesData.length / PER_PAGE);
  const _DATA = usePagination(studentResourcesData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "15px", marginBottom: "15px", padding: "15px" }}
      >
        <div className="d-flex mt-5">
          {PremissionChecker(user, "student.add_student_resources") ? (
            <button
              className="btn btn-primary mx-2"
              onClick={() => setAddModal(true)}
            >
              Add Student Resources
            </button>
          ) : null}
        </div>

        <div className="mt-5">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">Sr.No</th>
                <th className="text-center">Campus</th>
                <th className="text-center">Classes</th>
                <th className="text-center">Title</th>
                <th className="text-center">Files</th>
                <th className="text-center">View</th>
                {PremissionChecker(user, "student.update_student_resources") ? (
                  <th className="text-center">Edit</th>
                ) : null}
                {PremissionChecker(user, "student.delete_student_resources") ? (
                  <th className="text-center">Delete</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((ResourcesData, index) => (
                <tr key={index}>
                  <td className="text-center">{ResourcesData?.id}</td>
                  <td className="text-center">{ResourcesData?.campus?.name}</td>
                  <td className="text-center">
                    {ResourcesData?.student_classes
                      .slice(0, 3)
                      .map((className) => className.student_class?.name)
                      .join(", ") +
                      (ResourcesData?.student_classes.length > 3
                        ? ", ..."
                        : "")}
                  </td>
                  <td className="text-center">
                    {ResourcesData?.title.length > 35
                      ? ResourcesData?.title.substring(0, 35) + "..."
                      : ResourcesData?.title}
                  </td>
                  <td className="text-center">
                    {ResourcesData?.files?.map((file, fileIndex) => (
                      <a
                        key={fileIndex}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        href={`${baseUrl.replace(
                          "/api",
                          ""
                        )}/storage/student/resources/${file.file}`}
                        download={file.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-success">
                          <FaFileDownload />
                        </span>
                      </a>
                    ))}
                  </td>

                  <td className="text-center">
                    <a
                      style={{}}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => showViewModal(ResourcesData)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Display2.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                  {PremissionChecker(
                    user,
                    "student.update_student_resources"
                  ) ? (
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          getEditResources(ResourcesData?.id);
                          setEditResourcesID(ResourcesData?.id);
                          setEditResourcesModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary ">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}

                  {PremissionChecker(
                    user,
                    "student.delete_student_resources"
                  ) ? (
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setDeleteableResources(ResourcesData?.id);
                          setDeleteResourcesModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row justify-content-center">
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>

          <Pagination
            color="primary"
            count={count}
            size="large"
            page={page}
            variant="text"
            shape="round"
            onChange={handleChange}
          />
        </div>

        {/* ------------------------Student Resources Start----------------------- */}

        {/* Add Student Resources */}
        <Modal show={addModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add Student Resources
            </Modal.Title>
          </Modal.Header>

          {addLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Student Resources Adding Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div className="row ">
                <div className="col-md-6">
                  <label>
                    <b>
                      Select Campus
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>

                  <select
                    style={{ height: 40 }}
                    className="form-select form-control"
                    name="campusId"
                    aria-label="Default select example"
                    onChange={(e) => setCampusId(e.target.value)}
                  >
                    <option value="">Select Campus</option>
                    {campusData &&
                      campusData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label>
                    <b>Select Classes</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>

                  <MySelect
                    options={Options}
                    isMulti
                    name="selectedOptions"
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleChangeClasses}
                    allowSelectAll={true}
                    value={optionSelected}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label>
                    <b>Enter Student Resources Title</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => setTtile(e.target.value)}
                    name="resources-title"
                    placeholder="Enter Student Resources Title"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <label>
                    <b>Add Files</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    name="files"
                    accept=".pdf"
                    multiple
                  />
                  <div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <span
                            className="svg-icon svg-icon-md cursor-pointer ml-1"
                            onClick={() => removeImage(file)}
                          >
                            <MdDelete color="#F64E60" />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={addResourcesHandler}
              disabled={addLoading ? true : false}
            >
              Add Student Resources
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setAddModal(false);
                setisallfields(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Student Resources */}
        <Modal show={editResourcesModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Student Resources
            </Modal.Title>
          </Modal.Header>

          {editResourcesLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Resources Updating Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div className="row ">
                <div className="col-md-6">
                  <label>
                    <b>
                      Select Campus
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>

                  <select
                    style={{ height: 40 }}
                    className="form-select form-control"
                    name="campusId"
                    aria-label="Default select example"
                    value={editCampusId}
                    onChange={(e) => setEditCampusId(e.target.value)}
                  >
                    <option value="">Select Campus</option>
                    {campusData &&
                      campusData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label>
                    <b>Select Classes</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>

                  <MySelect
                    options={Options}
                    isMulti
                    name="selectedOptions"
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleEditClasses}
                    allowSelectAll={true}
                    value={editOptionSelected}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label>
                    <b>Enter Student Resources Title</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                    name="resources-title"
                    placeholder="Enter Student Resources Title"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <label>
                    <b>Add Files</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    name="files"
                    accept=".pdf"
                    multiple
                  />
                  <div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <span
                            className="svg-icon svg-icon-md cursor-pointer ml-1"
                            onClick={() => removeImage(file)}
                          >
                            <MdDelete color="#F64E60" />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={updateResourcesHandler}
              disabled={editResourcesLoading ? true : false}
            >
              Update Student Resources
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setEditResourcesModal(false);
                setisallfields(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Student Resources */}
        <Modal show={deleteResourcesModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this student resources?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Student Resource Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={deleteResourcesHandle}
                  className="btn button_blue"
                >
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setDeleteResourcesModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>

        {/* Show Student Resources */}
        <Modal onHide={() => setShowModal(false)} size="lg" show={showModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              View Student Resources
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <div className="d-flex">
                <h5>
                  <span className="text-primary"> Title:- </span>{" "}
                  {selectedResources.title}
                </h5>
              </div>
              <div className="d-flex mt-4">
                <h5>
                  <span className="text-primary"> Classes:- </span>{" "}
                  {selectedResources?.student_classes?.length > 0
                    ? selectedResources.student_classes
                        .map((className) => className.student_class?.name)
                        .join(", ")
                    : null}
                </h5>
              </div>
              <div className="d-flex mt-2">
                <h5>
                  <span className="text-primary"> Files:- </span>{" "}
                  {selectedResources?.files?.map((file, fileIndex) => (
                    <ul>
                      <li>
                        <span>{file?.file}</span>
                        <a
                          key={fileIndex}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          href={`${baseUrl.replace(
                            "/api",
                            ""
                          )}/storage/student/resources/${file?.file}`}
                          download={file?.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-success">
                            <FaFileDownload />
                          </span>
                        </a>
                      </li>
                    </ul>
                  ))}
                </h5>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        {/* ------------------------Student Resources End----------------------- */}
      </Paper>
    </div>
  );
};

export default StudentResources;
