import { useSelector } from "react-redux";
import React from 'react'
import axios from "axios";
function MyAuthToken() {
    let authtoken = useSelector(state => state.user.authtoken)
    if (!authtoken) {
       authtoken= localStorage.getItem('userToken');
    }
    // axios.defaults.headers.common['Authorization'] = authtoken;
    return authtoken
}
export default MyAuthToken