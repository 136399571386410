import React, { useRef } from "react";
import "./printChallan.style.css";
import { Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";

const Logo =
  "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";

const PrintChallanModal = ({ show, hideModal }) => {
  const onBeforeGetContentResolve = React.useRef(null);
  const componentRef = useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  return (
    <Modal ref={componentRef} show={show} onHide={hideModal} size="xl">
      <div ref={componentRef}>
        <div
          //  style={{ width: '612px', height: '791px' }}
          className="my-print"
        >
          {/* bank copy */}
          <div className=" test my__print__1">
            <div className="text-center">
              <b>HBL A/c No 143279616161616</b>
              <br></br>
              <b>Bank Alhabib 14327-961616-161-6-01</b>
            </div>
            <h6 className="text-center">Abu Bakar Campus</h6>
            {/* form text content */}
            <div className="text-form">
              <div className="left-content">
                <ul className="list-left">
                  <li>
                    <b>Due Date:</b>
                  </li>
                  <li>
                    <b>Issue Date:</b>
                  </li>
                  <li>
                    <b>Admission:</b>
                  </li>
                  <li>
                    <b style={{ height: 27 }}>Name:</b>
                  </li>
                  <li>
                    <b>Class:</b>
                  </li>
                </ul>
              </div>
              <div className="right-content">
                <ul className="list-right">
                  <li>2022-8-26</li>
                  <li>2022-8-26</li>
                  <li className="BAL">
                    <b>BAL-78</b>
                  </li>
                  <li>
                    <p style={{ height: 27 }}>
                      Muslim Hussain Mahdi <br></br> s/o Muhammad Ijaz
                    </p>
                  </li>
                  <li>9th (Abu Huraira)</li>
                </ul>
              </div>
            </div>

            <div className="print-copy">
              <table className="print__table">
                <tr>
                  <th>Inv No</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td>642144</td>
                  <td>Monthly fee sep 2022</td>

                  <td>4432</td>
                </tr>
              </table>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-center">
                {" "}
                <h3>Total Amount</h3>
              </div>

              <div style={{ marginLeft: 20 }}>
                <b
                  className="btn btn-white"
                  style={{ border: "1px solid black" }}
                >
                  4432
                </b>
              </div>
            </div>
          </div>
          {/* office copy */}
          <div className=" test my__print__2">
            {/* form text content */}
            <div className="text-form">
              <div className="left-content">
                <ul className="list-left">
                  <li>Due Date:</li>
                  <li>Issue Date:</li>
                  <li>Admission:</li>
                  <li>
                    <p style={{ height: 27 }}>Name:</p>
                  </li>
                  <li>Class:</li>
                </ul>
              </div>
              <div className="right-content">
                <ul className="list-right">
                  <li>2022-8-26</li>
                  <li>2022-8-26</li>
                  <li className="BAL">BAL-78</li>
                  <li>
                    <p style={{ height: 27 }}>
                      Muslim Hussain Mahdi s/o Muhammad Ijaz
                    </p>
                  </li>
                  <li>9th (Abu Huraira)</li>
                </ul>
              </div>
            </div>

            <div className="print-copy">
              <table className="print__table">
                <tr>
                  <th>Inv No</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td>642144</td>
                  <td>Monthly fee sep 2022</td>

                  <td>4432</td>
                </tr>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: 10,
                paddingLeft: 20,
              }}
            >
              <div className="text-center">
                {" "}
                <h3>Total Amount</h3>
              </div>

              <div style={{ marginLeft: 20 }}>
                <b
                  className="btn btn-white"
                  style={{ border: "1px solid black" }}
                >
                  4432
                </b>
              </div>
            </div>
          </div>
          {/* student copy */}

          <div className=" test my__print__3">
            {/* form text content */}
            <div className="text-form">
              <div className="left-content">
                <ul className="list-left">
                  <li>Due Date:</li>
                  <li>Issue Date:</li>
                  <li>Admission:</li>
                  <li>
                    <p style={{ height: 27 }}>Name:</p>
                  </li>
                  <li>Class:</li>
                </ul>
              </div>
              <div className="right-content">
                <ul className="list-right">
                  <li>2022-8-26</li>
                  <li>2022-8-26</li>
                  <li>BAL-78</li>
                  <li>
                    <p style={{ height: 27 }}>
                      Muslim Hussain Mahdi s/o Muhammad Ijaz
                    </p>
                  </li>
                  <li>9th (Abu Huraira)</li>
                </ul>
              </div>
            </div>

            <div className="print-copy">
              <table className="print__table">
                <tr>
                  <th>Inv No</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td>642144</td>
                  <td>Monthly fee sep 2022</td>

                  <td>4432</td>
                </tr>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: 10,
                paddingLeft: 20,
              }}
            >
              <div className="text-center">
                {" "}
                <h3>Total Amount</h3>
              </div>

              <div style={{ marginLeft: 20 }}>
                <b
                  className="btn btn-white"
                  style={{ border: "1px solid black" }}
                >
                  4432
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactToPrint
        documentTitle="Challan Copy"
        onAfterPrint={handleAfterPrint}
        onBeforeGetContent={handleOnBeforeGetContent}
        onBeforePrint={handleBeforePrint}
        removeAfterPrint={true}
        trigger={() => (
          <button className="btn btn-success button_blue button_blue">
            Print
          </button>
        )}
        content={() => componentRef.current}
      />
    </Modal>
  );
};

export default PrintChallanModal;
