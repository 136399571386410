import { Paper } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { baseUrl } from '../../../../services/config'
import { useEffect } from 'react'
import { Spinner, Table } from 'react-bootstrap'

const StudentProgress = () => {
    const [reportType,setReportType]=useState("detail")
    const [selected, setSelected] = useState("session");
    const [yearId, setYearId] = useState("");
    const [campusId, setCampusId] = useState("");
    const [classId, setClassId] = useState("");
    const [sessionData, setSessionData] = useState([]);
    const [campusData, setCampusData] = useState([]);
    const [classesData, setClassesData] = useState([]);
  const [session, setSession] = useState(true);
  const [loading, setLoading] = useState(false);
  const [campus, setCampus] = useState(false);
    
    let authtoken = localStorage.getItem("userToken");


    useEffect(() => {
        sessions();
        getAllCampus();
      }, []);



    const sessions = () => {
        fetch(`${baseUrl}/session`, {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setSessionData(data.payload);
            data.payload.forEach((session, index) => {
              if (session.active_academic_year == "1") {
                setYearId(session.id);
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const getAllCampus = async () => {
        await fetch(`${baseUrl}/campus`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setCampusData(data.payload);
          })
          .catch((err) => {
            console.log("vehicle", err);
          });
      };
      const onChampusChange = (id) => {
        setCampusId(id);
        setClassId("")
        fetch(`${baseUrl}/campusclass/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.payload) {
              setClassesData(data.payload);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };


    const onReportClick=(e)=>{
        setReportType(e.target.value)
    }
    const onRadioButtonClick = (e) => {
        const value = e.target.value;
    
        console.log(value);
        setCampusId("");
        setClassId("");
        setSelected(e.target.value);
        if (value == "session") {
            setCampus(false);
            setSession(true);
  
        }
        if (value == "campus") {
            setCampus(true);
            setSession(false);

        }
        if (value == "class") {
            setCampus(false);
            setSession(false);

        }
    
      };
  return (
    <>
        <Paper
          style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
        >
     <div className='row'>
        <div className="col-sm-6 text-center">
                  <input
                    checked={reportType == "detail"}
                    onChange={(e) => onReportClick(e)}
                    defaultChecked
                    type="radio"
                    id="detail"
                    value="detail"
                  />
                  <label for="detail">Detailed Report</label>
                </div>

                <div className="col-sm-6 text-center">
                  <input
                    checked={reportType == "summary"}
                    onChange={(e) => onReportClick(e)}
                    type="radio"
                    id="summary"
                    value="summary"
                  />
                  <label for="summary">Summary Report</label>
                </div>
        </div>
        <div className="row mt-3">
                <div className="col-sm-4 text-center">
                  <input
                    checked={selected == "session"}
                    onChange={(e) => onRadioButtonClick(e)}
                    defaultChecked
                    type="radio"
                    id="Session"
                    value="session"
                  />
                  <label for="Session">Session Wise Due Fee</label>
                </div>

                <div className="col-sm-4 text-center">
                  <input
                    checked={selected == "campus"}
                    onChange={(e) => onRadioButtonClick(e)}
                    type="radio"
                    id="Campus"
                    value="campus"
                  />
                  <label for="Campus">Campus Wise Due Fee</label>
                </div>

                <div className="col-sm-4 text-center">
                  <input
                    checked={selected == "class"}
                    onChange={(e) => onRadioButtonClick(e)}
                    type="radio"
                    id="Class"
                    value="class"
                  />
                  <label for="Class">Class Wise Due Fee</label>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-4">
                  <label><b>Select Session</b></label>
                  <div className="d-flex flex-column ">
                    <select
                      style={{ height: 40 }}
                      className="form-select"
                      name="year_id"
                      value={yearId}
                      aria-label="Default select example"
                      onChange={(e) => setYearId(e.target.value)}
                    >
                      {sessionData &&
                        sessionData.map((item) => (
                          <option value={item.id}>{item.year}</option>
                        ))}
                    </select>
                  </div>

                </div>

                {session ? null : (
                  <div className="col-sm-4">
                    <label><b>Select Campus</b></label>
                    <div className="d-flex flex-column">
                      <select
                        style={{ height: 40 }}
                        className="form-select"
                        name="year_id"
                        aria-label="Default select example"
                        onChange={(e) => onChampusChange(e.target.value)}
                      >
                        <option selected value="">
                          {" "}
                          Select Campus
                        </option>
                        {campusData &&
                          campusData.map((item) => (
                            <option value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                {/* Select Exam */}

                {session || campus ? null :  (
                    
                  <div className="col-sm-4">
                    <label><b>Select Class</b></label>
                    <div className="d-flex flex-column">
                      <select
                        style={{ height: 40 }}
                        className="form-select"
                        name="campus_id"
                        value={classId}
                        aria-label="Default select example"
                        onChange={(e) => setClassId(e.target.value)}
                      >
                        <option selected value="">
                          {" "}
                          Select Class
                        </option>
                        {classesData &&
                          classesData.map((item) => (
                            <>
                              <option value={item.id}>
                                {item.name}
                              </option>
                            </>
                          ))}
                      </select>
                    </div>
                  </div>
                )
            }

              </div>
              <div className='row mt-2'>
                   <div className='col-md-2'>
                        <button className='btn btn-primary'>Show</button>
                   </div>
              </div>


              {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>Sr</th>
                      <th>Adm-Id</th>
                      <th>Section</th>
                      <th>Student Name</th>
                      <th>Mobile No</th>
                      <th>Total Month</th>
                      <th>Fee Paid</th>
                      <th>Fee Unpaid</th>
                    </tr>
                  </thead>

                  <tbody>
                    {/* {!!studentsData &&
                      studentsData.map((student, index) => ( */}
                        <tr id="tbl">
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {/* {student.admission_id} */}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {/* {student.name} */}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {/* {student.mobile_no} */}
                          </td>

                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {/* {student?.student_class?.name} */}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {/* {student?.global_section?.name} */}
                          </td>

                          <td>
                            {/* {student.fee_challans.map((item, i) => ( */}
                              <>
                                <span
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {/* {i == "0" ? "" : ","}{" "} */}
                                  {/* {getMonthName(item.due_date)} */}
                                  {/* {getMonthName(item.fee_challan_details[0].fee_month)} */}

                                </span>
                              </>
                            {/* ))} */}
                          </td>


                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {/* {student?.fee_challans.length} */}
                          </td>

                          <td style={{ color: "black" }}>
                            {/* {getFeeSum(student.fee_challans)} */}
                          </td>
                        </tr>
                      {/* ))} */}
                  </tbody>
                </Table>
              </div>
            )}
        </Paper>
    </>
  )
}

export default StudentProgress