import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../../../services/config";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { Table, Modal, Alert } from "react-bootstrap";
import { Input, Select } from "../../../../../_metronic/_partials/controls";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import ViewStafStudents from "../ViewStafStudents/ViewStafStudents";
import { PremissionChecker } from "../../../../../services/PremissionChecker";
const EditAppointedStaff = ({ staffDetails, show, reload, studentShow }) => {
  console.log("edit staff details", staffDetails);
  const cnicNumberMask = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
  ];
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const user = useSelector((state) => state.user.user);
  //input states
  const [studentView, setStudentView] = useState(false);
  const [studentDetails, setStudentDetails] = useState([]);
  const [isallfields, setisallfields] = useState(false);
  const [showerror, setshowerror] = useState("Please Add All Fields");
  const [applied_for, setapplied_for] = useState(staffDetails.designation_id);
  const [full_name, setfull_name] = useState(staffDetails.full_name);
  const [father_name, setfather_name] = useState(staffDetails.father_name);
  const [nationality, setnationality] = useState(staffDetails.nationality);
  const [religion, setreligion] = useState(staffDetails.religion);
  const [experience, setexperience] = useState(staffDetails.experience);
  const [cnic_no, setcnic_no] = useState(staffDetails.cnic_no);
  const [campusData, setCampusData] = useState([]);

  const [qualification, setqualification] = useState(
    staffDetails.qualification
  );
  const [gender, setgender] = useState(staffDetails.gender);
  const [marital_status, setmarital_status] = useState(
    staffDetails.marital_status
  );
  const [dob, setdob] = useState(staffDetails.dob);
  const [remarks, setremarks] = useState(staffDetails.remarks);
  const [mobile_no, setmobile_no] = useState(staffDetails.mobile_no);
  const [phone, setphone] = useState(staffDetails.phone);
  const [address, setaddress] = useState(staffDetails.address);
  const [email, setemail] = useState(staffDetails.email);
  const [social_security_number, setsocial_security_number] = useState(
    staffDetails.social_security_number
  );
  const [eobi_no, seteobi_no] = useState(staffDetails.eobi_no);
  const [field_of_interest, setfield_of_interest] = useState(
    staffDetails.field_of_interest
  );
  const [distinctions, setdistinctions] = useState(staffDetails.distinctions);
  const [objectives, setobjectives] = useState(staffDetails.objectives);
  const [duties_assigned, setduties_assigned] = useState(
    staffDetails.duties_assigned
  );
  const [global_bank_id, setglobal_bank_id] = useState(
    staffDetails.global_bank_id
  );
  const [account_no, setaccount_no] = useState(staffDetails.account_no);
  const [pay_scale_id, setpay_scale_id] = useState(staffDetails.pay_scale_id);
  const [payment_type, setpayment_type] = useState(staffDetails.payment_type);
  const [salery_days, setsalery_days] = useState(staffDetails.salery_days);
  const [joining_date, setjoining_date] = useState(staffDetails.joining_date);
  const [account_head, setaccount_head] = useState(staffDetails.account_head);
  const [job_status_id, setjob_status_id] = useState(
    staffDetails.job_status_id
  );
  const [leaving_date, setleaving_date] = useState(staffDetails.leaving_date);

  //STATES
  const [designations, setdesignations] = useState([]);
  const [AllPayScales, setAllPayScales] = useState([]);
  const [bankList, setbankList] = useState([]);
  const [jobstatuses, setjobstatuses] = useState([]);

  //New States

  const [newBank, setNewBank] = useState([]);
  const dispatch = useDispatch();
  const [recevingdate, setrecevingdate] = useState();
  const [allbanks, setallbanks] = useState([]);
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedbankcateogry, setselectedbankcateogry] = useState(
    staffDetails?.bank_account?.bank_account_category?.id
  );
  // const [selectedbankcateogry, setselectedbankcateogry] = useState(useSelector((state) => state.campuses.selectedbankcategory_red));
  // const [selectedbank, setselectedbank] = useState(
  //   useSelector((state) => state.campuses.selectedbank_red)
  // );
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );

  console.log(staffDetails, "staffDetails=>");

  // selectedbank_red
  useEffect(() => {
    // console.log("pakistan")
    // if(selectedbank !=''){
    //     console.log("pakistani")
    //     feerecevingbank(selectedbank)
    // }

    getAllBanks();
    getAllCampus();
    // getAlSubAccountsList()
  }, []);
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const getAllBanks = () => {
    console.log(red_recevingdate);
    if (red_recevingdate != null && red_recevingdate != undefined) {
      setrecevingdate(red_recevingdate);
    }

    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const showStudents = (staffDetails) => {
    setStudentView(true);
  };
  const initialValues = {
    isformsubmitted: false,
    campus_id: staffDetails.campus_id,
    applied_for: staffDetails?.designation_id
      ? staffDetails?.designation_id
      : "",
    full_name: staffDetails?.full_name ? staffDetails?.full_name : "",
    father_name: staffDetails?.father_name ? staffDetails?.father_name : "",
    nationality: staffDetails?.nationality ? staffDetails?.nationality : "",
    religion: staffDetails?.religion ? staffDetails?.religion : "",
    experience: staffDetails?.experience ? staffDetails?.experience : "",
    cnic_no: staffDetails?.cnic_no ? staffDetails?.cnic_no : "",
    qualification: staffDetails?.qualification
      ? staffDetails?.qualification
      : "",
    gender: staffDetails?.gender ? staffDetails?.gender : "",
    marital_status: staffDetails?.marital_status
      ? staffDetails?.marital_status
      : "",
    dob: staffDetails?.dob ? staffDetails?.dob : "",
    remarks: staffDetails?.remarks ? staffDetails?.remarks : "",
    mobile_no: staffDetails?.mobile_no ? staffDetails?.mobile_no : "",
    phone: staffDetails?.phone ? staffDetails?.phone : "",
    address: staffDetails?.address ? staffDetails?.address : "",
    email: staffDetails?.email ? staffDetails?.email : "",
    social_security_number: staffDetails?.social_security_number
      ? staffDetails?.social_security_number
      : "",
    eobi_no: staffDetails?.eobi_no ? staffDetails?.eobi_no : "",
    // pay_scale_id: staffDetails.pay_scale_id,
    field_of_interest: staffDetails?.field_of_interest
      ? staffDetails?.field_of_interest
      : "",

    job_status_id: staffDetails?.job_status_id
      ? staffDetails?.job_status_id
      : "",
    leaving_date: staffDetails?.leaving_date ? staffDetails?.leaving_date : "",

    distinctions: staffDetails?.distinctions ? staffDetails?.distinctions : "",
    objectives: staffDetails?.objectives ? staffDetails?.objectives : "",
    duties_assigned: staffDetails?.duties_assigned
      ? staffDetails?.duties_assigned
      : "",
    // global_bank_id: staffDetails.global_bank_id,
    account_no: staffDetails?.account_no ? staffDetails?.account_no : "",
    // payment_type: staffDetails.payment_type,
    joining_date: staffDetails?.joining_date ? staffDetails?.joining_date : "",

    // account_head: staffDetails.account_head,
    salery_days: staffDetails?.salery_days ? staffDetails?.salery_days : "",
    bank_account_id: staffDetails?.bank_account_id
      ? staffDetails?.bank_account_id
      : "",
  };

  if (
    staffDetails.phone?.charAt(0) == "+" &&
    staffDetails.phone?.charAt(1) == "9" &&
    staffDetails.phone?.charAt(2) == "2"
  ) {
    initialValues.phone = staffDetails.phone.substring(3);
  }
  if (
    staffDetails.mobile_no?.charAt(0) == "+" &&
    staffDetails.mobile_no?.charAt(1) == "9" &&
    staffDetails.mobile_no?.charAt(2) == "2"
  ) {
    initialValues.mobile_no = staffDetails.mobile_no.substring(3);
  }
  console.log(initialValues);

  // if (
  //    staffDetails?.phone.charAt(0) == "+" &&
  //   staffDetails?.phone.charAt(1) == "9" &&
  //   staffDetails?.phone.charAt(2) == "2"
  // ) {
  //   initialValues.phone = staffDetails?.phone.substring(3);
  // }
  // if (
  //   staffDetails?.mobile_no.charAt(0) == "+" &&
  //   staffDetails?.mobile_no.charAt(1) == "9" &&
  //   staffDetails?.mobile_no.charAt(2) == "2"
  // ) {
  //   initialValues.mobile_no = staffDetails.mobile_no.substring(3);
  // }

  const StaffRegistrationSchema = Yup.object().shape({
    applied_for: Yup.string().required("Applied For is required"),

    full_name: Yup.string().required("Full name is required"),

    father_name: Yup.string().required("Father Name required field"),
    campus_id: Yup.string().required("Campus is required "),
    nationality: Yup.string().notRequired("Nationality is required field"),
    religion: Yup.string().notRequired("Religion Is Required"),
    cnic_no: Yup.string()
      .required("Cnic No required field")
      .matches(
        /^[0-9]{5}-[0-9]{7}-[0-9]$/,
        "CNIC No must follow the XXXXX-XXXXXXX-X format!"
      ),

    experience: Yup.string().notRequired("Experience is required field"),
    qualification: Yup.string().notRequired("Qualification Is Required"),

    gender: Yup.string().required("Gender Is Required"),
    marital_status: Yup.string().required("Marital Status Is Required"),

    dob: Yup.string().notRequired("DOB Is Required"),
    remarks: Yup.string().notRequired("Remarks required field"),

    phone: Yup.string()
      .notRequired("phone required field")
      .matches(
        /^(3)([0-9]{9})$/,
        "Phone Number  Must start with 3 and must have 13 digits"
      ),
    mobile_no: Yup.string()
      .notRequired("Mobile Number required field")
      .matches(
        /^(3)([0-9]{9})$/,
        "Mobile Number Must start with 3 and must have 13 digits"
      ),
    address: Yup.string().required("Address Is Required"),

    email: Yup.string()
      .notRequired("E-Mail Is Required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please Add valid email Schema"
      ),
    social_security_number: Yup.string().notRequired(
      "Social Security Number required field"
    ),

    // pay_scale_id: Yup.string().required("Pay Scale required field"),
    field_of_interest: Yup.string().notRequired(
      "Field Of Interest required field"
    ),
    job_status_id: Yup.string().notRequired("Job Status required field"),
    // leaving_date: Yup.string().required("Leaving Date required field"),
    leaving_date: Yup.string().when("job_status_id", {
      is: (val) => ![1, 5].includes(parseInt(val)),
      then: Yup.string().required("Leaving date is required"),
      otherwise: Yup.string().notRequired(),
    }),

    distinctions: Yup.string().notRequired("Distinctions required field"),
    objectives: Yup.string().notRequired("Objectives required field"),
    duties_assigned: Yup.string().notRequired("Duties Assigned required field"),
    // global_bank_id: Yup.string().required("Bank required field"),
    // payment_type: Yup.string().required("Payment Type required field"),
    joining_date: Yup.string().required("Joining Date required field"),
    // account_head: Yup.string().required("Account Head required field"),
    salery_days: Yup.string()
      .notRequired("Salary Days required field")
      .matches(/^[0-9]*$/, "please Add Valid data"),
    account_no: Yup.string().notRequired(" Account No is required field"),
    bank_account_id: Yup.string().notRequired(
      "Bank Account  is required field"
    ),
  });

  const createEroors = (errors, touched, isSubmitting, values) => {
    console.log("hklooo");
    setisallfields(false);

    if (errors.applied_for && touched.applied_for) {
      setshowerror(errors.applied_for);
      setisallfields(true);
      return;
    }
    if (errors.campus_id && touched.campus_id) {
      setshowerror(errors.campus_id);
      setisallfields(true);
      return;
    }

    if (errors.full_name && touched.full_name) {
      setshowerror(errors.full_name);
      setisallfields(true);
      return;
    }

    if (errors.father_name && touched.father_name) {
      setshowerror(errors.father_name);
      setisallfields(true);
      return;
    }

    if (errors.nationality && touched.nationality) {
      setshowerror(errors.nationality);
      setisallfields(true);
      return;
    }

    if (errors.religion && touched.religion) {
      setshowerror(errors.religion);
      setisallfields(true);
      return;
    }
    if (errors.experience && touched.experience) {
      setshowerror(errors.experience);
      setisallfields(true);
      return;
    }
    if (errors.gender && touched.gender) {
      setshowerror(errors.gender);
      setisallfields(true);
      return;
    }

    if (errors.marital_status && touched.marital_status) {
      setshowerror(errors.marital_status);
      setisallfields(true);
      return;
    }

    if (errors.dob && touched.dob) {
      setshowerror(errors.dob);
      setisallfields(true);
      return;
    }

    if (errors.remarks && touched.remarks) {
      setshowerror(errors.remarks);
      setisallfields(true);
      return;
    }
    if (errors.mobile_no && touched.mobile_no) {
      setshowerror(errors.mobile_no);
      setisallfields(true);
      return;
    }
    if (errors.phone && touched.phone) {
      setshowerror(errors.phone);
      setisallfields(true);
      return;
    }

    if (errors.address && touched.address) {
      setshowerror(errors.address);
      setisallfields(true);
      return;
    }

    if (errors.email && touched.email) {
      setshowerror(errors.email);
      setisallfields(true);
      return;
    }
    if (errors.social_security_number && touched.social_security_number) {
      setshowerror(errors.social_security_number);
      setisallfields(true);
      return;
    }

    // if (errors.pay_scale_id && touched.pay_scale_id) {
    //   setshowerror(errors.pay_scale_id);
    //   setisallfields(true);
    //   return;
    // }
    if (errors.field_of_interest && touched.field_of_interest) {
      setshowerror(errors.field_of_interest);
      setisallfields(true);
      return;
    }
    if (errors.job_status_id && touched.job_status_id) {
      setshowerror(errors.job_status_id);
      setisallfields(true);
      return;
    }

    if (errors.leaving_date && touched.leaving_date) {
      setshowerror(errors.leaving_date);
      setisallfields(true);
      return;
    }

    if (errors.distinctions && touched.distinctions) {
      setshowerror(errors.distinctions);
      setisallfields(true);
      return;
    }

    if (errors.objectives && touched.objectives) {
      setshowerror(errors.objectives);
      setisallfields(true);
      return;
    }
    if (errors.duties_assigned && touched.duties_assigned) {
      setshowerror(errors.duties_assigned);
      setisallfields(true);
      return;
    }
    // if (errors.global_bank_id && touched.global_bank_id) {
    //   setshowerror(errors.global_bank_id);
    //   setisallfields(true);
    //   return;
    // }

    if (errors.bank_account_id && touched.bank_account_id) {
      setshowerror("Bank Account Is required");
      setisallfields(true);
      return;
    }
    if (errors.account_no && touched.account_no) {
      setshowerror("Account Number Is required");
      setisallfields(true);
      return;
    }
    // if (errors.payment_type && touched.payment_type) {
    //   setshowerror(errors.payment_type);
    //   setisallfields(true);
    //   return;
    // }

    if (errors.salery_days && touched.salery_days) {
      setshowerror(errors.salery_days);
      setisallfields(true);
      return;
    }
    if (
      (values.salery_days != "" && parseInt(values.salery_days) >= 255) ||
      parseInt(values.salery_days) < 0
    ) {
      setisallfields(true);
      setshowerror("Please Deine Salary Days Between 0 and 255");
      return;
    }
    if (errors.joining_date && touched.joining_date) {
      setshowerror(errors.joining_date);
      setisallfields(true);
      return;
    }

    if (errors.cnic_no && touched.cnic_no) {
      setshowerror(errors.cnic_no);
      setisallfields(true);
      return;
    }
    // if (errors.account_head && touched.account_head) {
    //   setshowerror(errors.account_head);
    //   setisallfields(true);
    //   return;
    // }
    console.log(Object.keys(errors));

    if (Object.keys(errors).length === 0) {
      console.log("pakistanii");
      if (values.full_name == "") {
        console.log("pakistan");
      } else {
        console.log("pakistan is good");
        console.log(isSubmitting);
        if (isSubmitting) {
          let regu = /^(3)([0-9]{9})$/;

          // if (!regu.test(values.phone)) {
          //   setshowerror(
          //     "Landline Number Must start with 3 and must have 13 digits"
          //   );
          //   setisallfields(true);
          //   return;
          // }
          // if (!regu.test(values.mobile_no)) {
          //   setshowerror(
          //     "Mobile Number Must start with 3 and must have 13 digits"
          //   );
          //   setisallfields(true);
          //   return;
          // }
          console.log("pakistan is");
          if (!values.isformsubmitted) {
            values.isformsubmitted = true;
            UpdateStaff(values);
          }
        }
      }
    }
  };

  useEffect(() => {
    getAlldesignations();
    getAllPayScales();
    getBankList();
    getJobStatus();
    // getAllsession()
  }, []);

  const getBankList = () => {
    fetch(`${baseUrl}/bank`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setbankList(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobStatus = () => {
    fetch(`${baseUrl}/jobstatuses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setjobstatuses(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllPayScales = () => {
    fetch(`${baseUrl}/payscales`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setAllPayScales(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAlldesignations = () => {
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(banks)
        setdesignations(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const errorsCheck = () => {
    if (applied_for == "") {
      setisallfields(true);
      setshowerror("Please add Possition");
      return false;
    }
    if (full_name == "") {
      setisallfields(true);
      setshowerror("Please add Full Name");
      return false;
    }
    if (father_name == "") {
      setisallfields(true);
      setshowerror("Please add Father Name");
      return false;
    }
    if (nationality == "") {
      setisallfields(true);
      setshowerror("Please add Nationality");
      return false;
    }
    if (religion == "") {
      setisallfields(true);
      setshowerror("Please add religion");
      return false;
    }
    if (experience == "") {
      setisallfields(true);
      setshowerror("Please add Your Experience");
      return false;
    }
    if (cnic_no == "") {
      setisallfields(true);
      setshowerror("Please add CNIC");
      return false;
    }
    if (qualification == "") {
      setisallfields(true);
      setshowerror("Please add Qualification");
      return false;
    }

    if (gender == "") {
      setisallfields(true);
      setshowerror("Please Select Gender");
      return false;
    }
    if (marital_status == "") {
      setisallfields(true);
      setshowerror("Please add Your Marital Status");
      return false;
    }
    if (dob == "") {
      setisallfields(true);
      setshowerror("Please add Your Date Of Birth");
      return false;
    }
    if (remarks == "") {
      setisallfields(true);
      setshowerror("Please add Your Remarks");
      return false;
    }

    if (mobile_no == "") {
      setisallfields(true);
      setshowerror("Please add Your Mobile No");
      return false;
    }
    if (phone == "") {
      setisallfields(true);
      setshowerror("Please add Your Phone No");
      return false;
    }
    if (address == "") {
      setisallfields(true);
      setshowerror("Please add Your Address");
      return false;
    }
    if (email == "") {
      setisallfields(true);
      setshowerror("Please add Your Email");
      return false;
    } else {
      var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = String(email).search(filter) != -1;

      if (res == false) {
        setisallfields(true);
        setshowerror("Please Enter valid  Email");
        return false;
      }
    }

    if (social_security_number == null) {
      setisallfields(true);
      setshowerror("Please add Your Social Security Number");
      return false;
    } else {
      console.log(
        social_security_number.length,
        "social_security_number.length"
      );
      if (social_security_number.length < 5) {
        setisallfields(true);
        setshowerror("Social Security Number Must Be Atleast 5 Charecters");
        return false;
      }
    }
    if (pay_scale_id == "") {
      setisallfields(true);
      setshowerror("Please Select Your Pay Scale");
      return false;
    }
    if (field_of_interest == "") {
      setisallfields(true);
      setshowerror("Please Add Your Field OF Interest");
      return false;
    }
    if (job_status_id == "") {
      setisallfields(true);
      setshowerror("Please Select Your Job Status");
      return false;
    }
    if (leaving_date == "") {
      setisallfields(true);
      setshowerror("Please Add Leaving Date");
      return false;
    }
    if (distinctions == "") {
      setisallfields(true);
      setshowerror("Please Add Your Distinctions");
      return false;
    }
    if (objectives == "") {
      setisallfields(true);
      setshowerror("Please Add Your Objectives");
      return false;
    }
    if (duties_assigned == "") {
      setisallfields(true);
      setshowerror("Please Add Your Assigned Duties");
      return false;
    }
    if (global_bank_id == "") {
      setisallfields(true);
      setshowerror("Please Select Bank");
      return false;
    }
    if (account_no == "") {
      setisallfields(true);
      setshowerror("Please Add Your Account Number");
      return false;
    }
    // if (payment_type == "") {
    //   setisallfields(true);
    //   setshowerror("Please Select Payment Type");
    //   return false;
    // }
    if (salery_days == "") {
      setisallfields(true);
      setshowerror("Please Add Salary days");
      return false;
    }
    if (joining_date == "") {
      setisallfields(true);
      setshowerror("Please Add Joining Date");
      return false;
    }
    if (account_head == "") {
      setisallfields(true);
      setshowerror("Please Add Account Head");
      return false;
    }

    return true;
  };

  const UpdateStaff = (values) => {
    // let res = errorsCheck();

    // if (res == false) {
    //     return;
    // }
    setisallfields(false);
    setloading(true);
    const data = {
      campus_id: values.campus_id,
      designation_id: values.applied_for,
      full_name: values.full_name,
      father_name: values.father_name,
      nationality: values.nationality,
      religion: values.religion,
      experience: values.experience,
      cnic_no: values.cnic_no,
      qualification: values.qualification,
      gender: values.gender,
      marital_status: values.marital_status,
      dob: values.dob,
      remarks: values.remarks,
      mobile_no: values.mobile_no,
      phone: values.phone,
      address: values.address,
      email: values.email,

      social_security_number: values.social_security_number,
      eobi_no: values.eobi_no,
      field_of_interest: values.field_of_interest,
      distinctions: values.distinctions,
      objectives: values.objectives,
      duties_assigned: values.duties_assigned,
      // global_bank_id: values.global_bank_id,
      account_no: values.account_no,
      // payment_type: values.payment_type,
      salery_days: values.salery_days,
      joining_date: values.joining_date,
      // account_head: values.account_head,
      job_status_id: values.job_status_id,
      leaving_date: ![1, 5].includes(parseInt(values.job_status_id))
        ? values.leaving_date
        : "",
      // pay_scale_id: values.pay_scale_id,
      bank_account_id: values.bank_account_id,
    };
    console.log(data, "sended");
    fetch(`${baseUrl}/update_emp_appointed/${staffDetails.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          setloading(false);
          console.log(data, "updatd");
          if (data.metadata) {
            if (data.metadata.success) {
              reload();
              show(false);
              alert.success("Updated Successfully");
            } else if (data.metadata.responseCode == 422) {
              const msgArray = Object.values(data.metadata.message);
              msgArray.map((err) => alert.error(err[0]));
            }
          } else if (data.metadata.responseCode == 422) {
            const msgArray = Object.values(data.metadata.message);
            msgArray.map((err) => alert.error(err[0]));
          }
        });
      })
      .catch((err) => {
        setloading(false);
        alert.error(err);
        console.log(err);
      });
  };
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  return (
    <div>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={StaffRegistrationSchema}
          onSubmit={(values) => {
            // UpdateStaff(values);
          }}
        >
          {(props) => {
            const {
              handleSubmit,
              errors,
              touched,
              values,
              isSubmitting,
              isValid,
              handleChange,
              handleBlur,
            } = props;

            // setErrorsLis(errors)
            createEroors(errors, touched, isSubmitting, values);

            return (
              <>
                <Form onSubmit={handleSubmit} className="form form-label-right">
                  <div>
                    <div class="p-3">
                      <div class="row justify-content-between text-left">
                        <div class="form-group col-sm-6 flex-column d-flex">
                          <label class="form-control-label">
                            <b>
                              Applied For{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <select
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.applied_for"
                              )
                            }
                            class="form-select form-control "
                            name="applied_for"
                            value={values.applied_for}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ backgroundColor: "white" }}
                            aria-label="Default select example"
                          >
                            <option disabled>Select designation</option>

                            {designations?.map((desi) => (
                              <option value={desi.id}>{desi.name}</option>
                            ))}
                          </select>
                          {errors.gender && touched.gender && (
                            <div
                              style={{ color: "#f87d8a" }}
                              className="input-feedback"
                            >
                              {errors.gender}
                            </div>
                          )}
                        </div>
                        <div class="form-group col-sm-6 flex-column d-flex">
                          <label class="form-control-label">
                            <b>
                              Select Campus{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <select
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.select_campus"
                              )
                            }
                            class="form-select form-control"
                            name="campus_id"
                            value={values.campus_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-label="Default select example"
                          >
                            <option selected value="">
                              Select Campus
                            </option>
                            {campusData?.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </select>
                          {errors.campus_id && touched.campus_id && (
                            <div
                              style={{ color: "#f87d8a" }}
                              className="input-feedback"
                            >
                              {errors.campus_id}
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="row justify-content-between text-left">
                        <div class="form-group col-sm-6 flex-column d-flex">
                          <label class="form-control-label">
                            <b>
                              Full Name <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.full_name"
                              )
                            }
                            maxLength={30}
                            name="full_name"
                            component={Input}
                            placeholder="Enter Your full Name"
                          />
                        </div>

                        <div class="form-group col-sm-6 flex-column d-flex">
                          <label class="form-control-label">
                            <b>
                              Father's Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.father_name"
                              )
                            }
                            maxLength={30}
                            name="father_name"
                            component={Input}
                            placeholder="Enter Your Father Name"
                          />
                        </div>

                        <div class="form-group col-sm-6 flex-column d-flex ">
                          <label class="form-control-label">
                            <b>
                              Nationality{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.nationality"
                              )
                            }
                            maxLength={30}
                            name="nationality"
                            component={Input}
                            placeholder="Enter Your Nationality"
                          />
                        </div>

                        <div class="form-group col-sm-6 flex-column d-flex ">
                          <label class="form-control-label">
                            <b>
                              Religion <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.religion"
                              )
                            }
                            maxLength={30}
                            name="religion"
                            component={Input}
                            placeholder="Enter Your Religion"
                          />
                        </div>
                      </div>

                      <div class="row justify-content-between text-left">
                        <div class="form-group col-sm-6 flex-column d-flex ">
                          <label class="form-control-label">
                            <b>
                              Experience <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.experience"
                              )
                            }
                            maxLength={30}
                            name="experience"
                            component={Input}
                            placeholder="Enter Your Experience"
                          />
                        </div>

                        <div class="form-group col-sm-6 flex-column d-flex ">
                          <label class="form-control-label">
                            <b>
                              C.N.I.C NO <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            name="cnic_no"
                            render={({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={cnicNumberMask}
                                disabled={
                                  !PremissionChecker(
                                    user,
                                    "edit_appointed_staff.cnic_no"
                                  )
                                }
                                id="cnic_no"
                                placeholder="Enter Your CNIC"
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div class="row justify-content-between text-left">
                        <div class="form-group col-sm-6 flex-column d-flex ">
                          <label class="form-control-label">
                            <b>
                              Qualification{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.qualification"
                              )
                            }
                            name="qualification"
                            component={Input}
                            placeholder="Enter Your Qualification"
                            maxLength={30}
                          />
                        </div>

                        <div class="form-group col-md-6 flex-column d-flex">
                          <label>
                            <b>Gender</b>{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <select
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.gender"
                              )
                            }
                            class="form-select form-control"
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ backgroundColor: "white" }}
                            aria-label="Default select example"
                          >
                            <option disabled selected value="">
                              Select Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.gender && touched.gender && (
                            <div
                              style={{ color: "#f87d8a" }}
                              className="input-feedback"
                            >
                              {errors.gender}
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="row justify-content-between text-left">
                        <div class="form-group col-sm-6 flex-column d-flex">
                          <label class="form-control-label">
                            <b>
                              Marital Status{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <select
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.marital_status"
                              )
                            }
                            class="form-select form-control"
                            name="marital_status"
                            value={values.marital_status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ backgroundColor: "white" }}
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Select
                            </option>
                            <option value="Married">Married</option>
                            <option value="Unmarried">Unmarried</option>
                          </select>
                          {errors.marital_status && touched.marital_status && (
                            <div
                              style={{ color: "#f87d8a" }}
                              className="input-feedback"
                            >
                              {errors.marital_status}
                            </div>
                          )}
                        </div>

                        <div class="form-group col-md-6 flex-column d-flex">
                          <label>
                            <b>Date of Birth </b>{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.dob"
                              )
                            }
                            name="dob"
                            component={Input}
                            type="date"
                            max="9999-12-30"
                          />
                        </div>
                      </div>

                      <div class="row justify-content-between text-left">
                        <div class="form-group col-sm-12 flex-column d-flex">
                          <label class="form-control-label">
                            <b>
                              Remarks <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            name="remarks"
                            component={Input}
                            placeholder="Enter Your Remarks"
                            maxLength={50}
                          />
                        </div>
                      </div>

                      <div className="text-center">
                        <h4>CONTACT INFORMATION</h4>
                      </div>
                      <div class="row justify-content-between text-left mt-3">
                        <div class="form-group col-sm-6 flex-column d-flex ">
                          <label>
                            <b>Mobile Number</b>{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.mobile_no"
                              )
                            }
                            required
                            name="mobile_no"
                            component={Input}
                            placeholder="Enter Your Smart Phone Number"
                          />
                        </div>
                        <div class="form-group col-sm-6 flex-column d-flex ">
                          <label>
                            <b>Phone </b>
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.phone_no"
                              )
                            }
                            required
                            name="phone"
                            component={Input}
                            placeholder="Enter Your Phone/LandLine No"
                          />
                        </div>

                        <div class="form-group col-sm-12 flex-column d-flex">
                          <label class="form-control-label">
                            <b>
                              Residential Address{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.address"
                              )
                            }
                            maxLength={50}
                            name="address"
                            component={Input}
                            placeholder="Address"
                          />
                        </div>

                        <div class="form-group col-sm-6 flex-column d-flex">
                          <label class="form-control-label">
                            <b>
                              E-Mail Address{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.email"
                              )
                            }
                            maxLength={30}
                            name="email"
                            component={Input}
                            placeholder="Email"
                          />
                        </div>

                        <div className="form-group col-sm-6 flex-column d-flex">
                          <label className="form-control-label">
                            <b>
                              Social security number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.social_security_number"
                              )
                            }
                            maxLength={30}
                            name="social_security_number"
                            component={Input}
                            placeholder="Social Security Number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-between text-left">
                    <div className="row">
                      <div className="form-group col-sm-6 flex-column d-flex">
                        <label className="form-control-label">
                          <b>
                            Field of interest{" "}
                            <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <Field
                          disabled={
                            !PremissionChecker(
                              user,
                              "edit_appointed_staff.field_of_interest"
                            )
                          }
                          maxLength={30}
                          name="field_of_interest"
                          component={Input}
                          placeholder="Field Of Interest"
                        />
                      </div>

                      <div className="form-group col-sm-6 flex-column d-flex">
                        <label className="form-control-label">
                          <b>
                            Job Status <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <select
                          disabled={
                            !PremissionChecker(
                              user,
                              "edit_appointed_staff.job_status"
                            )
                          }
                          class="form-select form-control"
                          name="job_status_id"
                          value={values.job_status_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          aria-label="Default select example"
                        >
                          <option disabled>Select Job Status</option>

                          {jobstatuses.map((jobs) => (
                            <option value={jobs.id}>{jobs.name}</option>
                          ))}
                        </select>
                        {errors.job_status_id && touched.job_status_id && (
                          <div
                            style={{ color: "#f87d8a" }}
                            className="input-feedback"
                          >
                            {errors.job_status_id}
                          </div>
                        )}
                      </div>

                      {![1, 5].includes(parseInt(values.job_status_id)) ? (
                        <div className="form-group col-sm-6 flex-column d-flex ">
                          <label className="form-control-label">
                            <b>
                              Leaving Date{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <Field
                            disabled={
                              !PremissionChecker(
                                user,
                                "edit_appointed_staff.leaving_date"
                              )
                            }
                            name="leaving_date"
                            component={Input}
                            type="date"
                            placeholder="leaving_date"
                            max="9999-12-30"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row justify-content-between text-left">
                    <div className="form-group col-sm-12 flex-column d-flex">
                      <label className="form-control-label">
                        <b>
                          Distinctions <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <Field
                        disabled={
                          !PremissionChecker(
                            user,
                            "edit_appointed_staff.distinctions"
                          )
                        }
                        maxLength={30}
                        name="distinctions"
                        component={Input}
                        placeholder="Distinctions"
                      />
                    </div>
                  </div>

                  <div className="row justify-content-between text-left">
                    <div className="form-group col-sm-12 flex-column d-flex">
                      <label className="form-control-label">
                        <b>
                          Objectives <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <Field
                        disabled={
                          !PremissionChecker(
                            user,
                            "edit_appointed_staff.objectives"
                          )
                        }
                        maxLength={30}
                        name="objectives"
                        component={Input}
                        placeholder="Objectives"
                      />
                    </div>

                    <div className="form-group col-sm-12 flex-column d-flex">
                      <label className="form-control-label">
                        <b>
                          Duties Assigned{" "}
                          <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>

                      <Field
                        disabled={
                          !PremissionChecker(
                            user,
                            "edit_appointed_staff.duties_assigned"
                          )
                        }
                        maxLength={30}
                        name="duties_assigned"
                        component={Input}
                        placeholder="Duties Assigned"
                      />
                    </div>
                  </div>

                  <div className="row justify-content-between text-left">
                    <div className="form-group col-sm-6 flex-column d-flex ">
                      <label className="form-control-label">
                        <b>
                          Joining Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <Field
                        disabled={
                          !PremissionChecker(
                            user,
                            "edit_appointed_staff.joining_date"
                          )
                        }
                        name="joining_date"
                        component={Input}
                        type="date"
                        placeholder="joining_date"
                        max="9999-12-30"
                      />
                    </div>
                    <div className="form-group col-sm-6 flex-column d-flex ">
                      <label className="form-control-label">
                        <b>
                          EOBI No. <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <Field
                        disabled={
                          !PremissionChecker(
                            user,
                            "edit_appointed_staff.eobi_number"
                          )
                        }
                        maxLength={30}
                        name="eobi_no"
                        component={Input}
                        placeholder="EOBI Number"
                      />
                    </div>
                  </div>

                  <div className="row justify-content-between text-left">
                    <div className="row justify-content-between text-left">
                      <div className="form-group col-md-6 flex-column d-flex">
                        <label className="form-control-label">
                          <b>
                            Select Bank Category{" "}
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </b>
                        </label>
                        <select
                          disabled={
                            !PremissionChecker(
                              user,
                              "edit_appointed_staff.select_bank_category"
                            )
                          }
                          className="form-select form-control"
                          name="sub_account_id"
                          value={selectedbankcateogry}
                          onChange={(e) => {
                            setselectedbankcateogry(e.target.value);
                            dispatch({
                              type: "CHALLAN_BANK_CATEGORY",
                              payload: {
                                selectedbankcategory_red: e.target.value,
                              },
                            });
                            console.log(allbanks);
                            let anserr = allbanks.filter(
                              (item) => item.id == e.target.value
                            );

                            if (anserr.length != 0) {
                              setallsubaccounts([...anserr[0].bank_accounts]);
                            } else {
                              setallsubaccounts([]);
                            }
                          }}
                          style={{ backgroundColor: "white" }}
                          aria-label="Default select example"
                        >
                          <option acode="" selected value="">
                            Select...
                          </option>
                          {allbanks.map((item) => (
                            <option value={item.id}>{item.title}</option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group col-md-6 flex-column d-flex">
                        <label className="form-control-label">
                          <b>Select Bank </b>
                        </label>
                        <select
                          disabled={
                            !PremissionChecker(
                              user,
                              "edit_appointed_staff.select_bank"
                            )
                          }
                          class="form-select form-control"
                          name="bank_account_id"
                          value={
                            values.bank_account_id
                              ? values.bank_account_id
                              : staffDetails.bank_account_id
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ backgroundColor: "white" }}
                          aria-label="Default select example"
                        >
                          <option acode="" selected value="">
                            Select...
                          </option>
                          {allsubaccounts.map((item) => (
                            <option value={item.id}>{item.bank_name}</option>
                          ))}
                        </select>
                        {errors.bank_account_id && touched.bank_account_id && (
                          <div
                            style={{ color: "#f87d8a" }}
                            className="input-feedback"
                          >
                            "Bank Is Required"
                          </div>
                        )}
                      </div>

                      <div className="form-group col-sm-6 flex-column d-flex ">
                        <label className="form-control-label">
                          <b>Account No </b>
                        </label>
                        <Field
                          disabled={
                            !PremissionChecker(
                              user,
                              "edit_appointed_staff.account_no"
                            )
                          }
                          maxLength={30}
                          name="account_no"
                          component={Input}
                          placeholder="Account No"
                        />
                      </div>

                      <div className="form-group col-sm-6 flex-column d-flex ">
                        <label className="form-control-label">
                          <b>Salary Days</b>
                        </label>
                        <Field
                          disabled={
                            !PremissionChecker(
                              user,
                              "edit_appointed_staff.salary_days"
                            )
                          }
                          maxLength={3}
                          name="salery_days"
                          component={Input}
                          placeholder="Salery Days"
                        />
                      </div>
                    </div>
                  </div>
                </Form>
                {isallfields ? (
                  <div id="err">
                    <Alert
                      variant="danger"
                      onClose={() => setisallfields(false)}
                      dismissible
                    >
                      <Alert.Heading>Please fill All fields!</Alert.Heading>
                      <p>{showerror}</p>
                    </Alert>
                  </div>
                ) : null}

                <div style={{ justifyContent: "flex-end" }} className="row">
                  {loading ? (
                    <span className="spinner-border"></span>
                  ) : (
                    <>
                      <button
                        onClick={showStudents}
                        className="btn btn-success button_blue col-md-2"
                      >
                        Show Students
                      </button>
                      <button
                        onClick={() => handleSubmit()}
                        type="submit"
                        value="Submit"
                        className="btn btn-success button_blue col-md-2 mx-2"
                      >
                        Update
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          show(false);
                        }}
                        className="btn btn-danger btn_width col-md-2 ml-5"
                      >
                        CLOSE
                      </button>
                    </>
                  )}
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      <Modal onHide={() => setStudentView(false)} show={studentView} size="xl">
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            View Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewStafStudents studentDetails={staffDetails.students} />
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setStudentView(false)}
            className="btn btn-danger btn_width"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditAppointedStaff;
