
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';



const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function TextInput({ handleChange, label, name, placeholder, type }) {
  const classes = useStyles();
  return (

    <TextField
      type={type ? type : 'text'}
      id="outlined-name"
      label={label}
      className={classes.textField}
      value={name}
      placeholder={placeholder}
      onChange={(e) => handleChange(e)}
      margin="normal"
      variant="outlined"
    />


  );
}
