import { LinearProgress, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import { upperCase } from "lodash";

const TotalStaff = () => {
  const [selected, setSelected] = useState("pay");
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  let authtoken = localStorage.getItem("userToken");

  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRadioButtonClick = (e) => {
    setreportData([]);
    const value = e.target.value;
    setSelected(e.target.value);
  };
  const handleShow = () => {
    setLoading(true);
    const data = {
      pay_scale_wise: selected == "pay" ? 1 : "",
      designation_wise: selected == "desi" ? 1 : "",
    };
    fetch(`${baseUrl}/staff-figures-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setreportData(data.payload);
          }
        }
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  return (
    <>
      <Paper className="mt-5">
        {loading ? <LinearProgress /> : null}
        <div className="container">
          <div className="row pt-5">
            <div className="col-sm-3 pt-2">
              <input
                checked={selected == "pay"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="pay"
                value="pay"
              />
              <label for="pay">Pay Scale wise</label>
            </div>
            <div className="col-sm-3 pt-2">
              <input
                checked={selected == "desi"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="desi"
                value="desi"
              />
              <label for="desi">Designation Wise</label>
            </div>
            <div className="col-md-3">
              <button onClick={handleShow} className="btn btn-primary">
                Show
              </button>
            </div>
          </div>
        </div>
        <div className="row p-3 mt-4">
          <img
            // className='col-md-2'
            style={{ height: 100, width: 110 }}
            src={icon}
          />

          <div className="col-md-6">
            <h1>{unitName}</h1>
            <h5>
              {selected == "pay"
                ? "Pay Scale Wise Staff Figures"
                : "Designation Wise Staff Figures"}
            </h5>
          </div>
        </div>

        <ColoredLine color={"#FF0000"} />
        {selected == "pay" ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">Pay Scale</th>
                <th className="text-center">Total Staff</th>
              </tr>
            </thead>
            {reportData.length > 0
              ? reportData.map((item) => (
                  <>
                    <tr>
                      <td
                        className="text-start text-primary "
                        style={{ fontSize: "1.5rem", fontWeight: "500" }}
                      >
                        {item.Campus}
                      </td>
                      <td
                        className="text-primary"
                        style={{ fontSize: "1.5rem", fontWeight: "500" }}
                      >
                        {item.employees.reduce(
                          (a, c) => a + parseInt(c.total),
                          0
                        )}
                      </td>
                    </tr>
                    <tbody>
                      {item.employees.length > 0
                        ? item.employees.map((subitem) => (
                            <tr>
                              <td
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "400",
                                }}
                              >
                                {subitem.pay_scale}
                              </td>
                              <td
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "400",
                                }}
                              >
                                {subitem.total}
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </>
                ))
              : ""}
          </Table>
        ) : null}
        {selected == "desi" ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">Designation</th>
                <th className="text-center">Total Staff</th>
              </tr>
            </thead>
            {reportData.length > 0
              ? reportData.map((item) => (
                  <>
                    <tr>
                      <td
                        className="text-start text-primary "
                        style={{ fontSize: "1.5rem", fontWeight: "500" }}
                      >
                        {item.Campus}
                      </td>
                      <td
                        className="text-primary"
                        style={{ fontSize: "1.5rem", fontWeight: "500" }}
                      >
                        {item.employees.reduce(
                          (a, c) => a + parseInt(c.total),
                          0
                        )}
                      </td>
                    </tr>
                    <tbody>
                      {item.employees.length > 0
                        ? item.employees.map((subitem) => (
                            <tr>
                              <td
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "400",
                                }}
                              >
                                {subitem.Designation}
                              </td>
                              <td
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "400",
                                }}
                              >
                                {subitem.total}
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </>
                ))
              : ""}
          </Table>
        ) : null}
      </Paper>
    </>
  );
};

export default TotalStaff;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
