import React, { useState, useEffect } from "react";

import { Table, Modal, Spinner, Alert, Form } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";

import { useHistory } from "react-router-dom";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import Pagination from "../../../components/Pagination/Pagination";
import SVG from "react-inlinesvg";
import { useSelector, useDispatch } from "react-redux";

function GenrateStudentWiseFee({
  isfeemodal,
  studentdetail,
  is_issue_date,
  is_due_date,
  proceed,
}) {
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );

  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  const [currentsession, setcurrentsession] = useState("");
  const [allsessions, setallsessions] = useState([]);

  const history = useHistory();
  const alert = useAlert();

  const [allstudents, setallstudents] = useState([]);
  const [campus, setcampus] = useState([]);
  const [campus_id, setcampus_id] = useState("");
  const [class_id, setclass_id] = useState("");

  const [feetypes, setfeetypes] = useState([]);

  const [fee_type_id, setfee_type_id] = useState("");

  const [selectedStudent, setselectedStudent] = useState({});
  const [feemodal, setfeemodal] = useState(false);
  const [due_date, setdue_date] = useState(red_recevingdate);
  const [issue_date, setissue_date] = useState(red_recevingdate);

  const [pree_selectedStudent, setpree_selectedStudent] = useState(
    studentdetail
  );
  const [pree_feemodal, setpree_feemodal] = useState(isfeemodal);
  const [pree_due_date, setpree_due_date] = useState(is_due_date);
  const [pree_issue_date, setpree_issue_date] = useState(is_issue_date);

  // const [issue_date, setissue_date] = useState(is_issue_date == undefined ? '' : is_issue_date)

  const [loading, setloading] = useState(false);
  const [feeloading, setfeeloading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);

  const currentyear = new Date().getFullYear();
  const [isallfields, setisallfields] = useState(false);
  const [datesformat, setdatesformat] = useState([]);

  let [amount, setamount] = useState([]);
  let [fees_type_id, setfees_type_id] = useState([]);

  let [monthlyFeeInputs, setmonthlyFeeInputs] = useState([1]);
  let [monthlyFeeInputsvalue, setmonthlyFeeInputsvalue] = useState([]);
  let [isMonthlyFee, setisMonthlyFee] = useState(false);

  let [additiionalfeeinputs, setadditiionalfeeinputs] = useState([1]);
  let [addituionalFeeInputsvalue, setaddituionalFeeInputsvalue] = useState([]);
  let [isAdditionalFee, setisAdditionalFee] = useState(false);

  let [errorcreate, seterrorcreate] = useState("Please Fill All The Fields");

  // const [allsessions, setallsessions] = useState([])

  const [sessionstd, setsessionstd] = useState("");
  const [allsessionstd, setallsessionstd] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllSessions();

    // getAllSessions()
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        setallsessionstd(data.payload);

        setallsessions(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            setsessionstd(item.id);
            if (campusidget != "") {
              onchangefunc(campusidget, item.id);
            }
          }
        });
        setallsessionstd(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //getting token
  const authtoken = localStorage.getItem("userToken");
  useEffect(() => {
    if (isfeemodal == undefined) {
    } else {
      setfeemodal(true);
    }

    fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setcampus(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [allstudents]);

  // useEffect(() => {

  // }, [])

  // const getfeeres = (id, index) => {

  //     var found = checkingarray.find(function (element) {
  //         return element.id == id;
  //     });

  //     let updatingarray = amount;
  //     updatingarray[index] = found.amount;

  //     setamount([...updatingarray])
  // }

  const additionalfeeChange = (e, index) => {
    let updatingarray = amount;
    updatingarray[index] = e.target.value;
    setamount([...updatingarray]);
  };

  const getRespectivefee = (id, index) => {
    console.log(id);

    let data = {
      campus_id: selectedStudent.campus_id,
      student_class_id: selectedStudent.student_class_id,
      fee_type_id: id,
      year_id: currentsession,
    };

    console.log(data);

    //fetch(`${baseUrl}/campus/campus-fees/${id}`, {
    fetch(`${baseUrl}/feestructure_amount`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        //  let studentclassid = selectedStudent.student_class_id;
        // data.payload.find()

        // var found = data.payload.find(function (element) {
        //     return element.student_class_id == studentclassid;
        // });

        // console.log(found.amount);
        console.log(index, "index");
        console.log(data.payload.amount);

        let updatingarray = amount;
        updatingarray[index] = data.payload.amount;

        setamount([...updatingarray]);

        console.log(amount, "updating array 1");

        console.log(updatingarray, "updating array");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getfeetypes = (campusid, classid, session_id) => {
    console.log(session_id);
    console.log(classid);
    console.log(campusid);

    fetch(`${baseUrl}/classfeetypes/${campusid}/${classid}/${session_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("fee types ==>", data.payload);
        setfeetypes(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangefunc = async (e, sessionid) => {
    setloading(true);
    const id = e;
    let session_id = sessionid;
    setpreviousselectedcampus(id);

    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });

    let data = {
      campus_id: id,
      year_id: session_id,
    };

    setcampus_id(id);
    fetch(`${baseUrl}/all_fee_generate_std_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        let campusstudent = [];
        data.payload.map((student) => {
          if (student.campus_id == id) {
            campusstudent.push(student);
          }
        });

        setallstudents([...campusstudent]);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const handler = (event, index) => {
    if (event.key === "Enter") {
      console.log("enter press here! ");
    }
    // event.preventDefault();
    //additionalfeeChange(event,index)
  };

  const openfeemodal = (student) => {
    setselectedStudent({ ...student });
    console.log(sessionstd);
    getfeetypes(student.campus_id, student.student_class_id, sessionstd);
    setfeemodal(true);

    console.log(student, "student");
    setcampus_id(student.campus_id);
    setclass_id(student.student_class_id);
    //  getfeetypes(student.campus_id, student.student_class_id)
  };

  //fee genrate Function

  const cencelModal = () => {
    setisMonthlyFee(false);
    setisAdditionalFee(false);
    setisallfields(false);
    setmonthlyFeeInputsvalue([]);
    setmonthlyFeeInputs([]);
    setfees_type_id([]);
    setamount([]);
    setadditiionalfeeinputs([]);
    setfeeloading(false);
    seterrorcreate("Please Fill All The Fields");
  };

  const genrateFee = () => {
    setfeeloading(true);

    // isallfields

    let monthly_fee_status = 1;
    let additional_fee_status = 1;

    if (fees_type_id.length == 0) {
      additional_fee_status = 0;
    }
    if (monthlyFeeInputsvalue.length == 0) {
      monthly_fee_status = 0;
    }

    if (!isMonthlyFee) {
      if (!isAdditionalFee) {
        setisallfields(true);
        seterrorcreate("Please Add Monthly Fee or Additional Fee");
        setfeeloading(false);
        return;
      }
    }

    if (monthlyFeeInputsvalue.length != monthlyFeeInputs.length) {
      if (isMonthlyFee) {
        setisallfields(true);
        seterrorcreate("Please Add Monthly  Fee");
        setfeeloading(false);
        return;
      }
    }

    if (isAdditionalFee) {
      if (
        fees_type_id.length != additiionalfeeinputs.length ||
        additiionalfeeinputs.length != amount.length
      ) {
        setisallfields(true);
        seterrorcreate("Please Add Some Additional Fee");
        setfeeloading(false);
        return;
      }
    }

    // if (monthlyFeeInputsvalue.length != monthlyFeeInputs.length && fees_type_id.length && amount.length != additiionalfeeinputs.length) {

    //     setisallfields(true)
    //     seterrorcreate("Please Add Some Fee")
    //     setfeeloading(false)
    //     return;

    // }

    // if (!monthlyFeeInputsvalue.length == monthlyFeeInputs.length) {
    //     setisallfields(true)
    //     seterrorcreate("Please Don't Left Any Monthly Fee Field Empty")
    //     setfeeloading(false)
    //     return;
    // }
    // console.log(fees_type_id.length, amount.length, additiionalfeeinputs.length, "pakistan")
    // if (!fees_type_id.length && amount.length == additiionalfeeinputs.length) {
    //     setisallfields(true)
    //     seterrorcreate("Please Don't Left Any Additional Fee Field Empty")
    //     setfeeloading(false)
    //     return;
    // }

    if (due_date.length == 0 || issue_date.length == 0) {
      setisallfields(true);
      seterrorcreate("Please Give Due And issue Dates");
      setfeeloading(false);
      return;
    }

    setisallfields(false);

    // console.log(selectedStudent)

    // const data = {
    //     campus_id: 2,
    //     due_date: "09/17/2021",
    //     fee_type_id: 4,
    //     issue_date: "08/17/2021",
    //     student_class_id: 5,
    //     student_id: 1
    // }
    const data = {
      monthly_fee_status,
      additional_fee_status,
      fees_type_id,
      amount,
      fee_month: monthlyFeeInputsvalue,
      due_date,
      issue_date,
    };
    console.log(data);

    let stdid = "";

    if (selectedStudent.id == undefined) {
      stdid = studentdetail.id;
    } else {
      stdid = selectedStudent.id;
    }

    console.log(selectedStudent.id);

    fetch(`${baseUrl}/custom_fees_generate/${stdid}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          setfeeloading(false);
          if (proceed == "proceed") {
            if (data.metadata) {
              if (data.metadata.success) {
                alert.success("Fee genrated Success fully");
                history.push("/student");
                return;
              } else {
                alert.show("Something went wrong !");
                history.push("/student");
              }
            } else {
              alert.show("Something went wrong !");
              history.push("/student");
            }
          }

          if (data.metadata.success) {
            setfeeloading(false);
            setfeemodal(false);
            alert.show("Fee genrated Success fully");
          } else {
            setfeemodal(false);
            setfeeloading(false);
            alert.show("Something went wrong !");
          }
          cencelModal();
        });
      })
      .catch((err) => {
        setfeeloading(false);
        if (proceed == "proceed") {
          history.push("/showallstudents");
          return;
        }
        setfeeloading(false);
        setfeemodal(false);
        alert.show(err);
      });
  };

  const addMonthlyInputfield = (e) => {
    e.preventDefault();
    console.log(e.target.id);
    if (e.target.id === "add_Monthly_Fee_button") {
      let array = monthlyFeeInputs;
      array.push(1);
      console.log(array);
      setmonthlyFeeInputs([...array]);
    }
  };

  const addAdditionalInputfield = (e) => {
    e.preventDefault();
    let array = additiionalfeeinputs;
    array.push(1);
    setadditiionalfeeinputs([...array]);
  };

  const changesession = (id) => {
    setcurrentsession(id);
    getfeetypes(campus_id, class_id, sessionstd);
  };
  const changesessionForStd = (id) => {
    setsessionstd(id);
    if (previousselectedcampus != "") {
      onchangefunc(previousselectedcampus, id);
    }
  };

  //@_pagination

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = allstudents.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {isfeemodal == undefined ? (
          <>
            <div>
              <div class="form-group col-sm-6 flex-column d-flex">
                <label>
                  <b>Select Session</b>
                </label>
                <select
                  onChange={(e) => changesessionForStd(e.target.value)}
                  class="form-select"
                  style={{ height: 40 }}
                  value={sessionstd}
                  aria-label="Default select example"
                >
                  <option value="">Select Session</option>
                  {allsessionstd.map((session) => (
                    <option
                      // selected={
                      //   session.active_academic_year == "1" ? true : false
                      // }
                      value={session.id}
                    >
                      {session.year}
                    </option>
                  ))}
                </select>
              </div>

              {/* <h4 style={{ margin: 10, }}>Select Campus to Show Students</h4> */}

              {sessionstd != "" ? (
                <>
                  <div class="form-group col-sm-6 flex-column d-flex">
                    <label>
                      <b>Select Campus </b>
                    </label>
                    <select
                      onChange={(e) => onchangefunc(e.target.value, sessionstd)}
                      class="form-select"
                      style={{ height: 40 }}
                      value={campus.length == 0 ? "" : previousselectedcampus}
                      aria-label="Default select example"
                    >
                      <option selected value="">
                        Select Campus
                      </option>
                      {campus.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <div className="row justify-content-center">
                      {loading && (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )}
                    </div>
                    <Table responsive>
                      <thead>
                        <tr id="tbl">
                          <th>ID</th>
                          <th>Student Name</th>
                          <th>Father Name</th>
                          {/* <th>Address Name</th> */}
                          <th>CNIC/ B FORM</th>
                          <th>Shift</th>
                          <th>Generate Fee</th>
                        </tr>
                        {currentPosts.map((student) =>
                          student.campus_id == campus_id ? (
                            <>
                              <tr key={student.id}>
                                <td
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "start",
                                  }}
                                >
                                  {student.id}
                                </td>

                                <td
                                  title={
                                    student.name.length > 15
                                      ? student.name
                                      : null
                                  }
                                  style={{
                                    textAlign: "start",
                                    cursor: "pointer",
                                    fontWeight: "400",
                                  }}
                                >
                                  {student.name.length > 15
                                    ? student.name.substring(0, 12) + "..."
                                    : student.name}
                                </td>
                                {/* <td style={{ fontWeight: "400", textAlign: "start" }}>{student.name}</td> */}

                                <td
                                  title={
                                    student.father_name.length > 15
                                      ? student.father_name
                                      : null
                                  }
                                  style={{
                                    textAlign: "start",
                                    cursor: "pointer",
                                    fontWeight: "400",
                                  }}
                                >
                                  {student.father_name.length > 15
                                    ? student.name.substring(0, 12) + "..."
                                    : student.father_name}
                                </td>

                                {/* <td style={{ fontWeight: "400", textAlign: "start" }}>{student.father_name}</td> */}
                                {/* <td style={{ fontWeight: "400", textAlign: "start" }}>{student.address}</td> */}
                                <td
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "start",
                                  }}
                                >
                                  {student.cnic_no}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "start",
                                  }}
                                >
                                  {student.shift}
                                </td>
                                <td style={{ textAlign: "start" }}>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    onClick={() => {
                                      openfeemodal(student);
                                    }}
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/Flag.svg"
                                        )}
                                      />
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            </>
                          ) : null
                        )}
                      </thead>
                      <tbody></tbody>
                    </Table>
                    <Pagination
                      postsPerPage={postPerPage}
                      paginate={paginate}
                      totalPosts={allstudents.length}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : null}
        <Modal size="lg" show={feemodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Student Fee
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div class="row d-flex">
              <div class="col-xl-11 col-lg-11 col-md-11 col-11">
                {/* form start from here */}
                {/* <div className="generate text-center">Enter Student Detail and Submit</div> */}
                <br />
                <br />

                {/* <div class="form-group col-sm-12 flex-column d-flex">
                                    <label>
                                        <b>Select Session <span style={{ color: "red" }}>*</span></b>
                                    </label>
                                    <select
                                        onChange={(e) => changesession(e.target.value)}
                                        class="form-select"
                                        style={{ height: 40 }}
                                        value={currentsession}
                                        aria-label="Default select example"

                                    >
                                        <option selected value="" >Select Session</option>
                                        {
                                            allsessions.map(session => (

                                                <option value={session.id}>{session.year}</option>


                                            ))
                                        }
                                    </select>

                                </div> */}

                {/* {
                                    currentsession != "" ? */}

                <form class="form-card">
                  <div class="row">
                    <div className="form-group col-sm-12">
                      <div className="row">
                        <div className="col-sm-6">
                          <label>
                            <b>
                              Student Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <input
                            style={{ backgroundColor: "#F3F6F9" }}
                            disabled
                            defaultValue={
                              pree_selectedStudent == undefined
                                ? selectedStudent.name
                                : pree_selectedStudent.name
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>
                            <b>
                              Addmission ID{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <input
                            style={{ backgroundColor: "#F3F6F9" }}
                            disabled
                            defaultValue={
                              pree_selectedStudent == undefined
                                ? selectedStudent.admission_id
                                : pree_selectedStudent.admission_id
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="col-sm-12">
                          <label>
                            <b>
                              Father Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <input
                            disabled
                            defaultValue={
                              pree_selectedStudent == undefined
                                ? selectedStudent.father_name
                                : pree_selectedStudent.father_name
                            }
                            style={{ backgroundColor: "#F3F6F9" }}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div class="form-group col-sm-6 flex-column d-flex pt-3">

                                        <label class="form-control-label">
                                            <b>Select Fee type</b>
                                        </label>
                                        <select
                                            onChange={(e) => setfee_type_id(e.target.value)}
                                            class="form-select"
                                            style={{ backgroundColor: '#F3F6F9' }}
                                            aria-label="Default select example"
                                        >
                                            <option selected disabled>
                                                Select Fee Type
                                            </option>

                                            {
                                                feetypes.map(fee => (
                                                    <option value={fee.id}>{fee.name}</option>
                                                ))
                                            }


                                        </select>
                                    </div> */}
                  </div>

                  <div>
                    <h3 class="generate">
                      Add Fee <span style={{ color: "red" }}>*</span>
                    </h3>
                  </div>

                  <div class="row col-sm-12 justify-content-between text-left">
                    <div class="form-group col-sm-12 flex-column d-flex">
                      <div class="form-group col-sm-12 flex-column d-flex">
                        <div class="switch ">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={() => {
                                setisMonthlyFee(!isMonthlyFee);
                                setmonthlyFeeInputsvalue = [];
                                setmonthlyFeeInputs([1]);
                              }}
                              type="checkbox"
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">Monthly Fee</h3>
                          </label>
                        </div>

                        {isMonthlyFee ? (
                          <div>
                            <div className="form-group column ">
                              {monthlyFeeInputs.map((item, index) => (
                                <div className="col-lg-12 col-sm-12s flex-column d-flex">
                                  <div>
                                    <label>
                                      Enter Date{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                      className="flex-row  d-flex  col-lg-12 col-sm-12s"
                                    >
                                      {console.log(
                                        datesformat[index],
                                        " monthlyFeeInputsvalue[index]"
                                      )}
                                      <input
                                        className="flex-row  d-flex  col-lg-8 col-sm-12s"
                                        value={datesformat[index]}
                                        defaultValue={datesformat[index]}
                                        // onKeyPress={(e) => handler(e)}
                                        // value={

                                        //         monthlyFeeInputsvalue[index]
                                        // }

                                        key={index}
                                        onChange={(e) => {
                                          let datearray = datesformat;
                                          let array = monthlyFeeInputsvalue;
                                          datearray[index] = e.target.value;
                                          setdatesformat([...datearray]);
                                          array[index] = e.target.value + "-01";
                                          setmonthlyFeeInputsvalue([...array]);
                                        }}
                                        name="dob"
                                        type="month"
                                        max="9999-11"
                                        min={currentyear + "-01"}
                                        placeholder="Enter Date of birth"
                                      />

                                      <button
                                        id={index}
                                        onClick={(e) => {
                                          e.preventDefault();

                                          // let monthlyFeeInputs_remove = monthlyFeeInputs;
                                          // monthlyFeeInputs_remove.splice(index, 1)

                                          // setmonthlyFeeInputs([...monthlyFeeInputs_remove])

                                          let monthlyFeeInputsvalu_earray = monthlyFeeInputsvalue;
                                          monthlyFeeInputsvalu_earray.splice(
                                            index,
                                            1
                                          );

                                          setmonthlyFeeInputsvalue = [
                                            ...monthlyFeeInputsvalu_earray,
                                          ];
                                          let datesformat_array = datesformat;
                                          datesformat_array.splice(index, 1);
                                          setdatesformat([
                                            ...datesformat_array,
                                          ]);

                                          setmonthlyFeeInputs(
                                            monthlyFeeInputs.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                        }}
                                        className="btn btn-danger btn_width"
                                      >
                                        remove
                                      </button>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              ))}

                              <br />
                              <div className="col-lg-3 col-sm-12">
                                <button
                                  id="add_Monthly_Fee_button"
                                  onClick={(e) => {
                                    addMonthlyInputfield(e);
                                  }}
                                  className="btn btn-success button_blue"
                                >
                                  Add Fields
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div class="form-group col-sm-12 flex-column d-flex">
                      <div class="form-group col-sm-12 flex-column d-flex">
                        <div class="switch">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={() => {
                                setisAdditionalFee(!isAdditionalFee);
                                setaddituionalFeeInputsvalue([]);
                                setadditiionalfeeinputs([1]);
                                setamount([]);
                              }}
                              type="checkbox"
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">
                              Additional Fee
                            </h3>
                          </label>
                        </div>

                        {isAdditionalFee ? (
                          <div>
                            <div className="form-group column ">
                              {additiionalfeeinputs.map((item, index) => (
                                <div className="col-lg-12 col-sm-12s flex-column d-flex">
                                  <div>
                                    <label>
                                      Add Additional fee{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <div
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                      className="flex-row  d-flex  col-lg-12 col-sm-12s"
                                    >
                                      <select
                                        defaultValue={fees_type_id[index]}
                                        value={fees_type_id[index]}
                                        onChange={(e) => {
                                          console.log(e.target);

                                          let updatingarray = fees_type_id;
                                          updatingarray[index] = e.target.value;
                                          setfees_type_id([...updatingarray]);

                                          //  setfees_type_id()
                                          getRespectivefee(
                                            e.target.value,
                                            index
                                          );

                                          // getfeeres(e.target.value, index)
                                          // let array = addituionalFeeInputsvalue;
                                          // array[index] = e.target.value;
                                          // setaddituionalFeeInputsvalue();
                                          //        setfee_type_id(e.target.value)
                                        }}
                                        class="form-select col-lg-5 col-sm-5s"
                                        aria-label="Default select example"
                                      >
                                        <option selected disabled>
                                          Select Fee Type
                                        </option>

                                        {feetypes.map((fee) => (
                                          <option value={fee.id}>
                                            {fee.name}
                                          </option>
                                        ))}
                                      </select>

                                      <input
                                        id="stopkeypress"
                                        type="number"
                                        className="col-lg-3 col-sm-3s"
                                        onChange={(e) => {
                                          additionalfeeChange(e, index);
                                        }}
                                        min="1"
                                        defaultValue={amount[index]}
                                        value={amount[index]}
                                        onKeyPress={(e) => handler(e, index)}
                                      />
                                      <button
                                        id={index}
                                        className="btn btn-danger btn_width "
                                        onClick={(e) => {
                                          e.preventDefault();

                                          setadditiionalfeeinputs(
                                            additiionalfeeinputs.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                          setfees_type_id(
                                            fees_type_id.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                          setamount(
                                            amount.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        remove
                                      </button>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              ))}

                              <br />
                              <div className="col-lg-3 col-sm-12">
                                <button
                                  id="add_additional_Fee_button"
                                  onClick={(e) => {
                                    addAdditionalInputfield(e);
                                  }}
                                  className="btn btn-success button_blue"
                                >
                                  Add Fields
                                </button>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div class="form-group col-sm-6 flex-column d-flex">
                      <label class="form-control-label">
                        <b>
                          Due Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <input
                        value={due_date}
                        max="9999-12-30"
                        onChange={(e) => setdue_date(e.target.value)}
                        type="date"
                        class="form-control  mb-2"
                      />

                      <label class="form-control-label">
                        <b>
                          Issue Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <input
                        value={issue_date}
                        max="9999-12-30"
                        onChange={(e) => setissue_date(e.target.value)}
                        type="date"
                        class="form-control  mb-2"
                      />
                    </div>
                  </div>
                </form>
                {/* : null
                                } */}
              </div>
            </div>

            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>{errorcreate}</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>
          {feeloading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "15px",
              }}
            >
              {" "}
              <span className="spinner-border"></span>{" "}
            </div>
          ) : (
            <Modal.Footer>
              {/* {
                                currentsession != "" ?
                                    <> */}
              <button
                onClick={genrateFee}
                style={{ backgroundColor: "#121291", color: "#fff" }}
                type="button"
                class="btn"
              >
                Generate Fee
              </button>
              {/* </>
                                    : null
                            } */}
              <button
                onClick={() => {
                  cencelModal();
                  setfeemodal();
                }}
                style={{ color: "#fff", marginLeft: 10 }}
                type="button"
                class="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
}

export default GenrateStudentWiseFee;
