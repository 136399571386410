import React, { useState, useEffect, useRef, useMemo } from "react";
import { Table, Modal, Alert } from "react-bootstrap";
import "./style.css";
import { Link, useHistory } from "react-router-dom";

import { baseUrl } from "../../../../services/config";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import EditStaff from "./EditStaff/EditStaff";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Appointment from "./Appointment/Appointment";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../../_metronic/_partials/controls";

import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import { values } from "lodash-es";
import MaskedInput from "react-text-mask";

export default function AddNewStaff({ show, onHide, getAllStaff, campusid }) {
  const myRef = React.createRef();
  const alert = useAlert();

  const [phonecountrycod, setphonecountrycod] = useState("");
  const [mobcountrycod, setmobcountrycod] = useState("");

  const authtoken = localStorage.getItem("userToken");
  const [appointmentModal, setappointmentModal] = useState(false);
  const [designations, setdesignations] = useState([]);
  //modals and loading
  // const [registerModal, setregisterModal] = useState(false)
  const [loading, setloading] = useState(false);
  const [addloading, setaddloading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [editModal, seteditModal] = useState(false);
  //states
  const [applied_for, setapplied_for] = useState("");
  const [full_name, setfull_name] = useState("");
  const [father_name, setfather_name] = useState("");
  const [nationality, setnationality] = useState("");
  const [religion, setreligion] = useState("");
  const [experience, setexperience] = useState("");
  const [cnic_no1, setcnic_no1] = useState("");
  const [qualification, setqualification] = useState("");
  const [gender, setgender] = useState("");
  const [marital_status, setmarital_status] = useState("");
  const [dob, setdob] = useState("");
  const [remarks, setremarks] = useState("");
  const [mobile_no, setmobile_no] = useState("");
  const [phone, setphone] = useState("");
  const [address, setaddress] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [allStaff, setallStaff] = useState([]);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please fill All fields");
  const [buttondisable, setbuttondisable] = useState(true);
  const [currentObject, setcurrentObject] = useState({});
  const history = useHistory();
  const [isformsubmitted, setisformsubmitted] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  // let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  // const [previousselectedcampus, setpreviousselectedcampus] = useState(
  //   campusidget
  // );

  const cnicNumberMask = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
  ];

  useEffect(() => {
    getAlldesignations();
    // getAllsession()
    // if (campusidget != "") {
    //   setpreviousselectedcampus(campusidget);
    //   getAllStaff(campusidget);
    // }

    // getAllCampus();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
          console.log(allcampuses);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  const getAlldesignations = () => {
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(banks)
        setdesignations(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkerrors = () => {
    if (applied_for == "") {
      setshowerr(true);
      setshowerror("Please add Possition");
      return false;
    }
    if (full_name == "") {
      setshowerr(true);
      setshowerror("Please add Full Name");
      return false;
    }
    if (father_name == "") {
      setshowerr(true);
      setshowerror("Please add Father Name");
      return false;
    }
    if (nationality == "") {
      setshowerr(true);
      setshowerror("Please add Nationality");
      return false;
    }
    if (religion == "") {
      setshowerr(true);
      setshowerror("Please add religion");
      return false;
    }
    if (experience == "") {
      setshowerr(true);
      setshowerror("Please add Your Experience");
      return false;
    }
    if (cnic_no1 == "") {
      setshowerr(true);
      setshowerror("Please add CNIC");
      return false;
    }
    if (qualification == "") {
      setshowerr(true);
      setshowerror("Please add Qualification");
      return false;
    }

    if (gender == "") {
      setshowerr(true);
      setshowerror("Please Select Gender");
      return false;
    }
    if (marital_status == "") {
      setshowerr(true);
      setshowerror("Please add Your Marital Status");
      return false;
    }
    if (dob == "") {
      setshowerr(true);
      setshowerror("Please add Your Date Of Birth");
      return false;
    }
    if (remarks == "") {
      setshowerr(true);
      setshowerror("Please add Your Remarks");
      return false;
    }

    if (mobile_no == "") {
      setshowerr(true);
      setshowerror("Please add Your Mobile No");
      return false;
    }
    if (phone == "") {
      setshowerr(true);
      setshowerror("Please add Your Phone No");
      return false;
    }
    if (address == "") {
      setshowerr(true);
      setshowerror("Please add Your Address");
      return false;
    }
    if (email == "") {
      setshowerr(true);
      setshowerror("Please add Your Email");
      return false;
    } else {
      var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = String(email).search(filter) != -1;
      console.log(res);
      if (res == false) {
        setshowerr(true);
        setshowerror("Please Enter valid  Email");
        return false;
      }
    }
    return true;
  };

  const StaffHandler = () => {
    let res = checkerrors();
    if (res == true) {
      setshowerr(false);
      AddStaff();
    }
  };

  const AddStaff = (values) => {
    setaddloading(true);
    const data = {
      campus_id: values.campus_id,
      designation_id: values.designation_id,
      full_name: values.full_name,
      father_name: values.father_name,
      nationality: values.nationality,
      religion: values.religion,
      experience: values.experience,
      cnic_no: values.cnic_no,
      qualification: values.qualification,
      gender: values.gender,
      marital_status: values.marital_status,
      dob: values.dob,
      remarks: values.remarks,
      mobile_no: values.mobile_no,
      phone: values.phone.toString(),
      address: values.address,
      email: values.email,
    };

    console.log(data, "addstaff");
    fetch(`${baseUrl}/employees`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("New Staff Added");
              // setregisterModal(false)
              setaddloading(false);
              clearStates();

              if (onHide == undefined) {
                history.push("showstaff");
              } else {
                getAllStaff();
                onHide(false);
                setisformsubmitted(false);
              }
            } else if (data.metadata.responseCode == 422) {
              // setregisterModal(false)
              setaddloading(false);

              const msgArray = Object.values(data.metadata.message);
              msgArray.map((err) => alert.error(err[0]));
              if (onHide == undefined) {
                history.push("showstaff");
              } else {
                onHide(false);
              }
            }
          } else {
            setaddloading(false);

            const msgArray = Object.values(data.metadata.message);
            msgArray.map((err) => alert.error(err[0]));
            if (onHide == undefined) {
              history.push("showstaff");
            } else {
              onHide(false);
            }
          }
        });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          alert.error("Something went wrong!", err);
          // setregisterModal(false)
          setaddloading(false);
          if (onHide == undefined) {
            history.push("showstaff");
          } else {
            onHide(false);
          }
        }
      });
  };

  const clearStates = () => {
    setapplied_for("");
    setfull_name("");
    setfather_name("");
    setnationality("");
    setreligion("");
    setexperience("");
    setcnic_no1("");
    setqualification("");
    setgender("");
    setmarital_status("");
    setdob("");
    setremarks("");
    setmobile_no("");
    setphone("");
    setaddress("");
    setemail("");
  };

  //start from here
  const initialValues = {
    designation_id: "",
    campus_id: "",
    full_name: "",
    father_name: "",
    nationality: "",
    religion: "Islam",
    experience: "",
    cnic_no: "",
    qualification: "",
    gender: "",
    marital_status: "",
    dob: "",
    remarks: "",
    mobile_no: "",
    phone: "",
    address: "",
    email: "",
  };
  const StaffRegistrationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),

    father_name: Yup.string().required("Father name is required"),

    dob: Yup.string().required("Date Of Birth is required "),

    religion: Yup.string().required("Religion is required "),
    gender: Yup.string().required("Gender Is Required"),

    mobile_no: Yup.string()
      .required("Mobile Number is required ")
      .matches(
        /^(3)([0-9]{9})$/,
        "Mobile Number  Must start with 3 and must have 13 digits"
      ),

    address: Yup.string().required("Address Is Required"),

    // remarks: Yup.string().required("Remarks is required "),

    nationality: Yup.string().required("Nationality is required "),

    designation_id: Yup.string().required("Designation is required "),

    campus_id: Yup.string().required("Campus Id is required "),

    experience: Yup.string().notRequired("Experience is required "),
    qualification: Yup.string().required("Qualification is required"),

    cnic_no: Yup.string()
      .required("Cnic No required field")
      .matches(
        /^[0-9]{5}-[0-9]{7}-[0-9]$/,
        "CNIC No must follow the XXXXX-XXXXXXX-X format!"
      ),
    // .matches(
    //   /^[0-9]{5}-[0-9]{7}-[0-9]$/,
    //   "CNIC No must follow the XXXXX-XXXXXXX-X format!"
    // ),
    // cnic_no: Yup.string()
    //   .required("Cnic No required field")
    //   .matches(
    //     /^[0-9]{5}-[0-9]{7}-[0-9]$/,
    //     "CNIC No must follow the XXXXX-XXXXXXX-X format!"
    //   ),
    // email: Yup.string().notRequired("Email Is required"),

    marital_status: Yup.string().required("marital Status is required "),

    phone: Yup.string()
      .notRequired("Phone Number is required ")
      .matches(
        /^(3)([0-9]{9})$/,
        "Landline Number  Must start with 3 and must have 13 digits"
      ),
  });
  const createEroors = (errors, touched, isSubmitting, values) => {
    if (errors.designation_id && touched.designation_id) {
      setshowerror(errors.designation_id);
      setshowerr(true);
      return;
    }
    if (errors.campus_id && touched.campus_id) {
      setshowerror(errors.campus_id);
      setshowerr(true);
      return;
    }
    if (errors.full_name && touched.full_name) {
      setshowerror(errors.full_name);
      setshowerr(true);
      return;
    }
    if (values.full_name.length > 255) {
      setshowerror("Name Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }

    if (errors.father_name && touched.father_name) {
      setshowerror(errors.father_name);
      setshowerr(true);
      return;
    }
    if (values.father_name.length > 255) {
      setshowerror("Father Name Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }
    if (errors.nationality && touched.nationality) {
      setshowerror(errors.nationality);
      setshowerr(true);
      return;
    }
    if (values.nationality.length > 255) {
      setshowerror("Nationality Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }
    if (errors.religion && touched.religion) {
      setshowerror(errors.religion);
      setshowerr(true);
      return;
    }
    if (values.religion.length > 255) {
      setshowerror("Religion Name Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }

    if (errors.experience && touched.experience) {
      setshowerror(errors.experience);
      setshowerr(true);
      return;
    }
    if (values.experience.length > 255) {
      setshowerror("Experience  Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }

    if (errors.cnic_no && touched.cnic_no) {
      setshowerror(errors.cnic_no);
      setshowerr(true);
      return;
    }
    // if (touched.cnic_no && values.cnic_no.toString().length < 13 || values.cnic_no.toString().length > 13) {
    //     setshowerror("Please Give Valid CNIC  Number of 13  digits ")
    //     setshowerr(true)
    //     return;
    // }
    if (errors.qualification && touched.qualification) {
      setshowerror(errors.qualification);
      setshowerr(true);
      return;
    }
    if (values.religion.length > 255) {
      setshowerror("Qualification  Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }

    if (errors.gender && touched.gender) {
      setshowerror(errors.gender);
      setshowerr(true);
      return;
    }
    if (errors.marital_status && touched.marital_status) {
      setshowerror(errors.marital_status);
      setshowerr(true);
      return;
    }
    if (errors.dob && touched.dob) {
      setshowerror(errors.dob);
      setshowerr(true);
      return;
    }
    if (errors.remarks && touched.remarks) {
      setshowerror(errors.remarks);
      setshowerr(true);
      return;
    }
    if (values.remarks.length > 255) {
      setshowerror("Remarks  Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }

    if (errors.mobile_no && touched.mobile_no) {
      setshowerror(errors.mobile_no);
      setshowerr(true);
      return;
    }
    //  if (touched.mobile_no && values.mobile_no.toString().length < 11 || values.mobile_no.toString().length > 12) {
    //     setshowerror("Please Give Valid Mobile  Number of 11 or 12 digits ")
    //     setshowerr(true)
    //     return;
    // }

    if (errors.phone && touched.phone) {
      setshowerror(errors.phone);
      setshowerr(true);
      return;
    }
    //  if (touched.phone && values.phone.toString().length < 11 || values.phone.toString().length > 12) {
    //     setshowerror("Please Give Valid Phone Number of 11 or 12 digits ")
    //     setshowerr(true)
    //     return;
    // }

    if (errors.address && touched.address) {
      setshowerror(errors.address);
      setshowerr(true);
      return;
    }
    if (values.address.length > 255) {
      setshowerror("Address  Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }
    if (errors.email && touched.email) {
      setshowerror(errors.email);
      setshowerr(true);
      return;
    }
    if (values.email != "") {
      var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      let res = values.email.match(pattern);
      if (!res) {
        setshowerror("Please Add Valid Email");
        setshowerr(true);
        return;
      }
    }

    setshowerr(false);

    if (Object.keys(errors).length === 0) {
      if (values.full_name == "") {
      } else {
        if (isSubmitting) {
          if (!isformsubmitted) {
            setisformsubmitted(true);
            AddStaff(values);
          }
        }
      }
    }
  };

  return (
    <div>
      <Modal ref={myRef} show={show} size="xl">
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Register new Staff
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Header>
                    <Modal.Title>Register new Staff</Modal.Title>
                </Modal.Header> */}

        <Modal.Body>
          {addloading ? (
            <b style={{ color: "green" }}>
              Staff Registering Please Wait ! {`  `}{" "}
              <span className="spinner-border"></span>
            </b>
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={StaffRegistrationSchema}
              onSubmit={(values) => {}}
            >
              {(props) => {
                const {
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  isSubmitting,
                  isValid,
                  handleChange,
                  handleBlur,
                } = props;

                // setErrorsLis(errors)
                createEroors(errors, touched, isSubmitting, values);

                return (
                  <>
                    <Form
                      onSubmit={handleSubmit}
                      className="form form-label-right"
                    >
                      <div class="row d-flex justify-content-center">
                        <div>
                          <div class="p-3">
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Applied For{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <select
                                  class="form-select form-control"
                                  name="designation_id"
                                  value={values.designation_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  // style={{ backgroundColor: '#F3F6F9' }}
                                  aria-label="Default select example"
                                >
                                  <option selected value="">
                                    Select Designation
                                  </option>
                                  {designations?.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                                </select>
                                {errors.designation_id &&
                                  touched.designation_id && (
                                    <div
                                      style={{ color: "#f87d8a" }}
                                      className="input-feedback"
                                    >
                                      {errors.designation_id}
                                    </div>
                                  )}
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex">
                                {" "}
                                <label class="form-control-label">
                                  <b>
                                    Select Campus{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <select
                                  class="form-select form-control"
                                  name="campus_id"
                                  value={values.campus_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  aria-label="Default select example"
                                >
                                  <option selected value="">
                                    {" "}
                                    Select Campus
                                  </option>
                                  {allcampuses.map((campus, index) => (
                                    <>
                                      <option key={index} value={campus.id}>
                                        {campus.name}
                                      </option>
                                    </>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Full Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="full_name"
                                  component={Input}
                                  placeholder="Full Name"
                                />
                                {/* <input
                                                                        onChange={(e) => setfull_name(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Full Name"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Father's Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="father_name"
                                  component={Input}
                                  placeholder="Father Name"
                                />
                                {/* <input
                                                                        onChange={(e) => setfather_name(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Father Name"
                                                                    /> */}
                              </div>

                              <div class="form-group col-sm-6 flex-column d-flex ">
                                <label class="form-control-label">
                                  <b>
                                    Nationality{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="nationality"
                                  component={Input}
                                  placeholder="Nationality"
                                />
                                {/* <input
                                                                        onChange={(e) => setnationality(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Nationality"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex ">
                                <label class="form-control-label">
                                  <b>
                                    Religion{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="religion"
                                  component={Input}
                                  placeholder="Religion"
                                />
                                {/* <input
                                                                        onChange={(e) => setreligion(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Religion"
                                                                    /> */}
                              </div>
                            </div>
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-6 flex-column d-flex ">
                                <label class="form-control-label">
                                  <b>Experience </b>
                                </label>
                                <Field
                                  name="experience"
                                  component={Input}
                                  placeholder="Experience"
                                />
                                {/* <input
                                                                        onChange={(e) => setexperience(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Experience"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex ">
                                <label class="form-control-label">
                                  <b>
                                    C.N.I.C NO{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="cnic_no"
                                  render={({ field }) => (
                                    <MaskedInput
                                      {...field}
                                      mask={cnicNumberMask}
                                      id="cnic_no"
                                      placeholder="Enter Your CNIC / B-Form No"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      // className="is-invalid form-control"
                                      className={
                                        errors.cnic_no && touched.cnic_no
                                          ? "is-invalid form-control"
                                          : "form-control"
                                      }
                                    />
                                  )}
                                />
                                {errors.cnic_no && touched.cnic_no && (
                                  <div
                                    style={{ color: "#f87d8a" }}
                                    className="input-feedback"
                                  >
                                    {errors.cnic_no}
                                  </div>
                                )}
                                {/* <Field
                                  name="cnic_no"
                                  component={Input}
                                  placeholder="CNIC"
                                  type="number"
                                /> */}
                                {/* <input
                                                                        onChange={(e) => setcnic_no(e.target.value)}
                                                                        type="number"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="  CNIC"
                                                                    /> */}
                              </div>
                            </div>
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-md-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Gender{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <select
                                  class="form-select form-control"
                                  name="gender"
                                  value={values.gender}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  // style={{ backgroundColor: '#F3F6F9' }}
                                  aria-label="Default select example"
                                >
                                  <option selected value="">
                                    Select Gender
                                  </option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                                {errors.gender && touched.gender && (
                                  <div
                                    style={{ color: "#f87d8a" }}
                                    className="input-feedback"
                                  >
                                    {errors.gender}
                                  </div>
                                )}
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Marital Status{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <select
                                  class="form-select form-control"
                                  name="marital_status"
                                  value={values.marital_status}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  // style={{ backgroundColor: '#F3F6F9' }}
                                  aria-label="Default select example"
                                >
                                  <option selected value="">
                                    Merital Status
                                  </option>
                                  <option value="Married">Married</option>
                                  <option value="Unmarried">Unmarried</option>
                                </select>
                                {errors.marital_status &&
                                  touched.marital_status && (
                                    <div
                                      style={{ color: "#f87d8a" }}
                                      className="input-feedback"
                                    >
                                      {errors.marital_status}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-6 flex-column d-flex ">
                                <label class="form-control-label">
                                  <b>
                                    Qualification{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="qualification"
                                  component={Input}
                                  placeholder="Qualification"
                                />
                              </div>

                              <div class="form-group col-md-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Date Of Brith{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="dob"
                                  component={Input}
                                  type="date"
                                  max="9999-12-30"
                                  placeholder="Date Of Birth"
                                />
                                {/* <input
                                                                        onChange={(e) => setdob(e.target.value)}
                                                                        type="date"
                                                                        class="form-control placeholder-bg mb-2"
                                                                    /> */}
                              </div>
                            </div>
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-12 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Remarks{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="remarks"
                                  component={Input}
                                  placeholder="Remarks"
                                />
                                {/* <input
                                                                        onChange={(e) => setremarks(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Add Remarks"
                                                                    /> */}
                              </div>
                            </div>

                            <div className="text-center">
                              <b>CONTACT INFORMATION</b>
                            </div>
                            <div class="row justify-content-between text-left mt-3">
                              <div class="form-group col-sm-6 flex-column d-flex ">
                                <label class="form-control-label">
                                  <b>
                                    Mobile No{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="mobile_no"
                                  component={Input}
                                  // onClick={() => {
                                  //     if (values.mobile_no == '') {
                                  //         setmobcountrycod("+92")
                                  //       //  values.mobile_no = '+92'

                                  //     }
                                  // }}
                                  placeholder="Mobile No"
                                  type="text"
                                />
                                {/* <input
                                                                        onChange={(e) => setmobile_no(e.target.value)}
                                                                        type="number"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Mobile Number"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex ">
                                <label class="form-control-label">
                                  <b>Landline Number </b>
                                </label>
                                <Field
                                  name="phone"
                                  type="text"
                                  // onClick={() => {
                                  //     if (values.phone == '') {
                                  //         setphonecountrycod("+92")
                                  //         //values.phone = '+92'

                                  //     }
                                  // }}
                                  component={Input}
                                  placeholder="Phone No"
                                />
                                {/* <input
                                                                        onChange={(e) => setphone(e.target.value)}
                                                                        type="number"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Phone Number"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-12 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Residential Address{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="address"
                                  component={Input}
                                  placeholder="Address"
                                />
                                {/* <input
                                                                        onChange={(e) => setaddress(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bgg mb-2"
                                                                        placeholder="Address"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>E-Mail Address </b>
                                </label>
                                <Field
                                  name="email"
                                  type="email"
                                  component={Input}
                                  placeholder="Email"
                                />
                                {/* <input

                                                                        onChange={(e) => setemail(e.target.value)}
                                                                        type="email"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        placeholder="Email"
                                                                    /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                    {showerr ? (
                      <div id="err">
                        <Alert
                          variant="danger"
                          onClose={() => setshowerr(false)}
                          dismissible
                        >
                          <Alert.Heading>Oops You Got an Error!</Alert.Heading>
                          <p>{showerror}</p>
                        </Alert>
                      </div>
                    ) : null}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      class="btns"
                    >
                      <button
                        type="submit"
                        value="Submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-success button_blue"
                      >
                        Save
                      </button>
                      <button
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          if (onHide == undefined) {
                            console.log("not onHide");
                            history.push("showstaff");
                          } else {
                            console.log("onHide");
                            onHide(false);
                          }
                          // setregisterModal(false);
                        }}
                        type="button"
                        class="btn btn-danger btn_width"
                      >
                        Close
                      </button>
                    </div>
                    {/* </form> */}
                  </>
                );
              }}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
