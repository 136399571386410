import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Modal, Alert } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { baseUrl } from "../../../../services/config";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Input } from "../../../../_metronic/_partials/controls";
import Select from "react-select";
import jwt_token from "../../../../services/authToken";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

const AddUser = ({ show, onHide, reload }) => {
  const alert = useAlert();

  const history = useHistory();
  const authToken = jwt_token();
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorsLis, setErrorsLis] = useState("");
  const [roleList, setRoleList] = useState([]);
  let [errorcreate, seterrorcreate] = useState("Please fill all fields");
  let [isallfields, setisallfields] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedCampuses, setSelectedCampuses] = useState([]);
  const [campuses, setCampuses] = useState();
  const [addloading, setaddloading] = useState(false);

  const initialValues = {
    first_name: "",
    last_name: "",
    title: "",
    email: "",
    password: "",
  };

  useEffect(() => {
    getRoles();
    getAllCampus();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let arr = [{ label: "Select All", value: "select_all" }];

        data.payload.forEach((element) => {
          arr.push({
            label: element.name,
            value: element.id,
          });
        });
        setCampuses(arr);
      })
      .catch((err) => {
        console.log("campus", err);
      });
  };

  const getRoles = () => {
    fetch(`${baseUrl}/roles`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let arr = [{ label: "Select All", value: "select_all" }];

        data.payload.forEach((element) => {
          arr.push({
            label: element.name,
            value: element.id,
          });
        });
        setRoleList(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddHeadOfficeHandle = async (data) => {
    const fd = new FormData();
    fd.append("first_name", data.first_name);
    fd.append("last_name", data.last_name);
    fd.append("email", data.email);
    fd.append("password", data.password);
    fd.append("office_title", data.title);
    selectedRoles.map((role, i) => {
      fd.append(`role_ids[${i}]`, role.value);
    });
    selectedCampuses.map((role, i) => {
      fd.append(`campus_ids[${i}]`, role.value);
    });

    setaddloading(true);

    fetch(`${baseUrl}/users`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: fd,
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            disableLoading();
            setisallfields(false);
            setaddloading(false);
            seterrorcreate("");
            alert.show("User Successfully Added");
            reload();
            onHide(false);
          } else if (data.metadata.message) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg, {
                type: "error",
                transition: "fade",
              });
            });
            disableLoading();
            setisallfields(false);
            setaddloading(false);
            seterrorcreate("");
            onHide(false);
          }
        })
      )
      .catch((err) => {
        disableLoading();
        setisallfields(false);
        setaddloading(false);
        seterrorcreate("");
        alert.error("Failed");

        onHide(false);
      });
  };

  const createEroors = (errors, touched) => {
    if (Object.keys(errors).length === 0) {
      seterrorcreate("");
      setisallfields(false);
      return;
    }

    if (errors.first_name && touched.first_name) {
      seterrorcreate(errors.first_name);
      setisallfields(true);
      return;
    }

    if (errors.last_name && touched.last_name) {
      seterrorcreate(errors.last_name);
      setisallfields(true);
      return;
    }

    if (errors.email && touched.email) {
      seterrorcreate(errors.email);
      setisallfields(true);
      return;
    }

    if (errors.password && touched.password) {
      seterrorcreate(errors.password);
      setisallfields(true);
      return;
    }

    if (errors.title && touched.title) {
      seterrorcreate(errors.title);
      setisallfields(true);
      return;
    }
  };

  const HeadValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, "Name is atleast of 3 characters")
      .max(50, "Name is mximum of 50 characters")
      .required("Name Is Required"),

    email: Yup.string()
      .email("Wrong email format")
      .required("Email Is Required"),

    last_name: Yup.string()
      .min(3, "Last Name is atleast of 3 characters")
      .max(50, "Last Name is mximum of 50 characters")
      .required("Last  Name is Required"),
    title: Yup.string()
      .min(3, "Title is atleast of 3 characters")
      .max(50, "Title is mximum of 50 characters")
      .required("Title Required "),
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .required("Enter Your  password  "),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleRolesChange = (selectedOptions) => {
    if (selectedOptions == null) {
      setSelectedRoles([]);
      return;
    }
    if (selectedOptions.some((option) => option.value === "select_all")) {
      setSelectedRoles(
        roleList.filter((option) => option.value !== "select_all")
      );
    } else {
      setSelectedRoles(selectedOptions);
    }
  };

  const handleCampusChange = (selectedOptions) => {
    if (selectedOptions == null) {
      setSelectedCampuses([]);
      return;
    }
    if (selectedOptions.some((option) => option.value === "select_all")) {
      setSelectedCampuses(
        campuses.filter((option) => option.value !== "select_all")
      );
    } else {
      setSelectedCampuses(selectedOptions);
    }
  };

  return (
    <Modal size="lg" show={show} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header className="modal_header_style" closeButton>
        <Modal.Title
          className="Modal_Heading_font"
          id="contained-modal-title-vcenter"
        >
          Add New User
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {addloading ? (
          <b style={{ color: "green" }}>
            New User Adding Please Wait {` `}{" "}
            <span className="spinner-border"></span>
          </b>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={HeadValidationSchema}
            enableReinitialize={true}
            onSubmit={(data, { validateForm }) => {
              enableLoading();
              AddHeadOfficeHandle(data);
            }}
          >
            {(props) => {
              const {
                handleSubmit,
                errors,
                touched,
                values,
                isSubmitting,
                isValid,
              } = props;
              setErrorsLis(errors);
              createEroors(errors, touched);
              return (
                <Form onSubmit={handleSubmit} className="form form-label-right">
                  <div className="card-body">
                    <div className="container">
                      <div>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label for="first_name">
                              First Name{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Field
                              component={Input}
                              name="first_name"
                              control="input"
                              type="input"
                              colCount="col-6"
                              value={values.first_name}
                              placeholder="Enter First Name"
                            />
                          </div>

                          <div className="col-lg-6">
                            <label for="last_name">
                              Last Name <span style={{ color: "red" }}> *</span>
                            </label>
                            <Field
                              component={Input}
                              name="last_name"
                              control="input"
                              type="text"
                              colCount="col-6"
                              placeholder="Enter Last Name"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label>
                              Email <span style={{ color: "red" }}> *</span>
                            </label>
                            <Field
                              component={Input}
                              name="email"
                              control="input"
                              type="email"
                              colCount="col-6"
                              placeholder="Enter Your Email"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label>
                              Password <span style={{ color: "red" }}> *</span>
                            </label>
                            <Field
                              component={Input}
                              name="password"
                              control="input"
                              type="password"
                              colCount="col-6"
                              placeholder="Enter Your Password"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-lg-12">
                            <label>
                              Office Title{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Field
                              component={Input}
                              name="title"
                              control="input"
                              type="input"
                              colCount="col-12"
                              placeholder="Enter Office Title"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label>
                              Select Roles{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Select
                              isMulti
                              value={selectedRoles}
                              onChange={(newVal) => handleRolesChange(newVal)}
                              options={roleList}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label>
                              Select Campuses{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Select
                              isMulti
                              value={selectedCampuses}
                              onChange={(newVal) => handleCampusChange(newVal)}
                              options={campuses}
                            />
                          </div>
                        </div>

                        {isallfields ? (
                          <>
                            <Alert
                              variant="danger"
                              onClose={() => setisallfields(false)}
                              dismissible
                            >
                              <Alert.Heading>
                                Oh snap! You got an error!
                              </Alert.Heading>
                              <p>{errorcreate}</p>
                            </Alert>
                          </>
                        ) : null}

                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary font-weight-bold btn_width px-9 py-4 my-3 mx-4"
                          >
                            <span>Submit</span>
                            {loading && (
                              <span className="ml-3 spinner spinner-white"></span>
                            )}
                          </button>
                          <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                          <Link to="/users">
                            <button
                              onClick={() => {
                                setisallfields(false);
                                onHide(false);
                              }}
                              type="button"
                              className="btn btn-danger btn_width font-weight-bold px-9 py-4 my-3"
                            >
                              Cancel
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddUser;
