import React from "react";
import "./DailyTransaction.css";
import { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Table,
  Modal,
  Spinner,
  Form,
  ModalFooter,
  Badge,
} from "react-bootstrap";
import "./DailyTransaction.css";
// import data from "./mokeData";
import { baseUrl } from "../../../services/config";
import mokeData from "./mokeData.json";
import ReactToPrint from "react-to-print";
import { useAlert } from "react-alert";
import { upperCase } from "lodash";

const DailyTransaction = () => {
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [date, setDate] = useState("");
  const [selected, setSelected] = useState("annual");

  // const [nonCurrentData, setNonCurrentData] = useState({});
  // const [nonCurrentDetails, setNonCurrentDetails] = useState([]);
  //  const [currentData, setCurrentData] = useState({});
  // const [currentDetails, setCurrentDetails] = useState([]);
  // const [cashBankData, setCashBankData] = useState({});
  // const [cashBankDetails, setCashBankDetails] = useState([]);
  // const [liabilitiesData, setLiabilitiesData] = useState({});
  // const [liabilitiesDetails, setLiabilitiesDetails] = useState([]);
  // const [totalFeesData, setTotalFeesData] = useState({});
  // const [totalFeesDetails, setTotalFeesDetails] = useState([]);
  // const [receiptsData, setReceiptsData] = useState({});
  // const [receiptsDetails, setReceiptsDetails] = useState([]);
  // const [schoolExpenseData, setSchoolExpenseData] = useState({});
  // const [schoolExpenseDetails, setSchoolExpenseDetails] = useState([]);
  // const [societyExpenseData, setSocietyExpenseData] = useState({});
  // const [societyExpenseDetails, setSocietyExpenseDetails] = useState([]);
  const [reportData, setReportData] = useState([]);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const alert = useAlert();
  let authtoken = localStorage.getItem("userToken");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDateOneChange = (text) => {
    console.log(text);
    setDate(text);
  };

  const onshowClick = () => {
    if (date == "") {
      alert.error("Please Select the date");
      return;
    }
    const data = {
      year_id: yearId,
      date: date,
      annually: selected == "annual" ? 1 : 0,
      monthly: selected == "monthly" ? 1 : 0,
      daily: selected == "daily" ? 1 : 0,
    };
    setLoading(true);

    fetch(`${baseUrl}/transaction_reports`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    }).then((resp) => {
      resp
        .json()
        .then((data) => {
          console.log("this", data);
          if (data.metadata) {
            if (data.metadata.success) {
              if (data.payload) {
                // setNonCurrentData(data.payload.total_non_current_assets);
                // setNonCurrentDetails(data.payload.current_non_assets_detail);
                // setCurrentData(data.payload.total_current_assets);
                // setCurrentDetails(data.payload.current_assets_detail);
                // setCashBankData(data.payload.total_cash_and_bank);
                // setCashBankDetails(data.payload.cash_bank_details);
                // setLiabilitiesData(data.payload.short_term_liabilities);
                // setLiabilitiesDetails(data.payload.short_term_liabilities_detail);
                // setTotalFeesData(data.payload.total_fees);
                // setTotalFeesDetails(data.payload.fees_detail);
                // setReceiptsData(data.payload.total_other_receipts);
                // setReceiptsDetails(data.payload.other_receipts_detail);
                // setSchoolExpenseData(data.payload.total_school_expense);
                // setSchoolExpenseDetails(data.payload.school_expense_detail);
                // setSocietyExpenseData(data.payload.total_society_expense);
                // setSocietyExpenseDetails(data.payload.society_expense_details);
                setReportData(data.payload);
                setShowTable(true);
              }
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          alert.error("Something wents wronge");
          setLoading(false);
        });
    });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const onRadioButtonClick = (e) => {
    const value = e.target.value;

    console.log(value);
    setSelected(e.target.value);
  };
  return (
    <>
      <div>
        <Paper
          style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
        >
          <div>
            <div className="row">
              <div className="col-md-1 mr-8">
                <img
                  style={{ height: 100, width: 110 }}
                  src={icon}
                  alt="logo"
                />
              </div>
              <div className="col-md-8">
                <h1>{unitName}</h1>
                <h3>Daily Transaction Report for the Date of {date}</h3>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-4 text-center">
                <input
                  checked={selected == "annual"}
                  onChange={(e) => onRadioButtonClick(e)}
                  defaultChecked
                  type="radio"
                  id="annual"
                  value="annual"
                />
                <label for="annual">Annual Wise</label>
              </div>

              <div className="col-sm-4 text-center">
                <input
                  checked={selected == "monthly"}
                  onChange={(e) => onRadioButtonClick(e)}
                  type="radio"
                  id="monthly"
                  value="monthly"
                />
                <label for="monthly">Monthly Wise</label>
              </div>

              <div className="col-sm-4 text-center">
                <input
                  checked={selected == "daily"}
                  onChange={(e) => onRadioButtonClick(e)}
                  type="radio"
                  id="daily"
                  value="daily"
                />
                <label for="daily">Daily Wise</label>
              </div>
            </div>
            <hr />
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label>
                    <b>Select Session</b>
                  </label>
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    name="year_id"
                    value={yearId}
                    aria-label="Default select example"
                    onChange={(e) => setYearId(e.target.value)}
                  >
                    {sessionData &&
                      sessionData.map((item) => (
                        <option value={item.id}>{item.year}</option>
                      ))}
                  </select>
                </div>

                <div className="row col-md-3">
                  <label>
                    <b>Select Date</b>
                  </label>
                  <input
                    onChange={(e) => handleDateOneChange(e.target.value)}
                    style={{ width: "250px", height: "39px" }}
                    type="date"
                  />
                </div>
                <div className="col-md-4 mt-9">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onshowClick}
                  >
                    Show
                  </button>
                  {showTable ? (
                    <ReactToPrint
                      documentTitle={`Daily Transaction`}
                      pageStyle="auto"
                      onAfterPrint={handleAfterPrint}
                      onBeforeGetContent={handleOnBeforeGetContent}
                      onBeforePrint={handleBeforePrint}
                      removeAfterPrint={true}
                      trigger={() => (
                        <button
                          // onClick={() => console.log("dfsdfsdfd")}
                          disabled={printloading ? true : false}
                          style={{ marginLeft: "10px" }}
                          className="btn btn-success button_blue button_blue"
                        >
                          {printloading ? <span>Printing..</span> : "Print"}
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                  ) : null}
                </div>
              </div>
              <br />
            </form>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <div id="tableboot"></div>
            )}
          </div>
        </Paper>

        <div>
          <Paper
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              padding: "15px",
            }}
          >
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>A/C Code</th>
                  <th>Account Name</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>

              <tbody>
                {reportData.length > 0
                  ? reportData.map((item) => (
                      <>
                        {item.debit != 0 || item.credit != 0 ? (
                          <tr>
                            <td
                              style={{ color: "black", border: "none" }}
                              className="text_align_start text-primary"
                            >
                              <h5>{item?.acode}</h5>
                            </td>
                            <td
                              style={{ color: "black", border: "none" }}
                              className="text_align_start text-primary"
                            >
                              <h5>{item?.account_name}</h5>
                            </td>
                            <td
                              style={{ color: "black", border: "none" }}
                              className="text_align_start text-primary"
                            >
                              <h5>{item?.debit}</h5>
                            </td>

                            <td
                              style={{ color: "black", border: "none" }}
                              className="text_align_start text-primary"
                            >
                              <h5>{item?.credit}</h5>
                            </td>
                          </tr>
                        ) : null}

                        {item?.account_charts.map((subAccout) => (
                          <>
                            {subAccout.total_debit != 0 ||
                            subAccout.total_credit != 0 ? (
                              <>
                                <tr id="tbl">
                                  <td
                                    style={{ color: "black", border: "none" }}
                                    className="text_align_start"
                                  >
                                    <h6>{subAccout.acode}</h6>
                                  </td>
                                  <td
                                    style={{ color: "black", border: "none" }}
                                    className="text_align_start"
                                  >
                                    <h6>{subAccout.account_name}</h6>
                                  </td>
                                </tr>

                                {subAccout?.sub_accounts.length > 0
                                  ? subAccout.sub_accounts.map((subitem) => (
                                      <tr id="tbl">
                                        <td
                                          style={{
                                            color: "black",
                                            border: "none",
                                          }}
                                          className="text_align_start"
                                        >
                                          {subitem.acode}
                                        </td>
                                        <td
                                          style={{
                                            color: "black",
                                            border: "none",
                                          }}
                                          className="text_align_start"
                                        >
                                          {subitem.account_name}
                                        </td>
                                        <td
                                          style={{
                                            color: "black",
                                            border: "none",
                                          }}
                                          className="text_align_start"
                                        >
                                          {subitem.debit}
                                        </td>
                                        <td
                                          style={{
                                            color: "black",
                                            border: "none",
                                          }}
                                          className="text_align_start"
                                        >
                                          {subitem.credit}
                                        </td>
                                      </tr>
                                    ))
                                  : null}

                                <tr id="tbl">
                                  <td
                                    style={{ color: "black", border: "none" }}
                                    className="text_align_start"
                                  ></td>

                                  <td
                                    style={{
                                      color: "black",
                                      paddingLeft: "20%",
                                      border: "none",
                                    }}
                                    className="text_align_start"
                                  >
                                    Grand Total
                                  </td>
                                  <td
                                    style={{ color: "black", border: "none" }}
                                    className="text_align_start"
                                  >
                                    {subAccout.total_debit}
                                  </td>
                                  <td
                                    style={{ color: "black", border: "none" }}
                                    className="text_align_start"
                                  >
                                    {subAccout.total_credit}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  ></td>
                                </tr>
                              </>
                            ) : null}
                          </>
                        ))}
                      </>
                    ))
                  : null}
              </tbody>
              {/* for current data//////////////////////////// */}
              {/* 
                {currentData.debit !=0 || currentData.credit !=0? <tbody>
                  <tr>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{currentData?.acode}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{currentData?.account_name}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{currentData?.debit}</h5>
                    </td>

                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{currentData?.credit}</h5>
                    </td>
                  </tr>

            {currentDetails.map((item) => (
                    <>
                    {
                      item.total_debit !=0 || item.total_credit !=0?
                      <>
                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.acode}</h6>
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.account_name}</h6>
                        </td>
                      </tr>

                      {item.sub_account.map((subitem) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.acode}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.account_name}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.debit}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.credit}
                          </td>
                        </tr>
                      ))}

                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{
                            color: "black",
                            paddingLeft: "20%",
                            border: "none",
                          }}
                          className="text_align_start"
                        >
                          Grand Total
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_debit}
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_credit}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>
                      </tr>
                      </>:null}
                    </>
                  ))}
                </tbody>:null} */}
              {/* for cash bank//////////////////////////////////////////////////////////////////// */}
              {/* {cashBankData.debit !=0 || cashBankData.credit !=0?   <tbody>
                  <tr>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{cashBankData?.acode}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{cashBankData?.account_name}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{cashBankData?.debit}</h5>
                    </td>

                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{cashBankData?.credit}</h5>
                    </td>
                  </tr>

                  {cashBankDetails.map((item) => (
                    <>
                    {
                      item.total_debit !=0 || item.total_credit !=0?
                      <>
                      
                    
                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.acode}</h6>
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.account_name}</h6>
                        </td>
                      </tr>

                      {item.sub_account.map((subitem) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.acode}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.account_name}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.debit}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.credit}
                          </td>
                        </tr>
                      ))}
                       
                   

                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{
                            color: "black",
                            paddingLeft: "20%",
                            border:"none"
                          }}
                          className="text_align_start"
                        >
                          Grand Total
                        </td>
                        <td
                          style={{ color: "black", border: "none"  }}
                          className="text_align_start"
                        >
                          {item.total_debit}
                        </td>
                        <td
                          style={{ color: "black", border: "none"  }}
                          className="text_align_start"
                        >
                          {item.total_credit}
                        </td>
                        <td
                          style={{ color: "black" ,border: "none" }}
                          className="text_align_start"
                        ></td>
                      </tr>
                      </>
                      :null }
                    </>
                  ))}
                </tbody>:null} */}
              {/* ////for liabilities */}
              {/* 
                {liabilitiesData.debit !=0 || liabilitiesData.credit !=0? <tbody>
                  <tr>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{liabilitiesData?.acode}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{liabilitiesData?.account_name}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{liabilitiesData?.debit}</h5>
                    </td>

                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{liabilitiesData?.credit}</h5>
                    </td>
                  </tr>

                  {liabilitiesDetails.map((item) => (
                    <>
                    {
                      item.total_debit !=0 || item.total_credit!=0?
                      <>
                    
                   
                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.acode}</h6>
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.account_name}</h6>
                        </td>
                      </tr>

                      {item.sub_account.map((subitem) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.acode}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.account_name}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.debit}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.credit}
                          </td>
                        </tr>
                      ))}

                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{
                            color: "black",
                            paddingLeft: "20%",
                            border: "none",
                          }}
                          className="text_align_start"
                        >
                          Grand Total
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_debit}
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_credit}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>
                      </tr>
                      </>:null}
                    </>
                  ))}
                </tbody>:null} */}
              {/* // for total fees */}

              {/* {totalFeesData.debit !=0 || totalFeesData.credit !=0?  <tbody>
                  <tr>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{totalFeesData?.acode}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{totalFeesData?.account_name}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{totalFeesData?.debit}</h5>
                    </td>

                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{totalFeesData?.credit}</h5>
                    </td>
                  </tr>

                  {totalFeesDetails.map((item) => (
                    <>
                    {
                      item.total_debit !=0 || item.total_credit !=0?
                      <>
                    
                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.acode}</h6>
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.account_name}</h6>
                        </td>
                      </tr>

                      {item.sub_account.map((subitem) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.acode}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.account_name}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.debit}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.credit}
                          </td>
                        </tr>
                      ))}

                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{
                            color: "black",
                            paddingLeft: "20%",
                            border: "none",
                          }}
                          className="text_align_start"
                        >
                          Grand Total
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_debit}
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_credit}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>
                      </tr>
                    </>:null}
                    </>
                  ))}
                </tbody>:null} */}

              {/* ///Receipts//////////// */}
              {/* {receiptsData.debit !=0 || receiptsData.credit !=0?  <tbody>
                  <tr>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{receiptsData?.acode}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{receiptsData?.account_name}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{receiptsData?.debit}</h5>
                    </td>

                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{receiptsData?.credit}</h5>
                    </td>
                  </tr>

                  {receiptsDetails.map((item) => (
                    <>
                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.acode}</h6>
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.account_name}</h6>
                        </td>
                      </tr>

                      {item.sub_account.map((subitem) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.acode}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.account_name}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.debit}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.credit}
                          </td>
                        </tr>
                      ))}

                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{
                            color: "black",
                            paddingLeft: "20%",
                            border: "none",
                          }}
                          className="text_align_start"
                        >
                          Grand Total
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_debit}
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_credit}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>
                      </tr>
                    </>
                  ))}
                </tbody>:null} */}
              {/* /////school expenses */}
              {/* {schoolExpenseData.debit !=0 || schoolExpenseData.credit !=0?  <tbody>
                  <tr>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{schoolExpenseData?.acode}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{schoolExpenseData?.account_name}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{schoolExpenseData?.debit}</h5>
                    </td>

                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{schoolExpenseData?.credit}</h5>
                    </td>
                  </tr>

                  {schoolExpenseDetails.map((item) => (
                    <>
                    {
                      item.total_debit !=0 || item.total_credit? 
                      <>
                    
                    
                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.acode}</h6>
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.account_name}</h6>
                        </td>
                      </tr>

                      {item.sub_account.map((subitem) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.acode}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.account_name}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.debit}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.credit}
                          </td>
                        </tr>
                      ))}

                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{
                            color: "black",
                            paddingLeft: "20%",
                            border: "none",
                          }}
                          className="text_align_start"
                        >
                          Grand Total
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_debit}
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_credit}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>
                      </tr>
                      </>
                :null  
                }
                    </>
                  ))}
                
                </tbody>:null} */}
              {/* ///society_expense */}
              {/* {societyExpenseData.debit !=0 || societyExpenseData.credit !=0?  <tbody>
                  <tr>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{societyExpenseData?.acode}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{societyExpenseData?.account_name}</h5>
                    </td>
                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{societyExpenseData?.debit}</h5>
                    </td>

                    <td
                      style={{ color: "black", border: "none" }}
                      className="text_align_start text-primary"
                    >
                      <h5>{societyExpenseData?.credit}</h5>
                    </td>
                  </tr>

                  {societyExpenseDetails.map((item) => (
                    
                    <>
                    {
                      item.total_debit!=0 || item.total_credit !=0?
                      <>
                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.acode}</h6>
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          <h6>{item.account_name}</h6>
                        </td>
                      </tr>

                      {item.sub_account.map((subitem) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.acode}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.account_name}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.debit}
                          </td>
                          <td
                            style={{ color: "black", border: "none" }}
                            className="text_align_start"
                          >
                            {subitem.credit}
                          </td>
                        </tr>
                      ))}

                      <tr id="tbl">
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        ></td>

                        <td
                          style={{
                            color: "black",
                            paddingLeft: "20%",
                            border: "none",
                          }}
                          className="text_align_start"
                        >
                          Grand Total
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_debit}
                        </td>
                        <td
                          style={{ color: "black", border: "none" }}
                          className="text_align_start"
                        >
                          {item.total_credit}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        ></td>
                      </tr>
                      </>:null}
                    </>
                  ))}
                </tbody>:null} */}
            </Table>
          </Paper>
        </div>
      </div>

      {/* /////for print  */}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div>
          <Paper
            style={{
              padding: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <div>
                <img
                  style={{ height: 100, width: 110 }}
                  src={icon}
                  alt="logo"
                />
              </div>
              <div className="col-md-8">
                <h1>{unitName}</h1>
                <h3>Daily Transaction Report for the Date of {date}</h3>
              </div>
            </div>
            <br />
            <Table responsive style={{ padding: "1px", whiteSpace: "normal" }}>
              <thead
                style={{
                  padding: "1px",
                  whiteSpace: "normal",
                }}
              >
                <tr
                  id="tbl"
                  style={{
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                >
                  <th
                    style={{
                      fontSize: "20px",
                      padding: "1px",
                      whiteSpace: "normal",
                    }}
                  >
                    A/C Code
                  </th>
                  <th
                    style={{
                      fontSize: "20px",
                      padding: "1px",
                      whiteSpace: "normal",
                    }}
                  >
                    Account Name
                  </th>
                  <th
                    style={{
                      fontSize: "20px",
                      padding: "1px",
                      whiteSpace: "normal",
                    }}
                  >
                    Debit
                  </th>
                  <th
                    style={{
                      fontSize: "20px",
                      padding: "1px",
                      whiteSpace: "normal",
                    }}
                  >
                    Credit
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  padding: "1px",
                  whiteSpace: "normal",
                }}
              >
                {reportData.length > 0
                  ? reportData.map((item) => (
                      <>
                        {item.debit != 0 || item.credit != 0 ? (
                          <tr
                            style={{
                              padding: "1px",
                              whiteSpace: "normal",
                            }}
                          >
                            <td
                              style={{
                                padding: "1px",
                                whiteSpace: "normal",
                                color: "black",
                                border: "none",
                              }}
                              className="text_align_start text-primary"
                            >
                              <h5 style={{ fontSize: "20px" }}>
                                {item?.acode}
                              </h5>
                            </td>
                            <td
                              style={{
                                padding: "1px",
                                whiteSpace: "normal",
                                color: "black",
                                border: "none",
                              }}
                              className="text_align_start text-primary"
                            >
                              <h5 style={{ fontSize: "20px" }}>
                                {item?.account_name}
                              </h5>
                            </td>
                            <td
                              style={{
                                padding: "1px",
                                whiteSpace: "normal",
                                color: "black",
                                border: "none",
                              }}
                              className="text_align_start text-primary"
                            >
                              <h5 style={{ fontSize: "20px" }}>
                                {item?.debit}
                              </h5>
                            </td>

                            <td
                              style={{
                                padding: "1px",
                                whiteSpace: "normal",
                                color: "black",
                                border: "none",
                              }}
                              className="text_align_start text-primary"
                            >
                              <h5 style={{ fontSize: "20px" }}>
                                {item?.credit}
                              </h5>
                            </td>
                          </tr>
                        ) : null}

                        {item?.account_charts.map((subAccout) => (
                          <>
                            {subAccout.total_debit != 0 ||
                            subAccout.total_credit != 0 ? (
                              <>
                                <tr
                                  id="tbl"
                                  style={{
                                    padding: "1px",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "1px",
                                      whiteSpace: "normal",
                                      color: "black",
                                      border: "none",
                                    }}
                                    className="text_align_start"
                                  >
                                    <h6 style={{ fontSize: "20px" }}>
                                      {subAccout.acode}
                                    </h6>
                                  </td>
                                  <td
                                    style={{
                                      padding: "1px",
                                      whiteSpace: "normal",
                                      color: "black",
                                      border: "none",
                                    }}
                                    className="text_align_start"
                                  >
                                    <h6 style={{ fontSize: "20px" }}>
                                      {subAccout.account_name}
                                    </h6>
                                  </td>
                                </tr>

                                {subAccout?.sub_accounts.length > 0
                                  ? subAccout.sub_accounts.map((subitem) => (
                                      <tr
                                        id="tbl"
                                        style={{
                                          padding: "1px",
                                          whiteSpace: "normal",
                                          color: "black",
                                          border: "none",
                                        }}
                                      >
                                        <td
                                          style={{
                                            fontSize: "20px",
                                            padding: "1px",
                                            whiteSpace: "normal",
                                            color: "black",
                                            border: "none",
                                          }}
                                          className="text_align_start"
                                        >
                                          {subitem.acode}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "20px",
                                            padding: "1px",
                                            whiteSpace: "normal",
                                            color: "black",
                                            border: "none",
                                          }}
                                          className="text_align_start"
                                        >
                                          {subitem.account_name}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "20px",
                                            padding: "1px",
                                            whiteSpace: "normal",
                                            color: "black",
                                            border: "none",
                                          }}
                                          className="text_align_start"
                                        >
                                          {subitem.debit}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "20px",
                                            padding: "1px",
                                            whiteSpace: "normal",
                                            color: "black",
                                            border: "none",
                                          }}
                                          className="text_align_start"
                                        >
                                          {subitem.credit}
                                        </td>
                                      </tr>
                                    ))
                                  : null}

                                <tr
                                  id="tbl"
                                  style={{
                                    padding: "1px",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "1px",
                                      whiteSpace: "normal",
                                      color: "black",
                                      border: "none",
                                    }}
                                    className="text_align_start"
                                  ></td>

                                  <td
                                    style={{
                                      padding: "1px",
                                      whiteSpace: "normal",
                                      color: "black",
                                      paddingLeft: "20%",
                                      border: "none",
                                    }}
                                    className="text_align_start"
                                  >
                                    <b style={{ fontSize: "20px" }}>
                                      Grand Total
                                    </b>
                                  </td>
                                  <td
                                    style={{
                                      padding: "1px",
                                      whiteSpace: "normal",
                                      color: "black",
                                      border: "none",
                                      borderTop: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                    className="text_align_start"
                                  >
                                    <b style={{ fontSize: "20px" }}>
                                      {subAccout.total_debit}
                                    </b>
                                  </td>
                                  <td
                                    style={{
                                      padding: "1px",
                                      whiteSpace: "normal",
                                      color: "black",
                                      border: "none",
                                      borderTop: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                    className="text_align_start"
                                  >
                                    <b style={{ fontSize: "20px" }}>
                                      {subAccout.total_credit}
                                    </b>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                          </>
                        ))}
                      </>
                    ))
                  : null}
              </tbody>
            </Table>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default DailyTransaction;
