import React from "react";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { Checkbox, Divider } from "antd";
import { Spinner, Table } from "react-bootstrap";
import StudentFigureExport from "./StudentFigureExport";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

const StudentFigureDateWise = () => {
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);

  const [dateData, setDateData] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [isVisible, setIsVisible] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setselected] = useState("Simple");
  const [reportData, setReportData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [summary, setSummary] = useState(false);
  const [grandRetrain, setGrandRetrain] = useState(0);
  const [grandLeft, setGrandLeft] = useState(0);
  const [grandSummary, setGrandSummary] = useState(0);
  const [grandMale, setGrandMale] = useState(0);
  const [grandFemale, setGrandFemale] = useState(0);
  const [grandStrength, setGrandStrength] = useState(0);
  const [educationType, setEducationType] = useState(1);

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const alert = useAlert();

  useEffect(() => {
    sessions();
    // getAllCampus();
  }, []);
  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onRadioChanged = (e) => {
    setselected(e.target.value);
  };

  const showResults = () => {
    setLoading(true);

    const data = {
      date: dateData,
      campus_ids: campusId ? [parseInt(campusId)] : [],
    };
    fetch(`${baseUrl}/student-figure-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setLoading(false);
          setIsVisible(true);

          let sum = 0;
          let sum2 = 0;
          let sum3 = 0;
          data.payload.forEach((yup) => {
            yup.classes.forEach((element) => {
              element.sections.map((suitem) => {
                sum += parseInt(suitem?.report?.male);
              });
            });
          });
          data.payload.forEach((yup) => {
            yup.classes.forEach((element) => {
              element.sections.map((suitem) => {
                sum2 += parseInt(suitem?.report?.female);
              });
            });
          });
          data.payload.forEach((yup) => {
            yup.classes.forEach((element) => {
              element.sections.map((suitem) => {
                sum3 += parseInt(suitem?.report?.strength);
              });
            });
          });
          setGrandMale(sum);
          setGrandFemale(sum2);
          setGrandStrength(sum3);
          setReportData(data.payload);
        }
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getMaleSum = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        sum += parseInt(suitem?.report?.male);
      });
    });
    return sum;
  };

  const getGrandStrenght = (data) => {
    let sum = 0;
    data.map((item) => {
      item.classes.forEach((element) => {
        element.sections.map((suitem) => {
          suitem.programs.map((here) => {
            sum += parseInt(here?.report?.strength);
          });
        });
      });
    });
    setGrandSummary(sum);
  };
  const getfeMaleSum = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        sum += parseInt(suitem?.report?.female);
      });
    });
    return sum;
  };
  const getStrengthSum = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        sum += parseInt(suitem?.report?.strength);
      });
    });
    return sum;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label>
              <b>
                Select Date{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <div className="d-flex flex-column">
              <input
                value={dateData}
                className="form-control"
                onChange={(e) => setDateData(e.target.value)}
                style={{ height: 40 }}
                type="date"
              />
            </div>
          </div>

          <div className="col-md-4">
            <label>
              <b>Select Campus</b>{" "}
              <span style={{ color: "red", fontSize: 12 }}> *</span>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40, fontSize: "15px" }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="d-flex col-md-1">
            <button
              disabled={loading}
              className="btn btn-primary mt-9"
              onClick={showResults}
            >
              Show
            </button>
            {isVisible && (
              <StudentFigureExport
                reportData={reportData}
                grandStrength={grandStrength}
                grandFemale={grandFemale}
                grandMale={grandMale}
                getStrengthSum={getStrengthSum}
                getfeMaleSum={getfeMaleSum}
                getMaleSum={getMaleSum}
              />
            )}
          </div>
        </div>
        <Divider />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Campus Name</th>
                  <th className="text-center">Class Name</th>
                  <th className="text-center">Male</th>
                  <th className="text-center">Female</th>
                  <th className="text-center">Strength</th>
                </tr>
              </thead>
              <tbody>
                {reportData.length > 0 ? (
                  <>
                    {reportData.map((item) => (
                      <>
                        <tr
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          <td
                            style={{
                              fontSize: "1.2em",
                              borderTop: "1px solid black",
                            }}
                            className="text_align_center text-primary"
                          >
                            {item.Campus}
                          </td>
                          <td></td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getMaleSum(item)}
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getfeMaleSum(item)}
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getStrengthSum(item)}
                          </td>
                        </tr>

                        {item?.classes?.map((subitem, indu) => (
                          <>
                            <tr id="tbl">
                              <td></td>
                              <td
                                style={{
                                  color: "black",
                                  fontSize: "1rem",
                                }}
                                className="text-center"
                              >
                                {subitem?.Class?.name}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  fontSize: "1rem",
                                }}
                                className="text-center"
                              >
                                {subitem.sections.reduce(
                                  (a, c) => a + parseInt(c.report.male),
                                  0
                                )}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  fontSize: "1rem",
                                }}
                                className="text-center"
                              >
                                {subitem.sections.reduce(
                                  (a, c) => a + parseInt(c.report.female),
                                  0
                                )}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  fontSize: "1rem",
                                }}
                                className="text-center"
                              >
                                {subitem.sections.reduce(
                                  (a, c) => a + parseInt(c.report.strength),
                                  0
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    ))}
                    <tr>
                      <td
                        colSpan={2}
                        style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                        className="text-center text-primary"
                      >
                        Grand Total:-
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {grandMale}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {grandFemale}
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {grandStrength}
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          </div>
        )}
      </Paper>
    </>
  );
};

export default StudentFigureDateWise;
