import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Tab, Tabs, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import Barcode from "react-barcode";
import { baseUrl } from "../../../services/config";
import FeeReceviceModal from "../../../components/FeeReceviceModal/FeeReceviceModal";
import { useAlert } from "react-alert";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../components/usePagination/usePagination";

const icon =
  "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";

function AllClampusStudent({
  getStudentsForFeeGenerate,
  getcurrentsession,
  getCampusName,
}) {
  const [campuses, setallcampuses] = useState([]);
  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [postPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [allcampusstudent, setallcampusstudent] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [campusid, setcampusid] = useState("");
  const [classes, setclasses] = useState([]);
  const [classid, setclassid] = useState("");
  const [selectedclass, setselectedclass] = useState({});
  const [selectedstudentsarray, setselectedstudentsarray] = useState([]);
  const [sections, setsections] = useState([]);
  const [sectionid, setsectionid] = useState("");
  const [dataloader, setdataloader] = useState(false);
  const [campusName, setCampusName] = useState("");

  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  const [allsessions, setallsessions] = useState([]);

  const [currentsession, setcurrentsession] = useState("");
  const [searchtext, setsearchtext] = useState("");

  const alert = useAlert();

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            setcurrentsession(item.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("campus", err);
      });
  };

  const PRINTCOUNT = [1, 2, 3];

  let array = [
    { id: 1, name: "Junaid", class: "5th", section: "Hifz" },
    { id: 2, name: "Ahmad", class: "6th", section: "ali" },
  ];

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;

  //   const currentPosts = allcampusstudent.slice(
  //     indexOfFirstPost,
  //     indexOfLastPost
  //   );

  //   const dataSearch = allheadofficeunpaidchallans.filter((user) => {

  //     let text = searchtext.toLowerCase()
  //     return searchtext ? user.challan_no.toLowerCase().includes(text) : true
  // })

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    allcampusstudent.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    setallcampusstudent([...middlearray]);
  };
  const allcheckboxChecked = () => {
    let middlearray = [];
    let studentarray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      allcampusstudent.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
      getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    } else {
      setcheckallcheckbox(true);
      allcampusstudent.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
      getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    }

    setallcampusstudent([...middlearray]);
  };
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(allcampusstudent.length / PER_PAGE);
  const _DATA = usePagination(allcampusstudent, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const searchbyRegId = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_name_or_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            data.payload.map((campus) => {
              setcampusid(campus.campus_id);
              setclassid(campus.student_class_id);
              setsectionid(campus.global_section_id);
              setcurrentsession(campus.session_id);

              //  alert.show(campus.campus_id);

              // alert.show(campus.student_class_id);
              //  alert.show(campus.global_section_id);
              //   alert.show(campus.session_id);
              getStudentsForFeeGenerate(
                data,
                campus.campus_id,
                campus.student_class_id,
                campus.global_section_id
              );
            });
            //alert.show(campusid);

            // alert.show(sectionid);
            // alert.show(currentsession);
            // alert.show(classid);
            // if (data.payload.length === 0) {
            //   alert.error("Match not found");
            // }

            let checkboxes = data.payload;
            data.payload.map((item, index) => {
              item["checked"] = false;
              checkboxes[index] = item;
            });
            let middlearray = [];
            let studentarray = [];
            setcheckallcheckbox(true);
            checkboxes.map((item, i) => {
              item.checked = true;
              studentarray.push(item.id);
              middlearray.push(item);
            });
            getStudentsForFeeGenerate(
              studentarray,
              campusid,
              classid,
              sectionid
            );
            setallcampusstudent([...middlearray]);
            setallcampusstudent([...checkboxes]);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  return (
    <div>
      <div class="form-group row">
        <div className="col-md-8">
          <form className="row mt-9" onSubmit={searchbyRegId}>
            <div className="col-md-8">
              <input
                onChange={(e) => setsearchtext(e.target.value)}
                className="form-control"
                placeholder="Enter Name or Admission ID"
              />
            </div>
            <div className="col-md-4">
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>Student Name</th>
            <th>Monthly Fee</th>
            <th>Addmission ID</th>
            <th>Student Class</th>
            <th>Class Section</th>
            <th>Campus</th>
            <th>
              <input
                onClick={(e) => {
                  allcheckboxChecked();
                }}
                checked={checkallcheckbox}
                style={{}}
                placeholder="Checkbox"
                type="checkbox"
              />{" "}
              <span style={{ marginleft: "15px" }}>Check All</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {_DATA.currentData().map((feechallan, index) => (
            <tr key={feechallan.id}>
              <td
                // title={feechallan.name.length > 15 ? feechallan.name : null}
                style={{ textAlign: "start", cursor: "pointer" }}
              >
                {feechallan.name}
              </td>
              {feechallan.liable_fees.map((fee) =>
                fee.fees_type_id == 4 ? (
                  <td
                    title={fee.amount}
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {fee.amount}
                    {localStorage.setItem("monthly", fee.amount)}
                  </td>
                ) : null
              )}

              <td style={{ textAlign: "start" }}>{feechallan.admission_id}</td>
              <td style={{ textAlign: "start" }}>
                {feechallan.student_class.name}
              </td>
              <td style={{ textAlign: "start" }}>
                {feechallan.global_section.name}
              </td>
              <td>{feechallan.campus.name}</td>
              <td style={{ textAlign: "start" }}>
                <input
                  key={feechallan.id}
                  onClick={(e) => {
                    checkBoxClick(e, feechallan.id);
                  }}
                  value={allcheckboxes[index]}
                  checked={feechallan.checked}
                  type="checkbox"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        color="primary"
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
      />

      <div className="row justify-content-center">
        {dataloader && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
    </div>
  );
}

export default AllClampusStudent;
