
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { Spinner } from "react-bootstrap";
import { Switch } from "antd";

export function NotiFormatter(
  cellContent,
  row,
  rowIndex,
  {openEditCustomerDialog, openDeleteCustomerDialog}
) {
  //console.log('ye row', row)

  return (
    <>
<Switch size='small'  onChange={(e)=>{console.log(e)}}/>
        <a
          title="Edit"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditCustomerDialog(row)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>


        <a
          title="Delete"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => openDeleteCustomerDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>

    </>
  );
}
