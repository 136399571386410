import React, { useEffect, useState, useMemo } from "react";
import { baseUrl } from "../../../../services/config";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import logo1 from "../../../_assets/images/logo2.png";

export function Brand() {
  const [unitName, setUnitName] = useState("");

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(data.payload.settings.unit_name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* begin::Brand */}
      <div
        style={{ backgroundColor: "#121291" }}
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <h4
            style={{
              color: "#fff",
              marginLeft: 20,
              display: "flex",
              flexWrap: 2,
            }}
          >
            {unitName}
          </h4>
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-double-left.svg"
                  )}
                />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
