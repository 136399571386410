/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as auth from "../Auth";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";

function ChangePassword() {
  // Fields
  const [loading, setloading] = useState(false);
  const [isError, setisError] = useState(false);
  // const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  useEffect(() => {}, [user]);
  // Methods
  const changePasswordHandler = async (values, setStatus, setSubmitting) => {
    setloading(true);
    // setisError(false);

    const fd = new FormData();
    fd.append("old_password", values.current_password);
    fd.append("password", values.new_password);
    fd.append("password_confirmation", values.confirm_password);

    fd.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    await fetch(`${baseUrl}/change-password`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          alert.success("Password changed successfully");
          setloading(false);
          setSubmitting(false);
          history.push("/systemdashboard");
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg);
            setloading(false);
            setSubmitting(false);
          });
        }
        // setAssignModal(false);
        console.log(data);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });

    // const updatedUser = Object.assign(user, {
    //   password: values.password,
    // });
    // // user for update preparation
    // dispatch(props.setUser(updatedUser));

    // setTimeout(() => {
    //   setloading(false);
    //   setSubmitting(false);
    //   setisError(true);
    //   // Do request to your server for user update, we just imitate user update there, For example:
    //   // update(updatedUser)
    //   //  .then(()) => {
    //   //    setloading(false);
    //   //  })
    //   //  .catch((error) => {
    //   //    setloading(false);
    //   //    setSubmitting(false);
    //   //    setStatus(error);
    //   // });
    // }, 1000);
  };
  // UI Helpers
  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  const Schema = Yup.object().shape({
    current_password: Yup.string().required("Current password is required"),
    new_password: Yup.string()
      .min(8, "Minimum 8 characters")
      .required("New Password is required"),
    confirm_password: Yup.string()
      .min(8, "Minimum 8 characters")
      .required("Password confirmation is required")
      .when("new_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("new_password")],
          "New Password and Confirm Password didn't match"
        ),
      }),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      changePasswordHandler(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Change Password
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Change your account password
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-primary mr-4"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          {/* <Link
            to="/user-profile/profile-overview"
            className="btn btn-secondary"
          >
            Cancel
          </Link> */}
          {/* <Link to="/systemdashboard">
            <button className="btn btn-secondary">Cancel</button>
          </Link> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Alert */}
          {/* {isError && (
            <div
              className="alert alert-custom alert-light-danger fade show mb-10"
              role="alert"
            >
              <div className="alert-icon">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                  ></SVG>{" "}
                </span>
              </div>
              <div className="alert-text font-weight-bold">
                Configure user passwords to expire periodically. Users will need
                warning that their passwords are going to expire,
                <br />
                or they might inadvertently get locked out of the system!
              </div>
              <div className="alert-close" onClick={() => setisError(false)}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
          )} */}
          {/* end::Alert */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Current Password
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Current Password"
                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                  "current_password"
                )}`}
                name="current_password"
                {...formik.getFieldProps("current_password")}
              />
              {formik.touched.current_password &&
              formik.errors.current_password ? (
                <div className="invalid-feedback">
                  {formik.errors.current_password}
                </div>
              ) : null}
              {/* <a href="#" className="text-sm font-weight-bold">
                Forgot password ?
              </a> */}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              New Password
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="New Password"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "new_password"
                )}`}
                name="new_password"
                {...formik.getFieldProps("new_password")}
              />
              {formik.touched.new_password && formik.errors.new_password ? (
                <div className="invalid-feedback">
                  {formik.errors.new_password}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Confirm Password
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Confirm Password"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "confirm_password"
                )}`}
                name="confirm_password"
                {...formik.getFieldProps("confirm_password")}
              />
              {formik.touched.confirm_password &&
              formik.errors.confirm_password ? (
                <div className="invalid-feedback">
                  {formik.errors.confirm_password}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* end::Form */}
    </form>
  );
}

export default connect(null, auth.actions)(ChangePassword);
