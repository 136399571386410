import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from "../../../../src/_metronic/_partials/controls";
import { Modal, Spinner } from "react-bootstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { baseUrl } from "../../../services/config";
import AddRole from "./AddRole";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Checkbox, Divider, Tooltip } from "antd";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { PremissionChecker } from "../../../services/PremissionChecker";
import "./styles.css";

const Roles = () => {
  const user = useSelector((state) => state.user.user);

  let authtoken = localStorage.getItem("userToken");

  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermRole, setSearchTermRole] = useState("");

  const [isLoading, setisLoading] = useState(false);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [loading, setloading] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [roles, setroles] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [SelectedId, setSelectedId] = useState("");
  const [attachModal, setattachModal] = useState(false);
  const [list, setList] = useState([]);
  const [selecetedPerm, setselecetedPerm] = useState([]);
  const [myRoleIndexArray, setMyRoleIndexArray] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getAllBrands();
  }, []);

  console.log(list);
  const getAllBrands = () => {
    setloading(true);
    fetch(`${baseUrl}/roles`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false);
        console.log("brands List", data.payload);
        let newRoles = data.payload;
        data.payload.map((item, i) => {
          item["isLoading"] = false;
          item["sr_no"] = i + 1;
          newRoles[i] = item;
        });
        console.log("ne", newRoles);
        setroles(newRoles);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const options = {
    custom: true,
    totalSize: roles.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const updatingQueryParams = (page) => {
    console.log(page);
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "Sr.No",
      classes: "text-left pr-0",
    },
    {
      dataField: "name",
      text: "Role Name",
      classes: "text-left pr-0",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.RolesFormatter,
      formatExtraData: {
        user: user,
        attachPermissions: (item, i) => openAttachModal(item, i),
        openEditCustomerDialog: (item) => openEdit(item),
        openDeleteCustomerDialog: (id) => opendeleteModal(id),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const openAttachModal = (item, index) => {
    let arr = [...roles];
    arr[index].isLoading = true;
    setroles(arr);
    getPermissionsbyRole(item.id, index);
    // setSelectedId(item.id)
    // setattachModal(true)
  };

  const opendeleteModal = (id) => {
    setSelectedId(id);
    setdeleteModal(true);
  };

  const openEdit = (item) => {
    setSelectedObj(item);
    seteditModal(true);
    setaddModal(true);
  };

  const saveRole = (values) => {
    setError(null);
    setisLoading(true);
    let url = editModal ? `roles/${selectedObj.id}?_method=PUT` : "roles";

    fetch(`${baseUrl}/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setaddModal(false);
          toast.success(data.metadata.message);
          getAllBrands();
          setisLoading(false);
        } else if (!data.metadata.success) {
          setError(Object.values(data.metadata.message));
          setisLoading(false);
          // Object.values(data.metadata.message).map(msg => {
          //     toast.error(msg)
          //     setisLoading(false)
          // })
        }
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    setloading(true);
    fetch(`${baseUrl}/asset/permissions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false);
        console.log("i think so permission", data.payload);

        data.payload.sort((a, b) => a.name.localeCompare(b.name));
        convertToIndexArray(data.payload);
        setList(data.payload);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const getPermissionsbyRole = (id, index) => {
    console.log("running");

    let arrCopy = [...roles];
    fetch(`${baseUrl}/roles/${id}/permissions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "permissions checked");
        let dummy = [];
        for (let i = 0; i < data.payload.length; i++) {
          dummy[i] = data.payload[i].id;
        }

        let checkboxes = list;
        list.map((item, index) => {
          if (dummy.includes(item.id)) {
            item["checked"] = true;
            item["selected"] = true;
            checkboxes[index] = item;
          } else {
            item["checked"] = false;
            item["selected"] = false;
            checkboxes[index] = item;
          }
        });
        console.log("List", checkboxes);

        setList(checkboxes);
        setSelectedId(id);
        arrCopy[index].isLoading = false;

        setroles(arrCopy);
        setattachModal(true);
        // if (data.metadata.success) {

        // }
      });
  };

  const onChangePerm = (perm) => {
    console.log(perm);
    setselecetedPerm(perm);
  };

  const attachSubmit = () => {
    const permissionsList = list
      .filter((item) => item.checked)
      .map((item) => item.id);
    const formData = new FormData();

    for (let i = 0; i < permissionsList.length; i++) {
      formData.append(`permissions[${i}]`, permissionsList[i]);
    }

    fetch(`${baseUrl}/roles/${SelectedId}/attach-permissions`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setattachModal(false);
          toast.success(data.metadata.message);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
            setattachModal(false);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxChange = (itemId) => {
    let previous = null;

    // for (let i = 0; i < myRoleIndexArray.length; i++) {
    //   if (myRoleIndexArray[i] === itemId) {
    //     previous = myRoleIndexArray[i - 1];
    //     break;
    //   } else if (myRoleIndexArray[i] < itemId) {
    //     previous = myRoleIndexArray[i];
    //   } else {
    //     break;
    //   }
    // }
    console.log(itemId);
    setList((prevData) => {
      const newData = [...prevData];
      const itemIndex = newData.findIndex((item) => item.id === itemId);
      newData[itemIndex].checked = !newData[itemIndex].checked;
      return newData;
    });
    // if(itemId !=previous){
    //   setList((prevData) => {
    //     const newData = [...prevData];
    //     const itemIndex = newData.findIndex((item) => item.id === previous+1);
    //     console.log(itemIndex)
    //     newData[itemIndex].checked = true
    //     return newData;
    //   });
    // }
    // console.log(previous)
    // console.log(itemId)
    // if(itemId ==previous){
    //   setList((prevData) => {
    //     const newData = [...prevData];
    //     const itemIndex = newData.findIndex((item) => item.id === previous+1);
    //     console.log(itemIndex)
    //     newData[itemIndex].checked = !newData[itemIndex].checked
    //     return newData;
    //   });
    // }
  };

  const handleCheckAllChange = (event) => {
    setList((prevData) => {
      const newData = [...prevData];
      newData.forEach((item) => (item.checked = event.target.checked));
      return newData;
    });
  };

  const convertToIndexArray = (array) => {
    const dummy = array.map((item, i) => {
      const newObj = new Object();
      newObj.index = i;
      newObj.name = item.name.split(".")[0];
      return newObj;
    });

    const uniqueArr = dummy.reduce((result, current) => {
      const existingObject = result.find((item) => item.name === current.name);
      if (!existingObject) {
        result.push(current);
      }
      return result;
    }, []);
    let finnal = [];
    uniqueArr.map((ss) => {
      finnal.push(ss.index);
    });

    console.log(finnal);
    setMyRoleIndexArray(finnal);
  };
  const textChanger = (text) => {
    const spacedStr = text.replace(/_/g, " ");

    const words = spacedStr.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const convertedStr = capitalizedWords.join(" ");
    return convertedStr;
  };

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    // Filter roles based on the search term
    const filtered = roles.filter((role) =>
      role.name.toLowerCase().includes(term)
    );

    setroles(filtered);
  };

  // const handleRoleSearchChange = (e) => {

  //   setSearchTermRole(term);

  //   // Filter roles based on the search term

  //   setList(filtered);
  //   convertToIndexArray(filtered);
  // };
  const filtered = list.filter((role) => {
    const term = searchTermRole.toLowerCase();
    return role?.description
      ? role?.description.toLowerCase().includes(term)
      : true;
  });

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <Card style={{ marginTop: "10px", padding: 0 }}>
        <CardHeader title="Roles list">
          <CardHeaderToolbar>
            {PremissionChecker(user, "role.create") ? (
              <>
                {/* <input
                  type="text"
                  placeholder="Search by permissions name"
                  className="form-group"
                  onChange={handleSearchChange}
                  value={searchTerm}
                /> */}

                <button
                  onClick={() => {
                    seteditModal(false);
                    setaddModal(true);
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  New Role
                </button>
              </>
            ) : null}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <>
                  <Pagination
                    updatingQueryParams={updatingQueryParams}
                    onPaginationSelectChange={onPaginationSelectChange}
                    isLoading={loading}
                    paginationProps={paginationProps}
                  >
                    {PremissionChecker(user, "role.list") ? (
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        keyField="id"
                        data={roles}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        {...paginationTableProps}
                      ></BootstrapTable>
                    ) : null}
                  </Pagination>
                  {/* )} */}
                </>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
      <Modal show={addModal}>
        <Modal.Header>{editModal ? "Edit Role" : "Add new Role"}</Modal.Header>
        <AddRole
          customer={{
            name: editModal ? selectedObj.name : "",
          }}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => saveRole(values)}
          onHide={() => setaddModal(false)}
          actionsLoading={isLoading}
          errors={error}
        />
      </Modal>

      <Modal centered size="xl" show={attachModal}>
        <Modal.Header>{"Attach Permission"}</Modal.Header>
        <Modal.Body>
          <div
            style={{ height: "500px", overflowY: "scroll" }}
            className="permsonScoller"
          >
            <div
              style={{
                position: "sticky",
                top: 2,
                backgroundColor: "white",
                zIndex: 9999,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div className="container-fluid">
                  <Checkbox onChange={handleCheckAllChange}>Check All</Checkbox>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by permissions name"
                    onChange={(e) => setSearchTermRole(e.target.value)}
                    value={searchTermRole}
                  />
                </div>
              </div>

              <Divider style={{ marginBottom: "0px" }} />
            </div>
            <div
            //   style={{
            //       display: 'flex',
            //       flexWrap: 'wrap',

            //   }}
            >
              <div className="row container-fluid">
                {filtered.map((user, i) => (
                  <>
                    {myRoleIndexArray.includes(i) ? (
                      <>
                        <h4 className={"mt-5"}>
                          {textChanger(user.name.split(".")[0])}
                        </h4>
                      </>
                    ) : null}
                    <div className="col-md-4">
                      <span
                      //   style={{ width: "25%"}}
                      >
                        <Checkbox
                          style={{ paddingRight: "30px", paddingTop: "5px" }}
                          className="givingspace"
                          checked={user.checked}
                          onChange={(event) => handleCheckboxChange(user.id)}
                        >
                          {/* <Tooltip placement="top" title={user.description}> */}
                          {user.description}
                          {/* </Tooltip> */}
                        </Checkbox>
                      </span>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => setattachModal(false)}
            className="btn btn-danger"
          >
            Cancel
          </button>
          {PremissionChecker(user, "role.permissions_attach") ? (
            <button onClick={() => attachSubmit()} className="btn btn-primary">
              Attach
            </button>
          ) : null}
        </Modal.Footer>
      </Modal>

      <DeleteModal
        HideModal={() => setdeleteModal(false)}
        visible={deleteModal}
        // id={SelectedId}
        method="DELETE"
        reload={getAllBrands}
        url={`${baseUrl}/roles/${SelectedId}`}
      />
    </div>
  );
};

export default Roles;
