import { useEffect } from "react";
// import { generateTokken, messaging } from "../../utils/firebaseUtils";
// import { onMessage } from "firebase/messaging";
import { baseUrl } from "../../services/config";

export const SendNotifaction = async (
  title,
  des,
  notifaction_name,
  notifaction_id,
  token2
) => {
  // const { GoogleAuth } = require("google-auth-library");

  // async function getAccessToken() {
  //   const auth = new GoogleAuth({
  //     keyFile: "serviceAccountKey.json", // Path to your service account key file
  //     scopes: ["https://www.googleapis.com/auth/cloud-platform"],
  //   });

  //   const client = await auth.getClient();
  //   const accessToken = await client.getAccessToken();
  //   console.log("Retrieved Token: ", accessToken.token);
  //   return accessToken.token;
  // }
  // const accessToken = await getAccessToken();

  // const getTokenFromFirebase = async () => {
  //   token = await generateTokken(); // Wait for the token to be generated
  //   //console.log("Retrieved Token: ", token);
  // };

  // getTokenFromFirebase(); // Call the function to get the token

  // const token = await generateTokken();

  let token = "";
  const authtoken = localStorage.getItem("userToken");

  fetch(`${baseUrl}/send-notification`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      token = data.message;
      console.log("Token: ", token);
    })
    .catch((err) => {
      console.log(err);
    });

  // if (token) {
  //   const notification = {
  //     body: des,
  //     title: title,
  //     notification_name: notifaction_name,
  //     notification_id: notifaction_id,
  //   };

  //   console.log("Tokeen Testing: ", token);

  //   try {
  //     const serverKey =
  //       "BF7Dk_CuWsuEzla_va5Rk_In3i6ADHLUbFH3mnuWnKp5Cm-C4ILojocU3eOkzTaqVxldN95nn6cnI85W5Qvzjws"; // Replace with your actual server key
  //     const fcmUrl =
  //       "https://fcm.googleapis.com/v1/projects/sms-app-6916f/messages:send";

  //     const response = await fetch(fcmUrl, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${serverKey}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         message: {
  //           token: token, // Replace with actual token
  //           notification: notification,
  //         },
  //       }),
  //     });

  //     // const response = await fetch("https://fcm.googleapis.com/fcm/send", {
  //     // const response = await fetch(
  //     //   "POST https://fcm.googleapis.com/v1/projects/sms-app-6916f/messages:send",
  //     //   {
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //       Authorization:
  //     //         "Bearer BF7Dk_CuWsuEzla_va5Rk_In3i6ADHLUbFH3mnuWnKp5Cm-C4ILojocU3eOkzTaqVxldN95nn6cnI85W5Qvzjws",
  //     //     },
  //     //     method: "POST",
  //     //     body: JSON.stringify({
  //     //       notification: notification,
  //     //       to: `/topics/${token}`,
  //     //     }),
  //     //   }
  //     // );

  //     const data = await response.json();
  //     console.log("response", data);
  //   } catch (error) {
  //     console.error("Error making API request:", error);
  //   }
  // }
};

export const sendNotificationsInBatches = async (
  title,
  des,
  notifaction_name,
  notifaction_id,
  ids
) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize);
    const batchPromises = batchIds.map((id) =>
      SendNotifaction(title, des, notifaction_name, notifaction_id, id)
    );
    await Promise.all(batchPromises);
  }
};

export const sendNotificationsInBatches2 = async (arrayofResult) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofResult.length; i += batchSize) {
    const batchIds = arrayofResult.slice(i, i + batchSize);
    const batchPromises = batchIds.map((item) =>
      SendNotifaction(item.title, item.description, item.id)
    );
    await Promise.all(batchPromises);
  }
};

export const sendExamNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds.map((item) =>
      SendNotifaction(item.title, item.description, "exam", item.id)
    );
    await Promise.all(batchPromises);
  }
};

export const sendNotificationsInBatches3 = async (
  selectAttendace,
  arrayofstudents
) => {
  // Define the batch size (e.g., 10 IDs per batch)
  console.log("ya chala?");
  const batchSize = 10;

  for (let i = 0; i < arrayofstudents.length; i += batchSize) {
    const batchIds = arrayofstudents.slice(i, i + batchSize);
    const batchPromises = batchIds.map((item) => {
      switch (selectAttendace) {
        case "1":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, your child ${item.name} is absent from school today without any information. Please contact at the earliest`,
            item.id
          );
          break;

        case "2":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, application for your child ${item.name} sick leave has been received, may he/she get well soon and attend the school at earliest`,
            item.id
          );
          break;
        case "3":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, Leave Application of your child ${item.name} has been received in the school office and approved.`,
            item.id
          );
          break;
        case "4":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, your child ${item.name} has been late from school today. Kindly pay attention on his/her discipline`,
            item.id
          );
          break;

        case "5":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, your child ${item.name} home work was not complete today, Kindly pay attention on his/her studies`,
            item.id
          );
          break;

        case "6":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, your child ${item.name} was not in proper uniform today. Please send your child in proper uniform.`,
            item.id
          );
          break;

        case "7":
          SendNotifaction(
            "Attendance Alert",
            `Dear Parent, your child ${item.name} test was not prepared. Please make sure he/she must focus on his/her studies.`,
            item.id
          );
          break;
      }
    });
    await Promise.all(batchPromises);
  }
};
