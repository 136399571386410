import { Paper } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "../../../../services/config";
import Select from "react-select";
import { useAlert } from "react-alert";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";
const MonthlyPaySummary = () => {
  const [monthDate, setMonthDate] = useState("");
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [campusName, setCampusName] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [printAbleData, setPrintAbleData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const exportTable = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 5,
      }}
    />
  );

  useEffect(() => {
    getSettingData();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showSummaryHandler = () => {
    if (monthDate == "") {
      alert.error("Month Is Required");
      return;
    }
    let campus = campusData.find((c) => c.id == campusId);
    setCampusName(campus ? campus.name : "");
    setIsVisible(false);
    setLoading(true);
    const data = {
      salary_month: `${monthDate}-01`,
      year_id: yearId,
      campus_id: campusId,
    };

    fetch(`${baseUrl}/monthly-pay-summary`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setLoading(false);
          setIsVisible(true);
          setReportData(data.payload);
        })
      )
      .catch((err) => {
        setLoading(false);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}, ${d.getFullYear()}`;
  };

  const todayDate = () => {
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3 mt-2">
            <label className="form-control-label">
              <b>
                Select Session
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="year_id"
              value={yearId}
              aria-label="Default select example"
              onChange={(e) => setYearId(e.target.value)}
            >
              <option value="">Select Session</option>
              {sessionData &&
                sessionData.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.year}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setCampusId(e.target.value);
              }}
            >
              <option value="">Select Campus</option>
              {campusData &&
                campusData.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Month</b>
            </label>
            <input
              value={monthDate}
              onChange={(e) => {
                setMonthDate(e.target.value);
              }}
              className="form-control"
              type="month"
            />
          </div>
          <div className="col-md-3 mt-9">
            <button className="btn btn-primary" onClick={showSummaryHandler}>
              Show
            </button>
            {reportData?.total_net_pay > 0 && (
              <React.Fragment>
                <ReactToPrint
                  documentTitle={`Monthly Pay Summary`}
                  pageStyle="auto"
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-105px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <DownloadTableExcel
                  filename="Monthly Pay Summary"
                  sheet="Monthly Pay Summary"
                  currentTableRef={exportTable.current}
                >
                  {reportData?.total_net_pay > 0 && (
                    <button className="btn btn-primary mx-2">Export</button>
                  )}
                </DownloadTableExcel>
              </React.Fragment>
            )}
          </div>
        </div>
        {loading ? (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : isVisible ? (
          <React.Fragment>
            <div id="tableboot">
              <div className="row p-3 mt-12">
                <img style={{ height: 110, width: 120 }} src={icon} />

                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h3>
                    Monthly Pay Summary for the Month of{" "}
                    {getMonthName(monthDate)}
                    {campusName != "" ? "( " + campusName + " )" : null}
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      <h5 className="">Month:- {monthDate}</h5>
                    </div>
                    <div>
                      <h5 className="">Printing Date :- {todayDate()}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <ColoredLine color={"#FF0000"} />

              <Table>
                <tbody>
                  <tr
                    style={{
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <th
                      style={{ color: "#121291", fontSize: "18px" }}
                      className="text-center"
                      colSpan={2}
                    >
                      ALLOWANCES
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Basic Pay
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_basic_pay
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Hifz Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_hifz
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Hostel Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_hostel
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      College Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_college
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Additional Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_additional_allowance
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Increment Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_increment
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Second Shift Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_second_shift
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Qualification Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(reportData?.total_ugs)}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Other Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_other_allowance
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      HOD Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(reportData?.total_hod)}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Science Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_science
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Extra Period Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_extra_period
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Extra Coaching Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_extra_coaching
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Convance Allowance
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_convance
                      )}
                    </td>
                  </tr>

                  <tr
                    style={{
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      GPF Return
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_gpf_return
                      )}
                    </td>
                  </tr>

                  <tr
                    style={{
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{ color: "#121291", fontSize: "16px" }}
                      className="text-center"
                    >
                      <b>Gross Salary</b>
                    </td>
                    <td
                      style={{ color: "#121291", fontSize: "16px" }}
                      className="text-center"
                    >
                      <b>
                        {Intl.NumberFormat("en-Us").format(
                          reportData?.total_gross_salary
                        )}
                      </b>
                    </td>
                  </tr>

                  <tr
                    style={{
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <th
                      style={{ color: "#121291", fontSize: "18px" }}
                      className="text-center"
                      colSpan={2}
                    >
                      DEDUCTIONS
                    </th>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Welfare Fund
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_welfare_fund
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      GP Fund
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_gp_fund
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Income Tax
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_income_tax
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      EOBI
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_eobi
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Loan Refund
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_loan_refund
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Other Deductions
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_other_deduction
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Staff Child Fee Deductions
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_child_fee_deduction
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Insurance Deductions
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_insurance
                      )}
                    </td>
                  </tr>

                  <tr
                    style={{
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      Staff Van Fee Deductions
                    </td>
                    <td
                      style={{ color: "black", fontSize: "14px" }}
                      className="text-center"
                    >
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_child_fee_deduction
                      )}
                    </td>
                  </tr>

                  <tr
                    style={{
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{ color: "brown", fontSize: "16px" }}
                      className="text-center"
                    >
                      <b>Total Deductions</b>
                    </td>
                    <td
                      style={{ color: "brown", fontSize: "16px" }}
                      className="text-center"
                    >
                      <b>
                        {Intl.NumberFormat("en-Us").format(
                          reportData?.total_deductions
                        )}
                      </b>
                    </td>
                  </tr>

                  <tr
                    style={{
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{ color: "#121291", fontSize: "16px" }}
                      className="text-center"
                    >
                      <b>Net Pay</b>
                    </td>
                    <td
                      style={{ color: "#121291", fontSize: "16px" }}
                      className="text-center"
                    >
                      <b>
                        {Intl.NumberFormat("en-Us").format(
                          reportData?.total_net_pay
                        )}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </React.Fragment>
        ) : null}

        {/* For Print */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div id="tableboot">
            <div className="d-flex">
              <img style={{ height: 110, width: 120 }} src={icon} />

              <div className="col-md-8 pl-5">
                <h1 style={{ color: "black" }}>{unitName}</h1>
                <h3 style={{ color: "black" }}>
                  Monthly Pay Summary for the Month of {getMonthName(monthDate)}
                  {campusName != "" ? "( " + campusName + " )" : null}
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <div>
                    <h3 style={{ color: "black" }}>Month:- {monthDate}</h3>
                  </div>
                  <div>
                    <h3 style={{ color: "black" }}>
                      Printing Date :- {todayDate()}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <Table className="mt-12 w-75 text-center mx-auto">
              <tbody>
                <tr>
                  <th
                    style={{
                      color: "#121291",
                      fontSize: "20px",
                      padding: "1px",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                    className="text-center"
                    colSpan={2}
                  >
                    ALLOWANCES
                  </th>
                </tr>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Basic Pay
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_basic_pay
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Hifz Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(reportData?.total_hifz)}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Hostel Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_hostel
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    College Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_college
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Additional Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_additional_allowance
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Increment Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_increment
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Second Shift Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_second_shift
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Qualification Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(reportData?.total_ugs)}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Other Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_other_allowance
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    HOD Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(reportData?.total_hod)}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Science Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_science
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Extra Period Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_extra_period
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Extra Coaching Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_extra_coaching
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Convance Allowance
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_convance
                    )}
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "2px solid black",
                  }}
                >
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    GPF Return
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_gpf_return
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "2px solid black" }}>
                  <td
                    style={{
                      color: "#121291",
                      fontSize: "18px",
                      padding: "1px",
                    }}
                    className="text-center"
                  >
                    <b>Gross Salary</b>
                  </td>
                  <td
                    style={{
                      color: "#121291",
                      fontSize: "18px",
                      padding: "1px",
                    }}
                    className="text-center"
                  >
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_gross_salary
                      )}
                    </b>
                  </td>
                </tr>

                <tr
                  style={{
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                  }}
                >
                  <th
                    style={{
                      color: "#121291",
                      fontSize: "20px",
                      padding: "1px",
                    }}
                    className="text-center"
                    colSpan={2}
                  >
                    DEDUCTIONS
                  </th>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Welfare Fund
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_welfare_fund
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    GP Fund
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_gp_fund
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Income Tax
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_income_tax
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    EOBI
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(reportData?.total_eobi)}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Loan Refund
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_loan_refund
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Other Deductions
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_other_deduction
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Staff Child Fee Deductions
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_child_fee_deduction
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Insurance Deductions
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_insurance
                    )}
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "2px solid black",
                  }}
                >
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    Staff Van Fee Deductions
                  </td>
                  <td
                    style={{ color: "black", fontSize: "16px", padding: "1px" }}
                    className="text-center"
                  >
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_child_fee_deduction
                    )}
                  </td>
                </tr>

                <tr style={{ borderBottom: "2px solid black" }}>
                  <td
                    style={{ color: "brown", fontSize: "18px", padding: "1px" }}
                    className="text-center"
                  >
                    <b>Total Deductions</b>
                  </td>
                  <td
                    style={{ color: "brown", fontSize: "18px", padding: "1px" }}
                    className="text-center"
                  >
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_deductions
                      )}
                    </b>
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "2px solid black",
                  }}
                >
                  <td
                    style={{
                      color: "#121291",
                      fontSize: "18px",
                      padding: "1px",
                    }}
                    className="text-center"
                  >
                    <b>Net Pay</b>
                  </td>
                  <td
                    style={{
                      color: "#121291",
                      fontSize: "18px",
                      padding: "1px",
                    }}
                    className="text-center"
                  >
                    <b>
                      {Intl.NumberFormat("en-Us").format(
                        reportData?.total_net_pay
                      )}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        {/* ///for exprot print */}
        <div
          ref={exportTable}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <Table>
            <tbody>
              <tr
                style={{
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <th
                  style={{ color: "#121291", fontSize: "18px" }}
                  className="text-center"
                  colSpan={2}
                >
                  ALLOWANCES
                </th>
              </tr>
              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Basic Pay
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_basic_pay
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Hifz Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(reportData?.total_hifz)}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Hostel Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(reportData?.total_hostel)}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  College Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(reportData?.total_college)}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Additional Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_additional_allowance
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Increment Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_increment
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Second Shift Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_second_shift
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Qualification Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(reportData?.total_ugs)}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Other Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_other_allowance
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  HOD Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(reportData?.total_hod)}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Science Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(reportData?.total_science)}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Extra Period Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_extra_period
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Extra Coaching Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_extra_coaching
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Convance Allowance
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_convance
                  )}
                </td>
              </tr>

              <tr
                style={{
                  borderBottom: "1px solid black",
                }}
              >
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  GPF Return
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_gpf_return
                  )}
                </td>
              </tr>

              <tr
                style={{
                  borderBottom: "1px solid black",
                }}
              >
                <td
                  style={{ color: "#121291", fontSize: "16px" }}
                  className="text-center"
                >
                  <b>Gross Salary</b>
                </td>
                <td
                  style={{ color: "#121291", fontSize: "16px" }}
                  className="text-center"
                >
                  <b>
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_gross_salary
                    )}
                  </b>
                </td>
              </tr>

              <tr
                style={{
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <th
                  style={{ color: "#121291", fontSize: "18px" }}
                  className="text-center"
                  colSpan={2}
                >
                  DEDUCTIONS
                </th>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Welfare Fund
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_welfare_fund
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  GP Fund
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(reportData?.total_gp_fund)}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Income Tax
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_income_tax
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  EOBI
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(reportData?.total_eobi)}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Loan Refund
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_loan_refund
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Other Deductions
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_other_deduction
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Staff Child Fee Deductions
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_child_fee_deduction
                  )}
                </td>
              </tr>

              <tr>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Insurance Deductions
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_insurance
                  )}
                </td>
              </tr>

              <tr
                style={{
                  borderBottom: "1px solid black",
                }}
              >
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  Staff Van Fee Deductions
                </td>
                <td
                  style={{ color: "black", fontSize: "14px" }}
                  className="text-center"
                >
                  {Intl.NumberFormat("en-Us").format(
                    reportData?.total_child_fee_deduction
                  )}
                </td>
              </tr>

              <tr
                style={{
                  borderBottom: "1px solid black",
                }}
              >
                <td
                  style={{ color: "brown", fontSize: "16px" }}
                  className="text-center"
                >
                  <b>Total Deductions</b>
                </td>
                <td
                  style={{ color: "brown", fontSize: "16px" }}
                  className="text-center"
                >
                  <b>
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_deductions
                    )}
                  </b>
                </td>
              </tr>

              <tr
                style={{
                  borderBottom: "1px solid black",
                }}
              >
                <td
                  style={{ color: "#121291", fontSize: "16px" }}
                  className="text-center"
                >
                  <b>Net Pay</b>
                </td>
                <td
                  style={{ color: "#121291", fontSize: "16px" }}
                  className="text-center"
                >
                  <b>
                    {Intl.NumberFormat("en-Us").format(
                      reportData?.total_net_pay
                    )}
                  </b>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Paper>
    </>
  );
};

export default MonthlyPaySummary;
