import React from 'react'
import { ErrorMessage , Field} from 'formik'
import TextError from './TextError'

const FormikSelect = (props) => {

        const {name,label,options,colCount,...rest} = props
    return (
        <div className={`${colCount}`}>
            <label htmlFor={name} className="font-size-h6 font-size-lg-h4 col-form-label">{label}</label>
        <Field
                id={name}
                name={name}
                as='select'
                className="form-control form-control-lg form-control-solid  " 
                {...rest}
            >
                {options.map( designation => {
                    return(
                        <option value={designation} >{designation}</option>
                    )
                } )}

            </Field>

            <ErrorMessage name={name} component={TextError} /> 
            </div>
    )
}

export default FormikSelect
