import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { Form, Col, Table, Spinner, Modal } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import ViewSalaryDetails from "./ViewSalaryDetails/ViewSalaryDetails";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import MySelect from "./../../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import Paper from "@material-ui/core/Paper";
import EditGeneratedSalary from "./EditGeneratedSalary";
import "./table.css";
const FilterSalary = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [designations, setdesignations] = useState([]);
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");
  const [selectedclasses, setselectedclasses] = useState([]);
  const [Allsessions, setAllsessions] = useState([]);
  const [allData, setallData] = useState([]);
  const [loading, setloading] = useState(false);
  const [currentStaff, setcurrentStaff] = useState({});
  const [viewModal, setviewModal] = useState(false);
  let [optionSelected, setoptionSelected] = useState([]);
  const [optionSelectedvalue, setoptionSelectedvalue] = useState([]);
  const [editsalarymodal, setEditSalaryModal] = useState(false);
  const [filterTable, setfilterTable] = useState(false);
  //states
  const [selectedId, setSelectedId] = useState(null);
  const [AllSalaries, setAllSalaries] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [allcampuses, setallcampuses] = useState(user.campuses || []);

  const [campusID, setcampusID] = useState("");

  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  const [searchtext, setsearchtext] = useState("");

  const currentyear = new Date().getFullYear();
  const [selecteddate, setselecteddate] = useState("");
  const selectRow = (item, i) => {
    setSelectedId(item.id);
  };
  const [filtersalarydata, setfiltersalarydata] = useState([]);
  const [selectedsalary, setselectedsalary] = useState([]);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data) {
            setAllsessions(data.payload);
            // console.log(data)
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // getAllCampus();
    getAlldesignations();
    getAllSessions();
  }, []);
  const getAlldesignations = () => {
    console.log("designationsssssss");
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setdesignations(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchFilter = () => {
    // let newYear = year.substr(0, 4);
    setloading(true);
    setfilterTable(false);
    const data = {
      campus_id: campusID,
      salary_month: `${selecteddate}-01`,
      designation_ids: selectedclasses,
    };

    console.log(data);
    fetch(`${baseUrl}/get_salaries_filter`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          if (data.payload.salaries != "") {
            setfilterTable(true);
          }

          // console.log(data.payload + "Salaries Data");
          setfiltersalarydata({ ...data.payload });
          setAllSalaries([...data.payload.salaries]);
          setloading(false);
        });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const SeeDetails = (salary) => {
    setcurrentStaff(salary);
    setviewModal(true);
  };

  const handleChange = (selected) => {
    console.log(selected);
    let valuearray = [];
    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setselectedclasses([...valuearray]);
    setoptionSelectedvalue([...valuearray]);
    setoptionSelected([...selected]);
    console.log(optionSelectedvalue);
  };
  const Options = [];
  designations.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  const getAllStaff = (e) => {
    let id;
    if (e == undefined) {
      id = campusID;
    } else if (e != 0) {
      id = e;
      setcampusID(id);
    }

    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });

    // setloading(true);
    fetch(`${baseUrl}/employees_index/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // setloading(false);
      })
      .catch((err) => {
        // setloading(false);
        console.log(err);
      });
  };
  const dataSearch = AllSalaries.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.employee?.full_name?.toLowerCase().includes(text)
      : true;
  });

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label>
              Select Campus <span style={{ color: "red" }}> *</span>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={allcampuses.length === 0 ? "" : previousselectedcampus}
              onChange={(e) => getAllStaff(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {allcampuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label>
              Month <span style={{ color: "red" }}>*</span>
            </label>
            <input
              style={{ height: 40 }}
              className="form-control"
              onChange={(e) => setselecteddate(e.target.value)}
              type="month"
              value={selecteddate}
            />
          </div>

          <div className="col-md-4">
            <label>
              Select Desigination <span style={{ color: "red" }}>*</span>
            </label>

            <MySelect
              required
              options={Options}
              isMulti
              name="selectedOptions"
              closeMenuOnSelect={true}
              placeholder="Select All"
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChange}
              value={optionSelected}
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-4">
            <label>Search... </label>

            <input
              className="form-control"
              style={{ height: 40 }}
              onChange={(e) => setsearchtext(e.target.value)}
              id="searchInput"
              placeholder="Search ..."
            />
          </div>
          <div className="col-md-4 mt-9">
            <button
              className="btn btn-success button_blue"
              onClick={searchFilter}
              style={{ width: "100%" }}
              // disabled={selecteddate == ''?true : campusID=="" ? true : false}
            >
              Search
            </button>
          </div>
        </div>

        <br />
        <br />
        {filterTable ? (
          <>
            <div className="tablemain">
              <table className="table">
                <thead className="tablehead">
                  <tr className="tableheadtr text-center">
                    <th className="tableHEadNAme tablehedfixed1">SR.NO</th>
                    <th className="tableHEadNAme tablehedfixed2">
                      Employee Name
                    </th>
                    <th className="tableHEadNAme tablehedfixed3">
                      Employee Code
                    </th>
                    <th className="tableHEadNAme">Account No</th>
                    <th className="tableHEadNAme">Basic Pay</th>
                    <th className="tableHEadNAme">Hifz Allow</th>
                    <th className="tableHEadNAme">Hostel Allow</th>
                    <th className="tableHEadNAme">College Allow</th>
                    <th className="tableHEadNAme">Additional Allow</th>
                    <th className="tableHEadNAme">Increment Total</th>
                    <th className="tableHEadNAme">SShift Allow</th>
                    <th className="tableHEadNAme">USG Allow</th>
                    <th className="tableHEadNAme">Other Allow</th>
                    <th className="tableHEadNAme">HoD Allow</th>
                    <th className="tableHEadNAme">Science Allow</th>
                    <th className="tableHEadNAme">Ex.Priod Allow</th>
                    <th className="tableHEadNAme">Ex.Coaching Allow</th>
                    <th className="tableHEadNAme">Conv Allow</th>
                    <th className="tableHEadNAme">GPF Return</th>
                    <th className="tableHEadNAme">Gross Salary</th>
                    <th className="tableHEadNAme">GP Fund</th>
                    <th className="tableHEadNAme">Welfare Fund</th>
                    <th className="tableHEadNAme">Eobi</th>
                    <th className="tableHEadNAme">Income Tax</th>
                    <th className="tableHEadNAme">Loan Refund</th>
                    <th className="tableHEadNAme">Other Deduction</th>
                    <th className="tableHEadNAme">Child Fee Deduction</th>
                    <th className="tableHEadNAme">Insu. Deduction</th>
                    <th className="tableHEadNAme">Van Fee Deduction</th>
                    <th className="tableHEadNAme">Net Pay</th>
                    <th className="tableHEadNAme">Days</th>
                    <th className="tableHEadNAme">View</th>
                    <th className="tableHEadNAme">Edit</th>
                  </tr>
                </thead>

                <tbody>
                  {dataSearch?.map((salary, index) => (
                    <tr
                      key={index}
                      className="tbodytr text-cemter"
                      onClick={() => selectRow(salary, index)}
                      style={{
                        backgroundColor:
                          salary.id == selectedId ? "#D3CECE" : "",
                      }}
                    >
                      <td className="tbodytd fixed">{index + 1}</td>
                      <td
                        className="tbodytd fixed2"
                        title={
                          salary.employee.full_name.length > 15
                            ? salary.employee.full_name
                            : null
                        }
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {salary.employee.full_name.length > 15
                          ? salary.employee.full_name.substring(0, 12) + "..."
                          : salary.employee.full_name}
                      </td>
                      <td className="tbodytd fixed3">
                        {salary.employee.emp_code}
                      </td>
                      <td className="tbodytd">
                        {salary.account_no ? salary.account_no : "-"}
                      </td>
                      <td className="tbodytd">{salary.basic_pay}</td>
                      <td className="tbodytd">{salary.hifz}</td>
                      <td className="tbodytd">{salary.hostel}</td>
                      <td className="tbodytd">{salary.college}</td>
                      <td className="tbodytd">{salary.additional_allowance}</td>
                      <td className="tbodytd">{salary.increment}</td>
                      <td className="tbodytd">{salary.second_shift}</td>
                      <td className="tbodytd">{salary.ugs}</td>
                      <td className="tbodytd">{salary.other_allowance}</td>
                      <td className="tbodytd">{salary.hod}</td>
                      <td className="tbodytd">{salary.science}</td>
                      <td className="tbodytd">{salary.extra_period}</td>
                      <td className="tbodytd">{salary.extra_coaching}</td>
                      <td className="tbodytd">{salary.convance}</td>
                      <td className="tbodytd">{salary.gpf_return}</td>
                      <td
                        className="tbodytd text-primary"
                        style={{ backgroundColor: "darkgray" }}
                      >
                        {salary.gross_salary}
                      </td>
                      <td className="tbodytd">{salary.gp_fund}</td>
                      <td className="tbodytd">{salary.welfare_fund}</td>
                      <td className="tbodytd">{salary.eobi}</td>
                      <td className="tbodytd">{salary.income_tax}</td>
                      <td className="tbodytd">{salary.loan_refund}</td>
                      <td className="tbodytd">{salary.other_deduction}</td>
                      <td className="tbodytd">{salary.child_fee_deduction}</td>
                      <td className="tbodytd">{salary.insurance}</td>
                      <td className="tbodytd">{salary.van_charge}</td>
                      <td className="tbodytd">{salary.net_pay}</td>
                      <td className="tbodytd">{salary?.days}</td>
                      <td className="tbodytd">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => SeeDetails(salary)}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Unlock.svg"
                            )}
                          />
                        </span>
                      </td>
                      <td className="tbodytd">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditSalaryModal(true);
                            setselectedsalary(salary);
                          }}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Edit.svg"
                            )}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr className="tbodytr">
                    <th className="tbodytd fixed"></th>
                    <th className="tbodytd fixed2"></th>
                    <th className="tbodytd fixed3"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th className="tbodytd"></th>
                    <th
                      className="tbodytd text-center text-primary"
                      colSpan={2}
                    >
                      Total Basic Salary :-
                    </th>
                    <th className="tbodytd text-center">
                      {AllSalaries?.reduce(
                        (a, c) => a + parseInt(c.basic_pay),
                        0
                      )}
                    </th>
                    <th
                      className="tbodytd text-center text-primary"
                      colSpan={2}
                    >
                      Total Gross Salary :-
                    </th>
                    <th className="tbodytd text-center">
                      {" "}
                      {filtersalarydata.total_gross_salary}
                    </th>
                    <th
                      className="tbodytd text-center text-primary"
                      colSpan={2}
                    >
                      Total Net Salary :-
                    </th>

                    <th className="tbodytd text-center">
                      {" "}
                      {filtersalarydata.total_net_salary}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : null}

        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}

        <Modal onHide={() => setviewModal(false)} show={viewModal} size="xl">
          <Modal.Header>View Salary Details</Modal.Header>

          <Modal.Body>
            <ViewSalaryDetails EmpDetails={currentStaff} />
          </Modal.Body>

          <Modal.Footer>
            <button
              onClick={() => setviewModal(false)}
              className="btn btn-danger btn_width"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={editsalarymodal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Edit Salary - {selectedsalary?.employee?.full_name}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <input
                disabled
                className="form-control col-md-4 m-3"
                defaultValue={`Net Pay ${selectedsalary.net_pay}`}
              />
              <input
                disabled
                className="form-control col-md-4 m-3"
                defaultValue={`Gross Salary ${selectedsalary.net_pay}`}
              />
            </div>
            {/* <h3>Net Pay :{selectedsalary.net_pay} Gross Salary :{selectedsalary.gross_salary}</h3> */}
            <EditGeneratedSalary
              staffDetails={selectedsalary}
              show={() => setEditSalaryModal()}
              reload={() => searchFilter()}
            />
          </Modal.Body>
        </Modal>
      </Paper>
    </div>
  );
};

export default FilterSalary;
