import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../styles.css";
import Barcode from "react-barcode";
import { upperCase } from "lodash";
import { baseUrl } from "../../../../services/config";

export default function PrintClassChallans() {
  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const feechallans = useSelector(
    (state) => state.campuses.campusesclasschallans
  );
  console.log(feechallans);

  const printchallans = () => {
    alert("work");
  };

  return (
    <div>
      <button
        onClick={printchallans}
        className="btn btn-success button_blue button_blue"
      >
        Print
      </button>

      <div id="mychallans">
        {feechallans.map((item) => (
          <div className="row-printer">
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">
                <h1>Bank Copy</h1>
              </div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>

            {/* 2nd voucher */}
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">{<h1>Office Copy</h1>}</div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>
            {/* 3rd voucher */}
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">
                <h1>Student Copy</h1>
              </div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
