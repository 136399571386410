/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState, useEffect } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Staff from "./pages/Staff/Staff";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";

function Routes(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const history = useHistory();

  // const { isAuthorized } = useSelector(
  //   ({ auth }) => ({
  //     isAuthorized: auth.user != null,
  //   }),
  //   shallowEqual
  // );

  useEffect(() => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let tod = yyyy + "-" + mm + "-" + dd;
    dispatch({
      type: "CHALLAN_RECEVING_DATE",
      payload: {
        setchallanrecevingdate: tod,
      },
    });

    // if (performance.navigation.type == performance.navigation.TYPE_RELOAD && localStorage.getItem('userToken')) {

    //   dispatch({
    //     type: 'ADD_USER',
    //     payload: {
    //       isAuthenticated: true,

    //       authtoken: localStorage.getItem('userToken'),
    //       user: JSON.parse(localStorage.getItem('userDetails'))
    //     }
    //   })

    // }
  }, []);

  return (
    <Switch>
      {!localStorage.getItem("userToken") ? (
        //Render auth page when user at `/auth` and not authorized.

        <Route>
          <AuthPage />
        </Route>
      ) : localStorage.getItem("userToken") ? (
        <Redirect from="/auth" to="/systemdashboard" />
      ) : null}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {localStorage.getItem("userToken") ? (
        <Layout>{user && <BasePage />}</Layout>
      ) : (
        <Redirect to="/auth/login" />
      )}
    </Switch>
  );
}
export default injectIntl(connect(null, auth.actions)(Routes));
