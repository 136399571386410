import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import { baseUrl } from "../../services/config";
import { useDispatch, useSelector } from "react-redux";


function FeeReceviceModal({
  lateFine,
  feereceving,
  feerecevingcheck,
  data,
  onchangeDate,
  feerecevingbank,
  recevingdate,
  setrecevingdate,
  selectedbank,
  setselectedbank,
  setlatefine,
  setreadmissionFee,
  readmissionFee,
  setpostpond,
  setonlyChallan,
  selectedtype,
  setselectedtype


}) {
  console.log("data os recive fee", data)
  // const [recevingdate, setrecevingdate] = useState();
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const authtoken = localStorage.getItem("userToken");
  let [isfeereceving, setisfeereceving] = useState(false);

  const [showPayable, setshowPayable] = useState(data.payable)



  const [allbanks, setallbanks] = useState([]);

  const [latedays, setlatedays] = useState('')
  const [due_date, setdue_date] = useState('')

  const [selectedbankcateogry, setselectedbankcateogry] = useState(
    useSelector((state) => state.campuses.selectedbankcategory_red)
  );
  // const [selectedbank, setselectedbank] = useState(
  //   useSelector((state) => state.campuses.selectedbank_red)
  // );









  const onRadioButtonClick = (e) => {
    const value = e.target.value

    console.log(value)
    setselectedtype(e.target.value)
    if (value == "onlyChallan") {
      setshowPayable(data.payable)
      setreadmissionFee(0)
    }
    if (value == "postpond") {
      setshowPayable(data.payable)
      setreadmissionFee(0)
    }
    if (value == "pay_fine") {
      setlatefine(lateFine)
      setshowPayable(lateFine + Number(data.payable))
    }

  }












  useEffect(() => {
    receivedDateSelected(recevingdate)
  }, [])


  const onPostponCheck = val => {
    setshowPayable(data.payable)
    setpostpond(val)
    setonlyChallan(false)
  }


  const onOnlyChallanCheck = val => {

    setonlyChallan(val)
    setpostpond(false)
    setshowPayable(data.payable)
  }




  const dispatch = useDispatch();

  let selectedbank_red = useSelector(
    (state) => state.campuses.selectedbank_red
  );
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );

  // selectedbank_red
  useEffect(() => {

    console.log("red_recevingdate==>", red_recevingdate);
    if (red_recevingdate != null && red_recevingdate != undefined) {
      setrecevingdate(red_recevingdate);
    }
    console.log("selectedbank_red==>", selectedbank_red);
    if (selectedbank_red != null && selectedbank_red != undefined) {
      setselectedbank(selectedbank_red);
    }

    getAllBanks();
  }, []);

  const getAllBanks = () => {


    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAlSubAccountsList = async () => {
    await fetch(`${baseUrl}/sub_account_only_banks`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "asyn data");
        if (data.metadata) {
          if (data.metadata.success) {
            setallsubaccounts(data.payload);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log("sub accounts", err);
      });
  };




  const onDueSelect = dueDate => {
    setdue_date(dueDate)
  }




  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }



  function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }



  const receivedDateSelected = (date) => {



    if (getMonthDifference(new Date(data.due_date), new Date(date)) >= 1) {
      setshowPayable(data.payable)
      setreadmissionFee(1000)
      setshowPayable(prev => Number(prev) + 1000)
      setlatefine(0)
      setlatedays(0)

    } else {

      const date1 = new Date(data.due_date);
      const date2 = new Date(date);
      if (dateDiffInDays(date1, date2) * 15 > 0) {

        setreadmissionFee(0)
        setlatedays(dateDiffInDays(date1, date2))
        setlatefine(dateDiffInDays(date1, date2) * 15)
        setshowPayable(prev => Number(prev) + dateDiffInDays(date1, date2) * 15)

      } else {
        setreadmissionFee(0)
        setlatefine(0)
        setlatedays(dateDiffInDays(date1, date2))
        setshowPayable(data.payable)
      }





    }

  }



  return (
    <div className="row text-left">
      {/* <div className="generate text-center">Enter  Detail and Submit</div> */}
      {/* <div className="form-group col-sm-12 flex-column d-flex">
        <label>
          <b>
            Student Name <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <input
          style={{ backgroundColor: "#F3F6F9" }}
          disabled
          defaultValue={data.student_name}
          type="text"
          className="form-control"
        />
      </div> */}

      <div className="form-group col-sm-2 flex-column d-flex">
        <label>
          <b>
            Challan No <span style={{ color: "red" }}>*</span>{" "}
          </b>
        </label>
        <input
          disabled
          defaultValue={data.challan_no}
          style={{ backgroundColor: "#F3F6F9" }}
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group col-sm-2 flex-column d-flex">
        <label>
          <b>
            Due date<span style={{ color: "red" }}>*</span>{" "}
          </b>
        </label>
        <input
          disabled
          // onChange={(e) => onDueSelect(e.target.value)}
          defaultValue={data.due_date}
          style={{ backgroundColor: "#F3F6F9" }}
          type={'date'}
          className="form-control"
        />
      </div>
      {/* <div className="form-group col-sm-2 flex-column d-flex">
        <label>
          <b>
            Late days<span style={{ color: "red" }}>*</span>{" "}
          </b>
        </label>
        <input
          disabled
          defaultValue={latedays}
          value={latedays}
          style={{ backgroundColor: "#F3F6F9" }}
          type={'text'}
          className="form-control"
        />
      </div> */}


      {/* <div className="form-group col-sm-2 flex-column d-flex">
        <label>
          <b>
            Postponed<span style={{ color: "red" }}>*</span>{" "}
          </b>
        </label>
        <input
          value={"postpond"}
          checked={selectedtype == "postpond"}
          onChange={(e) => onRadioButtonClick(e)}
          style={{ backgroundColor: "#F3F6F9" }}
          type={'radio'}

        />
      </div> */}
      {/* <div className="form-group col-sm-2 flex-column d-flex">
        <label>
          <b style={{ font: '13px' }}>
            Only Challan<span style={{ color: "red" }}>*</span>{" "}
          </b>
        </label>
        <input
          value={"onlyChallan"}
          // defaultChecked={onlyChallan}
          checked={selectedtype == "onlyChallan"}
          onChange={(e) => onRadioButtonClick(e)}
          style={{ backgroundColor: "#F3F6F9" }}
          type={'radio'}

        />
      </div> */}
      {/* <div className="form-group col-sm-2 flex-column d-flex">
        <label>
          <b style={{ font: '13px' }}>
            Pay Fine<span style={{ color: "red" }}>*</span>{" "}
          </b>
        </label>
        <input
          value={"pay_fine"}

          checked={selectedtype == "pay_fine"}
          onChange={(e) => onRadioButtonClick(e)}
          style={{ backgroundColor: "#F3F6F9" }}
          type={'radio'}

        />
      </div> */}





      <div className="form-group col-sm-3 flex-column d-flex pt-3">
        <label className="form-control-label">
          <b>
            Challan Amount <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <input
          disabled
          defaultValue={data.payable}
          style={{ backgroundColor: "#F3F6F9" }}
          type="text"
          className="form-control"
        />
      </div>
      {/* <div className="form-group col-sm-3 flex-column d-flex pt-3">
        <label className="form-control-label">
          <b>
            Late Fine <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <input

          value={lateFine}
          defaultValue={lateFine}
          onChange={(e) => setlatefine(e.target.value)}
          style={{ backgroundColor: "#F3F6F9" }}
          type={'text'}
          className="form-control"
        />
      </div> */}
      {/* <div className="form-group col-sm-3 flex-column d-flex pt-3">
        <label className="form-control-label">
          <b>
            Re-Admission Fee <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <input
          value={readmissionFee}
          defaultValue={readmissionFee}
          onChange={(e) => {
            setreadmissionFee(e.target.value)

          }}
          style={{ backgroundColor: "#F3F6F9" }}
          type={'text'}
          className="form-control"
        />
      </div> */}

      {/* <div className="form-group col-sm-3 flex-column d-flex pt-3">
        <label className="form-control-label">
          <b>
            Payable <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <input
          disabled
          value={showPayable}
          defaultValue={showPayable}
          style={{ backgroundColor: "#F3F6F9" }}
          type="text"
          className="form-control"
        />
      </div> */}
      {/* <div className="form-group col-sm-3 flex-column d-flex pt-3">
        <label className="form-control-label">
          <b>
            Paid <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <input
          disabled
          defaultValue={data.paid == null ? 0 : data.paid}
          style={{ backgroundColor: "#F3F6F9" }}
          type="text"
          className="form-control"
        />
      </div> */}

      <div className="form-group col-sm-3 flex-column d-flex pt-3">
        <label className="form-control-label">
          <b>
            Select Bank Category <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <select
          className="form-select form-control"
          name="sub_account_id"
          value={selectedbankcateogry}
          onChange={(e) => {
            setselectedbankcateogry(e.target.value);
            dispatch({
              type: "CHALLAN_BANK_CATEGORY",
              payload: {
                selectedbankcategory_red: e.target.value,
              },
            });
            console.log(allbanks);
            let anserr = allbanks.filter((item) => item.id == e.target.value);

            if (anserr.length != 0) {
              setallsubaccounts([...anserr[0].bank_accounts]);
            } else {
              setallsubaccounts([]);
            }
          }}
          style={{ backgroundColor: "white" }}
          aria-label="Default select example"
        >
          <option acode="" selected value="">
            Select...
          </option>
          {allbanks.map((item) => (
            <option value={item.id}>{item.title}</option>
          ))}
        </select>
      </div>

      <div className="form-group col-sm-3 flex-column d-flex pt-3">
        <label className="form-control-label">
          <b>
            Select Bank <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <select
          className="form-select form-control"
          name="sub_account_id"
          value={selectedbank}
          onChange={(e) => {
            setselectedbank(e.target.value);
            dispatch({
              type: "CHALLAN_BANK",
              payload: {
                selectedbank_red: e.target.value,
              },
            });
            console.log(e.target.value);
            feerecevingbank(e.target.value);
          }}
          style={{ backgroundColor: "white" }}
          aria-label="Default select example"
        >
          <option acode="" selected value="">
            Select...
          </option>
          {allsubaccounts.map((item) => (
            <option value={item.id}>{item.bank_name}</option>
          ))}
        </select>
      </div>

      <div className="form-group col-sm-3 flex-column d-flex pt-3">
        <label className="form-control-label">
          <b>
            Received Date <span style={{ color: "red" }}>*</span>
          </b>
        </label>
        <input
          value={recevingdate}
          defaultValue={recevingdate}
          onChange={(e) => {
            dispatch({
              type: "CHALLAN_RECEVING_DATE",
              payload: {
                setchallanrecevingdate: e.target.value,
              },
            });
            receivedDateSelected(e.target.value)
            setrecevingdate(e.target.value);
            if (e.target.value == "") {
              setisfeereceving(true);
            } else {
              setisfeereceving(false);
              if (feereceving != undefined) {
                feereceving(false);
              }
            }

            onchangeDate(e.target.value);
          }}
          type="date"
          max="9999-11-30"
          className="form-control"
        />
      </div>

      {feerecevingcheck ? (
        <>
          <Alert
            variant="danger"
            onClose={() => setisfeereceving(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>Please Enter Received Date</p>
          </Alert>
        </>
      ) : null}
    </div>
  );
}

export default FeeReceviceModal;
