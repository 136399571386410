import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Badge, Button, Modal, Table } from "react-bootstrap";
import TextInput from "../../../components/TextInput";
import { useSelector, useDispatch } from "react-redux";
import { baseUrl } from "../../../services/config";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import AddUser from "./Form/AddUser";
import EditUser from "./EditUser/EditUser";

export default function Users() {
  const history = useHistory();
  const alert = useAlert();
  // const [state, setstate] = useState(initialState)
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [title, settitle] = useState("");
  const [address, setaddress] = useState("");
  const [area, setarea] = useState("");
  const [city, setcity] = useState("");
  const [province, setprovince] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [longitude, setlongitude] = useState("");
  const [latitude, setlatitude] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [SearchValue, setSearchValue] = useState("");
  const [loading, setloading] = useState(false);
  const [currentHeadofficeId, setcurrentHeadofficeId] = useState("");
  const [modalvisible, setmodalvisible] = useState(false);
  const [selectedHeadoffice, setselectedHeadoffice] = useState({});
  const [editModal, seteditModal] = useState(false);
  const [addloading, setaddloading] = useState(false);
  const [viewModal, setviewModal] = useState(false);
  const [headoffice, setheadoffice] = useState([]);

  const dispatch = useDispatch();
  let authtoken = localStorage.getItem("userToken");

  useEffect(() => {
    GetAllCampuses();
  }, []);

  const GetAllCampuses = async () => {
    setloading(true);

    await fetch(`${baseUrl}/users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((headoffices) => {
        console.log(headoffices);
        setheadoffice(headoffices.payload);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const openDelete = (row) => {
    console.log(row.id, "testing1");
    setcurrentHeadofficeId(row.id);
    setdeleteModal(true);
  };

  const DeleteHeadofficeHandler = (id) => {
    console.log(currentHeadofficeId, "testing");
    fetch(`${baseUrl}/users/${currentHeadofficeId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success == true) {
            alert.success("Headoffice Deleted Successfull");
            setdeleteModal(false);
            GetAllCampuses();
          } else {
            alert.error("something went wrong!");
          }
        })
      )
      .catch((err) => {
        console.log(err);
        setdeleteModal(false);
      });
  };

  const dataSearch = headoffice.filter((item) => {
    return SearchValue ? item.name.includes(SearchValue) : true;
  });

  const routeTo = (_path, payload) => {
    history.push(_path, payload);
  };

  const middleFunc = (row) => {
    setselectedHeadoffice(row);
    seteditModal(true);
  };

  return (
    <Paper className="mt-4 mb-4 p-5">
      <div>
        <button
          className="btn btn-primary"
          onClick={() => setmodalvisible(true)}
          style={{ margin: 5 }}
        >
          Add User
        </button>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Email</th>
            <th>Name</th>
            <th>Title</th>
            <th>Campuses</th>
            <th>Roles</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {dataSearch &&
            dataSearch.map((row, index) => (
              <tr key={index}>
                <td style={{ textAlign: "start" }}>{index + 1}</td>
                <td style={{ textAlign: "start" }}>{row.email}</td>

                <td
                  style={{ textAlign: "start" }}
                >{`${row.first_name} ${row.last_name} `}</td>
                <td style={{ textAlign: "start" }}>{`${row.office_title} `}</td>
                <td style={{ textAlign: "start" }}>
                  {row.campuses.map((campus) => {
                    return (
                      <Badge
                        style={{ backgroundColor: "black", marginRight: "2px" }}
                        bg="primary"
                      >
                        {campus.name}
                      </Badge>
                    );
                  })}
                </td>
                <td style={{ textAlign: "start" }}>
                  {row.roles.map((campus) => {
                    return (
                      <Badge
                        key={campus.id}
                        style={{ backgroundColor: "black", marginRight: "2px" }}
                        bg="primary"
                      >
                        {campus.name}
                      </Badge>
                    );
                  })}
                </td>
                <td style={{ textAlign: "start" }}>
                  <span
                    onClick={() => middleFunc(row)}
                    style={{ cursor: "pointer" }}
                    className="svg-icon svg-icon-md svg-icon-primary"
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                    />
                  </span>
                </td>
                <td style={{ textAlign: "start" }}>
                  <span
                    onClick={() => openDelete(row)}
                    style={{ cursor: "pointer" }}
                    className="svg-icon svg-icon-md svg-icon-danger"
                  >
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                    />
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <div className="row justify-content-center">
        {loading ? <span className="spinner-border"></span> : null}
      </div>
      <AddUser
        reload={() => GetAllCampuses()}
        onHide={() => setmodalvisible(false)}
        show={modalvisible}
      />

      <Modal show={deleteModal}>
        <Modal.Header closeButton>
          Are you sure want to delete Head office
        </Modal.Header>
        <Modal.Footer>
          <button onClick={DeleteHeadofficeHandler} className="btn button_blue">
            Delete
          </button>
          <button
            onClick={() => setdeleteModal(false)}
            className="btn btn-danger btn_width"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Edit  */}
      <EditUser
        reload={() => GetAllCampuses()}
        onHide={() => seteditModal(false)}
        show={editModal}
        headofficeDetail={selectedHeadoffice}
      />
    </Paper>
  );
}
