import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

const AppointedStaffReport = () => {
  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setloading] = useState(false);
  const [reportDetails, setreportDetails] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState("");
  const [campusName, setCampusName] = useState("");

  const alert = useAlert();
  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const pageStyle = `@page{
    size: A4 landscape !important;
  }`;

  useEffect(() => {
    // getAllCampus();
    getSettingData();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data.payload);
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeCampus = async () => {
    if (selectedcampus == "") {
      alert.error("Please Select Campus");
      return;
    }
    setloading(true);

    const campus = campuses.find((item) => {
      return item.id == selectedcampus;
    });
    setCampusName(campus.name);

    const data = {
      campus_id: selectedcampus,
    };
    await fetch(`${baseUrl}/staff_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setloading(false);
            setIsVisible(true);

            let res = data.payload.sort((a, b) => a.emp_code - b.emp_code);
            console.log(data);
            setreportDetails(res);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper className="p-5 mt-5 mb-5">
        <div className="row flex">
          <div className="col-md-4">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => setselectedcampus(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-sm-1 mt-9">
            <button
              onClick={onchangeCampus}
              className="btn btn-primary me-2"
              id="myBtn"
            >
              Show
            </button>
          </div>

          <div className="col-sm-1 mt-9" style={{ marginLeft: "-15px" }}>
            {reportDetails?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Appointed  Report`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            <div className="row p-3  mt-6">
              <img
                // className='col-md-2'
                style={{ height: 100, width: 110 }}
                src={icon}
              />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h5>List Of Appointed Staff From {campusName}</h5>
              </div>

              {/* <div>

                    </div> */}
            </div>

            <ColoredLine color={"#FF0000"} />

            <Table responsive>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Sr.</th>
                  <th style={{ textAlign: "center" }}>Code</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>Father's Name</th>
                  <th style={{ textAlign: "center" }}>DOB</th>
                  <th style={{ textAlign: "center" }}>Qualification</th>
                  <th style={{ textAlign: "center" }}>Mobile No</th>
                  <th style={{ textAlign: "start" }}>Designation</th>
                  <th style={{ textAlign: "center" }}>Martial Status</th>
                  <th style={{ textAlign: "center" }}>Religion</th>
                  <th style={{ textAlign: "center" }}>Experience</th>
                </tr>
              </thead>
              <tbody>
                {reportDetails &&
                  reportDetails.map((report, index) => (
                    <tr>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{report.emp_code}</td>
                      <td style={{ textAlign: "start" }}>{report.full_name}</td>
                      <td style={{ textAlign: "start" }}>
                        {report.father_name}
                      </td>
                      <td style={{ textAlign: "center" }}>{report.dob}</td>
                      <td style={{ textAlign: "center" }}>
                        {report.qualification}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.mobile_no}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report?.designation.name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.marital_status}
                      </td>
                      <td style={{ textAlign: "center" }}>{report.religion}</td>
                      <td style={{ textAlign: "center" }}>
                        {report.experience}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : null}

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="p-3 d-flex">
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col-md-8">
              <h1>{unitName}</h1>
              <h5>List Of Appointed Staff From {campusName}</h5>
            </div>
          </div>

          <Table style={{ width: "100%", marginTop: "10px" }}>
            <thead style={{ whiteSpace: "normal", padding: "1px" }}>
              <tr style={{ whiteSpace: "normal", padding: "1px" }}>
                <th
                  style={{
                    textAlign: "start",
                    width: "3%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "3%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Code
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "15%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Name <br /> Father's Name
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "5%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Scale <br /> Service
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "8%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Designation <br style={{ height: "3px" }} /> Religion
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "10%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Account No <br style={{ height: "3px" }} /> Marital Status
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "7%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Joining Date <br style={{ height: "3px" }} /> Relieve Date
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "7%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Date of Birth <br style={{ height: "3px" }} /> Gender
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "7%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Qualification
                  <br />
                  Experience
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "10%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                  }}
                >
                  Mobile No
                </th>
              </tr>
            </thead>
            <tbody>
              {reportDetails &&
                reportDetails.map((report, index) => (
                  <tr
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                      marginTop: "-25px",
                      padding: "1px",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ marginTop: "-5px" }}>{index + 1}</p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ marginTop: "-5px" }}> {report.emp_code}</p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {" "}
                        {/* {report.full_name} */}
                        {report.full_name.length > 25
                          ? report.full_name.substring(0, 22) + "..."
                          : report.full_name}
                      </p>

                      <p
                        style={{
                          whiteSpace: "normal",
                          marginTop: "-25px",
                          marginBottom: "-13px",
                        }}
                      >
                        {report.father_name.length > 25
                          ? report.father_name.substring(0, 22) + "..."
                          : report.father_name}
                        {/* {report.father_name} */}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ marginTop: "-5px" }}>
                        {report?.pay_scale?.payscale}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {report?.designation?.name
                          ? report?.designation?.name.length > 10
                            ? report?.designation?.name.substring(0, 10) + "..."
                            : report?.designation?.name
                          : null}
                      </p>
                      <p
                        style={{
                          whiteSpace: "normal",
                          marginTop: "-25px",
                          marginBottom: "-13px",
                        }}
                      >
                        {report.religion}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {" "}
                        {report.account_no}
                      </p>

                      <p
                        style={{
                          whiteSpace: "normal",
                          marginTop: "-25px",
                          marginBottom: "-13px",
                        }}
                      >
                        {" "}
                        {report.marital_status}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ marginTop: "-5px" }}>{report.joining_date}</p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {" "}
                        {report.dob}
                      </p>

                      <p
                        style={{
                          whiteSpace: "normal",
                          marginTop: "-25px",
                          marginBottom: "-13px",
                        }}
                      >
                        {" "}
                        {report.gender}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {" "}
                        {/* {report.qualification} */}
                        {report.qualification
                          ? report.qualification.length > 10
                            ? report.qualification.substring(0, 10) + "..."
                            : report.qualification
                          : null}
                      </p>

                      <p
                        style={{
                          whiteSpace: "normal",
                          marginTop: "-25px",
                          marginBottom: "-13px",
                        }}
                      >
                        {" "}
                        {/* {report.experience} */}
                        {report.experience
                          ? report.experience.length > 10
                            ? report.experience.substring(0, 10) + "..."
                            : report.experience
                          : null}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {" "}
                        {report.mobile_no}
                      </p>

                      <p
                        style={{
                          whiteSpace: "normal",
                          marginTop: "-25px",
                          marginBottom: "-13px",
                        }}
                      >
                        {" "}
                        {report.phone}
                      </p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Paper>
    </>
  );
};

export default AppointedStaffReport;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
