import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../services/config";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
} from "../widgets";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { toAbsoluteUrl } from "../../_helpers";
import "./styles.css";
import HeadOfficeDashboard from "../../../app/modules/HeadOfficeDashboard/HeadOfficeDashboard";
import SuperAdminDashboard from "../../../components/SuperAdminDashboard/SuperAdminDashboard";
import CampusDashboard from "../../../components/CampusDashboard/CampusDashboard";
export function Demo1Dashboard() {
  // const authtoken = useSelector(state => state.user.authtoken)
  const authtoken = localStorage.getItem("userToken");
  const [campuses, setcampuses] = useState([]);
  const [headoffices, setheadoffices] = useState([]);
  const [students, setstudents] = useState([]);

  useEffect(() => {
    getAllStudents();
    GetAllCampuses();
    GetAllheadoffice();
  }, []);

  const getAllStudents = async () => {
    await fetch(`${baseUrl}/student`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setstudents(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const GetAllheadoffice = async () => {
    await fetch(`${baseUrl}/headoffice`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((headoffices) => {
        setheadoffices(headoffices.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetAllCampuses = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((campuses) => {
        setcampuses(campuses.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const user = useSelector(state => state.user.user)
  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }

  return (
    <>
      <div className="row">
        {user.role[0] == "Super Admin" ? (
          <>
            <SuperAdminDashboard />
          </>
        ) : null}

        {user.role[0] == "Campus" ? (
          <>
            <CampusDashboard />
          </>
        ) : null}

        {/* <div className="col-lg-6 col-xxl-4">
          <StatsWidget11
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
        </div> */}

        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <ListsWidget1 className="card-stretch gutter-b" />
        </div>
        <div className="col-xxl-8 order-2 order-xxl-1">
          <AdvanceTablesWidget2 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget4 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div> */}
      </div>
      {/* <div className="row">
        <div className="col-lg-4">
          <MixedWidget14 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div>
      </div> */}

      {user.role[0] == "Head Office" ? (
        <>
          <HeadOfficeDashboard />
        </>
      ) : null}
    </>
  );
}
