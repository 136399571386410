import { Paper } from "@material-ui/core";
import { message, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAlert } from "react-alert";
import { Spinner, Table, Modal as Modalw2, Button } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { useSelector } from "react-redux";

const StaffMessaging = () => {
  const [open, setOpen] = useState(false);
  const [messagedata, setMessageData] = useState("");
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [staffData, setstaffData] = useState([]);
  const [staffIds, setstaffIds] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [searchtext2, setsearchtext2] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const [staffMessage, setStaffMessage] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [messageCount, setMessageCount] = useState(1);
  const [assignMessage, setAssignMessage] = useState(0);
  const [remainingMessage, setRemainingMessage] = useState(0);

  useEffect(() => {
    // getAllCampus();
    getUnitMessages();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getUnitMessages = async () => {
    await fetch(`${baseUrl}/get-unit-messages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setAssignMessage(data?.payload?.assign_sms);
        setRemainingMessage(data?.payload?.remaining_sms);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const handleModelOpen = () => {
    if (staffIds.length > 0) {
      setOpen(true);
    } else {
      alert.error("Please Select The At Least 1 Staff Member");
    }
  };
  const hideModal = () => {
    setOpen(false);
  };

  const sendMessage = () => {
    setConfirmModal(false);
    setOpen(false);
    setMessageData("");
    setbuttonDisabled(false);

    // messageApi.open({
    //   key,
    //   type: 'loading',
    //   content: 'Sending.....',
    // });
    setbuttonDisabled(true);
    let data = {
      message: staffMessage,
      employee_ids: staffIds,
    };

    fetch(`${baseUrl}/employee-custom-sms`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              setOpen(false);
              setMessageData("");
              messageApi.open({
                key,
                type: "success",
                content: "Successfully Send",
                duration: 1,
              });
              setbuttonDisabled(false);
            } else {
              messageApi.open({
                key,
                type: "error",
                content: "Something went wronge",
                duration: 4,
              });
              setbuttonDisabled(false);
            }
          }
          console.log(data);
        })
      )

      .catch((err) => {
        console.log(err);
      });
  };
  const onChampusChange = (id) => {
    getAllStaff(id);
    setCampusId(id);
  };
  const getAllStaff = (id) => {
    setloading(true);

    fetch(`${baseUrl}/emp_appointed/${id}/1`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              let res = data.payload.sort((a, b) => a.emp_code - b.emp_code);
              setstaffData(res);
              setloading(false);
              setcheckallcheckbox(false);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  // code fro checkboxess
  const allcheckboxChecked = () => {
    let studentarray = [];
    let middlearray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      staffData.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
    } else {
      setcheckallcheckbox(true);
      staffData.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
    }
    console.log(studentarray);
    console.log(middlearray);
    setstaffIds([...studentarray]);
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    staffData.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    setstaffIds([...studentarray]);
  };

  const searchbyRegId = (e) => {
    e.preventDefault();
    const data = {
      name_code: searchtext2.toLocaleUpperCase(),
    };

    fetch(`${baseUrl}/appointed-employees/filter/name-code`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          if (data.payload.length === 0) {
            alert.error("Match not found");
          }
          setstaffData(data.payload);
          setcheckallcheckbox(false);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg[0]);
          });
        }
      })
      .catch((err) => {
        console.log("err is re", err);
      });
  };

  const handleChange = (event) => {
    const inputMessage = event.target.value;
    const currentCharacterCount = inputMessage.length;
    const currentMessageCount = Math.ceil(currentCharacterCount / 160);

    setStaffMessage(inputMessage);
    setCharacterCount(currentCharacterCount);
    setMessageCount(currentMessageCount);
  };

  return (
    <>
      {contextHolder}
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="">
          <button onClick={handleModelOpen} className="btn btn-primary">
            Send Message
          </button>
        </div>
        <div className="row mt-3">
          <div className="col-sm-4">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => onChampusChange(e.target.value)}
              >
                <option selected value="">
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-8">
            <form onSubmit={searchbyRegId}>
              <div className="row mt-9">
                <div className="col-md-6">
                  <input
                    onChange={(e) => setsearchtext2(e.target.value)}
                    className="form-control"
                    placeholder="Enter Name or Employee ID"
                  />
                </div>
                <div className="col-md-6">
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <div id="tableboot">
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th>Sr.No</th>
                    <th>Code</th>
                    <th>Full Name</th>
                    <th>Mobile No</th>
                    <th>Joining</th>
                    <th>
                      <input type="checkbox" style={{ display: "none" }} />
                      <label className="checkbox checkbox-single">
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            allcheckboxChecked();
                          }}
                          checked={checkallcheckbox}
                        />
                        <span />
                      </label>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!!staffData &&
                    staffData.map((staff, index) => (
                      <tr id="tbl">
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {index + 1}
                        </td>

                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {staff.emp_code}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {staff.full_name}
                        </td>

                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {staff.phone}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {staff.joining_date}
                        </td>

                        <td style={{ textAlign: "start" }}>
                          <input type="checkbox" style={{ display: "none" }} />
                          <label className="checkbox checkbox-single">
                            <input
                              type="checkbox"
                              key={staff.id}
                              onClick={(e) => {
                                checkBoxClick(e, staff.id);
                              }}
                              checked={staff.checked}
                              value={allcheckboxes[index]}
                            />
                            <span />
                          </label>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </Paper>

      <Modal
        title="Message"
        open={open}
        onOk={() => setConfirmModal(true)}
        onCancel={hideModal}
        okButtonProps={{ disabled: buttonDisabled }}
        okText="Send"
        centered
      >
        <div className="row">
          <div className="col-md-12">
            {/* <textarea
              value={messagedata}
              onChange={(e) => setMessageData(e.target.value)}
              rows={4}
              style={{ width: "100%", padding: "5px" }}
              placeholder="Write the Message"
            ></textarea> */}

            <textarea
              rows="4"
              cols="50"
              placeholder="Write the Message..."
              value={staffMessage}
              onChange={handleChange}
              style={{ width: "100%", padding: "5px" }}
            ></textarea>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <h6>Character Count: {characterCount}</h6>
              <h6>Message Count: {messageCount}</h6>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <h6 className="blue_color">
                Remaining Messages: {remainingMessage}
              </h6>
            </div>
          </div>
        </div>
      </Modal>
      <Modalw2 show={confirmModal} onHide={() => setConfirmModal(false)}>
        <Modalw2.Header closeButton>
          <Modalw2.Title>Send Message</Modalw2.Title>
        </Modalw2.Header>
        <Modalw2.Body>Are You Sure You Want To Send Message</Modalw2.Body>
        <Modalw2.Footer>
          <Button variant="secondary" onClick={() => setConfirmModal(false)}>
            Close
          </Button>
          <Button
            disabled={buttonDisabled}
            variant="primary"
            onClick={sendMessage}
          >
            Send
          </Button>
        </Modalw2.Footer>
      </Modalw2>
    </>
  );
};

export default StaffMessaging;
