import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { PremissionChecker } from "../../../../services/PremissionChecker";
function DefineGrade() {
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);
  const authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const [isallfields, setisallfields] = useState(false);

  const [gradesData, setGradesData] = useState([]);
  const [addGradeLoading, setAddGradeLoading] = useState(false);

  const [addGradeModal, setAddGradeModal] = useState(false);
  const [gradeName, setGradeName] = useState("");
  const [minMarks, setMinMarks] = useState(0);
  const [maxMarks, setMaxMarks] = useState(0);
  const [grade, setGrade] = useState("");

  const [editGradeModal, setEditGradeModal] = useState(false);
  const [editGradeName, setEditGradeName] = useState("");
  const [editGrade, setEditGrade] = useState("");
  const [editMinMarks, setEditMinMarks] = useState(0);
  const [editMaxMarks, setEditMaxMarks] = useState(0);
  const [editGradeId, setEditGradeId] = useState("");

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteGradeId, setDeleteGradeId] = useState(0);
  const [deleteloading, setdeleteloading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getGrades();
  }, []);

  const getGrades = async () => {
    setloading(true);
    await fetch(`${baseUrl}/grade-systems`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setGradesData(data.payload);
          setloading(false);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const addGradeHandler = async () => {
    if (gradeName == "" || grade == "") {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    const data = {
      name: gradeName,
      min_marks: minMarks,
      max_marks: maxMarks,
      grade: grade,
    };
    setAddGradeLoading(true);

    await fetch(`${baseUrl}/grade-systems`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.success("Grade successfully added");
            setAddGradeModal(false);
            setAddGradeLoading(false);
            getGrades();
            setGradeName("");
            setGrade("");
            setMinMarks(0);
            setMaxMarks(0);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setAddGradeModal(false);
            setAddGradeLoading(false);
          } else {
            alert.show("Something Went Wrong");
            setAddGradeModal(false);
            setAddGradeLoading(false);
          }
        })
      )
      .catch((err) => {
        setAddGradeLoading(false);
        setAddGradeModal(false);
        console.log(err);
      });
  };

  const getEditGrade = async (id) => {
    await fetch(`${baseUrl}/grade-systems/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditGradeName(data.payload?.name);
          setEditGrade(data.payload?.grade);
          setEditMinMarks(data.payload?.min_marks);
          setEditMaxMarks(data.payload?.max_marks);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateGradeHandler = async () => {
    if (editGradeName == "") {
      return alert.error("Grade Name Is Required");
    }

    if (editGrade == "") {
      return alert.error("Grade Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: editGradeName,
      min_marks: editMinMarks,
      max_marks: editMaxMarks,
      grade: editGrade,
    };
    setSubmitLoading(false);
    await fetch(`${baseUrl}/grade-systems/${editGradeId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Grade Successfully Updated");
            getGrades();
            setEditGradeModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setEditGradeModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditGradeModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const deleteGradeHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/grade-systems/${deleteGradeId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            getGrades();
            setdeleteloading(false);
            setdeleteModal(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setdeleteloading(false);
            setdeleteModal(false);
          } else {
            alert.show("Something Went Wrong");
            setdeleteloading(false);
            setdeleteModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteModal(false);
      });
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div>
          <button
            onClick={() => setAddGradeModal(true)}
            className="btn btn-primary mr-2"
          >
            Add Grade
          </button>
        </div>

        <Table className="mt-5">
          <thead>
            <tr className="text-center">
              <th>ID</th>
              <th>Name</th>
              <th>Min. Marks</th>
              <th>Max. Marks</th>
              <th>Grade</th>
              {PremissionChecker(user, "campus.can_change_grade") && (
                <th>Update</th>
              )}
              {PremissionChecker(user, "campus.can_change_grade") && (
                <th>Delete</th>
              )}
            </tr>
          </thead>
          <tbody>
            {gradesData?.map((grades, index) => (
              <tr className="text-center" key={index}>
                <td>{grades.id}</td>
                <td>{grades.name}</td>
                <td>{grades.min_marks}</td>
                <td>{grades.max_marks}</td>
                <td>{grades.grade}</td>

                {PremissionChecker(user, "campus.can_change_grade") && (
                  <td>
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => {
                        getEditGrade(grades?.id);
                        setEditGradeId(grades?.id);
                        setEditGradeModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-success">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                )}

                {PremissionChecker(user, "campus.can_change_grade") && (
                  <td>
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => {
                        setDeleteGradeId(grades?.id);
                        setdeleteModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Delete-user.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        {/* Add Grade Modal */}
        <Modal show={addGradeModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Grade
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div class="row mb-4">
              <div className="col-md-6">
                <label className="form-control-label">
                  <b>
                    Enter Grade Name <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control text-uppercase"
                  value={gradeName}
                  onChange={(e) => setGradeName(e.target.value)}
                  placeholder="Enter Grade Name"
                />
              </div>

              <div className="col-md-6">
                <label className="form-control-label">
                  <b>
                    Enter Grade <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control text-uppercase"
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                  placeholder="Enter Grade"
                />
              </div>
            </div>

            <div class="row">
              <div className="col-md-6">
                <label className="form-control-label">
                  <b>
                    Enter Min. Marks <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control"
                  value={minMarks}
                  onChange={(e) => setMinMarks(e.target.value)}
                  placeholder="Enter Minimum Marks"
                />
              </div>

              <div className="col-md-6">
                <label className="form-control-label">
                  <b>
                    Enter Max. Marks <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control"
                  value={maxMarks}
                  onChange={(e) => setMaxMarks(e.target.value)}
                  placeholder="Enter Maximum Marks"
                />
              </div>
            </div>

            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>Please fill or select all fields</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>

          <Modal.Footer>
            {addGradeLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={addGradeHandler}
              >
                Add
              </button>
            )}
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setAddGradeModal(false);
                setisallfields(false);
                setGradeName("");
                setGrade("");
                setMinMarks(0);
                setMaxMarks(0);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Grade Modal */}
        <Modal show={editGradeModal} size="lg">
          <Modal.Header>
            <Modal.Title>Update Grade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row mb-4">
              <div className="col-md-6">
                <label className="form-control-label">
                  <b>
                    Enter Grade Name <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control text-uppercase"
                  value={editGradeName}
                  onChange={(e) => setEditGradeName(e.target.value)}
                  placeholder="Enter Grade Name"
                />
              </div>

              <div className="col-md-6">
                <label className="form-control-label">
                  <b>
                    Enter Grade <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control text-uppercase"
                  value={editGrade}
                  onChange={(e) => setEditGrade(e.target.value)}
                  placeholder="Enter Grade"
                />
              </div>
            </div>

            <div class="row">
              <div className="col-md-6">
                <label className="form-control-label">
                  <b>
                    Enter Min. Marks <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control"
                  value={editMinMarks}
                  onChange={(e) => setEditMinMarks(e.target.value)}
                  placeholder="Enter Minimum Marks"
                />
              </div>

              <div className="col-md-6">
                <label className="form-control-label">
                  <b>
                    Enter Max. Marks <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control"
                  value={editMaxMarks}
                  onChange={(e) => setEditMaxMarks(e.target.value)}
                  placeholder="Enter Maximum Marks"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button onClick={updateGradeHandler} className="btn btn-primary">
                Update
              </button>
            )}

            <button
              onClick={() => setEditGradeModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete  Grade Modal */}
        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this grade?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Fee Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button onClick={deleteGradeHandle} className="btn button_blue">
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setdeleteModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
}

export default DefineGrade;
