import React, { useEffect, useState } from "react";
// import { Table, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";
import StudentCard from "../../../components/StudentCard/StudentCard";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Table, Button, Modal, Spinner, Alert } from "react-bootstrap";
import EditCampusClass from "./editCampusClass";
import Paper from "@material-ui/core/Paper";
import MySelect from "../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
function CampusClasses() {
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);
  const [loading, setloading] = useState(false);
  const [allclasses, setallclasses] = useState([]);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const jwt_token = localStorage.getItem("userToken");
  const [deletedclassid, setdeletedclassid] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [editmodal, seteditmodal] = useState(false);
  const [selectedobject, setselectedobject] = useState({});
  const [deleteclassloader, setdeleteclassloader] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [optionSelected, setoptionSelected] = useState([]);
  const [allsubjects, setallsubjects] = useState([]);
  const [selectedsubjects, setselectedsubjects] = useState([]);
  const [isanysubject, setisanysubject] = useState(false);
  const [updateloading, setupdateloading] = useState(false);
  const [fullClass, setFullClass] = useState({});

  const [campusId, setCampusId] = useState("");
  useEffect(() => {
    getAllSubjects();
  }, []);

  const getAllSubjects = () => {
    fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsubjects(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteFee = (item) => {
    console.log(item);
    console.log(item.id);
    setdeletedclassid(item.id);
    setdeleteModal(true);
  };
  const DeleteFee = () => {
    console.log(deletedclassid, "deletedclassid");
    console.log(`${baseUrl}/campus_class_delete/${campusId}/${deletedclassid}`);

    setdeleteclassloader(true);

    fetch(`${baseUrl}/campus_class_delete/${campusId}/${deletedclassid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    })
      .then((res) => {
        console.log(res, "res");
        res.json().then((data) => {
          console.log(data);
          setdeleteclassloader(false);
          setdeleteModal(false);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Class Deleted success");
              getAllClasses(campusId);
            }
            if (!data.metadata.success) {
              alert.show("Error Occured");
            }
          } else {
            alert.show("Error Occured");
          }
        });
      })
      .catch((err) => {
        console.log(err);
        alert.show("Error Occured");
        setdeleteclassloader(false);
        setdeleteModal(false);
      });
  };

  const onCampusChange = (id) => {
    setCampusId(id);
    getAllClasses(id);
  };
  const getAllClasses = (id) => {
    setloading(true);
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    })
      .then((res) => {
        res.json().then((classes) => {
          setallclasses(classes.payload);
          setloading(false);
        });
      })

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const Assigned = () => {
    setupdateloading(true);
    let formdata = new FormData();

    formdata.append("class_id", fullClass.id);

    for (let i = 0; i < selectedsubjects.length; i++) {
      formdata.append(`subject_ids[${i}]`, selectedsubjects[i]);
    }

    fetch(`${baseUrl}/subjects-assign-to-classes`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
      body: formdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          alert.success("Result update successfully");
          setupdateloading(false);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg);
            setupdateloading(false);
          });
        }
        setAssignModal(false);
        console.log(data);
      })
      .catch((err) => {
        setupdateloading(false);
        console.log(err);
      });
  };

  const assignClick = (thereClass) => {
    setFullClass(thereClass);
    fetch(`${baseUrl}/assigned-subjects-of-class/${thereClass.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    })
      .then((res) => {
        res.json().then((res) => {
          console.log(res.payload);
          let arr = [];
          res.payload.map((item) => {
            arr.push({ value: item.id, label: item.name });
          });
          setoptionSelected(arr);
          setAssignModal(true);
        });
      })

      .catch((err) => {
        console.log(err);
      });
    // setAssignModal(true)
  };
  const handleChangeSubjects = (selected) => {
    console.log(selected);
    if (optionSelected.length == 30 && selected.length == 30) {
      setoptionSelected([]);
      return;
    }
    setoptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setselectedsubjects([...valuearray]);
    if (valuearray.length == 0) {
      setisanysubject(true);
      return;
    }
    setisanysubject(false);
  };

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const Options = [];
  allsubjects.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <Link to="/addcampusclass">
          <Button className="button_blue" variant="success">
            Add Class
          </Button>
        </Link>
        <div className="row mt-4">
          <div className="col-md-6">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                aria-label="Default select example"
                onChange={(e) => {
                  onCampusChange(e.target.value);
                }}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <Table style={{ margin: 10 }}>
          <thead>
            <tr className="text-center">
              <th style={{ textAlign: "start" }}># ID</th>
              <th style={{ textAlign: "start" }}>Class Name</th>
              <th style={{ textAlign: "center" }}>Assign Subjects</th>
              {/* <th>Edit</th> */}
              <th style={{ textAlign: "start" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {allclasses?.map((cls) => (
              <tr key={cls.id}>
                <td style={{ textAlign: "start" }}>{cls.id}</td>
                <td style={{ textAlign: "start" }}>{cls.name}</td>
                {/* <td >
                                    <a
                                        style={{ cursor: 'pointer' }}
                                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                        onClick={() => {
                                            setselectedobject(cls)
                                            seteditmodal(true)

                                        }
                                        }
                                    >
                                        <span className="svg-icon svg-icon-md svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                            />
                                        </span>
                                    </a>
                                </td> */}
                <td style={{ textAlign: "center" }}>
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => assignClick(cls)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                      />
                    </span>
                  </a>
                </td>
                <td style={{ textAlign: "start" }}>
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => deleteFee(cls)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Delete-user.svg"
                        )}
                      />
                    </span>
                  </a>
                </td>
              </tr>
            ))}
            <tr></tr>
          </tbody>
        </Table>
        <div>{loading && <span className="spinner-border"></span>}</div>

        <Modal show={deleteModal}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Are you sure want to delete class
            </Modal.Title>
          </Modal.Header>
          {deleteclassloader ? (
            <div style={{ padding: "15px", color: "green" }}>
              {" "}
              Class Deleting Please Wait{" "}
              <span className="spinner-border"></span>{" "}
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}></div>
              <button onClick={DeleteFee} className="btn button_blue">
                Delete
              </button>
              <button
                onClick={() => setdeleteModal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>

        <Modal show={assignModal}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Assign Subjects To {fullClass.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="form-group col-sm-12 flex-column d-flex">
              <label>
                <b>
                  Select subjects <span style={{ color: "red" }}>*</span>
                </b>
              </label>
              <MySelect
                options={Options}
                isMulti
                name="selectedOptions"
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChangeSubjects}
                allowSelectAll={true}
                value={optionSelected}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setAssignModal(false)}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
            <div style={{ display: "flex", justifyContent: "center" }}></div>
            {updateloading ? (
              <Spinner class="text-center" variant="info" animation="border" />
            ) : (
              <button onClick={Assigned} className="btn button_blue">
                Assign
              </button>
            )}
          </Modal.Footer>
        </Modal>

        {/* <Modal show={editmodal}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Campus Class
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <EditCampusClass seectedclass={selectedobject} show={editmodal} onHide={seteditmodal}/>
                </Modal.Body>
            </Modal> */}
      </Paper>
    </div>
  );
}

export default CampusClasses;
