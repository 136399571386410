import React, { useState, useRef, useEffect } from "react";
import { Divider } from "antd";
import { LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";

const StaffCertificate = () => {
  useEffect(() => {
    // getAllCampus();
    getSettingData();
  }, []);
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload[0].city);
        let campuses = [];
        setallcampuses(data.payload[0].city);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const currentDates = new Date().toISOString().slice(0, 10);
  const [code, setCode] = useState("");
  const [date, setDate] = useState(currentDates);
  const [campuses, setallcampuses] = useState([]);
  const [sub, setSub] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState({});
  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();
  const [icon, setIcon] = useState([]);

  const [directorSign, setDirectorSign] = useState(0);
  const [unitName, setUnitName] = useState("");
  // const icon =
  //   "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";

  const handleShow = () => {
    setMsg(document.getElementById("msgId").value);
    setSub(document.getElementById("subId").value);
    console.log(code);
    if (code == "" && date == "") {
      alert.error("please enter the employee code and select the date");
      return;
    }
    if (date == "") {
      alert.error("please select the date");
      return;
    }
    if (code == "") {
      alert.error("please enter the employee code");
      return;
    }
    setLoading(true);
    const data = {
      employee_code: code,
      date: date,
    };
    fetch(`${baseUrl}/staff-certificate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data, "=====");
        if (data.metadata) {
          if (data.metadata.success) {
            setreportData(data.payload);
          } else {
            alert.error("Record Not Found");
            setLoading(false);
            return;
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setMsg(document.getElementById("msgId").value);
    setSub(document.getElementById("subId").value);
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(data.payload.settings.unit_name);
        //  setDirectorSign(data.payload.settings.director_sign);
        setIcon(data.payload.settings.logo_file);

        // console.log(icon);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {loading ? <LinearProgress /> : null}

        <div className="row mt-3">
          <div className="col-md-4">
            <b style={{ fontSize: "15px", marginTop: "10px" }}>Date:</b>
            <input
              onChange={(e) => setDate(e.target.value)}
              type="date"
              defaultValue={date}
              placeholder="Enter employee code"
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <b style={{ fontSize: "15px", marginTop: "10px" }}>
              Employee Code:
            </b>
            <input
              onChange={(e) => setCode(e.target.value)}
              type="text"
              placeholder="Enter employee code"
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <button
              onClick={handleShow}
              className="btn btn-primary"
              style={{ marginTop: "18px" }}
            >
              Search
            </button>
          </div>
          <div className="col-md-10" style={{ marginTop: "10px" }}>
            <b style={{ fontSize: "15px", marginTop: "20px" }}>Subject</b>
            <input
              style={{ marginTop: "10px" }}
              type="text"
              placeholder="Enter Subject"
              className="form-control"
              id="subId"
            />
          </div>
          <div className="col-md-12" style={{ marginTop: "10px" }}>
            <b style={{ fontSize: "15px" }}>Certificate Details</b> <br />
            <textarea
              rows="5"
              cols="100"
              placeholder="Enter Certificate Message"
              id="msgId"
              style={{ marginTop: "10px" }}
            />
          </div>

          <div className="col-md-2">
            {reportData?.emp_code ? (
              <ReactToPrint
                documentTitle={`salary slip Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        <Divider />
        {reportData?.emp_code ? (
          <div className="mainContainer">
            <div className="logocontainer">
              <img
                // className='col-md-2'
                style={{ height: 100, width: 120 }}
                src={icon}
              />
            </div>
            <div>
              <br />
              <h2 style={{ textAlign: "center" }}>
                <b>{sub}</b>
              </h2>
              <br />
              <h2 style={{ textAlign: "center" }}>{reportData?.full_name}</h2>
              <br />
              <h4 style={{ textAlign: "center", whiteSpace: "pre-line" }}>
                {msg}
              </h4>
              <br />
              <h2 style={{ textAlign: "right" }}>Principal</h2>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          {reportData?.emp_code ? (
            <div
              className="mainContainer"
              style={{
                margin: "30px",
                border: "2px solid black",
                height: "1500px",
              }}
            >
              <div className="logocontainer" style={{ height: "250px" }}>
                <center>
                  <img
                    // className='col-md-2'
                    style={{
                      height: 200,
                      width: 170,
                      display: "inline-block",
                      textAlign: "center",
                      marginTop: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={icon}
                  />
                </center>
                <br />{" "}
                <p
                  style={{
                    display: "block",
                    textAlign: "center",
                    fontSize: "30px",
                    fontFamily: "Times New Roman",
                  }}
                >
                  <b>{unitName}</b>
                </p>
              </div>
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <div style={{ border: "2px solid black", fontSize: "30px" }}>
                  <p>
                    <b>Staff Certificate</b>
                  </p>{" "}
                </div>
              </div>
              <div>
                <div>
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      margin: "20px",
                    }}
                  >
                    <b> {sub}</b>
                  </p>
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      margin: "20px",
                    }}
                  >
                    <u>
                      <b>{reportData?.full_name}</b>
                    </u>
                  </p>
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "25px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {msg}
                  </p>
                  <br />
                  <h2
                    style={{
                      textAlign: "right",
                      margin: "30px",
                      marginLeft: "30px",
                      marginTop: "370px",
                    }}
                  >
                    <b>DIRECTOR: </b> ____________
                  </h2>
                  <p
                    style={{
                      textAlign: "left",
                      margin: "30px",
                      marginTop: "-70px",
                    }}
                  >
                    <b style={{ fontSize: "25px" }}>Dated: </b>{" "}
                    <label style={{ fontSize: "22px" }}>{date}</label>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Paper>
    </>
  );
};

export default StaffCertificate;
