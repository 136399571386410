import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { CustomersPage } from "./customers/CustomersPage";

import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { PremissionChecker } from "../../../../services/PremissionChecker";
import { useSelector } from "react-redux";

export default function Vehicle() {
  const user = useSelector((state) => state.user.user);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect exact={true} from="/vehicle" to="/vehicle/addvehicle" />
        }
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.vehicles")
              ? user.roles[0].name
              : "",
          ]}
          path="/vehicle/addvehicle"
          component={CustomersPage}
        />
      </Switch>
    </Suspense>
  );
}
