import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert, Form } from "react-bootstrap";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import { useSelector } from "react-redux";

export default function PromoteStudentModal({
  show,
  onHide,
  students,
  reload,
}) {
  const alert = useAlert();
  // configgration
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  //arrays
  const [allsessions, setallsessions] = useState([]);
  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [allcampus_classes, setallcampus_classes] = useState([]);
  const [allcampus_classe_sections, setallcampus_classe_sections] = useState(
    []
  );
  const [educationType, setEducationType] = useState(1);
  //strings
  const [selectedcampus, setselectedcampus] = useState("");
  const [allCampusSections, setallCampusSections] = useState([]);
  const [selectedclass, setselectedclass] = useState("");
  const [selectedsection, setselectedsection] = useState("");
  const [currentsession, setcurrentsession] = useState("");
  //booleans
  const [loader, setloader] = useState(false);

  useEffect(() => {
    // getAllCampus();
    getAllSessions();
  }, []);

  //functions

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setallcampus_classe_sections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("campus", err);
      });
  };

  const changesession = (id) => {
    setcurrentsession(id);
  };

  const onchangecampus = (id) => {
    setallcampus_classes([]);
    setallcampus_classe_sections([]);
    setselectedcampus(id);
    setselectedclass("");
    setselectedsection("");

    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampus_classes([...data.payload]);
        console.log("class", data);
      })
      .catch((err) => {
        console.log(err);
        console.log("class", err);
      });
  };
  const onchangeclassfunc = (id) => {
    setallcampus_classe_sections([]);
    setselectedsection("");
    setselectedclass(id);

    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampus_classe_sections([...data.payload]);
        console.log("section", data);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };
  const onchangeclasssection = (id) => {
    setselectedsection(id);
  };

  const passOutStudent = () => {
    setloader(true);
    let data = {
      student_ids: students,
    };
    console.log(data);
    fetch(`${baseUrl}/student_pass_out `, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloader(false);
        if (data.metadata) {
          if (data.metadata.success) {
            reload(
              selectedcampus,
              selectedclass,
              selectedsection,
              currentsession
            );
            alert.show("Successfully Pass Out");
          } else {
            alert.show("Error Occured");
          }
        } else {
          alert.show("Error Occured");
        }

        console.log("students", data);
        onHide(false);
      })
      .catch((err) => {
        setloader(false);
        alert.show("Error Occured");
        console.log(err);
        console.log("students", err);
      });
  };

  const promoteStudents = () => {
    if (selectedcampus == "") {
      alert.error("Campus is Required");
      return;
    }
    if (selectedclass == "") {
      alert.error("Class is Required");
      return;
    }
    if (selectedsection == "") {
      alert.error("Section is Required");
      return;
    }

    setloader(true);
    let data = {
      campus_id: selectedcampus,
      student_class_id: selectedclass,
      global_section_id: selectedsection,
      education_type: educationType,
      year_id: currentsession,
      student_ids: students,
    };
    console.log(data);
    fetch(`${baseUrl}/student_jump`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloader(false);
        if (data.metadata) {
          if (data.metadata.success) {
            reload(
              selectedcampus,
              selectedclass,
              selectedsection,
              currentsession
            );
            alert.show("Successfully Promoted");
          } else {
            alert.show("Error Occured");
          }
        } else {
          alert.show("Error Occured");
        }

        console.log("students", data);
        onHide(false);
      })
      .catch((err) => {
        setloader(false);
        alert.show("Error Occured");
        console.log(err);
        console.log("students", err);
      });
  };

  return (
    <div>
      <Modal size="lg" show={show}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Promote Students
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group col-sm-12 flex-column d-flex">
            <label>
              <b>
                Select Session <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              onChange={(e) => changesession(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Session
              </option>
              {allsessions.map((session) => (
                <option value={session.id}>{session.year}</option>
              ))}
            </select>
          </div>
          {currentsession != "" ? (
            <>
              <form class="form-card">
                <div className="row">
                  <div className="form-group col-sm-4 flex-column d-flex">
                    <label>
                      <b>Select Campus </b>
                    </label>
                    <select
                      onChange={(e) => onchangecampus(e.target.value)}
                      className="form-select"
                      style={{ height: 40 }}
                      value={selectedcampus}
                      aria-label="Default select example"
                    >
                      <option selected value="">
                        Select Campus
                      </option>

                      {allcampuses.map(
                        (campus, index) => (
                          // user.head_office.id == campus.head_office_id ? (
                          <>
                            <option key={campus.id} value={campus.id}>
                              {campus.name}
                            </option>
                          </>
                        )
                        // ) : null
                      )}
                    </select>
                  </div>
                  <div className="form-group col-sm-4 flex-column d-flex">
                    <label>
                      <b>Select Claass </b>
                    </label>
                    <select
                      onChange={(e) => onchangeclassfunc(e.target.value)}
                      className="form-select"
                      style={{ height: 40 }}
                      aria-label="Default select example"
                    >
                      <option selected value="">
                        Select Class
                      </option>

                      {allcampus_classes.map((item) => (
                        <option name={item.name} key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-sm-4 flex-column d-flex">
                    <label>
                      <b>Select Education Type</b>
                    </label>
                    <select
                      className="form-select"
                      name="campus_id"
                      value={educationType}
                      style={{ height: 40 }}
                      aria-label="Default select example"
                      onChange={(e) => {
                        setEducationType(e.target.value);
                        getSectionsListOnTypeChange(e.target.value);
                      }}
                    >
                      <option selected value={1}>
                        Education
                      </option>
                      <option value={2}>Hifz</option>
                    </select>
                  </div>
                  <div className="form-group col-sm-4 flex-column d-flex">
                    <label>
                      <b>Select Section </b>
                    </label>
                    <select
                      onChange={(e) => onchangeclasssection(e.target.value)}
                      className="form-select"
                      style={{ height: 40 }}
                      aria-label="Default select example"
                    >
                      <option selected value="">
                        Select Section
                      </option>
                      {allcampus_classe_sections.map((sec, index) => (
                        <option key={index} value={sec.id}>
                          {sec.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          {currentsession != "" ? (
            <>
              {loader ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "15px",
                  }}
                >
                  {" "}
                  <span className="spinner-border"></span>{" "}
                </div>
              ) : (
                <>
                  <button
                    onClick={promoteStudents}
                    className="btn btn-success button_blue"
                  >
                    Promote
                  </button>
                  <button
                    onClick={passOutStudent}
                    className="btn btn-success button_blue"
                  >
                    Pass Out
                  </button>
                </>
              )}
            </>
          ) : null}
          <button
            onClick={() => {
              //cencelModal()
              onHide(false);
            }}
            className="btn btn-danger btn_width"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
