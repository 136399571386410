import React from "react"
import { Table, Modal, Tab, Tabs, Alert } from 'react-bootstrap'

export default function ViewFeeModal({ show, onHide, feedetail }) {
    console.log(feedetail, "feedetail")
    return (

        <div>


            <Modal size="xl" show={show}>

                <Modal.Body>

                    <div className="card">

                        <Table responsive>
                            <thead>
                                <tr id="tbl">
                                    <th>SR#</th>
                                    <th>Fee Name</th>
                                    <th>Amount</th>
                                    <th>Challan No</th>
                                    <th>Due Date</th>

                                </tr>
                            </thead>
                            {
                                feedetail.fee_challan_details == undefined ? null :
                                    <>
                                        {
                                            feedetail.fee_challan_details.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td style={{ textAlign: "start" }}>{index + 1}</td>
                                                        <td style={{ textAlign: "start" }}>{item.fee_name}</td>
                                                        <td style={{ textAlign: "start" }}>{item.amount}</td>
                                                        <td style={{ textAlign: "start" }}>{feedetail.challan_no}</td>
                                                        <td style={{ textAlign: "start" }}>{feedetail.due_date}</td>
                                                    </tr>
                                                )

                                            })
                                        }
                                    </>
                            }

                           

                        </Table>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            onClick={() => {
                                onHide(false)
                            }}
                            style={{ width: '150px', height: '40px' }}
                            className="btn btn-danger btn_width">
                            Cancel
                        </button>

                    </div>

                </Modal.Footer>
            </Modal>
        </div>
    )
}