import { upperCase } from "lodash";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../services/config";
import { Table } from "react-bootstrap";
import Barcode from "react-barcode";

function MultiStudentFeeChallan({ allstudentList, blankPage }) {
  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMonthName2 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getMonthName3 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };

  const getIssueDate = (arr) => {
    console.log(arr, "before");
    if (Array.isArray(arr) && arr.length > 0) {
      const result = arr[arr.length - 1];
      console.log(result, "after");
      return result;
    }
    return null; // or any default value you want to return if the array is empty or undefined
  };

  const getFeeAmount = (challans) => {
    let sum = 0;
    if (Array.isArray(challans)) {
      challans.forEach((element) => {
        sum += parseInt(element.amount);
      });
    }
    return sum;
  };

  return (
    <div style={{ visibility: "hidden" }}>
      <>
        {allstudentList?.map((selectedStudent, i) => (
          <>
            {blankPage ? (
              <>
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "auto",
                      width: "auto",
                      position: "relative",
                    }}
                  >
                    {/* Bank Copy Challan */}
                    <div
                      style={{
                        border: "1px solid #121291",
                        height: "98vh",
                        width: "320px",
                      }}
                    >
                      {/* Logo & Campus Name */}
                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                        }}
                      >
                        <img
                          src={icon}
                          style={{ height: 70, width: 70 }}
                          alt="icon"
                        />
                        <div className="text-center ml-2">
                          <h5 style={{ color: "#121291", fontSize: "1.4rem" }}>
                            {unitName}
                          </h5>
                          <h5 style={{ color: "#121291", fontSize: "1.2rem" }}>
                            {selectedStudent?.campus?.name}
                          </h5>
                        </div>
                      </div>

                      {/* Bank Details */}
                      <div
                        className="text-center"
                        style={{
                          borderTop: "1px solid #121291",
                          borderBottom: "1px solid #121291",
                          padding: "1px",
                          textAlign: "center",
                        }}
                      >
                        {selectedStudent?.campus?.print_account_no &&
                          selectedStudent?.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b
                                  style={{
                                    fontSize: "1.1rem",
                                  }}
                                >{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}

                        {/* <b className="mytextfont">
                            {selectedStudent.campus.name}
                          </b> 
                          <br />*/}
                      </div>

                      {/* Student Details */}
                      <div className="p-3">
                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              // className="mytextfont printsecontpart"
                              style={{ width: "20px", fontSize: "1.2rem" }}
                            >
                              Due Date:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.due_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              // className="mytextfont printsecontpart"
                              style={{ width: "20px", fontSize: "1.2rem" }}
                            >
                              Issue Date:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.issue_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              // className="mytextfont printsecontpart"
                              style={{ width: "20px", fontSize: "1.2rem" }}
                            >
                              {selectedStudent.admission_id == null
                                ? "Register Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p>
                              <b>
                                <b
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {selectedStudent.admission_id == null
                                    ? selectedStudent.registration_id
                                    : selectedStudent.admission_id}
                                </b>
                              </b>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "55px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Name:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {selectedStudent.name}
                              <br />
                              {selectedStudent.gender?.toLowerCase() == "male"
                                ? "S/O"
                                : "D/O"}{" "}
                              {selectedStudent?.father_name}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Class:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent?.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedStudent.student_class_name +
                                " (" +
                                selectedStudent?.global_section_name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Fee Challan Details */}
                      <div
                        style={{
                          border: "1px solid  #121291",
                          margin: "2px",
                          // height: "375px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            fontSize: "1rem",
                            whiteSpace: "normal",
                            padding: "0.5px",
                            color: "#121291",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <div className="pl-3">
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description</b>
                          </div>
                          <div className="pr-3">
                            <b>Amount</b>
                          </div>
                        </div>

                        {selectedStudent?.fee_challans_detials
                          ?.slice(0, 18)
                          .map((copy, index) => (
                            <div
                              className="d-flex justify-content-between text-center"
                              style={{
                                color: "black",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <div className="pl-3">
                                {copy.fee_challan.challan_no}
                              </div>
                              <div>
                                {copy.fee_name.length > 20
                                  ? `${copy.fee_name.substring(0, 18)}..`
                                  : copy.fee_name}{" "}
                                {getMonthName3(copy?.fee_month)}
                              </div>
                              <div className="pr-3">{copy.amount}</div>
                            </div>
                          ))}
                        <div className="text-center" style={{ color: "black" }}>
                          {selectedStudent.fee_challans_detials.length > 18
                            ? "Continue ...."
                            : null}
                        </div>
                      </div>

                      {/* Total Amount */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 15,
                          right: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          // borderTop: "1px solid #ccc",
                          // padding: "10px",
                          width: "290px",
                        }}
                      >
                        {selectedStudent.fee_challans_detials && (
                          <b style={{ fontSize: "1rem", padding: "5px 0" }}>
                            {selectedStudent.fee_challans_detials.length > 18
                              ? "Total Remaining Amount"
                              : "Total Amount"}
                          </b>
                        )}
                        <p
                          className="btn btn-white"
                          style={{
                            border: "1px solid black",
                            fontSize: "1rem",
                            padding: "2px 8px",
                          }}
                        >
                          <b>
                            {getFeeAmount(
                              selectedStudent?.fee_challans_detials
                            )}
                          </b>
                        </p>
                      </div>

                      {/* Bank Copy Text */}
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          bottom: -10,
                          left: 0,
                          right: 685,
                          // marginTop: "-15px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "1.1rem",
                            padding: "1px 0",
                            color: "black",
                          }}
                        >
                          Bank Copy
                        </p>

                        <Barcode
                          value={
                            selectedStudent?.admission_id == null
                              ? selectedStudent?.registration_id
                              : selectedStudent?.admission_id
                          }
                          height={25}
                          margin={1}
                          displayValue={false}
                        />
                      </div>
                    </div>

                    {/* School Copy Challan */}
                    <div
                      style={{
                        border: "1px solid #121291",
                        height: "98vh",
                        width: "320px",
                      }}
                    >
                      {/* Logo & Campus Name */}
                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                        }}
                      >
                        <img
                          src={icon}
                          style={{ height: 70, width: 70 }}
                          alt="icon"
                        />
                        <div className="text-center ml-2">
                          <h5 style={{ color: "#121291", fontSize: "1.4rem" }}>
                            {unitName}
                          </h5>
                          <h5 style={{ color: "#121291", fontSize: "1.2rem" }}>
                            {selectedStudent?.campus?.name}
                          </h5>
                        </div>
                      </div>

                      {/* Bank Details */}
                      <div
                        className="text-center"
                        style={{
                          borderTop: "1px solid #121291",
                          borderBottom: "1px solid #121291",
                          padding: "1px",
                          textAlign: "center",
                        }}
                      >
                        {selectedStudent?.campus?.print_account_no &&
                          selectedStudent?.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b
                                  style={{
                                    fontSize: "1.1rem",
                                  }}
                                >{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}

                        {/* <b className="mytextfont">
                            {selectedStudent.campus.name}
                          </b> 
                          <br />*/}
                      </div>

                      {/* Student Details */}
                      <div className="p-3">
                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Due Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.due_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Issue Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.issue_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              // className="mytextfont printsecontpart"
                              style={{ width: "20px", fontSize: "1.2rem" }}
                            >
                              {selectedStudent.admission_id == null
                                ? "Register Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p>
                              <b>
                                <b
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {selectedStudent.admission_id == null
                                    ? selectedStudent.registration_id
                                    : selectedStudent.admission_id}
                                </b>
                              </b>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "55px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Name:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent.name}
                              <br />
                              {selectedStudent.gender?.toLowerCase() == "male"
                                ? "S/O"
                                : "D/O"}{" "}
                              {selectedStudent?.father_name}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Class:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent?.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedStudent.student_class_name +
                                " (" +
                                selectedStudent?.global_section_name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Fee Challan Details */}
                      <div
                        style={{
                          border: "1px solid  #121291",
                          margin: "2px",
                          // height: "375px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            fontSize: "1rem",
                            whiteSpace: "normal",
                            padding: "0.5px",
                            color: "#121291",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <div className="pl-3">
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description</b>
                          </div>
                          <div className="pr-3">
                            <b>Amount</b>
                          </div>
                        </div>

                        {selectedStudent?.fee_challans_detials
                          ?.slice(0, 18)
                          .map((copy, index) => (
                            <div
                              className="d-flex justify-content-between text-center"
                              style={{
                                color: "black",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <div className="pl-3">
                                {copy.fee_challan.challan_no}
                              </div>
                              <div>
                                {copy.fee_name.length > 20
                                  ? `${copy.fee_name.substring(0, 18)}..`
                                  : copy.fee_name}{" "}
                                {getMonthName3(copy?.fee_month)}
                              </div>
                              <div className="pr-3">{copy.amount}</div>
                            </div>
                          ))}
                        <div className="text-center" style={{ color: "black" }}>
                          {selectedStudent.fee_challans_detials.length > 18
                            ? "Continue ...."
                            : null}
                        </div>
                      </div>

                      {/* Total Amount */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 350,
                          right: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          // borderTop: "1px solid #ccc",
                          // padding: "10px",
                          width: "290px",
                        }}
                      >
                        {selectedStudent.fee_challans_detials && (
                          <b style={{ fontSize: "1rem", padding: "5px 0" }}>
                            {selectedStudent.fee_challans_detials.length > 18
                              ? "Total Remaining Amount"
                              : "Total Amount"}
                          </b>
                        )}
                        <p
                          className="btn btn-white"
                          style={{
                            border: "1px solid black",
                            fontSize: "1rem",
                            padding: "2px 8px",
                          }}
                        >
                          <b>
                            {getFeeAmount(
                              selectedStudent?.fee_challans_detials
                            )}
                          </b>
                        </p>
                      </div>

                      {/* Studnet Copy Text */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: -10,
                          left: 0,
                          right: 0,
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "1.1rem",
                            padding: "1px 0",
                            color: "black",
                          }}
                        >
                          School Copy
                        </p>
                        <Barcode
                          value={
                            selectedStudent?.admission_id == null
                              ? selectedStudent?.registration_id
                              : selectedStudent?.admission_id
                          }
                          height={25}
                          margin={1}
                          displayValue={false}
                        />
                      </div>
                    </div>

                    {/* Student Copy Challan */}
                    <div
                      style={{
                        border: "1px solid #121291",
                        height: "98vh",
                        width: "320px",
                      }}
                    >
                      {/* Logo & Campus Name */}
                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                        }}
                      >
                        <img
                          src={icon}
                          style={{ height: 70, width: 70 }}
                          alt="icon"
                        />
                        <div className="text-center ml-2">
                          <h5 style={{ color: "#121291", fontSize: "1.4rem" }}>
                            {unitName}
                          </h5>
                          <h5 style={{ color: "#121291", fontSize: "1.2rem" }}>
                            {selectedStudent?.campus?.name}
                          </h5>
                        </div>
                      </div>

                      {/* Bank Details */}
                      <div
                        className="text-center"
                        style={{
                          borderTop: "1px solid #121291",
                          borderBottom: "1px solid #121291",
                          padding: "1px",
                          textAlign: "center",
                        }}
                      >
                        {selectedStudent?.campus?.print_account_no &&
                          selectedStudent?.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b
                                  style={{
                                    fontSize: "1.1rem",
                                  }}
                                >{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}

                        {/* <b className="mytextfont">
                            {selectedStudent.campus.name}
                          </b> 
                          <br />*/}
                      </div>

                      {/* Student Details */}
                      <div className="p-3">
                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Due Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.due_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              // className="mytextfont printsecontpart"
                              style={{ width: "20px", fontSize: "1.2rem" }}
                            >
                              Issue Date:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.issue_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              // className="mytextfont printsecontpart"
                              style={{ width: "20px", fontSize: "1.2rem" }}
                            >
                              {selectedStudent.admission_id == null
                                ? "Register Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p>
                              <b>
                                <b
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {selectedStudent.admission_id == null
                                    ? selectedStudent.registration_id
                                    : selectedStudent.admission_id}
                                </b>
                              </b>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "55px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Name:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent.name}
                              <br />
                              {selectedStudent.gender?.toLowerCase() == "male"
                                ? "S/O"
                                : "D/O"}{" "}
                              {selectedStudent?.father_name}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex" style={{ height: "25px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b style={{ width: "20px", fontSize: "1.2rem" }}>
                              Class:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent?.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedStudent.student_class_name +
                                " (" +
                                selectedStudent?.global_section_name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Fee Challan Details */}
                      <div
                        style={{
                          border: "1px solid  #121291",
                          margin: "2px",
                          // height: "375px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            fontSize: "1rem",
                            whiteSpace: "normal",
                            padding: "0.5px",
                            color: "#121291",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <div className="pl-3">
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description</b>
                          </div>
                          <div className="pr-3">
                            <b>Amount</b>
                          </div>
                        </div>

                        {selectedStudent?.fee_challans_detials
                          ?.slice(0, 18)
                          .map((copy, index) => (
                            <div
                              className="d-flex justify-content-between text-center"
                              style={{
                                color: "black",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <div className="pl-3">
                                {copy.fee_challan.challan_no}
                              </div>
                              <div>
                                {copy.fee_name.length > 20
                                  ? `${copy.fee_name.substring(0, 18)}..`
                                  : copy.fee_name}{" "}
                                {getMonthName3(copy?.fee_month)}
                              </div>
                              <div className="pr-3">{copy.amount}</div>
                            </div>
                          ))}
                        <div className="text-center" style={{ color: "black" }}>
                          {selectedStudent.fee_challans_detials.length > 18
                            ? "Continue ...."
                            : null}
                        </div>
                      </div>

                      {/* Total Amount */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 685,
                          right: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          // borderTop: "1px solid #ccc",
                          // padding: "10px",
                          width: "290px",
                        }}
                      >
                        {selectedStudent.fee_challans_detials && (
                          <b style={{ fontSize: "1rem", padding: "5px 0" }}>
                            {selectedStudent.fee_challans_detials.length > 18
                              ? "Total Remaining Amount"
                              : "Total Amount"}
                          </b>
                        )}
                        <p
                          className="btn btn-white"
                          style={{
                            border: "1px solid black",
                            fontSize: "1rem",
                            padding: "2px 8px",
                          }}
                        >
                          <b>
                            {getFeeAmount(
                              selectedStudent?.fee_challans_detials
                            )}
                          </b>
                        </p>
                      </div>

                      {/* Studnet Copy Text */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: -10,
                          left: 685,
                          right: 0,
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "1rem",
                            padding: "1px 0",
                            color: "black",
                          }}
                        >
                          Student Copy
                        </p>
                        <Barcode
                          value={
                            selectedStudent?.admission_id == null
                              ? selectedStudent?.registration_id
                              : selectedStudent?.admission_id
                          }
                          height={25}
                          margin={1}
                          displayValue={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "auto",
                      width: "auto",
                      position: "relative",
                    }}
                  >
                    {/* Bank Copy Challan Blank */}
                    <div
                      style={{
                        // border: "1px solid #121291",
                        height: "98vh",
                        width: "300px",
                        marginLeft: "10px",
                      }}
                    >
                      {/* Logo & Campus Name */}
                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                          position: "absolute",
                        }}
                      >
                        {/* <img
                              src={icon}
                              style={{ height: 70, width: 70 }}
                              alt="icon"
                            />
                            <div className="text-center ml-2">
                              <h5
                                style={{ color: "#121291", fontSize: "1.4rem" }}
                              >
                                {unitName}
                              </h5>
                              <h5
                                style={{ color: "#121291", fontSize: "1.2rem" }}
                              >
                                {selectedStudent?.campus.name}
                              </h5>
                            </div> */}
                      </div>

                      {/* Bank Details */}
                      <div
                        className="text-center"
                        style={{
                          // borderTop: "1px solid #121291",
                          // borderBottom: "1px solid #121291",
                          padding: "0",
                          marginTop: "100px",
                        }}
                      >
                        {selectedStudent?.campus?.print_account_no &&
                          selectedStudent?.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b
                                  style={{
                                    fontSize: "10px",
                                    padding: "1px",
                                  }}
                                >{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}

                        {/* <b className="mytextfont">
                            {selectedStudent.campus.name}
                          </b> 
                          <br />*/}
                      </div>

                      {/* Student Details */}
                      <div
                        style={{
                          padding: "4px 12px",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="text-center">
                          <b style={{ fontSize: "1.2rem", color: "#121291" }}>
                            {selectedStudent?.campus?.name}
                          </b>
                          <br />
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Due Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.due_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Issue Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.issue_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {selectedStudent.admission_id == null
                                ? "Register Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p>
                              <b>
                                <b
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {selectedStudent.admission_id == null
                                    ? selectedStudent.registration_id
                                    : selectedStudent.admission_id}
                                </b>
                              </b>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "50px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Name:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent.name}
                              <br />
                              {selectedStudent.gender?.toLowerCase() == "male"
                                ? "S/O"
                                : "D/O"}{" "}
                              {selectedStudent?.father_name}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Class:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent?.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedStudent.student_class_name +
                                " (" +
                                selectedStudent?.global_section_name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Fee Challan Details */}
                      <div
                        style={{
                          // border: "1px solid  #121291",
                          margin: "2px",
                          // height: "375px",
                          width: "285px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            fontSize: "1rem",
                            whiteSpace: "normal",
                            padding: "0.5px",
                            color: "#121291",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <div className="pl-2">
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description</b>
                          </div>
                          <div>
                            <b>Amount</b>
                          </div>
                        </div>

                        {selectedStudent?.fee_challans_detials
                          ?.slice(0, 18)
                          .map((copy, index) => (
                            <div
                              className="d-flex justify-content-between text-center"
                              style={{ color: "black" }}
                            >
                              <div className="pl-2">
                                {copy.fee_challan.challan_no}
                              </div>
                              <div>
                                {copy.fee_name.length > 20
                                  ? `${copy.fee_name.substring(0, 18)}..`
                                  : copy.fee_name}{" "}
                                {getMonthName3(copy?.fee_month)}
                              </div>
                              <div>{copy.amount}</div>
                            </div>
                          ))}
                        <div className="text-center" style={{ color: "black" }}>
                          {selectedStudent.fee_challans_detials.length > 18
                            ? "Continue ...."
                            : null}
                        </div>
                      </div>

                      {/* Total Amount */}
                      <div
                        className="p-3"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 15,
                          right: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          // borderTop: "1px solid #ccc",
                          padding: "10px",
                          width: "290px",
                        }}
                      >
                        {selectedStudent.fee_challans_detials && (
                          <b style={{ fontSize: "1rem", padding: "5px 0" }}>
                            {selectedStudent.fee_challans_detials.length > 18
                              ? "Total Remaining Amount"
                              : "Total Amount"}
                          </b>
                        )}
                        <p
                          className="btn btn-white"
                          style={{
                            border: "1px solid black",
                            fontSize: "1rem",
                            padding: "2px 8px",
                          }}
                        >
                          <b>
                            {getFeeAmount(
                              selectedStudent?.fee_challans_detials
                            )}
                          </b>
                        </p>
                      </div>

                      {/* Bank Copy Text */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: -10,
                          left: 0,
                          right: 685,
                          textAlign: "center",
                        }}
                      >
                        <Barcode
                          value={
                            selectedStudent?.admission_id == null
                              ? selectedStudent?.registration_id
                              : selectedStudent?.admission_id
                          }
                          height={25}
                          margin={1}
                          displayValue={false}
                        />
                      </div>
                    </div>

                    {/* School Copy Challan Blank */}
                    <div
                      style={{
                        // border: "1px solid #121291",
                        height: "98vh",
                        width: "300px",
                        marginLeft: "5px",
                      }}
                    >
                      {/* Logo & Campus Name */}
                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                          position: "absolute",
                        }}
                      >
                        {/* <img
                              src={icon}
                              style={{ height: 70, width: 70 }}
                              alt="icon"
                            />
                            <div className="text-center ml-2">
                              <h5
                                style={{ color: "#121291", fontSize: "1.4rem" }}
                              >
                                {unitName}
                              </h5>
                              <h5
                                style={{ color: "#121291", fontSize: "1.2rem" }}
                              >
                                {selectedStudent?.campus.name}
                              </h5>
                            </div> */}
                      </div>

                      {/* Bank Details */}
                      <div
                        className="text-center"
                        style={{
                          // borderTop: "1px solid #121291",
                          // borderBottom: "1px solid #121291",
                          padding: "0",
                          marginTop: "100px",
                        }}
                      >
                        {selectedStudent?.campus?.print_account_no &&
                          selectedStudent?.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b
                                  style={{
                                    fontSize: "10px",
                                    padding: "1px",
                                  }}
                                >{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}

                        {/* <b className="mytextfont">
                            {selectedStudent.campus.name}
                          </b> 
                          <br />*/}
                      </div>

                      {/* Student Details */}
                      <div
                        style={{
                          padding: "4px 12px",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="text-center">
                          <b style={{ fontSize: "1.2rem", color: "#121291" }}>
                            {selectedStudent?.campus?.name}
                          </b>
                          <br />
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Due Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.due_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Issue Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.issue_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {selectedStudent.admission_id == null
                                ? "Register Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p>
                              <b>
                                <b
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {selectedStudent.admission_id == null
                                    ? selectedStudent.registration_id
                                    : selectedStudent.admission_id}
                                </b>
                              </b>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "50px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Name:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent.name}
                              <br />
                              {selectedStudent.gender?.toLowerCase() == "male"
                                ? "S/O"
                                : "D/O"}{" "}
                              {selectedStudent?.father_name}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Class:
                            </b>
                          </div>
                          <div
                            // className="mytextfont printseconcontent"
                            style={{ width: "auto", textAlign: "start" }}
                          >
                            <p style={{ color: "black" }}>
                              {selectedStudent?.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedStudent.student_class_name +
                                " (" +
                                selectedStudent?.global_section_name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Fee Challan Details */}
                      <div
                        style={{
                          // border: "1px solid  #121291",
                          margin: "2px",
                          // height: "375px",
                          width: "285px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            fontSize: "1rem",
                            whiteSpace: "normal",
                            padding: "0.5px",
                            color: "#121291",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <div className="pl-2">
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description</b>
                          </div>
                          <div>
                            <b>Amount</b>
                          </div>
                        </div>

                        {selectedStudent?.fee_challans_detials
                          ?.slice(0, 18)
                          .map((copy, index) => (
                            <div
                              className="d-flex justify-content-between text-center"
                              style={{ color: "black" }}
                            >
                              <div className="pl-2">
                                {copy.fee_challan.challan_no}
                              </div>
                              <div>
                                {copy.fee_name.length > 20
                                  ? `${copy.fee_name.substring(0, 18)}..`
                                  : copy.fee_name}{" "}
                                {getMonthName3(copy?.fee_month)}
                              </div>
                              <div>{copy.amount}</div>
                            </div>
                          ))}
                        <div className="text-center" style={{ color: "black" }}>
                          {selectedStudent.fee_challans_detials.length > 18
                            ? "Continue ...."
                            : null}
                        </div>
                      </div>

                      {/* Total Amount */}
                      <div
                        className="p-3"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 360,
                          right: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          // borderTop: "1px solid #ccc",
                          padding: "10px",
                          width: "290px",
                        }}
                      >
                        {selectedStudent.fee_challans_detials && (
                          <b style={{ fontSize: "1rem", padding: "5px 0" }}>
                            {selectedStudent.fee_challans_detials.length > 18
                              ? "Total Remaining Amount"
                              : "Total Amount"}
                          </b>
                        )}
                        <p
                          className="btn btn-white"
                          style={{
                            border: "1px solid black",
                            fontSize: "1rem",
                            padding: "2px 8px",
                          }}
                        >
                          <b>
                            {getFeeAmount(
                              selectedStudent?.fee_challans_detials
                            )}
                          </b>
                        </p>
                      </div>

                      {/* School Copy Text */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: -10,
                          left: 0,
                          right: 0,
                          textAlign: "center",
                        }}
                      >
                        <Barcode
                          value={
                            selectedStudent?.admission_id == null
                              ? selectedStudent?.registration_id
                              : selectedStudent?.admission_id
                          }
                          height={25}
                          margin={1}
                          displayValue={false}
                        />
                      </div>
                    </div>

                    {/* Student Copy Challan Blank */}
                    <div
                      style={{
                        height: "98vh",
                        width: "300px",
                        // marginLeft: "15px",
                      }}
                    >
                      {/* Logo & Campus Name */}
                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                          position: "absolute",
                        }}
                      ></div>

                      {/* Bank Details */}
                      <div
                        className="text-center"
                        style={{
                          padding: "0",
                          marginTop: "100px",
                        }}
                      >
                        {selectedStudent?.campus?.print_account_no &&
                          selectedStudent?.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b
                                  style={{
                                    fontSize: "10px",
                                    padding: "1px",
                                  }}
                                >{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}
                      </div>

                      {/* Student Details */}
                      <div
                        style={{
                          padding: "4px 12px",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="text-center">
                          <b style={{ fontSize: "1.2rem", color: "#121291" }}>
                            {selectedStudent?.campus?.name}
                          </b>
                          <br />
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Due Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.due_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Issue Date:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {getMonthName2(
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.issue_date
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "120px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {selectedStudent.admission_id == null
                                ? "Register Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p>
                              <b
                                style={{
                                  color: "black",
                                }}
                              >
                                <b
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {selectedStudent.admission_id == null
                                    ? selectedStudent.registration_id
                                    : selectedStudent.admission_id}
                                </b>
                              </b>
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "50px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Name:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {selectedStudent.name}
                              <br />
                              {selectedStudent.gender?.toLowerCase() == "male"
                                ? "S/O"
                                : "D/O"}{" "}
                              {selectedStudent?.father_name}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex" style={{ height: "20px" }}>
                          <div style={{ width: "70px", paddingLeft: "5px" }}>
                            <b
                              style={{
                                width: "20px",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Class:
                            </b>
                          </div>
                          <div style={{ width: "auto", textAlign: "start" }}>
                            <p style={{ color: "black" }}>
                              {selectedStudent?.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedStudent.student_class_name +
                                " (" +
                                selectedStudent?.global_section_name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Fee Challan Details */}
                      <div
                        style={{
                          // border: "1px solid  #121291",
                          margin: "2px",
                          // height: "375px",
                          width: "285px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            fontSize: "1rem",
                            whiteSpace: "normal",
                            padding: "0.5px",
                            color: "#121291",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <div className="pl-2">
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description</b>
                          </div>
                          <div>
                            <b>Amount</b>
                          </div>
                        </div>

                        {selectedStudent?.fee_challans_detials
                          ?.slice(0, 18)
                          .map((copy, index) => (
                            <div
                              className="d-flex justify-content-between text-center"
                              style={{ color: "black" }}
                            >
                              <div className="pl-2">
                                {copy.fee_challan.challan_no}
                              </div>
                              <div>
                                {copy.fee_name.length > 20
                                  ? `${copy.fee_name.substring(0, 18)}..`
                                  : copy.fee_name}{" "}
                                {getMonthName3(copy?.fee_month)}
                              </div>
                              <div>{copy.amount}</div>
                            </div>
                          ))}

                        <div className="text-center" style={{ color: "black" }}>
                          {selectedStudent.fee_challans_detials.length > 18
                            ? "Continue ...."
                            : null}
                        </div>
                      </div>

                      {/* Total Amount */}
                      <div
                        className="p-3"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 700,
                          right: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          // borderTop: "1px solid #ccc",
                          padding: "10px",
                          width: "290px",
                        }}
                      >
                        {selectedStudent.fee_challans_detials && (
                          <b style={{ fontSize: "1rem", padding: "5px 0" }}>
                            {selectedStudent.fee_challans_detials.length > 18
                              ? "Total Remaining Amount"
                              : "Total Amount"}
                          </b>
                        )}
                        <p
                          className="btn btn-white"
                          style={{
                            border: "1px solid black",
                            fontSize: "1rem",
                            padding: "2px 8px",
                          }}
                        >
                          <b>
                            {getFeeAmount(
                              selectedStudent?.fee_challans_detials
                            )}
                          </b>
                        </p>
                      </div>

                      {/* Student Copy Text */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: -10,
                          left: 685,
                          right: 0,
                          textAlign: "center",
                        }}
                      >
                        <Barcode
                          value={
                            selectedStudent?.admission_id == null
                              ? selectedStudent?.registration_id
                              : selectedStudent?.admission_id
                          }
                          height={25}
                          margin={1}
                          displayValue={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ))}
      </>
    </div>
  );
}

export default MultiStudentFeeChallan;
