import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { baseUrl } from '../../../../../services/config'
import { Modal, Tab, Table, Alert } from "react-bootstrap";
import { Input, DatePickerField, Select } from "../../../../../_metronic/_partials/controls";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";

function EditPayScale({
    payscaleDetails,
    show,
    reload
}) {
    const alert = useAlert()
    const authtoken = useSelector(state => state.user.authtoken)
    const [loading, setloading] = useState(false)
    const [payscale, setpayscale] = useState(payscaleDetails.payscale);
    const [basic, setbasic] = useState(payscaleDetails.basic);
    const [increment, setincrement] = useState(payscaleDetails.increment);
    const [maximum, setmaximum] = useState(payscaleDetails.maximum);
    const [gp_fund, setgp_fund] = useState(payscaleDetails.gp_fund);
    const [welfare_fund, setwalfare_fund] = useState(payscaleDetails.welfare_fund);
    const [showerr, setshowerr] = useState(false)
    const [showerror, setshowerror] = useState("Please Fill all Fields")


    const UpdateHandler = (values) => {
        setloading(true)
        const data = {
            payscale: values.payscale,
            basic: values.basic,
            increment: values.increment,
            maximum: values.maximum,
            gp_fund: values.gp_fund,
            welfare_fund: values.welfare_fund,
            // payscale,
            // basic,
            // increment,
            // maximum,
            // gp_fund,
            // welfare_fund,
        };
        fetch(`${baseUrl}/payscales/${payscaleDetails.id}?_method=PUT`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${authtoken}`
            },
            body: JSON.stringify(data)
        }).then((res) => {
            res.json()
                .then((data) => {
                    console.log(data)
                    if (data.metadata) {
                        if (data.metadata.success) {
                            show(false)
                            setloading(false)
                            alert.success('Pay Scale Updated')
                            reload()
                        } else {
                            show(false)
                            setloading(false)
                            alert.show("Error Occured")
                        }
                    }
                })
        })
            .catch((err) => {
                show(false)
                setloading(false)
                alert.error(err)
              
                console.log(err)
            })
    }


    // starts here
    const initialValues = {
        "issubmitted": false,
        "payscale": payscale,
        "basic": basic,
        "increment": increment,
        "maximum": maximum,
        "gp_fund": gp_fund,
        "welfare_fund": welfare_fund,

    };
    const EditCampusSchema = Yup.object().shape({

        payscale: Yup.string()
            .required('Payscale is required'),

        basic: Yup.string()
            .required('Basic is required'),

        increment: Yup.string()
            .required('Increment required field'),

        maximum: Yup.number()
            .required('Maximum required field'),

        gp_fund: Yup.string()
            .required('GP Fund required field'),

        welfare_fund: Yup.string()
            .required('Welfare Fund required field'),

    });
    const createEroors = (errors, touched, isSubmitting, values) => {

        if (errors.payscale && touched.payscale) {
            setshowerror(errors.payscale)
            setshowerr(true)
            return;
        }
        if (errors.basic && touched.basic) {
            setshowerror(errors.basic)
            setshowerr(true)
            return;
        }
        if (errors.increment && touched.increment) {
            setshowerror(errors.increment)
            setshowerr(true)
            return;
        }
        if (errors.maximum && touched.maximum) {
            setshowerror(errors.maximum)
            setshowerr(true)
            return;
        }

        if (errors.gp_fund && touched.gp_fund) {
            setshowerror(errors.gp_fund)
            setshowerr(true)
            return;
        }
        if (errors.welfare_fund && touched.welfare_fund) {
            setshowerror(errors.welfare_fund)
            setshowerr(true)
            return;
        }
        setshowerr(false)

        if (Object.keys(errors).length === 0) {
            if (values.payscale != "") {
                if (isSubmitting) {
                    if (!values.issubmitted) {
                        values.issubmitted = true
                        UpdateHandler(values)
                    }
                }
            }
        }

    }

    return (
        <div>
            {
                loading ? <b style={{ color: 'green' }}>Updating Please Wait <span className="spinner-border"></span></b>
                    :

                    <div className="row d-flex justify-content-center ">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    validationSchema={EditCampusSchema}
                                    // validate={validatefield }
                                    onSubmit={(values) => {

                                    }}

                                >
                                    {(props) => {
                                        const { handleSubmit, errors, touched, values, isSubmitting, isValid, handleChange, handleBlur } = props


                                        // setErrorsLis(errors)
                                        createEroors(errors, touched, isSubmitting, values);
                                        return (

                                            <>
                                                <Form onSubmit={handleSubmit} className="form form-label-right">
                                                    {/* form start from here */}
                                                    <div className="text-center">
                                                        <b>PAY SCALES</b>
                                                    </div>
                                                    <div className="row justify-content-between text-left">
                                                        <div className="form-group col-sm-4 flex-column d-flex">
                                                            <label className="form-control-label">
                                                                <b>Pay Scale *</b>
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                name="payscale"
                                                                component={Input}
                                                                placeholder="Payscale"
                                                            />
                                                            {/* <input
                                                                value={payscale}
                                                                onChange={(e) => setpayscale(e.target.value)}
                                                                type="number"
                                                                className="form-control placeholder-bg mb-2"
                                                                required
                                                            /> */}
                                                        </div>
                                                        <div className="form-group col-sm-4 flex-column d-flex">
                                                            <label className="form-control-label">
                                                                <b>Basic Pay</b>
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                name="basic"
                                                                component={Input}
                                                                placeholder="Basic Pay"
                                                            />
                                                            {/* <input
                                                                value={basic}
                                                                onChange={(e) => setbasic(e.target.value)}
                                                                type="number"
                                                                className="form-control placeholder-bg mb-2"
                                                            /> */}
                                                        </div>
                                                        <div className="form-group col-sm-4 flex-column d-flex">
                                                            <label className="form-control-label">
                                                                <b>Increment</b>
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                name="increment"
                                                                component={Input}
                                                                placeholder="Increment"
                                                            />
                                                            {/* <input
                                                                value={increment}
                                                                onChange={(e) => setincrement(e.target.value)}
                                                                type="number"
                                                                className="form-control placeholder-bg mb-2"
                                                            /> */}
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between text-left">
                                                        <div className="form-group col-sm-4 flex-column d-flex">
                                                            <label className="form-control-label">
                                                                <b>Maximum</b>
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                name="maximum"
                                                                component={Input}
                                                                placeholder="Maximum"
                                                            />
                                                            {/* <input
                                                                value={maximum}
                                                                onChange={(e) => setmaximum(e.target.value)}
                                                                type="number"
                                                                className="form-control placeholder-bg mb-2"
                                                                required
                                                            /> */}
                                                        </div>
                                                        <div className="form-group col-sm-4 flex-column d-flex">
                                                            <label className="form-control-label">
                                                                <b>GP Fund</b>
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                name="gp_fund"
                                                                component={Input}
                                                                placeholder="GP Fund"
                                                            />
                                                            {/* <input
                                                                value={gp_fund}
                                                                onChange={(e) => setgp_fund(e.target.value)}
                                                                type="number"
                                                                className="form-control placeholder-bg mb-2"
                                                            /> */}
                                                        </div>
                                                        <div className="form-group col-sm-4 flex-column d-flex">
                                                            <label className="form-control-label">
                                                                <b>Welfare Fund</b>
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                name="welfare_fund"
                                                                component={Input}
                                                                placeholder="Welfare Fund"
                                                            />
                                                            {/* <input
                                                                value={welfare_fund}
                                                                onChange={(e) => setwalfare_fund(e.target.value)}
                                                                type="number"
                                                                className="form-control placeholder-bg mb-2"
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </Form>
                                                {
                                                    showerr ? <div id="err">
                                                        <Alert variant="danger" onClose={() => setshowerr(false)} dismissible>
                                                            <Alert.Heading>Oops You Got an Error!</Alert.Heading>
                                                            <p>
                                                                {showerror}
                                                            </p>
                                                        </Alert>
                                                    </div>
                                                        : null

                                                }
                                                <div class="btns flex_last">
                                                    <button
                                                        type="submit" value="Submit"
                                                        onClick={() => handleSubmit()}
                                                        className="btn btn-success button_blue"
                                                    //onClick={UpdateHandler}
                                                    // type="button" class="btn btn-success button_blue"
                                                    >
                                                        SAVE
                                                    </button>
                                                    <button className=" btn_padding_left btn btn-danger btn_width ml-3" onClick={() => show(false)}>Cancel</button>
                                                </div>
                                            </>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default EditPayScale
