import React, { useState, useRef, useEffect } from "react";
import { Divider } from "antd";
import { Hidden, LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";

const HopeCertificate = () => {
  const currentDates = new Date().toISOString().slice(0, 10);
  const [code, setCode] = useState("");
  const [date, setDate] = useState(currentDates);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [closing, setClosing] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  var [logomodel, setlogomodel] = useState("");
  var [logomodel1, setlogomodel1] = useState("");

  let authtoken = localStorage.getItem("userToken");

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  const [icon, setIcon] = useState([]);
  const [unitName, setUnitName] = useState("");

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(data.payload.settings.unit_name);
        setIcon(data.payload.settings.logo_file);

        // console.log(icon);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleShow = () => {
    //document.getElementById("bits").value="";
    console.log(code);
    if (code == "" && date == "") {
      alert.error("please enter the Student ID and select the date");
      return;
    }
    if (date == "") {
      alert.error("please select the date");
      return;
    }
    if (code == "") {
      alert.error("please enter the Student");
      return;
    }
    setLoading(true);
    const data = {
      search_keyword: code.toLocaleUpperCase(),
    };
    fetch(`${baseUrl}/search_by_name_or_adm_id`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);

        if (data.metadata) {
          if (data.metadata.success) {
            if (data.payload.length > 0) {
              setreportData(data.payload);
            } else {
              alert.error("Record Not Found");
              setLoading(false);
              return;
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setTitle(document.getElementById("tits").value);
    setClosing(document.getElementById("chits").value);
    setDetails(document.getElementById("bits").value);
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const divStyle = {
    display: `${logomodel1}`,
  };
  function handleView() {
    console.log(logomodel);
    if (logomodel == false) {
      setlogomodel1("none");
    }
    if (logomodel == true) {
      setlogomodel1("");
    }
    // isFineEnable ? 1 : 0
    //  setlogomodel("");
    console.log(logomodel1);
  }
  function handleNone(event) {
    setlogomodel("none");
    console.log(logomodel);
  }
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {loading ? <LinearProgress /> : null}

        <div className="row mt-3">
          <div className="col-md-4">
            <label>
              <b>Select Date</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <input
              onChange={(e) => setDate(e.target.value)}
              type="date"
              placeholder="Enter Student ID"
              className="form-control"
              value={currentDates}
            />
          </div>
          <div className="col-md-4">
            <label>
              <b>Enter Student ID</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <input
              onChange={(e) => setCode(e.target.value)}
              type="text"
              placeholder="Enter Student ID"
              className="form-control"
            />
          </div>
          <div className="col-md-4 mt-9">
            <button onClick={handleShow} className="btn btn-primary">
              Search
            </button>

            {reportData.map((campus) => (
              <ReactToPrint
                documentTitle={`Hope Certificate`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary w-105px mx-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ))}
          </div>
        </div>
        <Divider />

        {reportData.map((campus) => (
          <div className="mainContainer">
            <label>
              <b style={{ fontSize: "15px" }}>Title :</b>
            </label>
            <br />
            <textarea
              rows="1"
              cols="100"
              id="tits"
              defaultValue="To Whom It May Concern"
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />

            <div style={{ marginTop: "10px" }}>
              <label>
                <b style={{ fontSize: "15px" }}>Description :</b>
              </label>
              <br />
              <textarea
                id="bits"
                rows="8"
                cols="100"
                defaultValue={
                  "It is certified that " +
                  campus.name +
                  " S/O " +
                  campus.father_name +
                  "  is a Bonafide student of this Institution for the Session  He appeared in HSSC-I Annual Examination 20-- under BISE Sargodha and obtained 000 marks out of 000 marks. He is appearing in HSSC-II Annual Examination 20-- to be held on May, 20-- under BISE Sargodha. It is hoped that he will secure above--% marks in the aforesaid Examination. He bears a good moral character."
                }
                onChange={(e) => setDetails(e.target.value)}
              />
              <br />
              <label style={{ marginTop: "10px" }}>
                <b style={{ fontSize: "15px" }}>Wishes :</b>
              </label>
              <br />
              <textarea
                id="chits"
                rows="1"
                cols="100"
                defaultValue="I wish him all success in life."
                onChange={(e) => setClosing(e.target.value)}
              />
            </div>
          </div>
        ))}

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          {reportData.map((campus) => (
            <div
              className="mainContainer"
              style={{
                margin: "30px",
                border: "2px solid black",
                height: "1500px",
              }}
            >
              <div className="logocontainer" style={{ height: "250px" }}>
                <div style={divStyle}>
                  <center>
                    <img
                      // className='col-md-2'
                      style={{
                        height: 200,
                        width: 170,
                        display: "inline-block",
                        textAlign: "center",
                        marginTop: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      src={icon}
                    />
                  </center>
                  <br />{" "}
                  <p
                    style={{
                      display: "block",
                      textAlign: "center",
                      fontSize: "30px",
                      fontFamily: "Times New Roman",
                    }}
                  >
                    <b>{unitName}</b>
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <div style={{ border: "2px solid black", fontSize: "30px" }}>
                  <p>
                    <b>Hope Certificate</b>
                  </p>{" "}
                </div>
              </div>

              <div>
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      marginTop: "100px",
                    }}
                  >
                    <u>{title}</u>
                  </h1>
                  <br />
                  <p
                    style={{
                      fontSize: "25px",
                      margin: "20px",
                    }}
                  >
                    {details}
                  </p>
                  <br />
                  <h4
                    style={{
                      textAlign: "center",
                      fontSize: "25px",
                      marginTop: "50px",
                    }}
                  >
                    {closing}
                  </h4>
                  <br />
                  <h2
                    style={{
                      textAlign: "right",
                      fontSize: "35px",
                      margin: "30px",
                      marginLeft: "30px",
                      marginTop: "170px",
                    }}
                  >
                    PRINCIPAL
                  </h2>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: "25px",
                      margin: "30px",
                      marginTop: "-70px",
                    }}
                  >
                    <b>Dated :</b> <u>{date}</u>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Paper>
    </>
  );
};

export default HopeCertificate;
