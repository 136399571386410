import React, { useEffect, useRef, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SingleStudentPrint from "./SingleStudentPrint";
import { useAlert } from "react-alert";
import { isEmpty, upperCase } from "lodash";
import ReactToPrint from "react-to-print";
import MultiStudentFeeChallan from "../MultiStudentFeeChallan/MultiStudentFeeChallan";

const SingleStudentMonth = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);

  const [selectedcampus, setselectedcampus] = useState("");
  const [selectedclass, setselectedclass] = useState("");
  const [selectedSection, setselectedSection] = useState("");
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [classes, setclasses] = useState([]);
  const [allsections, setallsections] = useState([]);
  const [allChalans, setAllChalans] = useState([]);
  const [printmodal, setPrintmodal] = useState(false);
  const [searchStudentLoading, setSearchStudentLoading] = useState(false);
  const [fee_month, setfee_month] = useState("");
  const [selectedChalan, setSelectedChalan] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [educationType, setEducationType] = useState(1);
  const alert = useAlert();

  const [allsessions, setallsessions] = useState([]);
  const [currentsession, setcurrentsession] = useState("");

  const [allstudentList, setallstudentsList] = useState([]);

  const PRINTCOUNT = [1, 2, 3];

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [blankPage, setBlankPage] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const pageStyle = `
  @page {
    size:letter landscape !important;
  }
  `;

  const [selectedChallans, setSelectedChallans] = useState([]);
  const [mainCheckBoxChecked, setmaincheckboxChecked] = useState(false);
  const searchClicked = useRef(false);
  const banksloaded = useRef(false);

  const componentRef = useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  useEffect(() => {
    if (!banksloaded.current) {
      banksloaded.current = true;
      getSettingData();
      // getAllCampus();
      getAllSessions();
    }

    if (searchClicked.current) {
      searchClicked.current = false;
      setAllCheckboxes(true);
    } else {
      checkAllCheckBoxes();
    }
  }, [[], selectedChallans]);

  const getMonthName = (date) => {
    if (date == "") {
      return "_";
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changesession = (id) => {
    setcurrentsession(id);
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
    await fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setclasses(data.payload);
          // getChallans()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = async (id) => {
    setselectedclass(id);
    await fetch(
      `${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setallsections(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setallsections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getChallans = (id) => {

  //     console.log('chala?')
  //     console.log(id)
  //     setselectedSection(id)

  //     const data = {
  //         campus_id: selectedcampus,
  //         student_class_id: selectedclass,
  //         global_section_id: id
  //     }

  //     console.log(data)

  //     fetch(`${baseUrl}/unpaid_challan_combine`, {
  //         method: 'POST',
  //         headers: {
  //             Accept: 'application/json',
  //             'Content-Type': 'application/json',
  //             Authorization: `Bearer ${authtoken}`,
  //         },
  //         body: JSON.stringify(data)
  //     }).then(res => res.json())
  //         .then(data => {

  //             console.log(data)
  //             if (data.metadata.success) {
  //                 // setallstudentsList(data.payload.students)
  //             }
  //         }).catch(err => {
  //             console.log(err)
  //         })
  // }

  const searchChallans = () => {
    if (
      currentsession == "" ||
      selectedcampus == "" ||
      selectedclass == "" ||
      // selectedSection == "" ||
      fee_month == ""
    ) {
      alert.error("please select all fields");
    } else {
      setloading(true);

      const data = {
        year_id: currentsession,
        campus_id: selectedcampus,
        student_class_id: selectedclass,
        global_section_id: selectedSection,
        education_type: educationType,
        fee_month: `${fee_month}-01`,
        fee_status: 0,
      };
      console.log(data);
      fetch(`${baseUrl}/fee_challan_month_wise`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            if (data.payload.length == 0) {
              alert.error("Match not found");
              setSelectedChallans([]);
            }

            searchClicked.current = true;
            setallstudentsList(data.payload.students);
            setloading(false);
            setShowResult(true);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
          console.log("Monthly challan", data);
        })
        .catch((err) => {
          setloading(false);

          console.log(err);
        });
    }
  };

  const searchbyAdmId = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
      return;
    }
    if (fee_month == "") {
      alert.error("Please Select Month");
      return;
    }
    setSearchStudentLoading(true);
    const data = {
      search_keyword: searchtext.toLocaleUpperCase(),
    };

    fetch(`${baseUrl}/search_by_name_or_adm_id`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          if (data.payload.length === 0) {
            setSearchStudentLoading(false);
            alert.error("No Fee Record found");
            setSelectedChallans([]);
            return;
          }
          console.log(data);
          searchClicked.current = true;
          searchChallanByID(data.payload[0].id);
        }
      })
      .catch((err) => {
        setSearchStudentLoading(false);
        console.log("err is re", err);
      });
  };

  const searchChallanByID = (id) => {
    const data = {
      student_id: id,
      fee_month: `${fee_month}-01`,
      fee_status: 0,
    };
    console.log(data);
    fetch(`${baseUrl}/fee-challan-month-wise-single-student`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          searchClicked.current = true;
          setallstudentsList(data.payload.students);
          setSearchStudentLoading(false);
          setShowResult(true);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg[0]);
          });
        }
        console.log("Monthly challan", data);
      })
      .catch((err) => {
        setSearchStudentLoading(false);
        console.log(err);
      });
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    var element = document.getElementById("hettingheight");

    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleMainCheckboxClick = () => {
    if (mainCheckBoxChecked) setAllCheckboxes(false);
    else setAllCheckboxes(true);
  };

  const setAllCheckboxes = (value) => {
    setmaincheckboxChecked(value);
    if (value) {
      setSelectedChallans([]);
    }

    allstudentList.map((item, i) => {
      item.checked = value;
      if (!value) {
        RemoveSelectedChallan(item);
      } else {
        AddSelectedChallan(item);
      }
    });
  };

  const checkAllCheckBoxes = () => {
    if (allstudentList.length == selectedChallans.length) {
      setmaincheckboxChecked(true);
    } else if (mainCheckBoxChecked) {
      setmaincheckboxChecked(false);
    }
  };

  const handleChallansCheckBoxClick = (e, index) => {
    allstudentList.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;

        if (item.checked) {
          AddSelectedChallan(item);
        } else {
          RemoveSelectedChallan(item);
        }
      }
    });
  };

  const AddSelectedChallan = (item) => {
    setSelectedChallans((prevSelectedChallans) => [
      ...prevSelectedChallans,
      item,
    ]);
  };

  const RemoveSelectedChallan = (item) => {
    setSelectedChallans((prevSelectedChallans) =>
      prevSelectedChallans.filter((std) => std.id !== item.id)
    );
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row col-md-12">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => changesession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option
                  key={index}
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              onChange={(e) => onCampusChange(e.target.value)}
              className="form-select"
              value={selectedcampus}
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Campus
              </option>
              {campuses.map((campus) => (
                <option value={campus.id}>{campus.name}</option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedclass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Class
              </option>
              {classes.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-3 mt-3">
            <label>
              <b>Select Section</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedSection}
              onChange={(e) => setselectedSection(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Section
              </option>
              {allsections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3 mt-3">
            <label>
              <b>Select Month</b>
            </label>
            <input
              style={{ height: 40 }}
              onChange={(e) => setfee_month(e.target.value)}
              type={"month"}
              className="form-select"
            />
          </div>
          <div className="d-flex col-md-6 mt-12">
            {loading ? (
              <button type="button" disabled={true} className="btn btn-primary">
                Searching...
              </button>
            ) : (
              <button
                type="button"
                onClick={searchChallans}
                className="btn btn-primary mr-4"
              >
                Search
              </button>
            )}
            {/* <button
              type="button"
              disabled={allChalans.length > 0 ? false : true}
              onClick={() => history.push(`/printmonthlychallan`)}
              className="btn btn-primary mx-2"
            >
              Print All
            </button> */}

            {showResult && allstudentList.length > 0 ? (
              <ReactToPrint
                pageStyle={pageStyle}
                documentTitle="Challan Copy"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary mr-4"
                  >
                    {printloading ? "Printing..." : "Print Fee Challan"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}

            <div className="mt-4">
              <label className="checkbox checkbox-single">
                <input
                  type="checkbox"
                  onChange={(e) => setBlankPage(e.target.checked)}
                />
                <span />
                &nbsp;&nbsp;&nbsp;Use Blank Page For Print
              </label>
            </div>
          </div>
          <form onSubmit={searchbyAdmId}>
            <div className="row mt-5">
              <div className="col-md-4">
                <input
                  onChange={(e) => setsearchtext(e.target.value)}
                  className="form-control"
                  placeholder="Enter Admission ID"
                />
              </div>
              <div className="col-md-4">
                {searchStudentLoading ? (
                  <button
                    type="button"
                    disabled={true}
                    className="btn btn-primary"
                  >
                    Searching...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Search Student
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "5px", textAlign: "center" }}>
                <input
                  onClick={(e) => {
                    handleMainCheckboxClick();
                  }}
                  checked={mainCheckBoxChecked}
                  style={{}}
                  placeholder="Checkbox"
                  type="checkbox"
                />
              </th>
              <th style={{ textAlign: "center" }}>Challan No</th>
              <th style={{ textAlign: "center" }}>Admission Id</th>
              <th style={{ textAlign: "center" }}>Student Name</th>
              <th style={{ textAlign: "center" }}>Father Name</th>
              <th style={{ textAlign: "center" }}>Class Section</th>
              <th style={{ textAlign: "center" }}>Month</th>
              <th style={{ textAlign: "center" }}>Payable</th>
              {/* <th style={{ textAlign: "center" }}>Print</th> */}
            </tr>
          </thead>
          <tbody>
            {allstudentList?.map((student) => (
              <tr key={student.id}>
                <td style={{ width: "5px" }}>
                  <input
                    type="checkbox"
                    value={student.id}
                    key={student.id}
                    onClick={(e) => {
                      handleChallansCheckBoxClick(e, student.id);
                    }}
                    checked={student.checked ?? false}
                    style={{ border: "10px" }}
                  ></input>
                </td>
                <td style={{ textAlign: "center" }}>
                  {student?.fee_challans_detials[0]?.fee_challan_id}
                </td>
                <td style={{ textAlign: "center" }}>{student.admission_id}</td>
                <td
                  title={student?.name}
                  style={{ textAlign: "center", cursor: "pointer" }}
                >
                  {student?.name}
                </td>
                <td
                  title={student?.father_name}
                  style={{ textAlign: "center", cursor: "pointer" }}
                >
                  {student?.father_name}
                </td>

                <td style={{ textAlign: "center" }}>
                  {student?.student_class?.name}&nbsp;
                  {student?.global_section?.name}&nbsp; ({student?.campus?.name}
                  )
                </td>
                <td style={{ textAlign: "center" }}>
                  {getMonthName(student?.fee_challans_detials[0]?.fee_month)}
                </td>

                <td style={{ textAlign: "center" }}>
                  {student?.fee_challans_detials[0]?.fee_challan?.payable}
                </td>

                {/* <td style={{ textAlign: "center" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedChalan(feechallan);
                      setPrintmodal(true);
                    }}
                    className="svg-icon svg-icon-md svg-icon-primary"
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Devices/Printer.svg"
                      )}
                    />
                  </span>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </Paper>

      {printmodal ? (
        <SingleStudentPrint
          educationType={educationType}
          printmodal={printmodal}
          selectedChalan={selectedChalan}
          onHide={() => setPrintmodal(false)}
        />
      ) : null}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        <MultiStudentFeeChallan
          allstudentList={
            allstudentList.length == selectedChallans.length
              ? allstudentList
              : selectedChallans
          }
          blankPage={blankPage}
        />
      </div>
    </>
  );
};

export default SingleStudentMonth;
