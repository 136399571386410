import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { CustomersPage } from "./customers/CustomersPage";

import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";

export default function Staff() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect
            exact={true}
            from="/staff"
            to="/staff/addstaff"
          />
        }
        <ContentRoute path="/staff/addstaff" component={CustomersPage} />
      
      </Switch>
    </Suspense>
  );
}
