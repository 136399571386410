import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../services/config";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import {
  Input,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import { useFormik, Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";

const EditCampus = ({ campusDetail, show, reload, wellfare }) => {
  const authtoken = localStorage.getItem("userToken");

  const alert = useAlert();
  const [first_name, setfirstname] = useState(campusDetail.first_name);
  const [last_name, setlastname] = useState(campusDetail.last_name);
  const [name, setname] = useState(campusDetail.name);
  const [code, setcode] = useState(campusDetail.code);
  const [area, setarea] = useState(campusDetail.area);
  const [city, setcity] = useState(campusDetail.city);
  const [province, setprovince] = useState(campusDetail.province);
  const [contact, setcontact] = useState(campusDetail.contact);
  const [email, setemail] = useState(campusDetail.email);
  const [password, setpassword] = useState(null);
  const [loading, setloading] = useState(false);
  const [allBanks, setAllBanks] = useState([]);
  const [bankIdsArray, setBankIdsArray] = useState([]);
  const [selectIds, setSelectedIZz] = useState([]);
  const [ispasswordchange, setispasswordchange] = useState(true);
  const [loader, setLoader] = useState(false);
  const [typeError, setTypeError] = useState("");
  const [type, setType] = useState("");
  const [wellFareError, setWellfareError] = useState(false);
  const [sub_AccountData, setSub_AccountData] = useState({
    label: "Select Account",
    value: "",
  });

  useEffect(() => {
    getAllBanks();
    setType(campusDetail?.type);
    const result = wellfare.find(
      (item) => item.value == campusDetail.welfare_account_id
    );
    setSub_AccountData(result);
  }, [campusDetail]);

  const getAllBanks = () => {
    setLoader(true);
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            data.payload.forEach((item) => {
              item["label"] = item.title;
              item["options"] = item.bank_accounts;
              item.bank_accounts.forEach((subitem) => {
                subitem.label = subitem.bank_name;
                subitem.value = subitem.id;
              });
            });
            setAllBanks(data.payload);
            data.payload.map((item) => {
              item.bank_accounts.map((dd) => {
                campusDetail.print_account_no.filter((sub) => {
                  if (sub.bank_account_id == dd.id) {
                    console.log(dd, "thisa is dd");
                    setSelectedIZz((prev) => [...prev, dd]);
                  }
                });
              });
            });
            setLoader(false);
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const initialValues = {
    name: name,
    first_name: "",
    last_name: "",
    cod: code,
    area: area,
    province: province,
    phone: contact,
    city: city,
    email: campusDetail.email,
    password: password,
  };

  if (
    campusDetail.contact.charAt(0) == "+" &&
    campusDetail.contact.charAt(1) == "9" &&
    campusDetail.contact.charAt(2) == "2"
  ) {
    initialValues.phone = campusDetail.contact.substring(3);
  }

  const EditCampusSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),

    cod: Yup.string().required(" cod required field"),

    area: Yup.string().required(" area required field"),

    province: Yup.string().required(" province required field"),

    phone: Yup.string().required("required field"),

    city: Yup.string().required(" city required field"),

    email: Yup.string()
      .required("Please Enter your Email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email Format Must Be correct"
      ),
  });

  const EditCampusHandler = async (values) => {
    setloading(true);
    setispasswordchange(true);
    let bank_ids = [];
    bankIdsArray.map((item) => {
      bank_ids.push(item.value);
    });
    const data = {
      first_name: values.name,
      last_name: "Campus",
      name: values.name,
      code: values.cod,
      area: values.area,
      city: values.city,
      province: values.province,
      contact: "+92" + values.phone,
      email: values.email,
      password: values.password,
      bank_account_ids: bank_ids,
      welfare_account_id: sub_AccountData.value,
      type: type,
    };

    fetch(`${baseUrl}/campus/${campusDetail.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            reload();
            show(false);
            alert.success("Campus Updated Successfully");
            setloading(false);
          }
          if (!data.metadata.success) {
            show(false);
            alert.error("Something went wrong");
            setloading(false);
          }
        })
      )
      .catch((err) => {
        show(false);
        setloading(false);
        console.log(err);
      });
  };

  const accountFunc = (e) => {
    setWellfareError(false);
    setSub_AccountData(e);
  };

  return (
    <div>
      {loading ? (
        <b>
          Campus Updating Please Wait {` `}{" "}
          <span className="spinner-border"></span>
        </b>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={EditCampusSchema}
          onSubmit={(values) => {
            if (sub_AccountData == undefined) {
              setWellfareError(true);
              return;
            }
            if (type == "") {
              setTypeError(true);
              return;
            }
            EditCampusHandler(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            isSubmitting,
            isValid,
            errors,
            touched,
            values,
          }) => (
            <>
              <Form autoComplete="off">
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner
                      class="text-center"
                      variant="info"
                      animation="border"
                    />
                  </div>
                ) : (
                  <>
                    <div className="row mt-3">
                      <div className="col-md-6 ">
                        <label>
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          value={values.name}
                          name="name"
                          component={Input}
                          placeholder="Name"
                          maxLength={40}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          Code <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          style={{ textTransform: "uppercase" }}
                          value={values.cod}
                          name="cod"
                          component={Input}
                          placeholder="Code"
                          maxLength={3}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6 ">
                        <label>
                          Area <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          name="area"
                          component={Input}
                          placeholder="Area"
                          value={values.area}
                          maxLength={120}
                        />
                      </div>
                      <div className="col-md-6 ">
                        <label>
                          City <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          name="city"
                          component={Input}
                          placeholder="city "
                          value={values.city}
                          maxLength={120}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label>
                          Province <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          name="province"
                          component={Input}
                          placeholder="Province"
                          value={values.province}
                          maxLength={120}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          Contact <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          name="phone"
                          component={Input}
                          placeholder="Contact"
                          maxLength={10}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      {loader ? (
                        ""
                      ) : (
                        <div className="col-md-6">
                          <label>
                            Bank <span style={{ color: "red" }}>*</span>
                          </label>
                          {console.log(selectIds, "this is")}
                          <Select
                            defaultValue={selectIds}
                            options={allBanks}
                            isMulti
                            onChange={(e) => setBankIdsArray(e)}
                          />
                        </div>
                      )}
                      {loader ? (
                        ""
                      ) : (
                        <div className="col-md-6">
                          <label>
                            Welfare Account{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={sub_AccountData}
                            onChange={(e) => accountFunc(e)}
                            className="disselect"
                            placeholder="Select Account"
                            options={wellfare}
                          />
                          {wellFareError && (
                            <b className="text-danger">
                              Welfare Account is required
                            </b>
                          )}
                        </div>
                      )}
                      <div className="col-md-6 mt-5">
                        <label>
                          Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          style={{ height: 40 }}
                          className="form-select"
                          name="type"
                          value={type}
                          aria-label="Default select example"
                          onChange={(e) => {
                            setTypeError(false);
                            setType(e.target.value);
                          }}
                        >
                          <option value="">Select Type...</option>
                          <option value="campus">Campus</option>
                          <option value="hostel">Hostel</option>
                        </select>
                        {typeError && (
                          <b className="text-danger">Type is required</b>
                        )}
                      </div>
                      <div className="col-md-6 mt-5">
                        <label>
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          disabled
                          name="email"
                          component={Input}
                          placeholder="E mail"
                          type="text"
                          value={values.email}
                          maxLength={40}
                        />
                      </div>
                      <div className="col-md-6 mt-5">
                        <label>Password </label>

                        {ispasswordchange ? (
                          <div>
                            <button
                              style={{
                                width: "165px",
                                color: "#ffffff",
                                backgroundColor: "#121291 ",
                                borderColor: "#121291 ",
                              }}
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault();
                                setispasswordchange(false);
                              }}
                            >
                              Change Password
                            </button>{" "}
                          </div>
                        ) : (
                          <Field
                            name="password"
                            component={Input}
                            placeholder="Password *"
                            value={values.password}
                            type="text"
                            maxLength={50}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Form>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="btn btn-success  button_blue"
                  onClick={() => handleSubmit()}
                  type="submit"
                  value="Submit"
                >
                  {loading ? (
                    <span className="spinner-border"></span>
                  ) : (
                    <>Update</>
                  )}
                </button>

                <button
                  onClick={() => show(false)}
                  className="btn btn-danger btn_width ml-3"
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditCampus;
