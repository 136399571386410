import React, { useEffect, useState } from "react";
import { InputGroup, Button, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MyAuthToken from "../../../../services/authToken";
import HelpIcon from "@material-ui/icons/Help";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import MySelect from "./../../CustomChallan/MySelect";
import { components } from "react-select";

import makeAnimated from "react-select/animated";

function AddClassCampus() {
  //getting jwt Token
  const alert = useAlert();
  const jwt_token = MyAuthToken();
  const history = useHistory();

  //coming from redux
  const [campusId, setCampusId] = useState("");
  const [allclasses, setallclasses] = useState([]);
  const [selectedclasses, setselectedclasses] = useState([]);
  const [selectedclassesvalue, setselectedclassesvalue] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  let [optionSelected, setoptionSelected] = useState([]);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwt_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  // console.log(user)
  useEffect(() => {
    // getAllCampus();

    fetch(`${baseUrl}/studentclass`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    })
      .then((res) => {
        res.json().then((classes) => {
          setallclasses(classes.payload);
        });
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  const gotoBack = () => {
    history.push("/campasclasses");
  };

  const addClasstoCampus = () => {
    const fdata = {
      campus_id: campusId,
      student_class_id: selectedclassesvalue,
    };

    console.log(fdata);

    fetch(`${baseUrl}/campusclass`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          if (data.metadata.success) {
            alert.show("Classes Added to campus");
            history.push("/campasclasses");
          }
        });
      })
      .catch((err) => {
        alert.show("Somthing went Wrong", err);
        console.log(err);
      });
  };
  const Options = [];
  allclasses.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });
  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleChange = (selected) => {
    console.log(selected);
    console.log(optionSelected);
    console.log(optionSelected.length);

    if (optionSelected.length == 30 && selected.length == 30) {
      setoptionSelected([]);
      return;
    }

    let valuearray = [];
    let classids = [];
    selected.map((item, index) => {
      valuearray[index] = { id: item.value, name: item.label };
      classids[index] = item.value;
    });
    setselectedclasses([...valuearray]);
    setoptionSelected([...selected]);

    setselectedclassesvalue([...classids]);
  };

  const onCampusChange = (id) => {
    setCampusId(id);
  };
  return (
    <div>
      <div className="card">
        <button onClick={gotoBack} className="btn btn-danger btn_width">
          Back
        </button>

        <br />
        <br />
        <div className="col-md-6" style={{ paddingLeft: 0 }}>
          <label>Select Campus</label>
          <div className="d-flex flex-column">
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              aria-label="Default select example"
              onChange={(e) => {
                onCampusChange(e.target.value);
              }}
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campusData &&
                campusData.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>
        </div>

        <div className="mt-4">
          <label>Select Classes</label>
          <HelpIcon style={{ paddingLeft: 20 }} />
        </div>
        <div style={{ marginLeft: "-10px" }} className="col-sm-6">
          <MySelect
            options={Options}
            isMulti
            name="selectedOptions"
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={handleChange}
            allowSelectAll={true}
            value={optionSelected}
          />
        </div>

        {/* <Form.Group as={Col} controlId="my_multiselect_field">
                    <Form.Label>Select Classes</Form.Label>
                    <Form.Control as="select" multiple value={selectedclasses}
                        onChange={e => {
                            // console.log("SElected DAta", e.target.selectedOptions);
                            setselectedclasses([].slice.call(e.target.selectedOptions).map(item => item.value))
                        }}>
                        {
                            allclasses.map((cls, i) => {
                                return (
                                    <option value={cls.id}>{cls.name}</option>
                                )
                            })

                        }

                    </Form.Control>
                </Form.Group> */}

        <h6 style={{ marginTop: 30, color: "blueviolet" }}>Selected Classes</h6>

        <ol>
          {selectedclasses.map((person) => {
            return <li key={person.id}>{person.name}</li>;
          })}
        </ol>

        <button
          onClick={addClasstoCampus}
          style={{ marginTop: 10 }}
          className="btn btn-success button_blue col-sm-3"
        >
          Add Classes{" "}
        </button>
      </div>
    </div>
  );
}

export default AddClassCampus;
