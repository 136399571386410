import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Table, Modal, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Col, Alert } from "react-bootstrap";
import EditDefinedFee from "./updateDefinedFee";


const DefineMonthlyFee = ({ studentdetail, show }) => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const [feetypes, setfeetypes] = useState([]);
  const [selectedfeetype, setselectedfeetype] = useState("");
  const [amount, setamount] = useState("");
  const [isallfields, setisallfields] = useState(false);
  const [errorcreate, seterrorcreate] = useState("Plese Fill All Fields");
  const [concessionamount, setconcessionamount] = useState(0);
  const [onChangeAmount, setonChangeAmount] = useState("");
  const [allDefinedFees, setallDefinedFees] = useState([]);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteablefee, setdeleteablefee] = useState({});
  const [editmodal, seteditmodal] = useState(false);
  const [editablefee, seteditablefee] = useState({});
  const [editedamount, seteditedamount] = useState("");
  const [editedconcession, seteditedconcession] = useState(0);
  const [deleteloading, setdeleteloading] = useState(false);
  const [remarks, setremarks] = useState("");
  const [allowedconcession, setallowedconcession] = useState(0);
  const [allconcessions, setallconcessions] = useState([]);
  const [applyconcessionbtn, setapplyconcessionbtn] = useState(true);
  const [isallowedconcesstion, setisallowedconcesstion] = useState(true);
  const [allsessions, setallsessions] = useState([]);

  const [currentsession, setcurrentsession] = useState("");



  //new states for changes

  const [selectedConcType, setselectedConcType] = useState('Amount')

  useEffect(() => {
    //  getfeeTypes();
    console.log(studentdetail.session_id);
    console.log(studentdetail);
    getfeeTypes(studentdetail.session_id);
    getDefinedFees();
    getConcessionTypes();
    getAllSessions();
    // setallowedconcession(studentdetail.concession_id);

  }, []);




  const applyonMount = (arr) => {

    const result = arr.filter(item => item.id == studentdetail.concession_id)
    console.log("result on mount", result);
    if (selectedConcType == "Percentage") {
      setallowedconcession(result[0].percentage)
    }
    if (selectedConcType == "Amount") {
      setallowedconcession(result[0].amount)
    }
  }

  // const changesession = ((id) => {
  //     setcurrentsession(id)
  //     getfeeTypes(id);
  // })

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDefinedFees = () => {
    fetch(`${baseUrl}/student_all_liable_fees/${studentdetail.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallDefinedFees([...data.payload]);
            console.log(data.payload, "all feeswes");
          } else {
            alert.show(
              "Something Went Wrong While Fetching Student Defined Fees"
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        alert.show("Something Went Wrong While Fetching Student Defined Fees");
      });
  };
  const checkerror = () => {
    if (!selectedfeetype) {
      setisallfields(true);
      seterrorcreate("Please Add Amount");
      return false;
    }
    if (onChangeAmount == "") {
      setisallfields(true);
      seterrorcreate("Please Add Fee");
      return false;
    }
    setisallfields(false);
    return true;
  };

  const feeDefine = () => {
    setisallfields(false);

    let res = checkerror();
    if (!res) {
      return;
    }

    setloading(true);
    let data = {
      student_id: studentdetail.id,
      fees_type_id: selectedfeetype,
      amount: onChangeAmount,
      concession_amount: parseInt(concessionamount),
      remarks: remarks,
    };

    console.log(data);
    fetch(`${baseUrl}/student_liable_fee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setapplyconcessionbtn(true);
        setisallowedconcesstion(true);
        setallowedconcession(0);
        if (data.metadata) {
          if (data.metadata.success) {
            setloading(false);
            // show(false)
            alert.success("Fee Added success");
            setonChangeAmount("");
            setconcessionamount(0);
            getDefinedFees();
          } else {
            setloading(false);
            // show(false)
            alert.error("Fee Not Added");
          }
        } else {
          alert.error("Fee Not Added");
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        console.log("err", err);
        alert.show("Error Occured");
      });
  };
  const getfeeTypes = (session_id) => {
    fetch(
      `${baseUrl}/classfeetypes/${studentdetail.campus_id}/${studentdetail.student_class_id}/${session_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setfeetypes(data.payload);
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };
  const getConcessionTypes = () => {
    fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallconcessions(data.payload);
            applyonMount(data.payload)
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };
  const getRespctiveFee = (id) => {
    setselectedfeetype(id);
    let data = {
      campus_id: studentdetail.campus_id,
      student_class_id: studentdetail.student_class_id,
      fee_type_id: id,
      year_id: studentdetail.session_id,
    };
    console.log(data);
    fetch(`${baseUrl}/feestructure_amount`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data.payload.amount);
            setonChangeAmount(data.payload.amount);
            setamount(data.payload.amount);
            console.log(onChangeAmount);
          } else {
            console.log(data);
            // setloading(false)
            // show(false)
            // alert.show('Fee Not Added success')
          }
        }
      })
      .catch((err) => {
        alert.show("Something Went Wrong");
        console.log("err", err);
      });
  };
  const onChangeFeeAmount = (value) => {
    setconcessionamount(amount - value);
    setonChangeAmount(value);
    if ((amount - value) < 0) {
      setisallfields(true)
      seterrorcreate("Please Check Concession")
      return
    } else {
      setisallfields(false)
      seterrorcreate('')
    }
    if (parseInt(value) < 0) {
      setisallfields(true)
      seterrorcreate("Please Check Amount")
      return
    } else {
      setisallfields(false)
      seterrorcreate('')
    }
  };
  const deleteFee = () => {
    setdeleteloading(true);

    fetch(`${baseUrl}/student_liable_fee/${deleteablefee.id}}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show(data.payload);
            getDefinedFees();
            setdeleteloading(false);
            setdeleteModal(false);
          } else {
            alert.show(data.payload);
            setdeleteloading(false);
            setdeleteModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteModal(false);
      });
  };
  const applyConcessionBtn = () => {
    // console.log(allowedconcession)
    // console.log(onChangeAmount)
    // console.log("concession applied")
    // concessionamount
    //applyconcessionbtn false
    if (selectedConcType == "Amount") {

      setapplyconcessionbtn(false);

      setonChangeAmount(amount - allowedconcession);
      setconcessionamount(allowedconcession);
      if (allowedconcession < 0) {
        setisallfields(true)
        seterrorcreate('Concession can not be negative.Please Check')
        return
      } else {
        setisallfields(false)
        seterrorcreate('')
      }
      if ((amount - allowedconcession) < 0) {
        setisallfields(true)
        seterrorcreate('Amount can not be negative.Please Check')
        return
      } else {
        setisallfields(false)
        seterrorcreate('')
      }

    }
    if (selectedConcType == "Percentage") {
      setapplyconcessionbtn(false);
      let calu = allowedconcession / 100 * amount
      setonChangeAmount(amount - calu)
      setconcessionamount(allowedconcession);


      if (allowedconcession < 0) {
        setisallfields(true)
        seterrorcreate('Concession can not be negative.Please Check')
        return
      } else {
        setisallfields(false)
        seterrorcreate('')
      }
      if ((amount - allowedconcession) < 0) {
        setisallfields(true)
        seterrorcreate('Amount can not be negative.Please Check')
        return
      } else {
        setisallfields(false)
        seterrorcreate('')
      }
    }

    //  setonChangeAmount(value)
  };
  // const onChangeConcession = (value) = {

  // }




console.log('this is new update from client',allDefinedFees)

  const onRadioButtonClick = (e) => {
    setselectedConcType(e.target.value)
    console.log(e.target.value)
  }

  const handleConcessionChnage=(e)=>{
    document.getElementById('feeselect').selectedIndex = 0
    const result = allconcessions.filter((item) => item.id == e.target.value);
    setonChangeAmount("");

    if(result[0].percentage !=null){
      setallowedconcession(result[0].percentage);
      setselectedConcType('Percentage')
    }
    if(result[0].amount !=null){
      setallowedconcession(result[0].amount);
      setselectedConcType('Amount')
    }
        
    setconcessionamount(0);
    setapplyconcessionbtn(true);
    if (onChangeAmount == "") {
      setisallowedconcesstion(true);
    } else {
      setisallowedconcesstion(false);
    }
  }

  return (
    <div>
      {loading ? (
        <b style={{ color: "green" }}>
          {" "}
          Fee defining Please Wait <span className="spinner-borderd"></span>
        </b>
      ) : (
        <div>
          <div className="container-fluid">
            <div className="row d-flex justify-content-center ">
              {/* <div className="generate text-center">Define  Fee</div> */}
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div>
                  <div className="row justify-content-between text-left">
                    <Table responsive>
                      <thead>
                        <tr id="tbl">
                          <th>Fee Name</th>
                          <th>Total Amount</th>
                          <th>Payable Amount</th>
                          <th>Concession</th>
                          <th>Remarks</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allDefinedFees.map((fees, i) => {
                          return (
                            <tr key={fees.id} className="addstudentList">
                              <td>{fees?.fee_type?.name}</td>
                              <td>
                                {parseInt(fees.amount) +
                                  parseInt(fees.concession_amount)}
                              </td>
                              <td>{fees.amount}</td>
                              <td>{fees.concession_amount}</td>
                              {/* <td  >remark?</td> */}
                              <td
                              //  title={fees.remarks}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  {fees.remarks ? (
                                    <SVG
                                      title={fees.remarks}
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Code/Info-circle.svg"
                                      )}
                                    />
                                  ) : (
                                    <SVG
                                      title="No Remarks"
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Code/Info-circle.svg"
                                      )}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <a
                                  className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                  onClick={() => {
                                    seteditablefee(fees);
                                    seteditmodal(true);
                                    // seteditedconcession(fees.concession_amount)
                                    // seteditedamount(fees.amount)
                                    //  openEditCustomerDialog(row.id)
                                  }}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Write.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a
                                  className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                  onClick={() => {
                                    setdeleteablefee(fees);
                                    setdeleteModal(true);
                                  }}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Delete-user.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="row justify-content-between text-left">
                    {/* <div class="form-group col-sm-12 flex-column d-flex">
                                                <label>
                                                    <b>Select Session <span style={{ color: "red" }}>*</span></b>
                                                </label>
                                                <select
                                                    onChange={(e) => changesession(e.target.value)}
                                                    class="form-select"
                                                    style={{ height: 40 }}
                                                    value={currentsession}
                                                    aria-label="Default select example"

                                                >
                                                    <option selected value="" >Select Session</option>
                                                    {
                                                        allsessions.map(session => (

                                                            <option value={session.id}>{session.year}</option>


                                                        ))
                                                    }
                                                </select>

                                            </div> */}

                    <>
                      {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="form-check">
                          <label>
                            <input
                              type="radio"
                              value="Amount"
                              checked={selectedConcType === "Amount"}
                              onChange={onRadioButtonClick}
                            />
                            Amount
                          </label>
                        </div>
                        <div className="form-check">
                          <label>
                            <input

                              type="radio"
                              value="Percentage"
                              checked={selectedConcType === "Percentage"}
                              onChange={onRadioButtonClick}
                            />
                            Percentage
                          </label>
                        </div>
                      </div> */}
                      <div className="form-group col-sm-4 flex-column d-flex">
                        <label className="form-control-label">
                          <b>Concession Type</b>
                        </label>
                        <select
                          defaultValue={studentdetail.concession_id}
                          // defaultChecked={studentdetail.concession_id}
                          onChange={handleConcessionChnage}
                          style={{ backgroundColor: "white" }}
                          className="form-select mb-2 "
                          aria-label="Default select example"
                        >
                          <option selected>
                            Select Concession Type{" "}
                          </option>


                          {allconcessions.map((feetype) => (
                            <option key={feetype.id}
                              value={feetype.id}>
                              {feetype.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-sm-3 flex-column d-flex">
                        <label className="form-control-label">
                          <b>Concession Allowed </b>
                        </label>

                        <span style={{ marginTop: "15px" }} disabled>
                          {" "}
                          Concession Allowed is {selectedConcType =='Percentage'? allowedconcession+"%" :allowedconcession}
                        </span>

                        {/* <input disabled value={concessionamount} type="text" /> */}
                      </div>
                      <div className="form-group col-sm-4 flex-column d-flex">
                        <label className="form-control-label">
                          <b>
                            Fee Type <span style={{ color: "red" }}>*</span>{" "}
                          </b>
                        </label>
                        <select
                        id="feeselect"
                          // value={designation_id}
                          onChange={(e) => {
                            setapplyconcessionbtn(true);
                            setonChangeAmount("");
                            setconcessionamount(0);
                            getRespctiveFee(e.target.value);
                            if (allowedconcession == 0) {
                              setisallowedconcesstion(true);
                            } else {
                              setisallowedconcesstion(false);
                            }
                            // checkerror()
                          }}
                          className="form-select mb-2 "
                          aria-label="Default select example"
                        >
                          <option disabled selected>
                            Select Fee Type{" "}
                          </option>
                          {feetypes.map((feetype) => (
                            <option key={feetype.id} value={feetype.id}>
                              {feetype.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-sm-4 flex-column d-flex">
                        <label className="form-control-label">
                          <b>
                            Amount <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <input
                          value={onChangeAmount}
                          defaultValue={onChangeAmount}
                          onChange={(e) => {

                            if (e.target.value == "") {
                              setisallfields(true);
                              seterrorcreate("Please Add Fee");
                            } else {
                              setisallfields(false);
                            }

                            if (parseInt(e.target.value) < 0) {
                              setisallfields(true)
                              seterrorcreate('Please Enter Correct amount')
                            } else {
                              setisallfields(false)
                              seterrorcreate('')
                            }
                            onChangeFeeAmount(e.target.value);

                          }}
                          type="number"
                        />
                      </div>
                      <div className="form-group col-sm-4 flex-column d-flex">
                        <label className="form-control-label">
                          <b>Concession </b>
                        </label>
                        {applyconcessionbtn ? (
                          <button
                            style={{
                              backgroundColor: "#121291",
                              color: "#ffffff",
                              borderColor: "#121291",
                              width: "165px",
                            }}
                            disabled={isallowedconcesstion}
                            onClick={applyConcessionBtn}
                            className="btn btn-success  pl-4 pr-4"
                          >
                            Apply Concession
                          </button>
                        ) : (
                          <input
                            disabled
                            value={concessionamount}
                            type="number"
                          />
                        )}
                      </div>
                      <div className="form-group col-sm-4 flex-column d-flex">
                        <label className="form-control-label">
                          <b>Remarks </b>
                        </label>
                        <input
                          onChange={(e) => {
                            setremarks(e.target.value);
                          }}
                          type="text"
                        />
                      </div>
                    </>
                  </div>
                  {isallfields ? (
                    <>
                      <Alert
                        variant="danger"
                        onClose={() => setisallfields(false)}
                        dismissible
                      >
                        <Alert.Heading>
                          Oh snap! You got an error!
                        </Alert.Heading>
                        <p>{errorcreate}</p>
                      </Alert>
                    </>
                  ) : null}
                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    className="btns"
                  >
                    <button
                      disabled={parseInt(concessionamount) < 0 ? true : parseInt(onChangeAmount) < 0 ? true : false}
                      onClick={feeDefine}
                      className="btn btn-success button_blue pl-4 pr-4"
                    >
                      Add
                    </button>

                    <button
                      onClick={() => show(false)}
                      className="btn btn-danger btn_width  pl-4 pr-4 ml-3"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal show={deleteModal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Are you want to delete
          </Modal.Title>
        </Modal.Header>
        {deleteloading ? (
          <div style={{ padding: "20px" }}>
            {" "}
            <b style={{ color: "green" }}>
              {" "}
              Fee Deleting Please Wait <span className="spinner-borderd"></span>
            </b>
          </div>
        ) : (
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={deleteFee} className="btn button_blue">
                Delete
              </button>
              <button
                style={{ marginLeft: "10px" }}
                onClick={() => setdeleteModal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>

      <EditDefinedFee
        feeDetail={editablefee}
        onHide={seteditmodal}
        show={editmodal}
        getDefinedFees={getDefinedFees}
      />
    </div>
  );
};

export default DefineMonthlyFee;
