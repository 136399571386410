import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";

const CertificateReport = () => {
  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [sessioning, setallsession] = useState([]);
  const [loading, setloading] = useState(false);
  const [reportDetails, setreportDetails] = useState([]);
  const [campuses, setallcampuses] = useState([]);
  const [selectedcampus, setselectedcampus] = useState("");
  const [campusName, setCampusName] = useState("");
  const [currentsession, setcurrentsession] = useState("");
  const alert = useAlert();
  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const pageStyle = `@page{
    size: a4 !important;
  }`;

  useEffect(() => {
    getallsessions();
    getSettingData();
  }, []);
  const getallsessions = async () => {
    await fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let allcampuses = [];
        setallsession(data.payload);

        data.payload.map((session, index) => {
          if (session.active_financial_year == "1") {
            console.log(session.id);
            setcurrentsession(session.id);
          }
        });

        // data.forEach((session, index) => {
        //   if (session.active_academic_year == "1") {
        //     setcurrentsession(session.id);
        //   }
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getAllCampus = async () => {
  //   await fetch(`${baseUrl}/campus`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((res) =>
  //       res.json().then((data) => {
  //         console.log(data.payload);
  //         setallcampuses(data.payload);
  //       })
  //     )

  //     .catch((err) => {
  //       console.log("campus", err);
  //     });
  // };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeCampus = async () => {
    if (selectedcampus == "") {
      alert.error("Please Select Campus");
      return;
    }
    setloading(true);

    const campus = campuses.find((item) => {
      return item.id == selectedcampus;
    });
    //setCampusName(campus.name);

    const data = {
      certificate_type: selectedcampus,
      year_id: currentsession,
    };
    await fetch(`${baseUrl}/all-certificates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setloading(false);
            setIsVisible(true);

            // let res = data.payload.sort((a, b) => a.emp_code - b.emp_code);
            console.log(data);
            setreportDetails(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper className="p-5 mt-5 mb-5">
        <div className="row flex">
          <div className="col-md-4" style={{ marginTop: "10px" }}>
            <b> Session</b>
            <select
              className="form-control"
              value={currentsession}
              onChange={(e) => {
                setcurrentsession(e.target.value);
              }}
              id="sessioner"
              style={{ marginTop: "5px" }}
            >
              {sessioning.map((sessions) => (
                <option value={sessions.id}>{sessions.year}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label>
              <b>Select Certificate</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              onChange={(e) => {
                setselectedcampus(e.target.value);
                setCampusName(e.target.options[e.target.selectedIndex].text);
              }}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Certificate
              </option>
              {/* {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))} */}

              <option value="1">School Leaving Certificate</option>
              <option value="2">Migration Certificate</option>
            </select>
          </div>

          <div className="col-sm-1 mt-9">
            <button
              onClick={onchangeCampus}
              className="btn btn-primary me-2"
              id="myBtn"
            >
              Show
            </button>
          </div>

          <div className="col-sm-1 mt-9" style={{ marginLeft: "-15px" }}>
            {reportDetails?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Appointed  Report`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            <div className="row p-3  mt-6">
              <img
                // className='col-md-2'
                style={{ height: 100, width: 110 }}
                src={icon}
              />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h5>List Of {campusName}</h5>
              </div>

              {/* <div>

                    </div> */}
            </div>

            <ColoredLine color={"#FF0000"} />

            <Table responsive>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Sr.</th>
                  <th style={{ textAlign: "center" }}>Admission ID</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>Father's Name</th>
                  <th style={{ textAlign: "start" }}>Leaving Date</th>
                  <th style={{ textAlign: "center" }}>DOB</th>
                  <th style={{ textAlign: "center" }}>Mobile</th>
                  <th style={{ textAlign: "center" }}>Class</th>
                  <th style={{ textAlign: "center" }}>Section</th>
                </tr>
              </thead>
              <tbody>
                {reportDetails &&
                  reportDetails.map((report, index) => (
                    <tr>
                      {/* <td style={{ textAlign: "center" }}>{index + 1}</td> */}
                      <td style={{ textAlign: "center" }}>{report.id}</td>
                      <td style={{ textAlign: "center" }}>
                        {report.student.admission_id}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report.student.name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report.student.father_name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.leaving_date}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.student.dob}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.student.mobile_no}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report?.student.student_class.name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.student.global_section.name}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : null}

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="p-3 d-flex">
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col-md-8">
              <h1>{unitName}</h1>
              <h5>List Of {campusName}</h5>
            </div>
          </div>

          <Table responsive>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "4%",
                    padding: "1px",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "7%",
                    padding: "1px",
                  }}
                >
                  Adm ID
                </th>
                <th
                  style={{
                    textAlign: "start",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "20%",
                    padding: "1px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    textAlign: "start",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "20%",
                    padding: "1px",
                  }}
                >
                  Father's Name
                </th>
                <th
                  style={{
                    textAlign: "start",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "12%",
                    padding: "1px",
                  }}
                >
                  Leaving Date
                </th>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "12%",
                    padding: "1px",
                  }}
                >
                  DOB
                </th>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "7%",
                    padding: "1px",
                  }}
                >
                  Mobile
                </th>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "10%",
                    padding: "1px",
                  }}
                >
                  Class
                </th>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    width: "10%",
                    padding: "1px",
                  }}
                >
                  Section
                </th>
              </tr>
            </thead>
            <tbody>
              {reportDetails &&
                reportDetails.map((report, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {report.student.admission_id}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {/* {report.student.name} */}
                      {report.student.name.length > 15
                        ? report.student.name.substring(0, 15) + "..."
                        : report.student.name}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {/* {report.student.father_name} */}
                      {report.student.father_name.length > 15
                        ? report.student.father_name.substring(0, 12)
                        : report.student.father_name}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {report.leaving_date}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {report.student.dob}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {report.student.mobile_no}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {/* {report?.student.student_class.name} */}
                      {report?.student.student_class.name.length > 5
                        ? report?.student.student_class.name.substring(0, 4)
                        : report?.student.student_class.name}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {/* {report.student.global_section.name} */}
                      {report?.student.global_section.name.length > 5
                        ? report?.student.global_section.name.substring(0, 4)
                        : report?.student.global_section.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Paper>
    </>
  );
};

export default CertificateReport;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
