import React, { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
const DueFeeExport = ({ grandTotal, printData }) => {
  const tableRef = useRef(null);

  // const feeChalanChecker = (arr) => {
  //     let res = arr.filter((item) => {
  //       if (item.fee_challan_details.length > 0) {
  //         return item;
  //       }
  //     });
  //     return res;
  //   };
  let num2 = 0;

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}`;
  };
  const getFeeSum = (array) => {
    let sum = 0;
    array.forEach((element) => {
      sum += parseInt(element.payable);
    });
    return sum;
  };
  const gettingSubTotalPrint = (miniitems) => {
    let sum = 0;
    miniitems.students.forEach((student) => {
      return (sum += student.total_fee);
    });
    // console.log("sessionsSum",sum)
    return sum;
  };
  return (
    <div>
      <DownloadTableExcel
        filename="Student Due Fee"
        sheet="DueFee"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary"> Export </button>
      </DownloadTableExcel>
      <table ref={tableRef} style={{ width: "100%", display: "none" }}>
        <thead style={{ marginTop: "20px" }}>
          <tr id="tbl" className="duefeeetd">
            <td
              style={{
                color: "black",
                textAlign: "center",
              }}
            >
              <b>Sr.no</b>
            </td>
            <td
              style={{
                color: "black",
                textAlign: "center",
              }}
            >
              <b>Adm-Id</b>
            </td>
            <td
              style={{
                color: "black",
                textAlign: "start",
              }}
            >
              <b>Student Name</b>
            </td>
            <td
              style={{
                color: "black",
                textAlign: "start",
              }}
            >
              <b>Father Name</b>
            </td>
            <td
              style={{
                color: "black",
                textAlign: "center",
              }}
            >
              <b>Mobile No</b>
            </td>
            <td
              style={{
                color: "black",
                textAlign: "center",
              }}
            >
              <b>Months</b>
            </td>
            <td
              style={{
                color: "black",
                textAlign: "center",
              }}
            >
              <b>Total Months</b>
            </td>
            <td
              style={{
                color: "black",
                textAlign: "center",
              }}
            >
              <b>Total Fee</b>
            </td>
          </tr>
        </thead>
        {printData &&
          printData.map((item) => (
            <>
              <tr>
                <td colSpan={7}>
                  <div
                    className="d-flex"
                    style={{ marginTop: "5px", marginLeft: "10px" }}
                  >
                    <div className="">
                      <h3 className="text-primary">{item.campus_name}</h3>
                    </div>
                  </div>
                </td>
              </tr>
              {item.classes?.map((subitem) =>
                subitem.sections.map((miniitems) => (
                  <>
                    {/* <tr style={{marginTop:"20px"}}>
                          <td colSpan={7}>
                          <div className="d-flex">
                           <div
                                className="d-flex"
                                style={{
                                  marginLeft: "20px",
                                }}
                              >
                                <h4>Class:-</h4>
                                <h4>{subitem?.class_name}</h4>
                              </div>
                              <div
                                className="d-flex"
                                style={{ paddingLeft: "40px" }}
                              >
                                <h4>Section:-</h4>
                                <h4>{miniitems.section_name}</h4>
                              </div>
                           </div>
                          </td>
                         </tr> */}

                    <tbody>
                      <tr
                        className="hadhybhai"
                        style={{
                          height: "20px",
                          display: "flex",
                          marginTop: "10px",
                          position: "relative",
                        }}
                      >
                        <td colSpan={3} className="text_align_start duefeesec">
                          {" "}
                          <h4>Class:- {subitem?.class_name}</h4>
                        </td>

                        <td
                          colSpan={4}
                          style={{}}
                          className="text_align_start duefeesec"
                        >
                          <h4>Section:- {miniitems.section_name}</h4>
                        </td>
                      </tr>
                      {miniitems.students.map((student, i) => (
                        <tr id="tbl" className="duefeeetd">
                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            {(num2 += 1)}
                            {/* <b>{num+=1}</b>  */}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            {student.admission_id}
                            {/* <b>{student.admission_id}</b> */}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "start",
                            }}
                          >
                            {student.student_name}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "start",
                            }}
                          >
                            {student.father_name}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            {student.mobile_no}
                          </td>

                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            {student?.fee_months.map((item, i) => (
                              <>
                                <span
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  <b>{i == "0" ? "" : ","} </b>
                                  {getMonthName(item)}
                                </span>
                              </>
                            ))}
                          </td>
                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            {student.total_months}
                          </td>

                          <td
                            style={{
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            {student.total_fee}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tr>
                      <td colSpan={6}></td>
                      <td style={{ textAlign: "end" }}>
                        <h5 className="text-primary">
                          Sub Total:{miniitems?.section_amount}
                        </h5>
                      </td>
                    </tr>
                  </>
                ))
              )}
              <tr>
                <td colSpan={6}></td>
                <td style={{ textAlign: "end" }}>
                  <h4 className="text-primary">
                    {item.campus_name} Total Due Fee: {item.amount}
                  </h4>
                </td>
              </tr>
            </>
          ))}
        <tr>
          <td colSpan={6}></td>
          <td style={{ textAlign: "end" }}>
            <h3 style={{ paddingTop: "5px" }} className="text-primary">
              Grand Total:- {grandTotal}
            </h3>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default DueFeeExport;
