import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../../../../services/config'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
const ViewStaffDetails = ({ EmpDetails,studentDetails }) => {


    console.log(EmpDetails,"this is staf details")

    const [firstlinewidth, setfirstlinewidth] = useState('fit-content')
    const [secondlinewidth, setsecondlinewidth] = useState('fit-content')
    const [loading, setloading] = useState(false)
    // const [EmpDetails, setEmpDetails] = useState({})


    useEffect(() => {
        // fetchDetails()
    }, [])



    // const fetchDetails = async () => {
    //     setloading(true)
    //     await fetch(`${baseUrl}/emp-salary-detail/${id}`, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${authtoken}`
    //         }
    //     }).then(async (res) => {
    //         res.json()
    //             .then((data) => {
    //                 // console.log(data)
    //                 setEmpDetails(data.payload)
    //                 setloading(false)


    //             })
    //     }).catch((err) => {
    //         console.log(err)
    //         setloading(false)
    //     })
    // }






    return (
        <div>
            {
                loading ? <Spinner variant="secondary" animation="border" /> :
                    <>
                        <div className="card">
                            <h2 style={{ margin: 10 }}>Personal Details</h2>
                            <br />
                            <div className='col-md-12 d-flex'>

                                <div className=" d-flex col-md-6 ">

                                    <div  > <h5 >Name : &ensp;</h5> </div>
                                    <h5 title={EmpDetails?.full_name?.length > 15 ? EmpDetails?.full_name : null} style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}>{EmpDetails.full_name.length > 15 ? "  " + EmpDetails.full_name.substring(0, 12) + "..." : "  " + EmpDetails.full_name}</h5>


                                </div>
                                <div className=" d-flex col-md-6 ">


                                    <div >  <h5 >Gender : &ensp;</h5> </div>
                                    <h5 title={EmpDetails?.gender.length > 15 ? EmpDetails?.gender : null} style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}>{EmpDetails.gender.length > 15 ? "  " + EmpDetails.gender.substring(0, 12) + "..." : "  " + EmpDetails.gender}</h5>




                                    {/* <h5 style={{ color: 'grey' }}>Name :{EmpDetails.full_name}</h5> */}
                                    {/* <h5 style={{ color: 'grey' }}>Gender : {EmpDetails.gender}</h5> */}

                                </div>

                            </div>
                            <hr />
                            <div className='col-md-12 d-flex'>
                                <div className="d-flex col-md-6 ">

                                    <div > <h5 >Duty : &ensp;</h5> </div>
                                    <h5 title={EmpDetails?.duties_assigned} style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}>{EmpDetails?.duties_assigned}</h5>
                                </div>
                                <div className=" d-flex col-md-6 ">

                                    <div > <h5 >Job status : &ensp;</h5> </div>
                                    <h5 title={EmpDetails?.job_status_name?.length > 15 ? EmpDetails?.job_status_name : null} style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}>{EmpDetails?.job_status_name?.length > 15 ? "  " + EmpDetails?.job_status_name.substring(0, 12) + "..." : "  " + EmpDetails?.job_status_name}</h5>

                                </div>
                            </div>
                            <hr />


                            <div className='col-md-12 d-flex'>
                                <div className="d-flex col-md-6 ">

                                    <div > <h5 >Mobile : &ensp;</h5> </div>
                                    <h5 title={EmpDetails?.mobile_no.length > 15 ? EmpDetails?.mobile_no : null} style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}>{EmpDetails?.mobile_no.length > 15 ? "  " + EmpDetails?.mobile_no.substring(0, 12) + "..." : "  " + EmpDetails?.mobile_no}</h5>
                                </div>
                                {/* <div className=" d-flex col-md-6 ">

                                    <div ><h5 >Payment : &ensp;</h5> </div>
                                    <h5 title={EmpDetails.payment_type.length > 15 ? EmpDetails.payment_type : null} style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}>{EmpDetails.payment_type.length > 15 ? "  " + EmpDetails.payment_type.substring(0, 12) + "..." : "  " + EmpDetails.payment_type}</h5>

                                </div> */}
                            </div>



                            {/* <div className=" col-md-6 d-flex justify-content-between">
                                <h5 style={{ color: 'grey' }}>Mobile  : {EmpDetails.mobile_no}</h5>
                                <h5 style={{ color: 'grey' }}>Payment : {EmpDetails.payment_type}</h5>

                            </div> */}
                            <hr />


                            <div className='col-md-12 d-flex'>
                                {/* <div className="d-flex col-md-6 ">

                                    <div> <h5 >Account Head : &ensp;</h5> </div>
                                    <h5 title={EmpDetails.account_head.length > 25 ? EmpDetails.account_head : null} style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}>{EmpDetails.account_head.length > 25 ? "  " + EmpDetails.account_head.substring(0, 22) + "..." : "  " + EmpDetails.account_head}</h5>
                                </div> */}
                                <div className=" d-flex col-md-6 ">

                                    <div > <h5 >Address : &ensp;</h5> </div>
                                    <h5 title={EmpDetails?.address.length > 25 ? EmpDetails?.address : null} style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}>{EmpDetails?.address.length > 25 ? "  " + EmpDetails?.address.substring(0, 22) + "..." : "  " + EmpDetails?.address}</h5>

                                </div>
                            </div>


                            {/* <div className=" col-md-6 d-flex justify-content-between">
                                <h5 style={{ color: 'grey' }}>Account Head : {EmpDetails.account_head}</h5>
                                <h5 style={{ color: 'grey' }}>Address: {EmpDetails.address}</h5>

                            </div> */}
                            <hr />

                        </div>



                        <div className="card p-3">
                            <h1 className="m-3">Salary details</h1>
                            <br />
                            <UserDetails
                                title="Salary Days"
                                value={EmpDetails.salery_days}
                                title2="Social_security_number"
                                value2={EmpDetails.social_security_number}
                            />
                            <UserDetails
                                title="Payment type"
                                value={EmpDetails.payment_type}
                                title2="Reg Code"
                                value2={EmpDetails.reg_code}
                            />

                        </div>

                        <div className="card p-3">
                            <h1 className="m-3">GP Fund details</h1>
                            <br />
                            <UserDetails
                                title="ID"
                                value={EmpDetails?.gp_collected_fund?.id}
                                title2="Collected Amount"
                                value2={EmpDetails?.gp_collected_fund?.collected_amount}

                            />

                        </div>

                        <div className="card p-3">
                            <h1 className="m-3">Salary Deduction</h1>
                            <br />
                            <UserDetails
                                title="Id"
                                //  value={EmpDetails.salayDeduction.id}
                                title2="Eobi"
                                value2={EmpDetails.salaryDeduction.eobi}
                            />
                            <UserDetails
                                title="Insurance"
                                value={EmpDetails?.salaryDeduction?.insurance}
                                title2="Income Tax"
                                value2={EmpDetails?.salaryDeduction?.income_tax}
                            />
                            <UserDetails
                                title="Van Charge"
                                value={EmpDetails?.salaryDeduction?.van_charge}
                                title2="Other"
                                value2={EmpDetails?.salaryDeduction?.other}
                            />

                        </div>



                        <div className="card p-3">
                            <h1 className="m-3">Salary Allowance</h1>
                            <br />
                            <UserDetails
                                title="Id"
                                value={EmpDetails?.salaryAlowance?.id}
                                title2="increment"
                                value2={EmpDetails?.salaryAlowance?.increment}
                            />
                            <UserDetails
                                title="Hifz"
                                value={EmpDetails?.salaryAlowance?.hifz}
                                title2="H-O-D"
                                value2={EmpDetails?.salaryAlowance?.hod}
                            />
                            <UserDetails
                                title="U-G-S"
                                value={EmpDetails?.salaryAlowance?.ugs}
                                title2="Second Shift"
                                value2={EmpDetails?.salaryAlowance?.second_shift}
                            />
                            <UserDetails
                                title="Additional Allowance"
                                value={EmpDetails?.salaryAlowance?.additional_allowance}
                                title2="Convance"
                                value2={EmpDetails?.salaryAlowance?.convance}
                            />
                            <UserDetails
                                title="extra_coaching"
                                value={EmpDetails?.salaryAlowance?.extra_coaching}
                                title2="extra_period"
                                value2={EmpDetails?.salaryAlowance?.extra_period}
                            />
                            {/* <UserDetails
                                title="extra_coaching"
                                value={EmpDetails.salaryAlowance.extra_coaching}
                                title2="extra_period"
                                value2={EmpDetails.salaryAlowance.extra_period}
                            /> */}
                            <UserDetails
                                title="Hostel"
                                value={EmpDetails?.salaryAlowance?.hostel}
                                title2="Science"
                                value2={EmpDetails?.salaryAlowance?.science}
                            />
                        </div>
                     {/* {studentDetails.length>0?   <div className="card p-3">
                            <h1 className="m-3">Childerns</h1>
                            <br />
                          {
                            studentDetails.map((item)=>(
                                <>

                            <UserDetails
                                title="Name"
                                value={item?.name}
                                title2="Father"
                                value2={item?.father_name}
                            />
                             <UserDetails
                                title="Admission Id"
                                value={item?.admission_id}
                                title2="Registration Id"
                                value2={item?.registration_id}
                            />
                     
                          
                            <UserDetails
                                title="Campus"
                                value={item?.campus?.name}
                                title2="Class"
                                value2={item?.student_class?.name}
                            />
                             <UserDetails
                                title="Mobile No"
                                value={item?.mobile_no}
                                title2="Gender"
                                value2={item?.gender}
                            />
                        
                                </>
                            ))
                          }
                        </div>:null} */}


                    </>


            }
        </div>
    )
}

export default ViewStaffDetails



const UserDetails = ({ title, title2, value, value2 }) => {
    return (
        <>

            <div className='col-md-12 d-flex'>
                <div className="d-flex col-md-6 ">

                    <h5 >{title} : &ensp;</h5>
                    <h5 style={{ textAlign: "start", cursor: "pointer", color: 'grey' }} > {value}</h5>
                </div>
                <div className=" d-flex col-md-6 ">

                    <h5 >{title2} : &ensp;</h5>
                    <h5 style={{ textAlign: "start", cursor: "pointer", color: 'grey' }}  > {value2}</h5>

                </div>
            </div>

            <hr />
        </>
        // <div className=" col-md-6 d-flex justify-content-between">
        //     <h5 className="m-3" style={{ color: 'grey' }}>{title}  : {value}</h5>
        //     <h5 className="m-3" style={{ color: 'grey' }}>{title2} : {value2}</h5>

        // </div>
    )
}