import { Paper } from "@material-ui/core";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { DatePicker, Divider } from "antd";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const MonthlyExpense = () => {
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [reportData, setReportData] = useState([]);
  const [campusId, setCampusId] = useState("");
  const [date, setDate] = useState("");

  const [loading, setLoading] = useState(false);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const alert = useAlert();
  const onBeforeGetContentResolve = React.useRef(null);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    sessions();
    // getAllCampus();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const handleGetData = () => {
    if (date == "") {
      alert.error("Date is required");
    }
    setLoading(true);
    const data = {
      year_id: yearId,
      campus_id: campusId,
      date: date,
    };
    fetch(`${baseUrl}/date_wise_monthly_expense_summary_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        if (data.payload) {
          if (data.metadata.success) {
            setReportData(data.payload);
          } else {
            alert.error("Something wents wronge");
          }
        }
        let arr = [];
        data.payload.filter((item) => {
          item.account_chart.filter((subitem) => {
            if (subitem.sub_account.length > 0) {
              subitem.sub_account.filter((subs) => {
                if (subs.date_wise_data.length > 0) {
                  subs.date_wise_data.map((gg) => {
                    arr.push({
                      date: gg.date,
                      debit: parseInt(gg.debit),
                    });
                  });
                }
              });
            }
          });
        });
        console.log("this is data", data);
        console.log(arr, "resultant");
        if (arr.length > 0) {
          let modifiedArr = [];
          let debitMap = new Map();

          arr.forEach((item) => {
            if (debitMap.has(item.date)) {
              debitMap.set(item.date, debitMap.get(item.date) + item.debit);
            } else {
              debitMap.set(item.date, item.debit);
            }
          });

          debitMap.forEach((debit, date) => {
            modifiedArr.push({ date, debit });
          });

          modifiedArr.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });

          console.log(modifiedArr);
          setReportData(modifiedArr);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="d-flex">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Date</b>
            </label>
            <input
              onChange={(e) => setDate(e.target.value)}
              className="form-control"
              type="date"
            />
          </div>
          <div className="mt-9 d-flex">
            <div className="ml-4">
              <button className="btn btn-primary" onClick={handleGetData}>
                Show
              </button>
              {reportData.length > 0 ? (
                <ReactToPrint
                  documentTitle={`Profit and Loss Report`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      // onClick={() => console.log("dfsdfsdfd")}
                      disabled={printloading ? true : false}
                      className="btn btn-success button_blue button_blue mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="row mt-9">
          <div className="col-md-1 mr-8">
            <img
              style={{ height: 100, width: 110, paddingLeft: "20px" }}
              src={icon}
              alt="logo"
            />
          </div>
          <div className="col-md-8">
            <h1>{unitName}</h1>
            <h3>DATE WISE MONTHLY EXPENSE SUMMARY REPORT</h3>
          </div>
        </div>
        <br />
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <>
              <div id="tableboot">
                {reportData.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr id="tbl">
                        <th className="text-center">Date of Expenses</th>
                        <th className="text-center">Expense Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData &&
                        reportData.map((item) => (
                          <tr id="tbl">
                            <td className="text-primary">{item.date}</td>

                            <td className="text-primary">{item.debit}</td>
                          </tr>
                        ))}
                      <tr id="tbl">
                        <td
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                          }}
                        >
                          Grand Total :-
                        </td>

                        <td
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.debit),
                            0
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mt-9 d-flex">
            <div>
              <img
                style={{ height: 100, width: 110, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="ml-4">
              <h1>{unitName}</h1>
              <h3>DATE WISE MONTHLY EXPENSE SUMMARY REPORT</h3>
            </div>
          </div>
          <br />
          <div className="mt-5">
            <>
              <div id="tableboot">
                {reportData.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr id="tbl">
                        <th className="text-center">Date of Expenses</th>
                        <th className="text-center">Expense Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData &&
                        reportData.map((item) => (
                          <tr id="tbl">
                            <td className="text-primary">{item.date}</td>

                            <td className="text-primary">{item.debit}</td>
                          </tr>
                        ))}
                      <tr id="tbl">
                        <td
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                          }}
                        >
                          Grand Total :-
                        </td>

                        <td
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.debit),
                            0
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  ""
                )}
              </div>
            </>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default MonthlyExpense;
