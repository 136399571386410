import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../../services/config'
import { useAlert } from 'react-alert'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import SVG from "react-inlinesvg";

import { Table, Modal, Button, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Col, Alert } from 'react-bootstrap'
const UpdsateDefinedFee = ({ feeDetail, onHide, show, getDefinedFees }) => {
    const alert = useAlert()
    const authtoken = localStorage.getItem('userToken')
    const [loading, setloading] = useState(false)
  console.log(feeDetail,"this is fee details ")
    const [isallfields, setisallfields] = useState(false)
    const [errorcreate, seterrorcreate] = useState("Plese Fill All Fields")
    const [remarks,setRemarks]=useState(feeDetail.remarks)
    const [editedamount, seteditedamount] = useState(feeDetail.amount)
    
    const [editedconcession, seteditedconcession] = useState(feeDetail.concession_amount)
    // console.log(feeDetail.amount,"")
    useEffect(() => {
        console.log(feeDetail.amount, "")
        seteditedamount(feeDetail.amount)
        seteditedconcession(feeDetail.concession_amount)
        setRemarks(feeDetail.remarks)
    }, [feeDetail])

    const onChangeFeeAmount = (value) => {
        seteditedamount(value)
        console.log(feeDetail.amount - value)
        let newconcession = parseInt(feeDetail.amount) - parseInt(value)
        let updatedcncession = parseInt(newconcession) + parseInt(feeDetail.concession_amount)
        seteditedconcession(updatedcncession)
    }
    const updateFee = () => {


        if (editedamount == "") {
            setisallfields(true)
            seterrorcreate("Please Add Fee")
            return false;
        }

        setisallfields(false)

        setloading(true)
        let data = {
            amount: editedamount,
            concession_amount: editedconcession,
            remarks:remarks
        }
        console.log(data)
        fetch(`${baseUrl}/student_liable_fee/${feeDetail.id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authtoken}`,

            },
            body: JSON.stringify(data)
        }).then((res) => res.json())
            .then((data) => {
                console.log(data)
                setloading(false)
                onHide(false)
                if (data.metadata) {

                    if (data.metadata.success) {
                        getDefinedFees();

                    } else {
                        console.log(data)

                    }
                }
            })
            .catch((err) => {
                alert.show('Something Went Wrong')
                console.log("err", err)
                setloading(false)
                onHide(false)

            })
    }
    return (
        <div>
            <Modal  show={show} >
                <Modal.Header className='modal_header_style' closeButton>
                    <Modal.Title className="Modal_Heading_font" id="contained-modal-title-vcenter">
                        Edit Fee Info
                    </Modal.Title>

                </Modal.Header>
                {
                    loading ? <div style={{ padding: "30px" }}> <b style={{ color: 'green' }}> Fee Edditing Please Wait <span className="spinner-borderd"></span></b> </div> :
                        <div>

                            <Modal.Body>

                                <div className="container-fluid">
                                    <div className="row ">
                                        {/* <div className="generate text-center">Define  Fee</div> */}
                                        <div className="col-md-12">
                                                    <label className="form-control-label">
                                                        <b>Fee Type:-</b> <span>{feeDetail.fee_type?.name}</span>
                                                    </label>
                                              <p className='mt-3'></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        <b>Amount <span style={{ color: "red" }}>*</span></b>
                                                    </label>
                                                    <input
                                                        value={editedamount}
                                                        defaultValue={feeDetail.amount}
                                                        onChange={(e) => {
                                                            onChangeFeeAmount(e.target.value)
                                                            if (e.target.value == "") {
                                                                setisallfields(true)
                                                                seterrorcreate("Please Add Fee")

                                                            } else {
                                                                setisallfields(false)
                                                            }
                                                            //    seteditedamount(e.target.value)
                                                        }}
                                                        type="number" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        <b>Concession <span style={{ color: "red" }}>*</span> </b>
                                                    </label>
                                                    <input disabled
                                                        defaultValue={feeDetail.concession_amount}
                                                        value={editedconcession}
                                                        type="number" />
                                                </div>
                                                <div className="col-sm-12 mt-3">
                                                    <label className="form-control-label">
                                                        <b>Remarks</b>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        onChange={(e)=>setRemarks(e.target.value)}
                                                        value={remarks}
                                                        type="text" />
                                                </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                                {
                                    isallfields ? <>
                                        <Alert variant="danger" onClose={() => setisallfields(false)} dismissible>
                                            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                                            <p>
                                                {errorcreate}

                                            </p>

                                        </Alert>
                                    </> : null
                                }
                            <Modal.Footer>
                                {loading ? <b style={{ color: 'green' }}> Fee Deleting Please Wait <span className="spinner-borderd"></span></b> :
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <button
                                            onClick={updateFee}
                                            className="btn button_blue">
                                            Update
                                        </button>
                                        <button
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => onHide(false)}
                                            className="btn btn-danger btn_width">
                                            Cancel
                                        </button>
                                    </div>
                                }
                            </Modal.Footer>
                        </div>
                }
            </Modal>
        </div>
    )
}

export default UpdsateDefinedFee
