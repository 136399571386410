import React, { useEffect, useState } from "react";
import "./style.css";
import { baseUrl } from "../../../services/config";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Table, Button, Modal, Spinner } from "react-bootstrap";
import { func } from "prop-types";
import { useHistory } from "react-router-dom";
import EditSubAccount from "./editSubAccount";
import AddSubAccount from "./AddSubAccount";

import Paper from "@material-ui/core/Paper";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";

export default function Accounts_Chart() {
  let authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const [accountsdata, setaccountsdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [selectedModal, setselectedModal] = useState({});
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteloading, setdeleteloading] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [acode_id, setacode_id] = useState("");
  let history = useHistory();
  useEffect(() => {
    getChartOfAcoounts();

    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.transition = "all 15s linear";
          content.style.display = "none";
        } else {
          content.style.transition = "all 15s linear";
          content.style.display = "block";
        }
      });
    }
  }, []);

  const getChartOfAcoounts = async () => {
    setloading(true);
    await fetch(`${baseUrl}/baseaccount`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);

        if (data.metadata) {
          if (data.metadata.success) {
            setaccountsdata([...data.payload]);
          }
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  function Active_div_btn(e) {
    icone_Change(e);

    let check_element = e.target.id;
    var lastFive = check_element.substr(check_element.length - 5);
    let element;
    if (lastFive == "icone") {
      let parent_element_id = check_element.slice(0, -5);

      element = document.getElementById(parent_element_id);
    } else {
      element = e.target;
    }

    element.classList.toggle("active");
    var content = element.nextElementSibling;

    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }

  const icone_Change = (e) => {
    let id = e.target.id;
    var lastFive = id.substr(id.length - 5);
    let tag;
    if (lastFive == "icone") {
      tag = document.getElementById(id);
    } else {
      tag = document.getElementById(id + "icone");
    }

    if (tag.className === "fa fa-angle-right") {
      tag.className = "";
      tag.className = "fa fa-angle-down";
    } else if (tag.className === "fa fa-angle-down") {
      tag.className = "";
      tag.className = "fa fa-angle-right";
    }
  };

  function editFunc(sub_account) {
    seteditModal(true);

    setselectedModal(sub_account);
  }

  function openDelete(sub_account) {
    setdeleteModal(true);
    setselectedModal(sub_account);
  }
  function deleteAccount() {
    setdeleteloading(true);
    fetch(`${baseUrl}/subaccount/${selectedModal.id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setdeleteloading(false);
          //   getChartOfAcoounts()
          if (data.metadata) {
            if (data.metadata.success) {
              getChartOfAcoounts();
            }
          }
          setdeleteModal(false);
        })
      )
      .catch((err) => {
        setdeleteModal(false);
        setdeleteloading(false);
        console.log(err);
      });
  }

  const openAddModal = (account_chart, e) => {
    let divtag;
    if (e.target.id == "Combined-Shape") {
      divtag =
        e.target.parentNode.parentNode.parentNode.parentNode.previousSibling;
    } else if (e.target.id == "Oval-5") {
      divtag =
        e.target.parentNode.parentNode.parentNode.parentNode.previousSibling;
    } else {
      divtag = e.target.parentNode.parentNode.previousSibling;
    }

    let childsDiv = divtag.lastChild;

    if (childsDiv != null) {
      let newecodeid = parseInt(childsDiv.id) + parseInt(1);
      newecodeid = newecodeid.toString();
      console.log(newecodeid);
      setacode_id(newecodeid);
    } else {
      let newecodeid = divtag.parentNode.parentNode.id + "0001";
      console.log(newecodeid);
      setacode_id(newecodeid);
    }

    setaddModal(true);
    setselectedModal(account_chart);
  };

  return (
    <div style={{ padding: "30px" }}>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {
          <div className="row justify-content-center">
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
        }

        {accountsdata.map((item, index) => {
          return (
            <div key={index}>
              <button
                onClick={(e) => Active_div_btn(e)}
                id={"accordion_item" + index}
                type="button"
                className="collapsible btn_style1"
              >
                <i
                  id={"accordion_item" + index + "icone"}
                  className="fa fa-angle-right"
                  style={{
                    marginRight: "15px",
                    fontSize: "20px",
                    color: "#3f4254",
                  }}
                ></i>
                {item.title + " (" + item.acode + ")"}
              </button>

              <div className="content content1">
                {item.account_groups.map(
                  (account_group, account_group_index) => {
                    return (
                      <div
                        style={{ backgroundColor: "white" }}
                        key={account_group_index}
                      >
                        <button
                          onClick={(e) => Active_div_btn(e)}
                          // onClick={Active_div}
                          id={"account_item" + index + account_group_index}
                          type="button"
                          className="collapsible btn_style2"
                        >
                          <i
                            id={
                              "account_item" +
                              index +
                              account_group_index +
                              "icone"
                            }
                            className="fa fa-angle-right"
                            style={{
                              marginRight: "15px",
                              fontSize: "20px",
                              color: "#3f4254",
                            }}
                          ></i>
                          {account_group.title +
                            " (" +
                            account_group.acode +
                            ")"}
                        </button>
                        <div className="content content2">
                          {account_group.account_charts.map(
                            (account_chart, account_chart_index) => {
                              return (
                                <div
                                  style={{ backgroundColor: "white" }}
                                  key={account_chart_index}
                                >
                                  <button
                                    onClick={(e) => Active_div_btn(e)}
                                    //  onClick={Active_div}
                                    id={
                                      "account_chart_item" +
                                      index +
                                      account_group_index +
                                      account_chart_index
                                    }
                                    type="button"
                                    className="collapsible btn_style3"
                                  >
                                    <i
                                      id={
                                        "account_chart_item" +
                                        index +
                                        account_group_index +
                                        account_chart_index +
                                        "icone"
                                      }
                                      className="fa fa-angle-right"
                                      style={{
                                        marginRight: "15px",
                                        fontSize: "20px",
                                        color: "#3f4254",
                                      }}
                                    ></i>
                                    {account_chart.title +
                                      " (" +
                                      account_chart.acode +
                                      ")"}
                                  </button>
                                  <div
                                    id={account_chart.acode}
                                    className="content content3"
                                  >
                                    <div
                                      id="last_div_content"
                                      style={{
                                        display: "flex",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      {account_chart.sub_accounts == [] ||
                                      account_chart.sub_accounts ==
                                        undefined ? null : (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            paddingBottom: "10px",
                                          }}
                                        >
                                          {account_chart.sub_accounts.map(
                                            (
                                              sub_account,
                                              sub_account_index
                                            ) => {
                                              return (
                                                <div
                                                  id={sub_account.acode}
                                                  key={sub_account_index}
                                                >
                                                  <button
                                                    style={{
                                                      width: "fit-content",
                                                    }}
                                                    // onClick={Active_div_btn}

                                                    type="button"
                                                    className="collapsible btn_style4"
                                                  >
                                                    {sub_account.title +
                                                      " (" +
                                                      sub_account.acode +
                                                      ")"}
                                                    {PremissionChecker(
                                                      user,
                                                      "chart_of_account.can_edit"
                                                    ) ? (
                                                      <span
                                                        onClick={() =>
                                                          editFunc(sub_account)
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        className="svg-icon svg-icon-md svg-icon-primary"
                                                      >
                                                        <SVG
                                                          src={toAbsoluteUrl(
                                                            "/media/svg/icons/Communication/Write.svg"
                                                          )}
                                                        />
                                                      </span>
                                                    ) : null}
                                                    {PremissionChecker(
                                                      user,
                                                      "chart_of_account.can_delete"
                                                    ) ? (
                                                      <span
                                                        onClick={() =>
                                                          openDelete(
                                                            sub_account
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        className="svg-icon svg-icon-md svg-icon-danger"
                                                      >
                                                        <SVG
                                                          src={toAbsoluteUrl(
                                                            "/media/svg/icons/General/Trash.svg"
                                                          )}
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </button>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        {PremissionChecker(
                                          user,
                                          "chart_of_account.can_add"
                                        ) ? (
                                          <span
                                            onClick={(e) =>
                                              openAddModal(account_chart, e)
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                            }}
                                            className="svg-icon svg-icon-md svg-icon-primary svg-icon_add"
                                          >
                                            <SVG
                                              src={toAbsoluteUrl(
                                                "/media/svg/icons/Code/Plus.svg"
                                              )}
                                            />
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>

              {/* {Active_div()} */}
            </div>
          );
        })}

        <Modal size="xl" show={editModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Edit Account
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditSubAccount
              getChartOfAcoounts={getChartOfAcoounts}
              show={seteditModal}
              selectedAccount={selectedModal}
            />
          </Modal.Body>
        </Modal>

        <Modal size="xl" show={addModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add Account
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddSubAccount
              getChartOfAcoounts={getChartOfAcoounts}
              show={setaddModal}
              selectedAccount={selectedModal}
              acode_id={acode_id}
            />
          </Modal.Body>
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              style={{ padding: "15px" }}
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete Account
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            {deleteloading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <>
                <button onClick={deleteAccount} className="btn button_blue">
                  Delete
                </button>
                <button
                  onClick={() => setdeleteModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
}
