import React from 'react';
import Select from 'react-select';

export default ({ onChange, options, value, className, disabled, onTextChange,defValue, isAvailable = false }) => {

    console.log(options)
    const defaultValue = (options, value) => {
        return options ? options.find(option => option.value == value) : "";
    };
    const defaultselect = (options) => {
        return options ? options.find(option => option.selected == true) : "";
    }

    // console.log('ye default value h bhai react select ki',defaultValue(options, value))
    // console.log('ye default function h bhai react select ki',defaultValue(options, value))

    return (
        <div className={className}>
            <Select
                isSearchable={true}
                defaultInputValue={defValue}
                isDisabled={disabled}
                defaultValue={defaultselect(options, value)}
                value={defaultValue(options, value)}
                //  selected={defaultselect(options)}
                onChange={value => {
                    onChange(value)

                }}
                options={options}
            />
        </div>

    )
}