import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import SVG from "react-inlinesvg";
import { Table, Modal, Alert } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import EditHostel from "./EditHostel/EditHostel";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../_metronic/_partials/controls";

export default function Hostels() {
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const authtoken = localStorage.getItem("userToken");

  // let [hostels, sethostels] = useState(useSelector(state => state.hostel.hostels))
  let [hostels, sethostels] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [loading, setloading] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [name, setname] = useState("");
  const [address_1, setaddress_1] = useState("");
  const [address_2, setaddress_2] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [addLoading, setaddLoading] = useState(false);
  const [Current, setCurrentId] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [selectedHostel, setselectedHostel] = useState({});
  const [add_hostel_check, setadd_hostel_check] = useState(false);
  const [selectedhostelindex, setselectedhostelindex] = useState();
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [campusError, setCampusError] = useState(false);

  useEffect(() => {
    getAllHostels();
    // getAllCampus();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const upDatedHotel = async (value, index) => {
    let middle_array = hostels;
    middle_array[selectedhostelindex] = value;
    console.log(middle_array, "middle array");
    sethostels([...middle_array]);
    console.log(hostels, "all hostels");
    dispatch({
      type: "GET_ALL_HOSTELS",
      payload: middle_array,
    });

    //let res=useSelector(state => state.hostel.hostels)
  };

  const getAllHostels = async () => {
    setloading(true);
    fetch(`${baseUrl}/hostel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((allhostels) => {
        console.log(allhostels, "allhostels");
        dispatch({
          type: "GET_ALL_HOSTELS",
          payload: allhostels.payload,
        });
        sethostels([...allhostels.payload]);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const AddHostelHandler = (values) => {
    if (campusId == "") {
      setCampusError(true);
      return;
    }

    setaddLoading(true);
    const fdata = {
      campus_id: campusId,
      name: values.hostel_name,
      address_1: values.address1,
      address_2: values.address2,
      latitude: values.longitude,
      longitude: values.latitude,
    };
    fetch(`${baseUrl}/hostel`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setaddLoading(false);
            setaddModal(false);
            getAllHostels();
            alert.show("Hostel Added Success");
          }
        })
      )
      .catch((err) => {
        console.log(err);
        setaddLoading(false);
        setaddModal(false);
      });
  };

  const deleteMiddle = (id) => {
    setCurrentId(id);
    setdeleteModal(true);
  };

  const _DeleteHostelHandler = () => {
    setdeleteLoading(true);
    fetch(`${baseUrl}/hostel/${Current}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setdeleteModal(false);
          alert.success("Deleted Successfully");
          setdeleteLoading(false);
          getAllHostels();
        }
      })
      .catch((err) => {
        setdeleteModal(false);
        setdeleteLoading(false);
        console.log(err);
      });
  };

  const middleEditFunc = (hostel, index) => {
    setselectedhostelindex(index);
    setselectedHostel(hostel);
    seteditModal(true);
  };

  const initialValues = {
    hostel_name: "",
    address1: "",
    address2: "",
    longitude: "",
    latitude: "",
  };

  const HostelRegistrationSchema = Yup.object().shape({
    hostel_name: Yup.string().required("Name Is required"),

    address1: Yup.string().required("Address 1 Is required"),
    address2: Yup.string().required("Address 2 Is required"),
    longitude: Yup.string()
      .required("Longitude Is required")
      .matches(/^[1-9][\.\d]*(,\d+)?$/, "Enter valid Longitude"),
    latitude: Yup.string()
      .required("Latitude Is required")
      .matches(/^[1-9][\.\d]*(,\d+)?$/, "Enter valid Latitude"),
  });

  return (
    <div>
      <Paper style={{ marginTop: "10px", padding: "15px" }}>
        <button
          className="btn btn-success button_blue"
          onClick={() => setaddModal(true)}
        >
          Add Hostel{" "}
        </button>
        <br />
        <br />
        <Table>
          <thead>
            <tr className="text-center">
              <th style={{ textAlign: "start" }}>Name</th>
              <th style={{ textAlign: "start" }}>Address_1</th>
              <th style={{ textAlign: "start" }}>Address_2</th>
              <th style={{ textAlign: "start" }}>Edit</th>
              <th style={{ textAlign: "start" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {hostels.map((item, index) => (
              <>
                <tr key={item.id}>
                  <td style={{ textAlign: "start" }}>{item.name}</td>
                  <td style={{ textAlign: "start" }}>{item.address_1}</td>
                  <td style={{ textAlign: "start" }}>{item.address_2}</td>
                  <td style={{ textAlign: "start" }}>
                    <span
                      onClick={() => middleEditFunc(item, index)}
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-primary"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      />
                    </span>
                  </td>

                  <td style={{ textAlign: "start" }}>
                    <span
                      onClick={() => deleteMiddle(item.id)}
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-danger"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Modal show={addModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add Hostel
            </Modal.Title>
          </Modal.Header>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={HostelRegistrationSchema}
            onSubmit={(values) => {
              AddHostelHandler(values);
            }}
          >
            {(props) => {
              const {
                handleSubmit,
                errors,
                touched,
                values,
                isSubmitting,
                isValid,
                handleChange,
                handleBlur,
              } = props;

              return (
                <>
                  <Modal.Body>
                    {addLoading ? (
                      <b style={{ color: "green" }}>
                        Hostel Adding Please Wait!{" "}
                        <span className="spinner-border"></span>
                      </b>
                    ) : (
                      <Form
                        onSubmit={handleSubmit}
                        className="form form-label-right"
                      >
                        <div>
                          <div className="row">
                            <div className="col-md-6">
                              <label>
                                <b>Select Campus</b>
                              </label>
                              <div className="d-flex flex-column">
                                <select
                                  style={{ height: 40 }}
                                  className="form-select"
                                  name="campus_id"
                                  aria-label="Default select example"
                                  onChange={(e) => {
                                    setCampusError(false);
                                    setCampusId(e.target.value);
                                  }}
                                >
                                  <option selected value="">
                                    {" "}
                                    Select Campus
                                  </option>
                                  {campusData &&
                                    campusData.map((item) => (
                                      <option value={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                                {campusError ? (
                                  <label className="text-danger">
                                    Please Select the campus
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Hostel Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                maxLength={150}
                                required
                                name="hostel_name"
                                component={Input}
                                type="text"
                                placeholder="Enter Hostel Name"
                              />
                              {/* <input placeholder="Enter Hostel Name" className="form-control" onChange={(e) => setname(e.target.value)} /> */}
                            </div>
                          </div>
                          <div className="row  mt-3">
                            <div className="col-md-6">
                              <label>
                                Address 1{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                maxLength={150}
                                required
                                name="address1"
                                component={Input}
                                type="text"
                                placeholder="Enter 1st Address"
                              />
                              {/* <input className="form-control" onChange={(e) => setaddress_1(e.target.value)} placeholder="Address 1" /> */}
                            </div>
                            <div className="col-md-6 ">
                              <label>
                                Address 2{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                maxLength={150}
                                required
                                name="address2"
                                component={Input}
                                type="text"
                                placeholder="Enter 2nd Address"
                              />
                              {/* <input className="form-control" onChange={(e) => setaddress_2(e.target.value)} placeholder="Address 1" /> */}
                            </div>
                          </div>
                          <div className="row  mt-3">
                            <div className="col-md-6">
                              <label>
                                Longitude{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                maxLength={20}
                                required
                                name="longitude"
                                component={Input}
                                type="text"
                                placeholder="Enter Longitude"
                              />
                              {/* <input className="form-control" onChange={(e) => setlongitude(e.target.value)} placeholder="Longitude" /> */}
                            </div>
                            <div className="col-md-6">
                              <label>
                                Latitude <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                maxLength={20}
                                required
                                name="latitude"
                                component={Input}
                                type="text"
                                placeholder="Enter Latitude"
                              />
                              {/* <input className="form-control" onChange={(e) => setlatitude(e.target.value)} placeholder="Latitude" /> */}
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Modal.Body>
                  {add_hostel_check ? (
                    <div id="err">
                      <Alert
                        variant="danger"
                        onClose={() => setadd_hostel_check(false)}
                        dismissible
                      >
                        <Alert.Heading>Please fill All fields!</Alert.Heading>
                        <p>All fields Are required</p>
                      </Alert>
                    </div>
                  ) : null}
                  <Modal.Footer>
                    <button
                      className="btn btn-success button_blue"
                      //  onClick={AddHostelHandler}
                      onClick={() => handleSubmit()}
                      type="submit"
                      value="Submit"
                    >
                      Add Hostel
                    </button>
                    <button
                      className="btn btn-danger btn_width"
                      onClick={() => setaddModal(false)}
                    >
                      Cancel
                    </button>
                  </Modal.Footer>
                </>
              );
            }}
          </Formik>
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header>Delete Hostel</Modal.Header>
          <Modal.Body>
            {deleteLoading ? (
              <b style={{ color: "green" }}>
                Hostel deleting Please wait {` `}{" "}
                <span className="spinner-border"></span>
              </b>
            ) : (
              <>Are you sure you want to delete?</>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn_width"
              onClick={_DeleteHostelHandler}
            >
              Delete
            </button>
            <button
              className="btn  btn-warning btn_width"
              onClick={() => setdeleteModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={editModal} size="lg">
          {/* <Modal.Header>Edit Hostel</Modal.Header> */}
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Edit Hostel
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditHostel
              show={() => seteditModal()}
              updateHostelDetail={upDatedHotel}
              HostelDetails={selectedHostel}
              allCampuses={campusData}
            />
          </Modal.Body>
        </Modal>
      </Paper>
    </div>
  );
}
