import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import { upperCase } from "lodash";

const BankScroll = () => {
  let authtoken = localStorage.getItem("userToken");

  const [reportDetails, setreportDetails] = useState([]);

  const [date, setdate] = useState("");
  const [loading, setloading] = useState(false);
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedSubAccountId, setselectedSubAccountId] = useState("");
  const [accoutHead, setaccoutHead] = useState({});

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    getSubAccounts();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubAccounts = async () => {
    await fetch(`${baseUrl}/subaccount_contra`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallsubaccounts(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  const searchReport = () => {
    const data = {
      date,
      sub_account_id: selectedSubAccountId,
    };

    setloading(true);
    fetch(`${baseUrl}/daily_scroll`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setaccoutHead(data.payload.account_head);
        setreportDetails(data.payload.transactions);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  return (
    <div>
      <Paper className="mt-5 p-5">
        <div className="row">
          <div className="col-md-6">
            <h3>Select Campus</h3>
            <select
              style={{ marginTop: 10, height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedSubAccountId}
              onChange={(e) => setselectedSubAccountId(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {allsubaccounts.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.title}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <h3>Select Date</h3>
            <input
              className="form-control"
              style={{ marginTop: 10, height: 40 }}
              type={"date"}
              onChange={(e) => setdate(e.target.value)}
            />
          </div>
        </div>
        <button onClick={searchReport} className="mt-3 btn btn-primary">
          Search
        </button>
      </Paper>

      {loading ? (
        <div
          className="mt-5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner variant="info" animation="border" />
        </div>
      ) : (
        <Paper className="mt-5">
          <div className="row p-3">
            <img
              // className='col-md-2'
              style={{ height: 100, width: 100 }}
              src={icon}
            />

            <div className="col-md-6">
              <h1>{unitName}</h1>
              <p>Bank Scroll Date Wise{""}</p>
            </div>

            <div>
              <p>{accoutHead?.id}</p>
              <p>{accoutHead?.Cash}</p>
              <p>-</p>

              <p>{accoutHead?.acode}</p>
            </div>
          </div>

          <ColoredLine color={"#FF0000"} />

          <Table responsive>
            <thead>
              <tr>
                <th style={{ textAlign: "start" }}>Sr No.</th>
                <th style={{ textAlign: "start" }}>Receving Date</th>
                <th style={{ textAlign: "start" }}>Debit</th>
                <th style={{ textAlign: "start" }}>Credit</th>
              </tr>
            </thead>
            <tbody>
              {reportDetails &&
                reportDetails.map((report, index) => (
                  <tr key={index + 1}>
                    <td style={{ textAlign: "start" }}>{index + 1}</td>
                    <td style={{ textAlign: "start" }}>
                      {report.tansaction_at}
                    </td>
                    <td style={{ textAlign: "start" }}>{report.debit}</td>
                    <td style={{ textAlign: "start" }}>{report.credit}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Paper>
      )}
    </div>
  );
};

export default BankScroll;
const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
