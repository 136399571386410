import React from "react";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useAlert } from "react-alert";
import { Spinner, Table, Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { baseUrl } from "../../../services/config";
import { DatePicker, Space } from "antd";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const pageStyle = `
  @page {
    size: landscape !important;
}
`;
const Attendance = () => {
  const user = useSelector((state) => state.user.user);
  const [allsessions, setallsessions] = useState([]);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [editmodal, seteditmodal] = useState(false);
  const [classesData, setClassesData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [campusId, setCampusId] = useState("");
  const [campusName, setCampusName] = useState("");
  const [className, setClassName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [classId, setClassId] = useState("");
  const [monthName, setMonthName] = useState("");
  const [monthDays, setMonthDays] = useState([]);
  const [monthYear, setMonthYear] = useState("");
  const [studentsData, setStudentsData] = useState(0);
  const [studentAttData, setStudentAttData] = useState(0);
  const [studentAttData1, setStudentAttData1] = useState([]);
  const [studentsData1, setStudentsData1] = useState([]);
  const [studentAttData2, setStudentAttData2] = useState([]);
  const [loading, setloading] = useState(false);
  const [dates, setDates] = useState([]);
  const [dates1, setDates1] = useState([]);
  const [educationType, setEducationType] = useState(1);
  const [currentsession, setcurrentsession] = useState("");
  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const componentRef1 = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    getAllSessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChanger = (day, date) => {
    const datez = new Date(date[1]);
    setMonthYear(datez.getFullYear());
    const monthDays = new Date(
      datez.getFullYear(),
      datez.getMonth() + 1,
      0
    ).getDate();
    let startDate = date[0] + "-01";
    let endDate = date[1] + "-" + monthDays;
    var start = startDate.split("-");
    var end = endDate.split("-");
    setDates(startDate);
    setDates1(endDate);
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${campusId}/${id}${
        classId ? "/" + classId : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };
  const handleChangeupdate = (value, e) => {
    e.preventDefault();
    console.log(e.target.value);
    fetch(
      `${baseUrl}/monthly-attendance-details?sid=${value}&starting=${dates}&ending=${dates1}&year_id=${currentsession}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setStudentsData1(data.stu);
        setStudentAttData2(data.attendance);
        console.log(data.stu);
      })
      .catch((err) => {
        console.log(err);
      });
    seteditmodal(true);
  };
  const onChampusChange = (id) => {
    const campus = campusData.find((item) => {
      return item.id == id;
    });

    setCampusName(campus.name);
    document.getElementById("classselect").selectedIndex = 0;
    document.getElementById("sectionselect").selectedIndex = 0;
    setCampusId(id);
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setClassesData(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onClassChange = (id) => {
    document.getElementById("sectionselect").selectedIndex = 0;
    const className = classesData.find((item) => {
      return item.id == id;
    });

    setClassName(className.name);
    setClassId(id);
    fetch(`${baseUrl}/class_section/${campusId}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSectionData(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("", err);
      });
  };

  const getAllStudents = (stucampus, stuclass, stusection) => {
    var ssid = document.getElementById("sid").value;
    setloading(true);
    if (ssid == "") {
      if (campusId == "") {
        alert.error("Campus is required ");
        setloading(false);
        return;
      } else {
        fetch(
          `${baseUrl}/monthlyAttendance?campus_id=${stucampus}&student_class_id=${stuclass}&global_section_id=${stusection}&start_date=${dates}&end_date=${dates1}&year_id=${currentsession}`,
          {
            method: "GET",
          }
        )
          .then((res) =>
            res.json().then((data) => {
              setStudentsData(data.stu);
              setStudentAttData(data.attendance);
              setStudentAttData1(data.attendance);
              setloading(false);
              console.log(data.stu);
              console.log(data.attendance);
            })
          )

          .catch((err) => {
            setloading(false);
            console.log(err);
          });
      }
    } else {
      fetch(
        `${baseUrl}/monthlyAttendance1?sid=${ssid}&starting=${dates}&ending=${dates1}&year_id=${currentsession}`,
        {
          method: "GET",
        }
      )
        .then((res) =>
          res.json().then((data) => {
            setStudentsData(data.stu);
            setStudentAttData(data.attendance);
            setStudentAttData1(data.attendance);
            setloading(false);
            console.log(data.stu);
            console.log(data.attendance);
          })
        )

        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
  };

  const onSectionChange = (id) => {
    const sectionName = sectionData.find((item) => {
      return item.id == id;
    });

    setSectionName(sectionName.name);
    setSectionId(id);
  };
  const handleShow = () => {
    if (dates == "") {
      alert.error("Date is required ");
      return;
    }
    getAllStudents(campusId, classId, sectionId);
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => setcurrentsession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option
                  key={index}
                  //   selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3 mb-2">
            <label>
              <b>Select Month</b>
            </label>
            <RangePicker
              picker="month"
              onChange={(day, date) => handleChanger(day, date)}
              className="form-month"
            />
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 35 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => onChampusChange(e.target.value)}
              >
                <option selected value="">
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>
            <div className="d-flex flex-column">
              <select
                id="classselect"
                style={{ height: 35 }}
                className="form-select"
                name="campus_id"
                aria-label="Default select example"
                onChange={(e) => onClassChange(e.target.value)}
              >
                <option selected value="">
                  Select Class
                </option>
                {classesData &&
                  classesData.map((item) => (
                    <>
                      <option value={item.id}>{item.name}</option>
                    </>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 35 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-3 mb-2">
            <label>
              <b>Select Section</b>
            </label>
            <div className="d-flex flex-column">
              <select
                id="sectionselect"
                style={{ height: 35 }}
                className="form-select"
                name="campus_id"
                aria-label="Default select example"
                onChange={(e) => onSectionChange(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Section
                </option>

                {sectionData &&
                  sectionData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <label>
              <b>Student ID</b>
            </label>
            <div className="d-flex flex-column">
              <input
                type="text"
                id="sid"
                style={{ height: 35 }}
                className="form-control"
                placeholder="Enter Student ID"
              />
            </div>
          </div>

          <div className="col-md-1 mt-9">
            <button className="btn btn-primary" onClick={handleShow}>
              Search
            </button>
          </div>

          <div className="col-md-2 mt-9 ml-10">
            {studentsData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Attendance Report`}
                onAfterPrint={handleAfterPrint}
                pageStyle={pageStyle}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-primary"
                    style={{ width: "70px" }}
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        {/* <div className="row mt-2">
          <div className="col-md-2">
            <button className="btn btn-primary" onClick={handleShow}>
              Search
            </button>
          </div>
          <div className="col-md-3 mt-2">
            {studentsData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Attendance Report`}
                onAfterPrint={handleAfterPrint}
                pageStyle={pageStyle}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div> */}

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div>
            {studentsData.length > 0 ? (
              <>
                <div className="row p-3">
                  <img
                    // className='col-md-2'
                    style={{ width: 100, height: 100 }}
                    src={icon}
                  />

                  <div className="col-md-8" style={{ lineHeight: 1.5 }}>
                    <h1>{unitName}</h1>
                    <h4>
                      Attendance Details of {campusName} {className}(
                      {sectionName}) <br /> From &nbsp;
                      {getMonthName(dates)} To {getMonthName(dates1)}
                      {/* Attendance Details of {campusName}{" "}
                      {className}(
                      {sectionName}) from {dates} {" "} to {dates1}
                      {monthName}, {monthYear}{" "} */}
                    </h4>
                  </div>
                </div>
                <Table responsive>
                  <thead>
                    <tr>
                      <td>Sr</td>
                      <td>Adm-Id</td>
                      <td>Student Name</td>
                      {/*monthDays.map((day) => (
                          <td>{day}</td>
                        ))*/}
                      <td>Absent</td>
                      <td>Sick</td>
                      <td>Leave</td>
                      <td>Late Coming</td>
                      <td>Home Work Not Done</td>
                      <td>Improper uniform</td>
                      <td>Test not prepared</td>
                      <td>View</td>
                    </tr>
                  </thead>

                  <tbody>
                    {studentsData.map((item, index) => (
                      // <>
                      //             {
                      //                   studentAttData.filter(person => (person.student_id == item.student.id) && (person.attendance_status_id === '1') || (person.attendance_status_id === '2') || (person.attendance_status_id === '3')  || (person.attendance_status_id === '4') || (person.attendance_status_id === '5') || (person.attendance_status_id === '6') || (person.attendance_status_id === '7')).map(filteredPerson => (
                      <tr>
                        <td
                        // style={{
                        //   color: "black",
                        //   border: "1px solid black",
                        //   textAlign: "center",
                        // }}
                        >
                          {index + 1}
                        </td>

                        <td
                        // style={{
                        //   color: "black",
                        //   border: "1px solid black",
                        //   textAlign: "center",
                        // }}
                        >
                          {item.student.admission_id}
                        </td>
                        <td
                        // style={{
                        //   color: "black",
                        //   border: "1px solid black",
                        //   textAlign: "center",
                        // }}
                        >
                          {item.student.name}
                        </td>
                        {/*monthDays.map((days) => (
                            <td
                              style={{
                                color: "black",
                                border: "1px solid black",
                                width: "35px",
                              }}
                              className="text_align_start"
                            ></td>
                            ))*/}
                        <td
                          // style={{
                          //   color: "black",
                          //   border: "1px solid black",
                          //   width: "35px",
                          // }}
                          className="text_align_center"
                        >
                          0
                          {studentAttData
                            .filter(
                              (person) =>
                                person.student_id == item.student.id &&
                                person.attendance_status_id === "1"
                            )
                            .map((filteredPerson) => (
                              <>{filteredPerson.count}</>
                            ))}
                        </td>
                        <td
                          // style={{
                          //   color: "black",
                          //   border: "1px solid black",
                          //   width: "35px",
                          // }}
                          className="text_align_center"
                        >
                          0
                          {studentAttData
                            .filter(
                              (person) =>
                                person.student_id == item.student.id &&
                                person.attendance_status_id === "2"
                            )
                            .map((filteredPerson) => (
                              <>{filteredPerson.count}</>
                            ))}
                        </td>
                        <td
                          // style={{
                          //   color: "black",
                          //   border: "1px solid black",
                          //   width: "35px",
                          // }}
                          className="text_align_center"
                        >
                          0
                          {studentAttData
                            .filter(
                              (person) =>
                                person.student_id == item.student.id &&
                                person.attendance_status_id === "3"
                            )
                            .map((filteredPerson) => (
                              <>{filteredPerson.count ?? 0}</>
                            ))}
                        </td>
                        <td
                          // style={{
                          //   color: "black",
                          //   border: "1px solid black",
                          //   width: "35px",
                          // }}
                          className="text_align_center"
                        >
                          0
                          {studentAttData
                            .filter(
                              (person) =>
                                person.student_id == item.student.id &&
                                person.attendance_status_id === "4"
                            )
                            .map((filteredPerson) => (
                              <>{filteredPerson.count}</>
                            ))}
                        </td>
                        <td
                          // style={{
                          //   color: "black",
                          //   border: "1px solid black",
                          //   width: "35px",
                          // }}
                          className="text_align_center"
                        >
                          0
                          {studentAttData
                            .filter(
                              (person) =>
                                person.student_id == item.student.id &&
                                person.attendance_status_id === "5"
                            )
                            .map((filteredPerson) => (
                              <>{filteredPerson.count}</>
                            ))}
                        </td>
                        <td
                          // style={{
                          //   color: "black",
                          //   border: "1px solid black",
                          //   width: "35px",
                          // }}
                          className="text_align_center"
                        >
                          0
                          {studentAttData
                            .filter(
                              (person) =>
                                person.student_id == item.student.id &&
                                person.attendance_status_id === "6"
                            )
                            .map((filteredPerson) => (
                              <>{filteredPerson.count}</>
                            ))}
                        </td>
                        <td
                          // style={{
                          //   color: "black",
                          //   border: "1px solid black",
                          //   width: "35px",
                          // }}
                          className="text_align_center"
                        >
                          0
                          {studentAttData
                            .filter(
                              (person) =>
                                person.student_id == item.student.id &&
                                person.attendance_status_id === "7"
                            )
                            .map((filteredPerson) => (
                              <>{filteredPerson.count}</>
                            ))}
                        </td>
                        {/* <td 
                          // style={{
                          //   color: "black",
                          //   border: "1px solid black",
                          //   width: "35px",
                          // }}
                            className="text_align_start">
                            <Button value={item.student.admission_id} onClick={(e) => { handleChangeupdate(e) }}>show</Button>
                          </td> */}

                        <td className="text_align_center">
                          <a
                            style={{}}
                            className="btn btn-icon btn-light btn-hover-primary btn-sm "
                            onClick={(e) => {
                              handleChangeupdate(item.student.admission_id, e);
                            }}
                            data-value={item.student.admission_id}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Devices/Display2.svg"
                                )}
                              />
                            </span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : null}
          </div>
        )}
        {/**
 alert
 */}
        <Modal show={editmodal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Attendance Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="form-group flex-column d-flex pt-3">
                {studentsData1.map((item, index) => (
                  <>
                    <h2 style={{ textAlign: "center" }}>
                      Student Attendance Report
                    </h2>
                    <h7 style={{ textAlign: "center" }}>
                      <b style={{ width: "115px", textAlign: "center" }}>
                        Time Period:
                      </b>
                      &nbsp;&nbsp;{getMonthName(dates)}&nbsp; To &nbsp;{" "}
                      {getMonthName(dates1)}
                    </h7>
                    <h4>Student Details:</h4>
                    <h7 style={{ marginBottom: "20px" }}>
                      <b style={{ width: "115px" }}>Admission ID:</b>&nbsp;
                      &nbsp;{item.admission_id}
                      <br />
                      <b style={{ width: "115px" }}>Student Name:</b>&nbsp;
                      &nbsp;{item.name}
                      <br />
                      <b style={{ width: "115px" }}>Campus:</b>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.campus.name}
                      <br />
                      <b style={{ width: "115px" }}>Class:</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;{item.student_class.name}-
                      {item.global_section.name}
                    </h7>
                    <h4>Attendance Totals:</h4>
                    <h7>
                      <b style={{ width: "100px" }}>Absents:</b>&nbsp;0
                      {studentAttData
                        .filter(
                          (person) =>
                            person.student_id == item.id &&
                            person.attendance_status_id === "1"
                        )
                        .map((filteredPerson) => (
                          <>{filteredPerson.count ?? 0}</>
                        ))}
                      &nbsp;&nbsp;<b style={{ width: "100px" }}> Leaves: </b>
                      &nbsp;0
                      {studentAttData
                        .filter(
                          (person) =>
                            person.student_id == item.id &&
                            person.attendance_status_id === "2"
                        )
                        .map((filteredPerson) => (
                          <>{filteredPerson.count ?? 0}</>
                        ))}
                      <b style={{ width: "100px" }}> Home Work Not Done:</b>
                      &nbsp;0
                      {studentAttData
                        .filter(
                          (person) =>
                            person.student_id == item.id &&
                            person.attendance_status_id === "4"
                        )
                        .map((filteredPerson) => (
                          <>{filteredPerson.count ?? 0}</>
                        ))}
                      <br />
                      <b style={{ width: "100px" }}> Late Coming:</b>&nbsp;0
                      {studentAttData
                        .filter(
                          (person) =>
                            person.student_id == item.id &&
                            person.attendance_status_id === "3"
                        )
                        .map((filteredPerson) => (
                          <>{filteredPerson.count ?? 0}</>
                        ))}
                      &nbsp;&nbsp;
                      <b style={{ width: "100px" }}>Improper Uniform:</b>&nbsp;0
                      {studentAttData
                        .filter(
                          (person) =>
                            person.student_id == item.id &&
                            person.attendance_status_id === "5"
                        )
                        .map((filteredPerson) => (
                          <>{filteredPerson.count ?? 0}</>
                        ))}{" "}
                      &nbsp;&nbsp;
                      <b style={{ width: "100px" }}>Unprepared Test:</b>&nbsp;0
                      {studentAttData
                        .filter(
                          (person) =>
                            person.student_id == item.id &&
                            person.attendance_status_id === "6"
                        )
                        .map((filteredPerson) => (
                          <>{filteredPerson.count ?? 0}</>
                        ))}
                    </h7>
                    <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>
                      Attendance Details:
                    </h4>
                  </>
                ))}
                <table responsive>
                  <thead>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          width: "35px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Date</b>
                      </td>
                      <td
                        style={{
                          color: "black",
                          width: "35px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Type</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {studentAttData2.map((item, index) => (
                      <tr>
                        <td
                          style={{
                            color: "black",
                            width: "35px",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {item.date}
                        </td>
                        <td
                          style={{
                            color: "black",
                            width: "35px",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {item.status_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  className="row"
                  alignItems="center"
                  style={{ marginTop: "20px" }}
                >
                  <ReactToPrint
                    documentTitle={`Attendance Report`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint={true}
                    trigger={() => (
                      <button
                        // onClick={() => console.log("dfsdfsdfd")}
                        disabled={printloading ? true : false}
                        className="btn btn-primary"
                        style={{ width: "150px", marginLeft: "150px" }}
                      >
                        Print
                      </button>
                    )}
                    content={() => componentRef1.current}
                  />
                  <button
                    className="btn btn-danger"
                    style={{ width: "150px", marginLeft: "20px" }}
                    onClick={() => {
                      seteditmodal(false);
                      // setBestOption([]);
                      // setFruitOne([]);
                      // setChecked([]);
                      //  handleSearch();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/**
         * Alert end
         */}

        {/* ///for print */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div>
            {studentsData.length > 0 ? (
              <>
                <div>
                  <table>
                    <tr>
                      <td>
                        <img
                          // className='col-md-2'
                          style={{
                            height: "140px",
                            width: "120px",
                            margin: "10px",
                          }}
                          src={icon}
                        />
                      </td>
                      <td>
                        <h1>{unitName}</h1>
                        <h4>
                          Attendance Details of {campusName} {className}(
                          {sectionName}) <br /> From &nbsp;
                          {getMonthName(dates)} To {getMonthName(dates1)}
                          {/* Attendance Details of {campusName}{" "}
                      {className}(
                      {sectionName}) from {dates} {" "} to {dates1}
                      {monthName}, {monthYear}{" "} */}
                        </h4>
                      </td>
                    </tr>
                  </table>
                </div>
                <div id="tableboot">
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Sr
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Adm-Id
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Student Name
                        </td>
                        {/*monthDays.map((day) => (
                          <td>{day}</td>
                        ))*/}
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Absent
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Sick
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Leave
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Late
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          H/W
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          I/uniform
                        </td>
                        <td
                          style={{
                            color: "black",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Test
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {studentsData.map((item, index) => (
                        <tr id="tbl">
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item.student.admission_id}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item.student.name}
                          </td>
                          {/*monthDays.map((days) => (
                            <td
                              style={{
                                color: "black",
                                border: "1px solid black",
                                width: "35px",
                              }}
                              className="text_align_start"
                            ></td>
                            ))*/}
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              width: "100px",
                              textAlign: "center",
                            }}
                            className="text_align_start"
                          >
                            0
                            {studentAttData1
                              .filter(
                                (person) =>
                                  person.student_id == item.student.id &&
                                  person.attendance_status_id === "1"
                              )
                              .map((filteredPerson) => (
                                <>{filteredPerson.count}</>
                              ))}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              width: "100px",
                              textAlign: "center",
                            }}
                            className="text_align_start"
                          >
                            0
                            {studentAttData1
                              .filter(
                                (person) =>
                                  person.student_id == item.student.id &&
                                  person.attendance_status_id === "2"
                              )
                              .map((filteredPerson) => (
                                <>{filteredPerson.count}</>
                              ))}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              width: "100px",
                              textAlign: "center",
                            }}
                            className="text_align_start"
                          >
                            0
                            {studentAttData1
                              .filter(
                                (person) =>
                                  person.student_id == item.student.id &&
                                  person.attendance_status_id === "3"
                              )
                              .map((filteredPerson) => (
                                <>{filteredPerson.count}</>
                              ))}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              width: "100px",
                              textAlign: "center",
                            }}
                            className="text_align_start"
                          >
                            0
                            {studentAttData1
                              .filter(
                                (person) =>
                                  person.student_id == item.student.id &&
                                  person.attendance_status_id === "4"
                              )
                              .map((filteredPerson) => (
                                <>{filteredPerson.count}</>
                              ))}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                              width: "100px",
                            }}
                            className="text_align_start"
                          >
                            {" "}
                            0{" "}
                            {studentAttData1
                              .filter(
                                (person) =>
                                  person.student_id == item.student.id &&
                                  person.attendance_status_id === "5"
                              )
                              .map((filteredPerson) => (
                                <>{filteredPerson.count}</>
                              ))}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                              width: "100px",
                            }}
                            className="text_align_start"
                          >
                            0{" "}
                            {studentAttData1
                              .filter(
                                (person) =>
                                  person.student_id == item.student.id &&
                                  person.attendance_status_id === "6"
                              )
                              .map((filteredPerson) => (
                                <>{filteredPerson.count}</>
                              ))}
                          </td>
                          <td
                            style={{
                              color: "black",
                              border: "1px solid black",
                              textAlign: "center",
                              width: "100px",
                            }}
                            className="text_align_start"
                          >
                            0
                            {studentAttData1
                              .filter(
                                (person) =>
                                  person.student_id == item.student.id &&
                                  person.attendance_status_id === "7"
                              )
                              .map((filteredPerson) => (
                                <>{filteredPerson.count}</>
                              ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {/*sub print*/}
        <div
          ref={componentRef1}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div>
            {studentsData.length > 0 ? (
              <>
                <div>
                  <table style={{ margin: "40px" }}>
                    <tr>
                      <td>
                        <img
                          // className='col-md-2'
                          style={{
                            height: "140px",
                            width: "120px",
                            margin: "10px",
                          }}
                          src={icon}
                        />
                      </td>
                      <td>
                        <h1>{unitName}</h1>
                        <h4>
                          Attendance Details of {campusName} {className}(
                          {sectionName}) <br /> From &nbsp;
                          {getMonthName(dates)} To {getMonthName(dates1)}
                          {/* Attendance Details of {campusName}{" "}
                      {className}(
                      {sectionName}) from {dates} {" "} to {dates1}
                      {monthName}, {monthYear}{" "} */}
                        </h4>
                      </td>
                    </tr>
                  </table>
                </div>
                <div style={{ marginLeft: "40px" }}>
                  {studentsData1.map((item, index) => (
                    <>
                      <h1 style={{ textAlign: "center", fontSize: "300%" }}>
                        Student Attendance Report
                      </h1>
                      <h7
                        style={{
                          textAlign: "center",
                          fontSize: "200%",
                          marginLeft: "330px",
                        }}
                      >
                        <b>Time Period:</b>&nbsp;&nbsp;{getMonthName(dates)}
                        &nbsp; To &nbsp; {getMonthName(dates1)}
                      </h7>
                      <h4 style={{ fontSize: "200%", marginTop: "40px" }}>
                        Student Details:
                      </h4>
                      <h7 style={{ marginBottom: "20px", fontSize: "150%" }}>
                        <b style={{ width: "115px" }}>Admission ID:</b>&nbsp;
                        &nbsp;{item.admission_id}
                        <br />
                        <b style={{ width: "115px" }}>Student Name:</b>&nbsp;
                        &nbsp;{item.name}
                        <br />
                        <b style={{ width: "115px" }}>Campus:</b>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.campus.name}
                        <br />
                        <b style={{ width: "115px" }}>Class:</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;{item.student_class.name}-
                        {item.global_section.name}
                      </h7>
                      <h4 style={{ fontSize: "200%", marginTop: "40px" }}>
                        Attendance Totals:
                      </h4>
                      <h7 style={{ fontSize: "150%" }}>
                        <b style={{ width: "100px" }}>Absents:</b>&nbsp;0
                        {studentAttData
                          .filter(
                            (person) =>
                              person.student_id == item.id &&
                              person.attendance_status_id === "1"
                          )
                          .map((filteredPerson) => (
                            <>{filteredPerson.count ?? 0}</>
                          ))}
                        &nbsp;&nbsp;<b style={{ width: "100px" }}> Leaves: </b>
                        &nbsp;0
                        {studentAttData
                          .filter(
                            (person) =>
                              person.student_id == item.id &&
                              person.attendance_status_id === "2"
                          )
                          .map((filteredPerson) => (
                            <>&nbsp;{filteredPerson.count ?? 0}</>
                          ))}
                        &nbsp;&nbsp;
                        <b style={{ width: "100px" }}>
                          {" "}
                          Home Work Not Done:
                        </b>{" "}
                        &nbsp;0
                        {studentAttData
                          .filter(
                            (person) =>
                              person.student_id == item.id &&
                              person.attendance_status_id === "4"
                          )
                          .map((filteredPerson) => (
                            <>&nbsp;{filteredPerson.count ?? 0}</>
                          ))}
                        <br />
                        <b style={{ width: "100px" }}> Late Coming:</b>&nbsp;0
                        {studentAttData
                          .filter(
                            (person) =>
                              person.student_id == item.id &&
                              person.attendance_status_id === "3"
                          )
                          .map((filteredPerson) => (
                            <>&nbsp;{filteredPerson.count ?? 0}</>
                          ))}
                        &nbsp;&nbsp;
                        <b style={{ width: "100px" }}>Improper Uniform:</b>
                        &nbsp;0
                        {studentAttData
                          .filter(
                            (person) =>
                              person.student_id == item.id &&
                              person.attendance_status_id === "5"
                          )
                          .map((filteredPerson) => (
                            <>&nbsp;{filteredPerson.count ?? 0}</>
                          ))}{" "}
                        &nbsp;&nbsp;
                        <b style={{ width: "100px" }}>Unprepared Test:</b>
                        &nbsp;0
                        {studentAttData
                          .filter(
                            (person) =>
                              person.student_id == item.id &&
                              person.attendance_status_id === "6"
                          )
                          .map((filteredPerson) => (
                            <>&nbsp;{filteredPerson.count ?? 0}</>
                          ))}
                      </h7>
                      <h2
                        style={{
                          marginTop: "40px",
                          marginBottom: "20px",
                          fontSize: "200%",
                        }}
                      >
                        Attendance Details:
                      </h2>
                    </>
                  ))}
                  <table style={{ fontSize: "150%", marginLeft: "30px" }}>
                    <thead>
                      <tr>
                        <td
                          style={{
                            color: "black",
                            width: "450px",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <b>Date</b>
                        </td>
                        <td
                          style={{
                            color: "black",
                            width: "450px",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <b>Type</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {studentAttData2.map((item, index) => (
                        <tr>
                          <td
                            style={{
                              color: "black",
                              width: "35px",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item.date}
                          </td>
                          <td
                            style={{
                              color: "black",
                              width: "35px",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {item.status_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default Attendance;
