import React, { useState, useEffect } from "react";
import { Paper, Switch } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Table, Modal, Spinner, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Barcode from "react-barcode";
import { Divider } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ViewStudentPrintWise from "../ViewStudentPrintWise/ViewStudentPrintWise";
import { useAlert } from "react-alert";
import { Toaster, toast } from "react-hot-toast";
import {
  sendNotificationsInBatches,
  sendNotificationsInBatches3,
} from "../../../components/SendNotifications/SendNotifications";

const MarkAttendance = () => {
  const alert = useAlert();
  //geeting token from storage
  const authtoken = localStorage.getItem("userToken");

  const history = useHistory();

  //redux actions

  const dispatch = useDispatch();

  //components states
  const user = useSelector((state) => state.user.user);
  const [allsessions, setallsessions] = useState([]);
  const [printmodal, setprintmodal] = useState(false);
  const [viewModal, setviewModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [classes, setclasses] = useState([]);
  const [allsections, setallsections] = useState([]);
  const [selectedStudentChallan, setselectedStudentChallan] = useState([]);
  const [attendModal, setattendModal] = useState(false);
  const [selectedcampus, setselectedcampus] = useState("");
  const [selectedclass, setselectedclass] = useState("");
  const [selectedSection, setselectedSection] = useState("");
  const [statusList, setstatusList] = useState([]);
  const [selectedStudent, setselectedStudent] = useState([]);
  const [allstudentList, setallstudentsList] = useState([]);
  const [date, setdate] = useState("");
  const [enableMessage, setenableMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [searchtext, setsearchtext] = useState("");
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [educationType, setEducationType] = useState(1);
  const [sendMessage, setSendMessage] = useState(true);
  const [sendNotification, setSendNotification] = useState(true);
  const [selectedStudents, setselectedStudents] = useState([]);
  const [selectedNotificationID, setselectedNotificationId] = useState([]);
  const [notificationStudents, setNotificationStudents] = useState([]);
  const [selectAttendace, setselectAttendace] = useState("");
  const [currentsession, setcurrentsession] = useState("");

  const [studentMessage, setStudentMessage] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [messageCount, setMessageCount] = useState(1);
  const [assignMessage, setAssignMessage] = useState(0);
  const [remainingMessage, setRemainingMessage] = useState(0);

  useEffect(() => {
    // getAllCampus();
    getAllSessions();
    getStatus();
    getUnitMessages();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatus = async () => {
    await fetch(`${baseUrl}/attendance_status`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("this ", data);
        setstatusList(data.payload.status);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllCampus = async () => {
    console.log("campus geeting");
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getUnitMessages = async () => {
    await fetch(`${baseUrl}/get-unit-messages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setAssignMessage(data?.payload?.assign_sms);
        setRemainingMessage(data?.payload?.remaining_sms);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
    await fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setclasses(data.payload);
          // getChallans()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onClassChange = async (id) => {
    setselectedclass(id);
    await fetch(
      `${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setallsections(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setallsections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getChallans = (id) => {
    setloading(true);
    console.log(id);
    setselectedSection(id);

    const data = {
      campus_id: selectedcampus,
      student_class_id: selectedclass,
      global_section_id: id,
      year_id: currentsession,
      education_type: educationType,
      status: 2,
    };

    console.log(data);

    fetch(`${baseUrl}/student_filter_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          let res = data.payload.sort(
            (a, b) => a.admission_id - b.admission_id
          );

          let checkboxes = res;
          data.payload.map((item, index) => {
            item["checked"] = false;
            checkboxes[index] = item;
          });
          setallstudentsList([...checkboxes]);
          // setallstudentsList(data.payload.students)
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);

        console.log(err);
      });
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    let makeNoti = [];
    let makeNotiIds = [];
    allstudentList.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        makeNoti.push({
          id: item.notification_id,
          name: item.name,
        });
        studentarray.push(item.id);
        makeNotiIds.push(item.notification_id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    setallstudentsList([...middlearray]);
    setselectedStudents([...studentarray]);
    setselectedNotificationId([...makeNotiIds]);
    setNotificationStudents([...makeNoti]);
  };

  const allcheckboxChecked = () => {
    let middlearray = [];
    let staffarray = [];
    let makeNoti = [];
    let makeNotiIds = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      allstudentList.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
      // generateSalary()
      setselectedStudents([]);
      setselectedNotificationId([]);
    } else {
      setcheckallcheckbox(true);
      allstudentList.map((item, i) => {
        item.checked = true;
        staffarray.push(item.id);
        makeNotiIds.push(item.notification_id);
        makeNoti.push({
          id: item.notification_id,
          name: item.name,
        });
        middlearray.push(item);
      });
      setselectedStudents([...staffarray]);
      setselectedNotificationId([...makeNotiIds]);
      setNotificationStudents([...makeNoti]);

      // generateSalary()
    }
    // console.log(selectedcampusstaff);
    setallstudentsList([...middlearray]);
  };

  const openModal = () => {
    if (selectedStudents.length <= 0) {
      alert.error("Please select students");
    } else {
      setSendNotification(true);
      setSendMessage(true);
      setattendModal(true);
    }
  };

  const LIST_ATTEN = [
    {
      id: 0,
      value: "Absent",
      checked: false,
    },
    {
      id: 1,
      value: "Sick",
      checked: false,
    },
    {
      id: 3,
      value: "Leave",
      checked: false,
    },
    {
      id: 4,
      value: "Custom SMS",
      checked: false,
    },
    {
      id: 5,
      value: "Late Coming Students",
      checked: false,
    },
    {
      id: 6,
      value: "Home Work Not Done",
      checked: false,
    },
    {
      id: 7,
      value: "In Proper Uniform",
      checked: false,
    },
    {
      id: 8,
      value: "Test Not Prepared",
      checked: false,
    },
  ];

  const MarkStudentAttendance = () => {
    console.log(selectedStudents);
    if (date == "") {
      toast.error("Please select Date");
    } else {
      setConfirmModal(false);
      // setLoading2(true)
      setattendModal(false);
      setselectedStudents("");
      setselectedNotificationId("");
      const data = {
        year_id: currentsession,
        student_ids: selectedStudents,
        attendance_status_id: selectAttendace,
        date: date,
        mobile_sms: sendMessage ? 1 : 0,
        message: enableMessage ? studentMessage : null,
      };

      fetch(`${baseUrl}/mark_students_absent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            alert.success(data.metadata.message);

            if (sendNotification) {
              console.log("noti send");
              if (enableMessage) {
                sendNotificationsInBatches(
                  "Attendance Alert",
                  message,
                  selectedNotificationID
                );
              } else {
                sendNotificationsInBatches3(
                  selectAttendace,
                  notificationStudents
                );
                // switch (selectAttendace) {
                //   case "1":
                //     sendNotificationsInBatches(
                //       "Attendance/Discipline Alert",
                //       "Dear Parents your child is absent from school today without any information.",
                //       selectedStudents
                //     );
                //     break;

                //   case "2":
                //     sendNotificationsInBatches(
                //       "Attendance Alert",
                //       "Dear Parents get well soon. We hope that he/she will attend the school at earliest.",
                //       selectedStudents
                //     );
                //     break;
                //   case "3":
                //     sendNotificationsInBatches(
                //       "Attendance Alert",
                //       "Dear Parents Leave Application of your childern has been received in the school office and approved.",
                //       selectedStudents
                //     );
                //     break;
                //   case "4":
                //     sendNotificationsInBatches(
                //       "Attendance Alert",
                //       "Dear Parents your child  are consistently arriving late to school. Kindly pay attention on your child.",
                //       selectedStudents
                //     );
                //     break;

                //   case "5":
                //     sendNotificationsInBatches(
                //       "Attendance Alert",
                //       "Dear Parents your child  home work is not done. Kindly pay attention on your child",
                //       selectedStudents
                //     );
                //     break;

                //   case "6":
                //     sendNotificationsInBatches(
                //       "Attendance Alert",
                //       "Dear Parents your child is not in proper uniform. Please send your child in proper uniform",
                //       selectedStudents
                //     );
                //     break;

                //   case "7":
                //     sendNotificationsInBatches(
                //       "Attendance Alert",
                //       "Dear Parents your child test is not prepared. PLease help your child in studies.",
                //       selectedStudents
                //     );
                //     break;
                // }
              }
            }
          } else {
            setLoading2(false);
            alert.error(data.metadata.message);
          }

          console.log(data);
        })
        .catch((err) => {
          setLoading2(false);
          console.log(err);
        });
      setLoading2(false);
      setenableMessage(false);
      setSendMessage(true);
      setattendModal(false);
      setenableMessage(false);
      setSendNotification(true);
      let checkboxes = allstudentList;
      allstudentList.map((item, index) => {
        item["checked"] = false;
        checkboxes[index] = item;
      });
      setallstudentsList([...checkboxes]);
      setselectedStudents("");
      setselectedNotificationId("");
      setcheckallcheckbox(false);
    }
  };

  const dataSearch = allstudentList.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.name?.toLowerCase().includes(text) ||
          user?.admission_id?.toLowerCase().includes(text)
      : true;
  });
  const onChackbox = (checked) => {
    console.log(checked);
    if (checked == true) {
      setselectAttendace("");
    }
    setenableMessage(checked);
  };
  console.log(notificationStudents, "here it is");

  const handleChange = (event) => {
    const inputMessage = event.target.value;
    const currentCharacterCount = inputMessage.length;
    const currentMessageCount = Math.ceil(currentCharacterCount / 160);

    setStudentMessage(inputMessage);
    setCharacterCount(currentCharacterCount);
    setMessageCount(currentMessageCount);
  };

  return (
    <div>
      <Toaster />
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-8">
            <button onClick={openModal} className="btn btn-primary">
              Mark Attendance
            </button>
          </div>
          <div className="col-md-4">
            <input
              className="form-control"
              onChange={(e) => setsearchtext(e.target.value)}
              placeholder="Enter Name of Student or Admission Id"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => setcurrentsession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option
                  key={index}
                  //   selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedclass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Class
              </option>
              {classes.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-3 mt-3">
            <label>
              <b>Select Section</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedSection}
              onChange={(e) => getChallans(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Section
              </option>
              {allsections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <Table responsive>
          <thead>
            <tr id="tbl">
              <th>Admission ID</th>
              <th>Student Name</th>
              <th>Father Name</th>
              {/* <th>CNIC/ B FORM</th> */}
              {/* <th>Delete</th> */}
              {/* <th>SignUp/Reset</th> */}
              <th>
                <input
                  onClick={(e) => {
                    allcheckboxChecked();
                  }}
                  checked={checkallcheckbox}
                  style={{}}
                  placeholder="Checkbox"
                  type="checkbox"
                />{" "}
                <span style={{ marginleft: "15px" }}>Check All</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataSearch &&
              dataSearch.map((student, index) => (
                <tr>
                  <td className="text_align_start ">{student.admission_id}</td>
                  <td className="text_align_start ">{student.name}</td>
                  <td className="text_align_start ">{student.father_name}</td>

                  <td style={{ textAlign: "start" }}>
                    <input
                      key={student.id}
                      onClick={(e) => {
                        checkBoxClick(e, student.id);
                      }}
                      value={allcheckboxes[index]}
                      checked={student.checked}
                      type="checkbox"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>
      </Paper>

      <Modal size="xl" onHide={() => setattendModal(false)} show={attendModal}>
        <Modal.Header>
          <Modal.Title>Mark Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="d-flex">
                <input type="checkbox" style={{ display: "none" }} />
                <label
                  style={{ width: "35px" }}
                  className="checkbox checkbox-single"
                >
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      // setSendMessage(e.target.c)
                      setSendMessage(e.target.checked);
                      // checkBoxClick(e, student.id);
                    }}
                    checked={sendMessage}
                    // value={allcheckboxes[index]}
                  />
                  <span />
                </label>
                <span>
                  <b>Send Message</b>
                </span>
              </div>
              <div className="d-flex mx-3">
                <input type="checkbox" style={{ display: "none" }} />
                <label
                  style={{ width: "35px" }}
                  className="checkbox checkbox-single"
                >
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      // setSendMessage(e.target.c)
                      setSendNotification(e.target.checked);
                      // checkBoxClick(e, student.id);
                    }}
                    checked={sendNotification}
                    // value={allcheckboxes[index]}
                  />
                  <span />
                </label>
                <span>
                  <b>Send Notification</b>
                </span>
              </div>
            </div>
            <div className="d-flex" style={{ fontSize: "14px" }}>
              <span>
                <b>Remaining Messages:</b> {remainingMessage}
              </span>
            </div>
          </div>
          {/* <Switch
        size="default"
          checkedChildren="Message" 
          unCheckedChildren="Notification" 
                  checked={messageOrNotification}
                  className="mx-2"
                  onChange={(check)=>setmessageOrNotification(check)}
                /> */}
          <Divider />
          <div className="row">
            {statusList &&
              statusList.map((item, index) => (
                <div className="col-md-3 align-items-center" key={item.id}>
                  <input
                    onChange={(e) => {
                      setenableMessage(false);
                      setselectAttendace(e.target.value);
                    }}
                    className="mt-3"
                    type={"radio"}
                    checked={item.id == selectAttendace}
                    value={item.id}
                    id="attendance"
                  />
                  <label for="attendance">{item.name}</label>
                </div>
              ))}
          </div>

          <div className="row">
            <div className="col-md-3 mt-3">
              <input
                className="form-control"
                onChange={(e) => setdate(e.target.value)}
                type={"date"}
              />
            </div>
            <div className="col-md-6 mt-3">
              <div className="d-flex">
                <label className="mt-3">Custom Message</label>

                <Switch
                  checked={enableMessage}
                  className="mx-2"
                  onChange={onChackbox}
                />
              </div>
            </div>
          </div>

          {enableMessage ? (
            <div className="row mt-6">
              <div className="col-md-12">
                <textarea
                  rows="3"
                  placeholder="Write the Message..."
                  value={studentMessage}
                  onChange={handleChange}
                  style={{ width: "100%", padding: "5px" }}
                ></textarea>
                <div
                  className="d-flex justify-content-between"
                  style={{ fontSize: "14px" }}
                >
                  <span>
                    <b>Character Count:</b> {characterCount}
                  </span>
                  <span>
                    <b>Message Count:</b> {messageCount}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => {
              setattendModal(false);
              setenableMessage(false);
            }}
            className="btn btn-danger"
          >
            Cancel
          </button>
          {loading2 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <button
              onClick={() => setConfirmModal(true)}
              className="btn btn-primary"
            >
              Mark
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Mark Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure You Want To Mark Attendance</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={MarkStudentAttendance}>
            Mark
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MarkAttendance;
