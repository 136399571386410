import React, { useEffect, useState } from 'react'
import { Modal, Spinner, Alert } from 'react-bootstrap'
import { baseUrl } from '../../../../../services/config'
import Select from "react-select";
import { useAlert } from 'react-alert'

const DefineLoan = ({
    visible,
    closeModal,
    employeDetails,
    reload
}) => {



    // console.log('my employe', employeDetails)
    const alert = useAlert()

    const authtoken = localStorage.getItem('userToken');

    const [loading, setloading] = useState(false)
    const [monthly_loan_installment, setmonthly_loan_installment] = useState('')
    const [loan_taken, setloan_taken] = useState('')
    const [subAccountList, setsubAccountList] = useState([])
    const [selectedSubId, setselectedSubId] = useState('')


    const [loanDetails, setloanDetails] = useState({})


    const [msg, setmsg] = useState('')

    useEffect(() => {
        getSubAccountList()
        getLoanDetails()
    }, [])



    const getLoanDetails = () => {

        const data = {
            employee_id: employeDetails.id,

        }

        fetch(`${baseUrl}/loan_employee_show`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`,

            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then((data) => {
                console.log("get loan details", data)
                if (data.metadata.success) {
                    setloanDetails(data.payload)
                    setmonthly_loan_installment(data.payload.monthly_loan_installment)
                    setloan_taken(data.payload.loan_taken)
                    setselectedSubId(data.payload.sub_account_id)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }






    const getSubAccountList = () => {
        console.log('geeting list')
        fetch(`${baseUrl}/show_employee_loan_accounts`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authtoken}`,
            }
        }).then(res => res.json())
            .then(data => {
                console.log("list", data)
                setsubAccountList(data.payload)
            }).catch(err => {
                console.log(err)
            })
    }


    const SubmitLoan = () => {

        if (selectedSubId == "" || monthly_loan_installment == "") {
            alert.error('Please fill all fields')
        } else {
            setloading(true)
            const data = {
                employee_id: employeDetails.id,
                sub_account_id: selectedSubId,
                // loan_taken,
                monthly_loan_installment
            }

            console.log(data)

            fetch(`${baseUrl}/laon_installment_against_salary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authtoken}`,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(data => {
                    console.log("define loan payload", data)
                    if (data.metadata.success) {
                        setloading(false)
                        alert.success('Loan defined')
                        getLoanDetails()
                        closeModal()
                    } else {
                        alert.error(`Something went wrong!`)
                        setloading(false)


                    }

                }).catch(err => {
                    console.log(err)
                    setloading(false)

                })
        }

    }

    const subAccountlise = [{ value: "", label:"Select" }];
    subAccountList.map((item, index) => {
      subAccountlise[index+1] = { value: item.id, label: `${item.title}-${item.acode}`};
    });
    return (
        <Modal
            backdrop="static"
            show={visible}
            size="lg"
        >
            <Modal.Header>

                <Modal.Title>Define Salary Loan</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                {/* {
                    loanDetails &&
                    <div>
                        <Alert variant={'info'}>
                            Previous Loan
                        </Alert>

                        <div className='row'>
                            <div className='col-md-4'>
                                <b>Loan Taken:{loanDetails?.loan_taken}</b>

                            </div>
                            <div className='col-md-4'>
                                <b>Monthly Loan Installment:{loanDetails?.monthly_loan_installment}</b>

                            </div>
                            <div className='col-md-4'>
                                <b>Loan Taken Date:{loanDetails?.loan_taken_date}</b>
                            </div>
                        </div>
                    </div>
                } */}

                <div className='mt-5 row'>
                <div className="col-lg-12">
                                    <label>Staff Loan Account</label>
                                    <Select
                                      defaultValue={{ value: loanDetails?.subAccount?.id, label: `${loanDetails?.subAccount?.title}-${loanDetails?.subAccount?.acode}`}}
                                    //   className="input"
                                    onChange={(e) => setselectedSubId(e.value)}
                                      placeholder="Select Vehicle"
                                      options={subAccountlise}
                                    />
          
                                  </div>
                    {/* <label>Staff Loan Account</label>
                    <select
                        defaultValue={loanDetails?.subAccount?.id}
                        defaultChecked={loanDetails?.subAccount?.id}
                        onChange={(e) => setselectedSubId(e.target.value)}
                        className='form-control'
                    >
                        <option>Select</option>
                        {
                            subAccountList.map((item) => (
                                <option value={item.id} key={item.id}>{`${item.title}-${item.acode}`}</option>
                            ))
                        }
                    </select> */}
                </div>

                <div className='row mt-3'>

                    <div className='col-md-6'>
                        <label>Loan balance</label>
                        <input
                            defaultValue={loanDetails.remaining_amount}
                            className='form-control'
                            placeholder='Loan balance'
                            disabled={true}
                            // onChange={(e) => setloan_taken(e.target.value)}
                        />
                    </div>
                    <div className='col-md-6'>
                        <label>Monthly Loan Installment</label>

                        <input
                            defaultValue={loanDetails.monthly_loan_installment}

                            className='form-control'
                            onChange={(e) => setmonthly_loan_installment(e.target.value)}
                            placeholder='Monthly Loan Installment'
                        />

                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    loading ?
                        <Spinner animation='border' />
                        :
                        <>
                            <button
                                onClick={closeModal}
                                className='btn btn-danger'>
                                Cancel
                            </button>
                            <button
                                onClick={SubmitLoan}
                                className='btn btn-primary'>
                                Define
                            </button>
                        </>

                }
            </Modal.Footer>
        </Modal>
    )
}

export default DefineLoan