import React, { useState, useEffect } from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../services/config";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
  Select,
} from "../../_metronic/_partials/controls";
import { values } from "lodash-es";
import IssueCardModel from "./IssueCardModel";
import { upperCase } from "lodash";

const StudentCard = ({ onClose, student }) => {
  //alert
  const alert = useAlert();
  const [cardIsuue, setCardIsuue] = useState(false);
  const [showCardModel, setShowCardModel] = useState(true);
  //react router dom

  const history = useHistory();

  //store data
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );

  //states

  const [isformsubmitted, setisformsubmitted] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [errorcreate, seterrorcreate] = useState("All Fields Are Required");
  const [issue_at, setissue_at] = useState("");
  const [test_date, settest_date] = useState("");
  const [test_time, settest_time] = useState("");
  const [interview_date, setinterview_date] = useState("");
  const [campus_id, setcampus_id] = useState(Number);
  const authtoken = localStorage.getItem("userToken");
  const [campus, setcampus] = useState([]);
  const [cardData, setCardDate] = useState({});
  const [printModel, setprintmodal] = useState(false);

  const [errorsLis, setErrorsLis] = useState("");
  const user = JSON.parse(localStorage.getItem("userDetails"));

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();

    fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setcampus(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const genrateCard = (values) => {
    setisallfields(false);
    const data = {
      campus_id: values.select_cmapus,
      student_id: student.id,
      issue_at: values.issue_at,
      test_date: values.test_date,
      test_time: values.test_time,
      interview_date: values.interview_date,
      status: "0",
    };
    console.log("student card", data);
    fetch(`${baseUrl}/registrationcard`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          if (data.metadata.success) {
            setCardDate(data.payload);
            alert.show("Card genrated successfully");
            console.log(data);
            setCardIsuue(true);
          }
        });
      })
      .catch((err) => {
        alert.show("Somthing went wrong", err);
        console.log(err);
      });
  };

  const StudentRegistrationSchema = Yup.object().shape({
    select_cmapus: Yup.string().required("Campus is required"),
    interview_date: Yup.string().required("Interview Date is required"),

    test_time: Yup.string().required("Test Time is required"),

    test_date: Yup.string().required("Test Date is required"),

    issue_at: Yup.string().required("Issue Date is required"),
  });
  const initialValues = {
    select_cmapus: "",
    interview_date: red_recevingdate,
    test_time: "",
    test_date: red_recevingdate,
    issue_at: red_recevingdate,
    button_submit: "",
  };
  const createEroors = (errors, touched, values, isSubmitting) => {
    setisallfields(false);
    if (Object.keys(errors).length === 0) {
      if (values.interview_date == "") {
      } else {
        if (isSubmitting) {
          if (!isformsubmitted) {
            genrateCard(values);
            setisformsubmitted(true);
          }
        }
      }

      return;
    }

    if (errors.select_cmapus && touched.select_cmapus) {
      seterrorcreate(errors.select_cmapus);
      setisallfields(true);
      return;
    }

    if (errors.interview_date && touched.interview_date) {
      seterrorcreate(errors.interview_date);
      setisallfields(true);
      return;
    }
    if (errors.test_time && touched.test_time) {
      seterrorcreate(errors.test_time);
      setisallfields(true);
      return;
    }
    if (errors.test_date && touched.test_date) {
      seterrorcreate(errors.test_date);
      setisallfields(true);
      return;
    }
    if (errors.issue_at && touched.issue_at) {
      seterrorcreate(errors.issue_at);
      setisallfields(true);
      return;
    }
  };

  const issuenow = async (item) => {
    const data = {
      campus_id: cardData.campus_id,
      id: cardData.id,
      interview_date: cardData.interview_date,
      issue_at: cardData.issue_at,
      status: "1",
      student_id: cardData.student_id,
      test_date: cardData.test_date,
      test_time: cardData.test_time,
    };
    const id = cardData.id;
    await fetch(`${baseUrl}/registrationcard/${id}?_method=PUT`, {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.show("Card Issued successfully");
            // onchangefunc(campus_id)
            setCardIsuue(false);
            setprintmodal(true);
          } else {
            alert.show("Somthing went Wrong");
          }
          // console.log('issue now log', data)
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };
  const printdoc = () => {
    var doc = document.getElementById("voucher");
    var WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(doc.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  };

  return (
    <>
      <div class="row d-flex justify-content-center">
        <div class="col-xl-10 col-lg-10 col-md-10 col-10 text-center">
          {/* <div class="generate text-center">Generate Admit Card</div> */}
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={StudentRegistrationSchema}
            // onSubmit={(values) => {
            //     genrateCard(values)
            //     //  AddStudentHandler(values)
            // }}
          >
            {(props) => {
              const {
                handleSubmit,
                errors,
                touched,
                values,
                isSubmitting,
                isValid,
                handleChange,
                handleBlur,
              } = props;

              setErrorsLis(errors);
              createEroors(errors, touched, values, isSubmitting);

              return (
                <>
                  <Form
                    onSubmit={handleSubmit}
                    className="form form-label-right"
                  >
                    {/* <form class="form-card" onsubmit={((e) => { e.preventDefault() })}> */}
                    <div class="row justify-content-between text-left">
                      <div class="form-group col-sm-12 flex-column d-flex">
                        <label>
                          Student Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          style={{ backgroundColor: "#F3F6F9" }}
                          disabled
                          defaultValue={student.name}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <div class="form-group col-sm-12 flex-column d-flex">
                        <label>
                          Father Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          disabled
                          defaultValue={student.father_name}
                          style={{ backgroundColor: "#F3F6F9" }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div class="form-group col-sm-6 flex-column d-flex pt-3">
                        <label class="form-control-label">
                          Campus <span style={{ color: "red" }}>*</span>
                        </label>
                        {/* <Field
                                                name="test_time"
                                                type="select"
                                                component={Input}
                                                placeholder="Enter Interview Date"
                                            /> */}
                        <select
                          // onChange={(e) => setcampus_id(e.target.value)}
                          class="form-select"
                          name="select_cmapus"
                          value={values.select_cmapus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ backgroundColor: "white" }}
                          aria-label="Default select example"
                        >
                          <option selected value="">
                            Select Campus
                          </option>
                          {campus.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {errors.select_cmapus && touched.select_cmapus && (
                          <div
                            style={{ color: "#f87d8a" }}
                            className="input-feedback"
                          >
                            {errors.select_cmapus}
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <h5>Entry Test Detail</h5>
                    </div>
                    <div class="row justify-content-between text-left">
                      <div class="form-group col-sm-6 flex-column d-flex">
                        <label class="form-control-label">
                          Test Date <span style={{ color: "red" }}>*</span>
                        </label>
                        {/* <input onChange={(e) => settest_date(e.target.value)} type="date" class="form-control placeholder-bg mb-2" /> */}
                        <Field
                          name="interview_date"
                          type="date"
                          component={Input}
                          max="9999-11-30"
                          placeholder="Enter Interview Date"
                        />

                        <label class="form-control-label">
                          Time <span style={{ color: "red" }}>*</span>
                        </label>
                        {/* <input onChange={(e) => settest_time(e.target.value)} type="time" class="form-control placeholder-bg mb-2" /> */}
                        <Field
                          name="test_time"
                          type="time"
                          component={Input}
                          placeholder="Enter Interview Date"
                        />

                        <label class="form-control-label">
                          Issue Date <span style={{ color: "red" }}>*</span>
                        </label>
                        {/* <input onChange={(e) => setissue_at(e.target.value)} type="date" class="form-control placeholder-bg mb-2" /> */}
                        <Field
                          name="test_date"
                          type="date"
                          max="9999-11-30"
                          component={Input}
                          placeholder="Enter Interview Date"
                        />
                      </div>
                      <br />
                      <div class="form-group col-sm-6 flex-column d-flex">
                        <label class="form-control-label">
                          Interview Date <span style={{ color: "red" }}>*</span>
                        </label>
                        {/* <input onChange={(e) => setinterview_date(e.target.value)} type="date" class="form-control placeholder-bg mb-2" /> */}
                        <Field
                          name="issue_at"
                          type="date"
                          max="9999-11-30"
                          component={Input}
                          placeholder="Enter Interview Date"
                        />
                      </div>
                    </div>
                  </Form>
                  {isallfields ? (
                    <>
                      <Alert
                        variant="danger"
                        onClose={() => setisallfields(false)}
                        dismissible
                      >
                        <Alert.Heading style={{ textAlign: "start" }}>
                          Oh snap! You got an error!
                        </Alert.Heading>
                        <p style={{ textAlign: "start" }}>{errorcreate}</p>
                      </Alert>
                    </>
                  ) : null}

                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    class="row btns"
                  >
                    <div class="col-md-7 col-sm-7">
                      <button
                        onClick={() => handleSubmit()}
                        type="submit"
                        value="Submit"
                        style={{
                          backgroundColor: "#121291",
                          color: "#fff",
                          width: "140px",
                        }}
                        className="btn"
                      >
                        Generate Card
                      </button>
                      <button
                        style={{ marginLeft: "15px" }}
                        className="btn btn-danger btn_width col-md-6"
                        onClick={(e) => {
                          e.preventDefault();
                          onClose(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
          {/* </form> */}
        </div>
      </div>

      <Modal show={cardIsuue} size="md">
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Issue Now
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IssueCardModel des="Do you want to issue it" />
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setCardIsuue(false)}
            className="btn btn-danger btn_width"
          >
            Close
          </button>
          <button onClick={issuenow} className="btn btn-primary btn-elevate">
            Issue Now
          </button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={printModel}>
        <div id="voucher">
          <Modal.Body>
            <div className="App">
              <h4 style={{ color: "#121291" }}>{unitName}</h4>
              <div className="stu-header text-center">
                <h4>Student Admit Card</h4>
              </div>
              <br />
              <br />
              <table
                border="1px"
                style={{ width: "500px", marginLeft: "100px" }}
              >
                <tbody>
                  <tr>
                    <td align="left" style={{ width: "174.5px" }}>
                      Student Name
                    </td>
                    <td align="right" style={{ width: "250.5px" }}>
                      {cardData?.student?.name}
                    </td>
                    <td align="left" style={{ width: "174.5px" }}>
                      Registration Id
                    </td>
                    <td align="right" style={{ width: "250.5px" }}>
                      {cardData.student?.registration_id}
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style={{ width: "174.5px" }}>
                      Campus Name
                    </td>
                    <td align="right" style={{ width: "250.5px" }}>
                      {cardData?.campus?.name}
                    </td>
                    <td align="left" style={{ width: "174.5px" }}>
                      Class Name
                    </td>
                    <td align="right" style={{ width: "250.5px" }}>
                      {cardData?.student?.student_class_name}
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style={{ width: "174.5px" }}>
                      Issue At
                    </td>
                    <td align="right" style={{ width: "250.5px" }}>
                      {cardData.issue_at}
                    </td>
                    <td align="left" style={{ width: "174.5px" }}>
                      Test Date
                    </td>
                    <td align="right" style={{ width: "250.5px" }}>
                      {cardData.test_date}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" style={{ width: "174.5px" }}>
                      Test Time
                    </td>
                    <td align="right" style={{ width: "250.5px" }}>
                      {cardData.test_time}
                    </td>
                    <td align="left" style={{ width: "174.5px" }}>
                      Section
                    </td>
                    <td align="right" style={{ width: "250.5px" }}>
                      {cardData?.student?.global_section_name}
                    </td>
                  </tr>
                </tbody>
              </table>
              <b style={{ width: "200px", display: "table", margin: "50px" }}>
                <span style={{ display: "table-cell", width: "100px" }}>
                  Signature:
                </span>
                <span
                  style={{
                    display: "table-cell",
                    borderBottom: "1px solid black",
                  }}
                ></span>
              </b>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <button
            style={{ cursor: "pointer" }}
            onClick={printdoc}
            className="btn btn-success button_blue"
          >
            Print
          </button>
          <button
            className="btn btn-danger btn_width  pl-4 pr-4 ml-3"
            onClick={() => onClose(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StudentCard;
