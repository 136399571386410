import React, { useState, useEffect } from 'react'

import { Input, DatePickerField, Select } from "../../../../_metronic/_partials/controls"; import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { baseUrl } from '../../../../services/config'
import { Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'


import { useFormik, Field, Form, Formik, ErrorMessage } from 'formik'

import * as Yup from 'yup'

const EditBank = ({ bankDetails, show, onHide, reload }) => {
    // console.log(bankDetails)
    const alert = useAlert()
    const [name, setname] = useState(bankDetails.name == undefined ? "" : bankDetails.name)
    const [branch_codes, setbranch_codes] = useState(bankDetails.branch_codes == undefined ? "" : bankDetails.branch_codes)
    const [account_title, setaccount_title] = useState(bankDetails.account_title == undefined ? "" : bankDetails.account_title)
    const [account_no, setaccount_no] = useState(bankDetails.account_no == undefined ? "" : bankDetails.account_no)
    const [branch, setbranch] = useState(bankDetails.branch == undefined ? "" : bankDetails.branch)
    const [loading, setloading] = useState(false)
    //const authtoken = useSelector(state => state.user.authtoken)
    const authtoken = localStorage.getItem('userToken')

    const [allbankgroups, setallbankgroups] = useState([])
    const [allsubaccounts, setallsubaccounts] = useState([])
    const history = useHistory()

    useEffect(() => {


        getAllBankGroupsList()
        getAlSubAccountsList()

    }, [])

    const getAlSubAccountsList = (async () => {


        await fetch(`${baseUrl}/sub_account_only_banks`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authtoken}`
            }
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                if (data.metadata) {
                    if (data.metadata.success) {
                        setallsubaccounts(data.payload)
                    } else {
                    }
                } else {
                }
            })
            .catch((err) => {
                console.log("sub accounts", err)
            })


    })


    const getAllBankGroupsList = (async () => {
        // bank_account_category
        // sub_account_only_banks
        // bank_account


        await fetch(`${baseUrl}/bank_account_category`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authtoken}`
            }
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                if (data.metadata) {
                    if (data.metadata.success) {
                        setallbankgroups(data.payload)
                    } else {
                    }
                } else {
                }
            })
            .catch((err) => {
                console.log("bank accounts", err)
            })


    })

    const initialValues = {
        "sub_account_id": bankDetails.sub_account_id,
        "bank_name": bankDetails.bank_name,
        "bank_branch": bankDetails.bank_branch,
        "account_title": bankDetails.account_title,
        "account_number": bankDetails.account_number,
        "account_head": bankDetails.account_head,
        "bank_account_category_id": bankDetails.bank_account_category_id

    };

    const BankValidationSchema = Yup.object().shape({

        sub_account_id: Yup.string()
            .required('Sub Account Is Required'),
        bank_name: Yup.string()
            .required('Bank Name is Required'),

        bank_branch: Yup.string()
            .required('Branch Name is required'),



        account_title: Yup.string()

            .required('Account Title is Required '),

        account_number: Yup.string()
            .required('Account Number is Required '),

        account_head: Yup.string()
            .required('Account Head is Required '),
        bank_account_category_id: Yup.string()
            .required('Bank Account Category is Required '),
    });

    const onChangeBankGroup = ((e, setFieldValue) => {
        let get_val = e.target.selectedOptions[0].getAttribute("titel");
        setFieldValue("account_title", get_val)
    })
    const onChangeSubAccount = ((e, setFieldValue) => {
        let get_val = e.target.selectedOptions[0].getAttribute("acode");
        setFieldValue("account_head", get_val)
    })

    const EditBankHandler = (values) => {
        setloading(true)
        const data = {
            sub_account_id: values.sub_account_id,
            bank_name: values.bank_name,
            bank_branch: values.bank_branch,

            account_title: values.account_title,
            account_number: values.account_number,
            account_head: values.account_head,

            bank_account_category_id: values.bank_account_category_id,


        }

        fetch(`${baseUrl}/bank_account/${bankDetails.id}?_method=PUT`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authtoken}`
            },
            body: JSON.stringify(data)
        }).then(res => {
            console.log(res)
            onHide(false)
            setloading(false)
            if (res.ok) {
                alert.success('Bank Added Successfully')
                reload("edit")
            } else {
                alert.success('Cannot Update Bank')
            }
            // console.log(res.ok)
            // res.json()

        })
            // .then(data => {
            //     show(false)
            //     setloading(false)
            //      console.log(data)
            //      if(data.metadata){

            //          if (data.metadata.success) {


            //              alert.success('Bank Addedd Successfully')
            //              reload()

            //          }else{
            //             alert.success('Cannot Update Bank')
            //          }
            //      }
            // })

            .catch(err => {
                alert.success('Cannot Update Bank')
                onHide(false)
                console.log(err)
                setloading(false)
            })
    }

    return (




        <div>
            <div className='container-fluid padding_zero' >
                <div style={{ marginTop: "0px", border: "0px" }} className="card card-custom gutter-b ">
                    <div className="card-body padding_zero">
                        <div className='row padding_zero' >


                            <div className='col-12' >


                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={BankValidationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        EditBankHandler(values)

                                    }}
                                >
                                    {/* {(props) => {
                    const { handleSubmit, errors, touched, values, isSubmitting, isValid } = props
                    console.log(JSON.stringify(values)) */}
                                    {/* {({ handleSubmit, handleChange, handleBlur, setFieldValue, isSubmitting, isValid, errors, touched, values }) => ( */}
                                    {(props) => {
                                        const { handleSubmit, errors, setFieldValue, setValues, touched, values, resetForm, isSubmitting, isValid, setSubmitting, handleChange, handleBlur } = props
                                        return (
                                            <>

                                                {
                                                    loading ? <b>Bank Editing Please Wait {` `} <span className="spinner-border"></span></b> :

                                                        <Form
                                                        // onSubmit={handleSubmit}
                                                        >

                                                            <div className="card-body padding_zero">
                                                                <div className='container padding_zero' >


                                                                    <div >





                                                                        <div className="row">
                                                                            <div className="row mt-3">
                                                                                <div className="col-md-6">

                                                                                    <label>Select  Bank Account Group <span style={{ color: "red" }}>*</span></label>
                                                                                    <select
                                                                                        className="form-select form-control"
                                                                                        name="bank_account_category_id"
                                                                                        value={values.bank_account_category_id}

                                                                                        onChange={e => { props.handleChange(e); onChangeBankGroup(e, setFieldValue) }}

                                                                                        // onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        aria-label="Default select example"
                                                                                    >
                                                                                        <option selected disabled value=""  >
                                                                                            Select...
                                                                                        </option>
                                                                                        {
                                                                                            allbankgroups.map(item => (

                                                                                                item.id == values.bank_account_category_id ?
                                                                                                    <option titel={item.title} value={item.id}>{item.title}</option>
                                                                                                    : null
                                                                                            ))


                                                                                        }
                                                                                    </select>
                                                                                    {
                                                                                        errors.bank_account_category_id &&
                                                                                        touched.bank_account_category_id &&
                                                                                        <div style={{ color: "#f87d8a" }} className="input-feedback">
                                                                                            {errors.bank_account_category_id}
                                                                                        </div>
                                                                                    }



                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label>Select Sub Account <span style={{ color: "red" }}>*</span></label>
                                                                                    <select
                                                                                        className="form-select form-control"
                                                                                        name="sub_account_id"
                                                                                        value={values.sub_account_id}
                                                                                        onChange={e => { props.handleChange(e); onChangeSubAccount(e, setFieldValue) }}
                                                                                        onBlur={handleBlur}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        aria-label="Default select example"
                                                                                    >
                                                                                        <option selected  value=""  >
                                                                                            Select...
                                                                                        </option>
                                                                                        {
                                                                                            allsubaccounts.map(item => (
                                                                                                <option acode={item.acode} value={item.id}>{item.title}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    {
                                                                                        errors.sub_account_id &&
                                                                                        touched.sub_account_id &&
                                                                                        <div style={{ color: "#f87d8a" }} className="input-feedback">
                                                                                            {errors.sub_account_id}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-md-6">
                                                                                    <label>Bank Name <span style={{ color: "red" }}>*</span>
                                                                                    </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        value={values.bank_name}
                                                                                        name="bank_name"
                                                                                        component={Input}
                                                                                        placeholder="Bank Name"
                                                                                        maxLength={40}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label>Bank Branch <span style={{ color: "red" }}>*</span>
                                                                                    </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        value={values.bank_branch}
                                                                                        name="bank_branch"
                                                                                        component={Input}
                                                                                        placeholder="Bank Branch"
                                                                                        maxLength={40}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-md-6">
                                                                                    <label>Account Title <span style={{ color: "red" }}>*</span>
                                                                                    </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        value={values.account_title}
                                                                                        name="account_title"
                                                                                        component={Input}
                                                                                        placeholder="Account Title"
                                                                                        maxLength={40}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label>Account Number <span style={{ color: "red" }}>*</span>
                                                                                    </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        value={values.account_number}
                                                                                        name="account_number"
                                                                                        component={Input}
                                                                                        placeholder="Account Number"
                                                                                        maxLength={40}
                                                                                    />
                                                                                </div>

                                                                                <div className="row mt-3">
                                                                                    <div className="col-md-6">
                                                                                        <label>Account Head <span style={{ color: "red" }}>*</span>
                                                                                        </label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            value={values.account_head}
                                                                                            name="account_head"
                                                                                            component={Input}
                                                                                            placeholder="Account Head"
                                                                                            maxLength={40}
                                                                                        />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                }
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <button className="btn btn-success button_blue button_blue" onClick={() => handleSubmit()} type="submit" value="Submit">
                                                        {
                                                            loading ? <span className="spinner-border"></span> : <>Submit</>
                                                        }
                                                    </button>
                                                    <button onClick={() => {


                                                        onHide(false)

                                                    }
                                                    }
                                                        className="btn btn-danger btn_width ml-3" >Cancel</button>
                                                </div>
                                            </>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        // <div className='container-fluid padding_zero' >
        //     {/* <div className="generate text-center">Edit Bank Detail and Submit</div> */}
        //     <div style={{ paddingTop: "0px" }} className=" ">
        //         <div>
        //             {
        //                 loading ? <b>Editing Please wait <span className="spinner-border"></span></b>
        //                     :
        //                     <>
        //                         <div className="row">
        //                             <div className="col-md-6">
        //                                 <label>Bank Name
        //                                     {/* <span style={{ color: "red" }}>*</span> */}
        //                                 </label>
        //                                 <input value={name} onChange={(e) => setname(e.target.value)} placeholder="Bank Name" className="form-control" type="text" />
        //                             </div>
        //                             <div className="col-md-6">
        //                                 <label>Branch Name
        //                                     {/* <span style={{ color: "red" }}>*</span> */}
        //                                 </label>
        //                                 <input value={branch} onChange={(e) => setbranch(e.target.value)} placeholder="Branch Name" className="form-control" type="text" />
        //                             </div>
        //                         </div>
        //                         <div className="row">
        //                             <div className="col-md-6">
        //                                 <label>Account Title
        //                                     {/* <span style={{ color: "red" }}>*</span> */}
        //                                 </label>
        //                                 <input value={account_title} onChange={(e) => setaccount_title(e.target.value)} placeholder="Account Title" className="form-control" type="text" />
        //                             </div>
        //                             <div className="col-md-6">
        //                                 <label>Account No
        //                                     {/* <span style={{ color: "red" }}>*</span> */}
        //                                 </label>
        //                                 <input value={account_no} onChange={(e) => setaccount_no(e.target.value)} placeholder="Account No" className="form-control" type="text" />
        //                             </div>
        //                         </div>
        //                         <div className="row">
        //                             <div className="col-md-6">
        //                                 <label>Branch Code
        //                                     {/* <span style={{ color: "red" }}>*</span> */}
        //                                 </label>
        //                                 <input value={branch_codes} onChange={(e) => setbranch_codes(e.target.value)} placeholder="Branch Code" className="form-control" type="text" />
        //                             </div>

        //                         </div>
        //                     </>
        //             }
        //             <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //                 <button
        //                     onClick={UpdateBank}
        //                     className="btn btn-success button_blue ml-3 button_blue"
        //                 >
        //                     {
        //                         loading && <span className="spinner-border-sm">
        //                         </span>
        //                     }
        //                     Update
        //                 </button>
        //                 <button
        //                     className="btn btn-danger btn_width ml-3"
        //                     onClick={() => show(false)}>
        //                     Cancel
        //                 </button>

        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default EditBank
