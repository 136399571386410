import React, { useState } from "react";
import { MARITAL_STATUS, GENDER, DESIGNATIONS } from "../Data/Form_Data";
import { useFormik, Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormikController from "../Formik/FormikController";
import { baseUrl } from "../../../../services/config";
import { useHistory, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import jwt_token from "../../../../services/authToken";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";

const AddStaff = ({ show, onHide }) => {
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const authToken = jwt_token();

  const cnicNumberMask = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
  ];

  const initValues = {
    applied_for: "",
    full_name: "",
    father_name: "",
    nationality: "",
    religion: "",
    experience: "",
    cnic_no: "",
    qualification: "",
    gender: "",
    marital_status: "",
    dob: "",
    remarks: "",
    mobile_no: "",
    phone: "",
    address: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  const AddStaffHandler = (data) => {
    console.log(data);

    const fdata = {
      campus_id: user.campus.id,
      applied_for: data.applied_for,
      full_name: data.full_name,
      father_name: data.father_name,
      nationality: data.nationality,
      religion: data.religion,
      experience: data.experience,
      cnic_no: data.cnic_no,
      qualification: data.qualification,
      gender: data.gender,
      marital_status: data.marital_status,
      dob: data.dob,
      remarks: data.remarks,
      mobile_no: data.mobile_no,
      phone: data.phone,
      address: data.address,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
    };

    fetch(`${baseUrl}/staffmember`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          if (data.metadata.success) {
            alert.show("Staff Created Successfully");
            history.push("/staff");
          }
        })
      )
      .catch((err) => {
        console.log(err);
        history.push("/staff");
      });
  };

  const StaffValidationSchema = Yup.object().shape({
    applied_for: Yup.string()
      .required("Required")
      .oneOf(DESIGNATIONS),
    gender: Yup.string()
      .required("Required")
      .oneOf(GENDER),
    marital_status: Yup.string()
      .required("Required")
      .oneOf(MARITAL_STATUS),
    dob: Yup.string().required("Required"),

    full_name: Yup.string().required("Name Is Required"),

    email: Yup.string()
      .email("Wrong email format")
      .required("Email Is Required"),

    father_name: Yup.string().required("Father  Name is Required"),

    nationality: Yup.string().required("Required"),
    cnic_no: Yup.string()
      .required("Cnic No required field")
      .matches(
        /^[0-9]{5}-[0-9]{7}-[0-9]$/,
        "CNIC No must follow the XXXXX-XXXXXXX-X format!"
      ),
    religion: Yup.string().required("Required "),
    qualification: Yup.string().required("Enter Your Qualification "),

    // remarks: Yup.string().required("Required "),

    mobile_no: Yup.number()
      .required("Enter Your Contact No  ")
      .matches(
        /^(3)([0-9]{9})$/,
        "Mobile Number Must start with 3 and must have 13 digits"
      ),
    phone: Yup.number()
      .min(3, "Minimum 3 symbols")
      .required("Required ")
      .matches(
        /^(3)([0-9]{9})$/,
        "Phone Number Must start with 3 and must have 13 digits"
      ),
    address: Yup.string().required("Propperly Mention Your Adress #xvzblock "),
    experience: Yup.string().required("required"),
  });

  const FORMGROUP = ({ children }) => {
    return <div className="form-group row">{children}</div>;
  };

  return (
    <Modal
      size="lg"
      show={show}
      // onHide={() => setmodalvisible(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="container-fluid">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">New Staff |</h3>
              <small>Enter Staff details and submit</small>
            </div>
          </div>
          <div className="card-body">
            <Formik
              initialValues={initValues}
              validationSchema={StaffValidationSchema}
              onSubmit={(values, { setSubmitting }) => {
                AddStaffHandler(values);
                history.push("/staff");

                //  VFGFCXZXCVFGFCXC VBCXV
              }}
            >
              {(props) => {
                const {
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  isSubmitting,
                  isValid,
                } = props;
                // console.log(JSON.stringify(values) )

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="container">
                        <div className="info-group">
                          <FORMGROUP>
                            <FormikController
                              name="applied_for"
                              control="select"
                              type="select"
                              colCount="col-12"
                              options={DESIGNATIONS}
                              label="Applied For"
                            />
                            {/* <FormikController 
                                     name='designationyear'
                                     control='date'
                                     type='date'
                                     colCount='col-4'
                                     label='Applied Date'
                                   /> */}
                          </FORMGROUP>

                          <FORMGROUP>
                            <FormikController
                              name="full_name"
                              control="input"
                              type="input"
                              colCount="col-6"
                              label="Full Name"
                            />
                            <FormikController
                              name="father_name"
                              control="input"
                              type="input"
                              colCount="col-6"
                              label="Father Name"
                            />
                          </FORMGROUP>

                          <FORMGROUP>
                            <FormikController
                              name="nationality"
                              control="input"
                              type="input"
                              colCount="col-6"
                              label="Nationality"
                            />

                            <FormikController
                              name="religion"
                              control="input"
                              type="input"
                              colCount="col-6"
                              label="Religion"
                            />
                          </FORMGROUP>

                          <FORMGROUP>
                            <FormikController
                              name="experience"
                              control="input"
                              type="input"
                              colCount="col-6"
                              label="Experience"
                            />

                            {/* <FormikController
                              name='cnic_no'
                              control='input'
                              type='input'
                              colCount='col-6'
                              label='CNIC'
                            /> */}
                            <Field
                              name="cnic_no"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={cnicNumberMask}
                                  id="cnic_no"
                                  placeholder="Enter Your CNIC"
                                />
                              )}
                            />
                          </FORMGROUP>
                          <FORMGROUP>
                            <FormikController
                              name="qualification"
                              control="input"
                              type="input"
                              colCount="col-6"
                              label="Qualification"
                            />

                            <FormikController
                              name="gender"
                              control="select"
                              type="input"
                              options={GENDER}
                              colCount="col-6"
                              label="Gender"
                            />
                          </FORMGROUP>
                          <FORMGROUP>
                            <FormikController
                              name="marital_status"
                              control="select"
                              type="select"
                              colCount="col-6"
                              options={MARITAL_STATUS}
                              label="Marital Status"
                            />

                            <FormikController
                              name="dob"
                              control="input"
                              type="date"
                              colCount="col-6"
                              label="DOB"
                            />
                          </FORMGROUP>
                          <FORMGROUP>
                            <FormikController
                              name="remarks"
                              control="input"
                              type="input"
                              colCount="col-12"
                              label="Remarks"
                            />
                          </FORMGROUP>
                          <div class="separator separator-dashed my-10"></div>
                          <div className="d-flex  justify-content-start my-4">
                            <div className="card-title">
                              <h3 className="card-label">Contact Info</h3>
                              <small>User Contact Information</small>
                            </div>
                          </div>
                        </div>

                        <div className="contact-group">
                          <FORMGROUP>
                            <FormikController
                              name="first_name"
                              control="input"
                              type="input"
                              colCount="col-6"
                              label="First Name"
                              placeholder="Enter your first name"
                            />
                            <FormikController
                              name="last_name"
                              control="input"
                              type="input"
                              colCount="col-6"
                              label="Last Name"
                              placeholder="Enter your last name"
                            />
                          </FORMGROUP>
                          <FORMGROUP>
                            <FormikController
                              name="email"
                              control="input"
                              type="email"
                              colCount="col-6"
                              label="Email"
                              placeholder="Enter Email Address @gmail"
                            />
                            <FormikController
                              name="password"
                              control="input"
                              type="password"
                              colCount="col-6"
                              label="Password"
                              placeholder="Enter your Password"
                            />
                          </FORMGROUP>

                          <FORMGROUP>
                            <FormikController
                              name="mobile_no"
                              control="input"
                              type="number"
                              colCount="col-6"
                              label="Mobile No"
                            />
                            <FormikController
                              name="phone"
                              control="input"
                              type="number"
                              colCount="col-6"
                              label="Phone"
                            />
                          </FORMGROUP>

                          <FORMGROUP>
                            <FormikController
                              name="address"
                              control="textarea"
                              type="input"
                              colCount="col-12"
                              label="Address"
                            />
                          </FORMGROUP>
                          <div className="d-flex  justify-content-end my-4">
                            <Link to="/staff">
                              <button
                                type="button"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                              >
                                Cancel
                              </button>
                            </Link>

                            <div>
                              <button
                                type="submit"
                                disabled={
                                  isSubmitting || Object.keys(errors).length > 0
                                }
                                className="btn btn-light-success font-weight-bold px-9 py-4 my-3 mx-4"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddStaff;
