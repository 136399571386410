import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../_metronic/_partials/controls";
import { Table, Modal, Alert } from "react-bootstrap";

const EditCampusFeeChallan = ({
  seectedchallan,
  show,
  onHide,
  campusId,
  reload,
}) => {
  //redux

  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const alert = useAlert();
  //states
  const [designations, setdesignations] = useState([]);
  const [loading, setloading] = useState(false);

  const [isformsubmitted, setisformsubmitted] = useState(false);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please fill All fields");
  const [challandetail, setchallandetail] = useState({});
  console.log(seectedchallan, "seectedchallan");
  const [feeamountarray, setfeeamountarray] = useState([]);
  const [dueDate, setdueDate] = useState(seectedchallan.due_date);
  const [challanpayableamount, setchallanpayableamount] = useState(
    parseInt(seectedchallan.payable)
  );
  useEffect(() => {
    //getChallan()
    setfeeamount();
  }, []);
  const setfeeamount = () => {
    let middlearray = [];
    seectedchallan.fee_challan_details.map((item, index) => {
      middlearray[index] = parseInt(item.amount);
    });
    setfeeamountarray(middlearray);
  };

  const getChallan = () => {
    fetch(`${baseUrl}/show_challan/${seectedchallan.challan_no}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setchallandetail(data.payload);
          } else {
            alert.show("Error Occured While Fetching Challan detail");
          }
        } else {
          alert.show("Error Occured While Fetching Challan detail");
        }
      })
      .catch((err) => {
        alert.show("Error Occured While Fetching Challan detail");
        console.log(err);
      });
  };
  const updateChallan = () => {
    let middlearray = [];
    seectedchallan.fee_challan_details.map((item, index) => {
      middlearray[index] = item.id;
    });
    let paymiddlearray = [];
    feeamountarray.map((item, index) => {
      paymiddlearray[index] = parseInt(item);
    });
    const data = {
      fee_challan_id: seectedchallan.id,
      due_date: dueDate,
      challan_detail_amounts: paymiddlearray,
      challan_payable: challanpayableamount,
      challan_detail_ids: middlearray,
    };

    setloading(true);
    console.log(data);

    fetch(`${baseUrl}/fee_challan_edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          if (data.metadata) {
            setloading(false);
            onHide(false);
            if (data.metadata.success) {
              alert.success("Updated Successfully");
              reload(campusId);
            } else {
              alert.success("Something Wemt Wrong");
            }
          } else {
            alert.success("Something Wemt Wrong");
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        onHide(false);
        alert.error("Something went wrong", err);
      });
  };

  let array123 = [];

  //start from here
  const initialValues = {
    isformsubmitted: false,
    challan_no: seectedchallan.challan_no,
    issue_date: seectedchallan.issue_date,
    due_date: seectedchallan.due_date,
    payable_amount: seectedchallan.payable,
  };
  seectedchallan.fee_challan_details.map((item, index) => {
    // let keyname="feeamunt"+index
    initialValues["feeamount" + index] = item.amount;
  });

  const StaffRegistrationSchema = Yup.object().shape({
    due_date: Yup.string().required("Due date  is required"),
  });

  const submitData = () => {
    if (showerr) {
      return;
    }
    updateChallan();
  };

  const createEroors = (errors, touched, isSubmitting, values) => {
    if (errors.due_date && touched.due_date) {
      setshowerror(errors.due_date);
      setshowerr(true);
      return;
    }

    if (Object.keys(errors).length === 0) {
      if (values.payable_amount == "") {
      } else {
        if (isSubmitting) {
          console.log(showerr);
          if (showerr) {
            return;
          }
          if (!isformsubmitted) {
            console.log(isformsubmitted);
            values.isformsubmitted = true;
            //  updateChallan(values)
          }
        }
      }
    }
  };

  const onchangeAmont = (target, index) => {
    let middlearray = feeamountarray;
    middlearray[index] = target.value;
    setshowerr(false);
    if (target.value == "") {
      setshowerror("Fee Amount Is required");
      setshowerr(true);
      return;
    }

    let sumofamount = 0;
    middlearray.map((item) => {
      console.log(item);
      sumofamount += parseInt(item);
      console.log(sumofamount);
    });
    setchallanpayableamount(sumofamount);
    setfeeamountarray(middlearray);
  };

  return (
    <div>
      {loading ? (
        <b style={{ color: "green" }}>
          Updating Fee Please Wait {`  `}{" "}
          <span className="spinner-border"></span>
        </b>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={StaffRegistrationSchema}
          onSubmit={(values) => {}}
        >
          {(props) => {
            const {
              handleSubmit,
              errors,
              touched,
              values,
              isSubmitting,
              isValid,
              handleChange,
              handleBlur,
            } = props;

            // setErrorsLis(errors)
            createEroors(errors, touched, isSubmitting, values);

            return (
              <>
                <Form onSubmit={handleSubmit} className="form form-label-right">
                  <div class="row d-flex justify-content-center">
                    <div>
                      <div class="p-3">
                        {/* <div className="text-center generate">
                                                        Edit Campus Fee Challan Detail 
                                                    </div> */}
                        <br />
                        <br />
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Challan No{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              name="challan_no"
                              component={Input}
                              disabled
                              placeholder="First Name"
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Issue Date{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              name="issue_date"
                              component={Input}
                              disabled
                              placeholder="Issue Date"
                            />
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Due Date <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            {/* <Field
                                                                name="due_date"
                                                                component={Input}
                                                                type="date"
                                                                placeholder="Due Date"
                                                            /> */}
                            <input
                              name="due_date"
                              type="date"
                              onChange={(e) => {
                                setdueDate(e.target.value);
                              }}
                              defaultValue={dueDate}
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Payable Amount{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>

                            <input
                              disabled
                              style={{ backgroundColor: "#F3F6F9" }}
                              type="number"
                              value={challanpayableamount}
                              defaultValue={challanpayableamount}
                            />
                            {/* <Field
                                                                name="payable_amount"
                                                                component={Input}
                                                                disabled
                                                                type="number"
                                                                placeholder="Payable Amount"
                                                            /> */}
                          </div>
                        </div>
                        {/* <div className="text-center">
                                                        <b>Challan Detail </b>
                                                    </div>
                                                    <br /> */}
                        <br />
                        {seectedchallan.fee_challan_details.map(
                          (challandetail, index) => (
                            // <option key={campus.id} value={campus.id}>{campus.name}</option>
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Fee Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  value={challandetail.fee_name}
                                  component={Input}
                                  disabled
                                  placeholder="Fee Name"
                                />
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    {" "}
                                    Amount{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <input
                                  type="text"
                                  value={feeamountarray[index]}
                                  defaultValue={feeamountarray[index]}
                                  onChange={(e) => {
                                    onchangeAmont(e.target, index);
                                  }}
                                  name={`feeamount${index}`}
                                  maxlength="5"
                                  id="pin"
                                  pattern="^0[1-9]|[1-9]\d$"
                                  required
                                />
                                {/* <input name={`feeamount${index}`} type="number" maxlength="5" pattern="\d{5}"  onChange={(e) => { onchangeAmont(e.target, index) }}
                                                                        defaultValue={feeamountarray[index]} /> */}
                                {/* <Field
                                                                        name={`feeamount${index}`}
                                                                        component={Input}
                                                                        type="number"
                                                                        placeholder="Payable Amount"
                                                                    /> */}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
                {showerr ? (
                  <div id="err">
                    <Alert
                      variant="danger"
                      onClose={() => setshowerr(false)}
                      dismissible
                    >
                      <Alert.Heading>Oops You Got an Error!</Alert.Heading>
                      <p>{showerror}</p>
                    </Alert>
                  </div>
                ) : null}
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  class="btns"
                >
                  <button
                    // type="submit" value="Submit"
                    onClick={() =>
                      //handleSubmit()
                      submitData()
                    }
                    className="btn btn-success button_blue"
                  >
                    Save
                  </button>
                  <button
                    style={{ marginLeft: "15px" }}
                    onClick={() => {
                      onHide(false);
                      setshowerr(false);
                    }}
                    type="button"
                    class="btn btn-danger btn_width"
                  >
                    Cancel
                  </button>
                </div>
                {/* </form> */}
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EditCampusFeeChallan;
