import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { Table, Modal, Alert } from 'react-bootstrap'
import { baseUrl } from '../../../../services/config'
import { useSelector } from 'react-redux'
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import { Input, DatePickerField, Select } from "../../../../_metronic/_partials/controls";



const EditHostel = ({ HostelDetails, show, reload, updateHostelDetail,allCampuses }) => {
    const alert = useAlert()

    const authtoken = localStorage.getItem("userToken")
    const user = JSON.parse(localStorage.getItem("userDetails"))
    const [name, setname] = useState(HostelDetails.name)
    const [address_1, setaddress_1] = useState(HostelDetails.address_1)
    const [address_2, setaddress_2] = useState(HostelDetails.address_2)
    const [latitude, setlatitude] = useState(HostelDetails.latitude)
    const [longitude, setlongitude] = useState(HostelDetails.longitude)
    const [addLoading, setaddLoading] = useState(false)
    const [update_showerror, setupdate_showerror] = useState(false);
    const [campusId,setCampusId]=useState('')
    const [campusError,setCampusError]=useState(false)
 
    const updateHostelHandler = (values) => {

        if(campusId==''){
            setCampusError(true)
            return
         }
        // if (name && address_1 && address_2 && latitude && longitude) {


            setupdate_showerror(false)

            setaddLoading(true)
            const fdata = {
                campus_id: campusId,
                name:values.hostel_name,
                address_1:values.address1,
                address_2:values.address2,
                latitude:values.longitude,
                longitude:values.latitude,
            }

            fetch(`${baseUrl}/hostel/${HostelDetails.id}?_method=PUT`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authtoken}`
                },
                body: JSON.stringify(fdata),
            })
                .then((res) =>
                    res.json().then((data) => {
                        if (data.metadata.success) {
                            setaddLoading(false)
                            alert.show('Hostel Updated Success')
                            updateHostelDetail(data.payload)
                            show(false)

                        }
                    })
                )
                .catch((err) => {
                    show(false)
                    console.log(err);
                    alert.error(err)
                });
        // } else {
        //     setupdate_showerror(true)

        // }
    };




    const initialValues = {
        "hostel_name": HostelDetails.name,
        "address1": HostelDetails.address_1,
        "address2": HostelDetails.address_2,
        "longitude": HostelDetails.latitude,
        "latitude": HostelDetails.longitude,
    }

    const HostelRegistrationSchema = Yup.object().shape({
        hostel_name: Yup.string()
            .required("Name Is required"),

        address1: Yup.string()
            .required("Address 1 Is required"),
        address2: Yup.string()
            .required("Address 2 Is required"),
        longitude: Yup.string()
            .required("Longitude Is required")
            .matches(
                /^[1-9][\.\d]*(,\d+)?$/,
                "Enter valid Longitude"
            ),
        latitude: Yup.string()
            .required("Latitude Is required")
            .matches(
                /^[1-9][\.\d]*(,\d+)?$/,
                "Enter valid Latitude"
            ),





    });



    return (
        <div>
            {/* {
                update_showerror ? <div id="err">
                    <Alert variant="danger" onClose={() => setupdate_showerror(false)} dismissible>
                        <Alert.Heading>Please fill All fields!</Alert.Heading>
                        <p>
                            All fields Are required
                        </p>
                    </Alert>
                </div>
                    : null

            } */}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={HostelRegistrationSchema}
                onSubmit={(values) => {
                    updateHostelHandler(values)
                }}
            >


                {(props) => {
                    const { handleSubmit, errors, touched, values, isSubmitting, isValid, handleChange, handleBlur } = props



                    return (


                        <>

                            {

                                addLoading ? <b style={{ color: 'green' }}>Hostel Updating Please Wait! <span className="spinner-border"></span></b> :
                                    <Form onSubmit={handleSubmit} className="form form-label-right">
                                        <div>

                                            <div className="row">
                                            <div className="col-md-6">
                    <label><b>Select Campus</b></label>
                    <div className="d-flex flex-column">
                      <select
                        style={{ height: 40 }}
                        className="form-select"
                        name="campus_id"
                        aria-label="Default select example"
                        onChange={(e) => {
                            setCampusError(false)
                            setCampusId(e.target.value)}}
                      >
                        <option selected value="">
                          {" "}
                          Select Campus
                        </option>
                        {allCampuses &&
                          allCampuses.map((item) => (
                            <option value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {
                        campusError?<label className='text-danger'>Please Select the campus</label>:''
                      }
                    </div>
                  </div>
                                                <div className="col-md-6">
                                                    <label>Hostel Name <span style={{ color: "red" }}>*</span></label>
                                                    <Field
                                                        maxLength={150}
                                                        required
                                                        name="hostel_name"
                                                        component={Input}
                                                        type="text"
                                                        placeholder="Enter Hostel Name"

                                                    />
                                                    {/* <input placeholder="Enter Hostel Name" className="form-control" onChange={(e) => setname(e.target.value)} /> */}
                                                </div>
                                            </div>
                                            <div className="row  mt-3">
                                                <div className="col-md-6">
                                                    <label>Address 1 <span style={{ color: "red" }}>*</span></label>
                                                    <Field
                                                        maxLength={150}
                                                        required
                                                        name="address1"
                                                        component={Input}
                                                        type="text"
                                                        placeholder="Enter 1st Address"

                                                    />
                                                    {/* <input className="form-control" onChange={(e) => setaddress_1(e.target.value)} placeholder="Address 1" /> */}
                                                </div>
                                                <div className="col-md-6 ">
                                                    <label>Address 2 <span style={{ color: "red" }}>*</span></label>
                                                    <Field
                                                        maxLength={150}
                                                        required
                                                        name="address2"
                                                        component={Input}
                                                        type="text"
                                                        placeholder="Enter 2nd Address"

                                                    />
                                                    {/* <input className="form-control" onChange={(e) => setaddress_2(e.target.value)} placeholder="Address 1" /> */}
                                                </div>
                                            </div>
                                            <div className="row  mt-3">
                                                <div className="col-md-6">
                                                    <label>Longitude <span style={{ color: "red" }}>*</span></label>
                                                    <Field
                                                        maxLength={20}
                                                        required
                                                        name="longitude"
                                                        component={Input}
                                                        type="text"
                                                        placeholder="Enter Longitude"

                                                    />
                                                    {/* <input className="form-control" onChange={(e) => setlongitude(e.target.value)} placeholder="Longitude" /> */}
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Latitude <span style={{ color: "red" }}>*</span></label>
                                                    <Field
                                                        maxLength={20}
                                                        required
                                                        name="latitude"
                                                        component={Input}
                                                        type="text"
                                                        placeholder="Enter Latitude"

                                                    />
                                                    {/* <input className="form-control" onChange={(e) => setlatitude(e.target.value)} placeholder="Latitude" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Hostel Name</label>
                                                <input value={name} placeholder="Enter Hostel Name" className="form-control" onChange={(e) => setname(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="row  mt-3">
                                            <div className="col-md-6">
                                                <label>Address 1</label>
                                                <input value={address_1} className="form-control" onChange={(e) => setaddress_1(e.target.value)} placeholder="Address 1" />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label>Address 2</label>
                                                <input value={address_2} className="form-control" onChange={(e) => setaddress_2(e.target.value)} placeholder="Address 1" />
                                            </div>
                                        </div>
                                        <div className="row  mt-3">
                                            <div className="col-md-6">
                                                <label>Longitude</label>
                                                <input value={longitude} className="form-control" onChange={(e) => setlongitude(e.target.value)} placeholder="Longitude" />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Latitude</label>
                                                <input value={latitude} className="form-control" onChange={(e) => setlatitude(e.target.value)} placeholder="Latitude" />
                                            </div>
                                        </div>
                                    </div> */}
                                    </Form>
                            }
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button className="btn btn-success button_blue"
                                    //  onClick={AddHostelHandler}
                                    onClick={() => handleSubmit()} type="submit" value="Submit"

                                >Add Hostel</button>
                                <button style={{marginLeft:"10px"}} className="btn btn-danger btn_width" onClick={() => show(false)}>Cancel</button>

                                {/* <button className="btn btn-success button_blue" onClick={updateHostelHandler}>Update Hostel</button>
                                <button className="btn btn-danger btn_width" onClick={() => show(false)}>Cancel</button> */}

                            </div>



                        </>


                    )
                }}

            </Formik>
        </div>
    )
}

export default EditHostel
