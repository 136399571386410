import React, { useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import StaffListOverAllExport from "./StaffListOverAllExport";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
const icon =
  "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";
const pageStyle = `
  @page {
    size: A4 landscape !important;
}
`;

const StaffListOverAll = () => {
  let authtoken = localStorage.getItem("userToken");

  const [loading, setloading] = useState(false);

  const [gender, setgender] = useState("Both");
  const [reportList, setreportList] = useState([]);
  const [serviceType, setserviceType] = useState("In Service Staff");
  const [serviceWise, setserviceWise] = useState("Joining Date Wise");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const onBeforeGetContentResolve = React.useRef(null);
  const componentRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onValueChange = (e) => {
    console.log(e.target.value);
    setgender(e.target.value);
  };

  const onValueChangeService = (e) => {
    console.log(e.target.value);
    setserviceType(e.target.value);
  };

  const onValueChangeServiceWise = (e) => {
    const id = e.target.value;
    setserviceWise(id);
  };

  const formatdate2 = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const searchReports = () => {
    setloading(true);
    const data = {
      male: gender == "Male" ? 1 : 0,
      female: gender == "Female" ? 1 : 0,
      both: gender == "Both" ? 1 : 0,
      joining_date_wise: serviceWise == "Joining Date Wise" ? 1 : 0,
      date_of_birth_wise: serviceWise == "Date of Birth Wise" ? 1 : 0,
      service_period_wise: serviceWise == "Service Period Wise" ? 1 : 0,
      employee_code_wise: serviceWise == "Employee Code Wise" ? 1 : 0,
      in_service_staff: serviceType == "In Service Staff" ? 1 : 0,
      retired_staff: serviceType == "In Service Staff & Retired Staff" ? 1 : 0,
      struck_off: serviceType == "Struck Off" ? 1 : 0,
      transfered: serviceType == "Transfered" ? 1 : 0,
    };
    fetch(`${baseUrl}/staff_list_overall`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setloading(false);
        setIsVisible(true);
        setreportList(data.payload);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  return (
    <div>
      <Paper className="mb-5 p-3">
        <>
          {" "}
          <div className="row p-5 mt-4">
            <div className="col-md-4">
              <div className="form-check">
                <input
                  type="radio"
                  value="Male"
                  className="form-check-input"
                  name="gender"
                  id="Male"
                  checked={gender === "Male"}
                  onChange={onValueChange}
                />
                <label className="form-check-label" for="Male">
                  Male
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value="Female"
                  id="Female"
                  checked={gender === "Female"}
                  onChange={onValueChange}
                  name="gender"
                />
                <label className="form-check-label" for="Female">
                  Female
                </label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  value="Both"
                  className="form-check-input"
                  checked={gender === "Both"}
                  onChange={onValueChange}
                  name="gender"
                  id="Both"
                  defaultChecked
                />
                <label className="form-check-label" for="Both">
                  Both
                </label>
              </div>
            </div>

            <div className="col-md-4">
              <div class="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value="In Service Staff"
                  checked={serviceType === "In Service Staff"}
                  onChange={onValueChangeService}
                  name="serviceType"
                  id="serviceType"
                  defaultChecked
                />
                <label className="form-check-label" for="serviceType">
                  In Service Staff
                </label>
              </div>

              <div class="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={`In Service Staff & Retired Staff`}
                  checked={serviceType === "In Service Staff & Retired Staff"}
                  onChange={onValueChangeService}
                  name="serviceType"
                  id="ServiceStaff"
                />
                <label className="form-check-label" for="ServiceStaff">
                  {`In Service Staff & Retired Staff`}
                </label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={`Struck Off`}
                  checked={serviceType === "Struck Off"}
                  onChange={onValueChangeService}
                  name="serviceType"
                  id="StruckOff"
                />
                <label className="form-check-label" for="StruckOff">
                  {`Struck Off`}
                </label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={`Transfered`}
                  checked={serviceType === "Transfered"}
                  onChange={onValueChangeService}
                  name="serviceType"
                  id="Transfered"
                />
                <label className="form-check-label" for="Transfered">
                  {`Transfered`}
                </label>
              </div>
            </div>

            <div className="col-md-4">
              <div class="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value="Joining Date Wise"
                  checked={serviceWise === "Joining Date Wise"}
                  onChange={onValueChangeServiceWise}
                  name="serviceWise"
                  id="JoiningDate"
                  defaultChecked
                />
                <label className="form-check-label" for="JoiningDate">
                  Joining Date Wise
                </label>
              </div>

              <div class="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={`Date of Birth Wise`}
                  checked={serviceWise === "Date of Birth Wise"}
                  onChange={onValueChangeServiceWise}
                  name="serviceWise"
                  id="dob"
                />
                <label className="form-check-label" for="dob">
                  {`Date of Birth Wise`}
                </label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={`Service Period Wise`}
                  checked={serviceWise === "Service Period Wise"}
                  onChange={onValueChangeServiceWise}
                  name="serviceWise"
                  id="ServicePeriod"
                />
                <label className="form-check-label" for="ServicePeriod">
                  {`Service Period Wise`}
                </label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={`Employee Code Wise`}
                  checked={serviceWise === "Employee Code Wise"}
                  onChange={onValueChangeServiceWise}
                  name="codeWise"
                  id="CodeWise"
                />
                <label className="form-check-label" for="CodeWise">
                  {`Employee Code Wise`}
                </label>
              </div>
            </div>
          </div>
          <div className="row p-5">
            <div className="col-md-4 d-flex">
              <div>
                <button className="btn btn-primary" onClick={searchReports}>
                  Search
                </button>
              </div>
              <div className="ml-2">
                {reportList.length > 0 ? (
                  <StaffListOverAllExport reportList={reportList} />
                ) : (
                  ""
                )}
              </div>
              {reportList.length > 0 ? (
                <ReactToPrint
                  documentTitle={`salary slip Report`}
                  onAfterPrint={handleAfterPrint}
                  pageStyle={pageStyle}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  style={{ margin: "0", padding: "0" }}
                  trigger={() => (
                    <button
                      // onClick={() => console.log("dfsdfsdfd")}
                      disabled={printloading ? true : false}
                      style={{
                        marginLeft: "10px",
                      }}
                      className="btn btn-success button_blue button_blue"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              ) : null}
            </div>
          </div>
          {loading ? (
            <div
              className="mt-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner variant="info" animation="border" />
            </div>
          ) : null}
          {isVisible ? (
            <>
              <div className="row p-3 mt-5">
                <img
                  // className='col-md-2'
                  style={{ height: 100, width: 110 }}
                  src={icon}
                />

                <div className="col-md-6">
                  <h1>{unitName}</h1>
                  <h4>Staff List Overall</h4>
                </div>

                {/* <div>

                    </div> */}
              </div>
              <ColoredLine color={"#FF0000"} />
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ textAlign: "start" }}>Sr.</th>
                    <th style={{ textAlign: "start" }}>Code</th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Father's Name
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "100px",
                      }}
                    >
                      Emp. Scale
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Designation
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Joining Date
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      CNIC{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Date of Birth{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Gender
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Qualification/Experience
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Mobile No
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        minWidth: "150px",
                      }}
                    >
                      Campus
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportList &&
                    reportList.map((report, index) => (
                      <tr>
                        <td
                          style={{
                            textAlign: "start",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                          }}
                        >
                          {report.emp_code}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report.full_name}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report.father_name}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "100px",
                          }}
                        >
                          {report.pay_scale_id}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report?.designation?.name}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report.joining_date}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report.cnic_no}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report.dob}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report.gender}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report.qualification}/{report.experience}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report.mobile_no}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            minWidth: "150px",
                          }}
                        >
                          {report?.campus?.name}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          ) : null}
          {/* //for print///// */}
          <div
            ref={componentRef}
            id="printSection"
            style={{ visibility: "hidden", margin: "0", padding: "0" }}
          >
            <div className="logocontainer" style={{ height: "100px" }}>
              <div className="d-flex mb-5">
                <div className="">
                  <img
                    style={{
                      height: 90,
                      width: 90,
                      // pasection_compareingLeft: "20px",
                    }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div
                  style={{
                    pasection_compareingTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <h1 style={{ fontSize: "20px" }}>{unitName}</h1>
                  <h3 style={{ fontSize: "15px" }}>Staff List Overall</h3>
                  <h3 style={{ fontSize: "13px" }}>
                    Printing Date:- &nbsp; {formatdate2()}
                  </h3>
                </div>
              </div>
            </div>
            <Table
              responsive
              style={{
                color: "black",
                whiteSpace: "normal",
                padding: "1px",
                fontSize: "11px",
                marginTop: "10px",
              }}
            >
              <thead style={{ whiteSpace: "normal", padding: "1px" }}>
                <tr style={{ whiteSpace: "normal", padding: "1px" }}>
                  <th
                    style={{
                      textAlign: "start",
                      width: "3%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Sr.
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "3%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Code
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "15%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Name <br /> Father's Name
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "5%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Gender <br /> Scale
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "8%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Designation <br style={{ height: "3px" }} /> Religion
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "10%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Account No <br style={{ height: "3px" }} /> CNIC
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "7%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Joining Date <br style={{ height: "3px" }} /> Relieve Date
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "7%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    DOB <br style={{ height: "3px" }} /> Emp_Status
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "7%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Qualification
                    <br />
                    Experience
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "10%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Mobile No
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      width: "8%",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Campus
                  </th>
                </tr>
              </thead>
              <tbody style={{ whiteSpace: "normal", padding: "1px" }}>
                {reportList &&
                  reportList.map((report, index) => (
                    <tr
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                        marginTop: "-25px",
                        padding: "1px",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ marginTop: "-5px" }}>{index + 1}</p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ marginTop: "-5px" }}> {report.emp_code}</p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                          {" "}
                          {/* {report.full_name} */}
                          {report.full_name.length > 25
                            ? report.full_name.substring(0, 22) + "..."
                            : report.full_name}
                        </p>

                        <p
                          style={{
                            whiteSpace: "normal",
                            marginTop: "-25px",
                            marginBottom: "-13px",
                          }}
                        >
                          {report.father_name.length > 25
                            ? report.father_name.substring(0, 22) + "..."
                            : report.father_name}
                          {/* {report.father_name} */}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ marginTop: "-5px" }}>{report.gender}</p>
                        <p
                          style={{
                            whiteSpace: "normal",
                            marginTop: "-25px",
                            marginBottom: "-13px",
                          }}
                        >
                          {report.pay_scale_id}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                          {report?.designation?.name
                            ? report?.designation?.name.length > 10
                              ? report?.designation?.name.substring(0, 10) +
                                "..."
                              : report?.designation?.name
                            : null}
                        </p>
                        <p
                          style={{
                            whiteSpace: "normal",
                            marginTop: "-25px",
                            marginBottom: "-13px",
                          }}
                        >
                          {report.religion}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                          {" "}
                          {report.account_no}
                        </p>

                        <p
                          style={{
                            whiteSpace: "normal",
                            marginTop: "-25px",
                            marginBottom: "-13px",
                          }}
                        >
                          {" "}
                          {report.cnic_no}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ marginTop: "-5px" }}>
                          {report.joining_date}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                          {" "}
                          {report.dob}
                        </p>

                        <p
                          style={{
                            whiteSpace: "normal",
                            marginTop: "-25px",
                            marginBottom: "-13px",
                          }}
                        >
                          {" "}
                          {report.marital_status}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                          {" "}
                          {/* {report.qualification} */}
                          {report.qualification
                            ? report.qualification.length > 10
                              ? report.qualification.substring(0, 10) + "..."
                              : report.qualification
                            : null}
                        </p>

                        <p
                          style={{
                            whiteSpace: "normal",
                            marginTop: "-25px",
                            marginBottom: "-13px",
                          }}
                        >
                          {" "}
                          {/* {report.experience} */}
                          {report.experience
                            ? report.experience.length > 10
                              ? report.experience.substring(0, 10) + "..."
                              : report.experience
                            : null}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                          {" "}
                          {report.mobile_no}
                        </p>

                        <p
                          style={{
                            whiteSpace: "normal",
                            marginTop: "-25px",
                            marginBottom: "-13px",
                          }}
                        >
                          {" "}
                          {report.phone}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <p style={{ marginTop: "-5px" }}>
                          {report?.campus?.name
                            ? report?.campus?.name.length > 10
                              ? report?.campus?.name.substring(0, 10) + "..."
                              : report?.campus?.name
                            : null}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
        {/* //EnD for print///// */}
      </Paper>
    </div>
  );
};

export default StaffListOverAll;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
