import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";

const RegisterStaffListReport = () => {
  let authtoken = localStorage.getItem("userToken");

  useEffect(() => {
    getAllSessions();
    getSettingData();
  }, []);

  const [loading, setloading] = useState(false);
  const [reportDetails, setreportDetails] = useState({});

  const alert = useAlert();
  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [sessionYear, setSessionYear] = useState("");

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeYear = () => {
    if (yearId == "") {
      alert.error("Please Select Session");
      return;
    }
    setloading(true);
    const session = sessionData.find((item) => {
      return item.id == yearId;
    });

    setSessionYear(session.year);
    const data = {
      year_id: yearId,
    };
    fetch(`${baseUrl}/register_staff_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setIsVisible(true);
        console.log(data);
        setreportDetails(data.payload);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  return (
    <>
      <Paper className="p-5 mt-5 m-b">
        <div className="d-flex">
          <div className="col-sm-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option selected value="">
                  Select Session
                </option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-sm-3 mt-9 mb-5">
            <button
              onClick={onchangeYear}
              className="btn btn-primary me-2"
              id="myBtn"
            >
              Show
            </button>
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            <div className="row p-3 mt-4">
              <img style={{ height: 100, width: 110 }} src={icon} />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h5>List of Register Staff From {sessionYear}</h5>
              </div>
            </div>

            <ColoredLine color={"#FF0000"} />
            <h4 className="ml-5">Male Staffs</h4>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Sr.</th>
                  <th style={{ textAlign: "center" }}>RegID</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>Father's Name</th>
                  <th style={{ textAlign: "center" }}>DOB</th>
                  <th style={{ textAlign: "center" }}>Qualification</th>
                  <th style={{ textAlign: "center" }}>Mobile No</th>
                  <th style={{ textAlign: "start" }}>Designation</th>
                  <th style={{ textAlign: "center" }}>Martial Status</th>
                  <th style={{ textAlign: "center" }}>Religion</th>
                  <th style={{ textAlign: "center" }}>Experience</th>
                </tr>
              </thead>
              <tbody>
                {reportDetails?.male_employee &&
                  reportDetails?.male_employee.map((report, index) => (
                    <tr>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{report.reg_code}</td>
                      <td style={{ textAlign: "start" }}>{report.full_name}</td>
                      <td style={{ textAlign: "start" }}>
                        {report.father_name}
                      </td>
                      <td style={{ textAlign: "center" }}>{report.dob}</td>
                      <td style={{ textAlign: "center" }}>
                        {report.qualification}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.mobile_no}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report?.designation.name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.marital_status}
                      </td>
                      <td style={{ textAlign: "center" }}>{report.religion}</td>
                      <td style={{ textAlign: "center" }}>
                        {report.experience}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <ColoredLine color={"#FF0000"} />
            <h4 className="ml-5">Female Staffs</h4>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Sr.</th>
                  <th style={{ textAlign: "center" }}>RegID</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>Father's Name</th>
                  <th style={{ textAlign: "center" }}>DOB</th>
                  <th style={{ textAlign: "center" }}>Qualification</th>
                  <th style={{ textAlign: "center" }}>Mobile No</th>
                  <th style={{ textAlign: "start" }}>Designation</th>
                  <th style={{ textAlign: "center" }}>Martial Status</th>
                  <th style={{ textAlign: "center" }}>Religion</th>
                  <th style={{ textAlign: "center" }}>Experience</th>
                </tr>
              </thead>
              <tbody>
                {reportDetails?.female_employee &&
                  reportDetails?.female_employee.map((report, index) => (
                    <tr>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{report.reg_code}</td>
                      <td style={{ textAlign: "start" }}>{report.full_name}</td>
                      <td style={{ textAlign: "start" }}>
                        {report.father_name}
                      </td>
                      <td style={{ textAlign: "center" }}>{report.dob}</td>
                      <td style={{ textAlign: "center" }}>
                        {report.qualification}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.mobile_no}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report?.designation.name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.marital_status}
                      </td>
                      <td style={{ textAlign: "center" }}>{report.religion}</td>
                      <td style={{ textAlign: "center" }}>
                        {report.experience}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default RegisterStaffListReport;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
