import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useDispatch } from "react-redux";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";
import { DownloadTableExcel } from "react-export-table-to-excel";

const NotGeneratedFeeMonthWise = () => {
  const [data, setData] = useState([]);

  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    return `${year}-${month}`;
  };

  const [monthDate, setMonthDate] = useState(getCurrentMonthYear());

  const [loading, setLoading] = useState(false);

  const [fee_types, setfee_types] = useState([]);
  const [feeType, setFeeType] = useState("4");

  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  let authtoken = localStorage.getItem("userToken");

  const [isVisible, setIsVisible] = useState(false);
  const alert = useAlert();
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [grandTotalAmount, setGrandTotalAmount] = useState(0);

  useEffect(() => {
    getSettingData();
    getAllSessions();
    getFeeTypes();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFeeTypes = () => {
    fetch(`${baseUrl}/feestypes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setfee_types(data.payload);
        });
      })
      .catch((err) => {});
  };

  const handleDateChange = () => {
    if (monthDate == "") {
      alert.error("Month Not Set");
      return false;
    }

    const data = {
      year_id: yearId,
      date: monthDate,
      fees_type_id: feeType,
    };

    console.log(data);

    setLoading(true);
    setIsVisible(false);

    fetch(`${baseUrl}/not-fee-generated-student-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);

        if (data.metadata) {
          setIsVisible(true);
          if (data.metadata.success) {
            setData(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const todayDate = () => {
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}, ${d.getFullYear()}`;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Session
                <span style={{ color: "red", fontSize: 12 }}> *</span>
              </b>
            </label>
            <select
              style={{ height: 40, fontSize: 14 }}
              className="form-select"
              name="year_id"
              value={yearId}
              aria-label="Default select example"
              onChange={(e) => setYearId(e.target.value)}
            >
              <option selected value="" disabled>
                Select Session
              </option>
              {sessionData &&
                sessionData.map((item) => (
                  <option value={item.id}>{item.year}</option>
                ))}
            </select>
          </div>

          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Fee Type{" "}
                <span style={{ color: "red", fontSize: 12 }}> *</span>
              </b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="fee_type_id"
              value={feeType}
              aria-label="Default select example"
              onChange={(e) => setFeeType(e.target.value)}
            >
              <option selected value="" disabled>
                All Type Of Fees
              </option>
              {fee_types.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Month{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <input
              value={monthDate}
              className="form-control"
              onChange={(e) => setMonthDate(e.target.value)}
              type="month"
            />
          </div>

          <div className="d-flex col-md-3 mt-7">
            <button className="btn btn-primary mx-2" onClick={handleDateChange}>
              Show
            </button>

            {data?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Monthly Bank Scroll Fee`}
                pageStyle="auto"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary w-50 mx-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        <br />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            <div id="tableboot">
              <div className="row p-3">
                <img style={{ height: 120, width: 130 }} src={icon} />

                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h2>
                    Not Generated Student Fee Of The Month{" "}
                    {getMonthName(monthDate)}
                  </h2>
                  {/* {data.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "90%",
                          marginTop: "10px",
                        }}
                      >
                        <div>
                          <h5 className="">Month:- {monthDate}</h5>

                          {selectedbank != 0 ? (
                            <h5 className="">
                              Bank Category :-{" "}
                              {wholeBankCat.length && wholeBankCat[0].title}{" "}
                            </h5>
                          ) : null}
                        </div>
                        <div>
                          <h5 className="">Printing Date :- {todayDate()}</h5>
                          {selectedbank != 0 ? (
                            <h5 className="">
                              Bank:- {wholeBank.bank_name}&nbsp;&nbsp;
                              {`(${wholeBank.account_head})`}{" "}
                            </h5>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null} */}
                </div>
              </div>

              <ColoredLine color={"#FF0000"} />
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-end">Sr No.</th>
                    <th className="text-center">AdmID</th>
                    <th className="text-start">Name</th>
                    <th className="text-start">Father Name</th>
                    <th className="text-center">Campus</th>
                    <th className="text-center">Class</th>
                    <th className="text-center">Section</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <tr key={i} id="tbl">
                      <td style={{ color: "black" }} className="text-center">
                        {i + 1}
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {item.admission_id}
                      </td>
                      <td style={{ color: "black" }} className="text-start">
                        {item.name}
                      </td>
                      <td style={{ color: "black" }} className="text-start">
                        {item.father_name}
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {item.campus?.name}
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {item.student_class?.name}
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {item.global_section?.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        ) : null}
      </Paper>

      {/* /////for print  */}
      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot">
          <div className="p-3" style={{ display: "flex" }}>
            <img style={{ height: 120, width: 130 }} src={icon} />

            <div className="row ml-4">
              <h1>{unitName}</h1>
              Not Generated Student Fee Of The Month {getMonthName(monthDate)}
              <h5 className="ffonmt">Printing Date :- {todayDate()}</h5>
            </div>
          </div>

          <table style={{ width: "100%" }}>
            <thead>
              <tr id="tbl">
                <th
                  className="text-end"
                  style={{
                    fontSize: "16px",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  Sr No.
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  AdmID
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  Name
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  Father Name
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  Campus
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  Class
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  Section
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i} id="tbl">
                  <td
                    style={{ color: "black", fontSize: "14px" }}
                    className="text-center"
                  >
                    {i + 1}
                  </td>
                  <td
                    style={{ color: "black", fontSize: "14px" }}
                    className="text-center"
                  >
                    {item.admission_id}
                  </td>
                  <td
                    style={{ color: "black", fontSize: "14px" }}
                    className="text-start"
                  >
                    {item.name}
                  </td>
                  <td
                    style={{ color: "black", fontSize: "14px" }}
                    className="text-start"
                  >
                    {item.father_name}
                  </td>
                  <td
                    style={{ color: "black", fontSize: "14px" }}
                    className="text-center"
                  >
                    {item.campus?.name}
                  </td>
                  <td
                    style={{ color: "black", fontSize: "14px" }}
                    className="text-center"
                  >
                    {item.student_class?.name}
                  </td>
                  <td
                    style={{ color: "black", fontSize: "14px" }}
                    className="text-center"
                  >
                    {item.global_section?.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* ///for exprot print */}
      {/* <div
        ref={exportTable}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot">
          <div className="p-3" style={{ display: "flex" }}>
            <img style={{ height: 120, width: 130 }} src={icon} />

            <div className="row ml-4">
              <h1>{unitName}</h1>
              <h2>
                Bank Scroll Date Wise for the Month of {getMonthName(monthDate)}
              </h2>
              <h5 className="ffonmt">Printing Date :- {todayDate()}</h5>
            </div>
          </div>

          <table style={{ width: "100%" }}>
            {data.map((bankAccount, index) => (
              <React.Fragment key={index}>
                <tr
                  id="tbl"
                  style={{
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                  }}
                >
                  <th
                    style={{ color: "#121291", fontSize: "18px" }}
                    className="text-center"
                  >
                    {bankAccount.id}
                  </th>
                  <th
                    colSpan={2}
                    style={{ color: "#121291", fontSize: "18px" }}
                    className="text-center"
                  >
                    {bankAccount.bank_name}
                  </th>
                  <th
                    style={{ color: "#121291", fontSize: "18px" }}
                    className="text-center"
                  >
                    {bankAccount.account_number}
                  </th>
                  <th
                    style={{ color: "#121291", fontSize: "18px" }}
                    className="text-center"
                  >
                    {bankAccount.account_head}
                  </th>
                </tr>
                <tbody>
                  <tr id="tbl">
                    <th></th>
                    <th></th>
                    <th
                      className="text-end"
                      style={{
                        fontSize: "16px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      Sr No.
                    </th>
                    <th
                      className="text-center"
                      style={{
                        fontSize: "16px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      Receiving Date
                    </th>
                    <th
                      className="text-center"
                      style={{
                        fontSize: "16px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  {bankAccount.feeChallans.map((item, i) => (
                    <tr key={i} id="tbl">
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "black", fontSize: "16px" }}
                        className="text-end"
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{ color: "black", fontSize: "16px" }}
                        className="text-center"
                      >
                        {item.received_date}
                      </td>
                      <td
                        style={{ color: "black", fontSize: "16px" }}
                        className="text-center"
                      >
                        {item.total_paid}
                      </td>
                    </tr>
                  ))}

                  <tr id="tbl">
                    <th></th>
                    <th></th>
                    <th
                      style={{
                        color: "black",
                        fontSize: "16px",
                        borderTop: "1px solid black",
                      }}
                      className="text-end"
                      colSpan="2"
                    >
                      Total Amount :-
                    </th>
                    <th
                      style={{
                        color: "black",
                        fontSize: "16px",
                        borderTop: "1px solid black",
                      }}
                      className="text-center"
                    >
                      {bankAccount.feeChallans.reduce(
                        (total, item) => total + parseInt(item.total_paid),
                        0
                      )}
                    </th>
                  </tr>
                </tbody>
              </React.Fragment>
            ))}

            <tbody>
              <tr id="tbl">
                <td></td>
                <td></td>
                <td></td>
                <td
                  style={{
                    color: "#121291",
                    fontSize: "18px",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                  }}
                >
                  Grand Total Amount :-
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: "18px",
                    fontWeight: "bold",
                    textAlign: "start",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                  }}
                >
                  {grandTotalAmount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
    </>
  );
};

export default NotGeneratedFeeMonthWise;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
