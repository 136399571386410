import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { PremissionChecker } from "../../../../services/PremissionChecker";
import { useSelector } from "react-redux";
const DefineExam = () => {
  const user = useSelector((state) => state.user.user);
  const authtoken = localStorage.getItem("userToken");

  const [examTypes, setExamTypes] = useState([]);

  const [examModal, setExamModal] = useState(false);
  const [examName, setExamName] = useState("");
  const [examDurationId, setExamDurationId] = useState("");

  const [editExamModal, setEditExamModal] = useState(false);
  const [editExamId, setEditExamId] = useState([]);
  const [editExameName, setEditExamName] = useState("");
  const [editExamDurationIdData, setEditExamDurationIdData] = useState("");

  const [deleteExamModal, setdeleteExamModal] = useState(false);
  const [deleteableExam, setDeleteableExam] = useState([]);

  const [loading, setloading] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [addExamLoading, setAddExamLoading] = useState(false);
  const alert = useAlert();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteloading, setdeleteloading] = useState(false);

  const [examDurationData, setExamDurationData] = useState([]);
  const [examDurationModal, setExamDurationModal] = useState(false);
  const [examDurationName, setExamDurationName] = useState("");

  const [editExamDurationModal, setEditExamDurationModal] = useState(false);
  const [editExamDurationId, setEditExamDurationId] = useState([]);
  const [editExamDurationName, setEditExamDurationName] = useState("");

  const [deleteExamDurationModal, setdeleteExamDurationModal] = useState(false);
  const [deleteableExamDuration, setDeleteableExamDuration] = useState([]);

  useEffect(() => {
    getExamTypes();
    getExamTypeDuration();
  }, []);

  const getExamTypes = async () => {
    setloading(true);
    await fetch(`${baseUrl}/exam-types`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setExamTypes(data.payload);
          setloading(false);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const getExamTypeDuration = async () => {
    await fetch(`${baseUrl}/exam-type-durations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setExamDurationData(data.payload);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  // Exam Type Function
  const addExamTypeHandler = async () => {
    if (examName == "" || examDurationId == "") {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    const data = {
      name: examName,
      exam_type_duration_id: examDurationId,
    };

    await fetch(`${baseUrl}/exam-types`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setExamModal(false);
            alert.success("Exam Type successfully Added");
            getExamTypes();
            setExamName("");
            setExamDurationId("");
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setExamModal(false);
          } else {
            alert.show("Something Went Wrong");
            setExamModal(false);
          }
        })
      )
      .catch((err) => {
        setAddExamLoading(false);
        setExamModal(false);
        console.log(err);
      });
  };

  const getEditExamType = async (id) => {
    await fetch(`${baseUrl}/exam-types/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditExamName(data.payload?.name);
          setEditExamDurationIdData(data.payload?.exam_type_duration_id);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateExamTypeHandler = async () => {
    if (editExameName == "" || editExamDurationIdData == "") {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    const data = {
      name: editExameName,
      exam_type_duration_id: editExamDurationIdData,
    };

    await fetch(`${baseUrl}/exam-types/${editExamId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setEditExamModal(false);
            alert.success("Exam Type successfully Updated");
            getExamTypes();
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setEditExamModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditExamModal(false);
          }
        })
      )
      .catch((err) => {
        setAddExamLoading(false);
        setExamModal(false);
        console.log(err);
      });
  };

  const deleteExamHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/exam-types/${deleteableExam.id}}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            getExamTypes();
            setdeleteloading(false);
            setdeleteExamModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setdeleteloading(false);
            setdeleteExamModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setdeleteloading(false);
          setdeleteExamModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteExamModal(false);
      });
  };

  // Exam Type Duration Function
  const examDurationHandler = async () => {
    if (examDurationName == "") {
      return alert.error("Exam Type Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: examDurationName,
    };
    setSubmitLoading(false);
    await fetch(`${baseUrl}/exam-type-durations`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Exam Type Duration Successfully Added");
            setExamDurationName("");
            getExamTypeDuration();
            setExamDurationModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setExamDurationName("");
            getExamTypeDuration();
            setExamDurationModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setExamDurationModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const getEditExamDuration = async (id) => {
    await fetch(`${baseUrl}/exam-type-durations/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditExamDurationName(data.payload?.name);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateExamDurationHandler = async () => {
    if (editExamDurationName == "") {
      return alert.error("Exam Duration Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: editExamDurationName,
    };
    setSubmitLoading(false);
    await fetch(`${baseUrl}/exam-type-durations/${editExamDurationId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Exam Type Duration Successfully Updated");
            getExamTypeDuration();
            setEditExamDurationModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setEditExamDurationModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setEditExamDurationModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const deleteExamDurationHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/exam-type-durations/${deleteableExamDuration.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            getExamTypeDuration();
            setdeleteloading(false);
            setdeleteExamDurationModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setdeleteloading(false);
            setdeleteExamDurationModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setdeleteloading(false);
          setdeleteExamDurationModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteExamDurationModal(false);
      });
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div>
          <button
            onClick={() => setExamModal(true)}
            className="btn btn-primary mr-2"
          >
            Add Exam Type
          </button>

          {PremissionChecker(user, "campus.can_change_exam") ? (
            <button
              className="btn btn-primary"
              onClick={() => setExamDurationModal(true)}
            >
              Add Exam Type Duration
            </button>
          ) : null}
        </div>

        <div>
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">ID</th>
                <th className="text-center">Exam Name</th>
                <th className="text-center">Exam Duration</th>
                {PremissionChecker(user, "campus.can_change_exam") ? (
                  <th className="text-center">Edit</th>
                ) : null}
                {PremissionChecker(user, "campus.can_change_exam") ? (
                  <th className="text-center">Delete</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {examTypes.map((examTypeData, index) => (
                <tr key={index}>
                  <td className="text-center">{examTypeData?.id}</td>
                  <td className="text-center">{examTypeData?.name}</td>
                  <td className="text-center">
                    {examTypeData?.exam_type_duration?.name}
                  </td>

                  {PremissionChecker(user, "campus.can_change_exam") ? (
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          getEditExamType(examTypeData?.id);
                          setEditExamId(examTypeData?.id);
                          setEditExamModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary ">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}

                  {PremissionChecker(user, "campus.can_change_exam") ? (
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setDeleteableExam(examTypeData);
                          setdeleteExamModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row justify-content-center">
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
        </div>

        {/* ------------------------Exam Type Start----------------------- */}
        {/* Add Exam */}
        <Modal show={examModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Exam Type
            </Modal.Title>
          </Modal.Header>

          {addExamLoading ? (
            <div style={{ padding: "25px" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="exam_name">
                    Exam Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="exam_name"
                    className="form-control"
                    placeholder="Enter Exam Name"
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setExamName(e.target.value);
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="exam_type_duration_id">
                    Select Exam Type Duration
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    className="form-select form-control"
                    name="exam_type_duration_id"
                    value={examDurationId}
                    onChange={(e) => {
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setExamDurationId(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Exam Type Duration</option>
                    {examDurationData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            {addExamLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={addExamTypeHandler}
              >
                Add Exam
              </button>
            )}
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setExamModal(false);
                setisallfields(false);
                setExamName("");
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Exam */}
        <Modal show={editExamModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Exam Type
            </Modal.Title>
          </Modal.Header>

          {addExamLoading ? (
            <div style={{ padding: "25px" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="edit_exam_name">
                    Exam Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="edit_exam_name"
                    className="form-control"
                    placeholder="Enter Exam Name"
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setEditExamName(e.target.value);
                    }}
                    value={editExameName}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="edit_exam_type_duration_id">
                    Select Exam Duration
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    className="form-select form-control"
                    name="edit_exam_type_duration_id"
                    value={editExamDurationIdData}
                    onChange={(e) => {
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setEditExamDurationIdData(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Exam Type Duration</option>
                    {examDurationData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            {addExamLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-primary"
                onClick={updateExamTypeHandler}
              >
                Update Exam Type
              </button>
            )}
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setEditExamModal(false);
                setisallfields(false);
                setExamName("");
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Exam */}
        <Modal show={deleteExamModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this exam?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Fee Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button onClick={deleteExamHandle} className="btn button_blue">
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setdeleteExamModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
        {/* ------------------------Exam Type End----------------------- */}

        {/* ------------------------Exam Type Duration Start----------------------- */}
        {/* Add Exam Type Duration  */}
        <Modal show={examDurationModal} size="md">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Exam Type Duration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Duration Nmae <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control text-uppercase"
                value={examDurationName}
                onChange={(e) => setExamDurationName(e.target.value)}
                placeholder="Enter Duration Name"
              />
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Duration Name</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {examDurationData.map((examDurationData, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      {examDurationData?.id}
                    </td>
                    <td style={{ textAlign: "start" }}>
                      {examDurationData?.name}
                    </td>
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setEditExamDurationId(examDurationData?.id);
                          getEditExamDuration(examDurationData?.id);
                          setEditExamDurationModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary ">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setDeleteableExamDuration(examDurationData);
                          setdeleteExamDurationModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button onClick={examDurationHandler} className="btn btn-primary">
                Add Duration
              </button>
            )}

            <button
              onClick={() => setExamDurationModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Exam Type Duration */}
        <Modal show={editExamDurationModal} size="md">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Exam Type Duration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Duration Name <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control text-uppercase"
                value={editExamDurationName}
                onChange={(e) => setEditExamDurationName(e.target.value)}
                placeholder="Enter Duration Name"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button
                onClick={updateExamDurationHandler}
                className="btn btn-primary"
              >
                Update Duration
              </button>
            )}

            <button
              onClick={() => setEditExamDurationModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Exam Type Duration */}
        <Modal show={deleteExamDurationModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this exam type duration?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Fee Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={deleteExamDurationHandle}
                  className="btn btn-primary mx-2"
                >
                  Delete Duration
                </button>
                <button
                  onClick={() => setdeleteExamDurationModal(false)}
                  className="btn btn-danger mx-2"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
        {/* ------------------------Exam Duration End----------------------- */}
      </Paper>
    </div>
  );
};

export default DefineExam;
