import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Pagination from "@material-ui/lab/Pagination";
import SVG from "react-inlinesvg";
import { Link, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import usePagination from "../../../../components/usePagination/usePagination";
import { faIR } from "date-fns/locale";
import { useAlert } from "react-alert";
import EditAppointedStaff from "../AppointedStaff/EditAppointedStaff/EditAppointedStaff";

const SearchStaff = () => {
  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const history = useHistory();
  const alert = useAlert();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);

  const [searchtext, setsearchtext] = useState("");
  const [loading, setloading] = useState(false);

  const [AllStaff, setAllStaff] = useState([]);

  const [allcampuses, setallcampuses] = useState([]);
  const [campusID, setcampusID] = useState("");
  const [searchtext2, setsearchtext2] = useState("");

  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  const [editModal, seteditModal] = useState(false);
  const [currentObject, setcurrentObject] = useState({});

  // Due FEE wala module ha
  const [empName, setEmpName] = useState(true);
  const [empFather, setEmpFather] = useState(false);
  const [empIdCard, setEmpIdCard] = useState(false);
  const [empCode, setEmpCode] = useState(false);
  const [selected, setSelected] = useState("empName");

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      getAllStaff(campusidget);
    }
    // getAllCampus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const fdata = new FormData();

    fdata.append("employee_name", empName ? 1 : 0);
    fdata.append("employee_father_name", empFather ? 1 : 0);
    fdata.append("employee_ID_card", empIdCard ? 1 : 0);
    fdata.append("employee_code", empCode ? 1 : 0);
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;

    console.log(value);
    setSelected(e.target.value);
    if (value == "empName") {
      setEmpName(true);
      setEmpFather(false);
      setEmpIdCard(false);
      setEmpCode(false);
    }
    if (value == "empFather") {
      setEmpName(false);
      setEmpFather(true);
      setEmpIdCard(false);
      setEmpCode(false);
    }
    if (value == "empIdCard") {
      setEmpName(false);
      setEmpFather(false);
      setEmpIdCard(true);
      setEmpCode(false);
    }
    if (value == "empCode") {
      setEmpName(false);
      setEmpFather(false);
      setEmpIdCard(false);
      setEmpCode(true);
    }
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };
  const getAllStaff = (e) => {
    let id = "";
    if (e == undefined) {
      id = campusID;
    } else {
      id = e;
    }
    setcampusID(id);

    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
  };

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const dataSearch = AllStaff.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.full_name?.toLowerCase().includes(text) ||
          user?.emp_code?.toLowerCase().includes(text)
      : true;
  });
  const currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(dataSearch.length / PER_PAGE);
  const _DATA = usePagination(dataSearch, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const searchByCode = (e) => {
    e.preventDefault();
    if (searchtext2 == "") {
      alert.error("Please Enter Empolyee Code");
    } else {
      const data = {
        name_code: searchtext2.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_appointed_employees_by_code`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(true);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStaff(arr);
            setloading(false);
            //   setAllStaff(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(true);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const searchByName = (e) => {
    e.preventDefault();
    if (searchtext2 == "") {
      alert.error("Please Enter Empolyee Name");
    } else {
      const data = {
        name_code: searchtext2.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_appointed_employees_by_name`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(true);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStaff(arr);
            setloading(false);
            //   setAllStaff(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(true);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const searchByFather = (e) => {
    e.preventDefault();
    if (searchtext2 == "") {
      alert.error("Please Enter Empolyee's Fathher Name");
    } else {
      const data = {
        name_code: searchtext2.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_appointed_employees_by_father`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(false);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStaff(arr);
            setloading(false);
            //   setAllStaff(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(false);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const searchByID = (e) => {
    e.preventDefault();
    if (searchtext2 == "") {
      alert.error("Please Enter Empolyee ID Card");
    } else {
      const data = {
        name_code: searchtext2.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_appointed_employees_by_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(false);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStaff(arr);
            setloading(false);
            //   setAllStaff(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(false);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const openEditModal = (staff) => {
    setcurrentObject(staff);
    seteditModal(true);
  };

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* <h1>Search Staff</h1> */}
        <form onSubmit={handleSubmit}>
          <div className="row mt-6">
            <div className="col-sm-3">
              <input
                checked={selected == "empName"}
                onChange={(e) => onRadioButtonClick(e)}
                defaultChecked
                type="radio"
                id="EmpName"
                name="contact"
                value="empName"
              />
              <label for="EmpName">Search by Name</label>
            </div>

            <div className="col-sm-3">
              <input
                checked={selected == "empFather"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="EmpFather"
                name="contact"
                value="empFather"
              />
              <label for="EmpFather">Search by Father Name</label>
            </div>

            <div className="col-sm-3">
              <input
                checked={selected == "empIdCard"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="EmpIdCard"
                name="contact"
                value="empIdCard"
              />
              <label for="EmpIdCard">Search by ID Card</label>
            </div>

            <div className="col-sm-3">
              <input
                checked={selected == "empCode"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="EmpCode"
                name="contact"
                value="empCode"
              />
              <label for="EmpCode">Search by Code</label>
            </div>
          </div>

          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {empName ? (
              <>
                <form onSubmit={searchByName} style={{ width: "100%" }}>
                  <div className="row mt-4">
                    <div className="col-md-5">
                      <input
                        onChange={(e) => setsearchtext2(e.target.value)}
                        className="form-control"
                        placeholder="Enter Employee Name"
                      />
                    </div>
                    <div className="col-md-4">
                      <button type="submit" className="btn btn-primary">
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </>
            ) : null}

            {empFather ? (
              <form onSubmit={searchByFather} style={{ width: "100%" }}>
                <div className="row mt-4">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext2(e.target.value)}
                      className="form-control"
                      placeholder="Enter Employee's Father Name"
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}

            {empIdCard ? (
              <form onSubmit={searchByID} style={{ width: "100%" }}>
                <div className="row mt-4">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext2(e.target.value)}
                      className="form-control"
                      placeholder="Enter Employee's ID Card"
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}

            {empCode ? (
              <form onSubmit={searchByCode} style={{ width: "100%" }}>
                <div className="row mt-4">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext2(e.target.value)}
                      className="form-control"
                      placeholder="Enter Employee Code"
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}
          </div>
        </form>

        <div className="mt-4" id="tableboot">
          <div>
            {/* <div className="makeITScrollAAble"> */}
            <Table
              style={{
                width: "200%",
                marginTop: "10px",
                overflowY: "scroll",
              }}
              responsive
            >
              <thead>
                <tr className="text-center">
                  <th style={{ textAlign: "center" }}>Sr. no</th>
                  <th style={{ textAlign: "center" }}>Emp Code</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>Father's Name</th>
                  <th style={{ textAlign: "center" }}>Job Status</th>
                  <th style={{ textAlign: "center" }}>CNIC_No</th>
                  <th style={{ textAlign: "center" }}>Mobile No</th>
                  <th style={{ textAlign: "center" }}>Joining Date</th>
                  <th style={{ textAlign: "center" }}>Leaving Date</th>
                  <th style={{ textAlign: "center" }}>Campus Name</th>
                  <th style={{ textAlign: "center" }}>Pay Scale</th>
                  <th style={{ textAlign: "center" }}>Designation</th>
                  <th style={{ textAlign: "center" }}>DOB</th>
                  <th style={{ textAlign: "center" }}>Gender</th>
                  <th style={{ textAlign: "center" }}>Martial Status</th>
                  <th style={{ textAlign: "center" }}>Experience</th>
                  <th style={{ textAlign: "center" }}>Qualification</th>
                  <th style={{ textAlign: "center" }}>Address</th>
                  <th style={{ textAlign: "center" }}>Edit</th>
                </tr>
              </thead>

              <tbody>
                {_DATA.currentData().map((staf, index) => (
                  <tr key={staf.id}>
                    <td>{staf.Sr}</td>
                    {staf.emp_code === null ? (
                      <td style={{ textAlign: "center" }}>{"----"}</td>
                    ) : (
                      <td style={{ textAlign: "center" }}>{staf.emp_code}</td>
                    )}

                    <td title={staf.full_name} style={{ textAlign: "start" }}>
                      {staf.full_name}
                    </td>
                    <td style={{ textAlign: "start" }}>{staf.father_name}</td>

                    {staf.job_status.id == 1 ? (
                      <td style={{ textAlign: "center", color: "green" }}>
                        {"In Service"}
                      </td>
                    ) : staf.job_status.id == 2 ? (
                      <td style={{ textAlign: "center", color: "red" }}>
                        {"Retired"}
                      </td>
                    ) : staf.job_status.id == 3 ? (
                      <td style={{ textAlign: "center", color: "red" }}>
                        {"Transfered"}
                      </td>
                    ) : staf.job_status.id == 4 ? (
                      <td style={{ textAlign: "center", color: "red" }}>
                        {"Struck Off"}
                      </td>
                    ) : staf.job_status.id == 5 ? (
                      <td style={{ textAlign: "center", color: "orange" }}>
                        {"Registered Only"}
                      </td>
                    ) : null}

                    <td style={{ textAlign: "center" }}>{staf.cnic_no}</td>
                    <td style={{ textAlign: "center" }}>{staf.mobile_no}</td>
                    {staf.joining_date == null ? (
                      <td style={{ textAlign: "center" }}>{"----"}</td>
                    ) : (
                      <td style={{ textAlign: "center" }}>
                        {staf.joining_date}
                      </td>
                    )}

                    {staf.leaving_date == null ? (
                      <td style={{ textAlign: "center" }}>{"----"}</td>
                    ) : (
                      <td style={{ textAlign: "center" }}>
                        {staf.leaving_date}
                      </td>
                    )}

                    <td style={{ textAlign: "center" }}>{staf.campus.name}</td>

                    {staf.pay_scale === null ? (
                      <td style={{ textAlign: "center" }}>{"Not Define"}</td>
                    ) : (
                      <td style={{ textAlign: "center" }}>
                        {staf.pay_scale.payscale}
                      </td>
                    )}
                    {/* <td style={{ textAlign: "center" }}>
                      {staf.pay_scale.payscale}
                    </td> */}
                    <td style={{ textAlign: "center" }}>
                      {staf.designation.name}
                    </td>
                    <td style={{ textAlign: "center" }}>{staf.dob}</td>
                    <td style={{ textAlign: "center" }}>{staf.gender}</td>
                    <td style={{ textAlign: "center" }}>
                      {staf.marital_status}
                    </td>
                    <td style={{ textAlign: "center" }}>{staf.experience}</td>
                    <td style={{ textAlign: "center" }}>
                      {staf.qualification}
                    </td>
                    <td style={{ textAlign: "start" }}>{staf.address}</td>
                    <td style={{ textAlign: "center" }}>
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                        onClick={() => {
                          openEditModal(staf);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Modal show={editModal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Staff Details
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditAppointedStaff
              staffDetails={currentObject}
              show={() => seteditModal()}
              reload={getAllStaff}
              campusID={campusID}
            />
          </Modal.Body>
        </Modal>

        <Pagination
          color="primary"
          count={count}
          size="large"
          page={page}
          variant="text"
          shape="round"
          onChange={handleChange}
        />
      </Paper>
    </div>
  );
};

export default SearchStaff;
