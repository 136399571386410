import React from 'react'
import {Field ,ErrorMessage } from 'formik'
import TextError from './TextError'
const FormikTextArea = (props) => {
    const {name,label,options,colCount,...rest} = props

    return (
        <div>
              
        <div className={`${colCount}`} style={{width:'600px'}}>
        <label htmlFor={name} className="font-size-h6 font-size-lg-h4 col-form-label">{label}</label>
        <Field
                id={name}
                name={name}
                as='textarea'
                
                className="form-control form-control-lg form-control-solid  " 
                {...rest}
            />

            <ErrorMessage name={name} component={TextError} /> 
            </div>
      
        </div>
    )
}

export default FormikTextArea
