import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../services/config";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Spinner } from "react-bootstrap";
import { Table, Modal, Alert } from "react-bootstrap";
import toast from "react-hot-toast";

const GenerateGPFund = ({ empDetails, show, reload }) => {
  const [overview, setOverview] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  console.log(empDetails?.employee_salaries1?.salary_month);
  let today = new Date();
  let sal_date = empDetails?.employee_salaries1?.salary_month;
  const answer_array = sal_date.split("-"); //2023-01-01

  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = String(today.getFullYear());

  console.log("empDetails==>", empDetails);

  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    return `${year}-${month}`;
  };
  const [month, setMonth] = useState(getCurrentMonthYear());
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [Allsessions, setAllsessions] = useState([]);
  const [year, setyear] = useState();
  const [gpAmount, setGpAmount] = useState("");
  const [chk, setchk] = useState("");
  const [acc, setacc] = useState("");

  const [loading, setloading] = useState(false);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please Fill all Fields");
  const [gpamerror, setgpamerror] = useState();
  const [bankerror, setbankerror] = useState();
  const [chkerror, setchkerror] = useState();
  const [accerror, setaccerror] = useState();
  const [jobstatuses, setjobstatuses] = useState([]);
  const [job_status_id, setjob_status_id] = useState("");
  const [isCheckedGpf, setisCheckedGpf] = useState(false);
  const [days, setdays] = useState("");
  const [is_returning_gpf, setis_returning_gpf] = useState("");
  const [loading2, setloading2] = useState(false);
  const [gpf_return_amount, setgpf_return_amount] = useState("");
  const [banktypess, setallbanktypes] = useState([]);
  const [AllBanktypess, setbanktypesAll] = useState([]);
  //const [banks, setallbanks] = useState([]);
  const [bank2, setbank2] = useState(1);
  const [selectedbankcateogry, setselectedbankcateogry] = useState("");
  const [allbanks, setallbanks] = useState([]);
  const [allsubaccounts, setallsubaccounts] = useState([]);

  const [selectedbank, setselectedbank] = useState("");

  const [allsessions, setallsessions] = useState([]);
  const [selectedsession, setselectedsession] = useState("");
  const [remarks, setRemarks] = useState("");
  const [GPAmountError, setGPAmountError] = useState(false);

  useEffect(() => {
    getAllSessions();
    getJobStatus();
    getAllBanks();
  }, []);
  const getAllBanks = async () => {
    // await fetch(`${baseUrl}/bankr`, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     let campuses = [];
    //     setallbanks(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleBankNow = (e) => {
    console.log(e.target.value);
    fetch(`${baseUrl}/banktyp?actype=${e.target.value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let campuses = [];
        setallbanktypes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleBankNowAll = (e) => {
    console.log(e.target.value);
    fetch(`${baseUrl}/banktyp?actype=${e.target.value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let campuses = [];
        setbanktypesAll(data);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(MoreBestOption);
  };
  var months = [
    {
      _id: "01",
      name: "January",
    },
    {
      _id: "02",
      name: "February",
    },
    {
      _id: "03",
      name: "March",
    },
    {
      _id: "04",
      name: "April",
    },
    {
      _id: "05",
      name: "May",
    },
    {
      _id: "06",
      name: "June",
    },
    {
      _id: "07",
      name: "July",
    },
    {
      _id: "08",
      name: "August",
    },
    {
      _id: "09",
      name: "September",
    },
    {
      _id: "10",
      name: "October",
    },
    {
      _id: "11",
      name: "November",
    },
    {
      _id: "12",
      name: "December",
    },
  ];

  const GenerateSalaryFunc = () => {
    if (gpAmount == "") {
      setgpamerror("Enter Amount");
      setshowerr(true);
    }
    if (bank2 == "") {
      setbankerror("Please Set Banks");
      setshowerr(true);
    }
    setshowerr(false);
    // let formetValue =  ''
    // if (parseInt(year) < 10) {
    //     formetValue = "0" + String(year)

    // } else {
    //     formetValue = String(year)
    // }
    // let current_year = new Date().getFullYear();

    let employee_id = empDetails.id;
    let campus_id = empDetails.campus_id;
    let bank_account_id = empDetails.bank_account_id;
    let salary_month = empDetails.employee_salaries1.salary_month;
    let account_no = empDetails.account_no;
    let session = empDetails.employee_salaries1.session_id;

    const data11 = {
      employee_id: employee_id,
      campus_id: campus_id,
      session_id: selectedsession,
      bank_account_id: selectedbank,
      cheque_number: chk,
      month: month + "-01",
      account_no: account_no,
      gpf_return: gpAmount,
      remarks: remarks,
    };
    setloading(true);
    console.log(data11);

    fetch(`${baseUrl}/genrate-gpfund-return`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data11),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        console.log(data.payload);
        show(false);
        alert.success(data.payload);
      })
      .catch((err) => {
        setloading(false);
        alert.error(err);
        show(false);
        setloading(false);
      });
  };

  const getJobStatus = () => {
    fetch(`${baseUrl}/jobstatuses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setjobstatuses(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "session data");
        if (data.metadata) {
          if (data.metadata.success) {
            setallsessions(data.payload);

            data.payload.map((session, index) => {
              if (session.active_financial_year == "1") {
                setselectedsession(session.id);
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAllSessions = () => {
  //   fetch(`${baseUrl}/session`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((res) => {
  //       res.json().then((data) => {
  //         if (data) {
  //           if (data.metadata) {
  //             if (data.metadata.success) {
  //               setAllsessions([...data.payload]);

  //               data.payload.map((session, index) => {
  //                 if (session.year.substr(0, 4) == yyyy) {
  //                   setyear(session.id);
  //                 }
  //               });
  //             }
  //           }
  //         }
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleChangeJobStatus = (id) => {
    setjob_status_id(id);
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}`;
  };

  const handleAmountChange = (event) => {
    // Convert value to number using parseFloat or parseInt
    const parsedValue = parseFloat(event); // or parseInt(value, 10) for integer
    // Create a new array with updated value
    let actualamount = empDetails?.g_p_fund?.collected_amount;

    if (actualamount < parsedValue) {
      setGPAmountError(true);
      toast.error("Return Amount is Greater than Total GP Fund Collected");
    } else {
      setGPAmountError(false);
    }
  };

  return (
    <div>
      {loading ? (
        <>
          {" "}
          <p style={{ color: "green" }}>Salary Generating Please Wait</p>{" "}
          <Spinner animation="border" variant="secondary" />{" "}
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-6">
              <label>
                <b>Select Session</b> <span style={{ color: "red" }}>*</span>
              </label>
              <select
                class="form-select form-control"
                name="year_id"
                value={selectedsession}
                onChange={(e) => setselectedsession(e.target.value)}
                style={{ backgroundColor: "white", fontSize: "14px" }}
                aria-label="Default select example"
              >
                <option value="">Select Session</option>
                {allsessions.map((item, key) => (
                  <option key={key} value={item.id}>
                    {item.year}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <div>
                <label>
                  <b>Select Month</b> <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                className="form-control"
                type="month"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="form-group col-md-6">
              <label>
                <b>Return Amount</b>
                <span style={{ color: "red" }}>*</span>
              </label>
              <div>
                <input
                  style={{ height: 40, width: "100%" }}
                  onChange={(e) => {
                    handleAmountChange(e.target.value);
                    setGpAmount(e.target.value);
                    setgpamerror(e.target.value);
                  }}
                  defultValue={empDetails?.g_p_fund?.collected_amount}
                  type={"text"}
                  placeholder="Enter amount"
                />
                {gpamerror == "Enter Amount" && (
                  <p style={{ color: "red" }}>{gpamerror}</p>
                )}
              </div>
            </div>
            <div className="form-group col-md-6">
              <label>
                <b>Total GP Fund Collected</b>
              </label>

              <div>
                <input
                  disabled={true}
                  style={{ height: 40, width: "100%" }}
                  value={empDetails?.g_p_fund?.collected_amount}
                  type={"number"}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "-5px" }}>
            <div className="form-group col-sm-6 flex-column d-flex">
              <label className="form-control-label">
                <b>
                  Select Bank Category{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </b>
              </label>
              <select
                className="form-select form-control"
                name="sub_account_id"
                value={selectedbankcateogry}
                onChange={(e) => {
                  setselectedbankcateogry(e.target.value);
                  console.log(allbanks);
                  let anserr = allbanks.filter(
                    (item) => item.id == e.target.value
                  );

                  if (anserr.length != 0) {
                    setallsubaccounts([...anserr[0].bank_accounts]);
                  } else {
                    setallsubaccounts([]);
                  }
                }}
                style={{ backgroundColor: "white" }}
                aria-label="Default select example"
              >
                <option acode="" selected value="">
                  Select...
                </option>
                {allbanks.map((item) => (
                  <option value={item.id}>{item.title}</option>
                ))}
              </select>
            </div>

            <div className="form-group col-sm-6 flex-column d-flex ">
              <label className="form-control-label">
                <b>
                  Select Bank <span style={{ color: "red" }}>*</span>
                </b>
              </label>
              <select
                className="form-select form-control"
                name="sub_account_id"
                value={selectedbank}
                onChange={(e) => {
                  setselectedbank(e.target.value);
                  // dispatch({
                  //   type: "CHALLAN_BANK",
                  //   payload: {
                  //     selectedbank_red: e.target.value,
                  //   },
                  // });
                  console.log(e.target.value);
                  setbank2(e.target.value);
                }}
                style={{ backgroundColor: "white" }}
                aria-label="Default select example"
              >
                <option acode="" selected value="">
                  Select...
                </option>
                {allsubaccounts.map((item) => (
                  <option value={item.id}>{item.bank_name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <div>
                <label>
                  <b style={{ height: 40 }}>Cheque No</b>
                </label>
              </div>
              <div>
                <input
                  disabled={bank2 == 1}
                  style={{ height: 40, width: "100%" }}
                  onChange={(e) => {
                    setchk(e.target.value);
                    setchkerror(e.target.value);
                  }}
                  id="searchInput"
                  type={"number"}
                  placeholder="Enter Cheque No"
                />
                {chkerror == "Please Set cheque No." && (
                  <p style={{ color: "red" }}>{chkerror}</p>
                )}
              </div>
            </div>
            <div className="form-group col-md-6">
              <div>
                <label>
                  <b>Account No</b>
                </label>
              </div>
              <div>
                <input
                  disabled
                  style={{ height: 40, width: "100%" }}
                  onChange={(e) => {
                    setacc(e.target.value);
                    setaccerror(e.target.value);
                  }}
                  value={empDetails?.account_no}
                  id="searchInput"
                  type={"text"}
                  placeholder="Employee Account No"
                />
                {accerror == "Please Set Account No" && (
                  <p style={{ color: "red" }}>{accerror}</p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <div>
                <label>
                  <b>Remarks</b>
                </label>
              </div>
              <input
                style={{ height: 40, width: "100%" }}
                name="remarks"
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
                value={remarks}
                type={"text"}
                placeholder="Enter Remarks"
              />
            </div>
          </div>

          <div className=" flex_last row m-10">
            <button
              onClick={() => {
                GenerateSalaryFunc();
                setOverview(true);
              }}
              className="btn btn-success button_blue"
              disabled={GPAmountError}
            >
              Submit
            </button>
            {/* <div
                                onClick={GenerateSalaryFunc}
                                className="col-md-6 col-sm-6">
                            </div> */}
            <button
              onClick={() => {
                setshowerr(false);
                setMonth("");
                show(false);
              }}
              className=" btn_padding_left btn btn-danger btn_width"
            >
              Cancel
            </button>
            {/* <div
                                onClick={() => show(false)}
                                className="col-md-6 col-sm-6">
                            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default GenerateGPFund;
