import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Modal, Alert } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import SVG from "react-inlinesvg";
import "./style.css";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";

import ViewModal from "./viewModal";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { upperCase } from "lodash";

export default function AllAccountVoucherPrint({ selectedAllVoucher }) {
  const [viewmodal, setviewmodal] = useState(false);
  let authtoken = localStorage.getItem("userToken");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [directorSign, setDirectorSign] = useState(0);

  // console.log(selectedVoucher, "selectedVoucher");
  console.log(selectedAllVoucher, "selectedAllVoucher for print");

  useEffect(() => {
    getAllSessions();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
        setDirectorSign(data.payload.settings.director_sign);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const printdoc = () => {
    function printElement(elem) {
      var $printSection1 = document.getElementById("printSection");
      if (!$printSection1) {
        var $printSection1 = document.createElement("div");
        $printSection1.id = "printSection";
        document.body.appendChild($printSection1);
      }

      $printSection1.innerHTML = elem;
      // $printSection1.appendChild(domClone);
      window.print();
    }

    printElement(document.getElementById("voucher-p").innerHTML);
  };

  // const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const alert = useAlert();
  //store

  //array states
  const [allsessions, setallsessions] = useState([]);
  //object states

  //strings states

  const [selectedsession, setselectedsession] = useState("");

  //boolean states
  let [loading, setloading] = useState(false);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  // const [viewmodal, setviewmodal] = useState(false);
  // const [show, setshow] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [submitloading, setsubmitloading] = useState(false);
  const [show3, setShow3] = useState(false);

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setallsessions(data.payload);

            data.payload.map((session, index) => {
              if (session.active_financial_year == "1") {
                setselectedsession(session.id);
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const todayDate = () => {
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  const [show2, setshow2] = useState(false);

  function inWords(num) {
    let a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lac "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
        : "";
    console.log(str);
    return str;
  }

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  // console.log(selectedVoucher.general_ledgers, "voucher print");

  return (
    <>
      {/* <div style={{ padding: 15 }}>
        <Modal size="xl" show={show}>
          <div id="voucher-p">
            <Modal.Body>
              <div>
                <div className="page1-content">
                  <div
                    style={{
                      display: "flex",
                      padding: "10px",
                    }}
                  >
                    <img
                      src={icon}
                      style={{ height: 100, width: 100 }}
                      alt="icon"
                    />
                    <h1
                      style={{
                        color: "#B4959D",
                        paddingLeft: "10px",
                        paddingTop: "25px",
                      }}
                    >
                      {unitName}
                    </h1>
                  </div>
                  <hr className="page1-line" />
                  <div className="dataPage1">
                    <div className="detailPage1">
                      <h5>Financial Year :- {selectedVoucher.session.year}</h5>
                      <h5>Printing Date :- {todayDate()}</h5>
                      <h5>
                        Printing Time :- {new Date().toLocaleTimeString()}
                      </h5>
                    </div>
                    <div className="voucherDetailPage1">
                      <h5>Voucher No:- {selectedVoucher.voucher_no}</h5>
                      <h5>Voucher Date:- {selectedVoucher.date} </h5>
                    </div>
                  </div>
                  <table className="page1-table">
                    <tr className="page1-tr">
                      <th
                        style={{ textAlign: "center", color: "black" }}
                        className="page1-th"
                      >
                        Sr No#
                      </th>
                      <th
                        style={{ textAlign: "center", color: "black" }}
                        className="page1-th"
                      >
                        Acc Code
                      </th>
                      <th
                        style={{ textAlign: "center", color: "black" }}
                        className="page1-th"
                      >
                        Account Name / Description
                      </th>
                      <th
                        style={{ textAlign: "center", color: "black" }}
                        className="page1-th"
                      >
                        Debit
                      </th>
                      <th
                        style={{ textAlign: "center", color: "black" }}
                        className="page1-th"
                      >
                        Credit
                      </th>
                    </tr>
                    {selectedVoucher.general_ledgers == undefined
                      ? null
                      : selectedVoucher.general_ledgers.map((item, index) => {
                          return (
                            <tr className="page1-tr">
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "1.2rem",
                                }}
                                className="page1-td"
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "1.2rem",
                                }}
                                className="page1-td"
                              >
                                {item.sub_account == null
                                  ? ""
                                  : item.sub_account.acode}
                              </td>
                              <td
                                style={{ textAlign: "center", color: "black" }}
                                className="page1-td"
                              >
                                {item.remarks != null ? (
                                  <>
                                    <div>
                                      {console.log(item?.campus?.name)}
                                      <span style={{ fontSize: "1.2rem" }}>
                                        <b>{item?.sub_account?.title}</b>
                                      </span>
                                      &nbsp;&nbsp;
                                      <span style={{ fontSize: "1.2rem" }}>
                                        {item?.campus?.name}
                                      </span>
                                      &nbsp;&nbsp;
                                    </div>
                                    <div>
                                      <p style={{ fontSize: "1.2rem" }}>
                                        <b>{item?.remarks}</b>
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    <span style={{ fontSize: "1.2rem" }}>
                                      <b>{item?.sub_account?.title}</b>
                                    </span>
                                    &nbsp;&nbsp;
                                    <span style={{ fontSize: "1.2rem" }}>
                                      {item?.campus?.name}
                                    </span>
                                    &nbsp;&nbsp;
                                  </div>
                                )}
                              </td>
                              <td
                                className="page1-td text-right"
                                style={{ color: "black", fontSize: "1.2rem" }}
                              >
                                {item.debit}
                              </td>
                              <td
                                className="page1-td text-right"
                                style={{ color: "black", fontSize: "1.2rem" }}
                              >
                                {item.credit}
                              </td>
                            </tr>
                          );
                        })}
                    <tr>
                      <td className="page1-td"></td>
                      <td className="page1-td"></td>
                      <td
                        className="page1-td text-right text-primary"
                        style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      >
                        {"Total: " +
                          inWords(parseInt(selectedVoucher.total_credit))}
                      </td>
                      <td
                        className="page1-td text-right text-primary"
                        style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      >
                        {selectedVoucher.total_credit == null
                          ? 0
                          : selectedVoucher.total_credit}
                      </td>
                      <td
                        className="page1-td text-right text-primary"
                        style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                      >
                        {selectedVoucher.total_debit == null
                          ? 0
                          : selectedVoucher.total_debit}
                      </td>
                    </tr>
                  </table>
                  <div className="lower-content">
                    <div className="lower1">
                      <div className="lower2">
                        <p>Prepared By</p>
                      </div>
                    </div>
                    <div className="lower1">
                      <div className="lower2">
                        <p>Accountant</p>
                      </div>
                    </div>
                    <div className="lower1" id="lowerLast">
                      {directorSign == 0 ? (
                        <div className="lower2">
                          <p>Chief Executive</p>
                        </div>
                      ) : (
                        <div className="lower2">
                          <p>Director</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ReactToPrint
                documentTitle={`demandPay Report`}
                pageStyle="auto"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue "
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />

              <button
                onClick={() => {
                  onHide();
                }}
                style={{ width: "150px", height: "40px", marginLeft: "10px" }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div> */}

      {/* //for print  */}
      <div style={{ visibility: "hidden" }}>
        {selectedAllVoucher?.map((selectedVoucher, i) => (
          <div style={{ pageBreakAfter: "always" }}>
            <div className="page1-content">
              <div
                style={{
                  display: "flex",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 120, width: 110 }}
                  alt="icon"
                />
                <h1
                  style={{
                    color: "#B4959D",
                    paddingLeft: "10px",
                    paddingTop: "25px",
                    fontSize: "35px",
                  }}
                >
                  {unitName}
                </h1>
              </div>

              <div className="dataPage1" style={{ margin: "15px 0" }}>
                <div className="detailPage1" style={{ marginTop: "-40px" }}>
                  <h5
                    style={{
                      fontSize: "19px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Financial Year :- {selectedVoucher.session.year}
                  </h5>
                  <h5
                    style={{
                      fontSize: "19px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Printing Date :- {todayDate()}
                  </h5>
                  <h5
                    style={{
                      fontSize: "19px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Printing Time :- {new Date().toLocaleTimeString()}
                  </h5>
                </div>
                <div className="voucherDetailPage1">
                  <h5
                    style={{
                      fontSize: "19px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Voucher No:- {selectedVoucher.voucher_no}
                  </h5>
                  <h5
                    style={{
                      fontSize: "19px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Voucher Date:- {selectedVoucher.date}{" "}
                  </h5>
                </div>
              </div>
              <table
                className="page1-table"
                style={{
                  fontSize: "20px",
                  whiteSpace: "normal",
                  padding: "1px",
                  marginTop: "-40px",
                }}
              >
                <tr
                  className="page1-tr"
                  style={{
                    fontSize: "19px",
                    whiteSpace: "normal",
                    padding: "1px",
                  }}
                >
                  <th
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "18px",
                      whiteSpace: "normal",
                      padding: "1px",
                      width: "4%",
                    }}
                    className="page1-th"
                  >
                    Sr.#
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "18px",
                      whiteSpace: "normal",
                      padding: "1px",
                      width: "10%",
                    }}
                    className="page1-th"
                  >
                    Acc Code
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "18px",
                      whiteSpace: "normal",
                      padding: "1px",
                      width: "66%",
                    }}
                    className="page1-th"
                  >
                    Account Name / Description
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "18px",
                      whiteSpace: "normal",
                      padding: "1px",
                      width: "10%",
                    }}
                    className="page1-th"
                  >
                    Debit
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "18px",
                      whiteSpace: "normal",
                      padding: "1px",
                      width: "10%",
                    }}
                    className="page1-th"
                  >
                    Credit
                  </th>
                </tr>
                {selectedVoucher.general_ledgers == undefined
                  ? null
                  : selectedVoucher.general_ledgers.map((item, index) => {
                      return (
                        <tr
                          className="page1-tr"
                          style={{
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "center",
                              color: "black",
                              fontSize: "20px",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                            className="page1-td"
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              color: "black",
                              fontSize: "20px",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                            className="page1-td"
                          >
                            {item.sub_account == null
                              ? ""
                              : item.sub_account.acode}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              color: "black",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                            className="page1-td"
                          >
                            {item.remarks != null ? (
                              <>
                                <div
                                  style={{
                                    marginTop: "-5px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      marginTop: "-20px",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                    }}
                                  >
                                    <b style={{ lineHeight: "2" }}>
                                      {item?.sub_account?.title}
                                    </b>
                                  </span>
                                  &nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      marginTop: "-20px",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      lineHeight: "1.35",
                                    }}
                                  >
                                    {item?.campus?.name && (
                                      <span>({item.campus.name})</span>
                                    )}
                                  </span>
                                  &nbsp;&nbsp;
                                </div>
                                <div
                                  style={{
                                    marginBottom: "-10px",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      marginTop: "-15px",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      lineHeight: "1.35",
                                    }}
                                  >
                                    {item?.remarks}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  margin: "-5px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "20px",
                                    marginTop: "-20px",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                >
                                  <b style={{ lineHeight: "2" }}>
                                    {item?.sub_account?.title}
                                  </b>
                                </span>
                                &nbsp;&nbsp;
                                <span
                                  style={{
                                    fontSize: "20px",
                                    marginTop: "-20px",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                    lineHeight: "1.35",
                                  }}
                                >
                                  {item?.campus?.name}
                                </span>
                                &nbsp;&nbsp;
                              </div>
                            )}
                          </td>
                          <td
                            className="page1-td text-center"
                            style={{
                              color: "black",
                              fontSize: "20px",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                          >
                            {item.debit}
                          </td>
                          <td
                            className="page1-td text-center"
                            style={{
                              color: "black",
                              fontSize: "20px",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                          >
                            {item.credit}
                          </td>
                        </tr>
                      );
                    })}
                <tr
                  style={{
                    color: "black",
                    whiteSpace: "normal",
                    padding: "1px",
                  }}
                >
                  <td
                    style={{
                      color: "black",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                    className="page1-td"
                  ></td>
                  <td
                    style={{
                      color: "black",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                    className="page1-td"
                  ></td>
                  <td
                    className="page1-td text-right text-primary"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontWeight: "bold",
                      paddingRight: "30px",
                    }}
                  >
                    <label style={{ lineHeight: "1.35" }}>
                      {"Total: " +
                        inWords(parseInt(selectedVoucher.total_credit))}
                    </label>
                  </td>
                  <td
                    className="page1-td text-center text-primary"
                    style={{
                      color: "black",
                      fontSize: "20px",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    {selectedVoucher.total_credit == null
                      ? 0
                      : selectedVoucher.total_credit}
                  </td>
                  <td
                    className="page1-td text-center text-primary"
                    style={{
                      color: "black",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {selectedVoucher.total_debit == null
                      ? 0
                      : selectedVoucher.total_debit}
                  </td>
                </tr>
              </table>
              <div className="lower-content">
                <div className="lower1">
                  <div className="lower2">
                    <p style={{ fontSize: "1.5rem" }}>Prepared By</p>
                  </div>
                </div>
                <div className="lower1">
                  <div className="lower2">
                    <p style={{ fontSize: "1.5rem" }}>Accountant</p>
                  </div>
                </div>
                <div className="lower1" id="lowerLast">
                  <div className="lower2">
                    {directorSign == 0 ? (
                      <p style={{ fontSize: "1.5rem" }}>Chief Executive</p>
                    ) : (
                      <p style={{ fontSize: "1.5rem" }}>Director</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
