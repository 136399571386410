import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import TextInput from '../../../components/TextInput';
import { baseUrl } from '../../../services/config';
import { useHistory } from 'react-router-dom';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: 'green'
  },
  input: {
    display: 'none',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);





export default function Vehicles() {

  const history = useHistory()

  const classes = useStyles();
  const [modalvisible, setmodalvisible] = useState(false)
  const [rp_number, setrp_number] = useState('')
  const [model, setmodel] = useState('')
  const [seats, setseats] = useState('')
  const dispatch = useDispatch()
  const authtoken = localStorage.getItem('userToken')
  const vehicles = useSelector(state => state.vehicles.vehicles)
  const user = JSON.parse(localStorage.getItem('userDetails'))
  console.log(vehicles)
  useEffect(() => {

    fetch(`${baseUrl}/vehicle`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authtoken}`
      }
    })
      .then((res) => res.json())
      .then((vehicle) => {
        dispatch({
          type: 'GET_VEHICLES',
          payload: vehicle.payload
        })
      })
      .catch((err) => {
        console.log(err)
      })

  }, [])







  const AddNewVehiclesHandler = () => {

    const data = {
      rp_number,
      model,
      seats
    }

    fetch(`${baseUrl}/vehicle`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authtoken}`
      },
      body: JSON.stringify(data)
    }).then(res => res.json()
      .then(data => {
        console.log(data)
      })

    ).catch(err => {
      console.log(err)
    })
  }
  const routeTo = () => {
    history.push('/addvehicle',{
        bearerToken:authtoken
    })
}


  return (
    <Paper className={classes.root}>

      <div style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
        {/* <InputGroup size="md">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
        </InputGroup> */}
        <Button
          style={{ margin: 5 }}
          onClick={() => routeTo()}
          size="small" variant="outline-success" className={classes.button}>
          Add Vehicle
        </Button>
      </div>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>

            <StyledTableCell>RP Number  </StyledTableCell>
            <StyledTableCell>Model</StyledTableCell>
            <StyledTableCell>Seats</StyledTableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          {vehicles.map(row => (
            user.campus.id == row.campus_id ? <>
              <StyledTableRow key={row.rp_number}>

                <StyledTableCell align="left">{row.rp_number}</StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {row.seats}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.model}
                </StyledTableCell>

              </StyledTableRow> </>
              : null
          ))}
        </TableBody>
      </Table>


      {/* <Modal
        size="lg"
        show={modalvisible}
        onHide={() => setmodalvisible(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Bank
          </Modal.Title>

        </Modal.Header>
        <Modal.Body>

          <TextInput label="RP Number" type="text" handleChange={(e) => setrp_number(e.target.value)} />
          <TextInput label="Model" type="text" handleChange={(e) => setmodel(e.target.value)} />
          <TextInput label="Seats" type="text" handleChange={(e) => setseats(e.target.value)} />

          <Button variant="success" onClick={AddNewVehiclesHandler} type="submit" value="Submit">Submit</Button>

          <Button variant="danger" style={{ margin: 10 }} type="reset" value="Reset" >Reset</Button>

          <Button onClick={() => setmodalvisible(false)} variant="outline-warning" style={{ float: 'right' }}  >Cancel</Button>


        </Modal.Body>
      </Modal> */}

    </Paper>
  );
}


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },


}));