import React, { useState, useEffect, useRef } from "react";

import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import "./printTable.css";
import { useAlert } from "react-alert";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";

const pageStyle = `
  @page {
  margin-left: 1.5in;
    size: legal landscape !important;
}
`;

const DemandPaySheet = () => {
  let authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const [landscape, setLandscape] = useState(true);
  const [reportDetails, setreportDetails] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState("");
  const [excludeClassFour, setExcludeClassFour] = useState("");
  const [excludeOtherStaff, setExcludeOtherStaff] = useState("");
  const [date, setdate] = useState("");
  const [wholeCampus, setWholeCampus] = useState({});

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  const [directorSign, setDirectorSign] = useState(0);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // getAllCampus();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  const SearchReport = () => {
    if (selectedcampus == "") {
      alert.error("Please select campus");
      return;
    }
    if (date == "") {
      alert.error("Please select Date");
      return;
    }

    const data = {
      date,
      campus_id: selectedcampus,
      exclude_class_four: excludeClassFour ? 1 : 0,
      exclude_other_staff: excludeOtherStaff ? 1 : 0,
    };

    setloading(true);
    fetch(`${baseUrl}/demand_pay_sheet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setloading(false);
            setIsVisible(true);

            let res = data.payload.employee.sort(
              (a, b) => a.emp_code - b.emp_code
            );
            setreportDetails(res);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };

  const yearSplice = (item) => {
    console.log(item);
    return item.slice(0, 4);
  };
  const qualSplice = (item) => {
    if (item == null) {
    } else {
      const data = item.toString().slice(0, 10);
      if (data.length == 10) {
        return data + "..";
      } else {
        return data;
      }
    }
  };

  const todayDate = () => {
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  const handleCampusChange = (id) => {
    setselectedcampus(id);
    const reser = campuses.find((item) => item.id == id);
    setWholeCampus(reser);
  };

  function convertDateFormat(inputDate) {
    var dateObject = new Date(inputDate);

    var day = dateObject.getDate();
    var month = dateObject.getMonth() + 1;
    var year = dateObject.getFullYear();

    // Format the components in "DD-MM-YYYY" format
    var convertedDate =
      (day < 10 ? "0" : "") +
      day +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      year;

    return convertedDate;
  }

  return (
    <div>
      <Paper className="p-5 mt-5 mb-5">
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => handleCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value=""> Select Campus</option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Date</b>
            </label>
            <input
              className="form-control"
              style={{ height: 40 }}
              type={"date"}
              onChange={(e) => setdate(e.target.value)}
            />
          </div>

          <div
            className="col-md-2"
            style={{ display: "flex", alignItems: "center", marginTop: 10 }}
          >
            <input
              id="classFour"
              onChange={(e) => setExcludeClassFour(e.target.checked)}
              type={"checkbox"}
            />
            <label className="mt-4" for="classFour">
              Exclude Class Four
            </label>
          </div>

          <div
            className="col-md-2"
            style={{ display: "flex", alignItems: "center", marginTop: 10 }}
          >
            <input
              id="otherStaff"
              onChange={(e) => setExcludeOtherStaff(e.target.checked)}
              type={"checkbox"}
            />
            <label className="mt-4" for="otherStaff">
              Exclude Other Staff
            </label>
          </div>
        </div>

        <button onClick={SearchReport} className="mt-5 btn btn-primary">
          Search
        </button>
        {reportDetails?.length > 0 ? (
          <ReactToPrint
            documentTitle={`demandPay Report`}
            pageStyle={pageStyle}
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint={true}
            trigger={() => (
              <button
                // onClick={() => console.log("dfsdfsdfd")}
                disabled={printloading ? true : false}
                style={{ marginLeft: "10px" }}
                className="btn btn-primary w-105px mt-5"
              >
                {printloading ? <span>Printing..</span> : "Print"}
              </button>
            )}
            content={() => componentRef.current}
          />
        ) : null}

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}
        {isVisible ? (
          <>
            <div className="row p-3 mt-5">
              <img
                // className='col-md-2'
                style={{ height: 100, width: 110 }}
                src={icon}
              />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h4>Demand Pay Sheet</h4>

                <div className="d-flex justify-content-between">
                  <h5>Month:- {getMonthName(date)}</h5>
                  <h5>Campus Name:- {wholeCampus.name} </h5>
                  <h5>Printing Date :- {todayDate()}</h5>
                </div>
              </div>
            </div>

            <ColoredLine color={"#FF0000"} />

            <Table responsive>
              <thead>
                <tr>
                  <th style={{ textAlign: "start" }}>Sr.</th>
                  <th style={{ textAlign: "start" }}>Code</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  {/* <th style={{ textAlign: "start" }}>CNIC</th> */}
                  <th style={{ textAlign: "start" }}>Scale</th>
                  <th style={{ textAlign: "start" }}>Desig.</th>
                  <th style={{ textAlign: "start" }}>Account No</th>
                  <th style={{ textAlign: "start" }}>Joining</th>
                  <th style={{ textAlign: "start" }}>Qual.</th>
                  <th style={{ textAlign: "start" }}>Leaving Date</th>
                  <th style={{ textAlign: "start" }}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {reportDetails &&
                  reportDetails.map((report, index) => (
                    <tr key={index + 1}>
                      <td style={{ textAlign: "start" }}>{index + 1}</td>
                      <td style={{ textAlign: "start" }}>{report.emp_code}</td>
                      <td style={{ textAlign: "start" }}>{report.full_name}</td>
                      {/* <td style={{ textAlign: "start" }}>{report.cnic_no}</td> */}
                      <td style={{ textAlign: "start" }}>
                        {report.pay_scale.payscale}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report?.designation?.name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report.account_no}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {convertDateFormat(report.joining_date)}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {qualSplice(report.qualification)}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report.leaving_date}
                      </td>
                      <td style={{ textAlign: "start" }}></td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : null}
      </Paper>

      {/* /////for print  */}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint demandPaymargin"
      >
        <div className="row p-3">
          <img
            // className='col-md-2'
            style={{ height: 100, width: 110 }}
            src={icon}
          />

          <div className="col-md-10">
            <h1>{unitName}</h1>
            <h4>Demand Pay Sheet</h4>

            <div className="d-flex justify-content-between">
              <h5>Month:- {getMonthName(date)}</h5>
              <h5>Campus Name:- {wholeCampus.name} </h5>
              <h5>Printing Date :- {todayDate()}</h5>
            </div>
          </div>

          {/* <div>

                    </div> */}
        </div>

        <ColoredLine color={"#FF0000"} />
        <table style={{ width: "100%" }}>
          <thead>
            <tr className="headtr">
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Sr.</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Code</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Name</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Scale</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Designation</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Account No</b>{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Joining</b>{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Qualification</b>
              </td>
              <td
                className="demandLeaving"
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Leaving</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  padding: "0px 2px",
                }}
              >
                <b>Remarks</b>
              </td>
            </tr>
          </thead>

          <tbody>
            {reportDetails &&
              reportDetails.map((report, index) => (
                <tr className="thisistr" key={index + 1}>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    <b>{report.emp_code}</b>
                  </td>
                  <td
                    className="demandName"
                    style={{
                      textAlign: "start",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                      paddingLeft: "3px",
                    }}
                  >
                    {report.full_name}
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    {report.pay_scale.payscale}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                      paddingLeft: "3px",
                    }}
                  >
                    {report?.designation?.name}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    {report.account_no}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    {convertDateFormat(report.joining_date)}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    {qualSplice(report.qualification)}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      padding: "0px",
                    }}
                  >
                    {report.leaving_date}
                  </td>
                  <td
                    className="demandremarks"
                    style={{
                      textAlign: "center",
                      border: "1px solid black ",
                      color: "black",
                      padding: "0px",
                    }}
                  ></td>
                </tr>
              ))}
          </tbody>
        </table>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "50px" }}
        >
          <div style={{ marginTop: "13px !important" }}>
            <div
              style={{
                paddingRight: "30px",
                paddingLeft: "30px",
                borderTop: "1px solid black",
              }}
            >
              <p style={{ fontSize: "1.25rem" }}>
                <b>Campus Incharge</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandPaySheet;
const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
