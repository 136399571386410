import React, { useState, useRef, useEffect } from "react";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import { upperCase } from "lodash";

const RegistrationSlip = ({ show, close, studentdetails }) => {
  const pageStyle = `
  @page {
    size: a4 !important;
}
`;
  const componentRef = useRef(null);
  const authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const onBeforeGetContentResolve = React.useRef(null);
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const formatdate2 = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  return (
    <div>
      <Modal size="xl" show={show}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Student Registration Slip
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="card" style={{ margin: "0" }}>
            {/* <h2 className="text-center" style={{ margin: 10 }}>
              CONGRATULATIONS
            </h2> */}

            <div className=" col-md-12 d-flex ">
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Name : &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails.name == undefined
                        ? null
                        : studentdetails.name.length > 20
                        ? studentdetails.name
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails.name == undefined
                      ? null
                      : " " + studentdetails.name}
                  </h5>
                </div>
              </div>
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Father Name : &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails.father_name == undefined
                        ? null
                        : studentdetails.father_name.length > 15
                        ? studentdetails.father_name
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails.father_name == undefined
                      ? null
                      : " " + studentdetails.father_name}
                  </h5>
                </div>
              </div>
            </div>
            <hr />

            <div className=" col-md-12 d-flex ">
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Date of Birth : &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails.dob == undefined
                        ? null
                        : studentdetails.dob.length > 15
                        ? studentdetails.dob
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails.dob == undefined
                      ? null
                      : " " + studentdetails.dob}
                  </h5>
                </div>
              </div>
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>
                    Registration ID : &ensp;
                  </h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails?.registration_id == undefined
                        ? "---"
                        : studentdetails?.registration_id
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails?.registration_id == undefined
                      ? null
                      : " " + studentdetails?.registration_id}
                  </h5>
                </div>
              </div>
            </div>

            <hr />

            <div className=" col-md-12 d-flex ">
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Gender : &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails.gender == undefined
                        ? null
                        : studentdetails.gender.length > 15
                        ? studentdetails.gender
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails.gender == undefined
                      ? null
                      : studentdetails.gender.length > 15
                      ? " " + studentdetails.gender.substring(0, 12) + "..."
                      : " " + studentdetails.gender}
                  </h5>
                </div>
              </div>
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Mobile No# : &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails.mobile_no == undefined
                        ? null
                        : studentdetails.mobile_no.length > 15
                        ? studentdetails.mobile_no
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails.mobile_no == undefined
                      ? null
                      : " " + studentdetails.mobile_no}
                  </h5>
                </div>
              </div>
            </div>

            <hr />
            <div className=" col-md-12 d-flex ">
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Class : &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails.student_class_name == undefined
                        ? null
                        : studentdetails.student_class_name.length > 15
                        ? studentdetails.student_class_name
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails.student_class_name == undefined
                      ? null
                      : " " + studentdetails.student_class_name}
                  </h5>
                </div>
              </div>
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Section: &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails?.global_section?.name == undefined
                        ? "---"
                        : studentdetails?.global_section?.name.length > 15
                        ? studentdetails?.global_section?.name
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails?.global_section?.name == undefined
                      ? " " + "---"
                      : " " + studentdetails?.global_section?.name}
                  </h5>
                </div>
              </div>
            </div>

            <hr />

            <div className=" col-md-12 d-flex ">
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Joining date : &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails?.Joining_date == undefined
                        ? null
                        : studentdetails?.Joining_date.length > 15
                        ? studentdetails?.Joining_date
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails?.Joining_date == undefined
                      ? null
                      : " " + studentdetails?.Joining_date}
                  </h5>
                </div>
              </div>
              <div className=" col-md-6 d-flex ">
                <div>
                  {" "}
                  <h5 style={{ color: "grey" }}>Father CNIC# : &ensp;</h5>{" "}
                </div>
                <div>
                  <h5
                    title={
                      studentdetails.father_cnic == undefined
                        ? null
                        : studentdetails.father_cnic.length > 15
                        ? studentdetails.father_cnic
                        : null
                    }
                    style={{
                      color: "grey",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {studentdetails.father_cnic == undefined
                      ? null
                      : " " + studentdetails.father_cnic}
                  </h5>
                </div>
              </div>
            </div>
            <hr />
            <div className="row mx-3">
              <div className="col-md-12">
                <h5 style={{ color: "grey" }}>
                  <b>Address:</b> &ensp;
                </h5>{" "}
              </div>
              <div className="col-md-12">
                {/* <h5>{studentdetails.name}</h5> */}
                <h5
                  title={
                    studentdetails.address == undefined
                      ? null
                      : studentdetails.address
                  }
                  style={{
                    color: "grey",
                    textAlign: "start",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {studentdetails.address == undefined
                    ? null
                    : studentdetails.address}
                </h5>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {studentdetails ? (
            <ReactToPrint
              pageStyle={pageStyle}
              documentTitle={`Student Registration Slip`}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint={true}
              trigger={() => (
                <button
                  onClick={() => console.log("dfsdfsdfd")}
                  disabled={printloading ? true : false}
                  className="btn btn-primary"
                  style={{ marginLeft: "10px" }}
                >
                  {printloading ? (
                    <span>Printing..</span>
                  ) : (
                    "Print Registration Slip"
                  )}
                </button>
              )}
              content={() => componentRef.current}
            />
          ) : null}

          <button
            onClick={() => {
              close();
            }}
            className="btn btn-danger btn_width"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        {studentdetails ? (
          <>
            <div className="p-3" style={{ display: "flex" }}>
              <img style={{ height: 100, width: 110 }} src={icon} />

              <div className="col-md-6 ml-4" style={{ paddingTop: "10px" }}>
                <h1>{unitName}</h1>
                <h4>
                  REGISTRATION SLIP FOR THE ACADEMIC SESSION{" "}
                  {studentdetails?.session?.year}
                </h4>
                <h4>Printing Date:- {formatdate2()}</h4>
              </div>
            </div>
            <hr style={{ border: "2px solid prrimary" }} />

            <div
              className="d-flex justify-content-between p-5"
              style={{ border: "2px solid #121291" }}
            >
              <div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Registration ID:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "140px", fontSize: "16px" }}>
                    {studentdetails?.registration_id}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Student Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "144px", fontSize: "16px" }}>
                    {studentdetails?.name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Father Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "156px", fontSize: "16px" }}>
                    {studentdetails?.father_name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Contact No.:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "166px", fontSize: "16px" }}>
                    {studentdetails?.mobile_no}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Class Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "166px", fontSize: "16px" }}>
                    {studentdetails?.student_class_name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Section Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "148px", fontSize: "16px" }}>
                    {studentdetails?.global_section_name == ""
                      ? "---"
                      : studentdetails?.global_section_name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Campus Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "137px", fontSize: "16px" }}>
                    {studentdetails?.campus?.name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Registration Date :-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "115px", fontSize: "16px" }}>
                    {studentdetails?.Joining_date}
                  </h6>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default RegistrationSlip;
