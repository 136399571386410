import React, { useState } from 'react'
import { baseUrl } from '../../../services/config'
import { useSelector } from 'react-redux'
import { useAlert } from 'react-alert'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import { Input, DatePickerField, Select } from "../../../_metronic/_partials/controls";


import { useFormik, Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";



const AddSubAccount = ({ getChartOfAcoounts, show, selectedAccount, acode_id }) => {
    // const authtoken = useSelector(state => state.user.authtoken)
    const authtoken = localStorage.getItem('userToken')

    const alert = useAlert()

    const [loading, setloading] = useState(false)
    const [ispasswordchange, setispasswordchange] = useState(true)

    const classes = ["form-control"];


    console.log(selectedAccount, "selectedAccount")

    const initialValues = {
        "account_chart_id": selectedAccount.id,
        "acode": acode_id,
        "title": "",
    };

    const AddSubAccountSchema = Yup.object().shape({



        acode: Yup.string()
            .required('Acode is required')
            .matches(
                /^[0-9]{8}$/,
                "Must Have 8 Numbers"
            ),

        title: Yup.string()
            .required(' Title is required'),

        account_chart_id: Yup.string()
            .required(' Account Chart Id is required '),




    });









    const addAccount = async (values) => {
        setloading(true);

        const data = {
            account_chart_id: parseInt(values.account_chart_id),
            acode: parseInt(values.acode),
            title: values.title

        }
        console.log(data)


        fetch(`${baseUrl}/subaccount`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authtoken}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json().then((data) => {
                console.log(data)
                show(false)
                setloading(false);
                if (data.metadata) {
                    if (data.metadata.success) {

                        getChartOfAcoounts()
                    }
                }

            }))
            .catch(err => {
                show(false)
                setloading(false);
                console.log(err)
            })
    }








    return (
        <div>
            {
                loading ? <b>Account Updating Please Wait {` `} <span className="spinner-border"></span></b> :

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={AddSubAccountSchema}

                        onSubmit={(values) => {
                            addAccount(values)
                        }}

                    >
                        {({ handleSubmit, handleChange, setFieldValue, isSubmitting, isValid, errors, touched, values }) => (

                            <>
                                <Form autoComplete="off">
                                    <div className="row mt-3">
                                        <div className="col-md-6 ">
                                            <label>Account ID <span style={{ color: "red" }}>*</span></label>
                                            <Field
                                                name="account_chart_id"
                                                value={values.account_chart_id}
                                                // defaultValue={values.title}
                                                component={Input}
                                                // value={values.title}
                                                placeholder="Account Chart Id"
                                                disabled

                                                maxLength={20}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 ">
                                            <label>Title <span style={{ color: "red" }}>*</span></label>
                                            <Field
                                                name="title"
                                                value={values.title}
                                                // defaultValue={values.title}
                                                component={Input}
                                                // value={values.title}
                                                placeholder="Title"

                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label>acode <span style={{ color: "red" }}>*</span></label>
                                            <Field
                                                value={values.acode}
                                                name="acode"
                                                // value={values.acode}
                                                component={Input}
                                                placeholder="acode"
                                                maxLength={8}


                                            />
                                        </div>


                                    </div>



                                </Form>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button className="btn btn-success  button_blue" onClick={() => handleSubmit()} type="submit" value="Submit">
                                        {
                                            loading ? <span className="spinner-border"></span> : <>Submit</>
                                        }

                                    </button>

                                    <button onClick={() => show(false)} className="btn btn-danger btn_width ml-3" >Cancel</button>
                                </div>


                            </>
                        )}
                    </Formik>
            }


        </div >
    )
}

export default AddSubAccount
