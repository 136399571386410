import { useSelector } from 'react-redux'



export const PremissionChecker2 = (user, permissionName) => {
    if (user) {
        // return user?.role[0]?.permissions.some(permission => permission.name.toLowerCase() === permissionName.toLowerCase());
        for (let i = 0; i < user.role[0]?.permissions.length; i++) {
            if (user.role[0]?.permissions[i].name === permissionName) {
                return true;
            }
        }
        return false;
    }


};

// export const PremissionChecker= (user,permissionName) => {
//     if (user) {
//         console.log(user,'user')
//         console.log(user?.roles[0]?.permissions.some(permission => permission.name.toLowerCase() === permissionName.toLowerCase()),"result")
//         return user?.roles[0]?.permissions.some(permission => permission.name.toLowerCase() === permissionName.toLowerCase());

//     }


// };


export const PremissionChecker = (user, permissionName) => {
    if (user) {
        // console.log(user, 'user');

        // Check each role for the specified permission
        const hasPermission = user?.roles?.some(role =>
            role.permissions.some(permission => permission.name.toLowerCase() === permissionName.toLowerCase())
        );

        // console.log(hasPermission, "result");
        return hasPermission;
    }
    return false;
};


