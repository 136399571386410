import React, { useEffect, useState } from "react";
// import "./BalanceSheet.css";
import { baseUrl } from "../../../../services/config";
import { useSelector } from "react-redux";

const ExpensesReport = () => {
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const [allCampus, setAllCampus] = useState(user.campuses || []);
  const [selectedCampus, setSelectedCampus] = useState();
  const [reportDAta, setReportData] = useState([]);
  const [allSession, setAllSession] = useState([]);
  const [selectedSession, setSelectedSession] = useState();

  useEffect(() => {
    // getAllCampus();
    getAllSession();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setAllCampus(data.payload);
          console.log("CAMPUSES DATA", data.payload);
        })
      )
      .catch((err) => console.log(err));
  };

  const getAllSession = async () => {
    await fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setAllSession(data.payload);
          console.log("Session data", data.payload);
        })
      )
      .catch((err) => console.log(err));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      year_id: selectedSession,
      campus_id: selectedCampus,
    };

    console.log(data, "posting data");

    await fetch(`${baseUrl}/account_expenses_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setReportData(data.payload.balance_sheet);
          console.log("REPORTS DATA", data.payload.balance_sheet);
        })
      )
      .catch((err) => console.log(err));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label>Select Session</label>
        <select
          name="year_id"
          value={selectedSession}
          onChange={(e) => setSelectedSession(e.target.value)}
          aria-label="Default select example"
        >
          <option>Select...</option>
          {allSession.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.year}
              </option>
            );
          })}
        </select>{" "}
        <label>Select Campus</label>
        <select
          name="campus_id"
          value={selectedCampus}
          onChange={(e) => setSelectedCampus(e.target.value)}
          aria-label="Default select example"
        >
          <option>Select...</option>
          {allCampus.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
        <button type="submit">Submit</button>
      </form>
      <div className="reportsheet-topheadings">
        <h3 className="topheading">Example Corporation</h3>
        <h3 className="topheading">Balance Sheet</h3>
        <h3 className="topheading">December 31, 2021</h3>
      </div>

      {reportDAta.map((item, index) => {
        return (
          <div className="report-main-div" key={index}>
            <div>
              <h1>{item.title}</h1>
              {item.account_group.map((item2, index) => {
                return (
                  <div key={index}>
                    <h3>{item2.title}</h3>
                    {item2.account_chart.map((item3, index) => {
                      return (
                        <div key={index}>
                          <p>
                            {item3.title} acode: {item3.acode} debit:{" "}
                            {item3.debit}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ExpensesReport;
