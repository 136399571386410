import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../services/config";
import { Divider, DatePicker } from "antd";
import { Spinner, Table } from "react-bootstrap";
import UsePaginations from "../../../components/usePagination/UsePaginations";

const { RangePicker } = DatePicker;

const ActionReport = () => {
  let authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);

  const [users, setUsers] = useState([]);
  const [actionLogs, setActionLogs] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [logreport, setLogReport] = useState([]);
  const [links, setLinks] = useState([]);
  const [action, setAction] = useState("");
  const [user_id, setUserId] = useState("");

  useEffect(() => {
    GetAllCampuses();
    GetActionsLogs();
  }, []);

  const GetAllCampuses = async () => {
    await fetch(`${baseUrl}/users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((headoffices) => {
        setUsers(headoffices.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const GetActionsLogs = async () => {
    await fetch(`${baseUrl}/action-logs/actions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(Object.values(data.payload));

        setActionLogs(Object.values(data.payload));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchLogs = async (url) => {
    setloading(true);
    const data = {
      action,
      user_id,
      start_date: selectedDates[0],
      end_date: selectedDates[1],
    };
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLogReport(data.payload.data);
        setLinks(data.payload.links);
        console.log("this is response", data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <Paper style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}>
      <div className="row">
        <div className="col-md-3">
          <select
            onChange={(e) => setUserId(e.target.value)}
            className="form-select"
          >
            <option>Select User</option>
            {users.map((usr) => (
              <option value={usr.id}>
                {usr.first_name} {usr.last_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <select
            onChange={(e) => setAction(e.target.value)}
            className="form-select"
          >
            <option>Select Action</option>
            {actionLogs.map((usr) => (
              <option value={usr}>{usr}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <RangePicker
            onChange={(date, dateString) => setSelectedDates(dateString)}
          />
        </div>

        <div className="col-md-3">
          <button
            onClick={() => searchLogs(`${baseUrl}/action-logs/report`)}
            className="btn btn-primary"
          >
            Search Logs
          </button>
        </div>
      </div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">Action</th>
                <th className="text-center">User</th>
                <th className="text-center">Admission Id/Employe Code</th>
                <th className="text-center">Date & Time</th>
                <th className="text-center">Employe Code</th>
                <th className="text-center">Previous Data</th>
                <th className="text-center">New Data</th>
              </tr>
            </thead>
            <tbody>
              {logreport &&
                logreport.map((report) => (
                  <tr key={report.id}>
                    <td className="text-center">{report?.action}</td>
                    <td className="text-center">{report?.user}</td>
                    <td className="text-center">
                      {report?.admission_id
                        ? report?.admission_id
                        : report?.emp_code}
                    </td>
                    <td className="text-center">{report?.date_time}</td>
                    <td className="text-center">{report?.previous_data}</td>
                    <td className="text-center">{report?.new_data}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <UsePaginations
            links={links}
            onPaginate={(link) => {
              searchLogs(link.url);
            }}
          />
        </>
      )}
    </Paper>
  );
};

export default ActionReport;
