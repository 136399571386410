import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import SVG from "react-inlinesvg";
import { Table, Modal, Alert, Spinner } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../components/usePagination/usePagination";
import { MdDelete } from "react-icons/md";
import { FaFileDownload } from "react-icons/fa";
import MySelect from "./../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { PremissionChecker } from "../../../services/PremissionChecker";

const BranchNotification = () => {
  const user = useSelector((state) => state.user.user);
  const authtoken = localStorage.getItem("userToken");

  const [loading, setloading] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editNotificationLoading, setEditNotificationLoading] = useState(false);
  const alert = useAlert();

  const [branchNotifyData, setBranchNotifyData] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [classesData, setClassesData] = useState([]);

  const [notificationType, setNotificationType] = useState("");
  const [optionSelected, setOptionSelected] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [title, setTtile] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState([]);

  const [editNotifyModal, setEditNotifyModal] = useState(false);
  const [editNotifyID, setEditNotifyID] = useState([]);
  const [editNotifyType, setEditNotifyType] = useState("");
  const [editOptionSelected, setEditOptionSelected] = useState([]);
  const [editClassIds, setEditClassIds] = useState([]);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editExpiryDate, setEditExpiryDate] = useState("");

  const [deleteNotificationModal, setDeleteNotificationModal] = useState(false);
  const [deleteableNotification, setDeleteableNotification] = useState("");

  // Notifications Type Variables
  const [notificationeTypeData, setNotificationTypeData] = useState([]);
  const [notificationTypeModal, setNotificationTypeModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [NotificationName, setNotificationName] = useState("");

  const [editNotificationModal, setEditNotificationModal] = useState(false);
  const [editNotificationId, setEditNotificationId] = useState([]);
  const [editNotificationName, setEditNotificationName] = useState("");

  const [deleteNotifyTypeModal, setdeleteNotifyTypeModal] = useState(false);
  const [deleteableNotify, setDeleteableNotifyType] = useState([]);
  const [deleteloading, setdeleteloading] = useState(false);
  const [branchUrl, setBranchUrl] = useState("");

  useEffect(() => {
    getSettingData();
    getClasses();
    getNotificationType();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBranchUrl(data.payload.settings.head_office_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBranchNotification = async () => {
    setloading(true);
    if (branchUrl == "") {
      return alert.error("Head Office Url is Required");
    }
    await fetch(`${branchUrl}/branch-notification`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata.success) {
            setBranchNotifyData(data.payload);
            setloading(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setloading(false);
          } else {
            alert.show("Something Went Wrong");
            setloading(false);
          }
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const getClasses = async () => {
    await fetch(`${baseUrl}/studentclasses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setClassesData(data.payload);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const getNotificationType = async () => {
    await fetch(`${baseUrl}/branch-notification-types`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setNotificationTypeData(data.payload);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  // Handle Add, Update and Delete Branch Notification
  const addNotificationHandler = async () => {
    if (
      notificationType == "" ||
      classIds == "" ||
      title == "" ||
      expiryDate == ""
    ) {
      setisallfields(true);
      return;
    }
    setAddLoading(true);
    setisallfields(false);

    const formData = new FormData();
    formData.append("branch_notification_type_id", notificationType);
    for (let i = 0; i < classIds.length; i++) {
      formData.append(`student_class_ids[${i}]`, classIds[i]);
    }
    formData.append("title", title);
    formData.append("description", description);
    formData.append("expiry_date", expiryDate);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    console.log([...formData]);

    await fetch(`${baseUrl}/branch-notification`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "testing");
        if (data.metadata) {
          setAddLoading(false);
          if (data.metadata.success) {
            alert.success("Branch Notification Successfully Added");
            setNotificationType("");
            setClassIds([]);
            setOptionSelected([]);
            setTtile("");
            setDescription("");
            setExpiryDate("");
            setFiles([]);
            getBranchNotification();
            setAddModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setAddModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setAddModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAddLoading(false);
      });
  };

  const getEditNotification = async (id) => {
    await fetch(`${baseUrl}/branch-notification/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditNotifyType(data.payload?.branch_notification_type_id);
          setEditTitle(data.payload?.title);
          setEditDescription(data.payload?.description);
          setEditExpiryDate(data.payload?.expiry_date);

          setEditOptionSelected(
            data.payload?.student_classes.map((item) => ({
              value: item.student_class.id,
              label: item.student_class.name,
            }))
          );
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateNotificationHandler = async () => {
    if (editNotifyType == "" || editTitle == "" || editExpiryDate == "") {
      setisallfields(true);
      return;
    }
    setEditNotificationLoading(true);
    setisallfields(false);

    let actualClasses = [];
    editOptionSelected.map((item, index) => {
      actualClasses[index] = item.value;
    });
    const formData = new FormData();
    formData.append("branch_notification_type_id", editNotifyType);
    if (editClassIds.length > 0) {
      for (let i = 0; i < editClassIds.length; i++) {
        formData.append(`student_class_ids[${i}]`, editClassIds[i]);
      }
    } else {
      for (let i = 0; i < actualClasses.length; i++) {
        formData.append(`student_class_ids[${i}]`, actualClasses[i]);
      }
    }
    formData.append("title", editTitle);
    formData.append("description", editDescription);
    formData.append("expiry_date", editExpiryDate);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    await fetch(`${baseUrl}/branch-notification/${editNotifyID}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setEditNotificationLoading(false);
            setEditNotifyModal(false);
            alert.success("Branch Notification successfully Updated");
            setFiles([]);
            setEditClassIds([]);
            getBranchNotification();
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setEditNotificationLoading(false);
            setEditNotifyModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditNotificationLoading(false);
            setEditNotifyModal(false);
          }
        })
      )
      .catch((err) => {
        setEditNotificationLoading(false);
        setEditNotifyModal(false);
        console.log(err);
      });
  };

  const deleteNotificationHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/branch-notification/${deleteableNotification}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            setBranchNotifyData([]);
            getBranchNotification();
            setdeleteloading(false);
            setDeleteNotificationModal(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setdeleteloading(false);
            setDeleteNotificationModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setdeleteloading(false);
          setDeleteNotificationModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setDeleteNotificationModal(false);
      });
  };

  const showViewModal = (notification) => {
    setSelectedNotification(notification);
    setShowModal(true);
  };

  // Test Type Function
  const NotificationTypeHandler = async () => {
    if (NotificationName == "") {
      return alert.error("Notification Type Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: NotificationName,
    };
    setSubmitLoading(false);
    await fetch(`${baseUrl}/branch-notification-types`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Notification Type Successfully Added");
            setNotificationName("");
            getNotificationType();
            setNotificationTypeModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setNotificationTypeModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setNotificationTypeModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const getEditNotificationType = async (id) => {
    await fetch(`${baseUrl}/branch-notification-types/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditNotificationName(data.payload?.name);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateNotificationTypeHandler = async () => {
    if (editNotificationName == "") {
      return alert.error("Notification Type Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: editNotificationName,
    };
    setSubmitLoading(false);
    await fetch(`${baseUrl}/branch-notification-types/${editNotificationId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Notification Type Successfully Updated");
            getNotificationType();
            setEditNotificationModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setEditNotificationModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setEditNotificationModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const deleteNotificationTypeHandle = async () => {
    setdeleteloading(true);

    await fetch(
      `${baseUrl}/branch-notification-types/${deleteableNotify.id}}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success("Notification Type Successfully Deleted");
            getNotificationType();
            setdeleteloading(false);
            setdeleteNotifyTypeModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setdeleteloading(false);
            setdeleteNotifyTypeModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setdeleteloading(false);
          setdeleteNotifyTypeModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteNotifyTypeModal(false);
      });
  };

  // Handle Multiple Classes
  const Options = [];
  classesData.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const handleChangeClasses = (selected) => {
    setOptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setClassIds([...valuearray]);
  };

  const handleEditClasses = (selected) => {
    setEditOptionSelected([...selected]);

    let valuearray = [];
    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    console.log(valuearray);
    setEditClassIds([...valuearray]);
  };

  // Handle File Upload
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeImage = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  // Variables & Function For Pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(branchNotifyData.length / PER_PAGE);
  const _DATA = usePagination(branchNotifyData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "15px", marginBottom: "15px", padding: "15px" }}
      >
        <div className="d-flex mt-5">
          <button
            className="btn btn-primary mx-2"
            onClick={() => getBranchNotification()}
          >
            Get Branch Notification
          </button>

          {branchUrl == baseUrl &&
          PremissionChecker(user, "branch.add_branch_notification") ? (
            <button
              className="btn btn-primary mx-2"
              onClick={() => setAddModal(true)}
            >
              Add Branch Notification
            </button>
          ) : null}

          {branchUrl == baseUrl &&
          PremissionChecker(user, "branch.add_notification_type") ? (
            <button
              className="btn btn-primary mx-2"
              onClick={() => setNotificationTypeModal(true)}
            >
              Add Branch Notification Type
            </button>
          ) : null}
        </div>

        <div className="mt-5">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">Sr.No</th>
                <th className="text-center">Notification Type</th>
                <th className="text-center">Class Name</th>
                <th className="text-center">Title</th>
                <th className="text-center">Description</th>
                <th className="text-center">Files</th>
                <th className="text-center">Expiry Date</th>
                <th className="text-center">View</th>
                {branchUrl == baseUrl &&
                PremissionChecker(user, "branch.update_branch_notification") ? (
                  <th className="text-center">Edit</th>
                ) : null}
                {branchUrl == baseUrl &&
                PremissionChecker(user, "branch.delete_branch_notification") ? (
                  <th className="text-center">Delete</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((notifyData, index) => (
                <tr key={index}>
                  <td className="text-center">{notifyData?.id}</td>
                  <td className="text-center">
                    {notifyData?.branch_notification_type?.name}
                  </td>
                  <td className="text-center">
                    {notifyData?.student_classes
                      .slice(0, 3)
                      .map((className) => className.student_class?.name)
                      .join(", ") +
                      (notifyData?.student_classes.length > 3 ? ", ..." : "")}
                  </td>
                  <td className="text-center">
                    {notifyData?.title.length > 20
                      ? notifyData?.title.substring(0, 15) + ".."
                      : notifyData?.title}
                  </td>
                  <td className="text-center">
                    {notifyData?.description.length > 20
                      ? notifyData?.description.substring(0, 20) + ".."
                      : notifyData?.description}
                  </td>

                  <td className="text-center">
                    {notifyData?.files?.map((file, fileIndex) => (
                      <a
                        key={fileIndex}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        href={`${branchUrl.replace(
                          "/api",
                          ""
                        )}/storage/branch/notifications/${file.file}`}
                        download={file.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-success">
                          <FaFileDownload />
                        </span>
                      </a>
                    ))}
                  </td>
                  <td className="text-center">{notifyData?.expiry_date}</td>

                  <td className="text-center">
                    <a
                      style={{}}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => showViewModal(notifyData)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Display2.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>

                  {branchUrl == baseUrl &&
                  PremissionChecker(
                    user,
                    "branch.update_branch_notification"
                  ) ? (
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          getEditNotification(notifyData?.id);
                          setEditNotifyID(notifyData?.id);
                          setEditNotifyModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary ">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}

                  {branchUrl == baseUrl &&
                  PremissionChecker(
                    user,
                    "branch.delete_branch_notification"
                  ) ? (
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setDeleteableNotification(notifyData?.id);
                          setDeleteNotificationModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row justify-content-center">
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>

          <Pagination
            color="primary"
            count={count}
            size="large"
            page={page}
            variant="text"
            shape="round"
            onChange={handleChange}
          />
        </div>

        {/* ------------------------Branch Notification Start----------------------- */}

        {/* Add Branch Notification */}
        <Modal show={addModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add Branch Notification
            </Modal.Title>
          </Modal.Header>

          {addLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Branch Notification Adding Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Select Notification Type</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      setNotificationType(e.target.value);
                    }}
                    className="form-select h-40px"
                    value={notificationType}
                    aria-label="Default select example"
                  >
                    <option value="">Select Notification Type</option>
                    {notificationeTypeData.map((notify, index) => (
                      <option key={index} value={notify.id}>
                        {notify?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>Select Classes</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>

                  <MySelect
                    options={Options}
                    isMulti
                    name="selectedOptions"
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleChangeClasses}
                    allowSelectAll={true}
                    value={optionSelected}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label>
                    <b>Enter Branch Notification Title</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => setTtile(e.target.value)}
                    name="notification-title"
                    placeholder="Enter Branch Notification Title"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label>
                    <b>
                      Enter Branch Notification Description{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    name="notification-description"
                    placeholder="Enter Branch Notification Description"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <label>
                    <b>Add Files</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    name="files"
                    multiple
                  />
                  <div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <span
                            className="svg-icon svg-icon-md cursor-pointer ml-1"
                            onClick={() => removeImage(file)}
                          >
                            <MdDelete color="#F64E60" />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>
                      Select Expiry Date{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    value={expiryDate}
                    className="form-control"
                    onChange={(e) => setExpiryDate(e.target.value)}
                    type="date"
                  />
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={addNotificationHandler}
              disabled={addLoading ? true : false}
            >
              Add Branch Notification
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setAddModal(false);
                setisallfields(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Branch Notification */}
        <Modal show={editNotifyModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Branch Notification
            </Modal.Title>
          </Modal.Header>

          {editNotificationLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Notification Updating Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Select Notification Type</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      setEditNotifyType(e.target.value);
                    }}
                    className="form-select h-40px"
                    value={editNotifyType}
                    aria-label="Default select example"
                  >
                    <option value="">Select Notification Type</option>
                    {notificationeTypeData.map((notify, index) => (
                      <option key={index} value={notify.id}>
                        {notify?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>Select Classes</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>

                  <MySelect
                    options={Options}
                    isMulti
                    name="selectedOptions"
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleEditClasses}
                    allowSelectAll={true}
                    value={editOptionSelected}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label>
                    <b>Enter Branch Notification Title</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                    name="notification-title"
                    placeholder="Enter Branch Notification Title"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label>
                    <b>
                      Enter Branch Notification Description{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editDescription}
                    onChange={(e) => setEditDescription(e.target.value)}
                    name="notification-description"
                    placeholder="Enter Branch Notification Description"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <label>
                    <b>Add Files</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    name="files"
                    multiple
                  />
                  <div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <span
                            className="svg-icon svg-icon-md cursor-pointer ml-1"
                            onClick={() => removeImage(file)}
                          >
                            <MdDelete color="#F64E60" />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>
                      Select Expiry Date{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    value={editExpiryDate}
                    className="form-control"
                    onChange={(e) => setEditExpiryDate(e.target.value)}
                    type="date"
                  />
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={updateNotificationHandler}
              disabled={editNotificationLoading ? true : false}
            >
              Update Branch Notification
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setEditNotifyModal(false);
                setisallfields(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Branch Notification */}
        <Modal show={deleteNotificationModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this branch notification?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Branch Notification Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={deleteNotificationHandle}
                  className="btn button_blue"
                >
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setDeleteNotificationModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>

        {/* Show Branch Notification */}
        <Modal onHide={() => setShowModal(false)} size="lg" show={showModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              View Branch Notification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <div className="d-flex justify-content-between">
                <h5>
                  <span className="text-primary"> Notification Type:- </span>
                  {selectedNotification?.branch_notification_type?.name}
                </h5>
                <h5>
                  <span className="text-danger"> Expiry Date:- </span>{" "}
                  {selectedNotification.expiry_date}
                </h5>
              </div>

              <div className="d-flex mt-2">
                <h5>
                  <span className="text-primary"> Classes:- </span>{" "}
                  {selectedNotification?.student_classes?.length > 0
                    ? selectedNotification.student_classes
                        .map((className) => className.student_class?.name)
                        .join(", ")
                    : null}
                </h5>
              </div>

              <h5 className="mt-2">
                <span className="text-primary"> Title:- </span>{" "}
                {selectedNotification.title}
              </h5>
              <div className="mt-4">
                <h5>
                  <span className="text-primary"> Description:- </span>{" "}
                </h5>
                <p className="font-size-h6 text-justify">
                  {selectedNotification.description}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* ------------------------Branch Notification End----------------------- */}

        {/* ------------------------Branch Notification Type Start----------------------- */}
        {/* Add Branch Notification Type  */}
        <Modal show={notificationTypeModal} size="md">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Branch Notification Type
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Notification Type Name{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control"
                value={NotificationName}
                onChange={(e) => setNotificationName(e.target.value)}
                placeholder="Enter Branch Notification Type Name"
              />
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Type Name</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {notificationeTypeData.map((notifyData, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{notifyData?.id}</td>
                    <td style={{ textAlign: "start" }}>{notifyData?.name}</td>
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setEditNotificationId(notifyData?.id);
                          getEditNotificationType(notifyData?.id);
                          setEditNotificationModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary ">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setDeleteableNotifyType(notifyData);
                          setdeleteNotifyTypeModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button
                onClick={NotificationTypeHandler}
                className="btn btn-primary"
              >
                Add Notification Type
              </button>
            )}

            <button
              onClick={() => setNotificationTypeModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Notification Type */}
        <Modal show={editNotificationModal} size="md">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Branch Notification Type
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Notification Type Name{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control"
                value={editNotificationName}
                onChange={(e) => setEditNotificationName(e.target.value)}
                placeholder="Enter Branch Notification Type Name"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button
                onClick={updateNotificationTypeHandler}
                className="btn btn-primary"
              >
                Update
              </button>
            )}

            <button
              onClick={() => setEditNotificationModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Notification Type */}
        <Modal show={deleteNotifyTypeModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this notification type?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Notification Type Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={deleteNotificationTypeHandle}
                  className="btn button_blue"
                >
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setdeleteNotifyTypeModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
        {/* ------------------------Branch Notification Type End----------------------- */}
      </Paper>
    </div>
  );
};

export default BranchNotification;
