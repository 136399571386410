import React from 'react'
import { Modal } from 'react-bootstrap'

const DeleteSingleFee = ({ show, close, id }) => {
    return (
        <Modal
            onHide={close}
            show={show}
        >
            <Modal.Header>
                <Modal.Title>Delete Single Fee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <b>Are you sure you want to delete ?</b>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={close} className='btn btn-danger'>
                    Cancel
                </button>
                <button className='btn btn-primary'>
                    Delete
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteSingleFee