import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { upperCase } from "lodash";
import { baseUrl } from "../../../../services/config";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        // setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div style={{ color: "#121291" }} className=" order-2 order-md-1">
          <span
            style={{ color: "#121291" }}
            className=" vv font-weight-bold mr-2"
          >
            {today.toString()}
          </span>{" "}
          &copy;{" "}
          <a
            href="#"
            rel="noopener noreferrer"
            // className="text-dark-75 text-hover-primary"
            className=" text-hover-primary"
          >
            {unitName}
          </a>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <a
            style={{ color: "#121291" }}
            href="#"
            // target="_blank"
            rel="noopener noreferrer"
            className="nav-link pr-3 pl-0"
          >
            About
          </a>
          {/* <a
            style={{color:"#121291"}}
            href="https://preesoft.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            Team
          </a> */}
          <a
            style={{ color: "#121291" }}
            href="#"
            // target="_blank"
            rel="noopener noreferrer"
            className="nav-link pl-3 pr-0"
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
}
