import React, { useState, useEffect } from "react";
// import { Table, Modal } from 'react-bootstrap'
import { baseUrl } from "../../../services/config";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Modal, Form, Col, Table, Alert } from "react-bootstrap";
import { Paper, Switch } from "@material-ui/core";
import { Modal as AntModal } from "antd";
import { PremissionChecker } from "../../../services/PremissionChecker";

const Sessions = () => {
  const alert = useAlert();
  const [loading, setloading] = useState(false);
  // const authtoken = useSelector(state => state.user.authtoken)
  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  const [addModal, setaddModal] = useState(false);
  const [Allsessions, setAllsessions] = useState([]);
  const [addLoading, setaddLoading] = useState("");
  const [allfields, setallfields] = useState(false);
  const [twish, setTwish] = useState(false);
  const [yearId, setYearId] = useState("");
  const [acedemicYear, setAcedemicYear] = useState(false);
  const [fencYear, setFencYear] = useState(false);
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    getAllSessions();
  }, []);

  const getAllSessions = () => {
    setloading(true);
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data) {
            setAllsessions(data.payload);
            console.log(data, "==>");
            setloading(false);
          } else {
            setloading(false);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const AddHandler = () => {
    if (from == "" || to == "") {
      setallfields(true);
      return;
    }
    setallfields(false);

    setaddLoading(true);
    const data = {
      year: `${from}-${to}`,
    };
    fetch(`${baseUrl}/session`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          setallfields(false);
          if (data.metadata) {
            if (data.metadata.success) {
              setaddModal(false);
              setaddLoading(false);

              alert.success("Session Added Success");
              getAllSessions();
            } else {
              let errormessage = data.metadata.message.year[0];
              setaddModal(false);
              console.log(
                "Something went wrong",
                data.metadata.message.year[0]
              );
              alert.error(errormessage);
              setaddLoading(false);
            }
          } else {
            setaddModal(false);
            console.log("Something went wrong", data.metadata.message.year[0]);
            let errormessage = data.metadata.message.year[0];
            alert.error(errormessage);
            setaddLoading(false);
          }
        });
      })
      .catch((err) => {
        setallfields(false);
        setaddModal(false);
        console.log(err);
        alert.error("Something went wrong", err);

        setaddLoading(false);
      });
  };
  const handlePreview = (e, id, adeyear, fecyear) => {
    console.log(adeyear);

    if (adeyear && fecyear == "") {
      alert.error("At least 1 academic year is required");
      setAcedemicYear(false);
      setFencYear(false);
      return;
    } else if (fecyear && adeyear == "") {
      alert.error("At least 1 financial year is required");
      setFencYear(false);
      setAcedemicYear(false);
      return;
    } else {
      setYearId(id);
      setTwish(true);
    }
    // if(fencYear){
    //     setYearId(id)
    //     setTwish(true)
    // }else{
    //     alert.error("At least 1 financial year is required")
    // }
  };

  const handleSwitchChange = () => {
    const data = {
      year_id: yearId,
      financial_year: fencYear ? 1 : 0,
      academic_year: acedemicYear ? 1 : 0,
    };
    fetch(`${baseUrl}/activate-session`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        getAllSessions();
        setTwish(false);
        console.log(data);
        setFencYear(false);
        setAcedemicYear(false);
        alert.success("Updated Successfully");
      })
      .catch((err) => {
        console.log("err is re", err);
      });
  };
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {PremissionChecker(user, "session.create") ? (
          <button
            className="btn btn-success button_blue"
            onClick={() => setaddModal(true)}
          >
            Add Session
          </button>
        ) : null}
        <br />
        <br />

        {PremissionChecker(user, "session.list") ? (
          <Table responsive>
            <thead>
              <tr>
                <td style={{ textAlign: "center" }}>ID</td>
                <td style={{ textAlign: "center" }}>Year</td>
                <td style={{ textAlign: "center" }}>Academic</td>
                <td style={{ textAlign: "center" }}>Financial</td>
              </tr>
            </thead>
            <tbody>
              {Allsessions.map((ses) => (
                <tr>
                  <td style={{ textAlign: "center" }}>{ses.id}</td>
                  <td style={{ textAlign: "center" }}>{ses.year}</td>
                  <td style={{ textAlign: "center" }}>
                    {PremissionChecker(user, "session.active") ? (
                      <Switch
                        size="small"
                        onChange={(e) => {
                          // setAcedemicYear(ses.active_academic_year=="0"?false:true)
                          setAcedemicYear(e);
                          handlePreview(
                            e,
                            ses.id,
                            ses.active_academic_year == "0" ? false : true,
                            ""
                          );
                        }}
                        checked={ses.active_academic_year == "0" ? false : true}
                      />
                    ) : null}
                  </td>

                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {PremissionChecker(user, "session.active") ? (
                      <Switch
                        size="small"
                        checked={
                          ses.active_financial_year == "0" ? false : true
                        }
                        onChange={(e) => {
                          setFencYear(e);
                          // setFencYear(ses.active_financial_year=="0"?false:true)
                          handlePreview(
                            e,
                            ses.id,
                            "",
                            ses.active_financial_year == "0" ? false : true
                          );
                        }}
                      />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>
        <Modal show={addModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Session
            </Modal.Title>
          </Modal.Header>
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <div>
              {addLoading ? (
                <b style={{ color: "green" }}>
                  Session Adding Please Wait{" "}
                  <span className="spinner-border"></span>
                </b>
              ) : (
                <div className="row">
                  <div style={{ color: "#F3F6F9" }} className="col-md-6">
                    <label>From</label>
                    <input
                      type="number"
                      IsMatch="^[0-9]*$"
                      maxLength={4}
                      onChange={(e) => setfrom(e.target.value)}
                      className="form-control "
                      placeholder="From"
                    />
                  </div>
                  <div style={{ color: "#F3F6F9" }} className="col-md-6">
                    <label>to</label>
                    <input
                      type="number"
                      IsMatch="^[0-9]*$"
                      maxLength={4}
                      onChange={(e) => setto(e.target.value)}
                      className="form-control "
                      placeholder="To"
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          {allfields ? (
            <div id="err">
              <Alert
                variant="danger"
                onClose={() => setallfields(false)}
                dismissible
              >
                <Alert.Heading>Please fill All fields!</Alert.Heading>
                <p>All fields Are required</p>
              </Alert>
            </div>
          ) : null}
          <Modal.Footer>
            <button
              onClick={AddHandler}
              className="btn btn-success button_blue"
            >
              Add
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setaddModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>

      <Modal show={twish}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Are you sure you want to change session
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            onClick={() => handleSwitchChange()}
            className="btn btn-success button_blue"
          >
            Yes
          </button>
          <button
            className="btn btn-danger btn_width"
            onClick={() => setTwish(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sessions;
