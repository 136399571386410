import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../services/config";
import { Paper } from "@material-ui/core";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import TrialExport from "./TrialExport";
import { upperCase } from "lodash";

const YearEnd = () => {
  const [yearId, setYearId] = useState("");
  const [yearId1, setYearId1] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [confirmLoading, setconfirmLoading] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [sessionName1, setSessionName1] = useState("");
  const [debitSum, setDebitSum] = useState(0);
  const [creditSum, setCreditSum] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);
  const [contraDebit, setContraDebit] = useState(0);
  const [contraCredit, setContraCredit] = useState(0);
  const [date, setDate] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const renderedRows = [];
  var drValue1 = 0;
  var crValue1 = 0;
  var sumcr = "0";
  var sumdr = "0";
  var crcd = "";
  const pageStyle = `
  @page {
    size: legal portrait !important;
    margin-bottom: 1in;
  }
  `;
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    sessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
            setYearId1(session.id + 1);
            console.log(session);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const yearClose = () => {
    var crval = creditSum + crValue1;
    var drval = debitSum + drValue1;
    let data = {
      year_data: renderedRows,
      session: yearId1,
      old_session: yearId,
      crval: crval,
      drval: drval,
    };
    console.log(data);
    fetch(`${baseUrl}/sessionClosed`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show(data.payload);
            setconfirmModal(false);
            setconfirmLoading(false);
            setViewModal(false);
            console.log(data);
          } else {
            setconfirmModal(false);
            setconfirmLoading(false);
            setViewModal(false);
            alert.error(data.metadata.message);
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };
  const handleGetData = () => {
    if (yearId == "") {
      alert.error("Please select the year");
      return;
    }
    setLoading(true);
    const data = {
      year_id: yearId,
      date: date,
    };
    fetch(`${baseUrl}/closingYearView?year_id=${yearId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setIsVisible(true);
        console.log(data);

        setReportData(data);
        let sum = 0;
        let sum2 = 0;
        data.forEach((element) => {
          if (element.cr !== element.dr) {
            const difference = element.cr - element.dr;

            //debitSum
            const crValue = difference > 0 ? difference : 0;
            const drValue = difference < 0 ? -difference : 0;

            sum += parseInt(crValue);
            sum2 += parseInt(drValue);
          }
        });
        setCreditSum(sum);
        setDebitSum(sum2);

        console.log(sum);
        console.log(sum2);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3 ">
            <label>
              <b>Current Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                disabled
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => {
                  const data = sessionData.find((ses) => {
                    return ses.id == e.target.value;
                  });
                  console.log(data);
                  setSessionName(data.year);
                  setYearId(e.target.value);
                }}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-3 ">
            <label>
              <b>New Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                disabled
                style={{ height: 40 }}
                className="form-select"
                name="year_id1"
                id="year_id1"
                value={yearId1}
                aria-label="Default select example"
                onChange={(e) => {
                  const data = sessionData.find((ses) => {
                    return ses.id == e.target.value;
                  });
                  console.log(data);
                  setSessionName1(data.year);
                  setYearId1(e.target.value);
                }}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-1 mt-9">
            <button className="btn btn-primary" onClick={handleGetData}>
              Show
            </button>
          </div>
          <div className="col-md-4 mt-9">
            <button
              className="btn btn-primary"
              onClick={() => {
                setViewModal(true);
                console.log();
              }}
            >
              Set New Session
            </button>
            {reportData.length > 0 ? (
              <ReactToPrint
                documentTitle={`Year End`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue "
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mt-5">
          {isVisible ? (
            <div id="tableboot mt-4">
              <div className="d-flex">
                <div className="">
                  <img
                    style={{ height: 100, width: 100, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="mx-5" style={{ paddingTop: "10px" }}>
                  <h1>{unitName}</h1>
                  <h3>Year End Voucher For The Financial Year {sessionName}</h3>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-center">A/C Code</th>
                    <th className="text-center">Account Name</th>
                    <th className="text-center">Debit</th>
                    <th className="text-center">Credit</th>
                  </tr>
                </thead>

                <tbody>
                  {reportData.map((item) => {
                    const ytext = document.getElementById("year_id1");
                    var new_year = ytext.options[ytext.selectedIndex].text;

                    if (item.cr === item.dr) {
                      return null; // Skip rendering
                    }
                    sumcr = sumcr + item.cr;
                    sumdr = sumdr + item.dr;

                    const difference = item.cr - item.dr;

                    //debitSum
                    const crValue = difference > 0 ? difference : 0;
                    const drValue = difference < 0 ? -difference : 0;

                    const rowObject = {
                      sub_account_id: item.sub_account_id,
                      account_chart_id: item.sub_account.account_chart_id,
                      acode: item.sub_account.acode,
                      title: item.sub_account.title,
                      dr: drValue,
                      cr: crValue,
                      session: yearId + 1,
                      campus_id: 10,
                      nuration:
                        "closing for the Year of " +
                        sessionName +
                        " and opening for the Next Session " +
                        new_year,
                    };
                    renderedRows.push(rowObject);

                    return (
                      <tr>
                        <td className="text-center">
                          {item.sub_account.acode}
                        </td>
                        <td className="text-center">
                          {item.sub_account.title}
                        </td>
                        <td className="text-center">
                          {/* {item.account_group} */}
                          {drValue}
                        </td>
                        <td className="text-center">{crValue}</td>
                      </tr>
                    );
                  })}
                  {(() => {
                    const difference1 = creditSum - debitSum;

                    drValue1 = difference1 > 0 ? difference1 : 0;
                    crValue1 = difference1 < 0 ? -difference1 : 0;

                    if (crValue1 > drValue1) {
                      crcd = "Profit";
                    } else {
                      crcd = "Loss";
                    }
                    const rowObject = {
                      sub_account_id: "-1",
                      account_chart_id: "-1",
                      acode: "22100001",
                      title: "Profit & Loss",
                      dr: drValue1,
                      cr: crValue1,
                      session: yearId + 1,
                      campus_id: 10,
                      nuration:
                        "Profit & Loss for the Year of " +
                        sessionName +
                        " Appropriate " +
                        crcd,
                    };
                    renderedRows.push(rowObject);
                  })()}

                  <tr>
                    <td className="text-center">22100001</td>
                    <td className="text-center">
                      Profit & Loss For Year of {sessionName} Appropriate {crcd}
                    </td>

                    <td className="text-center">{drValue1}</td>

                    <td className="text-center">{crValue1}</td>
                  </tr>

                  <tr id="tbl">
                    <td
                      style={{ color: "black", paddingLeft: "30px" }}
                      className="text-start"
                    ></td>
                    <td
                      style={{
                        fontSize: "1.3rem",
                        paddingLeft: "200px",
                        fontWeight: "bold",
                      }}
                      className="text-start text-primary"
                    >
                      Grand Total :-
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-end text-primary"
                    >
                      {debitSum + drValue1}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-end text-primary"
                    >
                      {creditSum + crValue1}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </Paper>
      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot mt-4">
          <div className="d-flex">
            <div className="">
              <img
                style={{ height: 100, width: 100, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="mx-5" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <h3>Year-End Summary For The Financial Year {sessionName}</h3>
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th
                  className="text-start"
                  style={{
                    fontSize: "20px",
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                >
                  A/C Code
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "20px",
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                >
                  Account Name
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "20px",
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                >
                  Debit
                </th>
                <th
                  className="text-center"
                  style={{
                    fontSize: "20px",
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                >
                  Credit
                </th>
              </tr>
            </thead>

            <tbody>
              {/* {reportData.map((item) => ( */}
              {renderedRows.map((item) => (
                <>
                  <tr id="tbl">
                    <td
                      style={{
                        color: "black",
                        fontSize: "20px",
                        padding: "1px",
                        whiteSpace: "normal",
                      }}
                      className="text-start"
                    >
                      {item.acode}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: "20px",
                        padding: "1px",
                        whiteSpace: "normal",
                      }}
                      className="text-center"
                    >
                      {item.title}
                    </td>
                    <td
                      style={{
                        color: "black",
                        right: "200px",
                        fontSize: "20px",
                        padding: "1px",
                        whiteSpace: "normal",
                      }}
                      className="text-center text-primary"
                    >
                      {/* {item.account_group} */}
                      {item.dr}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: "20px",
                        padding: "1px",
                        whiteSpace: "normal",
                      }}
                      className="text-center"
                    >
                      {item.cr}
                    </td>
                  </tr>
                </>
              ))}
              <tr id="tbl">
                <td
                  style={{
                    color: "black",
                    paddingLeft: "30px",
                    fontSize: "20px",
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                  className="text-start"
                ></td>
                <td
                  style={{
                    paddingLeft: "200px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                  className="text-start text-primary"
                >
                  Grand Total :-
                </td>

                <td
                  style={{
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",

                    fontSize: "20px",
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                  className="text-end text-primary"
                >
                  {debitSum + drValue1}
                </td>

                <td
                  style={{
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    fontSize: "20px",
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                  className="text-end text-primary"
                >
                  {creditSum + crValue1}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner class="text-center" variant="info" animation="border" />
        </div>
      ) : null}

      <Modal show={viewModal} centered size="lg">
        <Modal.Header>{selectedItem.sub_account}</Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th className="text-start">Account Code</th>
                <th className="text-start">Description</th>
                <th className="text-end">Debit</th>
                <th className="text-end">Credit</th>
              </tr>
            </thead>

            <tbody>
              {renderedRows.map((item) => (
                <tr id="tbl">
                  <td style={{ color: "black" }} className="text-start">
                    {item.acode}
                  </td>
                  <td style={{ color: "black" }} className="text-start">
                    {item.nuration}
                  </td>

                  <td
                    style={{ color: "black", fontWeight: "bold" }}
                    className="text-end text-primary"
                  >
                    {item.dr}
                  </td>

                  <td
                    style={{ color: "black", fontWeight: "bold" }}
                    className="text-end text-primary"
                  >
                    {item.cr}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  style={{ color: "black", paddingLeft: "30px" }}
                  className="text-start"
                ></td>
                <td
                  style={{ color: "black", fontWeight: "bold" }}
                  className="text-center text-primary"
                >
                  Grand Total :-
                </td>

                <td
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                  }}
                  className="text-end text-primary"
                >
                  {debitSum + drValue1}
                </td>

                <td
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                  }}
                  className="text-end text-primary"
                >
                  {creditSum + crValue1}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => setconfirmModal(true)}
          >
            Submit
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setViewModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={confirmModal}>
        <Modal.Header>Year End</Modal.Header>
        <Modal.Body>
          {confirmLoading ? (
            <b style={{ color: "green" }}>
              Year Changing Please wait {` `}{" "}
              <span className="spinner-border"></span>
            </b>
          ) : (
            <>
              Are you sure you want to run Year-End for current Financial Year?
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn_width" onClick={yearClose}>
            Yes
          </button>
          <button
            className="btn  btn-danger btn_width"
            onClick={() => setconfirmModal(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default YearEnd;
