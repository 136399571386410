import React, { useState, Component } from "react";
import ReactDOM from "react-dom";
import makeAnimated from "react-select/animated";
import MySelect from "./MySelect.js";
import { components } from "react-select";

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);
const animatedComponents = makeAnimated();


export default function Example({fee_types,selectedfeetypes}) {
    let [optionSelected, setoptionSelected] = useState(null);

    console.log(fee_types,selectedfeetypes);

    const handleChange = selected => {
        setoptionSelected(selected)
        console.log(selected);
        selectedfeetypes([1,2,3,3])
        console.log(selectedfeetypes,"haaaaaaa");
    };
     const Options = [];
    fee_types.map((item,index) => {
      
        Options[index]={value:item.id,label:item.name};
       
    })
   






    return (
        <MySelect
            options={Options}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            onChange={handleChange}
            allowSelectAll={true}
            value={optionSelected}
        />
    );

}



// export default class Example extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             optionSelected: null
//         };
//     }

//     handleChange = selected => {
//         this.setState({
//             optionSelected: selected
//         });
//     };



//     render() {
//         return (
//             <MySelect
//                 options={props.fee_types}
//                 isMulti
//                 closeMenuOnSelect={false}
//                 hideSelectedOptions={false}
//                 components={{ Option, MultiValue, animatedComponents }}
//                 onChange={this.handleChange}
//                 allowSelectAll={true}
//                 value={this.state.optionSelected}
//             />
//         );
//     }
// }


