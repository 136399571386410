import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MyAuthToken from "../../../../services/authToken";
import { useAlert } from "react-alert";
import { Alert } from "react-bootstrap";

import { baseUrl } from "../../../../services/config";

function AddConcession() {
  const alert = useAlert();
  const jwt_token = MyAuthToken();
  const [title, settitle] = useState("");
  const [error, seterror] = React.useState("");
  const [persentage, setpersentage] = useState("");
  const [persentageEnable, setpersentageEnable] = useState(false);
  const [amountEnable, setamountEnable] = useState(false);
  const [isused, setisused] = useState("");
  const [loading, setloading] = useState(false);
  const [isbuttonEnabled, setisbuttonEnabled] = React.useState(true);
  const history = useHistory();
  const [isallfields, setisallfields] = useState(false);
  const [amount, setamount] = useState("");

  const goBack = () => {
    history.push("/concession");
  };

  const addconcessionHandler = () => {
    if (title == "" || persentage == "") {
      // alert.show('Please fill All Required Fields')

      setisallfields(true);
      return;
    }
    setisallfields(false);
    setloading(true);
    const data = {
      title: title,
      amount: amount,
      percentage: persentage,
    };
    fetch(`${baseUrl}/concession`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((resp) => {
          if (resp.metadata.success) {
            setloading(false);
            history.push("/concession");
            alert.show("Concession Addedd");
          } else {
            setloading(false);
            alert.show("Error Occured");
          }
        });
        // if (res.ok) {
        //     alert.show('Concession Addedd')
        // } else {
        //     alert.show('Error Occured')
        // }
      })

      .catch((err) => {
        alert.show("Concession could not Addedd");
      });
  };

  return (
    <div className="container">
      <div className="card">
        <button
          // style={{ color: 'white', marginTop: "15px", marginBottom: "15px" }}
          onClick={goBack}
          // className="button_blue"

          style={{
            color: "#ffffff",
            backgroundColor: "#121291",
            borderColor: "#121291",
            width: "130px",
          }}
          // style={{ color: 'white', marginTop: "15px", marginBottom: "15px" }}

          className="btn btn-primary  col-sm-2"
        >
          Back
        </button>

        <h4 style={{ marginTop: "15px", marginBottom: "15px" }}>
          Add Concession
        </h4>

        {/* <h5 style={{ color: 'red' }}>{error}</h5> */}
        <div>
          <label>
            Title <span style={{ color: "red" }}>*</span>
          </label>
          <input
            //  onBlur={onblurfocus1}
            style={{ backgroundColor: "white" }}
            onChange={(e) => {
              if (e.target.value == "") {
                setisallfields(true);
              } else {
                setisallfields(false);
              }
              settitle(e.target.value);
            }}
            type="text"
            placeholder="Title"
            className="form-control  mb-2"
            maxLength={120}
            // className="form-control placeholder-bg mb-2"
          />
        </div>

        {/* <div style={{ display: 'flex' }}>
                    {
                        TYPES.map(type => (
                            <button
                                onClick={() => onClickType(type)}
                                key={type.id}
                                style={{
                                    color:selectedType.id == type.id ? "#ffffff" :'black',
                                    backgroundColor: selectedType.id == type.id ? "#121291" : '',margin:5, width: "130px"
                                }}
                            >
                                {type.title}
                            </button>
                        ))
                    }

                </div> */}

        <div>
          <>
            <label>
              Amount <span style={{ color: "red" }}>*</span>
            </label>
            <input
              // onBlur={onblurfocus2}
              style={{ backgroundColor: "white" }}
              onChange={(e) => {
                if (e.target.value == "") {
                  setisallfields(true);
                } else {
                  setisallfields(false);
                }
                setamount(e.target.value);
              }}
              type="text"
              placeholder="Amount"
              maxLength={4}
              className="form-control  mb-2"
            />
          </>
        </div>

        <div>
          <>
            <label>
              Persentage <span style={{ color: "red" }}>*</span>
            </label>
            <input
              // onBlur={onblurfocus2}
              style={{ backgroundColor: "white" }}
              onChange={(e) => {
                if (e.target.value == "") {
                  setisallfields(true);
                } else {
                  setisallfields(false);
                }
                setpersentage(e.target.value);
              }}
              type="text"
              placeholder="Persentage"
              maxLength={4}
              className="form-control  mb-2"
            />
          </>
        </div>
        {isallfields ? (
          <>
            <Alert
              variant="danger"
              onClose={() => setisallfields(false)}
              dismissible
            >
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>Please Fill All Fields</p>
            </Alert>
          </>
        ) : null}
        {loading ? (
          <span className="spinner-border"></span>
        ) : (
          <button
            // disabled={isbuttonEnabled}
            style={{
              color: "#ffffff",
              backgroundColor: "#121291",
              borderColor: "#121291",
              width: "151px",
            }}
            className="btn btn-success  col-sm-2"
            onClick={addconcessionHandler}
          >
            Add Concession
          </button>
        )}
      </div>
    </div>
  );
}

export default AddConcession;
