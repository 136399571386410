import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Table, Modal, Alert } from "react-bootstrap";

import { baseUrl } from "../../../../../services/config";
import { useHistory } from "react-router-dom";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../../../_metronic/_partials/controls";
import { values } from "lodash-es";

const Appointment = ({ staffDetail, show, reload }) => {
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );

  // console.log(staffDetail)
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");

  //data states
  const [AllPayScales, setAllPayScales] = useState([]);
  const [bankList, setbankList] = useState([]);
  const history = useHistory();

  //input states
  const [isformsubmitted, setisformsubmitted] = useState(false);
  const [social_security_number, setsocial_security_number] = useState("");
  const [field_of_interest, setfield_of_interest] = useState("");
  const [distinctions, setdistinctions] = useState("");
  const [objectives, setobjectives] = useState("");
  const [duties_assigned, setduties_assigned] = useState("");
  const [global_bank_id, setglobal_bank_id] = useState("");
  const [account_no, setaccount_no] = useState("");
  const [pay_scale_id, setpay_scale_id] = useState("");
  const [payment_type, setpayment_type] = useState("");
  const [salery_days, setsalery_days] = useState("");
  const [joining_date, setjoining_date] = useState("");
  const [account_head, setaccount_head] = useState("");
  const [loading, setloading] = useState(false);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please Fill all Fields");
  const [allsubaccounts, setallsubaccounts] = useState([]);
  useEffect(() => {
    getAllPayScales();
    getBankList();
  }, []);

  const getBankList = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          setbankList(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPayScales = () => {
    fetch(`${baseUrl}/payscales`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setAllPayScales(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const SubmitAppointment = (values) => {
    setloading(true);
    const data = {
      social_security_number: values.social_security_number.toString(),
      field_of_interest: values.field_of_interest,
      distinctions: values.distinctions,
      objectives: values.objectives,
      duties_assigned: values.duties_assigned,
      //  global_bank_id: values.global_bank_id,
      account_no: values.account_no.toString(),
      payment_type: values.payment_type,
      salery_days: values.salery_days.toString(),
      joining_date: values.joining_date,
      //  account_head: values.account_head,
      eobi_no: values.eobi_no,
      bank_account_id: values.bank_account_id,
    };

    console.log(data, "data");

    fetch(`${baseUrl}/emp_appointed/${staffDetail.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "res");
          if (data.metadata.success) {
            setloading(false);
            alert.success(
              "Staff Appointed Success Emp. Code:  " + data.payload.emp_code
            );
            show(false);
            setisformsubmitted(false);
            reload();
            history.push("/appointedstaff");
          } else if (data.metadata.responseCode == 422) {
            setloading(false);
            show(false);
            const msgArray = Object.values(data.metadata.message);
            msgArray.map((err) => alert.error(err[0]));
          }
        });
      })
      .catch((err) => {
        setloading(false);

        alert.error("Something Went wrong", err);
        show(false);
        setisformsubmitted(false);
        console.log(err);
      });
  };

  //start here
  const initialValues = {
    issubmitted: false,
    social_security_number: "",
    field_of_interest: "",
    distinctions: "",
    objectives: "",
    duties_assigned: "",
    global_bank_id: "",
    payment_type: "",
    salery_days: "",
    joining_date: red_recevingdate,
    account_head: "",
    eobi_no: "",

    bank_account_id: "",
    account_no: "",
  };
  const AppointStaffSchema = Yup.object().shape({
    social_security_number: Yup.string().notRequired(
      "Social Security Number is required"
    ),

    eobi_no: Yup.string().notRequired("EOBI Number is required"),

    field_of_interest: Yup.string().notRequired(
      "Field of Interest is required"
    ),

    distinctions: Yup.string().notRequired("Distinctions is required "),

    objectives: Yup.string().notRequired("Objectives is required "),
    duties_assigned: Yup.string().notRequired("Duties Assigned Is Required"),
    // global_bank_id: Yup.string().required("Bank is required "),

    // account_no: Yup.string().notRequired("Account No Is not required"),

    // payment_type: Yup.string().notRequired("Payment Type is not required "),
    salery_days: Yup.string()
      .required("Salary Days is required field")
      .matches(/^[0-9]*$/, "please Add Valid data"),

    joining_date: Yup.string().required("Joining Date is required "),

    account_no: Yup.string().notRequired(" Account No is required field"),
    bank_account_id: Yup.string().required("Bank Account  is required field"),
  });
  const createEroors = (errors, touched, isSubmitting, values) => {
    if (errors.social_security_number && touched.social_security_number) {
      setshowerror(errors.social_security_number);
      setshowerr(true);
      return;
    }
    if (
      touched.social_security_number &&
      values.social_security_number.toString().length != 0
    ) {
      if (
        values.social_security_number.toString().length > 10 ||
        values.social_security_number.toString().length < 5
      ) {
        setshowerr(true);
        setshowerror(" Sceurity Number must be between 5 to 10 Charcters");
        return;
      }
    }

    if (errors.field_of_interest && touched.field_of_interest) {
      setshowerror(errors.field_of_interest);
      setshowerr(true);
      return;
    }
    if (values.field_of_interest.length > 255) {
      setshowerr(true);
      setshowerror("Field Of Interest Must Not Be Greater Then 255 Charecters");
      return;
    }
    // if (errors.distinctions && touched.distinctions) {
    //   setshowerror(errors.distinctions);
    //   setshowerr(true);
    //   return;
    // }
    // if (values.distinctions.length > 255) {
    //   setshowerr(true);
    //   setshowerror("Distinctions Must Not Be Greater Then 255 Charecters");
    //   return;
    // }
    if (errors.objectives && touched.objectives) {
      setshowerror(errors.objectives);
      setshowerr(true);
      return;
    }
    if (values.objectives.length > 255) {
      setshowerr(true);
      setshowerror("Objectives Must Not Be Greater Then 255 Charecters");
      return;
    }
    if (errors.duties_assigned && touched.duties_assigned) {
      setshowerror(errors.duties_assigned);
      setshowerr(true);
      return;
    }
    if (values.duties_assigned.length > 255) {
      setshowerr(true);
      setshowerror("Duties Assigned Must Not Be Greater Then 255 Charecters");
      return;
    }
    //Global Bank Commented out

    // if (errors.global_bank_id && touched.global_bank_id) {
    //   setshowerror(errors.global_bank_id);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.payment_type && touched.payment_type) {
    //   setshowerror(errors.payment_type);
    //   setshowerr(true);
    //   return;
    // }
    if (errors.bank_account_id && touched.bank_account_id) {
      setshowerror("Plaese Select Bank");
      setshowerr(true);
      return;
    }

    if (errors.account_no && touched.account_no) {
      setshowerror("Please Add Account");
      setshowerr(true);
      return;
    }
    if (
      (values.account_no != "" && values.account_no.toString().length < 5) ||
      values.account_no.toString().length > 15
    ) {
      setshowerr(true);
      setshowerror(" Account Number must be between 5 to 15 Charcters");
      return;
    }

    if (errors.salery_days && touched.salery_days) {
      setshowerror(errors.salery_days);
      setshowerr(true);
      return;
    }
    if (
      (values.salery_days != "" && parseInt(values.salery_days) >= 255) ||
      parseInt(values.salery_days) < 0
    ) {
      setshowerr(true);
      setshowerror("Please Deine Salary Days Between 0 and 255");
      return;
    }
    if (errors.joining_date && touched.joining_date) {
      setshowerror(errors.joining_date);
      setshowerr(true);
      return;
    }
    if (errors.account_head && touched.account_head) {
      setshowerror(errors.account_head);
      setshowerr(true);
      return;
    }
    //Account Head Commented out
    // if (values.account_head.length > 255) {
    //   setshowerr(true);
    //   setshowerror("Account Head Must Not Be Greater Then 255 Charecters");
    //   return;
    // }

    setshowerr(false);

    if (Object.keys(errors).length === 0) {
      // if (values.distinctions != "") {

      if (isSubmitting) {
        if (!values.issubmitted) {
          setisformsubmitted(true);
          values.issubmitted = true;
          SubmitAppointment(values);
        }
      }
      // }
    }
  };
  return (
    <div>
      {loading ? (
        <b style={{ color: "green" }}>
          Staff Appointing Please Wait <span className="spinner-border"></span>
        </b>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={AppointStaffSchema}
          onSubmit={(values) => {}}
        >
          {(props) => {
            const {
              handleSubmit,
              errors,
              touched,
              values,
              isSubmitting,
              isValid,
              handleChange,
              handleBlur,
            } = props;

            // setErrorsLis(errors)
            createEroors(errors, touched, isSubmitting, values);

            return (
              <>
                <Form onSubmit={handleSubmit} className="form form-label-right">
                  <div class="row d-flex justify-content-center">
                    <div>
                      <div class="p-3">
                        {/* <div className="text-center">
                                                        <b>APPLICANT INFORMATION  </b>
                                                    </div> */}
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>Social security number </b>
                            </label>
                            <Field
                              name="social_security_number"
                              component={Input}
                              placeholder="Social Security Number"
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>EOBI Number </b>
                            </label>
                            <Field
                              name="eobi_no"
                              component={Input}
                              placeholder="EOBI Number"
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>Field of interest </b>
                            </label>
                            <Field
                              name="field_of_interest"
                              component={Input}
                              placeholder="Field Of Interest"
                            />
                          </div>
                        </div>

                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-12 flex-column d-flex">
                            <label class="form-control-label">
                              <b>Distinctions</b>
                            </label>
                            <Field
                              name="distinctions"
                              component={Input}
                              placeholder="Distinctions"
                            />
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-12 flex-column d-flex">
                            <label class="form-control-label">
                              <b>Objectives </b>
                            </label>
                            <Field
                              name="objectives"
                              component={Input}
                              placeholder="Objectives"
                            />
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-12 flex-column d-flex">
                            <label class="form-control-label">
                              <b>Duties Assigned </b>
                            </label>
                            <Field
                              name="duties_assigned"
                              component={Input}
                              placeholder="Duties Assigned"
                            />
                          </div>
                        </div>

                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Joining Date{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              name="joining_date"
                              type="date"
                              component={Input}
                              max="9999-12-30"
                              placeholder="Joining Date "
                            />
                          </div>
                        </div>

                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Bank Category{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <select
                              class="form-select form-control"
                              onChange={(e) => {
                                let anserr = bankList.filter(
                                  (item) => item.id == e.target.value
                                );

                                if (anserr.length != 0) {
                                  setallsubaccounts([
                                    ...anserr[0].bank_accounts,
                                  ]);
                                } else {
                                  setallsubaccounts([]);
                                }
                              }}
                              aria-label="Default select example"
                            >
                              <option selected value="">
                                Select...
                              </option>
                              {bankList.map((item) => (
                                <option value={item.id}>{item.title}</option>
                              ))}
                            </select>
                            {/* {errors.payment_type && touched.payment_type && (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                {errors.payment_type}
                              </div>
                            )} */}
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Bank Account{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <select
                              class="form-select form-control"
                              name="bank_account_id"
                              value={values.bank_account_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // style={{ backgroundColor: '#F3F6F9' }}
                              aria-label="Default select example"
                            >
                              <option selected value="">
                                Select ...
                              </option>
                              {allsubaccounts.map((item) => (
                                <option value={item.id}>
                                  {item.bank_name}
                                </option>
                              ))}
                            </select>
                            {errors.bank_account_id && touched.bank_account_id && (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                Please Select Bank
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Account No{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </b>
                            </label>
                            <Field
                              type="number"
                              name="account_no"
                              component={Input}
                              placeholder="Account No"
                            />
                          </div>

                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Salary Days{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              name="salery_days"
                              maxLength={3}
                              // type="number"
                              component={Input}
                              placeholder="Salery Days"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                {showerr ? (
                  <div id="err">
                    <Alert
                      variant="danger"
                      onClose={() => setshowerr(false)}
                      dismissible
                    >
                      <Alert.Heading>Oops You Got an Error!</Alert.Heading>
                      <p>{showerror}</p>
                    </Alert>
                  </div>
                ) : null}
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  class="btns"
                >
                  <button
                    type="submit"
                    value="Submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-success button_blue"
                  >
                    Save
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      show(false);
                    }}
                    type="button"
                    class="btn btn-danger btn_width"
                  >
                    Close
                  </button>
                </div>
                {/* </form> */}
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default Appointment;
