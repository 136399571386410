import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }
  if (!touched && errors) {
    classes.join(" ");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
      {label && <label> {label}</label>}
      {
        field.name == "phone" || field.name == "mobile_no" ?

          <div
         //   style={{ display: "flex" }}
          >
            <span  className="form-control" style={{
              alignItems: "center", border: "1px solid #E4E6EF", borderRight: "0px", fontSize: "15px",
              color: "#3F4254", padding: "10px 10px 9px 10px", borderRadius: "5px",display: "inline"
            }}>+92</span>

            <input
              style={
                { width:"84%",display: "inline" }
              }
              type={type}
              className={getFieldCSSClasses(touched[field.name], errors[field.name])}
              {...field}
              {...props}
            />
       
            {withFeedbackLabel && (
              <FieldFeedbackLabel
                error={errors[field.name]}
                touched={touched[field.name]}
                label={label}
                type={type}
                customFeedbackLabel={customFeedbackLabel}
              />
            )}



          </div>
          :
          <input
            type={type}
            className={getFieldCSSClasses(touched[field.name], errors[field.name])}
            {...field}
            {...props}
          />
      }
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
