import React, { useEffect, useState, useRef } from "react";
import { Divider } from "antd";
import { Hidden, LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Spinner, Table, Button, Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { DatePicker, Space } from "antd";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const AdvancedFeeReceive = () => {
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  var [logomodel, setlogomodel] = useState("");
  const [yearId, setYearId] = useState("");
  const [sessioning, setallsession] = useState([]);
  const [sessionname, setsessionname] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [session_id, setSession_id] = useState([0]);
  const [campus_id, setCampus_id] = useState([0]);
  const [dates, setDates] = useState([]);
  const [dates1, setDates1] = useState([]);
  let authtoken = localStorage.getItem("userToken");
  var i = 0;

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    getallsessions();
    // getallcampuses();
    getSettingData();
  }, []);

  const onSessionChange = (id) => {
    setSession_id(id);
    setDates(id);
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const printingDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const onCampusChange = (id) => {
    setCampus_id(id);
    // console.log(session_id);
    console.log(campus_id);
  };

  const getallsessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsession(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            //  setcurrentsession(item.id.toString());
            setYearId(item.id);
            setSession_id(item.id);
            setsessionname(item.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getallcampuses = () => {
    fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const handleShow = () => {
    setLoading(true);
    setIsVisible(false);

    const data = {
      year_id: session_id,
      campus_ids: campus_id,
    };

    fetch(`${baseUrl}/sessionwise-fee-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setIsVisible(true);
        console.log(data.payload);
        setreportData(data.payload);
        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data.payload);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
    console.log(reportData);
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  return (
    <>
      <Paper className="mb-6 mt-6 p-5">
        <div className="row mt-3">
          <div className="col-md-3">
            <b>
              <label>Select Session</label>
            </b>
            <select
              value={yearId}
              onChange={(e) => {
                onSessionChange(e.target.value);
                setYearId(e.target.value);
              }}
              className="form-select"
              style={{ height: 40 }}
            >
              <option value="">All Session</option>
              {sessioning.map((campus) => (
                <option value={campus.id}>{campus.year}</option>
              ))}
            </select>
            {dates == "Please Select Campus" && (
              <p style={{ color: "red" }}>{dates}</p>
            )}
          </div>
          <div className="col-md-3">
            <b>
              <label>Select Campus</label>
            </b>
            <select
              onChange={(e) => onCampusChange(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
            >
              <option value="0">All Campuses</option>
              {allcampuses.map((campus) => (
                <option value={campus.id}>{campus.name}</option>
              ))}
              {dates1 == "Please Select Campus" && (
                <p style={{ color: "red" }}>{dates1}</p>
              )}
            </select>
          </div>
          <div className="col-md-4 d-flex mt-9">
            <button onClick={handleShow} className="btn btn-primary">
              Show
            </button>
            {reportData.length > 0 ? (
              <ReactToPrint
                documentTitle={`Advance Fee Receive Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue ml-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            {/* <div > */}
            <Table responsive className="mt-5">
              <thead>
                <tr id="tbl" className="text-center">
                  <th>Sr No</th>
                  <th>Admin ID</th>
                  <th>Student Name</th>
                  <th>Father Name</th>
                  <th>Mobile No</th>
                  <th>Monthly Fee</th>
                  <th>Class</th>
                  <th>Last Month</th>
                  <th>Months</th>
                  <th>Fee Paid</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((data) => (
                  <tr>
                    <td>{(i = i + 1)}</td>
                    <td>{data.student.admission_id}</td>
                    <td>{data.student.name}</td>
                    <td>{data.student.father_name}</td>
                    <td>{data.student.mobile_no}</td>

                    <td>
                      {data.student.student_liable_fees.map((sitex) => (
                        <p style={{ whiteSpace: "normal", padding: "1px" }}>
                          {sitex.amount}
                        </p>
                      ))}
                      {data.student.student_liable_fees.amount}
                    </td>

                    <td>
                      {data.student.student_class.name}-
                      {data.student.global_section.name}
                    </td>
                    <td>
                      {getMonthName(
                        data.student.fee_challan_details_last.fee_month
                      )}
                    </td>
                    <td>{data.months}</td>
                    <td>{data.total}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* </div> */}
          </>
        ) : null}

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mainContainer">
            <div className="logocontainer" style={{ height: "100px" }}>
              <div className="d-flex">
                <div>
                  <img
                    style={{ height: 120, width: 110 }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h4> Student Advanced Fee Report</h4>
                  <div className="d-flex justify-content-between">
                    <h5>Session : {sessionname}</h5>
                    <h5>Printing Date: {printingDate()}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              {(() => {
                i = 0;
              })()}
              <Table
                style={{
                  fontSize: "16px",
                  whiteSpace: "normal",
                  padding: "1px",
                  marginTop: "15px",
                }}
                responsive
              >
                <thead
                  style={{
                    fontSize: "16px",
                    whiteSpace: "normal",
                    padding: "1px",
                  }}
                >
                  <tr
                    id="tbl"
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    <th
                      width="5%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Sr No
                    </th>
                    <th
                      width="5%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Admin_ID
                    </th>
                    <th
                      width="20%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      width="20%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Father Name
                    </th>
                    <th
                      width="10%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Mobile No
                    </th>
                    <th
                      width="5%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Fee
                    </th>
                    <th
                      width="12%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Class
                    </th>
                    <th
                      width="10%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Last Month
                    </th>
                    <th
                      width="5%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Months
                    </th>
                    <th
                      width="5%"
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Paid
                    </th>
                  </tr>
                </thead>
                {reportData.map((campus) => (
                  <tr
                    style={{
                      fontSize: "16px",
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {(i = i + 1)}
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {campus.student.admission_id}
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {/* {campus.student.name} */}
                      {campus.student.name.length > 15
                        ? campus.student.name.substring(0, 15) + "..."
                        : campus.student.name}
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {/* {campus.student.father_name} */}
                      {campus.student.father_name.length > 15
                        ? campus.student.father_name.substring(0, 15) + "..."
                        : campus.student.father_name}
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {campus.student.mobile_no}
                    </td>

                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {campus.student.student_liable_fees.map((sitex) => (
                        <p
                          style={{
                            whiteSpace: "normal",
                            padding: "1px",
                            margin: "-10px",
                          }}
                        >
                          {sitex.amount}
                        </p>
                      ))}
                    </td>

                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {campus.student.student_class.name.length > 7
                        ? campus.student.student_class.name.substring(0, 5)
                        : campus.student.student_class.name}
                      -
                      {campus.student.global_section.name.length > 7
                        ? campus.student.global_section.name.substring(0, 5)
                        : campus.student.global_section.name}
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {getMonthName(
                        campus.student.fee_challan_details_last.fee_month
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {campus.months}
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      {campus.total}
                    </td>
                  </tr>
                ))}
              </Table>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default AdvancedFeeReceive;
