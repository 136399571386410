import { Paper } from "@material-ui/core";
import { DatePicker } from "antd";
import React from "react";
import { useState } from "react";
import { Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";

const PaidUnpaid = () => {
    const [mode, setMode] = useState('top');
    const [loading, setloading] = useState(false);
    const [studentsData,setstudentsData]=useState([])
    const [paidAmount,setPainAmount]=useState(0)
    const [unpaidAmount,setUnPainAmount]=useState(0)
    const [totalAmount,setTotalAmount]=useState(0)
    let authtoken = localStorage.getItem("userToken");
    const handleDateChange = (date, dateString) => {
        console.log(dateString);
  const data={
    date:dateString
  }
   
    setloading(true);

    fetch(`${baseUrl}/paid-pending/fees`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
     
      body: JSON.stringify(data),
    }).then((resp) => {
      resp.json().then((data) => {
    setloading(false);
        if(data.metadata){
          if(data.metadata.success){
              console.log(data.payload)
              setstudentsData(data.payload)
              setPainAmount(data.payload.paid)
              setUnPainAmount(data.payload.unpaid)
              setTotalAmount(data.payload.total)
          }
        }
     

      
      });
    });
      };

      const handleModeChange = (e) => {
        setMode(e.target.value);
      };
      const getMonthName = (date) => {
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const d = new Date(date);
    
        return `${monthNames[d.getMonth()]}`;
      };

      const getFeeSum = (array) => {
        let sum = 0;
        array.forEach((element) => {
          sum += parseInt(element.payable);
        });
        return sum;
      };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >

    <div className="container">
        <div className="row">
            <div className="col-md-4">
            <DatePicker style={{width:"100%"}} onChange={handleDateChange} />
            </div>
            <div className="col-md-8" style={{display:"flex" ,justifyContent:"end"}}>
                <div>
                    <h4  className="text-primary mt-2">Total Amount:{totalAmount}</h4>
                </div>
            </div>

        </div>
         

    
    </div>
    <div style={{marginTop:"50px"}}>
    
    <Tabs
            onClick={(e) => {
              console.log(e.target)
            //   checkpayableamount(e);
            }}

            defaultActiveKey="active"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="active" title="Paid">
        <>
          {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <div id="tableboot">
                <Table responsive>
                  <thead>
                  <tr id="tbl">
             
             <th></th>
             <th></th>
             <th></th>
             <th></th>
             <th></th>
             <th></th>
             <th>Total Paid:</th>
             <th className="text-primary">{paidAmount}</th>
           </tr>
                    <tr id="tbl">
             
                      <th>Adm-Id</th>
                      <th>Student Name</th>
                      <th>Father Name</th>
                      <th>Mobile No</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Gender</th>
                       
                    </tr>
             
                  </thead>

                  <tbody>
                    {!!studentsData &&
                      studentsData?.paid_students?.map((student, index) => (
                        <tr id="tbl">
                     
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.admission_id}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.name}
                          </td>
                          <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student.father_name}
                  </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student.mobile_no}
                          </td>

                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student?.student_class?.name}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {student?.global_section?.name}
                          </td>
                          <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student?.gender}
                  </td> 


                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
        </>
        
        </Tab>
        
        <Tab


eventKey="unactive" title="Unpaid">
    {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
        <div id="tableboot">
        <Table responsive>
          <thead>
          <tr id="tbl">
             
             <th></th>
             <th></th>
             <th></th>
             <th></th>
             <th></th>
             <th></th>
             <th>Total Unpaid:</th>
             <th className="text-primary">{unpaidAmount}</th>
           </tr>
            <tr id="tbl">
     
              <th>Adm-Id</th>
              <th>Student Name</th>
              <th>Father Name</th>
              <th>Mobile No</th>
              <th>Class</th>
              <th>Section</th>
              <th>Gender</th>
      
            </tr>
         
          </thead>

          <tbody>
            {!!studentsData &&
              studentsData?.unpaid_students?.map((student, index) => (
                <tr id="tbl">
             
                  <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student.admission_id}
                  </td>
                  <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student.name}
                  </td>
                  <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student.father_name}
                  </td>
                  <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student.mobile_no}
                  </td>

                  <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student?.student_class?.name}
                  </td>
                  <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student?.global_section?.name}
                  </td>
                  <td
                    style={{ color: "black" }}
                    className="text_align_start"
                  >
                    {student?.gender}
                  </td>

                </tr>
              ))}
          </tbody>
        </Table>
      </div>
       )}
      </Tab>
      </Tabs>
    </div>
      </Paper>
    </>
  );
};

export default PaidUnpaid;
