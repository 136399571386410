import { Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import JoditEditor from "jodit-react";
import { Alert, Modal, Spinner, Table } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../../components/usePagination/usePagination";

const UpdateHomeWork = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [allsessions, setallsessions] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [currentSession, setCurrentSession] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [allSubjects, setAllSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [educationType, setEducationType] = useState(1);
  const [classSectionID, setClassSectionID] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [allClasses, setAllClasses] = useState([]);
  const [allCampusSections, setallCampusSections] = useState([]);
  const [currentDate, setCurrentDate] = useState("");

  const [loading, setloading] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [editTestLoading, setEditLoading] = useState(false);

  const [homeworkData, setHomeworkData] = useState([]);
  const editor = useRef(null);

  const [updateHomeWorkModal, setUpdateHomeWorkModal] = useState(false);
  const [editHomeWorkID, setEditHomeWorkID] = useState([]);
  const [editRemarks, setEditRemarks] = useState("");
  const [editSubject, setEditSubject] = useState("");
  const [editClassSectionID, setEditClassSectionID] = useState("");
  const [editDate, setEditDate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    getAllSessions();
    getAllSubjects();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setCurrentSession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSubjects = async () => {
    await fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllSubjects(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onCampusChange = async (id) => {
    setSelectedCampus(id);
    getCampusClasses(id);
    setAllClasses([]);
    setSelectedClass("");
    setallCampusSections([]);
    setSelectedSection("");
    setClassSectionID("");
  };

  const getCampusClasses = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setAllClasses(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setSelectedClass(id);
    setallCampusSections([]);
    setSelectedSection("");
    setClassSectionID("");

    fetch(`${baseUrl}/class_section/${selectedCampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallCampusSections([...data.payload]);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedCampus}/${id}${
        selectedClass ? "/" + selectedClass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          setallCampusSections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClassSectionId = (id) => {
    setSelectedSection(id);
    setClassSectionID("");
    fetch(
      `${baseUrl}/class-section/${selectedCampus}/${selectedClass}/${id}/${educationType}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setClassSectionID(data.payload);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getHomeWork = () => {
    if (
      selectedCampus != "" &&
      (selectedClass == "" || selectedSection == "")
    ) {
      setHomeworkData([]);
      return alert.error("Please Select Class and Section");
    }
    if (selectedCampus == "" && selectedClass == "" && selectedSection == "") {
      setClassSectionID("");
      setHomeworkData([]);
    }

    setloading(true);
    fetch(
      `${baseUrl}/home-work/${currentSession != "" ? currentSession : null}/${
        classSectionID != "" ? classSectionID : null
      }/${selectedSubject != "" ? selectedSubject : null}/${
        currentDate != "" ? currentDate : null
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setHomeworkData(data.payload);
          setloading(false);
        } else if (!data.metadata.success) {
          alert.error(data.metadata.message);
          setloading(false);
          setHomeworkData([]);
        } else {
          setloading(false);
          setHomeworkData([]);
          alert.error("No Record Found");
        }
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  // Handle Update and Delete Tests
  const getEditHomeWork = async (id) => {
    await fetch(`${baseUrl}/home-work/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setCurrentSession(data.payload?.session_id);
          setEditSubject(data.payload?.subject_id);
          setEditClassSectionID(data.payload?.class_section_id);
          setEditRemarks(
            data.payload?.remarks == null ? "" : data.payload?.remarks
          );
          setEditDate(data.payload?.date);
          setTitle(data.payload?.title);
          setDescription(data.payload?.description);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateHomeWorkHandler = async () => {
    if (editDate == "" || title == "" || description == "") {
      setisallfields(true);
      return;
    }
    setEditLoading(true);
    setisallfields(false);
    const data = {
      remarks: editRemarks,
      date: editDate,
      title: title,
      description: description,
      class_section_id: editClassSectionID,
      year_id: currentSession,
      subject_id: editSubject,
    };

    await fetch(`${baseUrl}/home-work/${editHomeWorkID}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setEditLoading(false);
            setUpdateHomeWorkModal(false);
            alert.success("Test successfully Updated");
            getHomeWork();
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setEditLoading(false);
            setUpdateHomeWorkModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditLoading(false);
            setUpdateHomeWorkModal(false);
          }
        })
      )
      .catch((err) => {
        setEditLoading(false);
        setUpdateHomeWorkModal(false);
        console.log(err);
      });
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(homeworkData.length / PER_PAGE);
  const _DATA = usePagination(homeworkData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const config = {
    readonly: false,
    placeholder: "Start typing home work description...",
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "20px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              onChange={(e) => setCurrentSession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentSession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedCampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Campus</option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedClass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Class</option>
              {allClasses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value={1}>Education</option>
              <option value={2}>Hifz</option>
            </select>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3">
            <label>
              <b>Select Section</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedSection}
              onChange={(e) => getClassSectionId(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Section</option>
              {allCampusSections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Subject</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Subject</option>
              {allSubjects.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>
                Select Date{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <input
              value={currentDate}
              className="form-control"
              onChange={(e) => setCurrentDate(e.target.value)}
              type="date"
            />
          </div>

          <div className="col-md-3">
            <button
              className="btn btn-primary mx-2 mt-9 w-150px"
              onClick={getHomeWork}
            >
              Get Home Work
            </button>
          </div>
        </div>

        <div className="mt-5">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">Sr.No</th>
                <th className="text-center">Campus Name</th>
                <th className="text-center">Class (Section) Name</th>
                <th className="text-center">Title</th>
                <th className="text-center">Subject</th>
                <th className="text-center">Remarks</th>
                <th className="text-center">Date</th>
                <th className="text-center">Edit</th>
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((homeList, index) => (
                <tr key={index}>
                  <td className="text-center">{homeList?.id}</td>
                  <td className="text-center">
                    {homeList?.class_section?.campus?.name}
                  </td>
                  <td className="text-center">
                    {homeList?.class_section?.student_class?.name} (
                    {homeList?.class_section?.section?.name})
                  </td>
                  <td className="text-center">{homeList?.title}</td>
                  <td className="text-center">{homeList?.subject?.name}</td>
                  <td className="text-center">{homeList?.remarks}</td>
                  <td className="text-center">{homeList?.date}</td>

                  <td className="text-center">
                    <a
                      className={
                        "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      }
                      onClick={() => {
                        getEditHomeWork(homeList?.id);
                        setEditHomeWorkID(homeList?.id);
                        setUpdateHomeWorkModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary ">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row justify-content-center">
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>

          <Pagination
            color="primary"
            count={count}
            size="large"
            page={page}
            variant="text"
            shape="round"
            onChange={handleChange}
          />
        </div>

        {/* Update Test */}
        <Modal show={updateHomeWorkModal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Home Work
            </Modal.Title>
          </Modal.Header>

          {editTestLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Home Work Updating Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-8">
                  <label>
                    <b>Enter Home Work Remarks</b> (Optional)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editRemarks}
                    onChange={(e) => setEditRemarks(e.target.value)}
                    name="edit_remarks"
                    placeholder="Enter Home Work Remarks"
                  />
                </div>

                <div className="col-md-4">
                  <label>
                    <b>
                      Select Date{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    value={editDate}
                    className="form-control"
                    onChange={(e) => setEditDate(e.target.value)}
                    type="date"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <label className="form-control-label">
                    <b>
                      Enter Home Work Title{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    value={title}
                    className="form-control"
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    name="homework_title"
                    placeholder="Enter Home Work Title"
                  />
                </div>

                <div className="col-md-12 mt-5">
                  <label className="form-control-label">
                    <b>
                      Enter Home Work Description
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={description}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setDescription(newContent)}
                  />
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}
          <Modal.Footer>
            <button
              disabled={editTestLoading ? true : false}
              className="btn btn-primary"
              onClick={updateHomeWorkHandler}
            >
              Update Home Work
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setUpdateHomeWorkModal(false);
                setisallfields(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
};

export default UpdateHomeWork;
