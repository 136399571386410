import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../services/config";

import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { useAlert } from "react-alert";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import ProjectedMonthlyIncomeExport from "./ProjectedMonthlyIncomeExport";
import { upperCase } from "lodash";

const ProjectedMonthlyIncome = () => {
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const alert = useAlert();

  // Balance Sheet
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [sessionName, setSessionName] = useState([]);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [campus, setCampus] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [averageIncomeData, setAverageIncomeData] = useState([]);
  const [feeBreakUpData, setFeeBreakUpData] = useState([]);
  const [fullCampus, setFullCampus] = useState({});
  const [isVisibleProjected, setIsVisibleProjected] = useState(false);
  const [isVisibleAverage, setIsVisibleAverage] = useState(false);
  const [isVisibleFeeBreakUp, setIsVisibleFeeBreakUp] = useState(false);

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRefProj = useRef(null);
  const componentRefAvg = useRef(null);
  const componentRefFeeBreak = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  // Legal Page Size
  const pageStyle = `
  @page {
    margin-left: 0.5in;
    size: a4 !important;
    }
`;

  const [loading, setloading] = useState(false);
  const [Count, setCount] = useState(false);

  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  // Due FEE wala module ha
  const [avgIncome, setAvgIncome] = useState(false);
  const [feeBreakUp, setFeeBreakUp] = useState(false);
  const [projIncome, setProjIncome] = useState(true);

  const [selected, setSelected] = useState("projIncome");

  const [GrandMonthlyFee, setGrandMonthlyFee] = useState(0);
  const [GrandHostelFee, setGrandHostelFee] = useState(0);
  const [GrandStudentCount, setGrandStudentCount] = useState(0);
  const [GrandAverageFee, setGrandAverageFee] = useState(0);
  const [GrandTotal, setGrandTotal] = useState(0);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();

    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
    }

    sessions();
    // getAllCampus();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const printingDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fdata = new FormData();

    fdata.append("project_income", projIncome ? 1 : 0);
    fdata.append("average_income", avgIncome ? 1 : 0);
    fdata.append("fee_break_up", feeBreakUp ? 1 : 0);
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;

    console.log(value);
    setSelected(e.target.value);
    if (value == "projIncome") {
      setProjIncome(true);
      setAvgIncome(false);
      setFeeBreakUp(false);
      setIsVisibleProjected(false);
      setIsVisibleAverage(false);
      setIsVisibleFeeBreakUp(false);
    }
    if (value == "avgIncome") {
      setProjIncome(false);
      setAvgIncome(true);
      setFeeBreakUp(false);
      setIsVisibleProjected(false);
      setIsVisibleAverage(false);
      setIsVisibleFeeBreakUp(false);
    }
    if (value == "feeBreakUp") {
      setProjIncome(false);
      setAvgIncome(false);
      setFeeBreakUp(true);
      setIsVisibleProjected(false);
      setIsVisibleAverage(false);
      setIsVisibleFeeBreakUp(false);
    }
  };

  const showProjIncome = async () => {
    // if (campus == "") {
    //   alert.error("Please Select Campus");
    //   return;
    // }

    let data = {
      year_id: yearId,
      campus_id: campusId ? [parseInt(campusId)] : [],
    };
    setloading(true);

    const sessionTemp = sessionData.find((session) => {
      return session.id == yearId;
    });
    setSessionName(sessionTemp.year);

    await fetch(`${baseUrl}/projected-monthly-income-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          setIsVisibleProjected(true);
          if (data.metadata) {
            if (data.metadata.success) {
              console.log(data.payload);
              setReportData(data.payload);

              let sumMonthly = 0;
              data.payload.all_campus_data.map((item) => {
                item.campus_data.map((subitem) => {
                  sumMonthly += parseInt(subitem.monthly_fee);
                });
              });
              setGrandMonthlyFee(sumMonthly);

              let sumHostel = 0;
              data.payload.all_campus_data.map((item) => {
                item.campus_data.map((subitem) => {
                  sumHostel += parseInt(subitem.hostel_fee);
                });
              });
              setGrandHostelFee(sumHostel);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const showAvgIncome = () => {
    // if (campus == "") {
    //   alert.error("Please Select Campus");
    //   return;
    // }

    let data = {
      year_id: yearId,
      campus_id: campusId ? [parseInt(campusId)] : [],
    };
    setloading(true);

    fetch(`${baseUrl}/projected-monthly-income-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          setIsVisibleAverage(true);
          if (data.metadata) {
            if (data.metadata.success) {
              console.log(data.payload);
              setAverageIncomeData(data.payload);

              let sumMonthly = 0;
              data.payload.all_campus_data.map((item) => {
                item.campus_data.map((subitem) => {
                  sumMonthly += parseInt(subitem.monthly_fee);
                });
              });
              setGrandMonthlyFee(sumMonthly);

              let sumStudentCount = 0;
              data.payload.all_campus_data.map((item) => {
                item.campus_data.map((subitem) => {
                  sumStudentCount += parseInt(subitem.total_student_count);
                });
              });
              setGrandStudentCount(sumStudentCount);

              let sumAverageFee = 0;
              data.payload.all_campus_data.map((item) => {
                item.campus_data.map((subitem) => {
                  if (
                    subitem.monthly_fee == 0 ||
                    subitem.total_student_count == 0
                  ) {
                    sumAverageFee = 0;
                  } else {
                    sumAverageFee += parseInt(
                      subitem.monthly_fee / subitem.total_student_count
                    );
                  }
                });
              });
              setGrandAverageFee(sumAverageFee);
              setCount(data.payload.all_campus_data?.campus_data.length);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const showFeeBreak = () => {
    // if (campus == "") {
    //   alert.error("Please Select Campus");
    //   return;
    // }

    let data = {
      year_id: yearId,
      campus_id: campusId ? [parseInt(campusId)] : [],
    };
    setloading(true);

    fetch(`${baseUrl}/monthly_fee_break_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          setIsVisibleFeeBreakUp(true);
          if (data.metadata) {
            if (data.metadata.success) {
              let sumStudentCount = 0;
              data.payload.all_campus_data.map((item) => {
                item.campus_data.map((subitem) => {
                  sumStudentCount += parseInt(subitem.countStudent);
                });
              });
              setGrandStudentCount(sumStudentCount);

              let sumTotal = 0;
              data.payload.all_campus_data.map((item) => {
                item.campus_data.map((subitem) => {
                  sumTotal += parseInt(subitem.countStudent * subitem.amount);
                });
              });
              setGrandTotal(sumTotal);

              console.log(data.payload);
              setFeeBreakUpData(data.payload);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const getMonthlyFeePA = (data) => {
    let sumHostelFee = 0;
    data.campus_data.map((subitem) => {
      sumHostelFee += parseInt(subitem.monthly_fee);
    });
    return sumHostelFee;
  };

  const getTotalStudentCountPA = (data) => {
    let sumStudentCount = 0;
    data.campus_data.map((subitem) => {
      sumStudentCount += parseInt(subitem.total_student_count);
    });
    return sumStudentCount;
  };

  const getHostelFeePA = (data) => {
    let sumHostelFee = 0;
    data.campus_data.map((subitem) => {
      sumHostelFee += parseInt(subitem.hostel_fee);
    });
    return sumHostelFee;
  };

  const getStudentCount = (data) => {
    let sumStudentCount = 0;
    data.campus_data.map((subitem) => {
      sumStudentCount += parseInt(subitem.countStudent);
    });
    return sumStudentCount;
  };

  const getSumTotal = (data) => {
    let sumTotal = 0;
    data.campus_data.map((subitem) => {
      sumTotal += parseInt(subitem.countStudent * subitem.amount);
    });
    return sumTotal;
  };

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* <h1>Search Staff</h1> */}
        <form onSubmit={handleSubmit}>
          <div className="row mt-6">
            <div className="col-sm-3">
              <input
                // disabled={loginAccount == "Campus" ? true : false}
                checked={selected == "projIncome"}
                onChange={(e) => onRadioButtonClick(e)}
                defaultChecked
                type="radio"
                id="projIncome"
                name="contact"
                value="projIncome"
              />
              <label for="projIncome">Projected Income Report</label>
            </div>

            <div className="col-sm-3">
              <input
                checked={selected == "avgIncome"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="avgIncome"
                name="contact"
                value="avgIncome"
              />
              <label for="avgIncome">Average Income Report</label>
            </div>

            <div className="col-sm-3">
              <input
                checked={selected == "feeBreakUp"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="feeBreakUp"
                name="contact"
                value="feeBreakUp"
              />
              <label for="feeBreakUp">Monthly Fee Break Up Report</label>
            </div>
          </div>

          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {projIncome ? (
              <div className="row mt-6" style={{ width: "100%" }}>
                <div className="col-md-3">
                  <label>
                    <b>Select Session</b>
                  </label>
                  <div className="d-flex flex-column ">
                    <select
                      style={{ height: 40 }}
                      className="form-select"
                      name="year_id"
                      value={yearId}
                      aria-label="Default select example"
                      onChange={(e) => setYearId(e.target.value)}
                    >
                      {sessionData &&
                        sessionData.map((item) => (
                          <option value={item.id}>{item.year}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>
                    <b>Select Campus</b>
                  </label>
                  <div className="d-flex flex-column">
                    <select
                      style={{ height: 40 }}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setCampusId(e.target.value)}
                    >
                      <option selected value="">
                        {" "}
                        Select Campus
                      </option>
                      {campusData &&
                        campusData.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mt-9">
                  <button className="btn btn-primary" onClick={showProjIncome}>
                    Show
                  </button>
                  {reportData && reportData.all_campus_data && (
                    <ReactToPrint
                      pageStyle={pageStyle}
                      documentTitle={`Projected Monthly Income`}
                      onAfterPrint={handleAfterPrint}
                      onBeforeGetContent={handleOnBeforeGetContent}
                      onBeforePrint={handleBeforePrint}
                      removeAfterPrint={true}
                      trigger={() => (
                        <button
                          // onClick={() => console.log("dfsdfsdfd")}
                          disabled={printloading ? true : false}
                          style={{ marginLeft: "10px" }}
                          className="btn btn-success button_blue button_blue "
                        >
                          {printloading ? <span>Printing..</span> : "Print"}
                        </button>
                      )}
                      content={() => componentRefProj.current}
                    />
                  )}
                  {isVisibleProjected && (
                    <ProjectedMonthlyIncomeExport
                      reportData={reportData}
                      GrandMonthlyFee={GrandMonthlyFee}
                      GrandHostelFee={GrandHostelFee}
                      GrandStudentCount={GrandStudentCount}
                      GrandAverageFee={GrandAverageFee}
                      GrandTotal={GrandTotal}
                      isVisibleProjected={isVisibleProjected}
                    />
                  )}
                </div>
              </div>
            ) : null}

            {avgIncome ? (
              <div className="row mt-6" style={{ width: "100%" }}>
                <div className="col-md-3">
                  <label>
                    <b>Select Session</b>
                  </label>
                  <div className="d-flex flex-column ">
                    <select
                      style={{ height: 40 }}
                      className="form-select"
                      name="year_id"
                      value={yearId}
                      aria-label="Default select example"
                      onChange={(e) => setYearId(e.target.value)}
                    >
                      {sessionData &&
                        sessionData.map((item) => (
                          <option value={item.id}>{item.year}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>
                    <b>Select Campus</b>
                  </label>
                  <div className="d-flex flex-column">
                    <select
                      style={{ height: 40 }}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setCampusId(e.target.value)}
                    >
                      <option selected value="">
                        {" "}
                        Select Campus
                      </option>
                      {campusData &&
                        campusData.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mt-9 d-flex">
                  <button className="btn btn-primary" onClick={showAvgIncome}>
                    Show
                  </button>
                  {averageIncomeData && averageIncomeData.all_campus_data && (
                    <ReactToPrint
                      pageStyle={pageStyle}
                      documentTitle={`Average Monthly Income`}
                      onAfterPrint={handleAfterPrint}
                      onBeforeGetContent={handleOnBeforeGetContent}
                      onBeforePrint={handleBeforePrint}
                      removeAfterPrint={true}
                      trigger={() => (
                        <button
                          // onClick={() => console.log("dfsdfsdfd")}
                          disabled={printloading ? true : false}
                          style={{ marginLeft: "10px" }}
                          className="btn btn-success button_blue button_blue "
                        >
                          {printloading ? <span>Printing..</span> : "Print"}
                        </button>
                      )}
                      content={() => componentRefAvg.current}
                    />
                  )}
                  {isVisibleAverage && (
                    <ProjectedMonthlyIncomeExport
                      averageIncomeData={averageIncomeData}
                      GrandMonthlyFee={GrandMonthlyFee}
                      GrandHostelFee={GrandHostelFee}
                      GrandStudentCount={GrandStudentCount}
                      GrandAverageFee={GrandAverageFee}
                      GrandTotal={GrandTotal}
                      Count={Count}
                      isVisibleAverage={isVisibleAverage}
                    />
                  )}
                </div>
              </div>
            ) : null}

            {feeBreakUp ? (
              <div className="row mt-6" style={{ width: "100%" }}>
                <div className="col-md-3">
                  <label>
                    <b>Select Session</b>
                  </label>
                  <div className="d-flex flex-column ">
                    <select
                      style={{ height: 40 }}
                      className="form-select"
                      name="year_id"
                      value={yearId}
                      aria-label="Default select example"
                      onChange={(e) => setYearId(e.target.value)}
                    >
                      {sessionData &&
                        sessionData.map((item) => (
                          <option value={item.id}>{item.year}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>
                    <b>Select Campus</b>
                  </label>
                  <div className="d-flex flex-column">
                    <select
                      style={{ height: 40 }}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setCampusId(e.target.value)}
                    >
                      <option selected value="">
                        {" "}
                        Select Campus
                      </option>
                      {campusData &&
                        campusData.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mt-9 d-flex">
                  <button className="btn btn-primary" onClick={showFeeBreak}>
                    Show
                  </button>
                  {feeBreakUpData && feeBreakUpData.all_campus_data && (
                    <ReactToPrint
                      pageStyle={pageStyle}
                      documentTitle={`Fee Break Up Monthly Income`}
                      onAfterPrint={handleAfterPrint}
                      onBeforeGetContent={handleOnBeforeGetContent}
                      onBeforePrint={handleBeforePrint}
                      removeAfterPrint={true}
                      trigger={() => (
                        <button
                          // onClick={() => console.log("dfsdfsdfd")}
                          disabled={printloading ? true : false}
                          style={{ marginLeft: "10px" }}
                          className="btn btn-success button_blue button_blue "
                        >
                          {printloading ? <span>Printing..</span> : "Print"}
                        </button>
                      )}
                      content={() => componentRefFeeBreak.current}
                    />
                  )}
                  {isVisibleFeeBreakUp && (
                    <ProjectedMonthlyIncomeExport
                      feeBreakUpData={feeBreakUpData}
                      GrandMonthlyFee={GrandMonthlyFee}
                      GrandHostelFee={GrandHostelFee}
                      GrandStudentCount={GrandStudentCount}
                      GrandAverageFee={GrandAverageFee}
                      GrandTotal={GrandTotal}
                      Count={Count}
                      isVisibleFeeBreakUp={isVisibleFeeBreakUp}
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </form>

        {/* Projected Monthly Income */}
        <div className="mt-9">
          {isVisibleProjected ? (
            <div id="tableboot mt-5">
              <div className="d-flex mb-4">
                <div className="">
                  <img
                    style={{ height: 100, width: 110, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="mx-5" style={{ paddingTop: "10px" }}>
                  <h1>{unitName}</h1>
                  <h3>Projected Monthly Income Report</h3>
                  {/* <h3>{reportData.campus}</h3> */}
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-center">Campus Name</th>
                    <th className="text-center">Class Name</th>
                    <th className="text-center">Monthly Fee</th>
                    <th className="text-center">Hostel Fee</th>
                  </tr>
                </thead>

                <tbody>
                  {reportData &&
                    reportData.all_campus_data &&
                    reportData.all_campus_data.map((item, key) => (
                      <>
                        <tr
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          <td
                            style={{
                              fontSize: "1.2em",
                              borderTop: "1px solid black",
                            }}
                            className="text_align_center text-primary"
                          >
                            {item.campus}
                          </td>
                          <td
                            style={{
                              fontSize: "1.2em",
                              borderTop: "1px solid black",
                            }}
                            className="text_align_center text-primary"
                          >
                            ----
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getMonthlyFeePA(item)}
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getHostelFeePA(item)}
                          </td>
                        </tr>
                        {item.campus_data.map((subitem, key) => (
                          <tr key={key}>
                            <td></td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.Class.name}
                            </td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.monthly_fee}
                            </td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.hostel_fee}
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                  <tr id="tbl">
                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                      }}
                      className="text-center text-primary"
                    >
                      Grand Total :-
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      ----
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {GrandMonthlyFee}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {GrandHostelFee}
                    </td>
                  </tr>

                  <tr id="tbl">
                    <td
                      colSpan={2}
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                      }}
                      className="text-center text-primary"
                    >
                      Sum of Monthly Fee & Hostel Fee :-
                    </td>

                    <td
                      colSpan={2}
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {GrandMonthlyFee + GrandHostelFee}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>

        <div
          ref={componentRefProj}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div className="row p-3 d-flex mb-3">
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col">
              <h1 style={{ fontSize: "2.5rem" }}>{unitName}</h1>
              <h3 style={{ fontSize: "2rem" }}>
                Projected Monthly Income Report
                {/* ({reportData.campus}) */}
              </h3>
              <div className="d-flex justify-content-between">
                <h3> Session: {sessionName}</h3>
                <h3> Printing Date: {printingDate()}</h3>
              </div>
            </div>
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr id="tbl">
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Campus Name
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Class Name
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Monthly Fee
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Hostel Fee
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData &&
                reportData.all_campus_data &&
                reportData.all_campus_data.map((item, key) => (
                  <>
                    <tr
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text_align_center text-primary"
                      >
                        {item.campus}
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text_align_center text-primary"
                      >
                        ----
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {getMonthlyFeePA(item)}
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {getHostelFeePA(item)}
                      </td>
                    </tr>
                    {item.campus_data.map((subitem, key) => (
                      <tr key={key}>
                        <td
                          colSpan={2}
                          className="text-end"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                            paddingRight: "100px",
                          }}
                        >
                          {subitem.Class.name}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                          }}
                        >
                          {subitem.monthly_fee}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                          }}
                        >
                          {subitem.hostel_fee}
                        </td>
                      </tr>
                    ))}
                  </>
                ))}

              <tr id="tbl">
                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  Grand Total
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  ----
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  {GrandMonthlyFee}
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  {GrandHostelFee}
                </td>
              </tr>
              <tr id="tbl">
                <td
                  colSpan={2}
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  Sum of Monthly Fee & Hostel Fee
                </td>

                <td
                  colSpan={2}
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  {GrandMonthlyFee + GrandHostelFee}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Average Monthly Income */}
        <div className="mt-9">
          {isVisibleAverage ? (
            <div id="tableboot mt-5">
              <div className="d-flex mb-4">
                <div className="">
                  <img
                    style={{ height: 100, width: 110, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="mx-5" style={{ paddingTop: "10px" }}>
                  <h1>{unitName}</h1>
                  <h3>Aveage Income Report Fee Break Up</h3>
                  <h3>{averageIncomeData.campus}</h3>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-center">Campus Name</th>
                    <th className="text-center">Class Name</th>
                    <th className="text-center">Monthly Fee</th>
                    <th className="text-center">Student Count</th>
                    <th className="text-center">Average Fee</th>
                  </tr>
                </thead>

                <tbody>
                  {averageIncomeData &&
                    averageIncomeData.all_campus_data &&
                    averageIncomeData.all_campus_data.map((item, key) => (
                      <>
                        <tr
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          <td
                            style={{
                              fontSize: "1.2em",
                              borderTop: "1px solid black",
                            }}
                            className="text_align_center text-primary"
                          >
                            {item.campus}
                          </td>
                          <td
                            style={{
                              fontSize: "1.2em",
                              borderTop: "1px solid black",
                            }}
                            className="text_align_center text-primary"
                          >
                            ----
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getMonthlyFeePA(item)}
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getTotalStudentCountPA(item)}
                          </td>
                          {getMonthlyFeePA(item) == 0 ||
                          getTotalStudentCountPA(item) == 0 ? (
                            <td
                              style={{ fontSize: "1.2rem" }}
                              className="text-center text-primary"
                            >
                              {0}
                            </td>
                          ) : (
                            <td
                              style={{ fontSize: "1.2rem" }}
                              className="text-center text-primary"
                            >
                              {parseInt(
                                getMonthlyFeePA(item) /
                                  getTotalStudentCountPA(item)
                              )}
                            </td>
                          )}
                        </tr>
                        {item.campus_data.map((subitem, key) => (
                          <tr key={key}>
                            <td></td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.Class.name}
                            </td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.monthly_fee}
                            </td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.total_student_count}
                            </td>
                            {subitem.monthly_fee == 0 ||
                            subitem.total_student_count == 0 ? (
                              <td
                                className="text-center"
                                style={{ fontSize: "1rem" }}
                              >
                                {0}
                              </td>
                            ) : (
                              <td
                                className="text-center"
                                style={{ fontSize: "1rem" }}
                              >
                                {parseInt(
                                  subitem.monthly_fee /
                                    subitem.total_student_count
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                      </>
                    ))}

                  <tr id="tbl">
                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                      }}
                      className="text-center text-primary"
                    >
                      Grand Total :-
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      ----
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {GrandMonthlyFee}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {GrandStudentCount}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {parseInt(GrandMonthlyFee / GrandStudentCount)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>

        <div
          ref={componentRefAvg}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div className="row p-3 d-flex mb-3">
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col">
              <h1 style={{ fontSize: "2.5rem" }}>{unitName}</h1>
              <h3 style={{ fontSize: "2rem" }}>
                Aveage Income Report Fee Break Up
                {/* ({averageIncomeData.campus}) */}
              </h3>
              <div className="d-flex justify-content-between">
                <h3> Session: {sessionName}</h3>
                <h3> Printing Date: {printingDate()}</h3>
              </div>
            </div>
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr id="tbl">
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Campus Name
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Class Name
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Monthly Fee
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Student Count
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Aveage Fee
                </th>
              </tr>
            </thead>
            <tbody>
              {averageIncomeData &&
                averageIncomeData.all_campus_data &&
                averageIncomeData.all_campus_data.map((item, key) => (
                  <>
                    <tr
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text_align_center text-primary"
                      >
                        {item.campus}
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text_align_center text-primary"
                      >
                        ----
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {getMonthlyFeePA(item)}
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {getTotalStudentCountPA(item)}
                      </td>
                      {getMonthlyFeePA(item) == 0 ||
                      getTotalStudentCountPA(item) == 0 ? (
                        <td
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                          }}
                          className="text-center text-primary"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                          }}
                          className="text-center text-primary"
                        >
                          {parseInt(
                            getMonthlyFeePA(item) / getTotalStudentCountPA(item)
                          )}
                        </td>
                      )}
                    </tr>
                    {item.campus_data.map((subitem, key) => (
                      <tr key={key}>
                        <td
                          colSpan={2}
                          className="text-end"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                            paddingRight: "70px",
                          }}
                        >
                          {subitem.Class.name}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                          }}
                        >
                          {subitem.monthly_fee}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                          }}
                        >
                          {subitem.total_student_count}
                        </td>
                        {subitem.monthly_fee == 0 ||
                        subitem.total_student_count == 0 ? (
                          <td
                            className="text-center"
                            style={{
                              fontSize: "1.5rem",
                              border: "1px solid black",
                            }}
                          >
                            {0}
                          </td>
                        ) : (
                          <td
                            className="text-center"
                            style={{
                              fontSize: "1.5rem",
                              border: "1px solid black",
                            }}
                          >
                            {parseInt(
                              subitem.monthly_fee / subitem.total_student_count
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </>
                ))}

              <tr id="tbl">
                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  Grand Total
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  ----
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  {GrandMonthlyFee}
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  {GrandStudentCount}
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  {parseInt(GrandMonthlyFee / GrandStudentCount)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Fee Break Up Monthly Income */}
        <div className="mt-9">
          {isVisibleFeeBreakUp ? (
            <div id="tableboot mt-5">
              <div className="d-flex mb-4">
                <div className="">
                  <img
                    style={{ height: 100, width: 110, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="mx-5" style={{ paddingTop: "10px" }}>
                  <h1>{unitName}</h1>
                  <h3>Expected Monthly Income Report Monthly Fee Break Up</h3>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-center">Campus Name</th>
                    <th className="text-center">Monthly Fee</th>
                    <th className="text-center">Student Count</th>
                    <th className="text-center">Total Amount</th>
                  </tr>
                </thead>

                <tbody>
                  {feeBreakUpData &&
                    feeBreakUpData.all_campus_data &&
                    feeBreakUpData.all_campus_data.map((item, key) => (
                      <>
                        <tr
                          style={{
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black",
                          }}
                        >
                          <td
                            style={{
                              fontSize: "1.2em",
                              borderTop: "1px solid black",
                            }}
                            className="text_align_center text-primary"
                          >
                            {item.campus_name}
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            ----
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getStudentCount(item)}
                          </td>
                          <td
                            style={{ fontSize: "1.2rem" }}
                            className="text-center text-primary"
                          >
                            {getSumTotal(item)}
                          </td>
                        </tr>
                        {item.campus_data.map((subitem, key) => (
                          <tr key={key}>
                            <td></td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.amount}
                            </td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.countStudent}
                            </td>
                            <td
                              className="text-center"
                              style={{ fontSize: "1rem" }}
                            >
                              {subitem.amount * subitem.countStudent}
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}

                  <tr id="tbl">
                    <td
                      style={{
                        fontSize: "1.3rem",
                        // paddingLeft: "300px",
                        fontWeight: "bold",
                      }}
                      className="text-center text-primary"
                    >
                      Grand Total :-
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      ----
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {GrandStudentCount}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-center text-primary"
                    >
                      {GrandTotal}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>

        <div
          ref={componentRefFeeBreak}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div className="row p-3 d-flex mb-3">
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col">
              <h1 style={{ fontSize: "2.5rem" }}>{unitName}</h1>
              <h3 style={{ fontSize: "2rem" }}>
                Expected Monthly Income Report Monthly Fee Break Up
              </h3>
              <div className="d-flex justify-content-between">
                <h3> Session: {sessionName}</h3>
                <h3> Printing Date: {printingDate()}</h3>
              </div>
            </div>
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr id="tbl">
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Campus Name
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Monthly Fee
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Student Count
                </th>
                <th
                  className="text-center text-primary"
                  style={{ fontSize: "1.7rem", border: "1px solid black" }}
                >
                  Total Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {feeBreakUpData &&
                feeBreakUpData.all_campus_data &&
                feeBreakUpData.all_campus_data.map((item, key) => (
                  <>
                    <tr>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text_align_center text-primary"
                      >
                        {item.campus_name}
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        ----
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {getStudentCount(item)}
                      </td>
                      <td
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid black",
                        }}
                        className="text-center text-primary"
                      >
                        {getSumTotal(item)}
                      </td>
                    </tr>
                    {item.campus_data.map((subitem, key) => (
                      <tr key={key}>
                        <td
                          colSpan={2}
                          className="text-end"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                            paddingRight: "100px",
                          }}
                        >
                          {subitem.amount}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                          }}
                        >
                          {subitem.countStudent}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: "1.5rem",
                            border: "1px solid black",
                          }}
                        >
                          {subitem.amount * subitem.countStudent}
                        </td>
                      </tr>
                    ))}
                  </>
                ))}

              <tr id="tbl">
                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  Grand Total
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  ----
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  {GrandStudentCount}
                </td>

                <td
                  style={{
                    fontSize: "1.7rem",
                    fontWeight: "bold",
                    border: "1px solid black",
                  }}
                  className="text-center text-primary"
                >
                  {GrandTotal}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default ProjectedMonthlyIncome;
