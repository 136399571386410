import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";

import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";

const ListWithBankData = () => {
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const pageStyle = `@page{
    size: A4 !important;
  }`;
  let authtoken = localStorage.getItem("userToken");

  const [loading, setloading] = useState(false);
  const [reportDetails, setreportDetails] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState("");
  const [campusName, setCampusName] = useState("");

  const alert = useAlert();
  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    // getAllCampus();
    getSettingData();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeCampus = async () => {
    if (selectedcampus == "") {
      alert.error("Please Select Campus");
      return;
    }
    setloading(true);

    const campus = campuses.find((item) => {
      return item.id == selectedcampus;
    });
    setCampusName(campus.name);

    const data = {
      campus_id: selectedcampus,
    };
    await fetch(`${baseUrl}/staff_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setloading(false);
        setIsVisible(true);
        setreportDetails(data.payload);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  return (
    <>
      <Paper className="p-5 mt-5 mb-5">
        <div className="row flex">
          <div className="col-md-4">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => setselectedcampus(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-sm-1 mt-9 mb-5">
            <button
              onClick={onchangeCampus}
              className="btn btn-primary me-2"
              id="myBtn"
            >
              Show
            </button>
          </div>
          <div className="col-sm-1 mt-9" style={{ marginLeft: "-15px" }}>
            {reportDetails?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Appointed  Report`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            <div className="row p-3">
              <img
                // className='col-md-2'
                style={{ height: 100, width: 110 }}
                src={icon}
              />

              <div className="col-md-6">
                <h1>{unitName}</h1>
                <h5>Staff List with Bank Details Of {campusName}</h5>
              </div>
            </div>

            <ColoredLine color={"#FF0000"} />

            <Table responsive>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Sr.</th>
                  <th style={{ textAlign: "center" }}>Code</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>CNIC NO</th>
                  <th style={{ textAlign: "start" }}>Bank Name</th>
                  <th style={{ textAlign: "start" }}>A/C No</th>
                  <th style={{ textAlign: "start" }}>Permanent Address</th>
                  <th style={{ textAlign: "start" }}>Description</th>
                </tr>
              </thead>
              <tbody>
                {reportDetails &&
                  reportDetails.map((report, index) => (
                    <tr>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{report.emp_code}</td>
                      <td style={{ textAlign: "start" }}>{report.full_name}</td>
                      <td style={{ textAlign: "start" }}>{report.cnic_no}</td>
                      <td style={{ textAlign: "start" }}>
                        {report.bank_account.bank_name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        {report.account_no}
                      </td>
                      <td style={{ textAlign: "start" }}>{report.address}</td>
                      <td style={{ textAlign: "start" }}>
                        {report.objectives}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : null}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="p-3 d-flex">
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col-md-8">
              <h1>{unitName}</h1>
              <h5>List Of Staff With Bank Data From {campusName}</h5>
            </div>
          </div>

          <Table style={{ width: "100%", marginTop: "10px", fontSize: "14px" }}>
            <thead style={{ whiteSpace: "normal", padding: "1px" }}>
              <tr style={{ whiteSpace: "normal", padding: "1px" }}>
                <th
                  style={{
                    textAlign: "start",
                    width: "3%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                    fontSize: "14px",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "3%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                    fontSize: "14px",
                  }}
                >
                  Code
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "10%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                    fontSize: "14px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "10%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                    fontSize: "14px",
                  }}
                >
                  CNIC No.
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "10%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                    fontSize: "14px",
                  }}
                >
                  Bank Name
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "5%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                    fontSize: "14px",
                  }}
                >
                  Account No
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "10%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                    fontSize: "14px",
                  }}
                >
                  Permanent Address
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "5%",
                    whiteSpace: "normal",
                    padding: "1px",
                    borderTop: "1px solid black",
                    fontSize: "14px",
                  }}
                >
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {reportDetails &&
                reportDetails.map((report, index) => (
                  <tr
                    style={{
                      textAlign: "start",
                      whiteSpace: "normal",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                      marginTop: "-25px",
                      padding: "1px",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ marginTop: "-5px" }}>{index + 1}</p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ marginTop: "-5px" }}> {report.emp_code}</p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {" "}
                        {/* {report.full_name} */}
                        {report.full_name.length > 25
                          ? report.full_name.substring(0, 22) + "..."
                          : report.full_name}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ marginTop: "-5px" }}>{report.cnic_no}</p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {report.bank_account.bank_name}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ whiteSpace: "normal", marginTop: "-5px" }}>
                        {" "}
                        {report.account_no}
                      </p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      <p style={{ marginTop: "-5px" }}>{report.address}</p>
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    ></td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Paper>
    </>
  );
};

export default ListWithBankData;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
