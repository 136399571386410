import React from "react";
import { Table } from "react-bootstrap";
import "./style.css";

const StudentConcessionPrint = ({
  reportData,
  getfeeSum,
  getConfeeSum,
  feeTotal,
  grandTotal,
}) => {
  let num2 = 0;

  return (
    <>
      <div id="tableboot">
        <table style={{ width: "100%" }}>
          <thead>
            <tr id="tbl" className="borderIt">
              <td
                style={{
                  color: "black",
                  textAlign: "center",
                }}
              >
                Sr.No
              </td>
              <td
                style={{
                  color: "black",
                  textAlign: "start",
                }}
              >
                Adm-Id
              </td>
              <td
                style={{
                  color: "black",
                  textAlign: "start",
                }}
              >
                Name
              </td>
              <td
                style={{
                  color: "black",
                  textAlign: "start",
                }}
              >
                Father Name
              </td>
              <td
                style={{
                  color: "black",
                  textAlign: "start",
                }}
              >
                Mobile
              </td>
              <td
                style={{
                  color: "black",
                  textAlign: "start",
                }}
              >
                Fee
              </td>
              <td
                style={{
                  color: "black",
                  textAlign: "center",
                }}
              >
                Concession
              </td>
              <td
                style={{
                  color: "black",
                  textAlign: "center",
                }}
              >
                Remarks
              </td>
            </tr>
          </thead>
          {reportData.length > 0
            ? reportData.map((item) =>
                getfeeSum(item) > 0 && getConfeeSum(item) > 0 ? (
                  <>
                    <tr className="mx-4 borderIt" style={{ marginTop: "40px" }}>
                      <td colSpan={7} className="text_align_start">
                        <h3 className="text-primary">{item.Campus}</h3>
                      </td>
                    </tr>
                    {item?.classes?.map((subitem) =>
                      subitem?.sections?.map((session) => (
                        <>
                          {session.students.length > 0 ? (
                            <>
                              <tr className="borderIt">
                                <td colSpan={7} className="text_align_start">
                                  <h4 className="">
                                    Class:-{subitem.Class?.name}(
                                    {session.Section?.name})
                                  </h4>
                                </td>
                              </tr>
                              <tbody>
                                {session?.students.map((student) => (
                                  <tr id="tbl" className="borderIt">
                                    <td
                                      style={{ color: "black" }}
                                      className="text-center"
                                    >
                                      {(num2 += 1)}
                                    </td>
                                    <td
                                      style={{
                                        color: "black",
                                        width: "100px",
                                        textAlign: "center",
                                      }}
                                      // className="text_align_start"
                                    >
                                      {student.admission_id}
                                    </td>
                                    <td
                                      style={{ color: "black" }}
                                      className="text_align_start"
                                    >
                                      {student.name}
                                    </td>
                                    <td
                                      style={{ color: "black" }}
                                      className="text_align_start"
                                    >
                                      {student.father_name}
                                    </td>
                                    <td
                                      style={{ color: "black" }}
                                      className="text_align_start"
                                    >
                                      {student.mobile_no}
                                    </td>
                                    <td
                                      style={{ color: "black" }}
                                      className="text_align_start"
                                    >
                                      {student.student_liable_fees[0]?.amount}
                                    </td>
                                    <td
                                      style={{ color: "black" }}
                                      className="text-center"
                                    >
                                      {
                                        student.student_liable_fees[0]
                                          ?.concession_amount
                                      }
                                    </td>
                                    <td
                                      style={{ color: "black" }}
                                      className="text-center"
                                    >
                                      {student?.concession_remarks}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          ) : null}
                        </>
                      ))
                    )}
                    <tr id="tbl" className="borderIt">
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>

                      <td
                        style={{ fontSize: "1.5rem" }}
                        className="text-center text-primary"
                      >
                        Total
                      </td>
                      <td
                        style={{ color: "black", fontSize: "1.5rem" }}
                        className="text-center"
                      >
                        {getfeeSum(item)}
                      </td>
                      <td
                        style={{ color: "black", fontSize: "1.5rem" }}
                        className="text-center"
                      >
                        {getConfeeSum(item)}
                      </td>
                    </tr>
                  </>
                ) : null
              )
            : ""}

          <tr id="tbl" className="borderIt">
            <td style={{ color: "black" }} className="text_align_start"></td>
            <td style={{ color: "black" }} className="text_align_start"></td>
            <td style={{ color: "black" }} className="text_align_start"></td>
            <td style={{ color: "black" }} className="text_align_start"></td>
            <td
              style={{ fontSize: "1.5rem" }}
              className="text-center text-primary"
            >
              Grand Total
            </td>
            <td
              style={{ color: "black", fontSize: "1.5rem" }}
              className="text-center"
            >
              {feeTotal}
              {/* {getfeeSum(item)} */}
            </td>
            <td
              style={{ color: "black", fontSize: "1.5rem" }}
              className="text-center"
            >
              {grandTotal}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default StudentConcessionPrint;
