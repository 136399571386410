import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Table,
  Modal,
  Spinner,
  Alert,
  Form,
  ModalFooter,
  Badge,
  Button,
} from "react-bootstrap";
import { Modal as AntdModal, DatePicker } from "antd";
import { baseUrl } from "../../../../services/config";
import "./studentdues.css";
import ReactToPrint from "react-to-print";
import LinearProgress from "@material-ui/core/LinearProgress";
import { message } from "antd";
import { Toaster, toast } from "react-hot-toast";
import { array } from "prop-types";
import { sendNotificationsInBatches2 } from "../../../../components/SendNotifications/SendNotifications";
import DueFeeExport from "./DueFeeExport";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../../services/PremissionChecker";

const { RangePicker } = DatePicker;

const StudentDueFee = () => {
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  function onchangeHandler(text) {
    console.log(text);
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user = useSelector((state) => state.user.user);

  //print states
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const [session, setSession] = useState(
    PremissionChecker(user, "system_reports.session_wise_due_fee")
      ? true
      : false
  );
  const [classes, setClasses] = useState(false);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [campus, setCampus] = useState(
    PremissionChecker(user, "system_reports.session_wise_due_fee")
      ? false
      : true
  );
  const [section, setSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [both, setBoth] = useState(true);
  const [yearId, setYearId] = useState("");
  const [campusId, setCampusId] = useState("");
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [studentStatus, setStudentStatus] = useState(2);
  const [feeType, setFeeType] = useState("");
  const [studentsData, setStudentsData] = useState(0);
  const [sessionData, setSessionData] = useState([]);
  const [classesData, setClassesData] = useState([]);
  // const [campusData, setCampusData] = useState([]);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [sectionData, setSectionData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [sum, setSum] = useState(0);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [studentIds, setStudentIds] = useState([]);
  // const [challan, setChallan] = useState([])
  const [selected, setSelected] = useState(
    PremissionChecker(user, "system_reports.session_wise_due_fee")
      ? "session"
      : "campus"
  );
  // const [selected, setSelected] = useState("session");
  const [gender, setGender] = useState("both");
  const [printData, setPrintData] = useState([]);
  const [printClassData, setClassPrintData] = useState([]);
  const [printLoading, setprintLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [messageModel, setMessageModel] = useState(false);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [notificationConfirmModal, setNotificationConfirmModal] = useState(
    false
  );
  const [educationType, setEducationType] = useState(0);
  const [fee_types, setfee_types] = useState([]);
  let authtoken = localStorage.getItem("userToken");
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [messageArray, setMessageArray] = useState([]);
  const [myNotificationArray, setMyNotificationArray] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const pageStyle = `
  @page {
    size: legal portrait !important;
    margin-bottom: 1in;
  }
`;
  const key = "updatable";
  const [SessionYear, setSessionYear] = useState("");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [overDueDate, setOverDueDate] = useState(0);

  useEffect(() => {
    sessions();
    studentclasses();
    // getAllCampus();
    getFeeTypes();
    getSettingData();
  }, []);

  const sessions = async () => {
    await fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const studentclasses = () => {
    fetch(`${baseUrl}/studentclass`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClassesData(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFeeTypes = () => {
    fetch(`${baseUrl}/feestypes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setfee_types(data.payload);
        });
      })
      .catch((err) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (loginAccount == "Campus" && campusId == "") {
    if (
      PremissionChecker(user, "system_reports.session_wise_due_fee")
        ? undefined
        : campusId == ""
    ) {
      toast.error("Campus is required");
      return;
    }
    // if(endDate==""){
    //   toast.error("End Date is required")
    //   return
    // }

    const fdata = new FormData();

    fdata.append("session_wise", session ? 1 : 0);
    fdata.append("class_wise", classes ? 1 : 0);
    fdata.append("campus_wise", campus ? 1 : 0);
    fdata.append("section_wise", section ? 1 : 0);
    fdata.append("year_id", yearId);
    fdata.append("campus_id", campusId);
    fdata.append("student_class_id", classId);
    fdata.append("global_section_id", sectionId);
    fdata.append("male", male ? 1 : 0);
    fdata.append("female", female ? 1 : 0);
    fdata.append("both", both ? 1 : 0);
    fdata.append("student_status", studentStatus);
    fdata.append("type_of_fees", feeType);
    fdata.append("start_date", startDate + "-01");
    fdata.append("end_date", endDate + "-01");
    fdata.append("over_due", overDueDate);
    fdata.append("education_type", educationType);

    // console.log(session ? 1 : 0);
    // console.log(classes ? 1 : 0);
    // console.log(campus ? 1 : 0);
    // console.log(section ? 1 : 0);
    // console.log(yearId);
    // console.log(campusId);
    // console.log(classId);
    // console.log(sectionId);
    // console.log(male ? 1 : 0);
    // console.log(female ? 1 : 0);
    // console.log(both ? 1 : 0);
    // console.log(studentStatus);
    // console.log(feeType);

    // fdata.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });

    setLoading(true);

    fetch(`${baseUrl}/student_due_fee`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: fdata,
    }).then((resp) => {
      resp.json().then((data) => {
        // console.log(data.payload + "Waqas Shaptar2");
        // setLoading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            // console.log(data);
            console.log("studentdata", data.payload.students);
            setStudentsData(data.payload.students);
            setLoading(false);
            setcheckallcheckbox(false);
          }
        }
      });
    });
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;

    console.log(value);

    setSelected(e.target.value);
    if (value == "session") {
      setSession(true);
      setClasses(false);
      setCampus(false);
      setSection(false);
      setCampusId("");
      setSectionId("");
      setClassId("");
      setEducationType(0);
    }
    if (value == "class") {
      setSession(false);
      setClasses(true);
      setCampus(false);
      setSection(false);
      setClassesData("");
      setCampusId("");
      setSectionId("");
      setClassId("");
      setEducationType(0);
    }
    if (value == "campus") {
      setSession(false);
      setClasses(false);
      setCampus(true);
      setSection(false);
      setCampusId("");
      setSectionId("");
      setClassId("");
      setEducationType(0);
    }
    if (value == "section") {
      setSession(false);
      setClasses(false);
      setCampus(false);
      setSection(true);
      setClassesData("");
      setCampusId("");
      setSectionId("");
      setClassId("");
      setEducationType(0);
    }
  };

  const ongenderButtonClick = (e) => {
    const value = e.target.value;

    setGender(e.target.value);
    if (value == "male") {
      setMale(true);
      setFemale(false);
      setBoth(false);
    }
    if (value == "female") {
      setMale(false);
      setFemale(true);
      setBoth(false);
    }

    if (value == "both") {
      setMale(false);
      setFemale(false);
      setBoth(true);
    }
  };

  const onChampusChange = (id) => {
    setCampusId(id);
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setClassesData(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setClassId(id);
    fetch(`${baseUrl}/class_section/${campusId}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSectionData(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("", err);
      });
  };
  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${campusId}/${id}${
        classId ? "/" + classId : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}`;
  };

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  // const addintion = () => {
  //   const data =

  //     studentsData?.fee_challans.map((item) => setSum(item.payable + sum));
  //   console.log(data);
  // };

  const getFeeSum = (array) => {
    let sum = 0;
    array.forEach((element) => {
      element.fee_challan_details.forEach((subElement) => {
        sum += parseInt(subElement.amount);
      });
    });
    return sum;
  };

  const sendMessage = () => {
    // messageApi.open({
    //   key,
    //   type: "loading",
    //   content: "Sending.....",
    // });
    // setbuttonDisabled(true);

    setbuttonDisabled(false);
    setMessageModel(false);
    let data = {
      student_ids: messageArray,
    };

    fetch(`${baseUrl}/student-due-fee-sms`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              messageApi.open({
                key,
                type: "success",
                content: "Successfully Send",
                duration: 1,
              });
              setbuttonDisabled(false);
              setMessageModel(false);
            } else {
              messageApi.open({
                key,
                type: "error",
                content: "Something went wronge",
                duration: 4,
              });
              setbuttonDisabled(false);
            }
          }
          console.log(data);
        })
      )

      .catch((err) => {
        console.log(err);
      });
  };

  const allcheckboxChecked = () => {
    let studentarray = [];
    let middlearray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      studentsData.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
    } else {
      setcheckallcheckbox(true);
      studentsData.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
    }
    console.log(studentarray);
    console.log(middlearray);
    setStudentIds([...studentarray]);
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    studentsData.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    setStudentIds([...studentarray]);
  };

  const handlePrint = () => {
    setShow(true);
    setprintLoading(true);
    fetch(`${baseUrl}/student_due_fee_print_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({
        type_of_fees: feeType,
        student_ids: studentIds,
        start_date: startDate + "-01",
        end_date: endDate + "-01",
        over_due: overDueDate,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("print data", data);
        setprintLoading(false);
        setPrintData(data.payload);

        // data.payload.map((item) => {
        //   let sum2 = 0;
        //   let std_total_fee = 0;
        //   if (item.classes.length > 0) {
        //     item.classes.map((cl) => {
        //       if (cl.sections.length > 0) {
        //         cl.sections.map((se) => {
        //           se.students.forEach((student) => {
        //             // const challansSum = getFeeSum(
        //             //   feeChalanChecker(student.fee_challans)
        //             // );
        //             std_total_fee = std_total_fee + student.total_fee
        //             return (sum2 += challansSum);
        //           });
        //         });
        //       }
        //       item.amount = sum2;
        //     });
        //   }
        // // });
        let sum = 0;
        let grandtotal = data.payload.forEach((item) => {
          sum += item.amount;
          return sum;
        });
        console.log("grandtotal", sum, grandtotal);
        setGrandTotal(sum);
        setClassPrintData(data.payload.classes);
        // let sectionSum = 0;
        /////session wise sum
        // data.payload.forEach((item)=>{
        // item.classes.forEach((subitem)=>{
        //   subitem.sections.forEach((sections)=>{
        //     sections.students.forEach((singleStudent)=>{
        //      const dueFeeSum= getFeeSum(singleStudent.fee_challans)
        //      console.log(dueFeeSum)
        //      return sectionSum+=item.amount
        //     })
        //   })
        // })
        // })
      })
      .catch((err) => {
        console.log(err);
        setprintLoading(false);
      });
  };

  //getting subtotal
  const gettingSubTotal = (miniitems) => {
    let sum = 0;
    miniitems.students.forEach((student) => {
      const challansSum = getFeeSum(feeChalanChecker(student.fee_challans));
      return (sum += challansSum);
    });
    // console.log("sessionsSum",sum)
    return sum;
  };

  //getting subtotal
  const gettingSubTotalPrint = (miniitems) => {
    let sum = 0;
    miniitems.students.forEach((student) => {
      return (sum += student.total_fee);
    });
    // console.log("sessionsSum",sum)
    return sum;
  };

  const datePicker = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setStartDate(dateString[0]);
    setendDate(dateString[1]);
  };

  let num = 0;
  let num2 = 0;
  const todayDate = () => {
    var date = new Date();
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var day = date.getDate();
    var month = monthNames[date.getMonth()];
    var year = date.getFullYear();
    var formattedDate = day + " " + month + " " + year;

    return formattedDate;
  };
  const currentTime = () => {
    var currentDate = new Date();

    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();

    var formattedHours = hours < 10 ? "0" + hours : hours;
    var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    var formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
    var formattedTime =
      formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;

    return formattedTime;
  };

  const STUD_STATUS = [
    {
      id: 1,
      title: "Registered Only",
    },
    {
      id: 2,
      title: "Admission Only",
    },
    {
      id: 3,
      title: "Applied For Admission",
    },
    {
      id: 4,
      title: "Struck Off",
    },
    {
      id: 5,
      title: "Passout",
    },
    {
      id: 6,
      title: "Leaving",
    },
    {
      id: 7,
      title: "Migrate",
    },
  ];
  const feeChalanChecker = (arr) => {
    let res = arr.filter((item) => {
      if (item.fee_challan_details.length > 0) {
        return item;
      }
    });
    return res;
  };

  const monthsNameString = (arr) => {
    let monthArray = [];
    arr.map((item) => {
      monthArray.push(getMonthName(item.fee_challan_details[0]?.fee_month));
    });
    return monthArray.join(",");
  };
  const sendMonthsToApi = (arr) => {
    let monthArray = [];
    arr.map((item) => {
      monthArray.push(item.fee_challan_details[0]?.fee_month);
    });
    return monthArray;
  };

  const sendMesagePreview = () => {
    let makePayload = [];
    const selectedFeeses = studentsData.filter((item) =>
      studentIds.includes(item.id)
    );
    selectedFeeses.map((fc) => {
      makePayload.push({
        studentId: fc.id,
        months: sendMonthsToApi(feeChalanChecker(fc.fee_challans)),
      });
    });
    console.log(makePayload);
    setMessageArray(makePayload);
    setMessageModel(true);
  };
  const mainTableGrandTotal = (array) => {
    if (array.length) {
      let sum = 0;
      array.forEach((student) => {
        const challansSum = getFeeSum(feeChalanChecker(student.fee_challans));
        return (sum += challansSum);
      });
      // console.log("sessionsSum",sum)
      return sum;
    }
  };
  const sendNotification = () => {
    const notificationArray = [];
    const selectedFeeses = studentsData.filter((item) =>
      studentIds.includes(item.id)
    );
    selectedFeeses.map((st) => {
      notificationArray.push({
        id: st.notification_id,
        title: "Due Fee Alert",
        description: `We have been checking accounts and found that there is some due fee pending (Rs. ${getFeeSum(
          feeChalanChecker(st.fee_challans)
        )}) of your child ${st.name}(${
          st.admission_id
        }) for the month of ${monthsNameString(
          feeChalanChecker(st.fee_challans)
        )}. Please pay the fees at the earliest`,
      });
    });
    if (notificationArray.length > 0) {
      setMyNotificationArray(notificationArray);
      setNotificationConfirmModal(true);
    } else {
      toast.error("Please Select At Least One Student");
    }
  };
  const sendMessageToStudents = () => {
    sendNotificationsInBatches2(myNotificationArray);
    toast.success("Notification Successfully Sent");
    setNotificationConfirmModal(false);
  };

  const renderTableRow = (student, index) => {
    const totalFeeChallanDetails = student.fee_challans.reduce(
      (acc, challan) => {
        return acc + challan.fee_challan_details.length;
      },
      0
    );
    if (totalFeeChallanDetails > 3) {
      return (
        <>
          <tr id="tbl">
            <td style={{ textAlign: "start" }}>
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-single">
                <input
                  type="checkbox"
                  key={student.id}
                  onClick={(e) => {
                    checkBoxClick(e, student.id);
                  }}
                  checked={student.checked}
                  value={allcheckboxes[index]}
                />
                <span />
              </label>

              {/* <input
                              key={student.id}
                              onClick={(e) => {
                                checkBoxClick(e, student.id);
                              }}
                              checked={student.checked}
                              value={allcheckboxes[index]}
                              type="checkbox"
                            /> */}
            </td>
            <td className="text_align_start" style={{ color: "red" }}>
              {student.admission_id}
            </td>
            <td className="text_align_start" style={{ color: "red" }}>
              {student.name}
            </td>
            <td className="text_align_start" style={{ color: "red" }}>
              {student.father_name}
            </td>
            <td className="text-center" style={{ color: "red" }}>
              {student.mobile_no}
            </td>

            <td className="text-center" style={{ color: "red" }}>
              {student?.student_class?.name}
            </td>
            <td className="text-center" style={{ color: "red" }}>
              {student?.global_section?.name}
            </td>

            <td>
              {feeChalanChecker(student.fee_challans).map((item, i) => (
                <span key={i} className="text-center" style={{ color: "red" }}>
                  {i != 0 && ", "} {/* Conditionally add comma */}
                  {item.fee_challan_details.map((subitem, subIndex) => (
                    <span key={subIndex}>
                      {getMonthName(subitem?.fee_month)}
                      {subIndex != item.fee_challan_details.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              ))}
            </td>

            <td style={{ color: "red" }} className="text-center">
              {feeChalanChecker(student?.fee_challans).length}
            </td>

            <td style={{ color: "red" }} className="text-center">
              {getFeeSum(feeChalanChecker(student.fee_challans))}
            </td>
          </tr>
        </>
      );
    } else if (totalFeeChallanDetails == 3) {
      return (
        <>
          <tr id="tbl">
            <td style={{ textAlign: "start" }}>
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-single">
                <input
                  type="checkbox"
                  key={student.id}
                  onClick={(e) => {
                    checkBoxClick(e, student.id);
                  }}
                  checked={student.checked}
                  value={allcheckboxes[index]}
                />
                <span />
              </label>
            </td>
            <td className="text_align_start" style={{ color: "#fd12b3" }}>
              {student.admission_id}
            </td>
            <td className="text_align_start" style={{ color: "#fd12b3" }}>
              {student.name}
            </td>
            <td className="text_align_start" style={{ color: "#fd12b3" }}>
              {student.father_name}
            </td>
            <td className="text-center" style={{ color: "#fd12b3" }}>
              {student.mobile_no}
            </td>

            <td className="text-center" style={{ color: "#fd12b3" }}>
              {student?.student_class?.name}
            </td>
            <td className="text-center" style={{ color: "#fd12b3" }}>
              {student?.global_section?.name}
            </td>

            <td>
              {feeChalanChecker(student.fee_challans).map((item, i) => (
                <span
                  key={i}
                  className="text-center"
                  style={{ color: "#fd12b3" }}
                >
                  {i != 0 && ", "} {/* Conditionally add comma */}
                  {item.fee_challan_details.map((subitem, subIndex) => (
                    <span key={subIndex}>
                      {getMonthName(subitem?.fee_month)}
                      {subIndex != item.fee_challan_details.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              ))}
            </td>

            <td style={{ color: "#fd12b3" }} className="text-center">
              {feeChalanChecker(student?.fee_challans).length}
            </td>

            <td style={{ color: "#fd12b3" }} className="text-center">
              {getFeeSum(feeChalanChecker(student.fee_challans))}
            </td>
          </tr>
        </>
      );
    } else if (totalFeeChallanDetails == 2) {
      return (
        <>
          <tr id="tbl">
            <td style={{ textAlign: "start" }}>
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-single">
                <input
                  type="checkbox"
                  key={student.id}
                  onClick={(e) => {
                    checkBoxClick(e, student.id);
                  }}
                  checked={student.checked}
                  value={allcheckboxes[index]}
                />
                <span />
              </label>

              {/* <input
                              key={student.id}
                              onClick={(e) => {
                                checkBoxClick(e, student.id);
                              }}
                              checked={student.checked}
                              value={allcheckboxes[index]}
                              type="checkbox"
                            /> */}
            </td>
            <td className="text_align_start" style={{ color: "green" }}>
              {student.admission_id}
            </td>
            <td className="text_align_start" style={{ color: "green" }}>
              {student.name}
            </td>
            <td className="text_align_start" style={{ color: "green" }}>
              {student.father_name}
            </td>
            <td className="text-center" style={{ color: "green" }}>
              {student.mobile_no}
            </td>

            <td className="text-center" style={{ color: "green" }}>
              {student?.student_class?.name}
            </td>
            <td className="text-center" style={{ color: "green" }}>
              {student?.global_section?.name}
            </td>

            <td>
              {feeChalanChecker(student.fee_challans).map((item, i) => (
                <span
                  key={i}
                  className="text-center"
                  style={{ color: "green" }}
                >
                  {i != 0 && ", "} {/* Conditionally add comma */}
                  {item.fee_challan_details.map((subitem, subIndex) => (
                    <span key={subIndex}>
                      {getMonthName(subitem?.fee_month)}
                      {subIndex != item.fee_challan_details.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              ))}
            </td>

            <td style={{ color: "green" }} className="text-center">
              {feeChalanChecker(student?.fee_challans).length}
            </td>

            <td style={{ color: "green" }} className="text-center">
              {getFeeSum(feeChalanChecker(student.fee_challans))}
            </td>
          </tr>
        </>
      );
    } else {
      return (
        <>
          <tr id="tbl">
            <td style={{ textAlign: "start" }}>
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-single">
                <input
                  type="checkbox"
                  key={student.id}
                  onClick={(e) => {
                    checkBoxClick(e, student.id);
                  }}
                  checked={student.checked}
                  value={allcheckboxes[index]}
                />
                <span />
              </label>

              {/* <input
                              key={student.id}
                              onClick={(e) => {
                                checkBoxClick(e, student.id);
                              }}
                              checked={student.checked}
                              value={allcheckboxes[index]}
                              type="checkbox"
                            /> */}
            </td>
            <td className="text_align_start" style={{ color: "black" }}>
              {student.admission_id}
            </td>
            <td className="text_align_start" style={{ color: "black" }}>
              {student.name}
            </td>
            <td className="text_align_start" style={{ color: "black" }}>
              {student.father_name}
            </td>
            <td className="text-center" style={{ color: "black" }}>
              {student.mobile_no}
            </td>

            <td className="text-center" style={{ color: "black" }}>
              {student?.student_class?.name}
            </td>
            <td className="text-center" style={{ color: "black" }}>
              {student?.global_section?.name}
            </td>

            <td>
              {feeChalanChecker(student.fee_challans).map((item, i) => (
                <span
                  key={i}
                  className="text-center"
                  style={{ color: "black" }}
                >
                  {i != 0 && ", "} {/* Conditionally add comma */}
                  {item.fee_challan_details.map((subitem, subIndex) => (
                    <span key={subIndex}>
                      {getMonthName(subitem?.fee_month)}
                      {subIndex != item.fee_challan_details.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              ))}
            </td>

            <td style={{ color: "black" }} className="text-center">
              {feeChalanChecker(student?.fee_challans).length}
            </td>

            <td style={{ color: "black" }} className="text-center">
              {getFeeSum(feeChalanChecker(student.fee_challans))}
            </td>
          </tr>
        </>
      );
    }
  };

  return (
    <>
      {contextHolder}
      <div>
        <Toaster />
        <Paper
          style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-3">
                  <input
                    disabled={
                      !PremissionChecker(
                        user,
                        "system_reports.session_wise_due_fee"
                      )
                    }
                    checked={selected == "session"}
                    onChange={(e) => onRadioButtonClick(e)}
                    defaultChecked
                    type="radio"
                    id="Session"
                    name="contact"
                    value="session"
                  />
                  <label for="Session">Session Wise Due Fee</label>
                </div>

                <div className="col-sm-3">
                  <input
                    checked={selected == "campus"}
                    onChange={(e) => onRadioButtonClick(e)}
                    type="radio"
                    id="Campus"
                    name="contact"
                    value="campus"
                  />
                  <label for="Campus">Campus Wise Due Fee</label>
                </div>

                <div className="col-sm-3">
                  <input
                    checked={selected == "class"}
                    onChange={(e) => onRadioButtonClick(e)}
                    type="radio"
                    id="Class"
                    name="contact"
                    value="class"
                  />
                  <label for="Class">Class Wise Due Fee</label>
                </div>
                <div className="col-sm-3">
                  <input
                    checked={selected == "section"}
                    onChange={(e) => onRadioButtonClick(e)}
                    type="radio"
                    id="Section"
                    name="contact"
                    value="section"
                  />
                  <label for="Section">Section Wise Due Fee</label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <input
                    checked={gender == "male"}
                    onChange={(e) => ongenderButtonClick(e)}
                    type="radio"
                    id="Male"
                    name="gender"
                    value="male"
                  />
                  <label for="Male">Male</label>
                </div>

                <div className="col-sm-3">
                  <input
                    type="radio"
                    checked={gender == "female"}
                    onChange={(e) => ongenderButtonClick(e)}
                    id="Female"
                    name="gender"
                    value="female"
                  />
                  <label for="Female">Female</label>
                </div>

                <div className="col-sm-3">
                  <input
                    checked={gender == "both"}
                    onChange={(e) => ongenderButtonClick(e)}
                    type="radio"
                    id="Both"
                    name="gender"
                    value="both"
                  />
                  <label for="Both">Both</label>
                </div>
              </div>
              <br />
              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                <div className="col-sm-3">
                  <label>
                    <b>Select Session</b>
                  </label>
                  <div className="d-flex flex-column ">
                    <select
                      style={{ height: 40 }}
                      className="form-select"
                      name="year_id"
                      value={yearId}
                      aria-label="Default select example"
                      onChange={(e) => setYearId(e.target.value)}
                    >
                      {sessionData &&
                        sessionData.map((item) => (
                          <option value={item.id}>{item.year}</option>
                        ))}
                    </select>
                  </div>
                </div>

                {session ? null : (
                  <div className="col-sm-3">
                    <label>
                      <b>Select Campus</b>
                    </label>
                    <div className="d-flex flex-column">
                      <select
                        style={{ height: 40 }}
                        className="form-select"
                        name="year_id"
                        aria-label="Default select example"
                        onChange={(e) => onChampusChange(e.target.value)}
                        value={campusId}
                      >
                        <option value=""> Select Campus</option>
                        {campusData &&
                          campusData.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-md-3">
                  <label>
                    <b>Select Education Type</b>
                  </label>
                  <select
                    className="form-select"
                    name="campus_id"
                    value={educationType}
                    style={{ height: 40 }}
                    aria-label="Default select example"
                    onChange={(e) => {
                      setEducationType(e.target.value);
                      getSectionsListOnTypeChange(e.target.value);
                    }}
                  >
                    <option value={0}>All Education Types</option>
                    <option value={1}>Education</option>
                    <option value={2}>Hifz</option>
                  </select>
                </div>
                {/* Select Exam */}

                {session || campus ? null : (
                  <>
                    <div className="col-sm-3">
                      <label>
                        <b>Select Class</b>
                      </label>
                      <div className="d-flex flex-column">
                        <select
                          style={{ height: 40 }}
                          className="form-select"
                          name="campus_id"
                          aria-label="Default select example"
                          onChange={(e) => onClassChange(e.target.value)}
                          value={classId}
                        >
                          <option value=""> Select Class</option>
                          {classesData &&
                            classesData.map((item) => (
                              <>
                                <option value={item.id}>{item.name}</option>
                              </>
                            ))}
                        </select>
                      </div>
                    </div>
                  </>
                )}

                {session || classes || campus ? null : (
                  <div className="col-sm-3 mt-4">
                    <label>
                      <b>Select Section</b>
                    </label>
                    <div className="d-flex flex-column">
                      <select
                        style={{ height: 40 }}
                        className="form-select"
                        name="campus_id"
                        aria-label="Default select example"
                        onChange={(e) => setSectionId(e.target.value)}
                        value={sectionId}
                      >
                        <option value=""> Section</option>
                        {sectionData &&
                          sectionData.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <br />
              <div className="mx-4">
                <div className="d-flex">
                  <div className="w-25">
                    <labe>
                      <b>Select Start and End Date</b>
                    </labe>
                    <RangePicker
                      style={{ height: "40px" }}
                      picker="month"
                      onChange={datePicker}
                    />
                  </div>
                  <div className="col-md-4 mt-9">
                    <input
                      type="checkbox"
                      class="form-checkbox"
                      id="over_due_date"
                      checked={overDueDate ? overDueDate == 1 : false}
                      onChange={(e) => setOverDueDate(e.target.checked ? 1 : 0)}
                    ></input>
                    <label for="over_due_date" class="form-check-label">
                      Due Date Passed
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <div className="d-flex">
                <div className="d-flex flex-column col-sm-3">
                  <label>
                    <b>Select Fee Type</b>
                  </label>
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    name="year_id"
                    value={feeType}
                    aria-label="Default select example"
                    onChange={(e) => setFeeType(e.target.value)}
                  >
                    <option selected value="">
                      All Type Of Fees
                    </option>
                    {fee_types.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="d-flex flex-column col-sm-3">
                  <label>
                    <b>Select Status</b>
                  </label>
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    name="year_id"
                    value={studentStatus}
                    aria-label="Default select example"
                    onChange={(e) => setStudentStatus(e.target.value)}
                  >
                    <option selected value="">
                      Select Status
                    </option>
                    {STUD_STATUS.map((item) => (
                      <option value={item.id}>{item.title}</option>
                    ))}
                  </select>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-8">
                  <button className="btn btn-primary" type="submit">
                    Show
                  </button>
                </div>
              </div>
            </form>
            <div className="mt-5">
              {!!studentIds && studentIds.length > 0 ? (
                <div>
                  <button className="btn btn-primary" onClick={handlePrint}>
                    Preview
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-3"
                    onClick={sendMesagePreview}
                  >
                    Send Message
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-3"
                    onClick={sendNotification}
                  >
                    Send Notification
                  </button>
                </div>
              ) : null}
            </div>
            {studentsData.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "end" }}>
                <h3>Grand Total: {mainTableGrandTotal(studentsData)}</h3>
              </div>
            ) : (
              ""
            )}
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>
                        <input type="checkbox" style={{ display: "none" }} />
                        <label className="checkbox checkbox-single">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              allcheckboxChecked();
                            }}
                            checked={checkallcheckbox}
                          />
                          <span />
                        </label>
                      </th>
                      <th>Adm-Id</th>
                      <th>Student Name</th>
                      <th>Father Name</th>
                      <th className="text-center">Mobile No</th>
                      {/* <th>Campus</th> */}
                      <th className="text-center">Class</th>
                      <th className="text-center">Section</th>
                      <th className="text-center">Months</th>
                      {/* <th>Fee</th> */}
                      <th className="text-center">Total Month Due</th>
                      <th className="text-center">Total Fee</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!!studentsData &&
                      studentsData.map((student, index) => (
                        <>{renderTableRow(student, index)}</>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          <div
            ref={componentRef}
            id="printSection"
            style={{ visibility: "hidden" }}
          >
            <div className="p-3" style={{ display: "flex" }}>
              <img
                // className='col-md-2'
                style={{ height: 100, width: 110 }}
                src={icon}
              />

              <div className="col-md-8" style={{ paddingTop: "10px" }}>
                <h1>{unitName}</h1>
                <p className="paybodyFont nextmoreFont">
                  <b>
                    Student Due Fee Report As On {todayDate()} {currentTime()}
                  </b>
                </p>
              </div>

              {/* <div>

                    </div> */}
            </div>
            <table style={{ width: "100%" }}>
              <thead style={{ marginTop: "20px" }}>
                <tr id="tbl" className="duefeeetd">
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <b>Sr.no</b>
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <b>Adm-Id</b>
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "start",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <b>Student Name</b>
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "start",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <b>Father Name</b>
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <b>Mobile No</b>
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <b>Months</b>
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <b>Total Months</b>
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <b>Total Fee</b>
                  </td>
                </tr>
              </thead>
              {printData &&
                printData.map((item) => (
                  <>
                    <tr>
                      <td colSpan={7}>
                        <div
                          className="d-flex"
                          style={{ marginTop: "5px", marginLeft: "10px" }}
                        >
                          <div className="">
                            <h3 className="text-primary">{item.campus_name}</h3>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {item.classes?.map((subitem) =>
                      subitem.sections.map((miniitems) => (
                        <>
                          {/* <tr style={{marginTop:"20px"}}>
                          <td colSpan={7}>
                          <div className="d-flex">
                           <div
                                className="d-flex"
                                style={{
                                  marginLeft: "20px",
                                }}
                              >
                                <h4>Class:-</h4>
                                <h4>{subitem?.class_name}</h4>
                              </div>
                              <div
                                className="d-flex"
                                style={{ paddingLeft: "40px" }}
                              >
                                <h4>Section:-</h4>
                                <h4>{miniitems.section_name}</h4>
                              </div>
                           </div>
                          </td>
                         </tr> */}

                          <tbody>
                            <div
                              className="hadhybhai"
                              style={{
                                height: "20px",
                                display: "flex",
                                marginTop: "10px",
                                position: "relative",
                              }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  // fontWeight: "bold",
                                  fontSize: "18px",
                                  position: "absolute",
                                  left: "30px",
                                }}
                                className="text_align_start duefeesec"
                              >
                                {" "}
                                <b>Class:- {subitem?.class_name}</b>
                              </p>

                              <p
                                style={{
                                  color: "black",
                                  // fontWeight: "bold",
                                  fontSize: "18px",
                                  position: "absolute",
                                  left: "350px",
                                }}
                                className="text_align_start duefeesec"
                              >
                                <b>Section: {miniitems.section_name}</b>
                              </p>
                            </div>
                            {miniitems.students.map((student, i) => (
                              <tr id="tbl" className="duefeeetd">
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {(num2 += 1)}
                                  {/* <b>{num+=1}</b>  */}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {student.admission_id}
                                  {/* <b>{student.admission_id}</b> */}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "start",
                                  }}
                                >
                                  {student.student_name}
                                  {/* <b>{student.name}</b> */}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "start",
                                  }}
                                >
                                  {student.father_name}
                                  {/* <b>{student.name}</b> */}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {student.mobile_no}
                                  {/* <b>{student.mobile_no}</b> */}
                                </td>

                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {/* {getMonthName(student?.fee_months)} */}
                                  {student?.fee_months.map((item, i) => (
                                    <>
                                      <span
                                        style={{
                                          color: "black",
                                          textAlign: "center",
                                        }}
                                      >
                                        <b>{i == "0" ? "" : ","} </b>
                                        {getMonthName(item)}
                                      </span>
                                    </>
                                  ))}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {student.total_months}
                                  {/* {
                                    feeChalanChecker(student?.fee_challans)
                                      .length
                                  } */}
                                  {/* <b>{student?.fee_challans.length}</b> */}
                                </td>

                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {student.total_fee}
                                  {/* {getFeeSum(
                                    feeChalanChecker(student.fee_challans)
                                  )} */}
                                  {/* <b>{getFeeSum(student.fee_challans)}</b> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tr>
                            <td colSpan={8} style={{ textAlign: "end" }}>
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  paddingRight: "35px",
                                  fontSize: "2.5rem",
                                }}
                              >
                                <h5 className="text-primary">Sub Total: </h5>
                                <h5>{miniitems?.section_amount}</h5>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8}>
                              <div
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  border: "1px solid black",
                                }}
                              ></div>
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                    <tr>
                      <td colSpan={8} style={{ textAlign: "end" }}>
                        <div
                          className="col-md-12 d-flex "
                          style={{
                            justifyContent: "flex-end",
                            paddingRight: "35px",
                            marginTop: "5px",
                            fontSize: "3rem",
                          }}
                        >
                          <h4 className="text-primary">
                            {item.campus_name} Total Due Fee:{" "}
                          </h4>
                          <h4 style={{ borderBottom: "1px solid black" }}>
                            {item.amount}
                          </h4>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              <tr>
                <td colSpan={8} style={{ textAlign: "end" }}>
                  <div>
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "35px",
                        marginTop: "5px",
                      }}
                    >
                      <h1
                        style={{ paddingTop: "5px" }}
                        className="text-primary"
                      >
                        Grand Total:- {grandTotal}
                      </h1>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </Paper>

        {/* /////Model start */}
        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          size="lg"
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Students</Modal.Title>
          </Modal.Header>
          {printLoading ? (
            <LinearProgress
              style={{ width: "100%", backgroundColor: "#121291" }}
            />
          ) : (
            <>
              <Modal.Body>
                {printData &&
                  printData.map((item) => (
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="text-primary">{item.campus_name}</h4>
                        </div>
                        {/* <div className="col-md-6 d-flex">
                          <h4 className="text-primary">Total Amount:-</h4>
                          <h4>{item.amount}</h4>
                        </div> */}
                      </div>
                      {item.classes?.map((subitem) => (
                        <>
                          <div>
                            {subitem.sections.map((miniitems) => (
                              <>
                                <div className="">
                                  <div className="col-md-6 d-flex">
                                    <h4>Class:- </h4>
                                    <h4>{subitem?.class_name}</h4>
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{
                                      paddingLeft: "30px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <h4>Section:- </h4>
                                    <h4>{miniitems.section_name}</h4>
                                  </div>

                                  <Table responsive>
                                    <thead>
                                      <tr id="tbl">
                                        <th className="text-center">SNo.</th>
                                        <th className="text-center">AdmId.</th>
                                        <th>Name</th>
                                        <th>Father Name</th>
                                        <th className="text-center">
                                          Mobile No
                                        </th>
                                        <th className="text-center">Months</th>
                                        <th className="text-center">
                                          Total Months
                                        </th>
                                        <th className="text-center">
                                          Total Fee
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {miniitems.students.map((student, i) => (
                                        <tr id="tbl">
                                          <td
                                            style={{ color: "black" }}
                                            className="text-center"
                                          >
                                            {i + 1}
                                          </td>
                                          <td
                                            style={{ color: "black" }}
                                            className="text-center"
                                          >
                                            {student.admission_id}
                                          </td>
                                          <td
                                            style={{ color: "black" }}
                                            className="text_align_start"
                                          >
                                            {student.student_name}
                                          </td>
                                          <td
                                            style={{ color: "black" }}
                                            className="text_align_start"
                                          >
                                            {student.father_name}
                                          </td>
                                          <td
                                            style={{ color: "black" }}
                                            className="text-center"
                                          >
                                            {student.mobile_no}
                                          </td>

                                          <td
                                            className="text-center"
                                            style={{ color: "black" }}
                                          >
                                            {student?.fee_months.map(
                                              (item, i) => (
                                                <>
                                                  <span
                                                    style={{
                                                      color: "black",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <b>
                                                      {i == "0" ? "" : ","}{" "}
                                                    </b>
                                                    {getMonthName(item)}
                                                  </span>
                                                </>
                                              )
                                            )}
                                          </td>
                                          <td
                                            style={{ color: "black" }}
                                            className="text-center"
                                          >
                                            {student.total_months}
                                          </td>

                                          <td
                                            style={{ color: "black" }}
                                            className="text-center"
                                          >
                                            {student.total_fee}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                  <div
                                    className="col-md-12 d-flex "
                                    style={{
                                      justifyContent: "flex-end",
                                      paddingRight: "19px",
                                    }}
                                  >
                                    <h6 className="text-primary">
                                      Sub Total:{" "}
                                    </h6>
                                    <h6>{miniitems?.section_amount}</h6>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      ))}
                      <div
                        className="col-md-12 d-flex "
                        style={{ justifyContent: "flex-end", marginTop: "5px" }}
                      >
                        <h6 className="text-primary">
                          {item.campus_name} Total Due Fee: {item.amount}
                        </h6>
                      </div>
                    </>
                  ))}
                <div>
                  <div
                    className="col-md-12 d-flex mt-4"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <h4 style={{ paddingTop: "5px" }} className="text-primary">
                      Grand Total:- {grandTotal}
                    </h4>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>

                <DueFeeExport printData={printData} grandTotal={grandTotal} />
                <ReactToPrint
                  pageStyle={pageStyle}
                  documentTitle={`Student Due Fee Report`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      onClick={() => console.log("dfsdfsdfd")}
                      disabled={printloading ? true : false}
                      className="btn btn-success button_blue button_blue"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              </Modal.Footer>
            </>
          )}
        </Modal>
      </div>
      <AntdModal
        title="Send Message"
        centered
        open={messageModel}
        onOk={sendMessage}
        okText="Send Message"
        okButtonProps={{ disabled: buttonDisabled }}
        onCancel={() => setMessageModel(false)}
      >
        <strong className="text-center">
          Are you sure you want to send the message
        </strong>
      </AntdModal>
      <AntdModal
        title="Send Notification"
        centered
        open={notificationConfirmModal}
        onOk={sendMessageToStudents}
        okText="Send Notification"
        onCancel={() => setNotificationConfirmModal(false)}
      >
        <strong className="text-center">
          Are you sure you want to send the notification
        </strong>
      </AntdModal>
    </>
  );
};

export default StudentDueFee;
