import React, { useEffect, useState } from "react";
////mui imports
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import "./style.css";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import MySelect from "../../CustomChallan/MySelect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { useSelector } from "react-redux";
/////tabs componenets/////////////////
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

const UpdateExams = () => {
  ///tabs states
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      height: 45,
      maxWidth: "100%",
      borderRadius: 5,
      borderColor: "#CBCBCB",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //   const color = chroma(data.color);
      return {
        ...styles,
        opacity: 10,
        backgroundColor: isDisabled ? "red" : "white",
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const authtoken = localStorage.getItem("userToken");
  //// for data for top 3 selects
  const [currentsession, setcurrentsession] = useState("");
  const [allsessions, setallsessions] = useState([]);
  const [allexamslist, setallexamslist] = useState([]);
  const [selectedcampus, setselectedcampus] = useState("");
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedExamType, setselectedExamType] = useState("");
  const [students, setstudents] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [loading, setloading] = useState(false);
  const [updateButton, setUpdateButton] = useState(false);
  const [studentid, setStudentid] = useState("");
  const [allclasses, setallclasses] = useState([]);
  const [optionSelected, setoptionSelected] = useState([]);
  const [selectedclasses, setselectedclasses] = useState([]);
  const [selectedclassesName, setselectedclassesName] = useState([]);
  const [educationType, setEducationType] = useState(1);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [removingClasses, setRemovingClasses] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false);
  const [removeStudentModal, setRemoveStudentModal] = useState(false);
  useEffect(() => {
    getAllSessions();
    // getAllCampus();
  }, []);
  const alert = useAlert();

  ///geting alll sessions
  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // get all campus////////
  const getAllCampus = async () => {
    console.log("campus geeting");
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  //get all exams

  const getAllExamsList = (id) => {
    const data = {
      year_id: currentsession,
      campus_id: id,
    };

    fetch(`${baseUrl}/exam_for_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("get exam", data);

        setallexamslist([...data.payload]);

        // setallclasses(data.payload[0]?.student_classes)

        // getassignedExamsList(data.payload)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////session k change per
  const onChangeSession = (id) => {
    setcurrentsession(id);
  };

  /////campus k change per
  const onCampusChange = async (id) => {
    setselectedcampus(id);
    getAllExamsList(id);
    getClasses(id);
    setoptionSelected([]);
  };

  // get all classes/////////

  const getClasses = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.payload) {
          setallclasses(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///for student search
  const searchbyRegId = (e) => {
    e.preventDefault();
    setloading(true);
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_name_or_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setloading(false);

          console.log("student", data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("Match not found");
            }
            setstudents(data.payload);
            if (data.payload.length === 1) {
              setUpdateButton(true);
              data.payload.map((item) => {
                setStudentid(item.id);
              });
            } else {
              setUpdateButton(false);
            }
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          setloading(false);

          console.log("err is re", err);
        });
    }
  };
  ////on Student update exam
  const updateStudentExam = () => {
    if (studentid == "") {
      alert.error("Please enter Admission Id");
    } else {
      const data = {
        exam_id: selectedExamType,
        student_id: studentid,
      };

      fetch(`${baseUrl}/add_exam_student`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("updateed student response", data);
          if (data.metadata.success) {
            alert.success(data.metadata.message);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  ////on class update exam
  const updateClassExam = () => {
    if (selectedclasses.length == 0) {
      alert.error("Please select at least 1 class");
    } else {
      setUpdateLoading(true);
      setConfirmModal(false);
      const data = {
        exam_id: selectedExamType,
        student_class_ids: selectedclasses,
        education_type: educationType,
      };

      fetch(`${baseUrl}/update_exam_classes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setUpdateLoading(false);
          console.log("updateed student response", data);
          if (data.metadata.success) {
            alert.success(data.metadata.message);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          setUpdateLoading(false);
          console.log("err is re", err);
        });
    }
  };

  const removeClassExam = () => {
    if (selectedclasses.length == 0) {
      alert.error("Please select at least 1 class");
    } else {
      setRemovingClasses(true);

      const data = {
        exam_id: selectedExamType,
        student_class_ids: selectedclasses,
        education_type: educationType,
      };

      fetch(`${baseUrl}/remove-exam-classes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setUpdateLoading(false);
          console.log("removed classes response", data);
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            setRemoveConfirmModal(false);
            setRemovingClasses(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };
  const removeStudentExam = () => {
    if (studentid.length == 0) {
      alert.error("Please select at least 1 student");
    } else {
      // setUpdateLoading(true);

      const data = {
        exam_id: selectedExamType,
        student_id: studentid,
      };

      fetch(`${baseUrl}/remove-exam-student`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setUpdateLoading(false);
          console.log("updated student response", data);
          setRemoveStudentModal(false);

          if (data.metadata.success) {
            alert.success(data.metadata.message);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          setUpdateLoading(false);
          console.log("err is re", err);
        });
    }
  };

  //multiselect configration
  const Options = [];
  allclasses.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleChangeClasses = (selected) => {
    console.log(selected);
    if (optionSelected.length == 30 && selected.length == 30) {
      setoptionSelected([]);
      return;
    }
    setoptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setselectedclasses([...valuearray]);
    // const selectedValues = selected.map((option) => option.value);
    // setselectedclassesName(selectedValues);
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "10px" }}
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab color="#121291" label="Students" {...a11yProps(0)} />
            <Tab color="#121291" label="Classes" {...a11yProps(1)} />
          </Tabs>

          {/* ////top 3 selects.......////////////// */}
          <div className="row mt-5">
            <div className="col-md-4">
              <label>
                <b>Select Session</b>
              </label>
              <select
                onChange={(e) => onChangeSession(e.target.value)}
                class="form-select"
                style={{ height: 40 }}
                value={currentsession}
                aria-label="Default select example"
              >
                <option value="">Select Session</option>
                {allsessions.map((session) => (
                  <option
                    // selected={session.active_academic_year == "1" ? true : false}
                    value={session.id}
                  >
                    {session.year}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-4">
              <label>
                <b>Select Campus</b>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                value={selectedcampus}
                onChange={(e) => onCampusChange(e.target.value)}
                aria-label="Default select example"
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campuses.map((campus, index) => (
                  <option key={index} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4">
              <label>
                <b>Select Exam</b>
              </label>
              <select
                onChange={(e) => {
                  setselectedExamType(e.target.value);

                  let finded = allexamslist.find(
                    (item) => item.id == e.target.value
                  );
                  // setallclasses(finded.student_classes)
                  // console.log("found object==>",finded)
                  // console.log("found student classes",finded.student_classes)
                }}
                class="form-select"
                style={{ height: 40 }}
                // value={currentsession}
                aria-label="Default select example"
              >
                <option value="">Select Exam</option>
                {allexamslist.map((exam) => (
                  <option
                    // selected={exam.active_academic_year == "1" ? true : false}
                    value={exam.id}
                  >
                    {exam?.exam_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {/* ////student part tab data//////// */}
              <form onSubmit={searchbyRegId}>
                <div className="row">
                  <div className="col-md-4">
                    <input
                      type="number"
                      onChange={(e) => setsearchtext(e.target.value)}
                      className="form-control"
                      placeholder="Enter Admission ID"
                      value={searchtext}
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                    {updateButton ? (
                      <>
                        <button
                          onClick={updateStudentExam}
                          style={{ marginLeft: "10px" }}
                          type="button"
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                        <button
                          onClick={() => setRemoveStudentModal(true)}
                          style={{ marginLeft: "10px" }}
                          type="button"
                          className="btn btn-danger"
                        >
                          Remove
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </form>

              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Spinner
                    class="text-center"
                    variant="info"
                    animation="border"
                  />
                </div>
              ) : (
                <Table responsive className="mt-5">
                  <thead>
                    <tr id="tbl">
                      <th>Student Name</th>
                      <th>Father Name</th>
                      <th>Admission ID</th>
                      <th>Campus</th>
                      <th>Class</th>
                    </tr>
                  </thead>

                  <tbody>
                    {students &&
                      students.map((student, index) => {
                        return (
                          // key={index}
                          <tr key={index}>
                            {/* <td className="text_align_start ">{student.id}</td> */}

                            <td
                              title={
                                student.name.length > 15 ? student.name : null
                              }
                              style={{ textAlign: "start", cursor: "pointer" }}
                            >
                              {student.name}
                              {/* {student.name.length > 15
                      ? student.name.substring(0, 12) + "..."
                      : student.name} */}
                            </td>
                            <td
                              title={
                                student.father_name.length > 15
                                  ? student.father_name
                                  : null
                              }
                              style={{ textAlign: "start", cursor: "pointer" }}
                            >
                              {student.father_name.length > 15
                                ? student.father_name.substring(0, 12) + "..."
                                : student.father_name}
                            </td>

                            <td className="text_align_start ">
                              {student.admission_id}
                            </td>
                            <td className="text_align_start ">
                              {student.campus?.name}
                            </td>
                            <td className="text_align_start ">
                              {student.student_class?.name}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <div className="row">
                <div className="col-md-3">
                  <label>
                    <b>Select Education Type</b>
                  </label>
                  <select
                    className="form-select"
                    name="campus_id"
                    value={educationType}
                    style={{ height: 40 }}
                    aria-label="Default select example"
                    onChange={(e) => setEducationType(e.target.value)}
                  >
                    <option value=""> Select Education Type</option>
                    <option selected value={1}>
                      Education
                    </option>
                    <option value={2}>Hifz</option>
                  </select>
                </div>
                <div className=" col-md-4">
                  <label>
                    <b>
                      Select Classes <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <MySelect
                    options={Options}
                    isMulti
                    name="selectedOptions"
                    closeMenuOnSelect={true}
                    styles={colourStyles}
                    hideSelectedOptions={false}
                    menuPortalTarget={document.body}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleChangeClasses}
                    allowSelectAll={true}
                    value={optionSelected}
                  />
                </div>
                <div className="col-md-4 mt-9">
                  {updateLoading ? (
                    <div className="mt-2">
                      <Spinner
                        class="text-center"
                        variant="info"
                        animation="border"
                      />
                    </div>
                  ) : (
                    selectedclasses.length > 0 && (
                      <>
                        <button
                          onClick={() => setConfirmModal(true)}
                          style={{ marginLeft: "10px" }}
                          type="button"
                          className="btn btn-primary"
                        >
                          Add / Update
                        </button>
                        <button
                          onClick={() => setRemoveConfirmModal(true)}
                          style={{ marginLeft: "10px" }}
                          type="button"
                          className="btn btn-danger"
                        >
                          Remove
                        </button>
                      </>
                    )
                  )}
                </div>
              </div>
            </TabPanel>
          </SwipeableViews>
        </div>
        <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to Add/Update result of these classes to exam?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={updateClassExam}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={removeConfirmModal}
          onHide={() => setRemoveConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to remove selected classes & student results from this
            exam?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setRemoveConfirmModal(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="btn btn-danger"
              disabled={removingClasses}
              onClick={removeClassExam}
            >
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={removeStudentModal}
          onHide={() => setRemoveStudentModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Single Student Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to remove selected student results from this exam?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setRemoveStudentModal(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="btn btn-danger"
              onClick={removeStudentExam}
            >
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </>
  );
};

export default UpdateExams;
