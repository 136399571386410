import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const StudentFigureExport = ({
  reportData,
  grandStrength,
  grandFemale,
  grandMale,
  getStrengthSum,
  getfeMaleSum,
  getMaleSum,
}) => {
  const tableRef = useRef(null);

  return (
    <>
      <DownloadTableExcel
        filename="Student Figure"
        sheet="Student Figure"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2 mt-9">Export</button>
      </DownloadTableExcel>
      <div id="tableboot mt-5">
        <Table ref={tableRef} style={{ display: "none" }}>
          <thead>
            <tr id="tbl">
              <th className="text-center">Campus Name</th>
              <th className="text-center">Class Name</th>
              <th className="text-center">Male</th>
              <th className="text-center">Female</th>
              <th className="text-center">Strength</th>
            </tr>
          </thead>
          <tbody>
            {reportData.length > 0 ? (
              <>
                {reportData.map((item) => (
                  <>
                    <tr>
                      <td className="text_align_center text-primary">
                        {item.Campus}
                      </td>
                      <td></td>
                      <td
                        style={{ fontSize: "1.2rem" }}
                        className="text-center text-primary"
                      >
                        {getMaleSum(item)}
                      </td>
                      <td
                        style={{ fontSize: "1.2rem" }}
                        className="text-center text-primary"
                      >
                        {getfeMaleSum(item)}
                      </td>
                      <td
                        style={{ fontSize: "1.2rem" }}
                        className="text-center text-primary"
                      >
                        {getStrengthSum(item)}
                      </td>
                    </tr>

                    {item?.classes?.map((subitem, indu) => (
                      <>
                        <tr id="tbl">
                          <td></td>
                          <td
                            style={{
                              color: "black",
                              fontSize: "1rem",
                            }}
                            className="text-center"
                          >
                            {subitem?.Class?.name}
                          </td>
                          <td
                            style={{
                              color: "black",
                              fontSize: "1rem",
                            }}
                            className="text-center"
                          >
                            {subitem.sections.reduce(
                              (a, c) => a + parseInt(c.report.male),
                              0
                            )}
                          </td>
                          <td
                            style={{
                              color: "black",
                              fontSize: "1rem",
                            }}
                            className="text-center"
                          >
                            {subitem.sections.reduce(
                              (a, c) => a + parseInt(c.report.female),
                              0
                            )}
                          </td>
                          <td
                            style={{
                              color: "black",
                              fontSize: "1rem",
                            }}
                            className="text-center"
                          >
                            {subitem.sections.reduce(
                              (a, c) => a + parseInt(c.report.strength),
                              0
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </>
                ))}
                <tr>
                  <td
                    colSpan={2}
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-center text-primary"
                  >
                    Grand Total:-
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                    className="text-center text-primary"
                  >
                    {grandMale}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                    className="text-center text-primary"
                  >
                    {grandFemale}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                    className="text-center text-primary"
                  >
                    {grandStrength}
                  </td>
                </tr>
              </>
            ) : (
              ""
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default StudentFigureExport;
