import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const TrialExport = ({
  reportData,
  debitSum,
  creditSum,
  profitCredited,
  lossDebited,
}) => {
  const tableRef = useRef(null);

  return (
    <>
      <DownloadTableExcel
        filename="Trial Balance"
        sheet="TrialBalance"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2"> Export </button>
      </DownloadTableExcel>

      <Table ref={tableRef} style={{ display: "none" }}>
        <thead>
          <tr id="tbl">
            <th className="text-center">A/C Code</th>
            <th className="text-center">Account Name</th>
            <th className="text-center">Debit</th>
            <th className="text-center">Credit</th>
          </tr>
        </thead>

        <tbody>
          {reportData.map((base) =>
            base.account_group.length > 0 ? (
              <React.Fragment key={base.base_account}>
                <tr id="tbl">
                  <th
                    colSpan={2}
                    className="text-center text-primary"
                    style={{ fontSize: "1.1rem" }}
                  >
                    {base?.base_account}
                  </th>
                  <th
                    className="text-center text-primary"
                    style={{ fontSize: "1.1rem" }}
                  >
                    {Intl.NumberFormat("en-Us").format(
                      base?.base_account_debit
                    )}
                  </th>
                  <th
                    className="text-center text-primary"
                    style={{ fontSize: "1.1rem" }}
                  >
                    {Intl.NumberFormat("en-Us").format(
                      base?.base_account_credit
                    )}
                  </th>
                </tr>
                {base.account_group.map((item) =>
                  item.account_group.length > 0 ? (
                    <React.Fragment key={item.account_group_code}>
                      <tr id="tbl">
                        <td
                          style={{
                            color: "#fd12b3",
                            fontWeight: "bold",
                          }}
                          className="text-start pl-10"
                        >
                          {item?.account_group_code}
                        </td>
                        <td
                          style={{
                            color: "#fd12b3",
                            fontWeight: "bold",
                          }}
                          className="text-start"
                        >
                          {item?.account_group}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                          className="text-center text-primary"
                        >
                          {Intl.NumberFormat("en-Us").format(
                            item?.account_group_debit
                          )}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                          }}
                          className="text-center text-primary"
                        >
                          {Intl.NumberFormat("en-Us").format(
                            item?.account_group_credit
                          )}
                        </td>
                      </tr>
                      {item.account_chart.map((subitem) =>
                        subitem.sub_account.length > 0 ? (
                          <React.Fragment key={subitem.account_chart_code}>
                            <tr id="tbl">
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "30px",
                                  fontWeight: "bold",
                                }}
                                className="text-center"
                              >
                                {subitem.account_chart_code}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "30px",
                                  fontWeight: "bold",
                                }}
                                className="text-start"
                              >
                                {subitem.account_chart}
                              </td>

                              <td
                                style={{ color: "black" }}
                                className="text-center"
                              ></td>
                            </tr>
                            {subitem.sub_account.length > 0
                              ? subitem.sub_account.map((last) => (
                                  <React.Fragment key={last.sub_account_code}>
                                    <tr id="tbl">
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "60px",
                                        }}
                                        className="text-end"
                                      >
                                        {last.sub_account_code}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "60px",
                                          cursor: "pointer",
                                        }}
                                        className="text-start"
                                      >
                                        {last.sub_account}
                                      </td>

                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {Intl.NumberFormat("en-Us").format(
                                          last.debit
                                        )}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {Intl.NumberFormat("en-Us").format(
                                          last.credit
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              : null}
                            <tr id="tbl">
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "30px",
                                }}
                                className="text-start"
                              ></td>
                              <td
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                                className="text-end pr-10"
                              >
                                Control Accounts Total :-
                              </td>

                              <td
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  borderTop: "2px solid black",
                                  borderBottom: "2px solid black",
                                }}
                                className="text-center"
                              >
                                {Intl.NumberFormat("en-Us").format(
                                  subitem?.account_chart_debit
                                )}
                              </td>

                              <td
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  borderTop: "2px solid black",
                                  borderBottom: "2px solid black",
                                }}
                                className="text-center"
                              >
                                {Intl.NumberFormat("en-Us").format(
                                  subitem?.account_chart_credit
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ) : null
                      )}
                    </React.Fragment>
                  ) : null
                )}
              </React.Fragment>
            ) : null
          )}

          <tr id="tbl">
            <td
              style={{ color: "black", paddingLeft: "30px" }}
              className="text-start"
            ></td>
            <td
              style={{
                fontSize: "1.3rem",
                paddingRight: "50px",
                fontWeight: "bold",
              }}
              className="text-end text-primary"
            >
              {lossDebited != 0 ? "Loss Debited :-" : "Profit Credited :-"}
            </td>

            <td
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
              }}
              className="text-center text-primary"
            >
              {lossDebited != 0
                ? Intl.NumberFormat("en-Us").format(lossDebited)
                : 0}
            </td>

            <td
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
              }}
              className="text-center text-primary"
            >
              {profitCredited != 0
                ? Intl.NumberFormat("en-Us").format(profitCredited)
                : 0}
            </td>
          </tr>

          <tr id="tbl">
            <td
              style={{ color: "black", paddingLeft: "30px" }}
              className="text-start"
            ></td>
            <td
              style={{
                fontSize: "1.3rem",
                paddingRight: "50px",
                fontWeight: "bold",
              }}
              className="text-end text-primary"
            >
              Grand Total :-
            </td>

            <td
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
              }}
              className="text-center text-primary"
            >
              {Intl.NumberFormat("en-Us").format(debitSum)}
            </td>

            <td
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
              }}
              className="text-center text-primary"
            >
              {Intl.NumberFormat("en-Us").format(creditSum)}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default TrialExport;
