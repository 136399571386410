import { Paper } from "@material-ui/core";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { DatePicker, Divider } from "antd";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;
const ProfitLoss = () => {
  const [selected, setSelected] = useState("all");
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [revenue, setRevenue] = useState([]);
  const [expen, setExpen] = useState([]);
  const [revSum, setRevSum] = useState(0);
  const [deSum, setDeSum] = useState(0);
  const [profit, setProfit] = useState(0);
  const [loss, setLoss] = useState(0);
  const pageStyle = `
  @page {
    size: A4 !important;
}
`;
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    sessions();
    // getAllCampus();
    getSettingData();
  }, []);
  const alert = useAlert();
  const onRadioButtonClick = (e) => {
    const value = e.target.value;
    setSelected(e.target.value);
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const datePicker = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setStartDate(dateString[0]);
    setendDate(dateString[1]);
  };
  const handleGetData = () => {
    setProfit(0);
    setLoss(0);
    if (yearId == "") {
      alert.error("Please select the year");
      return;
    }
    if (campusId == "") {
      alert.error("Please select the campus");
      return;
    }

    setLoading(true);
    const data = {
      year_id: yearId,
      start_date: selected == "specfic" ? startDate : "",
      end_date: selected == "specfic" ? endDate : "",
      campus_id: campusId,
    };
    fetch(`${baseUrl}/profit_and_loss_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.payload) {
          setRevenue(data.payload.revenue);
          setExpen(data.payload.expenditure);
          let sum = 0;
          data.payload.revenue.forEach((element) => {
            element.account_chart.map((suitem) => {
              suitem.sub_account.map((last) => {
                sum += parseInt(last.credit);
              });
            });
          });
          let sum2 = 0;
          data.payload.expenditure.forEach((element) => {
            element.account_chart.map((suitem) => {
              suitem.sub_account.map((last) => {
                sum2 += parseInt(last.debit);
              });
            });
          });
          let here = sum - sum2;
          let myResult = here.toString();
          if (myResult[0] == "-") {
            let los = myResult.replace("-", "");
            setLoss(los);
          } else {
            setProfit(myResult);
          }
          setRevSum(sum);
          setDeSum(sum2);
        }
        console.log("this is data", data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-6 text-center">
            <input
              checked={selected == "all"}
              onChange={(e) => onRadioButtonClick(e)}
              defaultChecked
              type="radio"
              id="all"
              value="all"
            />
            <label for="all">Over All</label>
          </div>

          <div className="col-md-6 text-center">
            <input
              checked={selected == "specfic"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="specfic"
              value="specfic"
            />
            <label for="specfic">Specfic Period</label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          {selected == "specfic" ? (
            <div className="col-md-4">
              <label>
                <b>Select Date</b>
              </label>
              <RangePicker onChange={datePicker} />
            </div>
          ) : null}
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <button className="btn btn-primary" onClick={handleGetData}>
              Show
            </button>
          </div>
          <div className="col-md-4">
            {revenue?.length || expen?.length > 0 ? (
              <ReactToPrint
                pageStyle={pageStyle}
                documentTitle={`Profit and Loss Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <div id="tableboot">
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-start">A/C Code</th>
                    <th className="text-start">Account Name</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>

                <tbody>
                  <tr id="tbl">
                    <th
                      className="text-center text-primary"
                      style={{ fontSize: "1.3rem" }}
                    >
                      Total Revenue
                    </th>
                    <th></th>
                    <th
                      className="text-end text-primary"
                      style={{ fontSize: "1.3rem" }}
                    >
                      {revSum}
                    </th>
                  </tr>
                  {revenue.map((item) =>
                    item.account_chart.length > 0 ? (
                      <>
                        <tr id="tbl">
                          <td
                            style={{ color: "#4DE836", fontWeight: "bold" }}
                            className="text-start"
                          >
                            {item.account_group_code}
                          </td>
                          <td
                            style={{ color: "#4DE836", fontWeight: "bold" }}
                            className="text-start"
                          >
                            {item.account_group}
                          </td>
                          <td
                            style={{ color: "#4DE836", fontWeight: "bold" }}
                            className="text-start"
                          >
                            {/* {item.account_group} */}
                          </td>
                        </tr>
                        {item.account_chart.map((subitem) =>
                          subitem.sub_account.length > 0 ? (
                            <>
                              <tr id="tbl">
                                <td
                                  style={{
                                    color: "black",
                                    paddingLeft: "30px",
                                    fontWeight: "bold",
                                  }}
                                  className="text-start"
                                >
                                  {subitem.account_chart_code}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    paddingLeft: "30px",
                                    fontWeight: "bold",
                                  }}
                                  className="text-start"
                                >
                                  {subitem.account_chart}
                                </td>

                                <td
                                  style={{ color: "black" }}
                                  className="text-end"
                                >
                                  {/* {subitem.credit} */}
                                </td>
                              </tr>
                              {subitem.sub_account.length > 0
                                ? subitem.sub_account.map((last) => (
                                    <>
                                      <tr id="tbl">
                                        <td
                                          style={{
                                            color: "black",
                                            paddingLeft: "60px",
                                          }}
                                          className="text-start"
                                        >
                                          {last.sub_account_code}
                                        </td>
                                        <td
                                          style={{
                                            color: "black",
                                            paddingLeft: "60px",
                                          }}
                                          className="text-start"
                                        >
                                          {last.sub_account}
                                        </td>

                                        <td
                                          style={{ color: "black" }}
                                          className="text-end"
                                        >
                                          {last.credit}
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                : null}
                              <tr id="tbl">
                                <td
                                  style={{
                                    color: "black",
                                    paddingLeft: "30px",
                                  }}
                                  className="text-start"
                                ></td>
                                <td
                                  style={{
                                    color: "black",
                                    paddingLeft: "200px",
                                    fontWeight: "bold",
                                  }}
                                  className="text-start"
                                >
                                  Control Accounts Total :-
                                </td>

                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                  }}
                                  className="text-end"
                                >
                                  {subitem.sub_account.reduce(
                                    (a, c) => a + parseInt(c.credit),
                                    0
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : null
                        )}
                      </>
                    ) : null
                  )}
                  <Divider />
                  <tr id="tbl">
                    <th
                      className="text-center text-primary"
                      style={{ fontSize: "1.3rem" }}
                    >
                      Total EXPENDITURE
                    </th>
                    <th></th>
                    <th
                      className="text-end text-primary"
                      style={{ fontSize: "1.3rem" }}
                    >
                      {deSum}
                    </th>
                  </tr>
                  {expen.map((item) =>
                    item.account_chart.length > 0 ? (
                      <>
                        <tr id="tbl">
                          <td
                            style={{ color: "#4DE836", fontWeight: "bold" }}
                            className="text-start"
                          >
                            {item.account_group_code}
                          </td>
                          <td
                            style={{ color: "#4DE836", fontWeight: "bold" }}
                            className="text-start"
                          >
                            {item.account_group}
                          </td>
                          <td
                            style={{ color: "#4DE836", fontWeight: "bold" }}
                            className="text-start"
                          >
                            {/* {item.account_group} */}
                          </td>
                        </tr>
                        {item.account_chart.map((subitem) =>
                          subitem.sub_account.length > 0 ? (
                            <>
                              <tr id="tbl">
                                <td
                                  style={{
                                    color: "black",
                                    paddingLeft: "30px",
                                    fontWeight: "bold",
                                  }}
                                  className="text-start"
                                >
                                  {subitem.account_chart_code}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    paddingLeft: "30px",
                                    fontWeight: "bold",
                                  }}
                                  className="text-start"
                                >
                                  {subitem.account_chart}
                                </td>

                                <td
                                  style={{ color: "black" }}
                                  className="text-end"
                                >
                                  {/* {subitem.debit} */}
                                </td>
                              </tr>
                              {subitem.sub_account.length > 0
                                ? subitem.sub_account.map((last) => (
                                    <>
                                      <tr id="tbl">
                                        <td
                                          style={{
                                            color: "black",
                                            paddingLeft: "60px",
                                          }}
                                          className="text-start"
                                        >
                                          {last.sub_account_code}
                                        </td>
                                        <td
                                          style={{
                                            color: "black",
                                            paddingLeft: "60px",
                                          }}
                                          className="text-start"
                                        >
                                          {last.sub_account}
                                        </td>

                                        <td
                                          style={{ color: "black" }}
                                          className="text-end"
                                        >
                                          {last.debit}
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                : null}
                              <tr id="tbl">
                                <td
                                  style={{
                                    color: "black",
                                    paddingLeft: "30px",
                                  }}
                                  className="text-start"
                                ></td>
                                <td
                                  style={{
                                    color: "black",
                                    paddingLeft: "200px",
                                    fontWeight: "bold",
                                  }}
                                  className="text-start"
                                >
                                  Control Accounts Total :-
                                </td>

                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                  }}
                                  className="text-end"
                                >
                                  {subitem.sub_account.reduce(
                                    (a, c) => a + parseInt(c.debit),
                                    0
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : null
                        )}
                      </>
                    ) : null
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div
            style={{
              display: "flex",
              padding: "10px",
            }}
          >
            <div>
              <img style={{ height: 100, width: 110 }} src={icon} alt="logo" />
            </div>
            <div className="col-md-8">
              <h1>{unitName}</h1>
              <h3>Profit & Loss Statment</h3>
            </div>
          </div>
          <br />
          <div id="tableboot">
            <Table
              responsive
              style={{
                padding: "10px",
                whiteSpace: "normal",
              }}
            >
              <thead
                style={{
                  padding: "1px",
                  whiteSpace: "normal",
                }}
              >
                <tr
                  id="tbl"
                  style={{
                    padding: "1px",
                    whiteSpace: "normal",
                    fontSize: "20px",
                  }}
                >
                  <th
                    className="text-start"
                    style={{
                      padding: "1px",
                      whiteSpace: "normal",
                      fontSize: "20px",
                    }}
                  >
                    A/C Code
                  </th>
                  <th
                    className="text-start"
                    style={{
                      padding: "1px",
                      whiteSpace: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Account Name
                  </th>
                  <th
                    className="text-end"
                    style={{
                      padding: "1px",
                      whiteSpace: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>

              <tbody
                style={{
                  padding: "1px",
                  whiteSpace: "normal",
                }}
              >
                <tr
                  id="tbl"
                  style={{
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                >
                  <th
                    className="text-center text-primary"
                    style={{
                      padding: "1px",
                      whiteSpace: "normal",
                    }}
                  >
                    Total Revenue
                  </th>
                  <th></th>
                  <th
                    className="text-end text-primary"
                    style={{ fontSize: "1.3rem" }}
                  >
                    {revSum}
                  </th>
                </tr>
                {revenue.map((item) =>
                  item.account_chart.length > 0 ? (
                    <>
                      <tr
                        id="tbl"
                        style={{
                          padding: "1px",
                          whiteSpace: "normal",
                        }}
                      >
                        <td
                          style={{ color: "#4DE836", fontWeight: "bold" }}
                          className="text-start"
                        >
                          {item.account_group_code}
                        </td>
                        <td
                          style={{ color: "#4DE836", fontWeight: "bold" }}
                          className="text-start"
                        >
                          {item.account_group}
                        </td>
                        <td
                          style={{ color: "#4DE836", fontWeight: "bold" }}
                          className="text-start"
                        >
                          {/* {item.account_group} */}
                        </td>
                      </tr>
                      {item.account_chart.map((subitem) =>
                        subitem.sub_account.length > 0 ? (
                          <>
                            <tr
                              id="tbl"
                              style={{
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "30px",
                                  fontWeight: "bold",
                                }}
                                className="text-start"
                              >
                                {subitem.account_chart_code}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "30px",
                                  fontWeight: "bold",
                                }}
                                className="text-start"
                              >
                                {subitem.account_chart}
                              </td>

                              <td
                                style={{ color: "black" }}
                                className="text-end"
                              >
                                {/* {subitem.credit} */}
                              </td>
                            </tr>
                            {subitem.sub_account.length > 0
                              ? subitem.sub_account.map((last) => (
                                  <>
                                    <tr
                                      id="tbl"
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "60px",
                                        }}
                                        className="text-start"
                                      >
                                        {last.sub_account_code}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "60px",
                                        }}
                                        className="text-start"
                                      >
                                        {last.sub_account}
                                      </td>

                                      <td
                                        style={{ color: "black" }}
                                        className="text-end"
                                      >
                                        {last.credit}
                                      </td>
                                    </tr>
                                  </>
                                ))
                              : null}
                            <tr
                              id="tbl"
                              style={{
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              <td
                                style={{ color: "black", paddingLeft: "30px" }}
                                className="text-start"
                              ></td>
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "200px",
                                  fontWeight: "bold",
                                }}
                                className="text-start"
                              >
                                Control Accounts Total :-
                              </td>

                              <td
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  borderTop: "2px solid black",
                                  borderBottom: "2px solid black",
                                }}
                                className="text-end"
                              >
                                {subitem.sub_account.reduce(
                                  (a, c) => a + parseInt(c.credit),
                                  0
                                )}
                              </td>
                            </tr>
                          </>
                        ) : null
                      )}
                    </>
                  ) : null
                )}
                <Divider />
                <tr
                  id="tbl"
                  style={{
                    padding: "1px",
                    whiteSpace: "normal",
                  }}
                >
                  <th
                    className="text-center text-primary"
                    style={{ fontSize: "1.3rem" }}
                  >
                    Total EXPENDITURE
                  </th>
                  <th></th>
                  <th
                    className="text-end text-primary"
                    style={{ fontSize: "1.3rem" }}
                  >
                    {deSum}
                  </th>
                </tr>
                {expen.map((item) =>
                  item.account_chart.length > 0 ? (
                    <>
                      <tr
                        id="tbl"
                        style={{
                          padding: "1px",
                          whiteSpace: "normal",
                        }}
                      >
                        <td
                          style={{ color: "#4DE836", fontWeight: "bold" }}
                          className="text-start"
                        >
                          {item.account_group_code}
                        </td>
                        <td
                          style={{ color: "#4DE836", fontWeight: "bold" }}
                          className="text-start"
                        >
                          {item.account_group}
                        </td>
                        <td
                          style={{ color: "#4DE836", fontWeight: "bold" }}
                          className="text-start"
                        >
                          {/* {item.account_group} */}
                        </td>
                      </tr>
                      {item.account_chart.map((subitem) =>
                        subitem.sub_account.length > 0 ? (
                          <>
                            <tr
                              id="tbl"
                              style={{
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "30px",
                                  fontWeight: "bold",
                                }}
                                className="text-start"
                              >
                                {subitem.account_chart_code}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "30px",
                                  fontWeight: "bold",
                                }}
                                className="text-start"
                              >
                                {subitem.account_chart}
                              </td>

                              <td
                                style={{ color: "black" }}
                                className="text-end"
                              >
                                {/* {subitem.debit} */}
                              </td>
                            </tr>
                            {subitem.sub_account.length > 0
                              ? subitem.sub_account.map((last) => (
                                  <>
                                    <tr
                                      id="tbl"
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "60px",
                                        }}
                                        className="text-start"
                                      >
                                        {last.sub_account_code}
                                      </td>
                                      <td
                                        style={{
                                          color: "black",
                                          paddingLeft: "60px",
                                        }}
                                        className="text-start"
                                      >
                                        {last.sub_account}
                                      </td>

                                      <td
                                        style={{ color: "black" }}
                                        className="text-end"
                                      >
                                        {last.debit}
                                      </td>
                                    </tr>
                                  </>
                                ))
                              : null}
                            <tr
                              id="tbl"
                              style={{
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              <td
                                style={{ color: "black", paddingLeft: "30px" }}
                                className="text-start"
                              ></td>
                              <td
                                style={{
                                  color: "black",
                                  paddingLeft: "200px",
                                  fontWeight: "bold",
                                }}
                                className="text-start"
                              >
                                Control Accounts Total :-
                              </td>

                              <td
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  borderTop: "2px solid black",
                                  borderBottom: "2px solid black",
                                }}
                                className="text-end"
                              >
                                {subitem.sub_account.reduce(
                                  (a, c) => a + parseInt(c.debit),
                                  0
                                )}
                              </td>
                            </tr>
                          </>
                        ) : null
                      )}
                    </>
                  ) : null
                )}
              </tbody>
            </Table>
            <div className="row mt-3">
              {profit > 0 ? (
                <div className="col-md-12 text-center mt-3">
                  <h2 style={{ fontStyle: "italic" }} className="text-primary">
                    Profit Credited Rs:{profit}
                  </h2>
                  <div className="linnerContaioner">
                    <div className="linner"></div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {loss > 0 ? (
                <div className="col-md-12 text-center mt-3">
                  <h2 className="text-danger">Loss Rs:{loss}</h2>
                  <div className="linnerContaioner">
                    <div className="linner"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {profit > 0 ? (
            <div className="col-md-12 text-center mt-3">
              <h2 style={{ fontStyle: "italic" }} className="text-primary">
                Profit Credited Rs:{profit}
              </h2>
              <div className="linnerContaioner">
                <div className="linner"></div>
              </div>
            </div>
          ) : (
            ""
          )}

          {loss > 0 ? (
            <div className="col-md-12 text-center mt-3">
              <h2 className="text-danger">Loss Rs:{loss}</h2>
              <div className="linnerContaioner">
                <div className="linner"></div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Paper>
    </>
  );
};

export default ProfitLoss;
