import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
const StaffListOverAllExport = ({ reportList }) => {
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Staff List OverAll",
    sheet: "StaffListOverAll",
  });
  return (
    <>
      <button className="btn btn-primary" onClick={onDownload}>
        {" "}
        Export{" "}
      </button>
      <Table ref={tableRef} style={{ display: "none" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "start" }}>Sr.</th>
            <th style={{ textAlign: "start" }}>Code</th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>Name</th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>
              Father's Name
            </th>
            <th style={{ textAlign: "start", minWidth: "100px" }}>
              Emp. Scale
            </th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>
              Designation
            </th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>
              Joining Date
            </th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>CNIC</th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>
              Date of Birth{" "}
            </th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>Gender</th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>
              Qualification/Experience
            </th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>Mobile No</th>
            <th style={{ textAlign: "start", minWidth: "150px" }}>Campus</th>
          </tr>
        </thead>
        <tbody>
          {reportList &&
            reportList.map((report, index) => (
              <tr>
                <td style={{ textAlign: "start" }}>{index + 1}</td>
                <td style={{ textAlign: "start" }}>{report.emp_code}</td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report.full_name}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report.father_name}
                </td>
                <td style={{ textAlign: "start", minWidth: "100px" }}>
                  {report.pay_scale_id}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report?.designation?.name}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report.joining_date}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report.cnic_no}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report.dob}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report.gender}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report.qualification}/{report.experience}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report.mobile_no}
                </td>
                <td style={{ textAlign: "start", minWidth: "150px" }}>
                  {report?.campus?.name}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default StaffListOverAllExport;
