import React, { useEffect, useState } from "react";
import { Table, Modal, Tab, Tabs, Alert, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";

export default function ViewFeeModal({
  show,
  onHide,
  feedetail,
  setchallandetails,
  setfeemodal,
  reloadFunction,
}) {
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();
  const dispatch = useDispatch();

  //states
  //objects

  const [challansforsplitingobj, setChallansForSplitingObj] = useState({});
  //arrays

  const [challansforspliting, setChallansForSpliting] = useState([]);
  const [challansForPay, sethallansForPay] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);

  //booleans
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  //strings
  const [received_date, setreceived_date] = useState(
    useSelector((state) => state.campuses.setchallanrecevingdate)
  );

  const [clallanloading, setclallanloading] = useState(false);

  //useeffect
  useEffect(() => {
    getChallnFeeses();
  }, []);

  //functions
  const resetvalue = () => {
    setChallansForSplitingObj({});
    setcheckallcheckbox(false);
    setChallansForSpliting([]);
    sethallansForPay([]);
    setallcheckboxes([]);
  };
  const makeOneChallan = async () => {
    let data = {
      fee_challan_id: feedetail.id,
      fee_challan_detail_ids: challansForPay,
      due_date: received_date,
    };

    console.log(data);
    setclallanloading(true);

    await fetch(`${baseUrl}/fee_challan_split`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setclallanloading(false);
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.show("Successfully generated");

              // let updateDetailObj = data.payload
              console.log(data.payload);
              setchallandetails({ ...data.payload });
              setfeemodal(true);
              onHide(false);
              resetvalue();
              reloadFunction();
            } else {
              alert.error(data?.metadata?.message);
            }
          } else {
            alert.error("Error Occured");
          }
        })
      )

      .catch((err) => {
        alert.error("Error Occured");
        setclallanloading(false);

        console.log(err);
      });
  };

  const getChallnFeeses = async () => {
    console.log("feedetail==>", feedetail);
    let data = {
      fee_challan_id: feedetail.id,
    };
    await fetch(`${baseUrl}/get_challan_for_spliting`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              setChallansForSplitingObj(data.payload);
              setChallansForSpliting([...data.payload.fee_challan_details]);
            }
          }
        })
      )

      .catch((err) => {
        console.log(err);
      });
  };

  //check box work
  const allcheckboxChecked = () => {
    let middlearray = [];
    let callanarray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      challansforspliting.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
    } else {
      setcheckallcheckbox(true);
      challansforspliting.map((item, i) => {
        item.checked = true;
        callanarray.push(item.id);
        middlearray.push(item);
      });
    }
    console.log(callanarray);
    console.log(middlearray);
    sethallansForPay([...callanarray]);
    setChallansForSpliting([...middlearray]);
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let callanarray = [];
    challansforspliting.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        callanarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(callanarray);
    sethallansForPay([...callanarray]);
    setChallansForSpliting([...middlearray]);
  };

  return (
    <div>
      <Modal size="xl" show={show}>
        <Modal.Body>
          <div className="card">
            <div className="form-group col-sm-4 flex-column d-flex pt-3">
              <label className="form-control-label">
                <b>
                  Received Date <span style={{ color: "red" }}>*</span>
                </b>
              </label>
              <input
                value={received_date}
                defaultValue={received_date}
                onChange={(e) => {
                  dispatch({
                    type: "CHALLAN_RECEVING_DATE",
                    payload: {
                      setchallanrecevingdate: e.target.value,
                    },
                  });

                  setreceived_date(e.target.value);
                }}
                type="date"
                max="9999-11-30"
                className="form-control"
              />
            </div>
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>
                    <input
                      onClick={(e) => {
                        allcheckboxChecked();
                      }}
                      checked={checkallcheckbox}
                      style={{}}
                      placeholder="Checkbox"
                      type="checkbox"
                    />{" "}
                    <span style={{ marginleft: "15px" }}>Check All</span>
                  </th>

                  <th>Id</th>
                  <th>Fee Name</th>
                  <th>Amount</th>
                  <th>Challan No</th>

                  <th>Fee Month</th>
                </tr>
              </thead>

              {challansforspliting?.map((item, index) => {
                return (
                  <tr>
                    <td style={{ textAlign: "start" }}>
                      <input
                        key={index}
                        onClick={(e) => {
                          checkBoxClick(e, item.id);
                        }}
                        checked={item.checked}
                        value={allcheckboxes[index]}
                        type="checkbox"
                      />
                    </td>
                    <td style={{ textAlign: "start" }}>{item.id}</td>
                    <td style={{ textAlign: "start" }}>{item.fee_name}</td>
                    <td style={{ textAlign: "start" }}>{item.amount}</td>
                    <td style={{ textAlign: "start" }}>
                      {feedetail.challan_no}
                    </td>

                    <td style={{ textAlign: "start" }}>{item.fee_month}</td>
                  </tr>
                );
              })}
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {clallanloading ? (
              <div className="row justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <button
                  disabled={
                    challansForPay.length == 0
                      ? true
                      : received_date == ""
                      ? true
                      : false
                  }
                  onClick={() => {
                    makeOneChallan();
                  }}
                  style={{ width: "150px", height: "40px" }}
                  className="btn button_blue btn_width"
                >
                  Pay
                </button>
                <button
                  onClick={() => {
                    onHide(false);
                    resetvalue();
                  }}
                  style={{ width: "150px", height: "40px", marginLeft: "10px" }}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

// export default function ViewFeeModal({ show, onHide, feedetail }) {
//   console.log(feedetail, "feedetail");
//   return (

//     <div>
//       <Modal size="xl" show={show}>
//         <Modal.Body>
//           <div className="card">
//             <Table responsive>
//               <thead>
//                 <tr id="tbl">
//                   <th>SR#</th>
//                   <th>Fee Name</th>
//                   <th>Amount</th>
//                   <th>Challan No</th>
//                   <th>Due Date</th>
//                   <th>Fee Month</th>
//                 </tr>
//               </thead>
//               {feedetail.fee_challan_details == undefined ? null : (
//                 <>
//                   {feedetail.fee_challan_details.map((item, index) => {
//                     return (
//                       <tr>
//                         <td style={{ textAlign: "start" }}>{index + 1}</td>
//                         <td style={{ textAlign: "start" }}>{item.fee_name}</td>
//                         <td style={{ textAlign: "start" }}>{item.amount}</td>
//                         <td style={{ textAlign: "start" }}>
//                           {feedetail.challan_no}
//                         </td>
//                         <td style={{ textAlign: "start" }}>
//                           {feedetail.due_date}
//                         </td>
//                         <td style={{ textAlign: "start" }}>{item.fee_month}</td>
//                       </tr>
//                     );
//                   })}
//                 </>
//               )}
//             </Table>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <div style={{ display: "flex", justifyContent: "flex-end" }}>
//             <button
//               onClick={() => {
//                 onHide(false);
//               }}
//               style={{ width: "150px", height: "40px" }}
//               className="btn btn-danger btn_width"
//             >
//               Cancel
//             </button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }
