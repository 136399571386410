import React from 'react'
import { CSVLink } from 'react-csv'

const ExportCsv = ({headers,csvList,left,name}) => {
  return (
    <>
    {
        csvList.length>0 ?
     <CSVLink
              filename={name}
              headers={headers}
              data={csvList}
            >
              <button 
              style={{marginLeft:left ?"10px":''}}
              className='btn btn-primary'
              >Export</button>
              
            </CSVLink>:''}
    </>
  )
}

export default ExportCsv