import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Tab, Tabs, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import Barcode from "react-barcode";
import { baseUrl } from "../../../services/config";
import FeeReceviceModal from "../../../components/FeeReceviceModal/FeeReceviceModal";
import { useAlert } from "react-alert";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../components/usePagination/usePagination";

const icon =
  "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";

function AllClampusStudent({
  getStudentsForFeeGenerate,
  getcurrentsession,
  getCampusName,
  getFeeType,
}) {
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const authtoken = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const [postPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [allcampusstudent, setallcampusstudent] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [campusid, setcampusid] = useState("");
  const [classes, setclasses] = useState([]);
  const [classid, setclassid] = useState("");
  const [selectedclass, setselectedclass] = useState({});
  const [selectedstudentsarray, setselectedstudentsarray] = useState([]);
  const [sections, setsections] = useState([]);
  const [sectionid, setsectionid] = useState("");
  const [dataloader, setdataloader] = useState(false);
  const [campusName, setCampusName] = useState("");

  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  const [allsessions, setallsessions] = useState([]);
  const [educationType, setEducationType] = useState(1);

  const [currentsession, setcurrentsession] = useState("");
  const [searchtext, setsearchtext] = useState("");

  useEffect(() => {
    // if (campusidget != "") {
    //   setpreviousselectedcampus(campusidget);
    //   onchangecampus(campusidget);
    // }
    // getAllCampus();
    getAllSessions();
  }, []);

  const alert = useAlert();

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            setcurrentsession(item.id.toString());
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("campus", err);
      });
  };

  const studentFetchFunction = () => {
    setdataloader(true);

    // setcheckallcheckbox(false);
    setallcampusstudent([]);

    let data = {};
    if (sectionid != "") {
      getStudentsForFeeGenerate([], campusid, classid, sectionid);
      //setsectionid(section_id);
      data = {
        campus_id: campusid,
        student_class_id: classid,
        section_id: sectionid,
        year_id: currentsession,
        education_type: educationType,
      };
    } else if (classid != "") {
      data = {
        campus_id: campusid,
        student_class_id: classid,
        education_type: educationType,
        year_id: currentsession,
      };
      getStudentsForFeeGenerate([], campusid, classid);
    } else {
      data = {
        campus_id: campusid,
        education_type: educationType,
        year_id: currentsession,
      };
      getStudentsForFeeGenerate([], campusid, currentsession);
    }

    console.log(data);
    fetch(`${baseUrl}/all_fee_generate_std_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setdataloader(false);

        console.log("students", data);
        let checkboxes = data.payload;
        data.payload.map((item, index) => {
          item["checked"] = false;
          checkboxes[index] = item;
        });
        let middlearray = [];
        let studentarray = [];
        setcheckallcheckbox(true);
        checkboxes.map((item, i) => {
          item.checked = true;
          studentarray.push(item.id);
          middlearray.push(item);
        });
        getStudentsForFeeGenerate(
          studentarray,
          campusid,
          classid,
          currentsession
        );
        setallcampusstudent([...middlearray]);
        setallcampusstudent([...checkboxes]);

        console.log("ye hain hmray asal", checkboxes);
        // setcheckallcheckbox(false);
      })
      .catch((err) => {
        console.log(err);
        setdataloader(false);

        console.log("students", err);
      });
  };
  const PRINTCOUNT = [1, 2, 3];

  let array = [
    { id: 1, name: "Junaid", class: "5th", section: "Hifz" },
    { id: 2, name: "Ahmad", class: "6th", section: "ali" },
  ];

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;

  //   const currentPosts = allcampusstudent.slice(
  //     indexOfFirstPost,
  //     indexOfLastPost
  //   );

  //   const dataSearch = allheadofficeunpaidchallans.filter((user) => {

  //     let text = searchtext.toLowerCase()
  //     return searchtext ? user.challan_no.toLowerCase().includes(text) : true
  // })

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    allcampusstudent.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    setallcampusstudent([...middlearray]);
  };
  const allcheckboxChecked = () => {
    let middlearray = [];
    let studentarray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      allcampusstudent.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
      getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    } else {
      setcheckallcheckbox(true);
      allcampusstudent.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
      getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    }

    setallcampusstudent([...middlearray]);
  };

  const onchangeclassfunc = (id) => {
    //studentFetchFunction(campusid, id, "");

    setsections([]);
    // getStudentsForFeeGenerate([], campusid, id);
    // setcheckallcheckbox(false);
    // setallcampusstudent([]);

    setclassid(id);

    fetch(`${baseUrl}/class_section/${campusid}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setsections([...data.payload]);
        console.log("class", data);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };
  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${campusid}/${id}${
        classid ? "/" + classid : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setsections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onchangecampus = (e) => {
    setcampusid(e);

    //studentFetchFunction(e, "", "");
    const id = e;
    setclasses([]);
    setsections([]);
    console.log(e);
    getStudentsForFeeGenerate([], e, "", currentsession);
    // setcheckallcheckbox(false);
    setallcampusstudent([]);

    const campusname = campuses.find((item) => {
      return item.id == id;
    });
    console.log(campusname);
    setCampusName(campusname);
    getCampusName(campusname);
    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });

    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setclasses([...data.payload]);

        console.log("class", data);
      })
      .catch((err) => {
        console.log(err);
        console.log("class", err);
      });
  };

  const changesession = (id) => {
    setcurrentsession(id);
    getcurrentsession(id);
    // if (previousselectedcampus != "") {
    //   onchangecampus(previousselectedcampus);
    // }

    //getfeetypes(campus_id, student_class_id, id);
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(allcampusstudent.length / PER_PAGE);
  const _DATA = usePagination(allcampusstudent, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const searchbyRegId = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_name_or_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            // if (data.payload.length === 0) {
            //   alert.error("Match not found");
            // }
            // getFeeType(data.payload[0].campus_id,data.payload[0].student_class_id,currentsession)
            let checkboxes = data.payload;
            data.payload.map((item, index) => {
              item["checked"] = false;
              checkboxes[index] = item;
            });
            let middlearray = [];
            let studentarray = [];
            setcheckallcheckbox(true);
            checkboxes.map((item, i) => {
              item.checked = true;
              studentarray.push(item.id);
              middlearray.push(item);
            });
            getStudentsForFeeGenerate(
              studentarray,
              data.payload[0].campus_id,
              data.payload[0].student_class_id,
              currentsession
            );
            setallcampusstudent([...middlearray]);
            setallcampusstudent([...checkboxes]);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  return (
    <div>
      <div class="form-group row">
        <div className="col-md-3">
          <label>
            <b>
              Select Session <span style={{ color: "red" }}>*</span>
            </b>
          </label>
          <select
            onChange={(e) => changesession(e.target.value)}
            class="form-select"
            style={{ height: 40 }}
            value={currentsession}
            aria-label="Default select example"
          >
            <option value="">Select Session</option>
            {allsessions.map((session) => (
              <option
                // selected={session.active_academic_year == "1" ? true : false}
                value={session.id}
              >
                {session.year}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3">
          <label>
            <b>Select Campus </b> <span style={{ color: "red" }}>*</span>
          </label>
          <select
            onChange={(e) => onchangecampus(e.target.value)}
            className="form-select"
            style={{ height: 40 }}
            value={campusid}
            aria-label="Default select example"
          >
            <option selected value="">
              Select Campus
            </option>

            {campuses.map((campus) => (
              <option key={campus.id} value={campus.id}>
                {campus.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3">
          <label>
            <b>Select Class </b> <span style={{ color: "red" }}>*</span>
          </label>
          <select
            onChange={(e) => onchangeclassfunc(e.target.value)}
            className="form-select"
            style={{ height: 40 }}
            aria-label="Default select example"
          >
            <option selected value="">
              Select Class
            </option>

            {classes.map((item) => (
              <option name={item.name} key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3">
          <label>
            <b>Select Education Type</b>
          </label>
          <select
            className="form-select"
            name="campus_id"
            value={educationType}
            style={{ height: 40 }}
            aria-label="Default select example"
            onChange={(e) => {
              setEducationType(e.target.value);
              getSectionsListOnTypeChange(e.target.value);
            }}
          >
            <option selected value={1}>
              Education
            </option>
            <option value={2}>Hifz</option>
          </select>
        </div>

        <div className="col-md-3 mt-3">
          <label>
            <b>Select Section </b> <span style={{ color: "red" }}>*</span>
          </label>
          <select
            onChange={(e) => {
              setsectionid(e.target.value);
            }}
            class="form-select"
            style={{ height: 40 }}
            aria-label="Default select example"
          >
            <option selected value="">
              Select Section
            </option>
            {sections.map((clas) => (
              <option value={clas.id}>{clas.name}</option>
            ))}
          </select>
        </div>

        <div className="col-md-3 mt-12">
          <button className="btn btn-primary" onClick={studentFetchFunction}>
            Show Student
          </button>
        </div>

        <div className="col-md-8">
          <form className="row mt-9" onSubmit={searchbyRegId}>
            <div className="col-md-8">
              <input
                onChange={(e) => setsearchtext(e.target.value)}
                className="form-control"
                placeholder="Enter Name or Admission ID"
              />
            </div>
            <div className="col-md-4">
              <button type="submit" className="btn btn-primary">
                Search Student
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* {currentsession != "" ? (
        <div className="row">
          <div className="form-group col-sm-2 flex-column d-flex">
            <label>
              <b>Select Campus </b>
            </label>
            <select
              onChange={(e) => onchangecampus(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={campusid}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Campus
              </option>

              {campuses.map((campus) => (
                <option key={campus.id} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-sm-3 flex-column d-flex">
            <label>
              <b>Select Class </b>
            </label>
            <select
              onChange={(e) => onchangeclassfunc(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Class
              </option>

              {classes.map((item) => (
                <option name={item.name} key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-sm-2 flex-column d-flex">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value=""> Select Education Type</option>
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="form-group col-sm-3 flex-column d-flex">
            <label>
              <b>Select Section </b>
            </label>
            <select
              onChange={(e) => {
                // studentFetchFunction(campusid, classid, e.target.value);
                setsectionid(e.target.value);
              }}
              class="form-select"
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Section
              </option>
              {sections.map((clas) => (
                <option value={clas.id}>{clas.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-sm-2 mt-9 flex-column d-flex">
            <button className="btn btn-primary" onClick={studentFetchFunction}>
              Search
            </button>
          </div>
        </div>
      ) : null} */}

      <Table responsive>
        <thead>
          <tr>
            <th>Student Name</th>
            <th>Monthly Fee</th>
            <th>Addmission ID</th>
            <th>Student Class</th>
            <th>Class Section</th>
            <th>
              <input
                onClick={(e) => {
                  allcheckboxChecked();
                }}
                checked={checkallcheckbox}
                style={{}}
                placeholder="Checkbox"
                type="checkbox"
              />{" "}
              <span style={{ marginleft: "15px" }}>Check All</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {_DATA.currentData().map((feechallan, index) => (
            <tr key={feechallan.id}>
              <td
                // title={feechallan.name.length > 15 ? feechallan.name : null}
                style={{ textAlign: "start", cursor: "pointer" }}
              >
                {feechallan.name}
              </td>
              {feechallan.liable_fees.length > 0 ? (
                feechallan.liable_fees.map((fee) =>
                  fee.fees_type_id == 4 ? (
                    <td
                      title={fee.amount}
                      style={{ textAlign: "start", cursor: "pointer" }}
                    >
                      {fee.amount}
                    </td>
                  ) : null
                )
              ) : (
                <td style={{ textAlign: "start" }}>0</td>
              )}

              <td style={{ textAlign: "start" }}>{feechallan.admission_id}</td>
              <td style={{ textAlign: "start" }}>
                {feechallan.student_class.name}
              </td>
              <td style={{ textAlign: "start" }}>
                {feechallan.global_section.name}
              </td>

              <td style={{ textAlign: "start" }}>
                <input
                  key={feechallan.id}
                  onClick={(e) => {
                    checkBoxClick(e, feechallan.id);
                  }}
                  value={allcheckboxes[index]}
                  checked={feechallan.checked}
                  type="checkbox"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        color="primary"
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
      />

      <div className="row justify-content-center">
        {dataloader && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
    </div>
  );
}

export default AllClampusStudent;
