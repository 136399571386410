import { Paper } from "@material-ui/core";
import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { Spinner, Table, Modal as Modalw2, Button } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
const StudentMessaging = () => {
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [classesData, setClassesData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [campusId, setCampusId] = useState("");
  const [classId, setClassId] = useState("");
  const [studentsData, setStudentsData] = useState(0);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [messagedata, setMessageData] = useState("");
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [studentIds, setStudentIds] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [searchtext, setsearchtext] = useState("");
  const [educationType, setEducationType] = useState(1);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const [studentMessage, setStudentMessage] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [messageCount, setMessageCount] = useState(1);
  const [assignMessage, setAssignMessage] = useState(0);
  const [remainingMessage, setRemainingMessage] = useState(0);

  const [allsessions, setallsessions] = useState([]);
  const [currentsession, setcurrentsession] = useState("");

  useEffect(() => {
    getAllSessions();
    // getAllCampus();
    getUnitMessages();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changesession = (id) => {
    setcurrentsession(id);
  };

  const getUnitMessages = async () => {
    await fetch(`${baseUrl}/get-unit-messages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setAssignMessage(data?.payload?.assign_sms);
        setRemainingMessage(data?.payload?.remaining_sms);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const onChampusChange = (id) => {
    setCampusId(id);
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setClassesData(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setClassId(id);
    fetch(`${baseUrl}/class_section/${campusId}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSectionData(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${campusId}/${id}${
        classId ? "/" + classId : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllStudents = () => {
    let data = {
      campus_id: campusId,
      student_class_id: classId,
      global_section_id: sectionId,
      education_type: educationType,
      status: 2,
      year_id: currentsession,
    };

    setloading(true);

    fetch(`${baseUrl}/student_filter_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              let res = data.payload.sort(
                (a, b) => a.admission_id - b.admission_id
              );

              setStudentsData(res);
              setloading(false);
              setcheckallcheckbox(false);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const onSectionChange = (id) => {
    setSectionId(id);
    getAllStudents(campusId, classId, id);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const handleModelOpen = () => {
    if (studentIds.length > 0) {
      setOpen(true);
    } else {
      alert.error("Please Select The At least 1 student");
    }
  };

  const sendMessage = () => {
    setConfirmModal(false);
    setOpen(false);
    setbuttonDisabled(false);
    setMessageData("");

    // messageApi.open({
    //   key,
    //   type: 'loading',
    //   content: 'Sending.....',
    // });
    setbuttonDisabled(true);
    let data = {
      message: studentMessage,
      student_ids: studentIds,
    };

    fetch(`${baseUrl}/student-custom-sms`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              setOpen(false);
              setMessageData("");
              messageApi.open({
                key,
                type: "success",
                content: "Successfully Send",
                duration: 1,
              });
              setbuttonDisabled(false);
              setConfirmModal(false);
            } else {
              messageApi.open({
                key,
                type: "error",
                content: "Something went wrong",
                duration: 4,
              });
              setbuttonDisabled(false);
              setConfirmModal(false);
            }
          }
          console.log(data);
        })
      )

      .catch((err) => {
        console.log(err);
      });
  };

  // code fro checkboxess
  const allcheckboxChecked = () => {
    let studentarray = [];
    let middlearray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      studentsData.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
    } else {
      setcheckallcheckbox(true);
      studentsData.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
    }
    console.log(studentarray);
    console.log(middlearray);
    setStudentIds([...studentarray]);
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    studentsData.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    setStudentIds([...studentarray]);
  };

  const searchbyRegId = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_name_or_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("Match not found");
            }
            setStudentsData(data.payload);
            setcheckallcheckbox(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const handleChange = (event) => {
    const inputMessage = event.target.value;
    const currentCharacterCount = inputMessage.length;
    const currentMessageCount = Math.ceil(currentCharacterCount / 160);

    setStudentMessage(inputMessage);
    setCharacterCount(currentCharacterCount);
    setMessageCount(currentMessageCount);
  };

  // const debouncedHandleChange = debounce((inputMessage) => {
  //   const currentCharacterCount = inputMessage.length;
  //   const currentMessageCount = Math.ceil(currentCharacterCount / 160);

  //   setStudentMessage(inputMessage);
  //   setCharacterCount(currentCharacterCount);
  //   setMessageCount(currentMessageCount);
  // }, 50); // Adjust debounce time as needed

  // const handleChange = (event) => {
  //   const inputMessage = event.target.value;
  //   debouncedHandleChange(inputMessage);
  // };

  return (
    <>
      {contextHolder}
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="">
          <button onClick={handleModelOpen} className="btn btn-primary">
            Send Message
          </button>
        </div>
        <div className="row mt-3">
          <div className="col-sm-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => changesession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option
                  key={index}
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => onChampusChange(e.target.value)}
              >
                <option selected value="">
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>
            <div className="d-flex flex-column">
              <select
                id="classselect"
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                aria-label="Default select example"
                onChange={(e) => onClassChange(e.target.value)}
              >
                <option selected value="">
                  Select Class
                </option>
                {classesData &&
                  classesData.map((item) => (
                    <>
                      <option value={item.id}>{item.name}</option>
                    </>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                // getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-3 mt-4">
            <label>
              <b>Select Section</b>
            </label>
            <div className="d-flex flex-column">
              <select
                id="sectionselect"
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                aria-label="Default select example"
                onChange={(e) => setSectionId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Section
                </option>

                {sectionData &&
                  sectionData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 mt-13">
            <button className="btn btn-primary" onClick={getAllStudents}>
              Show Student
            </button>
          </div>
        </div>
        <form onSubmit={searchbyRegId}>
          <div className="row mt-5">
            <div className="col-md-4">
              <input
                onChange={(e) => setsearchtext(e.target.value)}
                className="form-control"
                placeholder="Enter Name or Admission ID"
              />
            </div>
            <div className="col-md-4">
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </form>
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <div id="tableboot">
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th>Adm-Id</th>
                    <th>Student Name</th>
                    <th>Mobile No</th>
                    <th>Class</th>
                    <th>Section</th>
                    <th>
                      <input type="checkbox" style={{ display: "none" }} />
                      <label className="checkbox checkbox-single">
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            allcheckboxChecked();
                          }}
                          checked={checkallcheckbox}
                        />
                        <span />
                      </label>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!!studentsData &&
                    studentsData.map((student, index) => (
                      <tr id="tbl">
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {student.admission_id}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {student.name}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {student.mobile_no}
                        </td>

                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {student?.student_class?.name}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {student?.global_section?.name}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          <input type="checkbox" style={{ display: "none" }} />
                          <label className="checkbox checkbox-single">
                            <input
                              type="checkbox"
                              key={student.id}
                              onClick={(e) => {
                                checkBoxClick(e, student.id);
                              }}
                              checked={student.checked}
                              value={allcheckboxes[index]}
                            />
                            <span />
                          </label>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </Paper>

      <Modal
        title="Message"
        open={open}
        onOk={() => setConfirmModal(true)}
        onCancel={hideModal}
        okButtonProps={{ disabled: buttonDisabled }}
        okText="Send"
        centered
      >
        <div className="row">
          <div className="col-md-12">
            {/* <textarea
              value={messagedata}
              onChange={(e) => setMessageData(e.target.value)}
              rows={4}
              style={{ width: "100%", padding: "5px" }}
              placeholder="Write the Message"
            ></textarea> */}

            <textarea
              rows="4"
              cols="50"
              placeholder="Write the Message..."
              value={studentMessage}
              onChange={handleChange}
              style={{ width: "100%", padding: "5px" }}
            ></textarea>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <h6>Character Count: {characterCount}</h6>
              <h6>Message Count: {messageCount}</h6>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "14px" }}
            >
              <h6 className="blue_color">
                Remaining Messages: {remainingMessage}
              </h6>
            </div>
          </div>
        </div>
      </Modal>
      <Modalw2 show={confirmModal} onHide={() => setConfirmModal(false)}>
        <Modalw2.Header closeButton>
          <Modalw2.Title>Send Message</Modalw2.Title>
        </Modalw2.Header>
        <Modalw2.Body>Are You Sure You Want To Send Message</Modalw2.Body>
        <Modalw2.Footer>
          <Button variant="secondary" onClick={() => setConfirmModal(false)}>
            Close
          </Button>
          <Button
            disabled={buttonDisabled}
            variant="primary"
            onClick={sendMessage}
          >
            Send
          </Button>
        </Modalw2.Footer>
      </Modalw2>
    </>
  );
};

export default StudentMessaging;
