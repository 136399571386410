import React, { useEffect, useState, useRef } from "react";
import { Divider } from "antd";
import { Hidden, LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { DatePicker, Space } from "antd";
import { Spinner, Table, Button, Modal } from "react-bootstrap";
import { upperCase } from "lodash";
const { RangePicker } = DatePicker;

const YearFeeReport = () => {
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  var [logomodel, setlogomodel] = useState("");

  const [sessioning, setallsession] = useState([]);
  const [Start_date, setStartDate] = useState([]);
  const [End_Date, setEndDate] = useState([]);
  var [logomodel1, setlogomodel1] = useState("");
  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };
  var i = 0;

  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    getallsessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getallsessions = async () => {
    await fetch(`${baseUrl}/allsessions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let campuses = [];
        setallsession(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChanger = (day, date) => {
    let startDate = date[0] + "-01";
    let endDate = date[1] + "-01";
    var start = startDate.split("-");
    var end = endDate.split("-");
    //dates.push(startDate);
    setStartDate(startDate);
    setEndDate(endDate);
    //dates1.push(endDate);
    console.log(startDate);
    console.log(endDate);
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
  };
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  const handleShow = () => {
    //yearly-fee-report
    setLoading(true);
    //  fetch(
    //    `${baseUrl}/feepadx?start=${Start_date}&end=${End_Date}`,
    //    {
    //      method: "GET",
    //      headers: {
    //        Authorization: `Bearer ${authtoken}`,
    //      },
    //    }
    //  )
    const data = {
      Start_Date: Start_date,
      End_Date: End_Date,
    };
    fetch(`${baseUrl}/yearly-fee-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data.payload);
        setreportData(data.payload);
        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data.payload);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
    console.log(reportData);
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const divStyle = {
    display: `${logomodel1}`,
  };
  function handleView() {
    console.log(logomodel);
    if (logomodel == false) {
      setlogomodel1("none");
    }
    if (logomodel == true) {
      setlogomodel1("");
    }
    // isFineEnable ? 1 : 0
    //  setlogomodel("");
    console.log(logomodel1);
  }
  function handleNone(event) {
    setlogomodel("none");
    console.log(logomodel);
  }
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {loading ? <LinearProgress /> : null}

        <div className="row mt-3">
          <div className="col-md-4">
            <div>
              <label>
                <b>Select Date Range</b>
              </label>
              <RangePicker
                style={{ height: "40px" }}
                picker="month"
                onChange={(day, date) => handleChanger(day, date)}
              />
            </div>
          </div>
          <div className="col-md-2 mt-9">
            <button onClick={handleShow} className="btn btn-primary">
              Search
            </button>
          </div>
          <div className="col-md-1 mt-9">
            {reportData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`salary slip Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        <Divider />

        <div>
          <Paper
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              padding: "15px",
            }}
          >
            <Table responsive>
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Student Name</th>
                  <th>Father Name</th>
                  <th>Mobile No</th>
                  <th>Monthly Fee</th>
                  <th>Class</th>
                  <th>Months</th>
                  <th>Last Month</th>
                  <th>Fee Paid</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((campus) => (
                  <tr>
                    <td>{(i = i + 1)}</td>
                    <td>{campus.student.name}</td>
                    <td>{campus.student.father_name}</td>
                    <td>{campus.student.mobile_no}</td>

                    <td>
                      {campus.student.student_liable_fees.map(
                        (sitex) => sitex.amount
                      )}
                      {campus.student.student_liable_fees.amount}
                    </td>

                    <td>
                      {campus.student.student_class.name}-
                      {campus.student.global_section.name}
                    </td>
                    <td>{campus.months}</td>
                    <td>
                      {getMonthName(
                        campus.student.fee_challan_details_last.fee_month
                      )}
                    </td>
                    <td>{campus.total}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        </div>

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="mainContainer">
            <div className="logocontainer" style={{ height: "100px" }}>
              <div className="d-flex" style={divStyle}>
                <div className="">
                  <img
                    style={{ height: 100, width: 100, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div style={{ padding: "10px" }}>
                  <h1>{unitName}</h1>
                  <h3>Yearly Fee Progress Report</h3>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              {(() => {
                i = 0;
              })()}
              <Table responsive style={{ fontSize: "13px" }}>
                <thead>
                  <tr>
                    <th width="10%">Sr#</th>
                    <th width="20%">Student Name</th>
                    <th width="20%">Father Name</th>
                    <th width="20%">Mobile No</th>
                    <th width="5%">Fee</th>
                    <th width="20%">Class</th>
                    <th width="2%">Months</th>
                    <th width="10%">Last Month</th>
                    <th width="5%">Paid</th>
                  </tr>
                </thead>
                {reportData.map((campus) => (
                  <tr>
                    <td>{(i = i + 1)}</td>
                    <td>{campus.student.name}</td>
                    <td>{campus.student.father_name}</td>
                    <td>{campus.student.mobile_no}</td>

                    <td>
                      {campus.student.student_liable_fees.map(
                        (sitex) => sitex.amount
                      )}
                      {campus.student.student_liable_fees.amount}
                    </td>

                    <td>
                      {campus.student.student_class.name}-
                      {campus.student.global_section.name}
                    </td>
                    <td>{campus.months}</td>
                    <td>
                      {getMonthName(
                        campus.student.fee_challan_details_last.fee_month
                      )}
                    </td>
                    <td>{campus.total}</td>
                  </tr>
                ))}
              </Table>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default YearFeeReport;
