import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../services/config";
import { Paper } from "@material-ui/core";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import TrialExport from "./TrialExport";
import { upperCase } from "lodash";
const pageStyle = `
  @page {
      margin-left: 0.5in;
    size: A4 portrate !important;
}
`;

const FirmWiseReport = () => {
  const [yearId, setYearId] = useState("");
  const [values, setValues] = useState("");
  const [searchLoading, setsearchLoading] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [yearId1, setYearId1] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [baseAccountData, setBaseAccountData] = useState([]);
  const [accountGroupData, setAccountGroupData] = useState([]);
  const [accountChartData, setAccountChartData] = useState([]);
  const [sessionName, setSessionName] = useState("");
  const [sessionName1, setSessionName1] = useState("");
  const [debitSum, setDebitSum] = useState(0);
  const [banks, setallbanks] = useState([]);
  const [creditSum, setCreditSum] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [contraDebit, setContraDebit] = useState(0);
  const [contraCredit, setContraCredit] = useState(0);
  const [date, setDate] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const renderedRows = [];
  var drValue1 = 0;
  var crValue1 = 0;
  var sumcr = "0";
  var sumdr = "0";
  var crcd = "";
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    sessions();
    getAllBanks();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleInput(event) {
    setValues(event.target.value);
    setSessionName1(event.target.selectedOptions[0].text);
    console.log(values);
  }
  const getAllBanks = async () => {
    // await fetch(`${baseUrl}/bankr`, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     let campuses = [];
    //     setallbanks(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        //  if (selectedbankcateogry != "") {
        //    let anserr = data.payload.filter(
        //      (item) => item.id == selectedbankcateogry
        //    );

        //    if (anserr.length != 0) {
        //      setallsubaccounts([...anserr[0].bank_accounts]);
        //    } else {
        //      setallsubaccounts([]);
        //    }
        //  }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
            setYearId1(session.id + 1);
            console.log(session);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetData = () => {
    if (yearId == "") {
      alert.error("Please select the year");
      return;
    }
    setLoading(true);
    setsearchLoading(true);
    setbuttonDisable(true);
    const data = {
      year_id: yearId,
      date: date,
    };
    fetch(`${baseUrl}/firmview?year_id=${yearId}&bank_id=${values}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setsearchLoading(false);
        setbuttonDisable(false);
        setIsVisible(true);
        console.log(data.results);

        setReportData(data.results);
        setBaseAccountData(data.BaseAccount);
        setAccountGroupData(data.AccountGroup);
        setAccountChartData(data.AccountChart);

        let sum = 0;
        let sum2 = 0;
        data.forEach((element) => {
          if (element.cr === element.dr) {
            return null; // Skip rendering
          }

          sum += parseInt(element.cr);

          sum2 += parseInt(element.dr);
        });
        setCreditSum(sum);
        setDebitSum(sum2);

        console.log("this is data", data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const calckNumberDebit = (data) => {
    const totalDebit = data.reduce((a, c) => a + parseInt(c.debit), 0);
    const totalCredit = data.reduce((a, c) => a + parseInt(c.credit), 0);
    if (totalDebit > totalCredit) {
      return Number(totalDebit) - Number(totalCredit);
    } else {
      return 0;
    }
  };
  const calckNumberCredit = (data) => {
    const totalDebit = data.reduce((a, c) => a + parseInt(c.debit), 0);
    const totalCredit = data.reduce((a, c) => a + parseInt(c.credit), 0);
    if (totalCredit > totalDebit) {
      return Number(totalCredit) - Number(totalDebit);
    } else {
      return 0;
    }
  };

  const mainNumberCalc = (data) => {
    let totalDebit = 0;
    let totalCredit = 0;
    data.map((item) => {
      item.sub_account.map((last) => {
        totalDebit += parseInt(last.debit);
      });
      item.sub_account.map((last) => {
        totalCredit += parseInt(last.credit);
      });
    });
    if (totalDebit > totalCredit) {
      return `Total Lose ${totalDebit - totalCredit}`;
    } else {
      return `Total Profit ${totalCredit - totalDebit}`;
    }
  };
  const buildHierarchy = () => {
    // const { base_accounts, account_groups, account_charts } = data;

    return baseAccountData.map((baseAccount) => {
      const groups = accountGroupData.filter(
        (group) => group.base_account_id === baseAccount.id
      );
      const nestedGroups = groups
        .map((group) => {
          const charts = accountChartData.filter(
            (chart) => chart.account_group_id === group.id
          );
          const chartsWithSubAccounts = charts
            .map((chart) => {
              const subAccounts = reportData.filter(
                (ledger) =>
                  ledger.sub_account &&
                  ledger.sub_account.account_chart_id === chart.id
              );

              const subAccountsWithDifference = subAccounts.map(
                (subAccount) => {
                  const difference = subAccount.cr - subAccount.dr;
                  return { ...subAccount, difference };
                }
              );

              const hasLedgerData = subAccountsWithDifference.length > 0;
              return hasLedgerData
                ? { ...chart, sub_accounts: subAccountsWithDifference }
                : null;
            })
            .filter((chart) => chart !== null); // Filter out null charts (without sub-accounts)

          const hasLedgerData = chartsWithSubAccounts.length > 0;
          return hasLedgerData
            ? { ...group, account_charts: chartsWithSubAccounts }
            : null;
        })
        .filter((group) => group !== null); // Filter out null groups (without ledger data)

      return { ...baseAccount, account_groups: nestedGroups };
    });
  };

  const calculateSum = (items, key) => {
    return items.reduce((sum, item) => sum + (item[key] || 0), 0);
  };

  const calculateTotalProfitLoss = (items) => {
    const total = calculateSum(items, "difference");
    return {
      amount: total,
      type: total >= 0 ? "CR" : "DR",
    };
  };

  const hierarchicalData = buildHierarchy();

  const totalProfitLoss = calculateTotalProfitLoss(
    hierarchicalData.flatMap((baseAccount) =>
      baseAccount.account_groups.flatMap((group) =>
        group.account_charts.flatMap((chart) => chart.sub_accounts)
      )
    )
  );
  // const buildHierarchy = () => {
  //   // const { base_accounts, account_groups, account_charts } = data;

  //   return baseAccountData.map((baseAccount) => {
  //     const groups = accountGroupData.filter(
  //       (group) => group.base_account_id === baseAccount.id
  //     );
  //     const nestedGroups = groups
  //       .map((group) => {
  //         const charts = accountChartData.filter(
  //           (chart) => chart.account_group_id === group.id
  //         );
  //         const chartsWithSubAccounts = charts
  //           .map((chart) => {
  //             const subAccounts = reportData.filter(
  //               (ledger) =>
  //                 ledger.sub_account &&
  //                 ledger.sub_account.account_chart_id === chart.id
  //             );

  //             const subAccountsWithDifference = subAccounts.map(
  //               (subAccount) => {
  //                 const difference = subAccount.cr - subAccount.dr;
  //                 return { ...subAccount, difference };
  //               }
  //             );

  //             const hasLedgerData = subAccountsWithDifference.length > 0;
  //             return hasLedgerData
  //               ? { ...chart, sub_accounts: subAccountsWithDifference }
  //               : null;
  //           })
  //           .filter((chart) => chart !== null); // Filter out null charts (without sub-accounts)

  //         const hasLedgerData = chartsWithSubAccounts.length > 0;
  //         return hasLedgerData
  //           ? { ...group, account_charts: chartsWithSubAccounts }
  //           : null;
  //       })
  //       .filter((group) => group !== null); // Filter out null groups (without ledger data)

  //     return { ...baseAccount, account_groups: nestedGroups };
  //   });
  // };

  // const calculateSum = (items, key) => {
  //   return items.reduce((sum, item) => sum + (item[key] || 0), 0);
  // };

  // const hierarchicalData = buildHierarchy();
  // const buildHierarchy = () => {
  //   // const { base_accounts, account_groups, account_charts } = data;

  //   return baseAccountData.map((baseAccount) => {
  //     const groups = accountGroupData.filter(
  //       (group) => group.base_account_id === baseAccount.id
  //     );
  //     const nestedGroups = groups.map((group) => {
  //       const charts = accountChartData.filter(
  //         (chart) => chart.account_group_id === group.id
  //       );
  //       const chartsWithSubAccounts = charts.map((chart) => {
  //         const subAccounts = reportData.filter(
  //           (ledger) => ledger.sub_account_id === chart.id
  //         );
  //         const subAccountsWithDifference = subAccounts.map((subAccount) => {
  //           const difference = subAccount.cr - subAccount.dr;
  //           return { ...subAccount, difference };
  //         });
  //         return { ...chart, sub_accounts: subAccountsWithDifference };
  //       });
  //       return { ...group, account_charts: chartsWithSubAccounts };
  //     });
  //     return { ...baseAccount, account_groups: nestedGroups };
  //   });
  // };

  // const hierarchicalData = buildHierarchy();

  // const buildHierarchy = () => {
  //   // const { base_accounts, account_groups, account_charts } = data;

  //   return baseAccountData.map((baseAccount) => {
  //     const groups = accountGroupData.filter(
  //       (group) => group.base_account_id === baseAccount.id
  //     );
  //     const nestedGroups = groups.map((group) => {
  //       const charts = accountChartData.filter(
  //         (chart) => chart.account_group_id === group.id
  //       );
  //       const chartsWithLedger = charts.map((chart) => {
  //         const ledger =
  //           reportData.find((l) => l.sub_account_id === chart.id) || {};
  //         const difference = (ledger.cr || 0) - (ledger.dr || 0);
  //         return { ...chart, ledger, difference };
  //       });
  //       return { ...group, account_charts: chartsWithLedger };
  //     });
  //     return { ...baseAccount, account_groups: nestedGroups };
  //   });
  // };

  // const hierarchicalData = buildHierarchy();
  // const buildHierarchy = () => {
  // Assuming the response structure is something like:
  // {
  //   base_accounts: [...],
  //   account_groups: [...],
  //   account_charts: [...]
  // }

  //   return baseAccountData.map((baseAccount) => {
  //     const groups = accountGroupData.filter(
  //       (group) => group.base_account_id === baseAccount.id
  //     );
  //     const nestedGroups = groups.map((group) => {
  //       const charts = accountChartData.filter(
  //         (chart) => chart.account_group_id === group.id
  //       );
  //       return { ...group, account_charts: charts };
  //     });
  //     return { ...baseAccount, account_groups: nestedGroups };
  //   });
  // };

  // const hierarchicalData = buildHierarchy();
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3 ">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => {
                  const data = sessionData.find((ses) => {
                    return ses.id == e.target.value;
                  });
                  console.log(data);
                  setSessionName(data.year);
                  setYearId(e.target.value);
                }}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-3 ">
            <label>
              <b>Select Bank</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                class="form-control"
                onChange={(e) => {
                  handleInput(e);
                }}
                name="bank2"
                id="bank2"
                required
              >
                <option value="">Select Bank</option>
                {banks.map((campus) => (
                  <option value={campus.id}>{campus.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 mt-9">
            <button
              className="btn btn-primary"
              disabled={buttonDisable}
              onClick={handleGetData}
            >
              Show
            </button>

            {reportData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Firm Vise Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                pageStyle={pageStyle}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-primary mx-2 w-105px"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        <div className="mt-5">
          {isVisible ? (
            <div id="tableboot mt-4">
              <div className="d-flex">
                <div className="">
                  <img
                    style={{ height: 100, width: 110, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="mx-5" style={{ paddingTop: "10px" }}>
                  <h1>{unitName}</h1>
                  <h3>Firm Wise Report For The Financial Year {sessionName}</h3>
                </div>
              </div>
              <Table>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Acode</th>
                    <th style={{ textAlign: "center" }}>Title</th>
                    <th>Group Total</th>
                    <th style={{ textAlign: "center" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {hierarchicalData.map((baseAccount) => {
                    const baseAccountSum = calculateSum(
                      baseAccount.account_groups.flatMap((group) =>
                        group.account_charts.flatMap(
                          (chart) => chart.sub_accounts
                        )
                      ),
                      "difference"
                    );
                    const baseAccountProfitLoss =
                      baseAccountSum >= 0 ? "CR" : "DR";

                    return (
                      <React.Fragment key={baseAccount.id}>
                        <tr>
                          <td>
                            <b>
                              {" "}
                              {baseAccount.acode}-{baseAccount.title}
                            </b>
                          </td>
                          <td colSpan="2"></td>
                          <td>
                            <b>
                              {Math.abs(baseAccountSum)}-{baseAccountProfitLoss}
                            </b>
                          </td>
                        </tr>
                        {baseAccount.account_groups.map((group) => {
                          const groupSum = calculateSum(
                            group.account_charts.flatMap(
                              (chart) => chart.sub_accounts
                            ),
                            "difference"
                          );
                          const groupProfitLoss = groupSum >= 0 ? "CR" : "DR";

                          return (
                            <React.Fragment key={group.id}>
                              <tr>
                                <td></td>
                                <td style={{ textAlign: "left" }}>
                                  <b>
                                    {group.acode}-{group.title}{" "}
                                  </b>
                                </td>
                                <td></td>
                                <td>
                                  <b>
                                    {" "}
                                    {Math.abs(groupSum)}-{groupProfitLoss}
                                  </b>
                                </td>
                              </tr>
                              {group.account_charts.map((chart) => {
                                const chartSum = calculateSum(
                                  chart.sub_accounts,
                                  "difference"
                                );
                                const chartProfitLoss =
                                  chartSum >= 0 ? "CR" : "DR";

                                return (
                                  <React.Fragment key={chart.id}>
                                    <tr>
                                      <td></td>

                                      <td
                                        style={{
                                          textAlign: "left",
                                        }}
                                      >
                                        <b style={{ marginLeft: "100px" }}>
                                          {chart.acode}-{chart.title}
                                        </b>
                                      </td>

                                      <td>
                                        <b>
                                          {" "}
                                          {Math.abs(chartSum)}-{chartProfitLoss}{" "}
                                        </b>
                                      </td>
                                      <td></td>
                                    </tr>
                                    {chart.sub_accounts.map((subAccount) => (
                                      <tr key={subAccount.id}>
                                        <td></td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                          }}
                                        >
                                          <label
                                            style={{ marginLeft: "150px" }}
                                          ></label>
                                          {subAccount.sub_account
                                            ? subAccount.sub_account.acode
                                            : ""}
                                          -
                                          {subAccount.sub_account
                                            ? subAccount.sub_account.title
                                            : ""}
                                        </td>

                                        <td>
                                          {Math.abs(subAccount.difference)}
                                        </td>
                                        <td></td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                  <tr>
                    <td colSpan="3">Total Profit/Loss</td>
                    <td>
                      {Math.abs(totalProfitLoss.amount)}-{totalProfitLoss.type}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
        {/* <Table>
            <thead>
              <tr>
                <th>Group Acode</th>
                <th>Chart Acode</th>
                <th>Chart Acode</th>
                <th>Sub Account Acode</th>
                <th>Sub Account Title</th>

                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {hierarchicalData.map((baseAccount) => (
                <React.Fragment key={baseAccount.id}>
                  <tr>
                    <td>
                      {baseAccount.acode}-{baseAccount.title}
                    </td>
                    <td colSpan="4"></td>
                    <td>
                      {calculateSum(
                        baseAccount.account_groups.flatMap((group) =>
                          group.account_charts.flatMap(
                            (chart) => chart.sub_accounts
                          )
                        ),
                        "difference"
                      )}
                    </td>
                  </tr>
                  {baseAccount.account_groups.map((group) => (
                    <React.Fragment key={group.id}>
                      <tr>
                        <td></td>
                        <td>
                          {group.acode}-{group.title}
                        </td>
                        <td colSpan="3"></td>
                        <td>
                          {calculateSum(
                            group.account_charts.flatMap(
                              (chart) => chart.sub_accounts
                            ),
                            "difference"
                          )}
                        </td>
                      </tr>
                      {group.account_charts.map((chart) => (
                        <React.Fragment key={chart.id}>
                          <tr>
                            <td colSpan="3"></td>
                            <td>
                              {chart.acode}-{chart.title}
                            </td>
                            <td colSpan="2"></td>
                            <td>
                              {calculateSum(chart.sub_accounts, "difference")}
                            </td>
                          </tr>
                          {chart.sub_accounts.map((subAccount) => (
                            <tr key={subAccount.id}>
                              <td colSpan="4"></td>

                              <td>
                                {subAccount.sub_account
                                  ? subAccount.sub_account.acode
                                  : ""}
                                -
                                {subAccount.sub_account
                                  ? subAccount.sub_account.title
                                  : ""}
                              </td>
                              <td>{subAccount.difference}</td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table> */}
      </Paper>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot mt-4">
          <div className="d-flex">
            <div className="">
              <img
                style={{ height: 100, width: 120, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="mx-5" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <h3>Firm Wise Report For The Financial Year {sessionName}</h3>
              {/*(() => {
                const ytext = document.getElementById("bank2");
                crcd = ytext.options[ytext.selectedIndex].text;
              })()*/}
              <h3>
                {/*document.getElementById("bank2").options.text*/}
                Firm: {sessionName1}
              </h3>
            </div>
          </div>
          <Table>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "0px",
                    fontSize: "17px",
                    textAlign: "center",
                  }}
                >
                  Acode
                </th>
                <th style={{ padding: "0px", fontSize: "17px" }}>Title</th>
                <th
                  style={{
                    padding: "0px",
                    fontSize: "17px",
                    textAlign: "center",
                  }}
                >
                  Group Total
                </th>
                <th
                  style={{
                    padding: "0px",
                    fontSize: "17px",
                    textAlign: "center",
                  }}
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {hierarchicalData.map((baseAccount) => {
                const baseAccountSum = calculateSum(
                  baseAccount.account_groups.flatMap((group) =>
                    group.account_charts.flatMap((chart) => chart.sub_accounts)
                  ),
                  "difference"
                );
                const baseAccountProfitLoss =
                  baseAccountSum >= 0 ? "Profit" : "Loss";

                return (
                  <React.Fragment key={baseAccount.id}>
                    <tr>
                      <td style={{ padding: "0px", fontSize: "15px" }}>
                        <b>
                          {" "}
                          {baseAccount.acode}-{baseAccount.title}
                        </b>
                      </td>
                      <td
                        colSpan="2"
                        style={{ padding: "0px", fontSize: "15px" }}
                      ></td>
                      <td style={{ padding: "0px", fontSize: "15px" }}>
                        <b>
                          {Math.abs(baseAccountSum)}-{baseAccountProfitLoss}
                        </b>
                      </td>
                    </tr>
                    {baseAccount.account_groups.map((group) => {
                      const groupSum = calculateSum(
                        group.account_charts.flatMap(
                          (chart) => chart.sub_accounts
                        ),
                        "difference"
                      );
                      const groupProfitLoss = groupSum >= 0 ? "CR" : "DR";

                      return (
                        <React.Fragment key={group.id}>
                          <tr>
                            <td
                              style={{ padding: "0px", fontSize: "15px" }}
                            ></td>
                            <td
                              style={{
                                padding: "0px",
                                fontSize: "15px",
                                textAlign: "left",
                              }}
                            >
                              <b>
                                {group.acode}-{group.title}{" "}
                              </b>
                            </td>
                            <td></td>
                            <td style={{ padding: "0px", fontSize: "15px" }}>
                              <b>
                                {" "}
                                {Math.abs(groupSum)}-{groupProfitLoss}
                              </b>
                            </td>
                          </tr>
                          {group.account_charts.map((chart) => {
                            const chartSum = calculateSum(
                              chart.sub_accounts,
                              "difference"
                            );
                            const chartProfitLoss = chartSum >= 0 ? "CR" : "DR";

                            return (
                              <React.Fragment key={chart.id}>
                                <tr>
                                  <td></td>

                                  <td
                                    style={{
                                      textAlign: "left",
                                      padding: "0px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <b style={{ marginLeft: "100px" }}>
                                      {chart.acode}-{chart.title}
                                    </b>
                                  </td>

                                  <td
                                    style={{ padding: "0px", fontSize: "15px" }}
                                  >
                                    <b>
                                      {" "}
                                      {Math.abs(chartSum)}-{chartProfitLoss}{" "}
                                    </b>
                                  </td>
                                  <td></td>
                                </tr>
                                {chart.sub_accounts.map((subAccount) => (
                                  <tr key={subAccount.id}>
                                    <td></td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        padding: "0px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <label
                                        style={{ marginLeft: "150px" }}
                                      ></label>
                                      {subAccount.sub_account
                                        ? subAccount.sub_account.acode
                                        : ""}
                                      -
                                      {subAccount.sub_account
                                        ? subAccount.sub_account.title
                                        : ""}
                                    </td>

                                    <td
                                      style={{
                                        padding: "0px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {Math.abs(subAccount.difference)}
                                    </td>
                                    <td></td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
              <tr>
                <td style={{ padding: "0px", fontSize: "15px" }} colSpan="3">
                  <b> Total Profit/Loss</b>
                </td>
                <td style={{ padding: "0px", fontSize: "15px" }}>
                  <b>
                    {" "}
                    {Math.abs(totalProfitLoss.amount)}-{totalProfitLoss.type}
                  </b>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default FirmWiseReport;
