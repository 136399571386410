import React, { useEffect, useState } from "react";
// import { PeopleAltOutlined, Payment, Apartment } from '@material-ui/icons';
// import { useHistory } from 'react-router-dom';
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
// import AddchartIcon from '@mui/icons-material/Addchart';
import Paper from "@material-ui/core/Paper";
import {
  Table,
  Modal,
  Spinner,
  Alert,
  Form,
  ModalFooter,
  Badge,
} from "react-bootstrap";
// import makeAnimated from 'react-select/animated';
// import { components } from 'react-select';
// import MySelect from './../../CustomChallan/MySelect';
// import DatePicker from 'react-multi-date-picker';
// import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import "./DateSheetStyle.css";
import { date } from "yup";
import { useSelector } from "react-redux";

const ExamList = ({
  show,
  onHide,
  selectedclassfordatesheet,
  examid,
  showdetailmodal,
}) => {
  // const today = new Date();
  // const tomorrow = new Date();

  // const history = useHistory();
  // const alert = useAlert();
  // configgration
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  //arrays
  // const [values, setValues] = useState([]);
  // console.log('values==>', values);

  // const [dates, setDates] = useState([
  //     new Date(),
  //     new DateObject({ year: 2020, month: 9, day: 8 }),
  //     "December 09 2020",
  //     1597994736000 //unix time in milliseconds (August 21 2020)
  // ])
  // const [dates, setDates] = useState([]);

  // const [allexamslist, setallexamslist] = useState([]);
  // const [examslist, setexamslist] = useState([]);
  // const [allsessions, setallsessions] = useState([]);
  const [allclasses, setallclasses] = useState([]);
  const [declaredexamlist, setdeclaredexamlist] = useState([]);
  const [session, setSession] = useState([]);
  const [datesheetlist, setdatesheetlist] = useState([]);
  const [selectedcampus, setselectedcampus] = useState("");
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [allexamslist, setallexamslist] = useState([]);
  const [loading, setLoading] = useState(false);
  //objects

  //booleans
  // const [isallfields, setisallfields] = useState(false);
  // const [loading, setloading] = useState(false);
  // const [loader, setloader] = useState(false);
  // const [datasheetmodal, setdatasheetmodal] = useState(false);
  //strings
  const [educationType, setEducationType] = useState(1);

  const [selectedexam, setselectedexam] = useState("");
  const [selectedclass, setselectedclass] = useState("");
  const [selectedyear, setselectedyear] = useState("");
  const [selectedExamType, setselectedExamType] = useState("");
  const alert = useAlert();
  //functions

  useEffect(() => {
    // getAllDeclaredExams();
    getAllYear();
    // getAllCampus();
  }, []);

  const getAllYear = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Data", data);
        setSession([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setselectedyear(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    console.log("campus geeting");
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  //   const getAllDeclaredExams = () => {
  //     fetch(`${baseUrl}/exam`, {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${authtoken}`,
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         console.log('Data', data);
  //         setdeclaredexamlist([...data.payload]);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const getDateSheetHandler = () => {
    if (selectedcampus == "" || selectedExamType == "" || selectedclass == "") {
      return alert.error("Please select all fields");
    }
    setLoading(true);
    let data = {
      campus_id: selectedcampus,
      exam_id: selectedExamType,
      student_class_id: selectedclass,
      // education_type:educationType,
    };

    fetch(`${baseUrl}/exam_class_datesheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log("Data", data);
        setdatesheetlist(data.payload);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //multiselect configration
  // const Options = [];
  // allsubjects.map((item, index) => {

  //     Options[index] = { value: item.id, label: item.name };

  // })

  //   const animatedComponents = makeAnimated();
  //   const Option = (props) => {
  //     return (
  //       <div>
  //         <components.Option {...props}>
  //           <input type='checkbox' checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
  //         </components.Option>
  //       </div>
  //     );
  //   };
  //   const MultiValue = (props) => (
  //     <components.MultiValue {...props}>
  //       <span>{props.data.label}</span>
  //     </components.MultiValue>
  //   );

  const onCampusChange = async (id) => {
    setselectedExamType("");
    setselectedclass("");
    setselectedcampus(id);
    getAllExamsList(id);
  };
  const getAllExamsList = (id) => {
    const data = {
      year_id: selectedyear,
      campus_id: id,
    };

    fetch(`${baseUrl}/exam_for_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("get exam", data);

        setallexamslist([...data.payload]);
        // getassignedExamsList(data.payload)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div>
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {/* Select Year */}

            <div className="d-flex flex-column col-sm-3">
              <label>
                <b>Select Session</b>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={selectedyear}
                onChange={(e) => {
                  setselectedyear(e.target.value);
                  //   getDateSheetHandler(e.target.value, selectedexam);
                }}
                aria-label="Default select example"
              >
                <option selected value="">
                  {" "}
                  Select Session
                </option>
                {session.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.year}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-3">
              <label>
                <b>Select Campus</b>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                value={selectedcampus}
                onChange={(e) => onCampusChange(e.target.value)}
                aria-label="Default select example"
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campuses.map((campus, index) => (
                  <option key={index} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Select Exam */}
            <div className="col-md-3">
              <label>
                <b>Select Exam</b>
              </label>
              <select
                onChange={(e) => {
                  setselectedclass("");
                  setselectedExamType(e.target.value);
                  let finded = allexamslist.find(
                    (item) => item.id == e.target.value
                  );
                  setallclasses(finded.student_classes);
                }}
                value={selectedExamType}
                class="form-select"
                style={{ height: 40 }}
                // value={currentsession}
                aria-label="Default select example"
              >
                <option value="">Select Exam</option>
                {allexamslist.map((exam) => (
                  <option
                    // selected={exam.active_academic_year == "1" ? true : false}
                    value={exam.id}
                  >
                    {exam?.exam_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="d-flex flex-column col-sm-3">
              <label>
                <b>Select Class</b>
              </label>
              <select
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                value={selectedclass}
                onChange={(e) => {
                  setselectedclass(e.target.value);
                }}
                aria-label="Default select example"
              >
                <option selected value="">
                  {" "}
                  Select Class
                </option>
                {allclasses.map((clas, index) => (
                  <option key={index} value={clas.id}>
                    {clas.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2 mt-5">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "150px",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    class="text-center"
                    variant="info"
                    animation="border"
                  />
                </div>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={getDateSheetHandler}
                >
                  Show
                </button>
              )}
            </div>
            {/* <div className="col-md-4 mt-3">
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => setEducationType(e.target.value)}
            >
              <option  value="">
                {" "}
                Select Education Type
              </option>
                    <option selected value={1}>
                    Education
                    </option>
                    <option value={2}>
                    Hifz
                    </option>
            </select>
          </div> */}
          </div>
          <br />
          <br />
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th>ID</th>
                <th>Date</th>
                <th>Subject</th>
                <th>Class Name</th>
                {/* <th>Note</th> */}
              </tr>
            </thead>

            <tbody>
              {datesheetlist.subjects?.map((item, index) => (
                <tr id="tbl">
                  <td style={{ color: "black" }} className="text_align_start">
                    {item.id}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {item.date}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {item.subject.name}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {datesheetlist.student_class.name}
                  </td>
                  {/* <td style={{ color: 'black' }} className='text_align_start'></td> */}
                  {/* <td style={{ color: 'black' }} className='text_align_start'></td>
                  <td style={{ color: 'black' }} className='text_align_start'></td>
                  <td style={{ color: 'black' }} className='text_align_start'></td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Paper>
    </div>
  );
};

export default ExamList;
