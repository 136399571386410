import React from 'react'
import { Table } from 'react-bootstrap'

const ViewStafStudents = ({studentDetails}) => {
    console.log(studentDetails)
  return (
    <>
      <Table responsive>
          <thead>
            <tr className='text-center'>
              <th style={{ textAlign: 'start' }}>Name</th>
              <th style={{ textAlign: 'start' }}>Father Name</th>
              <th style={{ textAlign: 'start' }}>Campus</th>
              <th style={{ textAlign: 'start' }}>Class</th>
              <th style={{ textAlign: 'start' }}>Admission No</th>
              <th style={{ textAlign: 'start' }}>Registration No</th>
              <th style={{ textAlign: 'start' }}>Mobile No</th>
              <th style={{ textAlign: 'start' }}>Gender</th>
            </tr>
          </thead>
          <tbody>
            {studentDetails?.map((item) => (
              <tr key={item.id}>
             
                {/* <td style={{ textAlign: "start" }}>{staf.full_name}</td>
                                    <td style={{ textAlign: "start" }}>{staf.email}</td> */}

                <td  style={{ textAlign: 'start', cursor: 'pointer' }}>
                  {item.name}
                </td>
                <td  style={{ textAlign: 'start', cursor: 'pointer' }}>
                  {item.father_name}
                </td>
                <td  style={{ textAlign: 'start', cursor: 'pointer' }}>
                  {item?.campus?.name}
                </td>
                <td  style={{ textAlign: 'start', cursor: 'pointer' }}>
                  {item?.student_class?.name}
                </td>
                <td  style={{ textAlign: 'start', cursor: 'pointer' }}>
                  {item?.admission_id}
                </td>
                <td  style={{ textAlign: 'start', cursor: 'pointer' }}>
                  {item?.registration_id}
                </td>
                <td  style={{ textAlign: 'start', cursor: 'pointer' }}>
                  {item?.mobile_no}
                </td>
                <td  style={{ textAlign: 'start', cursor: 'pointer' }}>
                  {item?.gender}
                </td>
       
              </tr>
            ))}
          </tbody>
        </Table>
    </>
  )
}

export default ViewStafStudents