import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import '../styles.css'
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import "./myStyle.css";
import "./myprinter.css";
import { baseUrl } from "../../../services/config";
import { upperCase } from "lodash";

export default function PrintStudentWise() {
  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  //print states
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const feechallans = useSelector(
    (state) => state.campuses.studentWiseChallans
  );
  console.log("ye he to challans hain", feechallans);

  const getFeeAmount = (challans) => {
    let sum = 0;
    challans.forEach((element) => {
      sum += parseInt(element.amount);
    });

    return sum;
    // const sum = challans.reduce((accumulator, object) => {
    //   return accumulator + object.amount;
    // }, 0);
    // console.log(sum)
  };
  const getIssueDate = (arr) => {
    // let result = Math.max(...arr.map(o => o.challan_no))
    // const result = arr.reduce((p, c) => (p.challan_no > c.challan_no ? p : c));

    const result = arr[arr.length - 1];
    // console.log(result);
    return result;
  };

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const getMonthName3 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };
  const getMonthName2 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   const pageStyle = `
  // @page {
  //   size:letter portrait !important;
  // }
  // `;
  return (
    <div>
      <div style={{ position: "fixed", right: "8px", top: "20%" }}>
        <ReactToPrint
          // pageStyle={pageStyle}
          documentTitle={`Challan Copy of ${
            feechallans ? feechallans.length : 0
          }`}
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint={true}
          trigger={() => (
            <button
              disabled={loading ? true : false}
              className="btn btn-success button_blue button_blue"
            >
              {loading ? <span>Printing..</span> : "Print"}
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div>
        {feechallans.map((item) => (
          <div className="row-printer">
            <div className="main__voucher">
              <div className="voucher">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <img
                    src={icon}
                    style={{ height: 100, width: 100 }}
                    alt="icon"
                  />
                  <h3 style={{ color: "#B4959D", width: "40%" }}>{unitName}</h3>
                </div>
                <div className="redline"></div>
                <div className="bolsection">
                  <div>
                    <div>Issue Date:</div>
                    <div>Due Date :</div>
                    <div>
                      {" "}
                      {item?.admission_id == null
                        ? "Registration Id:"
                        : "Admission No:"}
                    </div>
                    <div>Name:</div>
                    <div style={{ paddingTop: "15px" }}>Class:</div>
                  </div>
                  <div>
                    <div>{getIssueDate(item?.fee_challans).issue_date}</div>
                    <div>{getIssueDate(item?.fee_challans).due_date}</div>
                    <div>
                      {item?.admission_id == null
                        ? item?.registration_id
                        : item?.admission_id}
                    </div>
                    <div>{item?.name}</div>
                    <div>{item?.student_class_name}</div>
                  </div>
                </div>

                {item?.fee_challans_detials &&
                  item?.fee_challans_detials.map((copy, index) => {
                    return (
                      <div>
                        <div className="bolsection printchallanfeesection">
                          <div style={{ fontSize: "smaller" }}>
                            {copy?.fee_type_id == "23"
                              ? "REMAINING BALANCE"
                              : copy?.fee_name}
                          </div>
                          <div style={{ fontSize: "smaller" }}>
                            {copy?.amount}
                          </div>
                          {/* <div style={{ fontSize: "smaller" }}>
                                     {copy.fee_month}
                                   </div> */}
                        </div>
                      </div>
                    );
                  })}

                <div className="lastsection">
                  <h5>
                    Total Amount: {getFeeAmount(item?.fee_challans_detials)}{" "}
                  </h5>
                </div>
                <div>
                  <Barcode value={item?.fee_challans_detials[0]?.challan_no} />,
                </div>
              </div>
            </div>

            {/* 2nd voucher */}
            <div className="main__voucher">
              <div className="voucher">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <img
                    src={icon}
                    style={{ height: 100, width: 100 }}
                    alt="icon"
                  />
                  <h3 style={{ color: "#B4959D", width: "40%" }}>{unitName}</h3>
                </div>
                <div className="redline"></div>
                <div className="bolsection">
                  <div>
                    <div>Issue Date:</div>
                    <div>Due Date :</div>
                    <div>
                      {" "}
                      {item?.admission_id == null
                        ? "Registration Id:"
                        : "Admission No:"}
                    </div>
                    <div>Name:</div>
                    <div>Class:</div>
                  </div>
                  <div>
                    <div>{getIssueDate(item?.fee_challans).issue_date}</div>
                    <div>{getIssueDate(item?.fee_challans).due_date}</div>
                    <div>
                      {item?.admission_id == null
                        ? item?.registration_id
                        : item?.admission_id}
                    </div>
                    <div>{item?.name}</div>
                    <div>{item?.student_class_name}</div>
                  </div>
                </div>

                {item?.fee_challans_detials &&
                  item?.fee_challans_detials.map((copy, index) => {
                    return (
                      <div>
                        <div className="bolsection printchallanfeesection">
                          <div style={{ fontSize: "smaller" }}>
                            {copy?.fee_type_id == "23"
                              ? "REMAINING BALANCE"
                              : copy?.fee_name}
                          </div>
                          <div style={{ fontSize: "smaller" }}>
                            {copy?.amount}
                          </div>
                          {/* <div style={{ fontSize: "smaller" }}>
                                     {copy.fee_month}
                                   </div> */}
                        </div>
                      </div>
                    );
                  })}

                <div className="lastsection">
                  <h5>
                    Total Amount: {getFeeAmount(item?.fee_challans_detials)}{" "}
                  </h5>
                </div>
                <div>
                  <Barcode value={item?.fee_challans_detials[0]?.challan_no} />,
                </div>
              </div>
            </div>
            {/* 3rd voucher */}
            <div className="main__voucher">
              <div className="voucher">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <img
                    src={icon}
                    style={{ height: 100, width: 100 }}
                    alt="icon"
                  />
                  <h3 style={{ color: "#B4959D", width: "40%" }}>{unitName}</h3>
                </div>
                <div className="redline"></div>
                <div className="bolsection">
                  <div>
                    <div>Issue Date:</div>
                    <div>Due Date :</div>
                    <div>
                      {" "}
                      {item?.admission_id == null
                        ? "Registration Id:"
                        : "Admission No:"}
                    </div>
                    <div>Name:</div>
                    <div>Class:</div>
                  </div>
                  <div>
                    <div>{getIssueDate(item?.fee_challans).issue_date}</div>
                    <div>{getIssueDate(item?.fee_challans).due_date}</div>
                    <div>
                      {item?.admission_id == null
                        ? item?.registration_id
                        : item?.admission_id}
                    </div>
                    <div>{item?.name}</div>
                    <div>{item?.student_class_name}</div>
                  </div>
                </div>

                {item?.fee_challans_detials &&
                  item?.fee_challans_detials.map((copy, index) => {
                    return (
                      <div>
                        <div className="bolsection printchallanfeesection">
                          <div style={{ fontSize: "smaller" }}>
                            {copy?.fee_type_id == "23"
                              ? "REMAINING BALANCE"
                              : copy?.fee_name}
                          </div>
                          <div style={{ fontSize: "smaller" }}>
                            {copy?.amount}
                          </div>
                          {/* <div style={{ fontSize: "smaller" }}>
                                     {copy.fee_month}
                                   </div> */}
                        </div>
                      </div>
                    );
                  })}

                <div className="lastsection">
                  <h5>
                    Total Amount: {getFeeAmount(item?.fee_challans_detials)}{" "}
                  </h5>
                </div>
                <div>
                  <Barcode value={item?.fee_challans_detials[0]?.challan_no} />,
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Autucal print */}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        {feechallans.map((selectedStudent, i) => (
          <>
            <div className="mainPrintDiv">
              <div className="secondmain">
                <div className="myprintBox1">
                  <div className="text-center">
                    {selectedStudent?.campus?.print_account_no &&
                      selectedStudent?.campus?.print_account_no.map((acc) => (
                        <>
                          <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                          <br />
                        </>
                      ))}

                    <b className="mytextfont">{selectedStudent.campus.name}</b>
                    <br></br>
                  </div>

                  <div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Due Date:</b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>
                          {getMonthName2(
                            getIssueDate(selectedStudent?.fee_challans).due_date
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">
                          Issue Date:
                        </b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>
                          {getMonthName2(
                            getIssueDate(selectedStudent?.fee_challans)
                              .issue_date
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Admission:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          <b>
                            {selectedStudent.admission_id == null
                              ? selectedStudent.registration_id
                              : selectedStudent.admission_id}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Name:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent.name}
                          <br></br>
                          {selectedStudent.gender?.toLowerCase() == "male"
                            ? "s/o"
                            : "d/o"}{" "}
                          {selectedStudent?.father_name}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Class:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent?.education_type == 2 ? "Hifz-" : ""}
                          {selectedStudent.student_class_name +
                            " (" +
                            selectedStudent?.global_section_name +
                            ")"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="endContent2">
                    <div className="endconheading2">
                      <div>
                        <b>Inv No.</b>
                      </div>
                      <div>
                        <b>Description.</b>
                      </div>
                      <div>
                        <b>Amount.</b>
                      </div>
                    </div>
                    {selectedStudent.fee_challans_detials.map((copy, index) => (
                      <>
                        <div className="endcontentMain2">
                          <div>
                            <p>{copy.fee_challan.challan_no}</p>
                          </div>

                          <div>
                            <p>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </p>
                          </div>
                          <div>
                            <p>{copy.amount}</p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="my__total__bottom4"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <b className="givingFont">Total Amount</b>
                    <p
                      className="btn btn-white givingFont totalFontSizer"
                      style={{ border: "1px solid black", marginLeft: "100px" }}
                    >
                      {getFeeAmount(selectedStudent?.fee_challans_detials)}
                    </p>
                  </div>
                </div>

                <div className="myprintBox2">
                  <div className="text-center">
                    {selectedStudent?.campus?.print_account_no &&
                      selectedStudent?.campus?.print_account_no.map((acc) => (
                        <>
                          <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                          <br />
                        </>
                      ))}

                    <b className="mytextfont">{selectedStudent.campus.name}</b>
                    <br></br>
                  </div>

                  <div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Due Date:</b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>
                          {getMonthName2(
                            getIssueDate(selectedStudent?.fee_challans).due_date
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">
                          Issue Date:
                        </b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>
                          {getMonthName2(
                            getIssueDate(selectedStudent?.fee_challans)
                              .issue_date
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Admission:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          <b>
                            {selectedStudent.admission_id == null
                              ? selectedStudent.registration_id
                              : selectedStudent.admission_id}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Name:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent.name}
                          <br></br>
                          {selectedStudent.gender?.toLowerCase() == "male"
                            ? "s/o"
                            : "d/o"}{" "}
                          {selectedStudent?.father_name}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Class:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent?.education_type == 2 ? "Hifz-" : ""}
                          {selectedStudent.student_class_name +
                            " (" +
                            selectedStudent?.global_section_name +
                            ")"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="endContent2">
                    <div className="endconheading2">
                      <div>
                        <b>Inv No.</b>
                      </div>
                      <div>
                        <b>Description.</b>
                      </div>
                      <div>
                        <b>Amount.</b>
                      </div>
                    </div>
                    {selectedStudent.fee_challans_detials.map((copy, index) => (
                      <>
                        <div className="endcontentMain2">
                          <div>
                            <p>{copy.fee_challan.challan_no}</p>
                          </div>

                          <div>
                            <p>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </p>
                          </div>
                          <div>
                            <p>{copy.amount}</p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="my__total__bottom4"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <b className="givingFont">Total Amount</b>
                    <p
                      className="btn btn-white givingFont totalFontSizer"
                      style={{ border: "1px solid black", marginLeft: "100px" }}
                    >
                      {getFeeAmount(selectedStudent?.fee_challans_detials)}
                    </p>
                  </div>
                </div>
                <div className="myprintBox3">
                  <div className="text-center">
                    {selectedStudent?.campus?.print_account_no &&
                      selectedStudent?.campus?.print_account_no.map((acc) => (
                        <>
                          <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                          <br />
                        </>
                      ))}

                    <b className="mytextfont">{selectedStudent.campus.name}</b>
                    <br></br>
                  </div>

                  <div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Due Date:</b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>
                          {getMonthName2(
                            getIssueDate(selectedStudent?.fee_challans).due_date
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">
                          Issue Date:
                        </b>
                      </div>
                      <div className="mytextfont printseconcontent">
                        <p>
                          {getMonthName2(
                            getIssueDate(selectedStudent?.fee_challans)
                              .issue_date
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Admission:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          <b>
                            {selectedStudent.admission_id == null
                              ? selectedStudent.registration_id
                              : selectedStudent.admission_id}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Name:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent.name}
                          <br></br>
                          {selectedStudent.gender?.toLowerCase() == "male"
                            ? "s/o"
                            : "d/o"}{" "}
                          {selectedStudent?.father_name}
                        </p>
                      </div>
                    </div>
                    <div className="printsecondpartcontainer printcontainerheight">
                      <div style={{ width: "170px", paddingLeft: "30px" }}>
                        <b className="mytextfont printsecontpart">Class:</b>
                      </div>
                      <div
                        className="mytextfont printseconcontent"
                        style={{ textAlign: "start" }}
                      >
                        <p>
                          {selectedStudent?.education_type == 2 ? "Hifz-" : ""}
                          {selectedStudent.student_class_name +
                            " (" +
                            selectedStudent?.global_section_name +
                            ")"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="endContent2">
                    <div className="endconheading2">
                      <div>
                        <b>Inv No.</b>
                      </div>
                      <div>
                        <b>Description.</b>
                      </div>
                      <div>
                        <b>Amount.</b>
                      </div>
                    </div>
                    {selectedStudent.fee_challans_detials.map((copy, index) => (
                      <>
                        <div className="endcontentMain2">
                          <div>
                            <p>{copy.fee_challan.challan_no}</p>
                          </div>

                          <div>
                            <p>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </p>
                          </div>
                          <div>
                            <p>{copy.amount}</p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="my__total__bottom4"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <b className="givingFont">Total Amount</b>
                    <p
                      className="btn btn-white givingFont totalFontSizer"
                      style={{ border: "1px solid black", marginLeft: "100px" }}
                    >
                      {getFeeAmount(selectedStudent?.fee_challans_detials)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
