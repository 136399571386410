import React from 'react'
import FormikDate from './FormikDate';
import FormikInput from './FormikInput';
import FormikSelect from './FormikSelect';
import FormikTextArea from './FormikTextArea';

const FormikController = ({control,...rest}) => {
    
    switch (control) {
        case 'input':
               return <FormikInput {...rest} />
            break;
        case 'select':
               return <FormikSelect {...rest} />
            break;
        case 'date':
               return <FormikDate {...rest} />
            break;
        case 'textarea':
               return <FormikTextArea {...rest} />
            break;
    
        default:
            break;
    }
    
}

export default FormikController
