import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Select from "react-select";
import { Switch } from "@material-ui/core";
import { PremissionChecker } from "../../../services/PremissionChecker";
const DefineFeeTypes = () => {
  const authtoken = localStorage.getItem("userToken");

  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [feeModal, setFeeModal] = useState(false);
  const [fee_type, setfee_type] = useState("");
  const [fee_types, setfee_types] = useState([]);
  const [alltargetAccountForSelect, setalltargetAccountForSelect] = useState(
    []
  );
  const [alltargetAccount, setalltargetAccount] = useState([]);
  const [subAccountId, setSubAccountId] = useState(0);
  const [feeDurationId, setFeeDurationId] = useState(0);
  const [feeOnStart, setFeeOnStart] = useState(false);
  const [feeOnStartId, setFeeOnStartId] = useState(0);
  const [feeOnStartModal, setFeeOnStartModal] = useState(false);

  const [editFeeModal, setEditFeeModal] = useState(false);
  const [editFeeTypesId, setEditFeeTypesId] = useState([]);
  const [editFeeTypeName, setEditFeTypeName] = useState("");
  const [editSubAccountId, setEditSubAccountId] = useState(0);
  const [editFeeTypeDurationId, setEditFeeTypeDurationId] = useState(0);
  const [editFeeOnStart, setEditFeeOnStart] = useState(false);

  const [loading, setloading] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [addfeeloading, setaddfeeloading] = useState(false);
  const alert = useAlert();

  const [feeDuration, setFeeDuration] = useState([]);
  const [feeDurationModal, setFeeDurationModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [feeDurationName, setFeeDurationName] = useState("");

  const [deleteFeeDurationModal, setdeleteFeeDurationModal] = useState(false);
  const [deleteableFeeDuration, setDeleteableFeeDuration] = useState([]);
  const [deleteloading, setdeleteloading] = useState(false);

  const [editFeeDurationModal, setEditFeeDurationModal] = useState(false);
  const [editFeeDurationId, setEditFeeDurationId] = useState([]);
  const [editFeeDurationName, setEditFeeDurationName] = useState("");

  useEffect(() => {
    getFeeTypes();
    getFeeDureation();
    getAllTargetAccounts();
  }, []);

  const getFeeTypes = async () => {
    setloading(true);
    await fetch(`${baseUrl}/feestypes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Fee Type");
          setfee_types(data.payload);
          setloading(false);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const getFeeDureation = async () => {
    await fetch(`${baseUrl}/fee-duration`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Fee Duration");
          setFeeDuration(data.payload);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const getAllTargetAccounts = async () => {
    let arr = [];
    await fetch(`${baseUrl}/subaccount`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setalltargetAccount([...data.payload]);
            data.payload.forEach((element) => {
              arr.push({
                label: element.title + " (" + element.acode + ")",
                value: element.id,
              });
            });
            console.log(data.payload, "select data");
            console.log(arr, "array Data");
            setalltargetAccountForSelect(arr);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addFeeHandler = async () => {
    if (fee_type == "" || subAccountId == "0" || feeDurationId == "") {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    const data = {
      name: fee_type,
      sub_account_id: subAccountId,
      fee_duration_id: feeDurationId,
      fee_on_start: feeOnStart == false ? 0 : 1,
    };
    console.log(data);
    // setaddfeeloading(true);

    await fetch(`${baseUrl}/feestypes`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          //   setaddfeeloading(false);
          if (data.metadata.success) {
            setFeeModal(false);
            setFeeOnStart(false);
            alert.show("Fee Type successfully genrated");
            getFeeTypes();
          } else {
            alert.show("Something Went Wrong");
          }
        })
      )
      .catch((err) => {
        setaddfeeloading(false);
        setFeeModal(false);
        console.log(err);
      });
  };

  const getEditFeeTypes = async (id) => {
    await fetch(`${baseUrl}/feestypes/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Fee Type");
          setEditFeTypeName(data.payload?.name);
          setEditSubAccountId(data.payload?.sub_account_id);
          setEditFeeTypeDurationId(data.payload?.fee_duration_id);
          setEditFeeOnStart(data.payload?.fee_on_start);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateFeeHandler = async () => {
    if (
      editFeeTypeName == "" ||
      editSubAccountId == "0" ||
      editFeeTypeDurationId == ""
    ) {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    const data = {
      name: editFeeTypeName,
      sub_account_id: editSubAccountId,
      fee_duration_id: editFeeTypeDurationId,
      fee_on_start: editFeeOnStart == false ? 0 : 1,
    };
    console.log(data);
    // setaddfeeloading(true);

    await fetch(`${baseUrl}/feestypes/${editFeeTypesId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          //   setaddfeeloading(false);
          if (data.metadata.success) {
            setEditFeeModal(false);
            alert.show("Fee Type successfully Updated");
            getFeeTypes();
          } else {
            alert.show("Something Went Wrong");
          }
        })
      )
      .catch((err) => {
        setaddfeeloading(false);
        setFeeModal(false);
        console.log(err);
      });
  };

  const feeDurationHandler = async () => {
    if (feeDurationName == "") {
      return alert.error("Fee Duration Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: feeDurationName,
    };
    console.log(data);
    setSubmitLoading(false);
    await fetch(`${baseUrl}/fee-duration`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.show(data.metadata.message, {
              timeout: 5000,
              type: "info",
            });
            // getAllowedMessages();
            setFeeDurationName("");
            getFeeDureation();
            setFeeDurationModal(false);
          } else {
            alert.show(data.metadata.message, {
              timeout: 5000,
              type: "danger",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const getEditFeeDuration = async (id) => {
    await fetch(`${baseUrl}/fee-duration/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Fee Duration");
          setEditFeeDurationName(data.payload?.display_duration);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateFeeDurationHandler = async () => {
    if (editFeeDurationName == "") {
      return alert.error("Fee Duration Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: editFeeDurationName,
    };
    console.log(data);
    setSubmitLoading(false);
    await fetch(`${baseUrl}/fee-duration/${editFeeDurationId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.show("Fee Duration Successfully Updated");
            getFeeDureation();
            setEditFeeDurationModal(false);
          } else {
            alert.show("Something Went Wrong");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const deleteFeeDurationHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/fee-duration/${deleteableFeeDuration.id}}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show(data.payload);
            getFeeDureation();
            setdeleteloading(false);
            setdeleteFeeDurationModal(false);
          } else {
            // alert.show(data.payload);
            setdeleteloading(false);
            setdeleteFeeDurationModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteFeeDurationModal(false);
      });
  };

  const onChangeFeeOnStart = (event) => {
    setFeeOnStart(event.target.checked);
    console.log(event.target.checked);

    setEditFeeOnStart(!editFeeOnStart);
    console.log(!editFeeOnStart);
  };

  const handleFeeOnStart = () => {
    fetch(`${baseUrl}/update-fee-on-start/${feeOnStartId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        getFeeTypes();
        setFeeOnStartModal(false);
        alert.success("Updated Successfully");
      })
      .catch((err) => {
        console.log("err is re", err);
      });
  };

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* {loginAccount == "Super Admin" ? ( */}
        <div>
          <button
            disabled={!PremissionChecker(user, "define_fee_type.can_add_fee")}
            onClick={() => setFeeModal(true)}
            className="btn btn-primary mr-2"
          >
            Add New Fee Type
          </button>

          <button
            disabled={
              !PremissionChecker(user, "define_fee_type.can_add_fee_duration")
            }
            className="btn btn-primary"
            style={{ margin: 5 }}
            onClick={() => setFeeDurationModal(true)}
          >
            Add Fee Duration
          </button>
        </div>
        {/* ) : (
          ""
        )} */}
        <div>
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">ID</th>
                <th className="text-center">Fee Type Name</th>
                <th className="text-center">Sub Account</th>
                <th className="text-center">Fee Duration</th>
                <th className="text-center">Fee On Start</th>
                <th className="text-center">Edit</th>
              </tr>
            </thead>
            <tbody>
              {fee_types.map((feetype) => (
                <tr>
                  <td className="text-center">{feetype?.id}</td>
                  <td className="text-center">{feetype?.name}</td>
                  <td className="text-center">{feetype?.sub_account}</td>
                  <td className="text-center">{feetype?.fee_duration}</td>
                  <td className="text-center">
                    <Switch
                      size="small"
                      onChange={() => {
                        setFeeOnStartId(feetype?.id);
                        setFeeOnStartModal(true);
                      }}
                      checked={feetype?.fee_on_start == "0" ? false : true}
                    />
                    {feetype?.fee_on_start == 1 ? "Active" : "Inactive"}
                  </td>
                  <td className="text-center">
                    <a
                      className={
                        // loginAccount == "Head Office" ?
                        "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        // : loginAccount == "Super Admin"
                        // ? "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        // : "btn btn-icon btn-light btn-hover-primary btn-sm mx-3 disabledSvg"
                      }
                      onClick={() => {
                        getEditFeeTypes(feetype?.id);
                        setEditFeeTypesId(feetype?.id);
                        setEditFeeModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary ">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row justify-content-center">
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
        </div>

        {/* ------------------------Fee Types Start----------------------- */}
        {/* Add Fee Type */}
        <Modal show={feeModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Fee Type
            </Modal.Title>
          </Modal.Header>

          {addfeeloading ? (
            <div style={{ padding: "25px" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label for="fee_type">
                    Fee Type Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Fee Type Name"
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setfee_type(e.target.value);
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    Select Sub Account <span style={{ color: "red" }}>*</span>
                  </label>

                  <Select
                    value={alltargetAccountForSelect.id}
                    name="target_sub_account_id"
                    onChange={(e) => {
                      console.log(e.value);
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setSubAccountId(e.value);
                    }}
                    className=""
                    placeholder="Select Sub Account"
                    options={alltargetAccountForSelect}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <br />
                  <label>
                    Select Fee Duration<span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    class="form-select form-control"
                    name="fee_duration_id"
                    value={feeDuration.id}
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setFeeDurationId(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Fee Duration</option>
                    {feeDuration.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.display_duration}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 mt-15">
                  <Switch
                    size="small"
                    checked={feeOnStart}
                    onChange={onChangeFeeOnStart}
                  />
                  <label className="mt-3">
                    Fee On Start <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
              </div>
              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            {addfeeloading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={addFeeHandler}
              >
                Add
              </button>
            )}
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setFeeModal(false);
                setisallfields(false);
                setfee_type("");
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Fee Type */}
        <Modal show={editFeeModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Fee Type
            </Modal.Title>
          </Modal.Header>

          {addfeeloading ? (
            <div style={{ padding: "25px" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label for="fee_type">
                    Fee Type <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Fee Type Name"
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setEditFeTypeName(e.target.value);
                    }}
                    value={editFeeTypeName}
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    Select Sub Account <span style={{ color: "red" }}>*</span>
                  </label>

                  <Select
                    value={alltargetAccountForSelect.id}
                    name="target_sub_account_id"
                    onChange={(e) => {
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setEditSubAccountId(e.value);
                      console.log(editSubAccountId);
                      console.log(alltargetAccountForSelect.id);
                      console.log(alltargetAccountForSelect.editSubAccountId);
                    }}
                    className=""
                    placeholder="Select Sub Account"
                    options={alltargetAccountForSelect}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <br />
                  <label>
                    Select Fee Duration<span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    class="form-select form-control"
                    name="fee_duration_id"
                    value={editFeeTypeDurationId}
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      console.log(e.target.value);
                      setEditFeeTypeDurationId(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Fee Duration</option>
                    {feeDuration.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.display_duration}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 mt-15">
                  <Switch
                    size="small"
                    checked={editFeeOnStart}
                    onChange={onChangeFeeOnStart}
                  />
                  <label className="mt-3">
                    Fee On Start <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
              </div>
              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            {addfeeloading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={updateFeeHandler}
              >
                Update
              </button>
            )}
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setEditFeeModal(false);
                setisallfields(false);
                setfee_type("");
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Change Fee On Start */}
        <Modal show={feeOnStartModal} size="md">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Change Fee On Start Status
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to change fee on start status
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => handleFeeOnStart()}
              className="btn btn-success button_blue"
            >
              Yes
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setFeeOnStartModal(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
        {/* ------------------------Fee Types End----------------------- */}

        {/* ------------------------Fee Duration Start----------------------- */}
        {/* Add Fee Duration  */}
        <Modal show={feeDurationModal} size="md">
          <Modal.Header>
            <Modal.Title>Add Fee Duration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Fee Duration Name{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control"
                value={feeDurationName}
                onChange={(e) => setFeeDurationName(e.target.value)}
                placeholder="Enter Fee Duration Name"
              />
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Duration Name</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {feeDuration.map((fee_duration) => (
                  <tr>
                    <td style={{ textAlign: "start" }}>{fee_duration?.id}</td>
                    <td style={{ textAlign: "start" }}>
                      {fee_duration?.display_duration}
                    </td>
                    <td className="text-center">
                      <a
                        className={
                          // loginAccount == "Head Office" ?
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          // : loginAccount == "Super Admin"
                          // ? "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          // : "btn btn-icon btn-light btn-hover-primary btn-sm mx-3 disabledSvg"
                        }
                        onClick={() => {
                          setEditFeeDurationId(fee_duration?.id);
                          getEditFeeDuration(fee_duration?.id);
                          setEditFeeDurationModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary ">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <a
                        className={
                          // loginAccount == "Head Office" ?
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          // : loginAccount == "Super Admin"
                          // ? "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          // : "btn btn-icon btn-light btn-hover-primary btn-sm mx-3 disabledSvg"
                        }
                        onClick={() => {
                          setDeleteableFeeDuration(fee_duration);
                          setdeleteFeeDurationModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button onClick={feeDurationHandler} className="btn btn-primary">
                Add Fee
              </button>
            )}

            <button
              onClick={() => setFeeDurationModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Fee Duration */}
        <Modal show={editFeeDurationModal} size="md">
          <Modal.Header>
            <Modal.Title>Update Fee Duration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Fee Duration Name{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control"
                value={editFeeDurationName}
                // defaultValue={editFeeDuration.display_duration}
                onChange={(e) => setEditFeeDurationName(e.target.value)}
                placeholder="Enter Fee Duration Name"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button
                onClick={updateFeeDurationHandler}
                className="btn btn-primary"
              >
                Update
              </button>
            )}

            <button
              onClick={() => setEditFeeDurationModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Fee Duration */}
        <Modal show={deleteFeeDurationModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you want to delete
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Fee Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={deleteFeeDurationHandle}
                  className="btn button_blue"
                >
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setdeleteFeeDurationModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
        {/* ------------------------Fee Duration End----------------------- */}
      </Paper>
    </div>
  );
};

export default DefineFeeTypes;
