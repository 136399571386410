import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert, Form } from "react-bootstrap";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import AllStudents from "./showallcampusstudent";
import Paper from "@material-ui/core/Paper";

import { useSelector, useDispatch } from "react-redux";

function GenerateCampusWiseFee() {
  const alert = useAlert();
  const dispatch = useDispatch();

  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  const [feeloading, setfeeloading] = useState(false);
  const [feemodal, setfeemodal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [classess, setClassess] = useState([]);
  const authtoken = localStorage.getItem("userToken");
  const [feetypes, setfeetypes] = useState([]);
  const [campus_id, setcampus_id] = useState("");
  const [fee_type_id, setfee_type_id] = useState("");
  const [student_class_id, setstudent_class_id] = useState("");
  const [due_date, setdue_date] = useState(red_recevingdate);
  const [issue_date, setissue_date] = useState(red_recevingdate);

  const [currentsession, setcurrentsession] = useState("");
  const [allsessions, setallsessions] = useState([]);

  const currentyear = new Date().getFullYear();
  const [isallfields, setisallfields] = useState(false);

  let [amount, setamount] = useState([]);
  let [fees_type_id, setfees_type_id] = useState([]);
  const [datesformat, setdatesformat] = useState([]);

  let [monthlyFeeInputs, setmonthlyFeeInputs] = useState([1]);
  let [monthlyFeeInputsvalue, setmonthlyFeeInputsvalue] = useState([]);
  let [isMonthlyFee, setisMonthlyFee] = useState(false);

  let [additiionalfeeinputs, setadditiionalfeeinputs] = useState([1]);
  let [addituionalFeeInputsvalue, setaddituionalFeeInputsvalue] = useState([]);
  let [isAdditionalFee, setisAdditionalFee] = useState(false);

  let [errorcreate, seterrorcreate] = useState("Please Fill All The Fields");

  let [selectedcampus, setselectedcampus] = useState({});
  const [studentsForFeeGenerate, setstudentsForFeeGenerate] = useState([]);
  const [allstudentschallan, setallstudentschallan] = useState([]);

  const addAdditionalInputfield = (e) => {
    e.preventDefault();

    let array = additiionalfeeinputs;
    array.push(1);
    setadditiionalfeeinputs([...array]);
  };

  const additionalfeeChange = (e, index) => {
    console.log(e.target.value);
    if (e.target.value == "") {
      setisallfields(true);
      seterrorcreate("Please Add Additional fees");
    } else {
      setisallfields(false);
      let updatingarray = amount;
      updatingarray[index] = e.target.value;
      setamount([...updatingarray]);
    }
  };

  const addMonthlyInputfield = (e) => {
    e.preventDefault();
    console.log(e.target.id);
    if (e.target.id === "add_Monthly_Fee_button") {
      let array = monthlyFeeInputs;
      array.push(1);
      console.log(array);
      setmonthlyFeeInputs([...array]);
    }
  };

  const getRespectivefee = (id, index) => {
    console.log(id);

    let data = {
      campus_id: campus_id,
      fee_type_id: id,
    };

    console.log(data);
  };

  //fee challans

  useEffect(() => {
    setissue_date(red_recevingdate);
    setdue_date(red_recevingdate);
  }, [red_recevingdate]);

  useEffect(() => {
    // if (campusidget != "") {
    //     setpreviousselectedcampus(campusidget)
    //     onchangefunc(campusidget);
    // }

    // getAllCampus();
    getAllSessions();
    //  getfeetypes()
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("campuses", data.payload);
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("campuses", err);
      });
  };

  const onchangeCampus = (id) => {
    console.log(id);
    // const id = e.target.value
    setcampus_id(id);

    // fetch(`${baseUrl}/campusclass/${id}`, {
    //     method: 'GET',
    //     headers: {
    //         'Authorization': `Bearer ${authtoken}`
    //     }
    // })
    //     .then((res) => res.json())
    //     .then((data) => {
    //         setClassess(data.payload)
    //         // console.log("class", data)

    //     })
    //     .catch((err) => {
    //         console.log(err)
    //         console.log("vehicle", err)

    //     })
  };

  const getfeetypes = (id) => {
    fetch(`${baseUrl}/campus/campus-fees/${campus_id}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data, "hahahah");
        let filtered_feetypes = [];
        let array = [];
        data.payload.map((item) => {
          if (!filtered_feetypes.includes(item.fee_name)) {
            if (item.fee_name == "MONTHLY FEE") {
            } else {
              console.log(item.fee_name);
              filtered_feetypes.push(item.fee_name);
              array.push({ name: item.fee_name, id: item.fee_type_id });
            }
          }
        });

        console.log("fee types ==>", data.payload);
        setfeetypes([...array]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cencelModal = () => {
    setisMonthlyFee(false);
    setisAdditionalFee(false);
    setisallfields(false);
    setmonthlyFeeInputsvalue([]);
    setmonthlyFeeInputs([]);
    setfees_type_id([]);
    setamount([]);
    setadditiionalfeeinputs([]);
    setfeeloading(false);
    seterrorcreate("Please Fill All The Fields");
  };

  const generateCampusFee = async () => {
    setfeeloading(true);

    let monthly_fee_status = 1;
    let additional_fee_status = 1;

    if (!isMonthlyFee) {
      if (!isAdditionalFee) {
        setisallfields(true);
        seterrorcreate("Please Add Monthly Fee or Additional Fee");
        setfeeloading(false);
        return;
      }
    }

    if (monthlyFeeInputsvalue.length != monthlyFeeInputs.length) {
      if (isMonthlyFee) {
        setisallfields(true);
        seterrorcreate("Please Add Monthly  Fee");
        setfeeloading(false);
        return;
      }
    }

    if (isAdditionalFee) {
      if (
        fees_type_id.length != additiionalfeeinputs.length ||
        additiionalfeeinputs.length != amount.length
      ) {
        setisallfields(true);
        seterrorcreate("Please Add Some Additional Fee");
        setfeeloading(false);
        return;
      }
    }

    if (due_date.length == 0 || issue_date.length == 0) {
      setisallfields(true);
      seterrorcreate("Please Give Due And issue Dates");
      setfeeloading(false);
      return;
    }

    setisallfields(false);
    const data = {
      monthly_fee_status,
      additional_fee_status,
      fee_month: monthlyFeeInputsvalue,
      amount,
      fees_type_id,
      due_date,
      issue_date,
      student_id: studentsForFeeGenerate,
    };

    console.log(data);

    await fetch(`${baseUrl}/all_fee_generate_with_std_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          const obj = data.payload;

          const newarr = Object.values(obj);
          console.log(newarr);
          setallstudentschallan(newarr);
          setfeeloading(false);
          if (data.metadata.success) {
            setfeemodal(false);
            setfeeloading(false);
            setisallfields(false);
            alert.show("Fee generated Successfully");
          } else {
            setfeemodal(false);
            setfeeloading(false);
            alert.show("Something went Wrong");
          }
          cencelModal();
        })
      )
      .catch((err) => {
        console.log(err);
        setfeemodal(false);
        alert.show(err);
      });
  };

  const getStudentsForFeeGenerate = (students, campusid, sessionid) => {
    campuses.map((item) => {
      if (item.id == campusid) {
        setselectedcampus(item);
      }
    });
    if (students.length != 0) {
      getfeetypes(sessionid);
    }

    setstudentsForFeeGenerate(students);
    onchangeCampus(campusid);
  };

  const changesession = (id) => {
    setcurrentsession(id);
    getfeetypes(id);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {studentsForFeeGenerate.length == 0 ? (
          <button
            onClick={() => alert.show("Please Select Some Students")}
            className="btn btn-success button_blue"
          >
            Add Fee
          </button>
        ) : (
          <button
            onClick={() => setfeemodal(true)}
            className="btn btn-success button_blue"
          >
            Add Fee
          </button>
        )}
        <br />
        <br />

        <AllStudents
          getStudentsForFeeGenerate={getStudentsForFeeGenerate}
          getcurrentsession={setcurrentsession}
        />

        <Modal size="lg" show={feemodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Campus Wise Fee
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />

            <form class="form-card">
              <div class="row justify-content-between text-left">
                <div class="form-group col-sm-12 flex-column d-flex">
                  <label>
                    <b>
                      {" "}
                      Campus <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    onChange={(e) => onchangeCampus(e)}
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected disabled value={selectedcampus.id}>
                      {selectedcampus.name}
                    </option>
                  </select>
                </div>

                <div class="form-group col-sm-12 flex-column d-flex pt-3">
                  <label class="form-control-label">
                    <b>
                      Select Fee type <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>

                  <div class="row col-sm-12 justify-content-between text-left">
                    <div class="form-group col-sm-12 flex-column d-flex">
                      <div class="form-group col-sm-12 flex-column d-flex">
                        <div class="switch ">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={() => {
                                setisMonthlyFee(!isMonthlyFee);
                                setmonthlyFeeInputsvalue = [];
                                setmonthlyFeeInputs([1]);
                              }}
                              type="checkbox"
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">Monthly Fee</h3>
                          </label>
                        </div>

                        {isMonthlyFee ? (
                          <div>
                            <div className="form-group column ">
                              {monthlyFeeInputs.map((item, index) => (
                                <div className="col-lg-12 col-sm-12s flex-column d-flex">
                                  <div>
                                    <label>
                                      Enter Date{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                      className="flex-row  d-flex  col-lg-12 col-sm-12s"
                                    >
                                      <input
                                        className="flex-row  d-flex  col-lg-8 col-sm-12s"
                                        value={datesformat[index]}
                                        defaultValue={datesformat[index]}
                                        key={index}
                                        onChange={(e) => {
                                          let datearray = datesformat;
                                          datearray[index] = e.target.value;
                                          setdatesformat([...datearray]);
                                          let array = monthlyFeeInputsvalue;
                                          array[index] = e.target.value + "-01";
                                          setmonthlyFeeInputsvalue([...array]);
                                        }}
                                        name="dob"
                                        type="month"
                                        max="9999-12"
                                        min={currentyear + "-01"}
                                        placeholder="Enter Date of birth"
                                      />
                                      <button
                                        id={index}
                                        value={datesformat[index]}
                                        defaultValue={datesformat[index]}
                                        onClick={(e) => {
                                          e.preventDefault();

                                          let monthlyFeeInputsvalu_earray = monthlyFeeInputsvalue;
                                          monthlyFeeInputsvalu_earray.splice(
                                            index,
                                            1
                                          );

                                          setmonthlyFeeInputsvalue = [
                                            ...monthlyFeeInputsvalu_earray,
                                          ];
                                          let datesformat_array = datesformat;
                                          datesformat_array.splice(index, 1);
                                          setdatesformat([
                                            ...datesformat_array,
                                          ]);

                                          setmonthlyFeeInputs(
                                            monthlyFeeInputs.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                        }}
                                        className="btn btn-danger "
                                        style={{ width: "90px" }}
                                      >
                                        remove
                                      </button>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              ))}

                              <br />
                              <div className="col-lg-3 col-sm-12">
                                <button
                                  id="add_Monthly_Fee_button"
                                  onClick={(e) => {
                                    addMonthlyInputfield(e);
                                  }}
                                  className="btn btn-success button_blue"
                                >
                                  Add Fields
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div class="form-group col-sm-12 flex-column d-flex">
                      <div class="form-group col-sm-12 flex-column d-flex">
                        <div class="switch">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={() => {
                                setisAdditionalFee(!isAdditionalFee);
                                setaddituionalFeeInputsvalue([]);
                                setadditiionalfeeinputs([1]);
                                setamount([]);
                              }}
                              type="checkbox"
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">
                              Additional Fee
                            </h3>
                          </label>
                        </div>

                        {isAdditionalFee ? (
                          <div>
                            <div className="form-group column ">
                              {additiionalfeeinputs.map((item, index) => (
                                <div className="col-lg-12 col-sm-12s flex-column d-flex">
                                  <div>
                                    <label>
                                      Add Additional fee{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <div
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                      className="flex-row  d-flex  col-lg-12 col-sm-12s"
                                    >
                                      <select
                                        defaultValue={fees_type_id[index]}
                                        value={fees_type_id[index]}
                                        onChange={(e) => {
                                          console.log(e.target);

                                          let updatingarray = fees_type_id;
                                          updatingarray[index] = e.target.value;
                                          setfees_type_id([...updatingarray]);

                                          //  setfees_type_id()
                                          // getRespectivefee(
                                          //   e.target.value,
                                          //   index
                                          // );

                                          // getfeeres(e.target.value, index)
                                          // let array = addituionalFeeInputsvalue;
                                          // array[index] = e.target.value;
                                          // setaddituionalFeeInputsvalue();
                                          //        setfee_type_id(e.target.value)
                                        }}
                                        class="form-select"
                                        aria-label="Default select example"
                                      >
                                        <option selected disabled>
                                          Select Fee Type
                                        </option>

                                        {feetypes.map((fee) => (
                                          <option value={fee.id}>
                                            {fee.name}
                                          </option>
                                        ))}
                                      </select>

                                      <input
                                        style={{ marginLeft: "20px" }}
                                        id="stopkeypress"
                                        type="number"
                                        onChange={(e) => {
                                          additionalfeeChange(e, index);
                                        }}
                                        min="1"
                                        defaultValue={amount[index]}
                                        value={amount[index]}
                                        //  onKeyPress={(e) => handler(e, index)}
                                      />
                                      <button
                                        style={{
                                          marginLeft: "10px",
                                          width: "90px",
                                        }}
                                        id={index}
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          setadditiionalfeeinputs(
                                            additiionalfeeinputs.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                          setfees_type_id(
                                            fees_type_id.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                          setamount(
                                            amount.filter(
                                              (item, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        remove
                                      </button>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              ))}

                              <br />
                              <div className="col-lg-3 col-sm-12">
                                <button
                                  id="add_additional_Fee_button"
                                  onClick={(e) => {
                                    addAdditionalInputfield(e);
                                  }}
                                  className="btn btn-success button_blue"
                                >
                                  Add Fields
                                </button>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div class="form-group col-sm-6 flex-column d-flex">
                      <label class="form-control-label">
                        <b>
                          Due Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <input
                        max="9999-12-30"
                        onChange={(e) => setdue_date(e.target.value)}
                        type="date"
                        value={due_date}
                        class="form-control  mb-2"
                      />

                      <label class="form-control-label">
                        <b>
                          Issue Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <input
                        max="9999-12-30"
                        value={issue_date}
                        onChange={(e) => setissue_date(e.target.value)}
                        type="date"
                        class="form-control  mb-2"
                      />
                    </div>
                  </div>

                  {/* <select
                                    onChange={(e) => setfee_type_id(e.target.value)}
                                    class="form-select"
                                    style={{ backgroundColor: '#F3F6F9' }}
                                    aria-label="Default select example"
                                >
                                    <option selected disabled>
                                        Select Fee Type
                                    </option>

                                    {
                                        feetypes.map(fee => (
                                            <option value={fee.id}>{fee.name}</option>
                                        ))
                                    }


                                </select> */}
                </div>
              </div>
              {/* <div class="row justify-content-between text-left" >

                            <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                    <b>Due Date</b>
                                </label>
                                <input onChange={(e) => setdue_date(e.target.value)} type="date" class="form-control placeholder-bg mb-2" />

                                <label class="form-control-label">
                                    <b>Issue Date</b>
                                </label>
                                <input onChange={(e) => setissue_date(e.target.value)} type="date" class="form-control placeholder-bg mb-2" />
                            </div>

                        </div> */}
            </form>
            {/* : null
                        } */}
            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>{errorcreate}</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>
          {feeloading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "15px",
              }}
            >
              {" "}
              <span className="spinner-border"></span>{" "}
            </div>
          ) : (
            <Modal.Footer>
              <button
                onClick={generateCampusFee}
                className="btn btn-success button_blue"
              >
                Generate
              </button>
              <button
                onClick={() => {
                  cencelModal();
                  setfeemodal(false);
                }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
}

export default GenerateCampusWiseFee;
