import React from "react"
import { Table, Modal, Tab, Tabs, Alert } from 'react-bootstrap'

export default function ViewFeeModal({ show, onHide, challandtail }) {
    console.log(challandtail.fee_challan_details, "challandtail")
    console.log(challandtail, "challandtail")

    return (

        <div>


            <Modal size="xl" show={show}>

                <Modal.Body>

                    <div className="card">

                        <Table responsive>
                            <thead>
                                <tr id="tbl">
                                    <th>SR#</th>
                                    <th>Amount</th>
                                    <th>Fee Name</th>
                                    <th>Challan Number</th>
                                    <th>Fee Month</th>

                                </tr>
                            </thead>
                            {
                                challandtail.fee_challan_details == undefined ? null :
                                    <>
                                        {
                                            challandtail.fee_challan_details.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "start" }}>{index + 1}</td>
                                                        <td style={{ textAlign: "start" }}>{item.amount}</td>
                                                        <td style={{ textAlign: "start" }}>{item.fee_name}</td>
                                                        <td style={{ textAlign: "start" }}>{challandtail.challan_no}</td>
                                                        <td style={{ textAlign: "start" }}>{item.fee_month}</td>
                                                    </tr>
                                                )

                                            })
                                        }
                                    </>
                            }

                           

                        </Table>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            onClick={() => {
                                onHide(false)
                            }}
                            style={{ width: '150px', height: '40px' }}
                            className="btn btn-danger btn_width">
                            Cancel
                        </button>

                    </div>

                </Modal.Footer>
            </Modal>
        </div>
    )
}