import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const StudentStrengthExport = ({
  summaryDetail,
  summaryCampusData,
  summaryGrandTotal,
  getSummaryStrengthTotal,
  grandSummary,
  reportData,
  grandStrength,
  grandFemale,
  grandMale,
  getStrengthSum,
  getFemaleSum,
  getMaleSum,
}) => {
  const tableRef = useRef(null);

  return (
    <>
      <DownloadTableExcel
        filename={
          summaryDetail
            ? "Student Strength Summary Report"
            : "Student Strength Report"
        }
        sheet={
          summaryDetail
            ? "Student Strength Summary Report"
            : "Student Strength Report"
        }
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2 mt-9">Export</button>
      </DownloadTableExcel>
      <div id="tableboot mt-5">
        {summaryDetail ? (
          <Table responsive ref={tableRef} style={{ display: "none" }}>
            <thead>
              <tr id="tbl">
                <th className="text-center">Class</th>
                <th className="text-center">Old Count</th>
                <th className="text-center">New Count</th>
                <th
                  className="text-center"
                  style={{ backgroundColor: "gainsboro" }}
                >
                  Current Strength
                </th>
                <th className="text-center">StruckOff</th>
                <th className="text-center">PassOut</th>
                <th className="text-center">Leaving</th>
                <th className="text-center">Migrate</th>
                <th className="text-center">Over All</th>
              </tr>
            </thead>
            <tbody>
              {summaryCampusData.length > 0 &&
                summaryCampusData.map((summary, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        className="text-start pl-10"
                        style={{
                          color: "#121291",
                          fontSize: "16px",
                        }}
                      >
                        <b>{summary?.Campus}</b>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <b>{summary?.campus_wise_sum?.old_count}</b>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <b>{summary?.campus_wise_sum?.new_count}</b>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontSize: "16px",

                          backgroundColor: "gainsboro",
                        }}
                      >
                        <b>{summary?.campus_wise_sum?.strength}</b>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <b>{summary?.campus_wise_sum?.stuck_off}</b>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <b>{summary?.campus_wise_sum?.pass_out}</b>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <b>{summary?.campus_wise_sum?.leaving}</b>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <b>{summary?.campus_wise_sum?.migrate}</b>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <b>{summary?.campus_wise_sum?.overall}</b>
                      </td>
                    </tr>
                    {summary?.classes.map((classData, subIndex) => (
                      <tr key={subIndex}>
                        <td>{classData?.Class}</td>
                        <td className="text-center">
                          {classData?.report?.old_count}
                        </td>
                        <td className="text-center">
                          {classData?.report?.new_count}
                        </td>
                        <td
                          className="text-center"
                          style={{ backgroundColor: "gainsboro" }}
                        >
                          {classData?.report?.strength}
                        </td>
                        <td className="text-center">
                          {classData?.report?.stuck_off}
                        </td>
                        <td className="text-center">
                          {classData?.report?.pass_out}
                        </td>
                        <td className="text-center">
                          {classData?.report?.leaving}
                        </td>
                        <td className="text-center">
                          {classData?.report?.migrate}
                        </td>
                        <td className="text-center">
                          {classData?.report?.overall}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              {summaryGrandTotal && (
                <tr>
                  <td
                    style={{
                      color: "#121291",
                      fontSize: "18px",
                    }}
                  >
                    <b>Grand Total:</b>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>{summaryGrandTotal?.old_count}</b>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>{summaryGrandTotal?.new_count}</b>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "18px",

                      backgroundColor: "gainsboro",
                    }}
                  >
                    <b>{summaryGrandTotal?.strength}</b>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>{summaryGrandTotal?.stuck_off}</b>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>{summaryGrandTotal?.pass_out}</b>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>{summaryGrandTotal?.leaving}</b>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>{summaryGrandTotal?.migrate}</b>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>{summaryGrandTotal?.overall}</b>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        ) : (
          <Table ref={tableRef} style={{ display: "none" }}>
            <thead>
              <tr id="tbl">
                <th className="text-center">Class Name</th>
                <th className="text-center">Section Nmae</th>
                <th className="text-center">Male</th>
                <th className="text-center">Female</th>
                <th className="text-center">Strength</th>
              </tr>
            </thead>
            {reportData.length > 0 ? (
              <>
                {reportData.map((item) => (
                  <>
                    {/* <div className="mx-4" style={{ marginTop: "40px" }}>
                    <h3 className="text-primary">{item.Campus}</h3>
                  </div> */}
                    <tr id="tbl">
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text_align_start text-primary"
                      >
                        {item.Campus}
                      </td>
                      <td style={{ fontWeight: "bold" }} className=""></td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {getMaleSum(item)}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {getFemaleSum(item)}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {getStrengthSum(item)}
                      </td>
                    </tr>
                    <tbody>
                      {item?.classes?.map((subitem) => (
                        <>
                          {subitem?.sections?.map((session, indu) => (
                            <>
                              {/* <div className="mt-5">
                          <h4 className="">
                            Class:-{subitem.Class?.name}(
                            {session.Section?.name})
                          </h4>
                        </div> */}
                              <tr id="tbl">
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {indu == 0 ? subitem?.Class?.name : ""}
                                  {/* {session?.report?.male} */}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {session?.Section?.name}
                                  {/* {session?.report?.male} */}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {session?.report?.male}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {session?.report?.female}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {session?.report?.strength}
                                </td>
                              </tr>
                            </>
                          ))}
                          <tr id="tbl">
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text_align_start"
                            ></td>
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text-end"
                            >
                              Class Wise Totals:-
                            </td>
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text-center"
                            >
                              {subitem.sections.reduce(
                                (a, c) => a + parseInt(c.report.male),
                                0
                              )}
                            </td>
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text-center"
                            >
                              {subitem.sections.reduce(
                                (a, c) => a + parseInt(c.report.female),
                                0
                              )}
                            </td>
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text-center"
                            >
                              {subitem.sections.reduce(
                                (a, c) => a + parseInt(c.report.strength),
                                0
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </>
                ))}
                <tr id="tbl">
                  <td
                    style={{ color: "black", fontWeight: "bold" }}
                    className="text_align_start"
                  ></td>
                  <td
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-end text-primary"
                  >
                    Grand Total:-
                  </td>
                  <td
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-center text-primary"
                  >
                    {grandMale}
                  </td>
                  <td
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-center text-primary"
                  >
                    {grandFemale}
                  </td>
                  <td
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-center text-primary"
                  >
                    {grandStrength}
                  </td>
                </tr>
              </>
            ) : (
              ""
            )}
          </Table>
        )}
      </div>
    </>
  );
};

export default StudentStrengthExport;
