import { Paper } from '@material-ui/core';
import { Divider,DatePicker } from 'antd';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { baseUrl } from '../../../../services/config';
import { Pagination } from '../../../../_metronic/_partials/controls';
import * as uiHelpers from "../../../modules/ECommerce/pages/customers/CustomersUIHelpers";


const { RangePicker } = DatePicker;
const LogReport = () => {

    const [reports,setReports]=useState([])
    let authtoken = localStorage.getItem("userToken");
    const [loading,setloading]=useState(false)
    const initialFilter = {
        pageNumber: 1,
        pageSize: 10,
      };
    
      const [queryParams, setQueryParamsBase] = useState(initialFilter);
    useEffect(() => {
        getLogs();
      }, []);
    
      const getLogs = () => {
        setloading(true);
        fetch(`${baseUrl}/sms-log-report`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authtoken}`,
          },
        })
          .then((res) => res.json())
    
          .then((data) => {
            setloading(false);
            console.log("log List", data.payload);
            setReports(data.payload);
          })
          .catch((err) => {
            setloading(false);
            console.log(err);
          });
      };
    
      const columns = [
        {
          dataField: "user[0]",
          text: "User",
        },
        {
          dataField: "date_time",
          text: "Time",
          width:"20px"
        },
        {
            dataField: "number",
            text: "Number", 
        },
        {
            dataField: "",
            text: "", 
        },
        {
          headerStyle: { width: '550px' },
           style: { width: '550px',textAlign:"left" },
            dataField: "message",
            text: "Message", 
        }
       
      ];
    const options = {
        custom: true,
        totalSize: reports.length,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber,
      };
    
      const onPaginationSelectChange = () => {
        setQueryParamsBase({
          pageNumber: 1,
        });
      };
    
      const updatingQueryParams = (page) => {
        console.log(page);
        console.log("this is chus2");
        setQueryParamsBase({
          pageNumber: page,
        });
      };
      const handlePickerChnage=(date, dateString)=>{
         console.log(date, dateString ,"this is ")

     if(date !=null){
      const data={
        start_date:dateString[0],
        end_date:dateString[1],
       }
       fetch(`${baseUrl}/date-wise-sms-log-report`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
        
            setReports(data.payload);


          } 
        })
        .catch((err) => {
          console.log("err is re", err);
        });
     }else{
      getLogs();

     }
      }
  return (
    <>
     <Paper
          style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
        >
        <div className='row'>
            <div className='col-md-5'>
            <RangePicker onChange={handlePickerChnage} />
            </div>
           {reports.length?
             <div className='col-md-7 text-end'>
             <h3>Total Messages:{reports.length}</h3>
         </div>
           :null}
        </div>
  <Divider/>
<PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <>
                    <Pagination
                      updatingQueryParams={updatingQueryParams}
                      onPaginationSelectChange={onPaginationSelectChange}
                      isLoading={loading}
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        keyField="id"
                        data={reports}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        {...paginationTableProps}
                      ></BootstrapTable>
                    </Pagination>
                    {/* )} */}
                  </>
                );
              }}
            </PaginationProvider>
        </Paper>
    </>
  )
}

export default LogReport