import React, { useEffect, useRef, useState } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import ReactToPrint from "react-to-print";
import Select from "react-select";
import "./GrossSalary.css";
import { Spinner, Table } from "react-bootstrap";
import PasswordModal from "../../../components/PasswordModal/PasswordModal";
import { useAlert } from "react-alert";
import GrossSalarySheetExport from "./GrossSalarySheetExport";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";

const GrossSalarySheet = () => {
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();
  const pageStyle = `
  @page {
    margin-left: 1.5in;
        size: legal landscape !important;
    }
`;
  const [month, setMonth] = useState("");
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campus, setCampus] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [fullCampus, setFullCampus] = useState({});
  const [loading, setloading] = useState(false);
  const [selectType, setSelectType] = useState("");
  const [selectGroup, setSelectGroup] = useState("");
  const [glossTotal, setGlossTotal] = useState("");
  const [netTotal, setNetTotal] = useState("");
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const [TotalBasicPay, setTotalBasicPay] = useState(0);
  const [TotalIncrement, setTotalIncrement] = useState(0);
  const [TotalUgs, setTotalUgs] = useState(0);
  const [TotalScience, setTotalScience] = useState(0);
  const [TotalAdditional, setTotalAdditional] = useState(0);
  const [TotalExtraPeriod, setTotalExtraPeriod] = useState(0);
  const [Total2ndShift, setTotal2ndShift] = useState(0);
  const [TotalHod, setTotalHod] = useState(0);
  const [TotalCollege, setTotalCollege] = useState(0);
  const [TotalHostel, setTotalHostel] = useState(0);
  const [TotalHifz, setTotalHifz] = useState(0);
  const [TotalOther, setTotalOther] = useState(0);
  const [TotalConva, setTotalConva] = useState(0);
  const [TotalExtraCocah, setTotalExtraCocah] = useState(0);
  const [TotalGrossSalary, setTotalGrossSalary] = useState(0);
  const [TotalAnnualIncr, setTotalAnnualIncr] = useState(0);

  // Get the current date
  const currentDate = new Date();

  // Format the date as a string
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
  }).format(currentDate);

  const displayDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    // getAllCampus();
    getSettingData();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const onShow = () => {
    if (campus == "") {
      alert.error("Please Select Campus");
      return;
    }

    let data = {
      campus_id: campus,
    };
    setloading(true);

    fetch(`${baseUrl}/staff_gross_salary_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          setIsVisible(true);
          console.log(data);

          if (data.metadata) {
            if (data.metadata.success) {
              let sumBasicPay = 0;
              data.payload.map((item) => {
                if (item.pay_scale == null) {
                  sumBasicPay += 0;
                } else {
                  sumBasicPay += parseInt(item?.pay_scale?.basic);
                }
              });
              setTotalBasicPay(sumBasicPay);

              let sumIncrement = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumIncrement += 0;
                } else {
                  sumIncrement += parseInt(item?.salaryAlowance?.increment);
                }
              });
              setTotalIncrement(sumIncrement);

              let sumUgs = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumUgs += 0;
                } else {
                  sumUgs += parseInt(item?.salaryAlowance?.ugs);
                }
              });
              setTotalUgs(sumUgs);

              let sumScience = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumScience += 0;
                } else {
                  sumScience += parseInt(item?.salaryAlowance?.science);
                }
              });
              setTotalScience(sumScience);

              let sumAddAlown = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumAddAlown += 0;
                } else {
                  sumAddAlown += parseInt(
                    item?.salaryAlowance?.additional_allowance
                  );
                }
              });
              setTotalAdditional(sumAddAlown);

              let sumExtraPer = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumExtraPer += 0;
                } else {
                  sumExtraPer += parseInt(item?.salaryAlowance?.extra_period);
                }
              });
              setTotalExtraPeriod(sumExtraPer);

              let sum2ndShift = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sum2ndShift += 0;
                } else {
                  sum2ndShift += parseInt(item?.salaryAlowance?.second_shift);
                }
              });
              setTotal2ndShift(sum2ndShift);

              let sumHoD = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumHoD += 0;
                } else {
                  sumHoD += parseInt(item?.salaryAlowance?.hod);
                }
              });
              setTotalHod(sumHoD);

              let sumCollege = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumCollege += 0;
                } else {
                  sumCollege += parseInt(item?.salaryAlowance?.college);
                }
              });
              setTotalCollege(sumCollege);

              let sumHostel = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumHostel += 0;
                } else {
                  sumHostel += parseInt(item?.salaryAlowance?.hostel);
                }
              });
              setTotalHostel(sumHostel);

              let sumHifz = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumHifz += 0;
                } else {
                  sumHifz += parseInt(item?.salaryAlowance?.hifz);
                }
              });
              setTotalHifz(sumHifz);

              let sumOther = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumOther += 0;
                } else {
                  sumOther += parseInt(item?.salaryAlowance?.other);
                }
              });
              setTotalOther(sumOther);

              let sumConvance = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumConvance += 0;
                } else {
                  sumConvance += parseInt(item?.salaryAlowance?.convance);
                }
              });
              setTotalConva(sumConvance);

              let sumExtraCoach = 0;
              data.payload.map((item) => {
                if (item.salaryAlowance == null) {
                  sumExtraCoach += 0;
                } else {
                  sumExtraCoach += parseInt(
                    item?.salaryAlowance?.extra_coaching
                  );
                }
              });
              setTotalExtraCocah(sumExtraCoach);

              let sumGrossSalary = 0;
              data.payload.map((item) => {
                if (item.pay_scale == null && item.salaryAlowance == null) {
                  sumGrossSalary += 0;
                } else {
                  sumGrossSalary +=
                    parseInt(item?.pay_scale?.basic) +
                    parseInt(item?.salaryAlowance?.all_allowance_total);
                }
              });
              setTotalGrossSalary(sumGrossSalary);

              // Change karna ha  all_allowance_total
              let sumAnnualIncr = 0;
              data.payload.map((item) => {
                if (item.pay_scale == null) {
                  sumAnnualIncr += 0;
                } else {
                  sumAnnualIncr += parseInt(item?.pay_scale?.increment);
                }
              });
              setTotalAnnualIncr(sumAnnualIncr);

              console.log(data.payload);
              setReportData(data.payload);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    const month = parseInt(parts[1], 10);
    const year = parts[0];

    // Create an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the full month name
    const fullMonth = monthNames[month - 1];

    // Format the result as "Month, Year"
    const formattedDate = `${fullMonth}, ${year}`;

    return formattedDate;
  }

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  let res = campusData.find(
                    (item) => item.id == e.target.value
                  );
                  setFullCampus(res);
                  setCampus(e.target.value);
                }}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="d-flex col-md-4">
            <button className="btn btn-primary mx-2 mt-9" onClick={onShow}>
              Show
            </button>
            {reportData.length > 0 && (
              <ReactToPrint
                pageStyle={pageStyle}
                documentTitle={`Gross Salary Sheet`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue mx-2 mt-9"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            )}
            <div>
              {isVisible && (
                <GrossSalarySheetExport
                  reportData={reportData}
                  TotalBasicPay={TotalBasicPay}
                  TotalIncrement={TotalIncrement}
                  TotalUgs={TotalUgs}
                  TotalScience={TotalScience}
                  TotalAdditional={TotalAdditional}
                  TotalExtraPeriod={TotalExtraPeriod}
                  Total2ndShift={Total2ndShift}
                  TotalHod={TotalHod}
                  TotalCollege={TotalCollege}
                  TotalHostel={TotalHostel}
                  TotalHifz={TotalHifz}
                  TotalOther={TotalOther}
                  TotalConva={TotalConva}
                  TotalExtraCocah={TotalExtraCocah}
                  TotalGrossSalary={TotalGrossSalary}
                  TotalAnnualIncr={TotalAnnualIncr}
                />
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div className="makeITScrollAAble mt-9">
            <Table
              style={{ width: "230%", marginTop: "15px", overflowY: "scroll" }}
              responsive
            >
              <thead>
                <tr
                  id="tbl"
                  style={{
                    position: "sticky",
                    top: "0",
                    zIndex: "2",
                    backgroundColor: "gainsboro",
                  }}
                >
                  <th className="text-center">Sr.No</th>
                  <th className="text-center">Code</th>
                  <th className="text-center">Name</th>
                  <th className="text-center">Basic Pay</th>
                  <th className="text-center">Increment Total</th>
                  <th className="text-center">UGS Allowance</th>
                  <th className="text-center">Science Allowance</th>
                  <th className="text-center">Additional Allowance</th>
                  <th className="text-center">Extra Period Allowance</th>
                  <th className="text-center">2nd Shift Allowance</th>
                  <th className="text-center">HOD Allowance</th>
                  <th className="text-center">College Allowance</th>
                  <th className="text-center">Hostel Allowance</th>
                  <th className="text-center">Hifz Allowance</th>
                  <th className="text-center">Other Allowance</th>
                  <th className="text-center">Conv Allow</th>
                  <th className="text-center">Extra Coaching</th>
                  <th className="text-center">Gross Salary</th>
                  <th className="text-center">Pay Scale</th>
                  <th className="text-center">Annual Increment</th>
                  <th className="text-center">Designation</th>
                  <th className="text-center">Account No.</th>
                </tr>
              </thead>

              <tbody>
                {reportData.length > 0 &&
                  reportData.map((subitem, index) => (
                    <tr id="tbl">
                      <td
                        style={{
                          color: "black",
                          backgroundColor: "white",
                        }}
                        className="text_align_center"
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          color: "black",
                          backgroundColor: "white",
                        }}
                        className="text_align_center"
                      >
                        {subitem.emp_code}
                      </td>
                      <td
                        style={{
                          color: "black",
                          backgroundColor: "white",
                        }}
                        className="text_align_center"
                      >
                        {subitem.full_name}
                      </td>

                      {subitem.pay_scale === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.pay_scale?.basic}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.increment}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.ugs}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.science}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.additional_allowance}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.extra_period}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.second_shift}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.hod}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.college}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.hostel}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.hifz}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.other}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.convance}
                        </td>
                      )}

                      {subitem.salaryAlowance === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.salaryAlowance?.extra_coaching}
                        </td>
                      )}

                      {subitem.pay_scale === null &&
                      subitem.salaryAlowance == null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {"----"}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {parseInt(subitem.pay_scale?.basic) +
                            parseInt(
                              subitem.salaryAlowance?.all_allowance_total
                            )}
                        </td>
                      )}

                      {subitem.pay_scale === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {"----"}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.pay_scale?.payscale}
                        </td>
                      )}

                      {/* Annual Increment */}
                      {subitem.pay_scale === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {0}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.pay_scale?.increment}
                        </td>
                      )}

                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.designation?.name}
                      </td>

                      {subitem.account_no === null ? (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {"Not Define"}
                        </td>
                      ) : (
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {subitem.account_no}
                        </td>
                      )}
                    </tr>
                  ))}
                {isVisible && (
                  <tr
                    id="tbl"
                    style={{
                      //   fontWeight: "bold",
                      backgroundColor: "gainsboro",
                    }}
                  >
                    <td></td>
                    <td></td>
                    <td className="text_align_center text-primary">Total</td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalBasicPay}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalIncrement}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalUgs}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalScience}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalAdditional}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalExtraPeriod}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {Total2ndShift}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalHod}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalCollege}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalHostel}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalHifz}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalOther}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalConva}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalExtraCocah}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalGrossSalary}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      ----
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {TotalAnnualIncr}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      ----
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      ----
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div className="row p-3">
            <img style={{ height: 120, width: 120 }} src={icon} />

            <div className="col-md-6">
              <h1>{unitName}</h1>
              <h4>
                Gross Salary Sheet Of {fullCampus.name} As Per {formattedDate}
              </h4>
              <h5>Printing Date: {displayDate}</h5>
            </div>
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <b>Sr.No</b>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <b>Code</b>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {/* <p> */}
                  <b>Employee Name</b>
                  {/* </p> */}
                  {/* <br /> */}
                  {/* <p> */}
                  <b>Account No.</b>
                  {/* </p> */}
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{
                    border: "1px solid black",
                    // transform: "rotate(270deg)",
                    // maxWidth: "200px",
                    // height: "150px",
                  }}
                >
                  <p>
                    <b>Basic Pay</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{
                    border: "1px solid black",
                    // transform: "rotate(270deg)",
                    // maxWidth: "200px",
                    // height: "150px",
                  }}
                >
                  <p>
                    <b>Incr. Total</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{
                    border: "1px solid black",
                    // transform: "rotate(270deg)",
                    // maxWidth: "200px",
                    // height: "150px",
                  }}
                >
                  <p>
                    <b>UGS Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{
                    border: "1px solid black",
                    // transform: "rotate(270deg)",
                    // maxWidth: "200px",
                    // height: "150px",
                  }}
                >
                  <p>
                    <b>Science Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Add. Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Ext. Per. Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>2nd Shift Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>HOD Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>College Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Hostel Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Hifz Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Other Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Conva. Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Ext. Co. Allow.</b>
                  </p>
                </td>
                <td
                  className="hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Gross Salary</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Annual Incre.</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <b>Designation</b>
                  <br />
                  <b>Payscale</b>
                </td>
              </tr>
            </thead>

            <tbody>
              {reportData.length > 0 &&
                reportData.map((subitem, index) => (
                  <tr id="tbl">
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {subitem.emp_code}
                    </td>
                    {subitem.account_no == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.full_name} <br />
                        {"Not Define"}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.full_name} <br /> {subitem.account_no}
                      </td>
                    )}

                    {subitem.pay_scale == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.pay_scale?.basic}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.increment}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.ugs}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.science}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.additional_allowance}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.extra_period}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.second_shift}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.hod}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.college}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.hostel}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.hifz}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.other}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.convance}
                      </td>
                    )}

                    {subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.salaryAlowance?.extra_coaching}
                      </td>
                    )}

                    {subitem.pay_scale == null &&
                    subitem.salaryAlowance == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {parseInt(subitem.pay_scale?.basic) +
                          parseInt(subitem.salaryAlowance?.all_allowance_total)}
                      </td>
                    )}

                    {subitem.pay_scale == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.pay_scale?.increment}
                      </td>
                    )}

                    {subitem.pay_scale == null ? (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {0}
                      </td>
                    ) : (
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.designation?.name} <br />{" "}
                        {subitem.pay_scale?.payscale}
                      </td>
                    )}
                  </tr>
                ))}
              <tr
                id="tbl"
                // style={{
                //   //   fontWeight: "bold",
                //   backgroundColor: "gainsboro",
                // }}
              >
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                ></td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                ></td>
                <td
                  style={{
                    fontSize: "16px",
                    color: "black",
                    border: "1px solid black",
                  }}
                  className="text_align_center text-primary"
                >
                  <b>Total</b>
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalBasicPay}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalIncrement}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalUgs}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalScience}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalAdditional}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalExtraPeriod}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {Total2ndShift}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalHod}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalCollege}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalHostel}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalHifz}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalOther}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalConva}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalExtraCocah}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalGrossSalary}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {TotalAnnualIncr}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  ----
                </td>
              </tr>
            </tbody>
          </table>
          <div className="endTotalGlossNet">
            <div>
              <p>
                <b style={{ fontSize: "22px" }} className="text-primary">
                  Total Gross Salary:
                </b>{" "}
                <b style={{ fontSize: "22px" }}>{TotalGrossSalary}</b>
              </p>
            </div>
          </div>
        </div>
        {/* {loginAccount == "Campus" ? <PasswordModal /> : ""} */}
      </Paper>
    </>
  );
};

export default GrossSalarySheet;
