import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DatePicker } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { baseUrl } from "../../../services/config";
import Select from "react-select";
import dayjs from "dayjs";
import MySelect from "../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;
const EditNotification = ({
  open,
  close,
  campusData,
  type,
  reloader,
  selected,
}) => {
  // const user = useSelector((state) => state.user.user);
  // const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState([]);
  const [typeId, setTypeId] = useState(selected?.notification_type?.id);
  const [title, setTitle] = useState(selected.title);
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [description, setDescription] = useState(selected.description);
  const [loading, setLoading] = useState(false);
  const [isanysubject, setisanysubject] = useState(false);
  const [optionSelected, setoptionSelected] = useState([]);
  let authtoken = localStorage.getItem("userToken");
  const dateFormat = "YYYY-MM-DD";
  console.log(selected);
  const datePicker = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setStartDate(dateString[0]);
    setendDate(dateString[1]);
  };
  useEffect(() => {
    if (selected) {
      setTitle(selected.title);
      setDescription(selected.description);
      setTypeId(selected?.notification_type?.id);
      setStartDate(selected.start_date);
      setendDate(selected.end_date);
      if (selected.campus_ids) {
        if (typeof selected.campus_ids == "number") {
          let arr = [];
          campusData.filter((sub) => {
            if (selected.campus_ids == sub.value) {
              arr.push(sub);
            }
          });
          setoptionSelected(arr);
          // setCampusId(arr)
        } else {
          let arr = [];
          selected.campus_ids.filter((item) =>
            campusData.filter((sub) => {
              if (item == sub.value) {
                arr.push(sub);
              }
            })
          );
          console.log(arr);
          // setCampusId(arr)
          setoptionSelected(arr);
        }
      }
    }
  }, [selected]);

  const handleAdd = () => {
    if (
      typeId == "" ||
      title == "" ||
      description == "" ||
      campusId.length == 0
    ) {
      toast.error("Please Enter All Fields");
      return;
    }
    let campus = [];
    campusId.map((item) => {
      campus.push(item.value);
    });
    setLoading(true);
    const data = {
      notification_type_id: typeId,
      title: title,
      description: description,
      campus_ids: campus,
      start_date: typeId == "7" ? startDate : "",
      end_date: typeId == "7" ? endDate : "",
    };
    fetch(`${baseUrl}/notifications/${selected.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        toast.success("Updated Successfully");
        close();
        reloader();
        setTitle("");
        setDescription("");
        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const handleChangeSubjects = (selected) => {
    console.log(selected);
    // if (optionSelected.length == 30 && selected.length == 30) {
    //     setoptionSelected([])
    //     return
    // }
    setoptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setCampusId([...valuearray]);
    if (valuearray.length == 0) {
      setisanysubject(true);
      return;
    }
    setisanysubject(false);
  };
  return (
    <>
      <Toaster />
      <Modal size="lg" show={open}>
        <Modal.Header>
          <h3>Update Notification</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <label>
                  <b>Select Campus</b>
                </label>
                {/* <div className="d-flex flex-column">
                      <select
                        style={{ height: 40 }}
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setCampusId(e.target.value)}
                      >
                        <option selected value="">
                          {" "}
                          Select Campus
                        </option>
                        {campusData &&
                          campusData.map((item) => (
                            <option value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div> */}
                {/* {campusId.length>0?
             <Select
    options={campusData}
    isMulti
    defaultValue={campusId}
    onChange={(e) => setCampusId(e)}
  />
      :''}       */}

                <MySelect
                  options={campusData}
                  isMulti
                  name="selectedOptions"
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  components={{ Option, MultiValue, animatedComponents }}
                  onChange={handleChangeSubjects}
                  allowSelectAll={true}
                  value={optionSelected}
                />
              </div>
              <div className="col-sm-6">
                <label>
                  <b>Select Type</b>
                </label>
                <div className="d-flex flex-column">
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    aria-label="Default select example"
                    value={typeId}
                    onChange={(e) => setTypeId(e.target.value)}
                  >
                    <option selected value="">
                      {" "}
                      Select Type
                    </option>
                    {type &&
                      type.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <label>
                  <b>Title</b>
                </label>
                <input
                  placeholder="Enter Title"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              {typeId == "7" ? (
                <div className="col-md-6">
                  <labe>
                    <b>Select Start and End Date</b>
                  </labe>
                  <RangePicker
                    defaultValue={[
                      dayjs(selected.start_date, dateFormat),
                      dayjs(selected.end_date, dateFormat),
                    ]}
                    style={{ marginTop: "10px", height: "40px" }}
                    onChange={datePicker}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label>
                  <b>Description</b>
                </label>
                <textarea
                  placeholder="Enter Description..."
                  style={{ width: "100%" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => {
              setCampusId([]);
              close();
            }}
            className="btn btn-danger"
          >
            Cancel
          </button>
          <button
            disabled={loading}
            onClick={handleAdd}
            className="btn btn-primary"
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditNotification;
