import React, { useState } from 'react'
import { Paper } from '@material-ui/core'




const PrintStudentFeebill = () => {


    const [admission_id, setadmission_id] = useState('')
    const [registraion_id, setregistraion_id] = useState('')





    return (
        <div>
            <Paper>


                <div className='row p-5'>
                    <div className='col-md-4'>
                        <input
                            className='form-control'
                            onChange={(e) => setadmission_id(e.target.value)}
                            placeholder='Admission Id'
                        />
                    </div>
                    <div className='col-md-4'>
                        <input
                            className='form-control'
                            onChange={(e) => setregistraion_id(e.target.value)}
                            placeholder='Registertion Id'
                        />
                    </div>
                    <div className='col-md-4'>
                        <button className='btn btn-primary'>
                            Search
                        </button>
                    </div>
                </div>
            </Paper>
        </div>

    )
}

export default PrintStudentFeebill