import React, { useState } from "react";
import { useFormik, Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormikController from "../../Staff/Formik/FormikController";
import { baseUrl } from "../../../../services/config";
import { Link, useHistory } from "react-router-dom";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../../_metronic/_partials/controls";
import { Modal, Tab, Table, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import MyAuthToken from "../../../../services/authToken";
import { useAlert } from "react-alert";

const AddVehicle = ({ show, onHide }) => {
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);
  console.log(user);

  const history = useHistory();
  const authToken = MyAuthToken();
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please Fill all Fields");
  const [loading, setloading] = useState(false);

  const initialValues = {
    issubmitted: false,
    rp_number: "",
    model: "",
    seats: "",
  };

  const VehicaleValidationSchema = Yup.object().shape({
    rp_number: Yup.string().required("Vehicle Regestration Number Is Required"),
    model: Yup.string().required("Model is Required"),
    seats: Yup.string().required("Please Add Seats"),
  });

  const addvehicle = (data) => {
    setloading(true);
    const fdata = {
      campus_id: user.campuses[0].id,
      rp_number: data.rp_number,
      model: data.model,
      seats: data.seats,
    };

    fetch(`${baseUrl}/vehicle`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);

          if (data.metadata) {
            if (data.metadata.success) {
              alert.show("New Vehicle Addedd");
              history.push("/vehicle");
            }
          } else {
            alert.show("Error Occured");
            history.push("/vehicle");
          }
        })
      )
      .catch((err) => {
        alert.error("Wrong Details or Something wrong!", err);
        // console.log(err)
        history.push("/vehicle");
        setloading(false);
      });
  };

  const FORMGROUP = ({ children }) => {
    return <div className="form-group row">{children}</div>;
  };

  //starts here
  const createEroors = (errors, touched, isSubmitting, values) => {
    if (errors.rp_number && touched.rp_number) {
      setshowerror(errors.rp_number);
      setshowerr(true);
      return;
    }
    if (errors.model && touched.model) {
      setshowerror(errors.model);
      setshowerr(true);
      return;
    }
    if (errors.seats && touched.seats) {
      setshowerror(errors.seats);
      setshowerr(true);
      return;
    }

    setshowerr(false);

    if (Object.keys(errors).length === 0) {
      if (values.payscale != "") {
        if (isSubmitting) {
          if (!values.issubmitted) {
            values.issubmitted = true;
            addvehicle(values);
          }
        }
      }
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      // onHide={() => setmodalvisible(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          New Vehicle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="card-header padding_zero">
            <div className="card-title ">
              {/* <h3 className="card-label">
                New Vehicle |
              </h3> */}
              <div className="text-center">
                <b>Enter Vehicle details and submit</b>
              </div>
              {/* <small>Enter Vehicle details and submit</small> */}
            </div>
          </div>
          {loading ? (
            <b style={{ color: "green" }}>
              Updating Please Wait <span className="spinner-border"></span>
            </b>
          ) : (
            <div className="card-body padding_zero">
              <div className="row padding_zero">
                <div className="col-1"></div>

                <div className="col-10">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={VehicaleValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      // addvehicle(values)
                      //  VFGFCXZXCVFGFCXC VBCXV
                    }}
                  >
                    {(props) => {
                      const {
                        handleSubmit,
                        errors,
                        touched,
                        values,
                        isSubmitting,
                        isValid,
                      } = props;
                      createEroors(errors, touched, isSubmitting, values);

                      return (
                        <Form
                          onSubmit={handleSubmit}
                          className="form form-label-right"
                        >
                          <div className="card-body">
                            <div className="container">
                              <div>
                                <div className="row justify-content-between text-left">
                                  <div className="form-group col-sm-12 flex-column d-flex">
                                    <label className="form-control-label">
                                      <b>Regestration No *</b>
                                    </label>
                                    <Field
                                      type="text"
                                      name="rp_number"
                                      component={Input}
                                      placeholder="Vehicle Regestration No"
                                    />
                                    <label className="form-control-label">
                                      <b>Model *</b>
                                    </label>
                                    <Field
                                      type="text"
                                      name="model"
                                      component={Input}
                                      placeholder="Model"
                                    />
                                    <label className="form-control-label">
                                      <b>Seats *</b>
                                    </label>
                                    <Field
                                      type="text"
                                      name="seats"
                                      component={Input}
                                      placeholder="Seats"
                                    />
                                  </div>
                                </div>
                                {/* <FORMGROUP>
                                <FormikController
                                  name='rp_number'
                                  control='input'
                                  type='input'
                                  colCount='col-12'
                                  label='Vehicle Regestration No'
                                />
                              

                              </FORMGROUP> */}

                                {/* <FORMGROUP>
                                <FormikController
                                  name='model'
                                  control='input'
                                  type='input'
                                  colCount='col-12'
                                  label='Model'
                                />
                              </FORMGROUP> */}

                                {/* <FORMGROUP>
                                <FormikController
                                  name='seats'
                                  control='input'
                                  type='input'
                                  colCount='col-12'
                                  label='Seats'

                                />
                              


                              </FORMGROUP> */}
                                {showerr ? (
                                  <div id="err">
                                    <Alert
                                      variant="danger"
                                      onClose={() => setshowerr(false)}
                                      dismissible
                                    >
                                      <Alert.Heading>
                                        Oops You Got an Error!
                                      </Alert.Heading>
                                      <p>{showerror}</p>
                                    </Alert>
                                  </div>
                                ) : null}
                                <div className="d-flex  justify-content-end my-4">
                                  <div>
                                    <button
                                      type="submit"
                                      value="Submit"
                                      onClick={() => handleSubmit()}
                                      className="btn btn-light-primary button_blue font-weight-bold px-9 py-4 my-3 mx-4"
                                    >
                                      Submit
                                    </button>
                                    <Link to="/vehicle">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn_width  font-weight-bold px-9 py-4 my-3 mx-4"
                                      >
                                        Cancel
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddVehicle;
