import React, { useState, useEffect } from 'react'

import { Input, DatePickerField, Select } from "../../../../_metronic/_partials/controls";
import { useAlert } from 'react-alert'
import { useFormik, Field, Form, Formik, ErrorMessage } from 'formik'

import * as Yup from 'yup'
import FormikController from '../../Staff/Formik/FormikController'
import { baseUrl } from '../../../../services/config'
import { Link, useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import authToken from "../../../../services/authToken";
import { values } from 'lodash';

const AddBankForm = ({ show, onHide, setbankaccountparent }) => {
  const history = useHistory()
  const jwt_token = authToken();
  const [loading, setloading] = useState(false)

  const [allbankgroups, setallbankgroups] = useState([])
  const [allsubaccounts, setallsubaccounts] = useState([])

  const [parentbankcategoryid, setparentbankcategoryid] = useState(setbankaccountparent==undefined?'':setbankaccountparent.id)
  const [parentbankcategorytitle, setparentbankcategorytitle] = useState(setbankaccountparent==undefined?'':setbankaccountparent.title)

  const alert = useAlert()
  const initialValues = {
    "sub_account_id": "",
    "bank_name": "",
    "bank_branch": "",
    "account_title": parentbankcategorytitle,
    "account_number": "",
    "account_head": "",
    "bank_account_category_id": parentbankcategoryid



  };


  useEffect(() => {


    getAllBankGroupsList()
    getAlSubAccountsList()

  }, [])

  const getAlSubAccountsList = (async () => {


    await fetch(`${baseUrl}/sub_account_only_banks`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwt_token}`
      }
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data.metadata) {
          if (data.metadata.success) {
            setallsubaccounts(data.payload)
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log("sub accounts", err)
      })


  })


  const getAllBankGroupsList = (async () => {
    // bank_account_category
    // sub_account_only_banks
    // bank_account


    await fetch(`${baseUrl}/bank_account_category`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwt_token}`
      }
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data.metadata) {
          if (data.metadata.success) {
            setallbankgroups(data.payload)
            // data.payload.map((item, index) => {
            //   console.log(item.title)
            //   console.log(setbankaccountparent)
            //   if (item.title == setbankaccountparent.title) {
            //     setparentbankcategorytitle(item.title)
            //     setparentbankcategoryid(item.id)
            //     console.log(item.id)
            //     console.log(item.title)
            //   }
            // })
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log("bank accounts", err)
      })


  })


  const AddNewBankHandler = (values) => {

    setloading(true);
    const data = {
      sub_account_id: values.sub_account_id,
      bank_name: values.bank_name,
      bank_branch: values.bank_branch,

      account_title: values.account_title,
      account_number: values.account_number,
      account_head: values.account_head,

      bank_account_category_id: values.bank_account_category_id,


    }


    console.log(data);




    fetch(`${baseUrl}/bank_account`, {

      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt_token}`
      },
      body: JSON.stringify(data)
    }).then(res => res.json()
      .then(data => {
        console.log(data)
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success('Bank Added Successfully')
            setloading(false)
            if (onHide == undefined) {
              history.push('/bank')
            } else {
              onHide(false)
            }
          } else {
            alert.error('Something went wrong')
            setloading(false)
            if (onHide == undefined) {
              history.push('/bank')
            } else {
              onHide(false)
            }
          }
        } else {
          alert.error('Something went wrong')
          setloading(false)
          if (onHide == undefined) {
            history.push('/bank')
          } else {
            onHide(false)
          }
        }




      })
    ).catch(err => {
      alert.success('Error Occured While Creating Bank')
      setloading(false)
      console.log(err)
      if (onHide == undefined) {
        history.push('/bank')
      } else {

        onHide(false)
      }

    })


  }


  const BankValidationSchema = Yup.object().shape({

    sub_account_id: Yup.string()
      .required('Sub Account Is Required'),
    bank_name: Yup.string()
      .required('Bank Name is Required'),

    bank_branch: Yup.string()
      .required('Branch Name is required'),



    account_title: Yup.string()

      .required('Account Title is Required '),

    account_number: Yup.string()
      .required('Account Number is Required '),

    account_head: Yup.string()
      .required('Account Head is Required '),
    bank_account_category_id: Yup.string()
      .required('Bank Account Category is Required '),
  });

  const onChangeBankGroup = ((e, setFieldValue) => {
    let get_val = e.target.selectedOptions[0].getAttribute("titel");
    setFieldValue("account_title", get_val)
  })
  const onChangeSubAccount = ((e, setFieldValue) => {
    let get_val = e.target.selectedOptions[0].getAttribute("acode");
    setFieldValue("account_head", get_val)
  })


  return (
    <Modal
      size="lg"
      show={show}

      // onHide={() => setmodalvisible(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >

      <Modal.Header className='modal_header_style' closeButton>
        <Modal.Title className="Modal_Heading_font" id="contained-modal-title-vcenter">
          Add Bank
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div>
          <div className='container-fluid padding_zero' >
            <div style={{ marginTop: "0px", border: "0px" }} className="card card-custom gutter-b ">
              <div className="card-body padding_zero">
                <div className='row padding_zero' >


                  <div className='col-12' >


                    <Formik
                      initialValues={initialValues}
                      validationSchema={BankValidationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        AddNewBankHandler(values)

                      }}
                    >
                      {/* {(props) => {
                      const { handleSubmit, errors, touched, values, isSubmitting, isValid } = props
                      console.log(JSON.stringify(values)) */}
                      {/* {({ handleSubmit, handleChange, handleBlur, setFieldValue, isSubmitting, isValid, errors, touched, values }) => ( */}
                      {(props) => {
                        const { handleSubmit, errors, setFieldValue, setValues, touched, values, resetForm, isSubmitting, isValid, setSubmitting, handleChange, handleBlur } = props
                        return (
                          <>

                            {
                              loading ? <b>Bank Adding Please Wait {` `} <span className="spinner-border"></span></b> :

                                <Form
                                // onSubmit={handleSubmit}
                                >

                                  <div className="card-body padding_zero">
                                    <div className='container padding_zero' >


                                      <div >





                                        <div className="row">
                                          <div className="row mt-3">
                                            <div className="col-md-6">

                                              <label>Select  Bank Account Group <span style={{ color: "red" }}>*</span></label>
                                              <select
                                                className="form-select form-control"
                                                name="bank_account_category_id"
                                                value={values.bank_account_category_id}

                                                onChange={(e) => { props.handleChange(e); onChangeBankGroup(e, setFieldValue) }}

                                                // onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ backgroundColor: 'white' }}
                                                aria-label="Default select example"
                                              >
                                                <option titel="" selected={values.bank_account_category_id==''?true:false} value=""  >
                                                  Select...
                                                </option>
                                                {
                                                  allbankgroups.map(item => (

                                                    <option titel={item.title} value={item.id}>{item.title}</option>
                                                  ))


                                                }
                                              </select>
                                              {
                                                errors.bank_account_category_id &&
                                                touched.bank_account_category_id &&
                                                values.bank_account_category_id==''&&
                                                <div style={{ color: "#f87d8a" }} className="input-feedback">
                                                  {errors.bank_account_category_id}
                                                </div>
                                              }



                                            </div>
                                            <div className="col-md-6">
                                              <label>Select Sub Account <span style={{ color: "red" }}>*</span></label>
                                              <select
                                                className="form-select form-control"
                                                name="sub_account_id"
                                                value={values.sub_account_id}
                                                onChange={e => { props.handleChange(e); onChangeSubAccount(e, setFieldValue) }}
                                                onBlur={handleBlur}
                                                style={{ backgroundColor: 'white' }}
                                                aria-label="Default select example"
                                              >
                                                <option acode="" selected value=""  >
                                                  Select...
                                                </option>
                                                {
                                                  allsubaccounts.map(item => (
                                                    <option acode={item.acode} value={item.id}>{item.title}</option>
                                                  ))
                                                }
                                              </select>
                                              {
                                                errors.sub_account_id &&
                                                touched.sub_account_id &&
                                                <div style={{ color: "#f87d8a" }} className="input-feedback">
                                                  {errors.sub_account_id}
                                                </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="row mt-3">
                                            <div className="col-md-6">
                                              <label>Bank Name <span style={{ color: "red" }}>*</span>
                                              </label>
                                              <Field
                                                type="text"
                                                value={values.bank_name}
                                                name="bank_name"
                                                component={Input}
                                                placeholder="Bank Name"
                                                maxLength={40}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <label>Bank Branch <span style={{ color: "red" }}>*</span>
                                              </label>
                                              <Field
                                                type="text"
                                                value={values.bank_branch}
                                                name="bank_branch"
                                                component={Input}
                                                placeholder="Bank Branch"
                                                maxLength={40}
                                              />
                                            </div>
                                          </div>
                                          <div className="row mt-3">
                                            <div className="col-md-6">
                                              <label>Account Title <span style={{ color: "red" }}>*</span>
                                              </label>
                                              <Field
                                                type="text"
                                                value={values.account_title}
                                                name="account_title"
                                                component={Input}
                                                placeholder="Account Title"
                                                maxLength={40}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <label>Account Number <span style={{ color: "red" }}>*</span>
                                              </label>
                                              <Field
                                                type="text"
                                                value={values.account_number}
                                                name="account_number"
                                                component={Input}
                                                placeholder="Account Number"
                                                maxLength={40}
                                              />
                                            </div>

                                            <div className="row mt-3">
                                              <div className="col-md-6">
                                                <label>Account Head <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  value={values.account_head}
                                                  name="account_head"
                                                  component={Input}
                                                  placeholder="Account Head"
                                                  maxLength={40}
                                                />
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                            }
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                              <button className="btn btn-success button_blue button_blue" onClick={() => handleSubmit()} type="submit" value="Submit">
                                {
                                  loading ? <span className="spinner-border"></span> : <>Submit</>
                                }
                              </button>
                              <button onClick={() => {
                                if (onHide == undefined) {
                                  history.push('/bank')
                                } else {

                                  onHide(false)
                                }
                              }
                              }
                                className="btn btn-danger btn_width ml-3" >Cancel</button>
                            </div>
                          </>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default AddBankForm
