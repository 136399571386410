import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const GrossSalarySheetExport = ({
  reportData,
  TotalBasicPay,
  TotalIncrement,
  TotalUgs,
  TotalScience,
  TotalAdditional,
  TotalExtraPeriod,
  Total2ndShift,
  TotalHod,
  TotalCollege,
  TotalHostel,
  TotalHifz,
  TotalOther,
  TotalConva,
  TotalExtraCocah,
  TotalGrossSalary,
  TotalAnnualIncr,
}) => {
  const tableRef = useRef(null);

  return (
    <>
      <DownloadTableExcel
        filename="Gross Salary Sheet"
        sheet="Gross Salary Sheet"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2 mt-9">Export</button>
      </DownloadTableExcel>
      <div id="tableboot mt-5">
        <Table ref={tableRef} style={{ display: "none" }}>
          <thead>
            <tr id="tbl">
              <th className="text-center">Sr.No</th>
              <th className="text-center">Code</th>
              <th className="text-center">Name</th>
              <th className="text-center">Basic Pay</th>
              <th className="text-center">Increment Total</th>
              <th className="text-center">UGS Allowance</th>
              <th className="text-center">Science Allowance</th>
              <th className="text-center">Additional Allowance</th>
              <th className="text-center">Extra Period Allowance</th>
              <th className="text-center">2nd Shift Allowance</th>
              <th className="text-center">HOD Allowance</th>
              <th className="text-center">College Allowance</th>
              <th className="text-center">Hostel Allowance</th>
              <th className="text-center">Hifz Allowance</th>
              <th className="text-center">Other Allowance</th>
              <th className="text-center">Conv Allow</th>
              <th className="text-center">Extra Coaching</th>
              <th className="text-center">Gross Salary</th>
              <th className="text-center">Pay Scale</th>
              <th className="text-center">Annual Increment</th>
              <th className="text-center">Designation</th>
              <th className="text-center">Account No.</th>
            </tr>
          </thead>

          <tbody>
            {reportData.length > 0 &&
              reportData.map((subitem, index) => (
                <tr id="tbl">
                  <td
                    style={{
                      color: "black",
                      border: "1px solid black",
                    }}
                    className="text_align_center"
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      color: "black",
                      border: "1px solid black",
                    }}
                    className="text_align_center"
                  >
                    {subitem.emp_code}
                  </td>
                  {subitem.account_no == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.full_name} <br />
                      {"Not Define"}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.full_name} <br /> {subitem.account_no}
                    </td>
                  )}

                  {subitem.pay_scale == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.pay_scale?.basic}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.increment}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.ugs}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.science}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.additional_allowance}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.extra_period}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.second_shift}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.hod}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.college}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.hostel}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.hifz}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.other}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.convance}
                    </td>
                  )}

                  {subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.salaryAlowance?.extra_coaching}
                    </td>
                  )}

                  {subitem.pay_scale == null &&
                  subitem.salaryAlowance == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {parseInt(subitem.pay_scale?.basic) +
                        parseInt(subitem.salaryAlowance?.all_allowance_total)}
                    </td>
                  )}

                  {subitem.pay_scale == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.pay_scale?.increment}
                    </td>
                  )}

                  {subitem.pay_scale == null ? (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {0}
                    </td>
                  ) : (
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.designation?.name} <br />{" "}
                      {subitem.pay_scale?.payscale}
                    </td>
                  )}
                </tr>
              ))}

            <tr id="tbl">
              <td></td>
              <td></td>
              <td className="text_align_center text-primary">Total</td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalBasicPay}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalIncrement}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalUgs}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalScience}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalAdditional}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalExtraPeriod}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {Total2ndShift}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalHod}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalCollege}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalHostel}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalHifz}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalOther}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalConva}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalExtraCocah}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalGrossSalary}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                ----
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {TotalAnnualIncr}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                ----
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                ----
              </td>
            </tr>
          </tbody>
          <div className="endTotalGlossNet">
            <div>
              <p>
                <b style={{ fontSize: "22px" }} className="text-primary">
                  Total Gross Salary:
                </b>{" "}
                <b style={{ fontSize: "22px" }}>{TotalGrossSalary}</b>
              </p>
            </div>
          </div>
        </Table>
      </div>
    </>
  );
};

export default GrossSalarySheetExport;
