import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { baseUrl } from '../../../../services/config'
import { useAlert } from 'react-alert'
const EditSingleFee = ({ show, close, feeObject, reload }) => {

    const authtoken = localStorage.getItem("userToken");
    const alert = useAlert()
    const [loading, setloading] = useState(false)
    const [amount, setamount] = useState(feeObject.amount)
    

    console.log("edit object=>", feeObject)


    const HandleEdit = () => {
        enableLoading()
        const data = {
            fee_challan_detail_id: feeObject.id,
            amount: amount ? amount : feeObject.amount,
            student_id: feeObject?.fee_challan.student_id
        }


        fetch(`${baseUrl}/edit_challan_detail_in_feesubmit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`,
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then((data) => {
                console.log('edit single fee',data)
                if (data.metadata.success) {
                    close()
                    alert.success(data.metadata.message)
                    reload()
                    disableLoading()
                } else {
                    disableLoading()
                }

            }).catch(err => {
                console.log(err)
                disableLoading()

            })
    }



    const enableLoading = () => {
        setloading(true)
    }
    const disableLoading = () => {
        setloading(false)
    }


    return (
        <Modal
            onHide={close}
            show={show}
        >
            <Modal.Header>
                <Modal.Title>Edit Single Fee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                {
                    loading ?
                        <Spinner className='text-center' animation='border' variant='primary' />
                        :
                        <input
                            defaultValue={feeObject.amount}
                            onChange={(e) => setamount(e.target.value)}
                            className='form-control'
                        />

                }
            </Modal.Body>
            <Modal.Footer>
                <button onClick={close} className='btn btn-danger'>
                    Cancel
                </button>
                <button
                    onClick={HandleEdit}
                    className='btn btn-primary'>
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditSingleFee