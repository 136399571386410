import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import Paper from "@material-ui/core/Paper";
//import EditAppointedStaff from "./EditAppointedStaff/EditAppointedStaff";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Pagination from "@material-ui/lab/Pagination";

import SVG from "react-inlinesvg";
//import DefineSallary from "./DefineSallary/DefineSallary";
import { Link, useHistory } from "react-router-dom";

import GenerateSallary from "./GpFund";
import GpFund from "./GenerateGBFund";
import { useAlert } from "react-alert";
{
  /*
import GenerateGBFund from "./GenrateGBFund/GenerateGBFund";
import ViewStaffDetails from "./ViewStaffDetails/ViewStaffDetails";
import ViewStafStudents from "./ViewStafStudents/ViewStafStudents.js";
*/
}

//import usePagination from "../../../../components/usePagination/usePagination";

const StaffGPFund = () => {
  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  // console.log(user.user.campus.id)
  const [searchtext, setsearchtext] = useState("");
  const [generateModal, setgenerateModal] = useState(false);
  const [generateGBModal, setgenerateGBModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [currentObject, setcurrentObject] = useState({});
  const [studentDetails, setStudentDetails] = useState([]);
  const [AllStaff, setAllStaff] = useState([]);
  const [viewModal, setviewModal] = useState(false);
  const [studentView, setStudentView] = useState(false);
  const [defineSalaryModal, setdefineSalaryModal] = useState(false);
  const [viewLoading, setviewLoading] = useState(false);

  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [campusID, setcampusID] = useState("");
  const [employee_id, setEmployeeID] = useState("");

  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  const alert = useAlert();

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      getAllStaff(campusidget);
    }

    // getAllCampus();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };
  const getAllStaff = (e) => {
    let id = "";
    if (e == undefined) {
      id = campusID;
    } else {
      id = e;
    }
    setcampusID(id);

    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });

    // fetch(`${baseUrl}/emp_appointed/${id}/1`, {
    //   method: 'GET',
    //   headers: {
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     const arr=data.payload
    //     arr.forEach((item,i)=>{
    //       item["Sr"]=i+1
    //     })
    //     setAllStaff(arr);
    //     setloading(false);
    //   })
    //   .catch((err) => {
    //     setloading(false);
    //     console.log(err);
    //   });
  };

  const openEditModal = (campus) => {
    setcurrentObject(campus);
    seteditModal(true);
  };
  const showStudents = (staff) => {
    console.log(staff);
    setStudentDetails(staff.students);
    setStudentView(true);
  };

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;
  // const currentPosts = AllStaff.slice(indexOfFirstPost, indexOfLastPost);

  const dataSearch = AllStaff.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.full_name?.toLowerCase().includes(text) ||
          user?.emp_code?.toLowerCase().includes(text)
      : true;
  });
  const currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  // const dataSearch = currentPosts.filter((user) => {
  //   let text = searchtext.toLowerCase();
  //   return searchtext ? user?.full_name?.toLowerCase().includes(text)||user?.emp_code?.toLowerCase().includes(text) : true;
  // });

  const defineSalaryFunc = (staff) => {
    setcurrentObject(staff);
    setdefineSalaryModal(true);
  };

  const gotoDetails = (id, staff) => {
    setStudentDetails(staff.students);
    setviewLoading(true);
    fetchDetails(id);
  };

  const fetchDetails = async (id) => {
    await fetch(`${baseUrl}/emp-salary-detail/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then(async (res) => {
        res.json().then((data) => {
          // console.log(data)
          setcurrentObject(data.payload);
          setviewModal(true);
          setviewLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setviewLoading(false);
      });
  };

  const opensalarygenerate = (campus) => {
    setcurrentObject(campus);
    setgenerateModal(true);
  };
  const opengenerateGB = (staff) => {
    setcurrentObject(staff);
    setgenerateGBModal(true);
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(dataSearch.length / PER_PAGE);
  //const _DATA = usePagination(dataSearch, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    // _DATA.jump(p);
  };

  const searchbyRegId = (e) => {
    e.preventDefault();
    const data = {
      emp_id: employee_id.toLocaleUpperCase(),
    };

    fetch(`${baseUrl}/employee-search-for-gpfund`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          if (data.payload.length == 0) {
            alert.error("Match not found");
          }
          setAllStaff(data.payload);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg[0]);
          });
        }
      })
      .catch((err) => {
        console.log("err is re", err);
      });
  };

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <form onSubmit={searchbyRegId}>
          <div className="row mx-1">
            <div className="col-md-4">
              <label>
                <b>
                  Enter Employee ID <span style={{ color: "red" }}>*</span>
                </b>
              </label>
              <input
                onChange={(e) => setEmployeeID(e.target.value)}
                className="form-control"
                placeholder="Enter Employee ID"
              />
            </div>
            <div className="col-md-4">
              <button type="submit" className="btn btn-primary mt-9">
                Search
              </button>
            </div>
          </div>
        </form>
        <Table responsive className="mt-5">
          <thead>
            <tr className="text-center">
              <th>Emp ID</th>
              <th>Full Name</th>
              <th>Cnic No</th>
              <th>Emp Code</th>
              <th>Mobile No</th>
              <th>Joining Date</th>
              <th>GP Fund</th>
              <th>Pay Now</th>
            </tr>
          </thead>

          {AllStaff.map((campus, index) => (
            <tbody>
              <tr className="text-center" key={index}>
                <td>{campus.id}</td>
                <td>{campus.full_name}</td>
                <td>{campus.cnic_no}</td>
                <td>{campus.emp_code}</td>
                <td>{campus.phone}</td>
                <td>{campus.joining_date}</td>
                <td>{campus.g_p_fund.collected_amount}</td>
                <td>
                  {campus.pay_scale_id !== null ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => opensalarygenerate(campus)}
                      className={"svg-icon svg-icon-md svg-icon-primary"}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Unlock.svg"
                        )}
                      />
                    </span>
                  ) : (
                    <span
                      title="You need to Define Salary First"
                      className="svg-icon svg-icon-md svg-icon-primary"
                    >
                      No
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          ))}
        </Table>

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Pagination
          color="primary"
          count={count}
          size="large"
          page={page}
          variant="text"
          shape="round"
          onChange={handleChange}
        />

        <Modal show={generateModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Pay GP Fund
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <GpFund
              empDetails={currentObject}
              show={() => setgenerateModal()}
              reload={getAllStaff}
              campusID={campusID}
            />
          </Modal.Body>
        </Modal>

        {/* View Modal  */}
        {/*}     <Modal onHide={() => setviewModal(false)} show={viewModal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              View Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewStaffDetails
              EmpDetails={currentObject}
              studentDetails={studentDetails}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setviewModal(false)}
              className="btn btn-danger btn_width"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
                  */}
        {/* <Modal onHide={() => setStudentView(false)} show={studentView} size='xl'>
          <Modal.Header className='modal_header_style' closeButton>
            <Modal.Title className='Modal_Heading_font' id='contained-modal-title-vcenter'>
              View Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewStafStudents  studentDetails={studentDetails} />
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setStudentView(false)} className='btn btn-danger btn_width'>
              Close
            </button>
          </Modal.Footer>
        </Modal> */}
      </Paper>
    </div>
  );
};

export default StaffGPFund;
