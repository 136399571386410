import React from 'react'

const TextError = (props) => {
    

    return (
        <div  >
          <p style={{color:'red',fontWeight:'bolder'}}>{props.children}</p> 
        </div>
    )
}

export default TextError

