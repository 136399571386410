import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { baseUrl } from "../../../services/config";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useAlert } from "react-alert";
import EditCampus from "./EditCampus/EditCampus";
import AddCampus from "./Form/AddCampus";
import { PremissionChecker } from "../../../services/PremissionChecker";

export default function Campus({ className }) {
  const history = useHistory();
  const alert = useAlert();
  // const [state, setstate] = useState(initialState)
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [name, setname] = useState("");
  const [code, setcode] = useState("");
  const [area, setarea] = useState("");
  const [city, setcity] = useState("");
  const [province, setprovince] = useState("");
  const [contact, setcontact] = useState("");
  const [state, setstate] = useState("");
  const [email, setemail] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [password, setpassword] = useState("");
  const [SearchValue, setSearchValue] = useState("");
  const [currentCampusId, setcurrentCampusId] = useState("");
  const [editModal, seteditModal] = useState(false);
  const [modalvisible, setmodalvisible] = useState(false);
  const [selectedCampus, setselectedCampus] = useState({});
  const [loading, setloading] = useState(false);
  const [addloading, setaddloading] = useState(false);

  const [wellfare, setWellfare] = useState([]);
  const [selectedinfo, setselectedinfo] = useState("");
  const [showdetailmodal, setshowdetailmodal] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const campuses = useSelector((state) => state.campuses.campuses);

  let authtoken = localStorage.getItem("userToken");
  useEffect(() => {
    authtoken = localStorage.getItem("userToken");
    GetAllCampuses();
    accounts();
  }, []);

  const accounts = () => {
    fetch(`${baseUrl}/subaccount`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let arr = [];
        console.log(data);
        data.payload.forEach((element) => {
          arr.push({
            label: element.title + `(${element.acode})`,
            value: element.id,
            account_chart_id: element.account_chart_id,
          });
        });
        setWellfare(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showInfoFunc = (item) => {
    setselectedinfo(item);
    setshowdetailmodal(true);
  };

  const GetAllCampuses = async () => {
    setloading(true);
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((campuses) => {
        console.log(campuses.payload);

        dispatch({
          type: "GET_ALL_CAMPUS",
          payload: campuses.payload,
        });
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const AddNewCampusHandler = () => {
    setaddloading(true);
    if (
      first_name == "" ||
      last_name == "" ||
      name == "" ||
      code == "" ||
      area == "" ||
      city == "" ||
      province == "" ||
      contact == "" ||
      email == "" ||
      password == ""
    ) {
      alert.error("Please fill all fields");
    } else {
      const data = {
        first_name,
        last_name,
        name,
        code,
        area,
        city,
        province,
        contact,
        email,
        password,
      };
      fetch(`${baseUrl}/campus`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) =>
          res.json().then((data) => {
            console.log(data);
            if (data.metadata.success) {
              setmodalvisible(false);
              alert.success("Campus Added");
              setaddloading(false);
              GetAllCampuses();
            }
            if (!data.metadata.success) {
              setmodalvisible(false);
              alert.error("Something went wrong");
              setaddloading(false);
            }
          })
        )
        .catch((err) => {
          setmodalvisible(false);
          console.log(err);
          setaddloading(false);
          alert.error("Something went wrong");
        });
    }
  };

  const DeleteCampus = () => {
    // console.log(id)
    fetch(`${baseUrl}/campus/${currentCampusId}`, {
      method: "DELETE",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.success("Campus Deleted Successfully");
            GetAllCampuses();
            setdeleteModal(false);
          }
          if (!data.metadata.success) {
            alert.success("Something went wrong & campus not deleted");
            setdeleteModal(false);
          }
        })
      )
      .catch((err) => {
        console.log(err);
        setdeleteModal(false);
      });
  };

  const dataSearch = campuses.filter((item) => {
    return SearchValue ? item.name.includes(SearchValue) : true;
  });

  const openDelete = (row) => {
    // console.log(row)
    setcurrentCampusId(row.id);
    setdeleteModal(true);
  };

  const middleFunc = (row) => {
    setselectedCampus(row);
    seteditModal(true);
  };

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0]
  return (
    <Paper style={{ marginTop: "10px", marginBottom: "10px" }}>
      {/* {loginAccount == "Super Admin" ? ( */}
      <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
        <button
          disabled={!PremissionChecker(user, "campus.create")}
          className="btn btn-success button_blue button_blue"
          style={{ margin: 5 }}
          onClick={() => setmodalvisible(true)}
        >
          Add Campus
        </button>
      </div>
      {/* ) : (
        ""
      )} */}

      <div style={{ padding: "15px" }}>
        {PremissionChecker(user, "campus.list") ? (
          <>
            <Table responsive>
              <thead>
                <tr className="text-center">
                  <th>Sr. no</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>Code</th>
                  <th style={{ textAlign: "start" }}>Area</th>
                  <th style={{ textAlign: "start" }}>City</th>
                  <th style={{ textAlign: "start" }}>Province</th>
                  <th style={{ textAlign: "start" }}>Office No</th>
                  <th style={{ textAlign: "start" }}>Email</th>
                  <th style={{ textAlign: "center" }}>Edit</th>
                  <th style={{ textAlign: "center" }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {campuses.map((row, index) => (
                  <>
                    <tr key={index} name={index}>
                      <td>{index + 1}</td>
                      <td
                        title={row.name?.length > 15 ? row.name : null}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {row.name?.length > 15
                          ? row.name.substring(0, 12) + "..."
                          : row.name}
                      </td>

                      <td
                        title={row.code?.length > 15 ? row.code : null}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {row.code?.length > 15
                          ? row.code.substring(0, 12) + "..."
                          : row.code}
                      </td>
                      <td
                        title={row.area?.length > 15 ? row.area : null}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {" "}
                        {row.area?.length > 15
                          ? row.area.substring(0, 12) + "..."
                          : row.area}
                      </td>
                      <td
                        title={row.city?.length > 15 ? row.city : null}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {row.city?.length > 15
                          ? row.city.substring(0, 12) + "..."
                          : row.city}
                      </td>
                      <td
                        title={row.province?.length > 15 ? row.province : null}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {row.province?.length > 15
                          ? row.province.substring(0, 12) + "..."
                          : row.province}
                      </td>
                      <td
                        title={row.contact?.length > 15 ? row.contact : null}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {row.contact?.length > 15
                          ? row.contact.substring(0, 12) + "..."
                          : row.contact}
                      </td>
                      <td
                        title={row.email?.length > 15 ? row.email : null}
                        style={{ textAlign: "start", cursor: "pointer" }}
                      >
                        {row.email?.length > 15
                          ? row.email.substring(0, 12) + "..."
                          : row.email}
                      </td>
                      {PremissionChecker(user, "campus.update") ? (
                        <td style={{ textAlign: "center" }}>
                          <span
                            onClick={() => middleFunc(row)}
                            style={{ cursor: "pointer" }}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </td>
                      ) : null}

                      {PremissionChecker(user, "campus.delete") ? (
                        <td style={{ textAlign: "center" }}>
                          <span
                            onClick={() => openDelete(row)}
                            style={{ cursor: "pointer" }}
                            className={
                              "disabledSvg svg-icon svg-icon-md svg-icon-danger"
                            }
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </td>
                      ) : null}
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
            <div className="row justify-content-center">
              {loading && <span className="spinner-border"></span>}
            </div>
          </>
        ) : (
          <div className="bg-dark-o-15 font-size-h4 font-weight-bold text-center">
            You dont have permission to view this list
          </div>
        )}
      </div>

      <AddCampus show={modalvisible} onHide={() => setmodalvisible()} />

      <Modal show={deleteModal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            style={{ padding: "15px" }}
            id="contained-modal-title-vcenter"
          >
            Are you sure want to delete Campus
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button onClick={DeleteCampus} className="btn button_blue">
            Delete
          </button>
          <button
            onClick={() => setdeleteModal(false)}
            className="btn btn-danger btn_width"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal size="sm" show={showdetailmodal}>
        {/* setselectedinfo(true)
setshowdetailmodal(item) */}

        <Modal.Body>
          <h5>{selectedinfo}</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setshowdetailmodal(false)}
            className="btn btn-danger btn_width"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Edit modal */}
      <Modal size="xl" show={editModal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Edit Campus
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditCampus
            wellfare={wellfare}
            reload={() => GetAllCampuses()}
            show={() => seteditModal()}
            campusDetail={selectedCampus}
          />
        </Modal.Body>
      </Modal>
    </Paper>
  );
}
