import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Button, Modal, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { baseUrl } from "../../../services/config";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import EditBank from "./EditBank/EditBank";
import AddBankForm from "./Form/AddBankForm";

export default function AddBank() {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loading, setloading] = useState(false);
  const [addLoading, setaddLoading] = useState(false);
  const [modalvisible, setmodalvisible] = useState(false);
  const [name, setname] = useState("");
  const [branch_codes, setbranch_codes] = useState("");
  const [account_title, setaccount_title] = useState("");
  const [account_no, setaccount_no] = useState("");
  const [branch, setbranch] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteloading, setdeleteloading] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [currentCampusId, setcurrentCampusId] = useState({});
  const [currentBank, setcurrentBank] = useState({});
  let [allbanks, setallbanks] = useState([]);
  const [addbankgroupmodal, setAddBankGroupModal] = useState(false);
  const [isaddaccount, setisaddaccount] = useState(false);
  const [addaccounttitle, setaddaccounttitle] = useState("");

  const [addaccountloading, setaddaccountloading] = useState(false);
  const [editsubaccountmodal, seteditsubaccountmodal] = useState(false);
  const [selectedsubaccount, setselectedsubaccount] = useState({});
  const [initialloading, setinitialloading] = useState(false);

  const [bankaccountparent, setbankaccountparent] = useState("");

  // const[selected]

  let array111 = [
    { id: 1, name: "allied bank" },
    { id: 2, name: "allied bank pakistan" },
  ];
  // const loginAccount=JSON.parse(localStorage.getItem("userDetails")).role[0]
  //let allbanks = [];
  let [test, settest] = useState(array111);
  let banks = useSelector((state) => state);
  const authtoken = localStorage.getItem("userToken");
  // useSelector(state => state.user.authtoken)
  const user = JSON.parse(localStorage.getItem("userDetails"));
  //useSelector(state => state.user.user)

  const AddNewBankHandler = () => {
    setaddLoading(true);

    const data = {
      name,
      branch_codes,
      account_title,
      account_no,
      branch,
    };
    fetch(`${baseUrl}/bank`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          if (data.metadata.success) {
            setmodalvisible(false);
            setaddLoading(false);
            alert.success("Bank Addedd Successfully");
            getAllBanks();
          }
        })
      )
      .catch((err) => {
        setaddLoading(false);
        setmodalvisible(false);
        console.log(err);
      });
  };

  useEffect(() => {
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.transition = "all 15s linear";
          content.style.display = "none";
        } else {
          content.style.transition = "all 15s linear";
          content.style.display = "block";
        }
      });
    }

    getAllBanks();
  }, []);
  const getAllBanks = async (check) => {
    if (check != "edit") {
      setinitialloading(true);
    }
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setinitialloading(false);
        console.log(data);
        setallbanks(data.payload);
        dispatch({
          type: "GET_ALL_BANK",
          payload: data.payload,
        });
      })
      .catch((err) => {
        setinitialloading(false);

        console.log(err);
      });
  };

  const DeleteBank = () => {
    setdeleteloading(true);
    let deleteurl = "";
    if (selectedsubaccount.bank_accounts == undefined) {
      deleteurl = "bank_account";
    } else {
      deleteurl = "bank_account_category";
    }

    fetch(`${baseUrl}/${deleteurl}/${selectedsubaccount.id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          // console.log(data)

          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              setdeleteloading(false);
              alert.show("Bank Deleted Successfully");
              setdeleteModal(false);
              getAllBanks("edit");
            } else {
              let message = data.metadata.message;
              alert.error(message);
              setdeleteModal(false);
              setdeleteloading(false);
            }
          } else {
            let message = data.metadata.message;
            alert.error(message);
            setdeleteModal(false);
            setdeleteloading(false);
          }
        })
      )
      .catch((err) => {
        setdeleteModal(false);
        setdeleteloading(false);
        console.log(err);
      });
  };

  const openDelete = (row) => {
    setcurrentCampusId(row.id);
    setdeleteModal(true);
  };

  const middleFunc = (row) => {
    setcurrentBank(row);
    seteditModal(true);
  };
  const icone_Change = (e) => {
    let id = e.target.id;
    var lastFive = id.substr(id.length - 5);
    let tag;
    if (lastFive == "icone") {
      tag = document.getElementById(id);
    } else {
      tag = document.getElementById(id + "icone");
    }

    if (tag.className === "fa fa-angle-right") {
      tag.className = "";
      tag.className = "fa fa-angle-down";
    } else if (tag.className === "fa fa-angle-down") {
      tag.className = "";
      tag.className = "fa fa-angle-right";
    }
  };

  function Active_div_btn(e) {
    icone_Change(e);

    let check_element = e.target.id;
    var lastFive = check_element.substr(check_element.length - 5);
    let element;
    if (lastFive == "icone") {
      let parent_element_id = check_element.slice(0, -5);

      element = document.getElementById(parent_element_id);
    } else {
      element = e.target;
    }

    element.classList.toggle("active");
    var content = element.nextElementSibling;

    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }

  const addBankGroup = () => {
    if (addaccounttitle == "") {
      setisaddaccount(true);
      return;
    }
    setisaddaccount(false);
    let data = {
      title: addaccounttitle,
    };
    console.log(data);

    setaddaccountloading(true);

    fetch(`${baseUrl}/bank_account_category`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setAddBankGroupModal(false);
          setaddaccountloading(false);
          setaddaccounttitle("");
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Bank Addedd Successfully");
              getAllBanks();
            } else {
              alert.error("Error Occured");
            }
          } else {
            alert.error("Error Occured");
          }
        })
      )
      .catch((err) => {
        alert.error("Error Occured");
        setaddaccounttitle("");
        setAddBankGroupModal(false);
        setaddaccountloading(false);

        console.log(err);
      });
  };

  const editFunc = (item) => {
    setselectedsubaccount(item);
    seteditsubaccountmodal(true);
    console.log(item);
  };

  return (
    <Paper style={{ marginTop: "10px", marginBottom: "10px" }}>
      {/* {loginAccount =="Super Admin"?   */}
      <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
        <button
          style={{
            margin: 5,
            width: "fit-content ",
            color: "#ffffff",
            backgroundColor: "#121291",
            borderColor: "#121291",
          }}
          className="btn btn-success"
          onClick={() => setAddBankGroupModal(true)}
        >
          Add Bank Category
        </button>
      </div>
      {/* :''} */}
      <div style={{ padding: "15px" }}>
        {initialloading ? (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <span className="spinner-border"></span>
          </div>
        ) : (
          <>
            {allbanks.map((item, index) => {
              return (
                <div key={index}>
                  <button
                    onClick={(e) => Active_div_btn(e)}
                    id={"accordion_item" + index}
                    type="button"
                    className="collapsible btn_style1"
                  >
                    <i
                      id={"accordion_item" + index + "icone"}
                      className="fa fa-angle-right"
                      style={{
                        marginRight: "15px",
                        fontSize: "20px",
                        color: "#3f4254",
                      }}
                    ></i>
                    {item.title}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log(item);
                        if (item.bank_accounts.length != 0) {
                          alert.show("Please Delete the Sub Accounts First");
                        } else {
                          console.log(item);

                          setselectedsubaccount(item);
                          setdeleteModal(true);
                        }
                      }}
                      style={{ cursor: "pointer", padding: "8px" }}
                      // className={loginAccount != "Super Admin"?"disabledSvg svg-icon svg-icon-md  svg-icon-danger":"svg-icon svg-icon-md svg-icon-danger"}>
                      className={"svg-icon svg-icon-md svg-icon-danger"}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </button>

                  <div className="content content1">
                    <div
                      id="last_div_content"
                      style={{ display: "flex", backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingBottom: "10px",
                        }}
                      >
                        {item.bank_accounts.map(
                          (account_group, account_group_index) => {
                            return (
                              <div key={account_group_index}>
                                <button
                                  style={{ width: "fit-content" }}
                                  id={
                                    "account_item" + index + account_group_index
                                  }
                                  type="button"
                                  className="collapsible btn_style2"
                                >
                                  {account_group.bank_name +
                                    " (" +
                                    account_group.account_number +
                                    ") " +
                                    account_group.account_head}
                                  <span
                                    onClick={() => editFunc(account_group)}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                    }}
                                    className="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Write.svg"
                                      )}
                                    />
                                  </span>

                                  <span
                                    onClick={() => {
                                      console.log(account_group);
                                      setdeleteModal(true);
                                      setselectedsubaccount(account_group);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                    }}
                                    className={
                                      "svg-icon svg-icon-md svg-icon-danger"
                                    }
                                  >
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Trash.svg"
                                      )}
                                    />
                                  </span>
                                </button>
                              </div>
                            );
                          }
                        )}
                        <div
                          style={{ display: "flex", alignItems: "flex-end" }}
                        >
                          <span
                            onClick={() => {
                              console.log(item);
                              setbankaccountparent(item);
                              setmodalvisible(true);
                            }}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            className="svg-icon svg-icon-md svg-icon-primary svg-icon_add"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Code/Plus.svg"
                              )}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {loading && (
        <span
          className="row justify-content-center"
          className="spinner-border ml-5"
        ></span>
      )}

      <Modal show={deleteModal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Delete Bank
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          {deleteloading ? (
            <span className="spinner-border"></span>
          ) : (
            <>
              <button
                onClick={DeleteBank}
                className="btn btn-success button_blue"
              >
                Delete
              </button>
              <button
                onClick={() => setdeleteModal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      {modalvisible ? (
        <AddBankForm
          show={modalvisible}
          onHide={() => setmodalvisible()}
          setbankaccountparent={bankaccountparent}
        />
      ) : null}

      {/* setselectedsubaccount(item)
      seteditsubaccountmodal(true) */}

      {/* {
        editsubaccountmodal ?
          <EditBank show={editsubaccountmodal} onHide={() => seteditsubaccountmodal()} reload={getAllBanks} bankDetails={selectedsubaccount} /> : null
      } */}

      <Modal size="lg" show={editsubaccountmodal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Edit Bank
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditBank
            show={editsubaccountmodal}
            onHide={() => seteditsubaccountmodal()}
            reload={getAllBanks}
            bankDetails={selectedsubaccount}
          />

          {/* <EditBank reload={() => getAllBanks()} show={() => seteditModal()} bankDetails={currentBank} /> */}
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={addbankgroupmodal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Add Bank Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3">
            <div className="col-md-6 d-flex flex-column">
              <label>
                Account Title <span style={{ color: "red" }}>*</span>{" "}
              </label>
              <input
                onChange={(e) => {
                  setaddaccounttitle(e.target.value);
                }}
                maxLength={40}
                type="text"
                placeholder="Enter Title"
              />
              {isaddaccount ? (
                <div style={{ color: "#f87d8a" }} className="input-feedback">
                  Please Add Account Title
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {addaccountloading ? (
            <div style={{ margin: "15px" }}>
              {" "}
              <span className="spinner-border"></span>
            </div>
          ) : (
            <>
              <button
                onClick={addBankGroup}
                className="btn btn-success button_blue"
              >
                Add
                {deleteloading && <span className="spinner-border"></span>}
              </button>
              <button
                onClick={() => {
                  setaddaccounttitle("");
                  setAddBankGroupModal(false);
                }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </>
          )}
        </Modal.Footer>
        {/* setisaddaccount */}
      </Modal>
    </Paper>
  );
}
