import React from "react";
import Chart from "react-apexcharts";
import {
  PeopleAltOutlined,
  Payment,
  Apartment,
  FindReplaceRounded,
  FindReplace,
  AccountBalanceWalletTwoTone,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import "./style.css";
import { baseUrl } from "../../../services/config";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import FadeInOut from "./FadeInOut";
import { PiStudentFill } from "react-icons/pi";
import { FaUsersGear } from "react-icons/fa6";
import { useState } from "react";
import { useEffect } from "react";
const HeadOfficeDashboard = () => {
  const history = useHistory();

  const authtoken = localStorage.getItem("userToken");

  const [showStrength, setShowStrength] = useState(false);
  const toggleShowStrength = () => setShowStrength(!showStrength);

  const [showConcession, setShowConcession] = useState(false);
  const toggleShowConcession = () => setShowConcession(!showConcession);

  const [showEmployees, setShowEmployees] = useState(false);
  const toggleShowEmployees = () => setShowEmployees(!showEmployees);

  const [showBankBalance, setShowBankBalance] = useState(false);
  const toggleShowBankBalance = () => setShowBankBalance(!showBankBalance);

  // Student Due Fee
  const [paidFee, setPaidFee] = useState("");
  const [unpaidFee, setUnpaidFee] = useState("");

  // Total Student Strength
  const [admitStudent, setAdmitStudent] = useState("");
  const [stuckOffStudent, setStuckOffStudent] = useState("");
  const [leavingStudent, setLeavingStudent] = useState("");
  const [totalStudents, setTotalStudents] = useState("");
  const [currentStudent, setCurrentStudent] = useState("");

  // Total Student Liable Fee
  const [totalFee, setTotalFee] = useState("");
  const [averageFee, setAverageFee] = useState("");
  const [totalConcession, setTotalConcession] = useState("");
  const [noFeeStudent, setNoFeeStudent] = useState("");
  const [lessThan2000Students, setLessThan2000Students] = useState("");
  const [lessThan4500Students, setLessThan4500Students] = useState("");
  const [aboveThan4500Students, setAboveThan4500Students] = useState("");

  // Total Student Concession
  const [ConcessionName, setConcessionName] = useState([]);
  const [ConcessionStudent, setConcessionStudent] = useState([]);

  // Total Employees Salaries
  const [CampusEmpSalaries, setCampusEmpSalaries] = useState([]);
  const [CampusNameSalaries, setCampusNameSalaries] = useState([]);
  const [totalEmpSalaries, setTotalEmpSalaries] = useState("");

  // Total Employees
  const [CampusEmployees, setCampusEmployees] = useState([]);
  const [CampusEmployeesName, setCampusEmployeesName] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);

  // Total Bank Balance
  const [AccountTitle, setAccountTitle] = useState([]);
  const [FinalAmount, setFinalAmount] = useState(0);
  // const [totalEmployees, setTotalEmployees] = useState(0);

  // Get the current date
  const currentDate = new Date();

  // Format the date as a string
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
  }).format(currentDate);

  // Assign And Remaining Messages
  const [assignMessage, setAssignMessage] = useState(0);
  const [remainingMessage, setRemainingMessage] = useState(0);

  useEffect(() => {
    getUnitMessages();
    getAllFee();
    getStudentStatus();
    getStudentLiableFeeGraph();
    getConcessionStudentGraph();
    getTotalEmployeesSalariesGraph();
    getTotalEmployeesGraph();
    getTotalAmountBalanceGraph();
  }, []);

  let abbrev_name = function(str1) {
    var split_names = str1.trim().split(" ");
    if (split_names.length > 1) {
      return split_names[0] + " " + split_names[1].charAt(0) + ".";
    }
    return split_names[0];
  };

  let abbrev_bank = function(str1) {
    var split_names = str1.trim().split(" ");
    if (split_names.length > 1) {
      return split_names[0] + " " + split_names[1];
    }
    return split_names[0];
  };

  const getUnitMessages = async () => {
    await fetch(`${baseUrl}/get-unit-messages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setAssignMessage(data?.payload?.assign_sms);
        setRemainingMessage(data?.payload?.remaining_sms);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const getAllFee = async () => {
    console.log("campus fee");
    await fetch(`${baseUrl}/total_paid_unpaid_fee`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //   setPaidFee(data.payload);
        setPaidFee(data.payload.paid);
        setUnpaidFee(data.payload.unpaid);
        console.log(data.payload);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const getStudentStatus = async () => {
    // console.log("campus fee");
    await fetch(`${baseUrl}/total-student-graph`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //   setPaidFee(data.payload);
        setTotalStudents(data.payload.total_students);
        setCurrentStudent(data.payload.current_students);
        setAdmitStudent(data.payload.admit_students);
        setStuckOffStudent(data.payload.stuckOff_students);
        setLeavingStudent(data.payload.leaving_students);
        console.log(data.payload);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getStudentLiableFeeGraph = async () => {
    await fetch(`${baseUrl}/student_liable_fee_graph`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTotalFee(data.payload.totalFee);
        setAverageFee(data.payload.averageFee);
        setTotalConcession(data.payload.totalConcession);
        setNoFeeStudent(data.payload.noFeeStudent);
        setLessThan2000Students(data.payload.lessThan2000Students);
        setLessThan4500Students(data.payload.lessThan4500Students);
        setAboveThan4500Students(data.payload.aboveThan4500Students);
        console.log(data.payload);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const getConcessionStudentGraph = async () => {
    await fetch(`${baseUrl}/total_concession_student_graph`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        let conce_name = "";
        const concession_name = [];
        const concession_student_type = [];

        for (let i = 0; i < data.payload.total_concession.length; i++) {
          conce_name = data.payload?.total_concession[i]?.concession_name;
          concession_name.push(abbrev_name(conce_name));
          concession_student_type.push(
            parseInt(data.payload?.total_concession[i]?.concession_type)
          );
        }
        setConcessionName(concession_name);
        setConcessionStudent(concession_student_type);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const getTotalEmployeesSalariesGraph = async () => {
    await fetch(`${baseUrl}/total_employees_salaries_graph`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let abr_name = "";
        const campus_name = [];
        const campus_employees_salaries = [];

        for (let i = 0; i < data.payload.campus_salaries_data.length; i++) {
          abr_name = data.payload?.campus_salaries_data[i]?.campus_name;
          campus_name.push(abbrev_name(abr_name));

          campus_employees_salaries.push(
            parseInt(data.payload?.campus_salaries_data[i]?.campus_salary)
          );
        }
        setCampusNameSalaries(campus_name);
        setCampusEmpSalaries(campus_employees_salaries);
        setTotalEmpSalaries(parseInt(data.payload.total_employees_salaries));
        console.log(data.payload);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const getTotalEmployeesGraph = async () => {
    await fetch(`${baseUrl}/total_employees_graph`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let abr_name = "";
        const campus_name = [];
        const campus_employees = [];

        for (let i = 0; i < data.payload.campus_employee_data.length; i++) {
          abr_name = data.payload?.campus_employee_data[i]?.campus_name;
          campus_name.push(abbrev_name(abr_name));

          campus_employees.push(
            parseInt(data.payload?.campus_employee_data[i]?.campus_employee)
          );
        }
        setCampusEmployeesName(campus_name);
        setCampusEmployees(campus_employees);
        setTotalEmployees(data.payload.total_employees);
        console.log(data.payload);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  const getTotalAmountBalanceGraph = async () => {
    await fetch(`${baseUrl}/total_amount_balance`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let abr_name = "";
        const account_title = [];
        const final_amount = [];

        for (let i = 0; i < data.payload.total_account_balance.length; i++) {
          abr_name = data.payload?.total_account_balance[i]?.account_title;
          // account_title.push(abr_name);
          account_title.push(abbrev_bank(abr_name));

          final_amount.push(
            parseInt(data.payload?.total_account_balance[i]?.final_amount)
          );
        }
        setAccountTitle(account_title);
        setFinalAmount(final_amount);
        // setTotalEmpSalaries(parseInt(data.payload.total_employees_salaries));
        console.log(data.payload);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/receivepartialfee")}
            className="card card-1 m-2"
          >
            <Payment
              style={{ height: 60, width: 60, color: "#121291" }}
              color="primary"
              fontSize="large"
            />
            <h4>Receive Fee</h4>
          </div>
        </div>
        <div className="col-md-4">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/student-wise-print")}
            className="card card-1 m-2"
          >
            <PeopleAltOutlined
              style={{ height: 60, width: 60, color: "#121291" }}
              //  color="primary"
              fontSize="large"
            />
            <h4>Print Fee Bill</h4>
          </div>
        </div>

        <div className="col-md-4">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/account_vocher")}
            className="card card-1 m-2"
          >
            <Apartment
              style={{ height: 60, width: 60, color: "#121291" }}
              color="primary"
              fontSize="large"
            />
            <h4>Add Voucher</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/due-fee")}
            className="card card-1 m-2"
          >
            <AccountBalanceWalletTwoTone
              style={{ height: 60, width: 60, color: "#121291" }}
              color="primary"
              fontSize="large"
            />
            <h4>Students Due Fee</h4>
          </div>
        </div>
        <div className="col-md-4">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/findstudent")}
            className="card card-1 m-2"
          >
            <PiStudentFill
              style={{ height: 60, width: 60, color: "#121291" }}
              color="primary"
              fontSize="large"
            />
            <h4>Find Student</h4>
          </div>
        </div>
        <div className="col-md-4">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/searchstaff")}
            className="card card-1 m-2"
          >
            <FaUsersGear
              style={{ height: 60, width: 60, color: "#121291" }}
              color="primary"
              fontSize="large"
            />
            <h4>Find Staff</h4>
          </div>
        </div>
      </div>

      <div
        className="container p-4 mt-3 mb-3"
        style={{ background: "white", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-between">
          <h5 className="font-weight-bold ml-4 blue_color">
            Total Unit Messages
          </h5>
          <h5 className="font-weight-bold ml-4 blue_color">
            Assigned Messages: &nbsp;&nbsp; <span>{assignMessage}</span>
          </h5>
          <h5 className="font-weight-bold mr-4 blue_color">
            Remaining Messages: &nbsp;&nbsp; <span>{remainingMessage}</span>
          </h5>
        </div>
      </div>

      <div
        className="container p-4 mt-3 mb-3"
        style={{ background: "white", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-between">
          <h5
            className="font-weight-bold ml-4 blue_color"
            // style={{ marginLeft: "30rem" }}
          >
            Student Due Fee & Strength Graph
          </h5>
          <span
            className="mr-3"
            style={{ cursor: "pointer" }}
            onClick={toggleShowStrength}
          >
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
            />
          </span>
        </div>
        <FadeInOut show={showStrength} duration={500}>
          <div className="row d-flex justify-content-between mt-6 bg-white">
            <div className="col-md-4">
              <Chart
                type="donut"
                width={450}
                height={450}
                series={[unpaidFee, paidFee]}
                options={{
                  labels: ["Unpaid Fee", "Paid Fee"],
                  title: {
                    text: "Total Paid & Unpaid Fee of " + formattedDate,
                  },
                  colors: ["#E63B60", "#121291"],
                  fill: {
                    colors: ["#E63B60", "#121291"],
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        customScale: 0.8,
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: 20,
                            color: "#3F4254",
                          },
                          name: {
                            show: true,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                }}
              />
            </div>

            <div className="col-md-4" style={{ marginRight: "175px" }}>
              <Chart
                type="donut"
                width={490}
                height={490}
                series={[
                  admitStudent,
                  currentStudent,
                  stuckOffStudent,
                  leavingStudent,
                ]}
                options={{
                  labels: [
                    "New Admission",
                    "Prev-Admission",
                    "StuckOff",
                    "Leaving",
                  ],
                  title: { text: "Total Active Students: " + totalStudents },
                  colors: [
                    "#E63B60",
                    "#121291",
                    "#f5a201",
                    "#0D1F2D",
                    "#1B2021",
                  ],
                  fill: {
                    colors: [
                      "#E63B60",
                      "#121291",
                      "#f5a201",
                      "#0D1F2D",
                      "#1B2021",
                    ],
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        customScale: 0.8,
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: 18,
                            color: "#3F4254",
                          },
                          name: {
                            show: true,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                }}
              />
            </div>
          </div>
        </FadeInOut>
      </div>

      <div
        className="container p-4 mt-3 mb-3"
        style={{ background: "white", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-between">
          <h5
            className="font-weight-bold ml-4 blue_color"
            // style={{ marginLeft: "30rem" }}
          >
            Total Fee Concession & Concession Students Types
          </h5>
          <span
            className="mr-3"
            style={{ cursor: "pointer" }}
            onClick={toggleShowConcession}
          >
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
            />
          </span>
        </div>
        <FadeInOut show={showConcession} duration={500}>
          <div className="row d-flex justify-content-between mt-6 bg-white">
            <div className="col-md-4">
              <Chart
                type="donut"
                width={490}
                height={490}
                series={[
                  noFeeStudent,
                  lessThan2000Students,
                  lessThan4500Students,
                  aboveThan4500Students,
                ]}
                options={{
                  labels: [
                    "No Fee",
                    "1-2000 Fee",
                    "2001-4500 Fee",
                    "4501-Above Fee",
                  ],
                  title: {
                    text: "Total Fee Concession",
                  },
                  colors: [
                    "#4e6e29",
                    "#828c1c",
                    "#FFC107",
                    "#FF9800",
                    "#FF5722",
                    "#F44336",
                    "#E91E63",
                  ],
                  fill: {
                    colors: [
                      "#4e6e29",
                      "#828c1c",
                      "#FFC107",
                      "#FF9800",
                      "#FF5722",
                      "#F44336",
                      "#E91E63",
                    ],
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        customScale: 0.8,
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: 18,
                            color: "#3F4254",
                          },
                          name: {
                            show: true,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                }}
              />
              <div className="d-flex text-center" style={{ width: "450px" }}>
                <p>
                  Total Fee: <b>{totalFee}</b>
                </p>
                <p className="ml-2">
                  Average Fee: <b>{averageFee}</b>
                </p>
                <p className="ml-2">
                  Total Concession Fee: <b>{totalConcession}</b>
                </p>
              </div>
            </div>

            <div className="col-md-4" style={{ marginRight: "185px" }}>
              <Chart
                type="donut"
                width={490}
                height={490}
                series={ConcessionStudent}
                options={{
                  labels: ConcessionName,
                  title: { text: "Concession Students Types" },
                  colors: [
                    "#4e6e29",
                    "#828c1c",
                    "#FFC107",
                    "#FF9800",
                    "#FF5722",
                    "#F44336",
                    "#E91E63",
                    "#9C27B0",
                    "#673AB7",
                    "#3F51B5",
                    "#2196F3",
                    "#03A9F4",
                    "#00BCD4",
                    "#009688",
                    "#4CAF50",
                  ],
                  fill: {
                    colors: [
                      "#4e6e29",
                      "#828c1c",
                      "#FFC107",
                      "#FF9800",
                      "#FF5722",
                      "#F44336",
                      "#E91E63",
                      "#9C27B0",
                      "#673AB7",
                      "#3F51B5",
                      "#2196F3",
                      "#03A9F4",
                      "#00BCD4",
                      "#009688",
                      "#4CAF50",
                    ],
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        customScale: 0.8,
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: 18,
                            color: "#3F4254",
                          },
                          name: {
                            show: true,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                }}
              />
            </div>
          </div>
        </FadeInOut>
      </div>

      <div
        className="container p-4 mt-3 mb-3"
        style={{ background: "white", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-between">
          <h5
            className="font-weight-bold ml-4 blue_color"
            // style={{ marginLeft: "30rem" }}
          >
            Campus Wise Employees Gross Salaries & Strength
          </h5>
          <span
            className="mr-3"
            style={{ cursor: "pointer" }}
            onClick={toggleShowEmployees}
          >
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
            />
          </span>
        </div>
        <FadeInOut show={showEmployees} duration={500}>
          <div className="row d-flex justify-content-between mt-6 bg-white">
            <div className="col-md-4">
              <Chart
                type="donut"
                width={490}
                height={490}
                series={CampusEmpSalaries}
                options={{
                  labels: CampusNameSalaries,
                  title: {
                    text: "Total Employees Salaries: " + totalEmpSalaries,
                  },
                  colors: [
                    "#4CAF50",
                    "#009688",
                    "#00BCD4",
                    "#03A9F4",
                    "#2196F3",
                    "#3F51B5",
                    "#673AB7",
                    "#9C27B0",
                    "#E91E63",
                    "#F44336",
                    "#FF5722",
                    "#FF9800",
                    "#FFC107",
                    "#FFEB3B",
                    "#CDDC39",
                    "#8BC34A",
                  ],
                  fill: {
                    colors: [
                      "#4CAF50",
                      "#009688",
                      "#00BCD4",
                      "#03A9F4",
                      "#2196F3",
                      "#3F51B5",
                      "#673AB7",
                      "#9C27B0",
                      "#E91E63",
                      "#F44336",
                      "#FF5722",
                      "#FF9800",
                      "#FFC107",
                      "#FFEB3B",
                      "#CDDC39",
                      "#8BC34A",
                    ],
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        customScale: 0.8,
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: 18,
                            color: "#3F4254",
                          },
                          name: {
                            show: true,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                }}
              />
            </div>

            <div className="col-md-4" style={{ marginRight: "175px" }}>
              <Chart
                type="donut"
                width={490}
                height={490}
                series={CampusEmployees}
                options={{
                  labels: CampusEmployeesName,
                  title: {
                    text: "Total Employees: " + totalEmployees,
                  },
                  colors: [
                    "#4CAF50",
                    "#009688",
                    "#00BCD4",
                    "#03A9F4",
                    "#2196F3",
                    "#3F51B5",
                    "#673AB7",
                    "#9C27B0",
                    "#E91E63",
                    "#F44336",
                    "#FF5722",
                    "#FF9800",
                    "#FFC107",
                    "#FFEB3B",
                    "#CDDC39",
                    "#8BC34A",
                  ],
                  fill: {
                    colors: [
                      "#4CAF50",
                      "#009688",
                      "#00BCD4",
                      "#03A9F4",
                      "#2196F3",
                      "#3F51B5",
                      "#673AB7",
                      "#9C27B0",
                      "#E91E63",
                      "#F44336",
                      "#FF5722",
                      "#FF9800",
                      "#FFC107",
                      "#FFEB3B",
                      "#CDDC39",
                      "#8BC34A",
                    ],
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        customScale: 0.8,
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: 18,
                            color: "#3F4254",
                          },
                          name: {
                            show: true,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                }}
              />
            </div>
          </div>
        </FadeInOut>
      </div>

      <div
        className="container p-4 mt-3 mb-3"
        style={{ background: "white", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-between">
          <h5
            className="font-weight-bold ml-4 blue_color"
            // style={{ marginLeft: "30rem" }}
          >
            Bank Account Balances
          </h5>
          <span
            className="mr-3"
            style={{ cursor: "pointer" }}
            onClick={toggleShowBankBalance}
          >
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
            />
          </span>
        </div>
        <FadeInOut show={showBankBalance} duration={500}>
          <div className="row d-flex justify-content-between mt-6 bg-white">
            <div className="col-md-4">
              <Chart
                type="donut"
                width={510}
                height={510}
                series={FinalAmount}
                options={{
                  labels: AccountTitle,
                  // title: {
                  //   text: "Total Employees Salaries: " + totalEmpSalaries,
                  // },
                  colors: [
                    "#4CAF50",
                    "#009688",
                    "#00BCD4",
                    "#03A9F4",
                    "#2196F3",
                    "#3F51B5",
                    "#673AB7",
                    "#9C27B0",
                    "#E91E63",
                    "#F44336",
                    "#FF5722",
                    "#FF9800",
                    "#FFC107",
                    "#FFEB3B",
                    "#CDDC39",
                    "#8BC34A",
                  ],
                  fill: {
                    colors: [
                      "#4CAF50",
                      "#009688",
                      "#00BCD4",
                      "#03A9F4",
                      "#2196F3",
                      "#3F51B5",
                      "#673AB7",
                      "#9C27B0",
                      "#E91E63",
                      "#F44336",
                      "#FF5722",
                      "#FF9800",
                      "#FFC107",
                      "#FFEB3B",
                      "#CDDC39",
                      "#8BC34A",
                    ],
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        customScale: 0.8,
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: 18,
                            color: "#3F4254",
                          },
                          name: {
                            show: true,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                }}
              />
            </div>

            {/* <div className="col-md-4" style={{ marginRight: "175px" }}>
              <Chart
                type="donut"
                width={490}
                height={490}
                series={CampusEmployees}
                options={{
                  labels: CampusEmployeesName,
                  title: {
                    text: "Total Employees: " + totalEmployees,
                  },
                  colors: [
                    "#4CAF50",
                    "#009688",
                    "#00BCD4",
                    "#03A9F4",
                    "#2196F3",
                    "#3F51B5",
                    "#673AB7",
                    "#9C27B0",
                    "#E91E63",
                    "#F44336",
                    "#FF5722",
                    "#FF9800",
                    "#FFC107",
                    "#FFEB3B",
                    "#CDDC39",
                    "#8BC34A",
                  ],
                  fill: {
                    colors: [
                      "#4CAF50",
                      "#009688",
                      "#00BCD4",
                      "#03A9F4",
                      "#2196F3",
                      "#3F51B5",
                      "#673AB7",
                      "#9C27B0",
                      "#E91E63",
                      "#F44336",
                      "#FF5722",
                      "#FF9800",
                      "#FFC107",
                      "#FFEB3B",
                      "#CDDC39",
                      "#8BC34A",
                    ],
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        customScale: 0.8,
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: 18,
                            color: "#3F4254",
                          },
                          name: {
                            show: true,
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },
                }}
              />
            </div> */}
          </div>
        </FadeInOut>
      </div>
    </div>
  );
};

export default HeadOfficeDashboard;
