import { Paper } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import { AddStaff } from "./AddStaff";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useAlert } from "react-alert";

const StaffMember = () => {
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [editModal, seteditModal] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [deleteModel, setDeleteModel] = useState(false);
  let authtoken = localStorage.getItem("userToken");
  useEffect(() => {
    getStaff();
  }, []);
  const alert = useAlert();
  const campusId = JSON.parse(localStorage.getItem("userDetails")).campus_id;
  const getStaff = () => {
    fetch(`${baseUrl}/staff-member/campus/${campusId}`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.metadata){
          if(data.metadata.success){
            setStaffList(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteStaffMember = (id) => {
    setSelectedID(id);
    setDeleteModel(true);
  };
  const editStaffMember = (item) => {
    setEditItem(item);
    seteditModal(true);
    setAddModal(true);
  };

  const deleteThis = () => {
    let fetchurl = `${baseUrl}/staffmember/${selectedID}`;

    fetch(fetchurl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success("Deleted Successfully");
            setDeleteModel(false);
            getStaff()
          } else {
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <button
          onClick={() => {
            seteditModal(false);
            setAddModal(true);
          }}
          className="btn btn-primary"
        >
          Add Staff Member
        </button>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  {/* <th>Edit</th> */}
                  <th>Delete</th>
                </tr>
              </thead>

              <tbody>
                {staffList.length>0?
                  staffList?.map((staff, index) => (
                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {staff.user.first_name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {staff.user.last_name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {staff.user.email}
                      </td>
                      {/* <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        <a
                          title="Edit customer"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          onClick={() => editStaffMember(staff)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td> */}
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        <a
                          title="Delete"
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => deleteStaffMember(staff.id)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td>
                    </tr>
                  )):''}
              </tbody>
            </Table>
          </div>
        )}
      </Paper>

      <Modal size="lg" show={addModal}>
        <Modal.Header>
          <h4>{editModal ? "Edit Staff Member" : "Add New Staff Member"}</h4>
        </Modal.Header>
        <AddStaff
          editItem={editModal ? editItem : ""}
          reload={getStaff}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => console.log(values)}
          onHide={() => setAddModal(false)}
        />
      </Modal>

      <Modal show={deleteModel} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Delete Staff Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Are you sure to permanently delete this?</span>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setDeleteModel(false)}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={deleteThis}
              className="btn btn-danger btn-elevate"
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StaffMember;
