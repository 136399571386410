import React, { useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch } from 'react-redux'

import { Button, Modal } from 'react-bootstrap'
import TextInput from '../../../components/TextInput';
import { baseUrl } from '../../../services/config';
// import MultiStepStaff_Form from './MultiPageForm/MultiStepStaff_Form';
import { useHistory } from 'react-router-dom';


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: 'green'
    },
    input: {
        display: 'none',
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];



export default function Staff() {

     const history = useHistory()

    const [appliedfor, setapppliedfor] = useState('')
    const [full_name, setfullname] = useState('')
    const [father_name, setfathername] = useState('')
    const [nationality, setnationality] = useState('')
    const [religion, setreligion] = useState('')
    const [experience, setexperience] = useState('')
    const [cnic_no, setcnic_no] = useState('')
    const [qualification, setqualification] = useState('')
    const [gender, setgender] = useState('')
    const [marital_status, setmarital_status] = useState('')
    const [dob, setdob] = useState('')
    const [remarks, setremarks] = useState('')
    const [mobile_no, setmobile_no] = useState('')
    const [phone, setphone] = useState('')
    const [address, setaddress] = useState('')
    const [first_name, setfirst_name] = useState('')
    const [last_name, setlast_name] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [error, seterror] = useState('')

    const initialValues =  {
        appliedfor:'',
        designation_date:'',
        fullname:'',
        fathername:'',
        nationality:'',
        religion:'',
        cnic_no:'',
        qualification:'',
        gender:'',
        marital_status:'',
        dob:'',
        remarks:'',
        mobile_no:'',
        phone:'',
        address:'',
        first_name:'',
        last_name:'',
        email:'',
        password:'',
      }

    const authtoken = useSelector(state => state.user.authtoken)
    const allstaff = useSelector(state => state.staff.allstaff)
    const user = useSelector(state => state.user.user)
    // console.log(allstaff)
    const dispatch = useDispatch()

    const getAllStaf = async () => {
        await fetch(`/staffmember`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authtoken}`
            }
        })
            .then((res) => res.json())
            .then((staff) => {
                dispatch({
                    type: 'GET_ALL_STAFF',
                    payload: staff.payload
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        getAllStaf()
    }, [])


    const classes = useStyles();
    const [modalvisible, setmodalvisible] = useState(false)


    const AddStaffHandler = () => {

        const data = {
            applied_for: appliedfor,
            full_name,
            father_name,
            nationality,
            religion,
            experience,
            cnic_no,
            qualification,
            gender,
            marital_status,
            dob,
            remarks,
            mobile_no,
            phone,
            address,
            first_name,
            last_name,
            email,
            password
        }


        fetch(`/staffmember`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authtoken}`
            },
            body: JSON.stringify(data)
        }).then(res => res.json()
            .then(data => {
                if (data.metadata.success === false) {
                    seterror('Please Fill All Fields')

                    console.log(data)
                }
            })

        ).catch(err => {
            seterror(err)
            console.log(err)
        })


    }
     const routeTo = () => {
         history.push('/addstaff',
         {
             bearerToken:authtoken
         })
     }

    return (
        <Paper className={classes.root}>

            <div style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
                {/* <InputGroup size="md">
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" />
        </InputGroup> */}
                <Button
                    style={{ margin: 5 }}
                    onClick={()=> routeTo()}

                    size="small" variant="outline-success" className={classes.button}>
                    Add Staff
                </Button>
            </div>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Applied_for</StyledTableCell>
                        <StyledTableCell align="center">full_name</StyledTableCell>
                        <StyledTableCell align="right">father_name</StyledTableCell>
                        <StyledTableCell align="right">nationality</StyledTableCell>
                        <StyledTableCell align="right">religion</StyledTableCell>
                        <StyledTableCell align="right">experience</StyledTableCell>
                        <StyledTableCell align="right">cnic_no</StyledTableCell>
                        <StyledTableCell align="right">qualification</StyledTableCell>
                        <StyledTableCell align="right">gender</StyledTableCell>
                        <StyledTableCell align="right">marital_status</StyledTableCell>
                        <StyledTableCell align="right">dob</StyledTableCell>
                        <StyledTableCell align="right">remarks</StyledTableCell>
                        <StyledTableCell align="right">mobile_no</StyledTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {allstaff.map(staff => (
                        user.campus.id == staff.campus_id ? <>
                            <StyledTableRow key={staff.id}>
                                <StyledTableCell align="left">{staff.applied_for}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                    {staff.full_name}
                                </StyledTableCell>

                                <StyledTableCell align="right">{staff.father_name}</StyledTableCell>
                                <StyledTableCell align="right">{staff.nationality}</StyledTableCell>
                                <StyledTableCell align="right">{staff.address}</StyledTableCell>
                                <StyledTableCell align="right">{staff.cnic_no}</StyledTableCell>
                                <StyledTableCell align="right">{staff.qualification}</StyledTableCell>
                                <StyledTableCell align="right">{staff.gender}</StyledTableCell>
                                <StyledTableCell align="right">{staff.marital_status}</StyledTableCell>
                                <StyledTableCell align="right">{staff.dob}</StyledTableCell>
                                <StyledTableCell align="right">{staff.remarks}</StyledTableCell>
                                <StyledTableCell align="right">{staff.phone}</StyledTableCell>



                            </StyledTableRow> </> : null
                    ))}
                </TableBody>
            </Table>


        </Paper>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

}));