import React, { useState, useEffect } from "react";
import "./Style.css";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import { Modal, Tab, Table, Alert } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import EditPayScale from "./EditPayScale/EditPayScale";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../../_metronic/_partials/controls";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import Paper from "@material-ui/core/Paper";

function Payscale() {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [addModal, setaddModal] = useState(false);
  const [startloading, setstartloading] = useState(false);
  const [error, seterror] = useState("");
  const [currentId, setcurrentId] = useState("");
  const [payscale, setpayscale] = useState("");
  const [basic, setbasic] = useState("");
  const [increment, setincrement] = useState("");
  const [maximum, setmaximum] = useState("");
  const [gp_fund, setgp_fund] = useState("");
  const [welfare_fund, setwalfare_fund] = useState("");
  const [AllPayScales, setAllPayScales] = useState([]);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [selectedObject, setselectedObject] = useState({});
  const [loading, setloading] = useState(false);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please Fill all Fields");

  const [allcampuses, setallcampuses] = useState([]);
  const [campusID, setcampusID] = useState("");

  useEffect(() => {
    getAllPayScales();
    // getAllCampus();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  const AddPayScale = (values) => {
    setstartloading(true);
    const data = {
      payscale: values.payscale,
      basic: values.basic,
      increment: values.increment,
      maximum: values.maximum,
      gp_fund: values.gp_fund,
      welfare_fund: values.welfare_fund,
    };
    console.log(data);
    fetch(`${baseUrl}/payscales`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              setaddModal(false);
              setstartloading(false);
              alert.success("Pay Scale Added");
              getAllPayScales();
            } else {
              alert.show(data.metadata.message[0]);
            }
          }
        });
      })
      .catch((err) => {
        setaddModal(false);
        alert.show("Error Occured");
        console.log(err);
      });
  };

  const getAllPayScales = (id) => {
    setloading(true);
    fetch(`${baseUrl}/payscales`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload);
          setAllPayScales(data.payload);
          setloading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const openDelete = (scal) => {
    console.log(scal);
    setcurrentId(scal.id);
    setdeleteModal(true);
  };

  const deleteHandler = () => {
    setdeleteLoading(true);
    fetch(`${baseUrl}/payscales/${currentId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          setdeleteLoading(false);
          setdeleteModal(false);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Deleted Successfully");
              getAllPayScales();
            } else {
              alert.error(data.metadata.message);
            }
          } else {
            alert.error(data.metadata.message);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setdeleteLoading(false);
        setdeleteModal(false);
        alert.error("Something went wrong");
      });
  };

  const middleFunc = (scal) => {
    setselectedObject(scal);
    seteditModal(true);
  };

  //start here
  const initialValues = {
    issubmitted: false,
    payscale: "",
    basic: "",
    increment: "",
    maximum: "",
    gp_fund: "",
    welfare_fund: "",
  };
  const createEroors = (errors, touched, isSubmitting, values) => {
    if (errors.payscale && touched.payscale) {
      setshowerror(errors.payscale);
      setshowerr(true);
      return;
    }
    if (errors.basic && touched.basic) {
      setshowerror(errors.basic);
      setshowerr(true);
      return;
    }
    if (errors.increment && touched.increment) {
      setshowerror(errors.increment);
      setshowerr(true);
      return;
    }
    if (errors.maximum && touched.maximum) {
      setshowerror(errors.maximum);
      setshowerr(true);
      return;
    }

    if (errors.gp_fund && touched.gp_fund) {
      setshowerror(errors.gp_fund);
      setshowerr(true);
      return;
    }
    if (errors.welfare_fund && touched.welfare_fund) {
      setshowerror(errors.welfare_fund);
      setshowerr(true);
      return;
    }

    setshowerr(false);

    if (Object.keys(errors).length === 0) {
      if (values.payscale != "") {
        if (isSubmitting) {
          if (!values.issubmitted) {
            values.issubmitted = true;
            AddPayScale(values);
          }
        }
      }
    }
  };

  const AppointStaffSchema = Yup.object().shape({
    payscale: Yup.string().required("Payscale is required"),

    basic: Yup.string().required("Basic is required"),

    increment: Yup.string().required("Increment is required "),

    maximum: Yup.string().required("Maximum is required "),
    gp_fund: Yup.string().required("GP Fund Is Required"),
    welfare_fund: Yup.string().required("Welfare Fund is required "),
  });

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div>
          <button
            onClick={() => setaddModal(true)}
            className="btn btn-success button_blue"
          >
            Add Payscale
          </button>
        </div>
        <br />
        <br />
        {/* <div className="d-flex align-items-center">
          <select

            style={{ marginTop: 30, height: 40, }}
            className="form-select"
            name="campus_id"
            onChange={(e) => getAllPayScales(e.target.value)}
            aria-label="Default select example"
          >
            <option selected disabled>Select</option>
            {
              allcampuses.map(campus => (
                user.head_office.id == campus.head_office_id ? <>
                  <option key={campus.id} value={campus.id}>{campus.name}</option>
                </> : null

              ))
            }
          </select>
        </div> */}
        <br />
        <br />

        <Table responsive>
          <thead>
            <tr className="text-center">
              <th style={{ textAlign: "start" }}>Pay Scale </th>
              <th style={{ textAlign: "start" }}>Basic Pay </th>
              <th style={{ textAlign: "start" }}>Increment </th>
              <th style={{ textAlign: "start" }}>Maximum </th>
              <th style={{ textAlign: "start" }}>GP Fund </th>
              <th style={{ textAlign: "start" }}>Welfare fund </th>
              <th style={{ textAlign: "start" }}>Edit </th>
              <th style={{ textAlign: "start" }}>Delete </th>
            </tr>
          </thead>
          <tbody>
            {AllPayScales?.map((scal) => (
              <tr>
                <td style={{ textAlign: "start" }}>{scal.payscale}</td>
                <td style={{ textAlign: "start" }}>{scal.basic}</td>
                <td style={{ textAlign: "start" }}>{scal.increment}</td>
                <td style={{ textAlign: "start" }}>{scal.maximum}</td>
                <td style={{ textAlign: "start" }}>{scal.gp_fund}</td>
                <td style={{ textAlign: "start" }}>{scal.welfare_fund}</td>
                <td style={{ textAlign: "start" }}>
                  <span
                    onClick={() => middleFunc(scal)}
                    style={{ cursor: "pointer" }}
                    className="svg-icon svg-icon-md svg-icon-primary"
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                    />
                  </span>
                </td>
                <td style={{ textAlign: "start" }}>
                  <span
                    onClick={() => openDelete(scal)}
                    style={{ cursor: "pointer" }}
                    className="svg-icon svg-icon-md svg-icon-danger"
                  >
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>
        <Modal show={addModal} size="lg">
          {/* <Modal.Header>Add Payscale</Modal.Header> */}
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add Payscale
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2 style={{ color: "red" }}>{error}</h2>
            <div className="row d-flex justify-content-center ">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                {startloading ? (
                  <b style={{ color: "green" }}>
                    Salary defining Please Wait{" "}
                    <span className="spinner-borderd"></span>
                  </b>
                ) : (
                  <div>
                    {/* form start from here */}
                    {/* <div className="text-center">
                        <b>PAY SCALES</b>
                      </div> */}

                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValues}
                      validationSchema={AppointStaffSchema}
                      onSubmit={(values) => {}}
                    >
                      {(props) => {
                        const {
                          handleSubmit,
                          errors,
                          touched,
                          values,
                          isSubmitting,
                          isValid,
                          handleChange,
                          handleBlur,
                        } = props;

                        // setErrorsLis(errors)
                        createEroors(errors, touched, isSubmitting, values);

                        return (
                          <>
                            <Form
                              onSubmit={handleSubmit}
                              className="form form-label-right"
                            >
                              {/* <form className="form-card mt-3" onsubmit="event.preventDefault()"> */}
                              <div className="row justify-content-between text-left">
                                <div className="form-group col-sm-4 flex-column d-flex">
                                  <label className="form-control-label">
                                    <b>
                                      Pay Scale{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </label>
                                  <Field
                                    type="number"
                                    name="payscale"
                                    component={Input}
                                    placeholder="Payscale"
                                  />
                                  {/* <input
                                onChange={(e) => setpayscale(e.target.value)}
                                type="number"
                                className="form-control placeholder-bg mb-2"
                                required
                              /> */}
                                </div>
                                <div className="form-group col-sm-4 flex-column d-flex">
                                  <label className="form-control-label">
                                    <b>
                                      Basic Pay{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </label>
                                  <Field
                                    type="number"
                                    name="basic"
                                    component={Input}
                                    placeholder="Basic Pay"
                                  />
                                  {/* <input
                                onChange={(e) => setbasic(e.target.value)}
                                type="number"
                                className="form-control placeholder-bg mb-2"
                              /> */}
                                </div>
                                <div className="form-group col-sm-4 flex-column d-flex">
                                  <label className="form-control-label">
                                    <b>
                                      Increment{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </label>
                                  <Field
                                    type="number"
                                    name="increment"
                                    component={Input}
                                    placeholder="Increment"
                                  />
                                  {/* <input
                                onChange={(e) => setincrement(e.target.value)}
                                type="number"
                                className="form-control placeholder-bg mb-2"
                              /> */}
                                </div>
                              </div>
                              <div className="row justify-content-between text-left">
                                <div className="form-group col-sm-4 flex-column d-flex">
                                  <label className="form-control-label">
                                    <b>
                                      Maximum{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </label>
                                  <Field
                                    type="number"
                                    name="maximum"
                                    component={Input}
                                    placeholder="Maximum"
                                  />
                                  {/* <input
                                onChange={(e) => setmaximum(e.target.value)}
                                type="number"
                                className="form-control placeholder-bg mb-2"
                                required
                              /> */}
                                </div>
                                <div className="form-group col-sm-4 flex-column d-flex">
                                  <label className="form-control-label">
                                    <b>
                                      GP Fund{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </label>
                                  <Field
                                    type="number"
                                    name="gp_fund"
                                    component={Input}
                                    placeholder="GP Fund"
                                  />
                                  {/* <input
                                onChange={(e) => setgp_fund(e.target.value)}
                                type="number"
                                className="form-control placeholder-bg mb-2"
                              /> */}
                                </div>
                                <div className="form-group col-sm-4 flex-column d-flex">
                                  <label className="form-control-label">
                                    <b>
                                      Welfare Fund{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </label>
                                  <Field
                                    type="number"
                                    name="welfare_fund"
                                    component={Input}
                                    placeholder="Welfare Fund"
                                  />
                                  {/* <input
                                onChange={(e) => setwalfare_fund(e.target.value)}
                                type="number"
                                className="form-control placeholder-bg mb-2"
                              /> */}
                                </div>
                              </div>

                              {/* <div className="form-check ">
                  <input
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label for="flexRadioDefault1" className="pl-2 pb-2">
                    Pemanent
                  </label>
                  <input
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    className="ml-5"
                  />
                  <label for="flexRadioDefault2" className="pl-2 pb-2">
                    Casual
                  </label>
                </div> */}
                            </Form>
                            {showerr ? (
                              <div id="err">
                                <Alert
                                  variant="danger"
                                  onClose={() => setshowerr(false)}
                                  dismissible
                                >
                                  <Alert.Heading>
                                    Oops You Got an Error!
                                  </Alert.Heading>
                                  <p>{showerror}</p>
                                </Alert>
                              </div>
                            ) : null}
                            <div class=" btns flex_last">
                              <button
                                // onClick={AddPayScale} type="button"
                                type="submit"
                                value="Submit"
                                onClick={() => handleSubmit()}
                                class="btn btn-success button_blue"
                              >
                                SAVE
                              </button>
                              <button
                                onClick={() => setaddModal(false)}
                                class="btn btn-danger btn_width btn_padding_left"
                              >
                                CLOSE
                              </button>
                            </div>
                          </>
                        );
                      }}
                    </Formik>

                    {/* </form> */}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={editModal}>
          <Modal.Header>Edit Payscale</Modal.Header>
          <Modal.Body>
            <EditPayScale
              payscaleDetails={selectedObject}
              reload={() => getAllPayScales()}
              show={() => seteditModal()}
            />
          </Modal.Body>
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header>Are you sure you want to delete?</Modal.Header>
          <Modal.Body>
            {deleteLoading && <span className="spinner-border"></span>}
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={deleteHandler}
              className="btn btn-success button_blue"
            >
              Delete
            </button>
            <button
              onClick={() => setdeleteModal(false)}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
}

export default Payscale;
