
const INITIAL_STATE = {
    banks: [],

};

const BankReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_BANK':
            return {
                ...state,
                banks: action.payload
            };

        default:
            return state;
    }
};

export default BankReducer;
