import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";

const ScrollFee = () => {
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState("");
  const [dateData, setDateData] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedbankcateogry, setselectedbankcateogry] = useState("");
  const [allbanks, setallbanks] = useState([]);
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedbank, setselectedbank] = useState("");
  const [subAccount, setSubAccount] = useState("");

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  let authtoken = localStorage.getItem("userToken");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    getAllBanks();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDateChange = () => {
    const data = {
      date: dateData,
      bank_account_id: selectedbank,
    };
    setLoading(true);
    fetch(`${baseUrl}/daily_scroll_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setData(data.payload.feeChallans);
            setAmount(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-2">
            <img
              style={{ height: 100, width: 100, paddingLeft: "20px" }}
              src={icon}
              alt="logo"
            />
          </div>
          <div className="col-md-10" style={{ paddingTop: "25px" }}>
            <h1>{unitName}</h1>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Date{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <input
              value={dateData}
              className="form-control"
              onChange={(e) => setDateData(e.target.value)}
              style={{}}
              type="date"
            />
          </div>
          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Bank Category{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <select
              className="form-select form-control"
              name="sub_account_id"
              value={selectedbankcateogry}
              onChange={(e) => {
                setselectedbankcateogry(e.target.value);
                let anserr = allbanks.filter(
                  (item) => item.id == e.target.value
                );

                if (anserr.length != 0) {
                  setallsubaccounts([...anserr[0].bank_accounts]);
                } else {
                  setallsubaccounts([]);
                }
              }}
              style={{ backgroundColor: "white" }}
              aria-label="Default select example"
            >
              <option acode="" selected value="">
                Select...
              </option>
              {allbanks.map((item) => (
                <option value={item.id}>{item.title}</option>
              ))}
            </select>
          </div>

          <div className="col-md-4 ">
            <label className="form-control-label">
              <b>
                Select Bank <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              className="form-select form-control"
              name="sub_account_id"
              value={selectedbank}
              onChange={(e) => {
                setselectedbank(e.target.value);
              }}
              style={{ backgroundColor: "white" }}
              aria-label="Default select example"
            >
              <option acode="" selected value="">
                Select...
              </option>
              {allsubaccounts.map((item) => (
                <option value={item.id}>
                  {item.bank_name}&nbsp;&nbsp;{`(${item.account_head})`}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-7">
            <button className="btn btn-primary" onClick={handleDateChange}>
              Show
            </button>
          </div>
        </div>
        {data?.length > 0 ? (
          <ReactToPrint
            documentTitle={`Daily Scroll fee`}
            pageStyle="auto"
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint={true}
            trigger={() => (
              <button
                // onClick={() => console.log("dfsdfsdfd")}
                disabled={printloading ? true : false}
                style={{ marginLeft: "10px" }}
                className="btn btn-success button_blue button_blue mt-5"
              >
                {printloading ? <span>Printing..</span> : "Print"}
              </button>
            )}
            content={() => componentRef.current}
          />
        ) : null}
        <br />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>Sr No</th>
                  <th>Name</th>
                  <th>Admission Id</th>
                  <th>Challan No</th>
                  <th>Payable</th>
                  <th>Bank</th>
                  <th>Date</th>
                </tr>
                <tr id="tbl">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Total Amount</th>
                  <th>{amount ? amount.total_amount : 0}</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, i) => (
                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.student?.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.student?.admission_id}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.challan_no}
                      </td>

                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.payable}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.bank_account?.bank_name}{" "}
                        {`(${item.bank_account?.account_head})`}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.feed_at}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      </Paper>

      {/* /////for print  */}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot">
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th>Sr No</th>
                <th>Name</th>
                <th>Admission Id</th>
                <th>Challan No</th>
                <th>Payable</th>
                <th>Bank</th>
                <th>Date</th>
              </tr>
              <tr id="tbl">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>Total Amount</th>
                <th>{amount ? amount.total_amount : 0}</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, i) => (
                  <tr id="tbl">
                    <td style={{ color: "black" }} className="text_align_start">
                      {i + 1}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {item.student?.name}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {item.student?.admission_id}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {item.challan_no}
                    </td>

                    <td style={{ color: "black" }} className="text_align_start">
                      {item.payable}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {item.bank_account?.bank_name}{" "}
                      {`(${item.bank_account?.account_head})`}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {item.feed_at}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ScrollFee;
