import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MyAuthToken from "../../../services/authToken";
import { useDispatch, useSelector } from "react-redux";
import { Table, Modal, Button, Spinner, Alert } from "react-bootstrap";
import { useAlert } from "react-alert";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { baseUrl } from "../../../services/config";
import Paper from "@material-ui/core/Paper";
import { PremissionChecker } from "../../../services/PremissionChecker";

const Concession = () => {
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const alert = useAlert();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const dispatch = useDispatch();
  const [editmodal, seteditmodal] = useState(false);
  const [currentobject, setcurrentobject] = useState({});
  const [amount, setamount] = useState("");

  const [isused, setisused] = useState("");
  const [deletemodal, setdeletemodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const concessions = useSelector((state) => state.concession.concessions);
  const history = useHistory();
  const [currentid, setcurrentid] = useState("");
  const authtoken = localStorage.getItem("userToken");
  const [persentage, setpersentage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isallfields, setisallfields] = useState(false);

  const [fee_types, setfee_types] = useState([]);
  const [concession, setConcession] = useState([]);
  const [concessionDetails, setConcessionDetails] = useState([]);

  const [addConcessionModal, setAddConcessionModal] = useState(false);
  const [concessionTitle, setConcessionTitle] = useState("");

  const [deleteConcessionModal, setDeleteConcessionModal] = useState(false);
  const [deleteableConcession, setDeleteableConcession] = useState([]);
  const [deleteloading, setdeleteloading] = useState(false);

  const [editConcessionModal, setEditConcessionModal] = useState(false);
  const [editConcessionId, setEditConcessionId] = useState([]);
  const [editConcessionTitle, setEditConcessionTitle] = useState("");

  const [addConDetailModal, setAddConDetailModal] = useState(false);
  const [concessionTitleId, setConcessionTitleId] = useState(0);
  const [feeTypeId, setFeeTypeId] = useState(0);
  const [concessionValue, setConcessionValue] = useState("");
  const [concessionTypeId, setConcessionTypeId] = useState(0);

  const [editConDetailModal, setEditConDetailModal] = useState(false);
  const [editConcessionDetailId, setEditConcessionDetailId] = useState([]);
  const [editConcessionValue, setEditConcessionValue] = useState("");
  const [editConcessionTypeId, setEditConcessionTypeId] = useState(0);

  const [deleteConDetailModal, setDeleteConDetailModal] = useState(false);
  const [deleteableConDetail, setDeleteableConDetail] = useState([]);

  useEffect(() => {
    getAllConcessionDetails();
    getAllConcessions();
    getFeeTypes();
  }, []);

  const getAllConcessions = () => {
    setloading(true);
    fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Concession");
          setConcession(data.payload);
          setloading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllConcessionDetails = () => {
    setloading(true);
    fetch(`${baseUrl}/concession-details`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Concession");
          setConcessionDetails(data.payload);
          setloading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFeeTypes = async () => {
    setloading(true);
    await fetch(`${baseUrl}/feestypes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Fee Type");
          setfee_types(data.payload);
          setloading(false);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const addConcessionHandler = () => {
    if (concessionTitle == "") {
      return alert.error("Concession Title Is Required");
    }
    setloading(true);
    const data = {
      title: concessionTitle,
    };

    fetch(`${baseUrl}/concession`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        getAllConcessions();
        getAllConcessionDetails();
        setAddConcessionModal(false);
        alert.success("Concession Added Successfully");
      })

      .catch((err) => {
        alert.err("Concession Not Addedd");
      });
  };

  const getEditConcession = async (id) => {
    await fetch(`${baseUrl}/concession/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Concession Edit");
          setEditConcessionTitle(data.payload?.title);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateConcessionHandler = async () => {
    if (editConcessionTitle == "") {
      return alert.error("Concession Title Is Required");
    }

    setSubmitLoading(true);
    const data = {
      title: editConcessionTitle,
    };
    console.log(data);
    setSubmitLoading(false);
    await fetch(`${baseUrl}/concession/${editConcessionId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Concession Title Successfully Updated");
            getAllConcessions();
            setEditConcessionModal(false);
          } else {
            alert.show("Something Went Wrong");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const deleteConcessionHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/concession/${deleteableConcession.id}}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show("Concession Deleted Successfully");
            getAllConcessions();
            setdeleteloading(false);
            setDeleteConcessionModal(false);
          } else {
            // alert.show(data.payload);
            setdeleteloading(false);
            setDeleteConcessionModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setDeleteConcessionModal(false);
      });
  };

  const addConcessionDetailHandler = () => {
    if (
      concessionTitleId == "" ||
      feeTypeId == "" ||
      concessionValue == "" ||
      concessionTypeId == ""
    ) {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    setloading(true);
    const data = {
      concession_id: concessionTitleId,
      fees_type_id: feeTypeId,
      concession_value: concessionValue,
      concession_type: concessionTypeId,
    };

    fetch(`${baseUrl}/concession-details`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        getAllConcessionDetails();
        setAddConDetailModal(false);
        alert.success("Concession Detail Added Successfully");
      })

      .catch((err) => {
        alert.err("Concession Not Addedd");
      });
  };

  const getEditConcessionDetailHandler = async (id) => {
    await fetch(`${baseUrl}/concession-details/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "Concession Detail Edit");
          setEditConcessionValue(data.payload?.concession_value);
          setEditConcessionTypeId(data.payload?.concession_type);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateConcessionDetailHandler = () => {
    if (editConcessionValue == "" || editConcessionTypeId == "") {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    setloading(true);
    const data = {
      concession_value: editConcessionValue,
      concession_type: editConcessionTypeId,
    };

    fetch(`${baseUrl}/concession-details/${editConcessionDetailId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        getAllConcessionDetails();
        setEditConDetailModal(false);
        alert.success("Concession Detail Updated Successfully");
      })

      .catch((err) => {
        alert.err("Concession Not Updated");
      });
  };

  const deleteConcessionDetailHandler = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/concession-details/${deleteableConDetail.id}}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show("Concession Deleted Successfully");
            getAllConcessionDetails();
            setdeleteloading(false);
            setDeleteConDetailModal(false);
          } else {
            // alert.show(data.payload);
            setdeleteloading(false);
            setDeleteConDetailModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setDeleteConDetailModal(false);
      });
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* {loginAccount == "Super Admin" ? ( */}
        <div>
          <button
            disabled={!PremissionChecker(user, "concession.can_add_concession")}
            onClick={() => setAddConcessionModal(true)}
            className="btn btn-primary"
          >
            Add Concession
          </button>

          <button
            disabled={
              !PremissionChecker(user, "concession.can_add_concession_details")
            }
            onClick={() => setAddConDetailModal(true)}
            className="btn btn-primary ml-2"
          >
            Add Concession Details
          </button>
        </div>
        {/* ) : (
          ""
        )} */}
        <br />

        <div>
          <Table responsive>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>#</th>
                <th>Title</th>
                <th style={{ textAlign: "center" }}>Fee Type</th>
                <th style={{ textAlign: "center" }}>Amount</th>
                <th style={{ textAlign: "center" }}>Concession Type</th>
                <th style={{ textAlign: "center" }}> Edit</th>
                <th style={{ textAlign: "center" }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {concessionDetails.map((cons, index) => (
                <tr key={cons.id}>
                  <td style={{ textAlign: "center" }}>{cons.id}</td>
                  {/* <td style={{ textAlign: "start" }}>{cons.title}</td> */}
                  <td
                    title={
                      cons.concession_id.length > 15 ? cons.concession_id : null
                    }
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {cons.concession_id.length > 15
                      ? cons.concession_id.substring(0, 12) + "..."
                      : cons.concession_id}
                  </td>

                  <td style={{ textAlign: "center" }}>{cons.fees_type_id}</td>
                  <td style={{ textAlign: "center" }}>
                    {cons.concession_value}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {cons.concession_type}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => {
                        setEditConcessionDetailId(cons?.id);
                        getEditConcessionDetailHandler(cons?.id);
                        setEditConDetailModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => {
                        setDeleteableConDetail(cons);
                        setDeleteConDetailModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>

                  {/* <td style={{ textAlign: "start" }}>
                                        <input key={feechallan.id} onClick={(e) => { checkBoxClick(e, feechallan.id) }} value={allcheckboxes[index]} checked={feechallan.checked} type="checkbox" />
                                    </td>
 */}
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row justify-content-center">
            {loading && <span className="spinner-border"></span>}
          </div>

          {/* ------------------------Concession Start----------------------- */}
          {/* Add Concession  */}
          <Modal show={addConcessionModal} size="md">
            <Modal.Header>
              <Modal.Title>Add Concession</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <label className="form-control-label">
                  <b>
                    Enter Concession Title{" "}
                    <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => {
                    setConcessionTitle(e.target.value);
                  }}
                  type="text"
                  placeholder="Enter Concession Title"
                  className="form-control  mb-2"
                  maxLength={120}
                />
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Concession Title</th>
                    <th className="text-center">Edit</th>
                    <th className="text-center">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {concession.map((concession) => (
                    <tr>
                      <td style={{ textAlign: "start" }}>{concession?.id}</td>
                      <td style={{ textAlign: "start" }}>
                        {concession?.title}
                      </td>
                      <td className="text-center">
                        <a
                          className={
                            // loginAccount == "Head Office" ?
                            "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            // : loginAccount == "Super Admin"
                            // ? "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            // : "btn btn-icon btn-light btn-hover-primary btn-sm mx-3 disabledSvg"
                          }
                          onClick={() => {
                            setEditConcessionId(concession?.id);
                            getEditConcession(concession?.id);
                            setEditConcessionModal(true);
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary ">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td>
                      <td className="text-center">
                        <a
                          className={
                            // loginAccount == "Head Office" ?
                            "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            // : loginAccount == "Super Admin"
                            // ? "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            // : "btn btn-icon btn-light btn-hover-primary btn-sm mx-3 disabledSvg"
                          }
                          onClick={() => {
                            setDeleteableConcession(concession);
                            setDeleteConcessionModal(true);
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Delete-user.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <div
                  style={{
                    width: "80px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    class="text-center"
                    variant="info"
                    animation="border"
                  />
                </div>
              ) : (
                <button
                  onClick={addConcessionHandler}
                  className="btn btn-primary"
                >
                  Add Concession
                </button>
              )}

              <button
                onClick={() => setAddConcessionModal(false)}
                className="btn btn-danger"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

          {/* Update Concession */}
          <Modal show={editConcessionModal} size="md">
            <Modal.Header>
              <Modal.Title>Update Fee Duration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <label className="form-control-label">
                  <b>
                    Enter Concession Title{" "}
                    <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => {
                    setEditConcessionTitle(e.target.value);
                  }}
                  type="text"
                  placeholder="Enter Concession Title"
                  className="form-control  mb-2"
                  maxLength={120}
                  value={editConcessionTitle}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {submitLoading ? (
                <div
                  style={{
                    width: "80px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    class="text-center"
                    variant="info"
                    animation="border"
                  />
                </div>
              ) : (
                <button
                  onClick={updateConcessionHandler}
                  className="btn btn-primary"
                >
                  Update Concession
                </button>
              )}

              <button
                onClick={() => setEditConcessionModal(false)}
                className="btn btn-danger"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

          {/* Delete Concession */}
          <Modal show={deleteConcessionModal}>
            <Modal.Header className="modal_header_style" closeButton>
              <Modal.Title
                className="Modal_Heading_font"
                id="contained-modal-title-vcenter"
              >
                Are you want to delete
              </Modal.Title>
            </Modal.Header>
            {deleteloading ? (
              <div style={{ padding: "20px" }}>
                {" "}
                <b style={{ color: "green" }}>
                  {" "}
                  Fee Deleting Please Wait{" "}
                  <span className="spinner-borderd"></span>
                </b>
              </div>
            ) : (
              <Modal.Footer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={deleteConcessionHandle}
                    className="btn button_blue"
                  >
                    Delete
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={() => setDeleteConcessionModal(false)}
                    className="btn btn-danger btn_width"
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Footer>
            )}
          </Modal>
          {/* ------------------------Concession End----------------------- */}

          {/* ------------------------Concession Details Start----------------------- */}
          {/* Add Concession Details  */}
          <Modal show={addConDetailModal} size="lg">
            <Modal.Header>
              <Modal.Title>Add Concession Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <label for="fee_type">
                    Select Concession Title{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-select form-control"
                    name="fee_duration_id"
                    value={concession.id}
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      console.log(e.target.value);
                      setConcessionTitleId(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Concession Title</option>
                    {concession.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <label>
                    Select Fee Types <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    class="form-select form-control"
                    name="fee_duration_id"
                    value={fee_types.id}
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      console.log(e.target.value);
                      setFeeTypeId(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Fee Type</option>
                    {fee_types.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-6">
                  <label for="fee_type">
                    Concession Value <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Concession Value"
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setConcessionValue(e.target.value);
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    Concession Type <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    class="form-select form-control"
                    name="fee_duration_id"
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      console.log(e.target.value);
                      setConcessionTypeId(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Concession Type</option>
                    <option value="1">Amount</option>
                    <option value="2">Percentage</option>
                  </select>
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <div
                  style={{
                    width: "80px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    class="text-center"
                    variant="info"
                    animation="border"
                  />
                </div>
              ) : (
                <button
                  onClick={addConcessionDetailHandler}
                  className="btn btn-primary"
                >
                  Add Concession Details
                </button>
              )}

              <button
                onClick={() => setAddConDetailModal(false)}
                className="btn btn-danger"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

          {/* Update Concession Details  */}
          <Modal show={editConDetailModal} size="lg">
            <Modal.Header>
              <Modal.Title>Update Concession Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row mt-4">
                <div className="col-md-6">
                  <label for="fee_type">
                    Concession Value <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Concession Value"
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      setEditConcessionValue(e.target.value);
                    }}
                    value={editConcessionValue}
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    Concession Type <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    class="form-select form-control"
                    name="fee_duration_id"
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.target.value == 0) {
                        setisallfields(true);
                      } else {
                        setisallfields(false);
                      }
                      console.log(e.target.value);
                      console.log(editConcessionTypeId);
                      setEditConcessionTypeId(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                    aria-label="Default select example"
                    value={editConcessionTypeId}
                  >
                    <option value="">Select Concession Type</option>
                    <option value="1">Amount</option>
                    <option value="2">Percentage</option>
                  </select>
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <div
                  style={{
                    width: "80px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    class="text-center"
                    variant="info"
                    animation="border"
                  />
                </div>
              ) : (
                <button
                  onClick={updateConcessionDetailHandler}
                  className="btn btn-primary"
                >
                  Update Concession Details
                </button>
              )}

              <button
                onClick={() => setEditConDetailModal(false)}
                className="btn btn-danger"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

          {/* Delete Concession Details*/}
          <Modal show={deleteConDetailModal}>
            <Modal.Header className="modal_header_style" closeButton>
              <Modal.Title
                className="Modal_Heading_font"
                id="contained-modal-title-vcenter"
              >
                Are you want to delete
              </Modal.Title>
            </Modal.Header>
            {deleteloading ? (
              <div style={{ padding: "20px" }}>
                {" "}
                <b style={{ color: "green" }}>
                  {" "}
                  Fee Deleting Please Wait{" "}
                  <span className="spinner-borderd"></span>
                </b>
              </div>
            ) : (
              <Modal.Footer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={deleteConcessionDetailHandler}
                    className="btn button_blue"
                  >
                    Delete
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={() => setDeleteConDetailModal(false)}
                    className="btn btn-danger btn_width"
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Footer>
            )}
          </Modal>
          {/* ------------------------Concession Details End----------------------- */}
        </div>
      </Paper>
    </div>
  );
};

export default Concession;
