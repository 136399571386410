import React from "react";
import "./style.css";
import {
  PeopleAltOutlined,
  Payment,
  Apartment,
  AccountBalanceWalletTwoTone,
  FindReplace,
  FindReplaceRounded,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const CampusDashboard = () => {
  const history = useHistory();
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/student-wise-print")}
              className="card card-1 m-2"
            >
              <Payment
                style={{ height: 60, width: 60, color: "#121291" }}
                //  color="primary"
                fontSize="large"
              />
              <h4>Print Fee Bill</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/markattendance")}
              className="card card-1 m-2"
            >
              <PeopleAltOutlined
                style={{ height: 60, width: 60 }}
                color="primary"
                fontSize="large"
              />
              <h4>Mark Attendance</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/showallstudents")}
              className="card card-1 m-2"
            >
              <Apartment
                style={{ height: 60, width: 60 }}
                color="primary"
                fontSize="large"
              />
              <h4>Edit Student</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/due-fee")}
              className="card card-1 m-2"
            >
              <AccountBalanceWalletTwoTone
                style={{ height: 60, width: 60, color: "#121291" }}
                color="primary"
                fontSize="large"
              />
              <h4>Students Due Fee</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/findstudent")}
              className="card card-1 m-2"
            >
              <FindReplace
                style={{ height: 60, width: 60, color: "#121291" }}
                color="primary"
                fontSize="large"
              />
              <h4>Find Student</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/searchstaff")}
              className="card card-1 m-2"
            >
              <FindReplaceRounded
                style={{ height: 60, width: 60, color: "#121291" }}
                color="primary"
                fontSize="large"
              />
              <h4>Find Staff</h4>
            </div>
          </div>
        </div>

        <div className="row">
          {/* <div className="col-md-4">
                        <div style={{ cursor: 'pointer' }} onClick={() => history.push('/payscale')} className="card card-1 m-2">
                            <Apartment style={{ height: 60, width: 60 }} color="primary" fontSize="large" />
                            <h4>Define Pay Scale</h4>

                        </div>
                    </div> */}
          {/* <div className="col-md-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/appointedstaff")}
              className="card card-1 m-2"
            >
              <PeopleAltOutlined
                style={{ height: 60, width: 60 }}
                color="primary"
                fontSize="large"
              />
              <h4>Appointed Staff</h4>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CampusDashboard;
