import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";

const VerifyDN = () => {
  const [reportData, setreportData] = useState([]);
  const [amount, setAmount] = useState("");
  const [dateData, setDateData] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedbankcateogry, setselectedbankcateogry] = useState("");
  const [allbanks, setallbanks] = useState([]);
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedbank, setselectedbank] = useState("");
  const [subAccount, setSubAccount] = useState("");

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  let authtoken = localStorage.getItem("userToken");

  useEffect(() => {
    getSettingData();
    handleDateChange();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDateChange = () => {
    setLoading(true);
    fetch(`${baseUrl}/predndetails`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        setreportData(data.challan);
        setAmount(data.total_payable);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-2">
            <img
              style={{ height: 100, width: 100, paddingLeft: "20px" }}
              src={icon}
              alt="logo"
            />
          </div>
          <div className="col-md-10" style={{ paddingTop: "25px" }}>
            <h1>{unitName}</h1>
          </div>
        </div>
        <br />

        {reportData?.length > 0 ? (
          <ReactToPrint
            documentTitle={`Daily Scroll fee`}
            pageStyle="auto"
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint={true}
            trigger={() => (
              <button
                // onClick={() => console.log("dfsdfsdfd")}
                disabled={printloading ? true : false}
                style={{ marginLeft: "10px" }}
                className="btn btn-success button_blue button_blue mt-5"
              >
                {printloading ? <span>Printing..</span> : "Print"}
              </button>
            )}
            content={() => componentRef.current}
          />
        ) : null}
        <br />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>Sr No</th>
                  <th>Bank</th>
                  <th>Payable</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((item, i) => (
                  <tr id="tbl">
                    <td style={{ color: "black" }} className="text_align_start">
                      {i + 1}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {item.bank_account.account_title}
                    </td>
                    <td style={{ color: "black" }} className="text_align_start">
                      {item.netpay}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tbody>
                <tr id="tbl">
                  <th></th>
                  <th>Total Amount</th>
                  <th>{amount ? amount : 0}</th>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
      </Paper>

      {/* /////for print  */}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot">
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th>Sr No</th>
                <th>Bank</th>
                <th>Payable</th>
              </tr>
            </thead>
            <tbody>
              {reportData.map((item, i) => (
                <tr id="tbl">
                  <td style={{ color: "black" }} className="text_align_start">
                    {i + 1}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {item.bank_account.account_title}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {item.netpay}
                  </td>
                </tr>
              ))}
            </tbody>
            <tbody>
              <tr id="tbl">
                <th></th>
                <th>Total Amount</th>
                <th>{amount ? amount : 0}</th>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default VerifyDN;
