import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../../services/config";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Spinner } from "react-bootstrap";
import { Table, Modal, Alert } from "react-bootstrap";
import { PremissionChecker } from "../../../../../services/PremissionChecker";

const GenerateSallary = ({ empDetails, show, reload }) => {
  const [overview, setOverview] = useState(false);
  const [overviewData, setOverviewData] = useState({});

  console.log("empDetails==>", empDetails);

  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const [Allsessions, setAllsessions] = useState([]);

  // const [yearInfo, setYearInfo] = useState([]);

  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    return `${year}-${month}`;
  };
  const [monthDate, setMonthDate] = useState(getCurrentMonthYear());

  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");

  const [loading, setloading] = useState(false);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please Fill all Fields");
  const [jobstatuses, setjobstatuses] = useState([]);
  const [job_status_id, setjob_status_id] = useState("");
  const [isCheckedGpf, setisCheckedGpf] = useState(false);
  const [days, setdays] = useState(30);
  const [is_returning_gpf, setis_returning_gpf] = useState("");
  const [loading2, setloading2] = useState(false);
  const [gpf_return_amount, setgpf_return_amount] = useState("");

  useEffect(() => {
    getAllSessions();
    getJobStatus();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobStatus = () => {
    fetch(`${baseUrl}/jobstatuses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setjobstatuses(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GenerateSalaryFunc = () => {
    if (yearId == "") {
      setshowerror("Session is Required");
      setshowerr(true);
      return;
    }
    if (monthDate == "") {
      setshowerror("Month is Required");
      setshowerr(true);
      return;
    }
    setshowerr(false);

    const data = {
      salary_month: `${monthDate}-01`,
      year_id: yearId,
      days,
      is_returning_gpf,
      job_status_id,
      gpf_return_amount,
    };

    setloading(true);
    console.log(data);

    fetch(`${baseUrl}/employeeSalary-create/${empDetails.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);

        if (data.metadata.success) {
          reload();
          setOverview(false);
          show(false);
          alert.success(data.metadata.message);
          console.log("yac chala");
        }
        console.log(data);
      })
      .catch((err) => {
        setloading(false);
        alert.error(err);
        show(false);
        setloading(false);
      });
  };

  const GenerateSalaryFuncPreview = () => {
    if (yearId == "") {
      setshowerror("Session is Required");
      setshowerr(true);
      return;
    }
    if (monthDate == "") {
      setshowerror("Month is Required");
      setshowerr(true);
      return;
    }
    setshowerr(false);
    setloading2(true);

    const data = {
      salary_month: `${monthDate}-01`,
      year_id: yearId,
      preview: 1,
      days,
      is_returning_gpf,
      job_status_id,
      gpf_return_amount,
    };

    console.log(data);

    fetch(`${baseUrl}/employeeSalary-create/${empDetails.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading2(false);
        if (data.metadata.success) {
          setOverviewData(data.payload);
        }
        console.log(data);

        setOverview(true);
      })
      .catch((err) => {
        alert.error(err);
        show(false);
        setloading(false);
      });
  };

  const onReturnGpClick = (id) => {
    if (id) {
      setisCheckedGpf(true);
      setis_returning_gpf(1);
    }
    if (!id) {
      setisCheckedGpf(false);
      setis_returning_gpf(0);
    }
  };

  const handleChangeJobStatus = (id) => {
    setjob_status_id(id);
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}`;
  };

  return (
    <div>
      {loading ? (
        <>
          {" "}
          <p style={{ color: "green" }}>Salary Generating Please Wait</p>{" "}
          <Spinner animation="border" variant="secondary" />{" "}
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-6">
              <label className="form-control-label">
                <b>
                  Select Session
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </b>
              </label>
              <select
                style={{ height: 40, fontSize: 14 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option selected value="">
                  Select Session
                </option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>

            <div className="col-md-6">
              <label className="form-control-label">
                <b>
                  Select Month{" "}
                  <span style={{ color: "red", fontSize: 12 }}> *</span>
                </b>
              </label>
              <input
                value={monthDate}
                className="form-control"
                onChange={(e) => setMonthDate(e.target.value)}
                type="month"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="form-group col-md-6">
              <div>
                <label>
                  <b>No. Of Days</b>
                </label>
              </div>
              <div>
                <input
                  style={{ height: 40, width: "100%" }}
                  onChange={(e) => setdays(e.target.value)}
                  id="searchInput"
                  value={days}
                  type={"number"}
                  placeholder="Days ..."
                />
              </div>
            </div>
            <div className="form-group col-md-6">
              <div>
                <label>
                  <b>Total GP Fund Collected</b>
                </label>
              </div>
              <div>
                <input
                  disabled={true}
                  style={{ height: 40 }}
                  value={
                    empDetails?.collected_gp_fund ??
                    empDetails?.gp_collected_fund?.collected_amount
                  }
                  type={"number"}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
              className="col-md-6 mb-4"
            >
              <span>
                <input
                  type={"checkbox"}
                  id="return_gp_fund"
                  values={is_returning_gpf}
                  onChange={(e) => onReturnGpClick(e.target.checked)}
                />
              </span>
              <span className="ml-3">
                <label for="return_gp_fund" style={{ marginTop: "7px" }}>
                  <b style={{ fontSize: "15px" }}>Return GP Fund</b>
                </label>
              </span>
            </div>
          </div>

          {isCheckedGpf ? (
            <div className="d-flex">
              <div className="form-group col-sm-6 flex-column d-flex">
                <label className="form-control-label">
                  <b>
                    Set Job Status After Salary{" "}
                    <span style={{ color: "red" }}>*</span>
                  </b>
                </label>
                <select
                  class="form-select form-control"
                  name="job_status_id"
                  value={job_status_id}
                  onChange={(e) => handleChangeJobStatus(e.target.value)}
                  // style={{ backgroundColor: '#F3F6F9' }}
                  aria-label="Default select example"
                >
                  <option disabled>Select Job Status</option>

                  {jobstatuses.map((jobs) => (
                    <option value={jobs.id}>{jobs.name}</option>
                  ))}
                </select>
              </div>
              <div
                className="form-group col-sm-6 flex-column d-flex "
                style={{ marginTop: "25px" }}
              >
                <input
                  placeholder={"GP Fund Return Amount"}
                  type={"text"}
                  defaultValue={
                    empDetails?.collected_gp_fund ??
                    empDetails?.gp_collected_fund?.collected_amount
                  }
                  onChange={(e) => setgpf_return_amount(e.target.value)}
                />
              </div>
            </div>
          ) : null}

          <br />
          <br />
          {showerr ? (
            <div id="err">
              <Alert
                variant="danger"
                onClose={() => setshowerr(false)}
                dismissible
              >
                <Alert.Heading>Oops You Got an Error!</Alert.Heading>
                <p>{showerror}</p>
              </Alert>
            </div>
          ) : null}

          <div className=" flex_last row m-10">
            <button
              onClick={() => {
                GenerateSalaryFuncPreview();
                setOverview(true);
              }}
              className="btn btn-success button_blue"
            >
              Preview
            </button>

            <button
              onClick={() => {
                setshowerr(false);
                setYearId("");
                setMonthDate("");
                show(false);
              }}
              className=" btn_padding_left btn btn-danger btn_width"
            >
              Cancel
            </button>
          </div>

          <Modal show={overview} size="xl">
            <Modal.Header className="modal_header_style" closeButton>
              <Modal.Title
                className="Modal_Heading_font"
                id="contained-modal-title-vcenter"
              >
                Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th
                      style={{
                        borderBottom: "1px solid black",
                      }}
                    ></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}>
                      Allowances
                    </th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>

                    <th></th>

                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}>
                      Deductions
                    </th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                    <th style={{ borderBottom: "1px solid black" }}></th>
                  </tr>
                  <tr className="text-center">
                    <th>Account no</th>
                    <th>Basic Pay</th>
                    <th>Days</th>
                    <th>Month</th>

                    <th
                      style={{
                        borderLeft: "1px solid black",
                      }}
                    >
                      Hifz
                    </th>
                    <th>Hostel</th>
                    <th>College</th>
                    <th>Additional</th>
                    <th>Increment Total</th>
                    <th>Second Shift</th>
                    <th>UGS</th>
                    <th>Other Allowance</th>
                    <th>HoD</th>
                    <th>Science</th>
                    <th>Extra Coaching</th>
                    <th>Extra Periods</th>
                    <th>Convance</th>
                    {/* <th>Insurance Contribution</th> */}
                    <th>GPF Return</th>
                    {/* <th>GPF Contribution</th> */}

                    <th
                      style={{
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                      }}
                    >
                      Gross Salary
                    </th>

                    <th>GP Fund</th>
                    <th>Welfare Fund</th>
                    <th>Eobi</th>
                    <th>Income Tax</th>
                    <th>Loan Refund</th>
                    <th>Other Deduction</th>
                    <th>Child Fee</th>
                    <th>Insurance</th>
                    <th>Van Charge</th>
                  </tr>
                </thead>
                <tbody>
                  {loading2 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner
                        class="text-center"
                        variant="info"
                        animation="border"
                      />
                    </div>
                  ) : (
                    <tr>
                      <td>{overviewData?.account_no}</td>
                      <td className="text_align_center">
                        {overviewData?.basic_pay}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.days}
                      </td>
                      <td className="text_align_center">
                        {getMonthName(overviewData?.salary_month)}
                      </td>
                      <td
                        className="text_align_center"
                        style={{ borderLeft: "1px solid black" }}
                      >
                        {overviewData?.hifz}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.hostel}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.college}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.additional_allowance}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.increment}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.second_shift}
                      </td>
                      <td className="text_align_center">{overviewData?.ugs}</td>
                      <td className="text_align_center">
                        {overviewData?.other_allowance}
                      </td>
                      <td className="text_align_center">{overviewData?.hod}</td>
                      <td className="text_align_center">
                        {overviewData?.science}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.extra_period}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.extra_coaching}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.convance}
                      </td>
                      {/* <td className="text_align_center">
                        {overviewData?.insurance_cont}
                      </td> */}
                      <td className="text_align_center">
                        {overviewData?.gpf_return}
                      </td>
                      {/* <td className="text_align_center">
                        {overviewData?.gpf_cont}
                      </td> */}
                      <td
                        className="text_align_center"
                        style={{
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                        }}
                      >
                        {overviewData?.gross_salary}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.gp_fund}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.welfare_fund}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.eobi}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.income_tax}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.loan_refund}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.other_deduction}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.child_fee_deduction}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.insurance}
                      </td>
                      <td className="text_align_center">
                        {overviewData?.van_charge}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div style={{ float: "right", marginRight: "20px" }}>
                <h3>Net Salary: {overviewData?.net_pay}</h3>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={() => {
                  GenerateSalaryFunc();
                }}
                className="btn btn-success button_blue"
              >
                Generate
              </button>
              <button
                onClick={() => {
                  show(false);
                  setOverview(false);
                }}
                className="btn btn-danger btn_width"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default GenerateSallary;
