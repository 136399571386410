import React from 'react'
import {Field ,ErrorMessage } from 'formik'
import TextError from './TextError'
const FormikInput = ({name,colCount,label,...rest}) => {
   
   
    return (
        
        <div className={`${colCount}`}>
        <label htmlFor={name} className="font-size-h6 font-size-lg-h4 col-form-label">{label}</label>

       
                 <Field
                id={name}
                name={name}
                className="form-control form-control-lg form-control-solid  " 
                {...rest}
            />
            <ErrorMessage name={name} component={TextError} /> 
            </div>
     
    )
}

export default FormikInput
