import React from 'react'

const ViewSalaryDetails = ({ EmpDetails }) => {
    // console.log(EmpDetails)
    return (
        <div>
            <div className="card p-3">
                <h2 className="m-3">Employe Details</h2>
                <UserDetails
                    title="Employee Name"
                    value={EmpDetails.employee_name}
                    title2="Father Name"
                    value2={EmpDetails.father_name}
                />
                <UserDetails
                    title="Account No"
                    value={EmpDetails.account_no}
                    title2="Salary_month"
                    value2={EmpDetails.salary_month}
                />
                <UserDetails
                    title="Net Pay"
                    value={EmpDetails.net_pay}
                    title2="Basic Pay"
                    value2={EmpDetails.basic_pay}
                />
            </div>
            <div className="card p-3">
                <h2 className="m-3">Salary Details</h2>
                <UserDetails
                    title="Hifz"
                    value={EmpDetails.hifz}
                    title2="Hostel"
                    value2={EmpDetails.hostel}
                />
                <UserDetails
                    title="College"
                    value={EmpDetails.college}
                    title2="Additional Allowance"
                    value2={EmpDetails.additional_allowance}
                />
                <UserDetails
                    title="Increment"
                    value={EmpDetails.increment}
                    title2="Second Shift"
                    value2={EmpDetails.second_shift}
                />
                <UserDetails
                    title="Extra Period"
                    value={EmpDetails.extra_period}
                    title2="Extra Coaching"
                    value2={EmpDetails.extra_coaching}
                />
                <UserDetails
                    title="Eobi"
                    value={EmpDetails.eobi}
                    title2="Eobi Payments"
                    value2={EmpDetails.eobi_payments}
                />
                <UserDetails
                    title="insurance"
                    value={EmpDetails.insurance}
                    title2="Van_charge"
                    value2={EmpDetails.van_charge}
                />
                <UserDetails
                    title="Child Fee Deduction"
                    value={EmpDetails.child_fee_deduction}
                    title2="Loan Refund"
                    value2={EmpDetails.loan_refund}
                />
                <UserDetails
                    title="Other Allowance"
                    value={EmpDetails.other_allowance}
                    title2="Other Deduction"
                    value2={EmpDetails.other_deduction}
                />
                <UserDetails
                    title="Ugs"
                    value={EmpDetails.ugs}
                    title2="Science"
                    value2={EmpDetails.science}
                />
                <UserDetails
                    title="Income Tax"
                    value={EmpDetails.Income_tax}
                    title2="Hod"
                    value2={EmpDetails.hod}
                />
            </div>

            <div className="card p-3">
                <h2 className="m-3">Funds</h2>
                <UserDetails
                    title="GP-fund"
                    value={EmpDetails.gp_fund}
                    title2="Welfare Fund"
                    value2={EmpDetails.welfare_fund}
                />
                <UserDetails
                    title="GPf Return"
                    value={EmpDetails.gpf_return}
                />
            </div>

        </div>
    )
}

export default ViewSalaryDetails




const UserDetails = ({ title, title2, value, value2 }) => {
    return (
        <div className=" col-md-6 d-flex justify-content-between">
            <h5 className="m-3" style={{ color: 'grey' }}>{title}  : {value}</h5>
            <h5 className="m-3" style={{ color: 'grey' }}>{title2} : {value2}</h5>

        </div>
    )
}
