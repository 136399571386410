import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";
import { DownloadTableExcel } from "react-export-table-to-excel";

const DailyScroll = () => {
  const [data, setData] = useState([]);
  const [studentDetailData, setStudentDetailData] = useState([]);
  const [amount, setAmount] = useState("");
  const [dateData, setDateData] = useState(
    new Date().toISOString().split("T")[0]
  );

  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    return `${year}-${month}`;
  };

  const [monthDate, setMonthDate] = useState(getCurrentMonthYear());
  const user = useSelector((state) => state.user.user);
  const [user_id, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedbankcateogry, setselectedbankcateogry] = useState("");
  const [allbanks, setallbanks] = useState([]);
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedbank, setselectedbank] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [wholeBankCat, setWholeBankCat] = useState([]);
  const [wholeBank, setWholeBank] = useState({});

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const exportTable = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  let authtoken = localStorage.getItem("userToken");

  const [isVisible, setIsVisible] = useState(false);
  const alert = useAlert();
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [fullMonthBox, setFullMonthBox] = useState(false);

  const [selected, setSelected] = useState("timeWise");
  const [timeWise, setTimeWise] = useState(true);
  const [summary, setSummary] = useState(false);
  const [studentWise, setStudentWise] = useState(false);

  useEffect(() => {
    getUserId();
    getAllBanks();
    getSettingData();
  }, []);

  const getUserId = () => {
    user.roles.map((item) => {
      if (item.name == "Super Admin" || item.name == "Head Office") {
        setUserId("");
      } else {
        setUserId(user.id);
      }
    });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;
    setData([]);
    setIsVisible(false);
    console.log(value);
    setSelected(e.target.value);
    if (value == "timeWise") {
      setTimeWise(true);
      setSummary(false);
      setStudentWise(false);
    }
    if (value == "summary") {
      setTimeWise(false);
      setSummary(true);
      setStudentWise(false);
    }
    if (value == "studentWise") {
      setTimeWise(false);
      setSummary(false);
      setStudentWise(true);
    }
  };

  console.log(selected, "checkedimn");

  const handleDateChange = () => {
    if (dateData == "") {
      alert.error("Date Not Set");
      return false;
    }

    if (fullMonthBox && monthDate == "") {
      alert.error("Month Not Set");
      return false;
    }

    if (selectedbank == 0) {
      alert.error("Please select bank / branch");
      return false;
    }

    const data = {
      date: fullMonthBox ? monthDate : dateData,
      bank_account_id: selectedbank,
      type: selected,
      user_id: user_id,
    };
    setLoading(true);
    setIsVisible(false);
    fetch(`${baseUrl}/daily_scroll_received_wise`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);

        if (data.metadata) {
          setIsVisible(true);
          if (data.metadata.success) {
            setStudentDetailData(data.payload.student_detail_data);

            let result = data.payload.feeChallans.sort(
              (a, b) => new Date(a.feed_at) - new Date(b.feed_at)
            );

            // setData(data.payload.feeChallans)
            setData(result);

            setAmount(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const todayDate = () => {
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  const handleBankChange = (e) => {
    setselectedbank(e.target.value);
    const result = allsubaccounts.find((item) => item.id == e.target.value);
    console.log(result, "rang lagay");
    setWholeBank(result);
  };
  console.log(wholeBankCat);
  const onlyTimeTaker = (dateTimeString) => {
    const timeString = dateTimeString.split(" ")[1];
    return timeString;
  };

  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}, ${d.getFullYear()}`;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row mt-4 mb-4">
          <div className="col-sm-3">
            <input
              checked={selected == "timeWise"}
              onChange={(e) => onRadioButtonClick(e)}
              defaultChecked
              type="radio"
              id="TimeWise"
              name="contact"
              value="timeWise"
            />
            <label for="TimeWise">Receiving Time Wise</label>
          </div>

          <div className="col-sm-3">
            <input
              checked={selected == "summary"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="G_Summary"
              name="contact"
              value="summary"
            />
            <label for="G_Summary">Summary Admission Wise</label>
          </div>

          <div className="col-sm-3">
            <input
              checked={selected == "studentWise"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="StudentDetailWise"
              name="contact"
              value="studentWise"
            />
            <label for="StudentDetailWise">Student Detail Wise</label>
          </div>
        </div>

        <div className="row">
          {fullMonthBox ? (
            <div className="col-md-3">
              <label className="form-control-label">
                <b>
                  Select Month{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </b>
              </label>
              <input
                value={monthDate}
                className="form-control"
                onChange={(e) => setMonthDate(e.target.value)}
                type="month"
              />
            </div>
          ) : (
            <div className="col-md-3">
              <label className="form-control-label">
                <b>
                  Select Date{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </b>
              </label>
              <input
                value={dateData}
                className="form-control"
                onChange={(e) => setDateData(e.target.value)}
                type="date"
              />
            </div>
          )}
          {/* <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select User{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <select
              onChange={(e) => setUserId(e.target.value)}
              className="form-select form-control"
            >
              <option value="">Select User</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="row col-md-3">
            <label className="form-control-label">
              <b>
                Select Bank{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <select
              className="form-select form-control"
              name="sub_account_id"
              value={selectedbankcateogry}
              onChange={(e) => {
                setselectedbankcateogry(e.target.value);
                let anserr = allbanks.filter(
                  (item) => item.id == e.target.value
                );
                setWholeBankCat(anserr);
                if (anserr.length != 0) {
                  setallsubaccounts([...anserr[0].bank_accounts]);
                } else {
                  setallsubaccounts([]);
                }
              }}
              style={{ backgroundColor: "white" }}
              aria-label="Default select example"
            >
              <option acode="" selected value="">
                Select...
              </option>
              {allbanks.map((item) => (
                <option value={item.id}>{item.title}</option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Bank Branch<span style={{ color: "red" }}> *</span>
              </b>
            </label>
            <select
              className="form-select form-control"
              name="sub_account_id"
              value={selectedbank}
              onChange={(e) => {
                handleBankChange(e);
              }}
              style={{ backgroundColor: "white" }}
              aria-label="Default select example"
            >
              <option acode="" selected value="" disabled>
                Select...
              </option>
              {allsubaccounts.map((item) => (
                <option value={item.id}>
                  {item.bank_name}&nbsp;&nbsp;{`(${item.account_head})`}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex col-md-3 mt-7">
            <div className="mt-4 mr-5">
              <label className="checkbox checkbox-single">
                <input
                  type="checkbox"
                  onChange={(e) => setFullMonthBox(e.target.checked)}
                />
                <span />
                &nbsp;&nbsp;&nbsp;Show Full Month Scroll
              </label>
            </div>

            <button className="btn btn-primary" onClick={handleDateChange}>
              Show
            </button>
          </div>
        </div>

        <div className="d-flex mt-6 col-md-4">
          {data?.length > 0 ? (
            <ReactToPrint
              documentTitle={`Daily Scroll fee`}
              pageStyle="auto"
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint={true}
              trigger={() => (
                <button
                  // onClick={() => console.log("dfsdfsdfd")}
                  disabled={printloading ? true : false}
                  className="btn btn-primary w-25"
                >
                  {printloading ? <span>Printing..</span> : "Print"}
                </button>
              )}
              content={() => componentRef.current}
            />
          ) : null}

          <DownloadTableExcel
            filename="Daily Scroll Fee"
            sheet="Daily Scroll Fee"
            currentTableRef={exportTable.current}
          >
            {data?.length > 0 ? (
              <button className="btn btn-primary mx-4">Export</button>
            ) : null}
          </DownloadTableExcel>
        </div>
        <br />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            <div id="tableboot">
              <div className="row p-3 mt-5">
                <img
                  // className='col-md-2'
                  style={{ height: 120, width: 130 }}
                  src={icon}
                />

                <div className="col-md-8" style={{ paddingTop: "25px" }}>
                  <h1>{unitName}</h1>
                  {data.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "90%",
                          marginTop: "10px",
                        }}
                      >
                        <div>
                          {fullMonthBox ? (
                            <h5 className="">Month:- {monthDate}</h5>
                          ) : (
                            <h5 className="">Date:- {dateData}</h5>
                          )}
                          <h5 className="">Printing Date :- {todayDate()}</h5>
                        </div>
                        <div>
                          <h5 className="">
                            Bank Category :-{" "}
                            {wholeBankCat.length && wholeBankCat[0].title}{" "}
                          </h5>
                          <h5 className="">
                            Bank:- {wholeBank.bank_name}&nbsp;&nbsp;
                            {`(${wholeBank.account_head})`}{" "}
                          </h5>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <ColoredLine color={"#FF0000"} />

              {selected == "timeWise" || selected == "summary" ? (
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>Sr No</th>
                      <th>Admission Id</th>
                      <th>Name</th>
                      <th>Section Name</th>
                      <th>Class Name</th>
                      <th>Campus Name</th>
                      <th>Amount</th>
                      {timeWise ? <th>Time</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item, i) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {i + 1}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item.student?.admission_id}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item.student?.name}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item.student.global_section?.name}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item.student?.student_class?.name}
                          </td>

                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item.student?.campus?.name}
                          </td>

                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item.paid}
                          </td>
                          {timeWise ? (
                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {onlyTimeTaker(item.feed_at)}
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    <tr id="tbl">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {timeWise ? <td></td> : null}
                      <td
                        colSpan={2}
                        style={{
                          color: "black",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        Total Amount
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          textAlign: "start",
                        }}
                      >
                        {amount ? amount.total_amount : 0}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th className="text-center">Sr No.</th>
                      <th className="text-center">AdmID</th>
                      <th>Name</th>
                      <th className="text-center">FeeID</th>
                      <th className="text-center">TypeID</th>
                      <th className="text-center">Fee Type Name</th>
                      <th className="text-center">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentDetailData &&
                      studentDetailData.map((item, i) => (
                        <React.Fragment key={i}>
                          <tr id="tbl">
                            <td
                              style={{ color: "#121291", fontWeight: "bold" }}
                              className="text-center"
                            >
                              {i + 1}
                            </td>
                            <td
                              style={{ color: "#121291", fontWeight: "bold" }}
                              className="text-center"
                            >
                              {item.student?.admission_id}
                            </td>
                            <td
                              style={{ color: "#121291", fontWeight: "bold" }}
                              className="text_align_start"
                            >
                              {item.student?.name}
                            </td>
                          </tr>
                          {item.challans &&
                            item.challans.map((sub_item, j) => (
                              <React.Fragment key={j}>
                                {sub_item.fee_challan_details.map(
                                  (sub_sub_item, k) => (
                                    <tr key={k}>
                                      <td colSpan="3" />
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {sub_sub_item.fee_challan_id}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {sub_sub_item.fees_type_id}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {sub_sub_item.fee_name}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {sub_sub_item.amount}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </React.Fragment>
                            ))}
                          <tr
                            id="tbl"
                            style={{
                              borderTop: "2px solid black",
                              borderBottom: "2px solid black",
                            }}
                          >
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              style={{
                                color: "black",
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                              }}
                            >
                              Total Amount
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {item.total_paid}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}

                    <tr id="tbl">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#121291",
                          fontSize: "1.4rem",
                          fontWeight: "bold",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                      >
                        Grand Total Amount
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: "1.4rem",
                          fontWeight: "bold",
                          textAlign: "center",
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                        }}
                      >
                        {amount ? amount.total_amount : 0}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
          </>
        ) : null}
      </Paper>

      {/* /////for print  */}
      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot">
          <div className="p-3" style={{ display: "flex" }}>
            <img
              // className='col-md-2'
              style={{ height: 120, width: 130 }}
              src={icon}
            />

            <div className="row ml-4">
              <h1>{unitName}</h1>
              <h3>Daily Scroll Bank Wise Report</h3>
              {data.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "70%",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      {fullMonthBox ? (
                        <h5 className="ffonmt">Month:- {monthDate}</h5>
                      ) : (
                        <h5 className="ffonmt">Date:- {dateData}</h5>
                      )}
                      <h5 className="ffonmt">Printing Date :- {todayDate()}</h5>
                    </div>
                    <div>
                      <h5 className="ffonmt">
                        Bank Category :-{" "}
                        {wholeBankCat.length && wholeBankCat[0].title}{" "}
                      </h5>
                      <h5 className="ffonmt">
                        Bank:- {wholeBank.bank_name}&nbsp;&nbsp;
                        {`(${wholeBank.account_head})`}{" "}
                      </h5>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          {/* <hr
            style={{
              color: "black",
              backgroundColor: "black",
              height: 0.5,
              borderColor: "black",
            }}
          /> */}

          {selected == "timeWise" || selected == "summary" ? (
            <table style={{ width: "100%" }}>
              <thead>
                <tr id="tbl" style={{ borderTop: "1px solid black" }}>
                  <th
                    style={{ width: "70px", textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    SrNo.
                  </th>
                  <th
                    style={{ width: "70px", textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    AdmID
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Name
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Section
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Class
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Campus
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Amount
                  </th>
                  {timeWise ? (
                    <th
                      style={{ textAlign: "center" }}
                      className="fontchagerledger2"
                    >
                      Time
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, i) => (
                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.student?.admission_id}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start fontchagerledger2"
                      >
                        {item.student?.name.length > 18
                          ? item.student?.name.slice(0, 22)
                          : item.student?.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.student.global_section?.name.length > 12
                          ? item.student?.global_section?.name.slice(0, 12)
                          : item.student?.global_section?.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.student?.student_class?.name.length > 12
                          ? item.student?.student_class?.name.slice(0, 12)
                          : item.student?.student_class?.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.student?.campus?.name}
                      </td>

                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.paid}
                      </td>
                      {timeWise ? (
                        <td
                          style={{ color: "black" }}
                          className="text-center fontchagerledger2"
                        >
                          {onlyTimeTaker(item.feed_at)}
                        </td>
                      ) : null}
                    </tr>
                  ))}
                <tr id="tbl">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {timeWise ? <td></td> : null}
                  <td
                    colSpan={2}
                    style={{
                      color: "black",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    Grand Total Amount:-
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      textAlign: "start",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {amount ? amount.total_amount : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table style={{ width: "100%" }}>
              <thead>
                <tr id="tbl" style={{ borderTop: "1px solid black" }}>
                  <th
                    style={{ width: "70px", textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    SrNo.
                  </th>
                  <th
                    style={{ width: "70px", textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    AdmID
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Name
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    FeeID
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    TypeID
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Fee Type Name
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentDetailData &&
                  studentDetailData.map((item, i) => (
                    <React.Fragment key={i}>
                      <tr
                        id="tbl"
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td
                          style={{ color: "#121291", fontWeight: "bold" }}
                          className="text-center fontchagerledger2"
                        >
                          {i + 1}
                        </td>
                        <td
                          style={{ color: "#121291", fontWeight: "bold" }}
                          className="text-center fontchagerledger2"
                        >
                          {item.student?.admission_id}
                        </td>
                        <td
                          style={{ color: "#121291", fontWeight: "bold" }}
                          className="text_align_start fontchagerledger2"
                        >
                          {item.student?.name}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {item.challans &&
                        item.challans.map((sub_item, j) => (
                          <React.Fragment key={j}>
                            {sub_item.fee_challan_details.map(
                              (sub_sub_item, k) => (
                                <tr key={k}>
                                  <td colSpan="3" />

                                  <td
                                    style={{ color: "black" }}
                                    className="text-center fontchagerledger2"
                                  >
                                    {sub_sub_item.fee_challan_id}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text-center fontchagerledger2"
                                  >
                                    {sub_sub_item.fees_type_id}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text-center fontchagerledger2"
                                  >
                                    {sub_sub_item.fee_name}
                                  </td>

                                  <td
                                    style={{ color: "black" }}
                                    className="text-center fontchagerledger2"
                                  >
                                    {sub_sub_item.amount}
                                  </td>
                                </tr>
                              )
                            )}
                          </React.Fragment>
                        ))}
                      <tr id="tbl">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            color: "black",
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Total Amount
                        </td>
                        <td
                          style={{
                            color: "black",
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          {item.total_paid}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}

                <tr id="tbl">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td
                    colSpan={2}
                    style={{
                      color: "black",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    Grand Total Amount:-
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      textAlign: "start",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {amount ? amount.total_amount : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* ///for exprot print */}
      <div
        ref={exportTable}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot">
          <div className="p-3" style={{ display: "flex" }}>
            <img
              // className='col-md-2'
              style={{ height: 120, width: 130 }}
              src={icon}
            />

            <div className="row ml-4">
              <h1>{unitName}</h1>
              <h3>Daily Scroll Bank Wise Report</h3>
              {data.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "70%",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      {fullMonthBox ? (
                        <h5 className="ffonmt">Month:- {monthDate}</h5>
                      ) : (
                        <h5 className="ffonmt">Date:- {dateData}</h5>
                      )}
                      <h5 className="ffonmt">Printing Date :- {todayDate()}</h5>
                    </div>
                    <div>
                      <h5 className="ffonmt">
                        Bank Category :-{" "}
                        {wholeBankCat.length && wholeBankCat[0].title}{" "}
                      </h5>
                      <h5 className="ffonmt">
                        Bank:- {wholeBank.bank_name}&nbsp;&nbsp;
                        {`(${wholeBank.account_head})`}{" "}
                      </h5>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          {/* <hr
            style={{
              color: "black",
              backgroundColor: "black",
              height: 0.5,
              borderColor: "black",
            }}
          /> */}

          {selected == "timeWise" || selected == "summary" ? (
            <table style={{ width: "100%" }}>
              <thead>
                <tr id="tbl" style={{ borderTop: "1px solid black" }}>
                  <th
                    style={{ width: "70px", textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    SrNo.
                  </th>
                  <th
                    style={{ width: "70px", textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    AdmID
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Name
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Section
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Class
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Campus
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Amount
                  </th>
                  {timeWise ? (
                    <th
                      style={{ textAlign: "center" }}
                      className="fontchagerledger2"
                    >
                      Time
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, i) => (
                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.student?.admission_id}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start fontchagerledger2"
                      >
                        {item.student?.name.length > 18
                          ? item.student?.name.slice(0, 22)
                          : item.student?.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.student.global_section?.name.length > 12
                          ? item.student?.global_section?.name.slice(0, 12)
                          : item.student?.global_section?.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.student?.student_class?.name.length > 12
                          ? item.student?.student_class?.name.slice(0, 12)
                          : item.student?.student_class?.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.student?.campus?.name}
                      </td>

                      <td
                        style={{ color: "black" }}
                        className="text-center fontchagerledger2"
                      >
                        {item.paid}
                      </td>
                      {timeWise ? (
                        <td
                          style={{ color: "black" }}
                          className="text-center fontchagerledger2"
                        >
                          {onlyTimeTaker(item.feed_at)}
                        </td>
                      ) : null}
                    </tr>
                  ))}
                <tr id="tbl">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {timeWise ? <td></td> : null}
                  <td
                    colSpan={2}
                    style={{
                      color: "black",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    Grand Total Amount:-
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      textAlign: "start",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {amount ? amount.total_amount : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table style={{ width: "100%" }}>
              <thead>
                <tr id="tbl" style={{ borderTop: "1px solid black" }}>
                  <th
                    style={{ width: "70px", textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    SrNo.
                  </th>
                  <th
                    style={{ width: "70px", textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    AdmID
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Name
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    FeeID
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    TypeID
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Fee Type Name
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="fontchagerledger2"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentDetailData &&
                  studentDetailData.map((item, i) => (
                    <React.Fragment key={i}>
                      <tr
                        id="tbl"
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td
                          style={{ color: "#121291", fontWeight: "bold" }}
                          className="text-center fontchagerledger2"
                        >
                          {i + 1}
                        </td>
                        <td
                          style={{ color: "#121291", fontWeight: "bold" }}
                          className="text-center fontchagerledger2"
                        >
                          {item.student?.admission_id}
                        </td>
                        <td
                          style={{ color: "#121291", fontWeight: "bold" }}
                          className="text_align_start fontchagerledger2"
                        >
                          {item.student?.name}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {item.challans &&
                        item.challans.map((sub_item, j) => (
                          <React.Fragment key={j}>
                            {sub_item.fee_challan_details.map(
                              (sub_sub_item, k) => (
                                <tr key={k}>
                                  <td colSpan="3" />

                                  <td
                                    style={{ color: "black" }}
                                    className="text-center fontchagerledger2"
                                  >
                                    {sub_sub_item.fee_challan_id}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text-center fontchagerledger2"
                                  >
                                    {sub_sub_item.fees_type_id}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text-center fontchagerledger2"
                                  >
                                    {sub_sub_item.fee_name}
                                  </td>

                                  <td
                                    style={{ color: "black" }}
                                    className="text-center fontchagerledger2"
                                  >
                                    {sub_sub_item.amount}
                                  </td>
                                </tr>
                              )
                            )}
                          </React.Fragment>
                        ))}
                      <tr id="tbl">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            color: "black",
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Total Amount
                        </td>
                        <td
                          style={{
                            color: "black",
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          {item.total_paid}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}

                <tr id="tbl">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td
                    colSpan={2}
                    style={{
                      color: "black",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    Grand Total Amount:-
                  </td>
                  <td
                    style={{
                      color: "black",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      textAlign: "start",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    {amount ? amount.total_amount : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default DailyScroll;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
