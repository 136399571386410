import { Paper } from "@material-ui/core";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { DatePicker, Divider } from "antd";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;
const FeeSummary = () => {
  const [selected, setSelected] = useState("all");
  let authtoken = localStorage.getItem("userToken");
  const [yearId, setYearId] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [reportData, setReportData] = useState([]);
  const [campusId, setCampusId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [loading, setLoading] = useState(false);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const pageStyle = `
  @page {
    size: A4 !important;
}
`;

  useEffect(() => {
    getSettingData();
    sessions();
    // getAllCampus();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const datePicker = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setStartDate(dateString[0]);
    setendDate(dateString[1]);
  };
  const handleGetData = () => {
    if (yearId == "") {
      alert.error("Please select the year");
      return;
    }

    if (startDate == "") {
      alert.error("Please select the start date");
      return;
    }
    if (endDate == "") {
      alert.error("Please select the end date");
      return;
    }

    setLoading(true);
    const data = {
      year_id: yearId,
      campus_id: campusId,
      from_date: startDate,
      to_date: endDate,
    };
    fetch(`${baseUrl}/class_wise_fee_summary`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.payload) {
          console.log(data);
          setReportData(data.payload.fee_summary);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-4 mt-9">
            {/* <label>
              <b>Select Date</b>
            </label> */}
            <RangePicker onChange={datePicker} />
          </div>
        </div>
        <div className="mt-4 d-flex">
          <div>
            <button className="btn btn-primary mr-4" onClick={handleGetData}>
              Show
            </button>
          </div>
          <div>
            <ReactToPrint
              documentTitle={`Fee Summary`}
              pageStyle={pageStyle}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint={true}
              trigger={() => (
                <button
                  // onClick={() => console.log("dfsdfsdfd")}
                  disabled={printloading ? true : false}
                  className="btn btn-success button_blue button_blue"
                >
                  {printloading ? <span>Printing..</span> : "Print"}
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
        <hr />
        <div className="mt-5">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <>
              <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th>CLASS</th>
                      <th>ADMISSION Fee</th>
                      <th>ANNUAL Fund</th>
                      <th>DUPLICATE FEE BILL CHARGES</th>
                      <th>HOSTEL Fee</th>
                      <th>MONTHLY Fee</th>
                      <th>OTHERS Fine</th>
                      <th>PROSPECTUS</th>
                      <th>RE-Admission Fee</th>
                      <th>REGISTRATION</th>
                      <th>SECOND SHIFT STUDY</th>
                      <th>SPORTS CHARGES</th>
                      <th>
                        <b>Total</b>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {reportData &&
                      reportData.map((item, index) => (
                        <tr id="tbl">
                          <td
                            style={{ color: "black" }}
                            className="text_align_start"
                          >
                            {item?.name}
                          </td>

                          <td style={{ color: "black" }}>
                            {item?.admission_fee}
                          </td>
                          <td style={{ color: "black" }}>
                            {item?.annual_fund}
                          </td>
                          <td style={{ color: "black" }}>
                            {item?.duplicate_fee_bill_charges}
                          </td>
                          <td style={{ color: "black" }}>{item?.hostel_fee}</td>
                          <td style={{ color: "black" }}>
                            {item?.monthly_fee}
                          </td>
                          <td style={{ color: "black" }}>
                            {item?.others_fine}
                          </td>
                          <td style={{ color: "black" }}>{item?.prospectus}</td>
                          <td style={{ color: "black" }}>
                            {item?.re_admission_fee}
                          </td>
                          <td style={{ color: "black" }}>
                            {item?.registration}
                          </td>
                          <td style={{ color: "black" }}>
                            {item?.second_shift_study}
                          </td>
                          <td style={{ color: "black" }}>
                            {item?.sports_charges}
                          </td>
                          <td style={{ color: "black" }}>
                            <b>{item?.total}</b>
                          </td>
                        </tr>
                      ))}
                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        <b>Total</b>
                      </td>

                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.admission_fee),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.annual_fund),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) =>
                              a + parseInt(c.duplicate_fee_bill_charges),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.hostel_fee),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.monthly_fee),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.others_fine),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.prospectus),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.re_admission_fee),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.registration),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.second_shift_study),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.sports_charges),
                            0
                          )}
                        </b>
                      </td>
                      <td style={{ color: "black" }}>
                        <b>
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.total),
                            0
                          )}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="p-3" style={{ display: "flex" }}>
            <img
              // className='col-md-2'
              style={{ height: 100, width: 100 }}
              src={icon}
            />
            <div className="col-md-8" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <p style={{ fontSize: "17px" }}>Student Fee Received Summary</p>
            </div>
          </div>

          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>CLASS</th>
                  <th>ADMISSION Fee</th>
                  <th>ANNUAL Fund</th>
                  <th>DUPLICATE FEE BILL CHARGES</th>
                  <th>HOSTEL Fee</th>
                  <th>MONTHLY Fee</th>
                  <th>OTHERS Fine</th>
                  <th>PROSPECTUS</th>
                  <th>RE-Admission Fee</th>
                  <th>REGISTRATION</th>
                  <th>SECOND SHIFT STUDY</th>
                  <th>SPORTS CHARGES</th>
                  <th>
                    <b>Total</b>
                  </th>
                </tr>
              </thead>

              <tbody>
                {reportData &&
                  reportData.map((item, index) => (
                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item?.name}
                      </td>

                      <td style={{ color: "black" }}>{item?.admission_fee}</td>
                      <td style={{ color: "black" }}>{item?.annual_fund}</td>
                      <td style={{ color: "black" }}>
                        {item?.duplicate_fee_bill_charges}
                      </td>
                      <td style={{ color: "black" }}>{item?.hostel_fee}</td>
                      <td style={{ color: "black" }}>{item?.monthly_fee}</td>
                      <td style={{ color: "black" }}>{item?.others_fine}</td>
                      <td style={{ color: "black" }}>{item?.prospectus}</td>
                      <td style={{ color: "black" }}>
                        {item?.re_admission_fee}
                      </td>
                      <td style={{ color: "black" }}>{item?.registration}</td>
                      <td style={{ color: "black" }}>
                        {item?.second_shift_study}
                      </td>
                      <td style={{ color: "black" }}>{item?.sports_charges}</td>
                      <td style={{ color: "black" }}>
                        <b>{item?.total}</b>
                      </td>
                    </tr>
                  ))}
                <tr id="tbl">
                  <td style={{ color: "black" }} className="text_align_start">
                    <b>Total</b>
                  </td>

                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.admission_fee),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.annual_fund),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.duplicate_fee_bill_charges),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.hostel_fee),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.monthly_fee),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.others_fine),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.prospectus),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.re_admission_fee),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.registration),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.second_shift_study),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.sports_charges),
                        0
                      )}
                    </b>
                  </td>
                  <td style={{ color: "black" }}>
                    <b>
                      {reportData.reduce((a, c) => a + parseInt(c.total), 0)}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default FeeSummary;
