import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Form, Col, Table, Spinner, Modal } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import ViewSalaryDetails from "../FilterSalary/ViewSalaryDetails/ViewSalaryDetails";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import MySelect from "./../../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import Paper from "@material-ui/core/Paper";
import PaySalaryModal from "../PaySalary/PaySalaryModal";
import { Spin } from "antd";

const PaySalary = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [designations, setdesignations] = useState([]);
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");
  const [selectedclasses, setselectedclasses] = useState([]);
  const [Allsessions, setAllsessions] = useState([]);
  const [allData, setallData] = useState([]);
  const [loading, setloading] = useState(false);
  const [bankList, setbankList] = useState([]);
  const [currentStaff, setcurrentStaff] = useState({});
  const [viewModal, setviewModal] = useState(false);
  let [optionSelected, setoptionSelected] = useState([]);
  const [optionSelectedvalue, setoptionSelectedvalue] = useState([]);
  //states
  const user = useSelector((state) => state.user.user);
  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [AllSalaries, setAllSalaries] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  //New States
  const [paySalaryModal, setPaySalaryModal] = useState(false);
  const [selectedbankcateogry, setselectedbankcateogry] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const [payType, setPayType] = useState([]);
  const [selectedCampusId, setSelectedCampusId] = useState([]);

  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [paidStaff, setPaidStaff] = useState([]);

  const [selectedbank, setselectedbank] = useState("");
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [employeeforsalrygenerate, setemployeeforsalrygenerate] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [ispaysalary, setispaysalary] = useState(false);
  const [selectedbankcategory, setselectedbankcategory] = useState("");
  const [bankDetail, setbankDetail] = useState({});
  const [bankcategorylist, setbankcategorylist] = useState([]);
  const [totalNetSalary, setTotalNetSalary] = useState(0);
  const [totalGrossSalary, setTotalGrossSalary] = useState(0);

  const currentyear = new Date().getFullYear();
  const [selecteddate, setselecteddate] = useState("");
  const [searchtext, setsearchtext] = useState("");
  const [paysalarydata, setpaysalarydata] = useState({});

  const [cheque_number, setCheque_number] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [showCheque, setshowCheque] = useState("");

  useEffect(() => {
    getAlldesignations();
    getAllBanks();
  }, []);

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "fetch data ");
        setbankList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAlldesignations = () => {
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setdesignations(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBankList = () => {
    fetch(`${baseUrl}/bank`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setbankList(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchFilter = () => {
    if (selecteddate == "") {
      alert.error("Month is required");
      return;
    }
    setloading(true);
    const data = {
      campus_id: selectedCampusId,
      salary_month: selecteddate == "" ? null : `${selecteddate}-01`,
      designation_id: optionSelectedvalue,
      bank_account_id: selectedbank,
    };
    console.log(selecteddate);
    console.log(data, "search filter data ");

    setispaysalary(false);
    setAllSalaries([]);
    fetch(`${baseUrl}/get_salaries_for_payment_letter`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          setloading(false);
          console.log(data);
          console.log(data.payload);
          if (data.metadata) {
            if (data.metadata.success) {
              setpaysalarydata({ ...data.payload });
              setAllSalaries([...data.payload.salaries]);
              // console.log()
            } else {
              alert.show("Error" + data.metadata.message);
            }
          } else {
            alert.show("Error" + data.metadata.message);
          }
        });
      })
      .catch((err) => {
        alert.show("Error" + data.metadata.message);
        console.log(err);
      });
  };

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const SeeDetails = (salary) => {
    setcurrentStaff(salary);
    setviewModal(true);
  };

  const handleChange = (selected) => {
    let valuearray = [];
    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setselectedclasses([...valuearray]);
    setoptionSelectedvalue([...valuearray]);
    setoptionSelected([...selected]);
    console.log(optionSelectedvalue);
  };
  const Options = [];
  designations.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });
  const allcheckboxChecked = () => {
    let middlearray = [];
    let studentarray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      AllSalaries.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
      setemployeeforsalrygenerate([...studentarray]);
      //  getStudentsForFeeGenerate(studentarray, campusid);
    } else {
      setcheckallcheckbox(true);
      AllSalaries.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
      setemployeeforsalrygenerate([...studentarray]);
      // getStudentsForFeeGenerate(studentarray, campusid);
    }

    setAllSalaries([...middlearray]);
    getSomeOfGrossSalary(middlearray);
    getSomeOfNetSalary(middlearray);
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    AllSalaries.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    setemployeeforsalrygenerate([...studentarray]);
    setAllSalaries([...middlearray]);
    console.log(middlearray);
    getSomeOfGrossSalary(middlearray);
    getSomeOfNetSalary(middlearray);
  };
  const getSomeOfGrossSalary = (array) => {
    let sum = 0;
    array.map((item) => {
      if (item.checked == true) sum += parseInt(item?.gross_salary);
    });
    setTotalGrossSalary(sum);
  };
  const getSomeOfNetSalary = (array) => {
    let sum = 0;
    array.map((item) => {
      if (item.checked == true) sum += parseInt(item?.net_pay);
    });
    setTotalNetSalary(sum);
  };

  const paySalay = () => {
    console.log(employeeforsalrygenerate);
    let data = {
      salary_id: employeeforsalrygenerate,
      cheque_number,
    };
    // setConfirmLoading(true);
    console.log(data);
    fetch(`${baseUrl}/pay_salaries`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          setConfirmLoading(false);
          setConfirmModal(false);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.show(data.metadata.message);
              setispaysalary(true);
              setshowCheque(data.payload.cheque_number);
            } else {
              alert.show("Error " + data.metadata.message);
            }
          } else {
            alert.show("Erro r" + data.metadata.message);
          }
        });
      })
      .catch((err) => {
        setConfirmLoading(false);
        setConfirmModal(false);
        alert.show("Error" + data.metadata.message);
        console.log(err);
      });
  };

  const filteredStaff = () => {
    let objarray = [];
    employeeforsalrygenerate.map((firstid) => {
      AllSalaries.map((item) => {
        if (item.id === firstid) {
          let data = item;
          objarray.push(data);
        }
      });
    });
    console.log("filteredStaff obj", objarray);
    let res = objarray.sort(
      (a, b) => a.employee.emp_code - b.employee.emp_code
    );
    let sum = 0;
    res.map((item) => {
      sum += parseInt(item.net_pay);
    });
    var result = [];
    var groupSize = 38;

    for (var i = 0; i < res.length; i += groupSize) {
      result.push(res.slice(i, i + groupSize));
    }

    setGrandTotal(sum);
    setPaidStaff(result);
  };

  const filteredBank = () => {
    console.log("banklist", bankList);
    console.log("selected bank", selectedbank);

    console.log(allsubaccounts);

    allsubaccounts.map((bankk) => {
      if (bankk.id == selectedbank) {
        setbankDetail(bankk);
      }
    });
  };

  const dataSearch = AllSalaries.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.employee?.full_name?.toLowerCase().includes(text)
      : true;
  });

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label className="form-control-label">
              <b>
                Bank Category <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              className="form-select form-control"
              value={selectedbankcateogry}
              onChange={(e) => {
                setselectedbankcateogry(e.target.value);
                console.log(bankList);
                let anserr = bankList.filter(
                  (item) => item.id == e.target.value
                );
                if (anserr.length != 0) {
                  setallsubaccounts([...anserr[0].bank_accounts]);
                } else {
                  setallsubaccounts([]);
                }
              }}
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option>Select</option>
              {bankList.map((bank) => (
                <option value={bank.id}>{bank.title}</option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label className="form-control-label">
              <b>
                Select Bank <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              className="form-select form-control"
              name="sub_account_id"
              value={selectedbank}
              onChange={(e) => {
                setselectedbank(e.target.value);
              }}
              style={{ backgroundColor: "white" }}
              aria-label="Default select example"
            >
              <option acode="" selected value="">
                Select...
              </option>
              {allsubaccounts.map((item) => (
                <option value={item.id}>{item.bank_name}</option>
              ))}
            </select>
          </div>
        </div>
        <br />

        <div className="row mt-5">
          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Campus <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              onChange={(e) => setSelectedCampusId(e.target.value)}
              className="form-select form-control"
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option value="">Select</option>
              {allcampuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Salary Month <span style={{ color: "red" }}>*</span>
              </b>
            </label>

            <input
              className="form-control"
              style={{ height: 40 }}
              onChange={(e) => setselecteddate(e.target.value)}
              type="month"
              value={selecteddate}
            />
          </div>
          <div className="col-md-4">
            <label className="form-control-label">
              <b>Select Desigination</b>{" "}
            </label>
            <MySelect
              options={Options}
              isMulti
              name="selectedOptions"
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChange}
              placeholder="select All"
              value={optionSelected}
            />
          </div>

          <div className="col-md-1 mt-7">
            <button
              disabled={selectedbank == "" ? true : false}
              className="btn btn-success button_blue"
              onClick={searchFilter}
            >
              Search
            </button>
          </div>
        </div>
        <br />

        <div className="row mt-5 mb-4">
          <div className="col">
            <input
              onChange={(e) => setsearchtext(e.target.value)}
              id="searchInput"
              className="form-control "
              placeholder="Search By Name"
            />
          </div>
          <div className="col">
            <input
              onChange={(e) => setCheque_number(e.target.value)}
              id="searchInput"
              className="form-control"
              placeholder="Cheque Number"
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-success button_blue"
              disabled={employeeforsalrygenerate.length == 0 ? true : false}
              onClick={() => {
                if (cheque_number == "") {
                  alert.error("Cheque Number Is Required");
                  return;
                }
                if (selecteddate == "") {
                  alert.error("Month Is Required");
                  return;
                }
                setConfirmModal(true);
              }}
            >
              Pay Salary
            </button>

            <button
              style={{ marginLeft: "10px" }}
              disabled={!ispaysalary}
              className="btn btn-success button_blue"
              onClick={() => {
                setPaySalaryModal(true);
                console.log("filteredBank");
                filteredBank();
                console.log("filteredStaff");
                filteredStaff();
              }}
            >
              Print
            </button>
          </div>
        </div>

        <Modal show={paySalaryModal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Pay Salary
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <PaySalaryModal
              show={() => setPaySalaryModal()}
              grandTotal={grandTotal}
              paidStaff={paidStaff}
              bankDetail={bankDetail}
              showCheque={showCheque}
              selecteddate={selecteddate}
            />
          </Modal.Body>
        </Modal>

        <Table responsive>
          <thead>
            <tr>
              <th>
                <input
                  onClick={(e) => {
                    allcheckboxChecked();
                  }}
                  checked={checkallcheckbox}
                  style={{}}
                  placeholder="Checkbox"
                  type="checkbox"
                />{" "}
                {/* <span style={{ marginleft: "15px" }}>Check All</span> */}
              </th>
              <th>Employee name</th>
              <th>Father name</th>
              <th>Employee Code</th>
              <th>Cnic No</th>
              <th>Account No</th>

              <th>gross_salary</th>
              <th>Net Pay</th>
            </tr>
          </thead>
          {dataSearch.length == 0 ? null : (
            <tbody>
              {dataSearch?.map((salary, index) => (
                <tr className="text-center">
                  <td style={{ textAlign: "start" }}>
                    <input
                      key={salary.id}
                      onClick={(e) => {
                        checkBoxClick(e, salary.id);
                      }}
                      value={allcheckboxes[index]}
                      checked={salary.checked}
                      type="checkbox"
                    />
                  </td>
                  {/* <td>
                    {salary.employee.full_name}

                  </td>
                  <td>
                    {salary.employee.father_name}

                  </td> */}

                  <td
                    title={
                      salary.employee.full_name.length > 15
                        ? salary.employee.full_name
                        : null
                    }
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {salary.employee.full_name.length > 15
                      ? salary.employee.full_name.substring(0, 12) + "..."
                      : salary.employee.full_name}
                  </td>
                  <td
                    title={
                      salary.employee.father_name.length > 15
                        ? salary.employee.father_name
                        : null
                    }
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {salary.employee.father_name.length > 15
                      ? salary.employee.father_name.substring(0, 12) + "..."
                      : salary.employee.father_name}
                  </td>

                  <td style={{ textAlign: "start" }}>
                    {salary.employee.emp_code}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {salary.employee.cnic_no}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {salary.employee.account_no}
                  </td>

                  <td style={{ textAlign: "start" }}>{salary.gross_salary}</td>

                  <td style={{ textAlign: "start" }}>{salary.net_pay}</td>
                </tr>
              ))}
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>Total Gross Salary :- {totalGrossSalary}</th>
                <th>Total Net Salary :- {totalNetSalary}</th>
              </tr>
            </tbody>
          )}
        </Table>
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}

        <Modal onHide={() => setviewModal(false)} show={viewModal} size="xl">
          <Modal.Header>View Salary Details</Modal.Header>

          <Modal.Body>
            <ViewSalaryDetails EmpDetails={currentStaff} />
          </Modal.Body>

          <Modal.Footer>
            <button
              onClick={() => setviewModal(false)}
              className="btn btn-danger btn_width"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <Modal centered size="md" show={confirmModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Pay Salary
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>Are You Sure You Want To Pay Salary?</Modal.Body>
          <Modal.Footer>
            {confirmLoading ? (
              <Spin style={{ marginRight: "10px" }} />
            ) : (
              <button
                disabled={confirmLoading}
                onClick={paySalay}
                className="btn btn-success button_blue"
              >
                Pay Salary
              </button>
            )}

            <button
              onClick={() => {
                setConfirmModal(false);
              }}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
};

export default PaySalary;
