import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import "./table.css";

const PreviewModal = ({
  salaryList,
  show,
  hide,
  campusName,
  setsalaryList,
}) => {
  console.log("this is list salary==>", salaryList);

  const [selectedId, setSelectedId] = useState(null);

  const getFeeAmount = () => {
    let sum = 0;

    salaryList.forEach((element) => {
      sum += parseInt(element.net_pay);
    });

    return sum;
  };

  const selectRow = (item, i) => {
    setSelectedId(item.id);
  };

  const renderTableRow = (salary, index) => {
    if (salary.days > 30) {
      return (
        <>
          <tr
            onClick={() => selectRow(salary, index)}
            style={{
              backgroundColor: salary.id == selectedId ? "#D3CECE" : "",
              color: "green",
            }}
            className="tbodytr text-center"
            key={salary.id}
          >
            <td className="tbodytd fixed">{index + 1}</td>
            <td className="tbodytd fixed2">{salary.employee?.emp_code}</td>
            <td className="tbodytd fixed3">{salary.employee?.full_name}</td>
            <td className="tbodytd adjusting">
              {salary.employee?.father_name}
            </td>
            <td className="tbodytd">
              {salary.account_no ? salary.account_no : "-"}
            </td>
            <td className="tbodytd">{salary.basic_pay}</td>
            <td className="tbodytd">{salary.hifz}</td>
            <td className="tbodytd">{salary.hostel}</td>
            <td className="tbodytd">{salary.college}</td>
            <td className="tbodytd">{salary.additional_allowance}</td>
            <td className="tbodytd">{salary.increment}</td>
            <td className="tbodytd">{salary.second_shift}</td>
            <td className="tbodytd">{salary.ugs}</td>
            <td className="tbodytd">{salary.other_allowance}</td>
            <td className="tbodytd">{salary.hod}</td>
            <td className="tbodytd">{salary.science}</td>
            <td className="tbodytd">{salary.extra_period}</td>
            <td className="tbodytd">{salary.extra_coaching}</td>
            <td className="tbodytd">{salary.convance}</td>
            <td className="tbodytd">{salary.gpf_return}</td>
            <td
              className="tbodytd text-primary"
              style={{ backgroundColor: "darkgray" }}
            >
              {salary.gross_salary}
            </td>
            <td className="tbodytd">{salary.gp_fund}</td>
            <td className="tbodytd">{salary.welfare_fund}</td>
            <td className="tbodytd">{salary.eobi}</td>
            <td className="tbodytd">{salary.income_tax}</td>
            <td className="tbodytd">{salary.loan_refund}</td>
            <td className="tbodytd">{salary.other_deduction}</td>
            <td className="tbodytd">{salary.child_fee_deduction}</td>
            <td className="tbodytd">{salary.insurance}</td>
            <td className="tbodytd">{salary.van_charge}</td>
            <td className="tbodytd">{salary.net_pay}</td>
            <td className="tbodytd">{salary?.days}</td>
          </tr>
        </>
      );
    } else if (salary.days < 30) {
      return (
        <>
          <tr
            onClick={() => selectRow(salary, index)}
            style={{
              backgroundColor: salary.id == selectedId ? "#D3CECE" : "",
              color: "red",
            }}
            className="tbodytr text-center"
            key={salary.id}
          >
            <td className="tbodytd fixed">{index + 1}</td>
            <td className="tbodytd fixed2">{salary.employee?.emp_code}</td>
            <td className="tbodytd fixed3">{salary.employee?.full_name}</td>
            <td className="tbodytd adjusting">
              {salary.employee?.father_name}
            </td>
            <td className="tbodytd">
              {salary.account_no ? salary.account_no : "-"}
            </td>
            <td className="tbodytd">{salary.basic_pay}</td>
            <td className="tbodytd">{salary.hifz}</td>
            <td className="tbodytd">{salary.hostel}</td>
            <td className="tbodytd">{salary.college}</td>
            <td className="tbodytd">{salary.additional_allowance}</td>
            <td className="tbodytd">{salary.increment}</td>
            <td className="tbodytd">{salary.second_shift}</td>
            <td className="tbodytd">{salary.ugs}</td>
            <td className="tbodytd">{salary.other_allowance}</td>
            <td className="tbodytd">{salary.hod}</td>
            <td className="tbodytd">{salary.science}</td>
            <td className="tbodytd">{salary.extra_period}</td>
            <td className="tbodytd">{salary.extra_coaching}</td>
            <td className="tbodytd">{salary.convance}</td>
            <td className="tbodytd">{salary.gpf_return}</td>
            <td
              className="tbodytd text-primary"
              style={{ backgroundColor: "darkgray" }}
            >
              {salary.gross_salary}
            </td>
            <td className="tbodytd">{salary.gp_fund}</td>
            <td className="tbodytd">{salary.welfare_fund}</td>
            <td className="tbodytd">{salary.eobi}</td>
            <td className="tbodytd">{salary.income_tax}</td>
            <td className="tbodytd">{salary.loan_refund}</td>
            <td className="tbodytd">{salary.other_deduction}</td>
            <td className="tbodytd">{salary.child_fee_deduction}</td>
            <td className="tbodytd">{salary.insurance}</td>
            <td className="tbodytd">{salary.van_charge}</td>
            <td className="tbodytd">{salary.net_pay}</td>
            <td className="tbodytd">{salary?.days}</td>
          </tr>
        </>
      );
    } else {
      return (
        <>
          <tr
            onClick={() => selectRow(salary, index)}
            style={{
              backgroundColor: salary.id == selectedId ? "#D3CECE" : "",
            }}
            className="tbodytr text-center"
            key={salary.id}
          >
            <td className="tbodytd fixed">{index + 1}</td>
            <td className="tbodytd fixed2">{salary.employee?.emp_code}</td>
            <td className="tbodytd fixed3">{salary.employee?.full_name}</td>
            <td className="tbodytd adjusting">
              {salary.employee?.father_name}
            </td>
            <td className="tbodytd">
              {salary.account_no ? salary.account_no : "-"}
            </td>
            <td className="tbodytd">{salary.basic_pay}</td>
            <td className="tbodytd">{salary.hifz}</td>
            <td className="tbodytd">{salary.hostel}</td>
            <td className="tbodytd">{salary.college}</td>
            <td className="tbodytd">{salary.additional_allowance}</td>
            <td className="tbodytd">{salary.increment}</td>
            <td className="tbodytd">{salary.second_shift}</td>
            <td className="tbodytd">{salary.ugs}</td>
            <td className="tbodytd">{salary.other_allowance}</td>
            <td className="tbodytd">{salary.hod}</td>
            <td className="tbodytd">{salary.science}</td>
            <td className="tbodytd">{salary.extra_period}</td>
            <td className="tbodytd">{salary.extra_coaching}</td>
            <td className="tbodytd">{salary.convance}</td>
            <td className="tbodytd">{salary.gpf_return}</td>
            <td
              className="tbodytd text-primary"
              style={{ backgroundColor: "darkgray" }}
            >
              {salary.gross_salary}
            </td>
            <td className="tbodytd">{salary.gp_fund}</td>
            <td className="tbodytd">{salary.welfare_fund}</td>
            <td className="tbodytd">{salary.eobi}</td>
            <td className="tbodytd">{salary.income_tax}</td>
            <td className="tbodytd">{salary.loan_refund}</td>
            <td className="tbodytd">{salary.other_deduction}</td>
            <td className="tbodytd">{salary.child_fee_deduction}</td>
            <td className="tbodytd">{salary.insurance}</td>
            <td className="tbodytd">{salary.van_charge}</td>
            <td className="tbodytd">{salary.net_pay}</td>
            <td className="tbodytd">{salary?.days}</td>
          </tr>
        </>
      );
    }
  };

  return (
    <Modal
      onHide={hide}
      size="xl"
      show={show}
      // scrollable={salaryList.length>4? true:false}
    >
      <Modal.Header>
        <Modal.Title>Salary List Details</Modal.Title>
        <h3 className="text-primary">{campusName.name}</h3>
        <h4>Net Payable Salary : {getFeeAmount()}</h4>

        <button onClick={hide} className="btn btn-danger">
          Close
        </button>
      </Modal.Header>

      <Modal.Body>
        <div className="tablemain">
          <table className="table">
            <thead className="tablehead">
              <tr className="tableheadtr text-center">
                <th className="tableHEadNAme tablehedfixed1">SR.NO</th>
                <th className="tableHEadNAme tablehedfixed2">Employee Code</th>
                <th className="tableHEadNAme tablehedfixed3">Employee Name</th>
                <th className="tableHEadNAme tablehedfixed4">Father Name</th>
                <th className="tableHEadNAme">Account No</th>
                <th className="tableHEadNAme">Basic Pay</th>
                <th className="tableHEadNAme">Hifz Allow</th>
                <th className="tableHEadNAme">Hostel Allow</th>
                <th className="tableHEadNAme">College Allow</th>
                <th className="tableHEadNAme">Additional Allow</th>
                <th className="tableHEadNAme">Increment Total</th>
                <th className="tableHEadNAme">SShift Allow</th>
                <th className="tableHEadNAme">USG Allow</th>
                <th className="tableHEadNAme">Other Allow</th>
                <th className="tableHEadNAme">HoD Allow</th>
                <th className="tableHEadNAme">Science Allow</th>
                <th className="tableHEadNAme">Ex.Priod Allow</th>
                <th className="tableHEadNAme">Ex.Coaching Allow</th>
                <th className="tableHEadNAme">Conv Allow</th>
                <th className="tableHEadNAme">GPF Return</th>
                <th className="tableHEadNAme">Gross Salary</th>
                <th className="tableHEadNAme">GP Fund</th>
                <th className="tableHEadNAme">Welfare Fund</th>
                <th className="tableHEadNAme">Eobi</th>
                <th className="tableHEadNAme">Income Tax</th>
                <th className="tableHEadNAme">Loan Refund</th>
                <th className="tableHEadNAme">Other Deduction</th>
                <th className="tableHEadNAme">Child Fee Deduction</th>
                <th className="tableHEadNAme">Insu. Deduction</th>
                <th className="tableHEadNAme">Van Fee Deduction</th>
                <th className="tableHEadNAme">Net Pay</th>
                <th className="tableHEadNAme">Days</th>
              </tr>
            </thead>
            <tbody>
              {salaryList.map((salary, index) => (
                <>{renderTableRow(salary, index)}</>
              ))}
              <tr className="tbodytr text-center">
                <td className="tbodytd fixed">-</td>
                <td className="tbodytd fixed2">-</td>
                <td className="tbodytd fixed3">-</td>
                <td className="tbodytd adjusting">Total</td>
                <td className="tbodytd">-</td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.basic_pay), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.hifz), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.hostel), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.college), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce(
                    (a, c) => a + parseInt(c.additional_allowance),
                    0
                  )}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.increment), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.second_shift), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.ugs), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce(
                    (a, c) => a + parseInt(c.other_allowance),
                    0
                  )}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.hod), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.science), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce(
                    (a, c) => a + parseInt(c.extra_period),
                    0
                  ) || "-"}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce(
                    (a, c) => a + parseInt(c.extra_coaching),
                    0
                  ) || "-"}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.convance), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.gpf_return), 0)}
                </td>
                <td
                  className="tbodytd text-primary"
                  style={{ backgroundColor: "darkgray" }}
                >
                  {salaryList.reduce((a, c) => a + parseInt(c.gross_salary), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.gp_fund), 0)}
                </td>

                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.welfare_fund), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.eobi), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.income_tax), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.loan_refund), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce(
                    (a, c) => a + parseInt(c.other_deduction),
                    0
                  )}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce(
                    (a, c) => a + parseInt(c.child_fee_deduction),
                    0
                  )}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.insurance), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.van_charge), 0)}
                </td>
                <td className="tbodytd">
                  {salaryList.reduce((a, c) => a + parseInt(c.net_pay), 0)}
                </td>
                <td className="tbodytd">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default PreviewModal;
