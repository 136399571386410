import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import CustomSelect from "../../Student/Form/CustomSelect";
import { DatePicker, Input, Space } from "antd";
import { Field } from "formik";

const { RangePicker } = DatePicker;
const AdmitModal = ({ show, onHide, singleStudent }) => {
  const alert = useAlert();
  const [hostelIDs, setHostelIDs] = useState([]);
  const [hostel, setHostel] = useState("");
  let [monthlyFeeInputsvalue, setmonthlyFeeInputsvalue] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [concession, setconcession] = useState([]);
  let [anyconcesstion, setanyconcesstion] = useState("");
  const [concessionAmount, setConcessionAmount] = useState(0);
  const [selectedConcType, setselectedConcType] = useState("");
  const [hostelFeeAmount, setHostelFeeAmount] = useState(0);
  const [holAfterConAmount, setholAfterConAmount] = useState(0);
  const [holAdmAfterConAmount, setholAdmAfterConAmount] = useState(0);
  const [hostelError, setHostelError] = useState(false);
  const [feeMonthError, setFeeMonthError] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);

  const [startingDate, setStartingDate] = useState("");
  // const [dateData, setDateData] = useState(
  //   new Date().toISOString().split("T")[0]
  // );
  const [hostelFee, setHostelFee] = useState([]);
  const [receivableAmounts, setReceivableAmounts] = useState([]);
  const [hostelReceivable, setHostelReceivable] = useState([]);
  const [lastHostelReceivable, setLastHostelReceivable] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [receivableFee, setReceivableFee] = useState(0);

  useEffect(() => {
    getHostelID();
    // getconcession();
    // gettingAmount7()
  }, []);

  /// for hotal dropdown

  let authtoken = localStorage.getItem("userToken");
  const getHostelID = async () => {
    await fetch(`${baseUrl}/hostel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHostelIDs(data.payload);
      })
      .catch((err) => {
        console.log("hostel", err);
      });
  };
  const hosteloptions = [];
  hosteloptions[0] = { value: "", label: "Select ..." };
  hostelIDs.map((item, index) => {
    hosteloptions[index + 1] = { value: item.id, label: item.name };
  });

  const hostalChange = (id) => {
    setHostelError(false);
    setHostel(id);
  };

  ////for fee months
  const handleChanger = (day, date) => {
    setFeeMonthError(false);
    let startDate = date[0] + "-01";
    let endDate = date[1] + "-01";
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    setmonthlyFeeInputsvalue(dates);
    //  console.log(dates)
  };

  //for start and end date
  // const dateSearch = (value) => {
  //   if (value != null) {
  //     const startDate = value[0].$d;
  //     const endDate = value[1].$d;
  //     var curr_moth = ("0" + (startDate.getMonth() + 1)).slice(-2);
  //     var curr_date = ("0" + startDate.getDate()).slice(-2);
  //     let newStartdate = `${startDate.getFullYear()}-${curr_moth}-${curr_date}`;
  //     setStartDate(newStartdate);

  //     var moth = ("0" + (endDate.getMonth() + 1)).slice(-2);
  //     var date = ("0" + endDate.getDate()).slice(-2);
  //     let newendDate = `${endDate.getFullYear()}-${moth}-${date}`;
  //     setEndDate(newendDate);
  //   }
  // };

  // ///conssion part
  // const getconcession = async () => {
  //   await fetch(`${baseUrl}/concession`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${authtoken}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("consession", data.payload);
  //       setconcession(data.payload);
  //     })
  //     .catch((err) => {
  //       console.log("concession", err);
  //     });
  // };

  const concessionoption = [];
  concessionoption[0] = { value: "", label: "Select ..." };
  concession.map((item, index) => {
    concessionoption[index + 1] = { value: item.id, label: item.title };
  });

  // // conssionchange
  // const handleConcession = (evt) => {
  //   setanyconcesstion(evt.value);
  //   const result = concession.filter((item) => item.id == evt.value);
  //   console.log("result", result);
  //   if (result[0]?.percentage != null) {
  //     setConcessionAmount(result[0].percentage);
  //     setselectedConcType("Percentage");

  //     ////hostel fee calculations
  //     let calu2 =
  //       (Number(result[0].percentage) / 100) * Number(hostelFeeAmount);
  //     const data2 = Number(hostelFeeAmount) - calu2;
  //     if (data2 >= 0) {
  //       setholAfterConAmount(data2);
  //     } else {
  //       setholAfterConAmount(0);
  //     }
  //   }

  //   if (result[0]?.amount != null) {
  //     setConcessionAmount(result[0].amount);
  //     setselectedConcType("Amount");

  //     //hostel fee calculation
  //     const data2 = Number(hostelFeeAmount) - Number(result[0].amount);
  //     if (data2 >= 0) {
  //       setholAfterConAmount(data2);
  //     } else {
  //       setholAfterConAmount(0);
  //     }
  //   }
  // };

  const getHostelFeeStructure = (SingleStudent, startingDate) => {
    const data = {
      student_id: SingleStudent.id,
      start_date: startingDate,
    };

    console.log(data, "Hostel Fee On Start");

    fetch(`${baseUrl}/hostelFeeStructure`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "Fee On Start");
        setHostelFee(data.payload);

        if (data.payload && data.payload.length > 0) {
          const initialAmounts = data.payload.map((feeOnStart) => ({
            fee_type_id: feeOnStart.fee_type_id,
            amount: feeOnStart.net_amount,
            amount_after_concession: feeOnStart.amount,
          }));

          let initialNetFee = 0;
          data.payload.map((item) => {
            initialNetFee += parseInt(item.net_amount);
          });

          let initialTotalFee = 0;
          data.payload.map((item) => {
            initialTotalFee += parseInt(item.amount);
          });

          // Update the state variable with the filtered values
          console.log("total Fee Collect", initialTotalFee);

          setTotalFee(initialNetFee);
          setReceivableFee(initialTotalFee);
          setReceivableAmounts(initialAmounts);

          setHostelReceivable(initialAmounts.slice(0, 2));
          setLastHostelReceivable(initialAmounts.slice(2));
        } else {
          setTotalFee(0);
          setReceivableFee(0);
        }
      })
      .catch((err) => {
        console.log("campuses", err);
      });
  };

  const cancelBtnHandle = () => {
    setHostel("");
    setHostelFee("");
    setStartingDate("");
    setmonthlyFeeInputsvalue([]);
    setHostelReceivable([]);
    setLastHostelReceivable([]);
    setTotalFee(0);
    setReceivableFee(0);
    onHide(false);
  };
  const handleAmountChange = (index, event) => {
    const { value } = event.target;

    // Convert value to number using parseFloat or parseInt
    const parsedValue = parseFloat(value); // or parseInt(value, 10) for integer

    // Create a new array with updated value
    const updatedReceivableAmounts = [...receivableAmounts];
    updatedReceivableAmounts[index] = {
      ...updatedReceivableAmounts[index],
      amount_after_concession: parsedValue,
    };

    let actualamount = updatedReceivableAmounts[index].amount;

    if (actualamount < parsedValue) {
      setError(true);
      alert.error("Receiving Amount is Greater than Net Amount");
    } else {
      setError(false);
    }

    let initialReceiveableFee = 0;
    updatedReceivableAmounts.map((item) => {
      initialReceiveableFee += parseInt(item.amount_after_concession);
    });
    setReceivableFee(initialReceiveableFee);

    // Update the state
    setReceivableAmounts(updatedReceivableAmounts);
    console.log(updatedReceivableAmounts, "Recviable Amount");
    console.log(initialReceiveableFee, "Net Fee Collect");

    setHostelReceivable(updatedReceivableAmounts.slice(0, 2));
    setLastHostelReceivable(updatedReceivableAmounts.slice(2));

    // // Array containing the first two items
    // const firstTwoItemsArray = updatedReceivableAmounts.slice(0, 2);
    // setReceivableAmounts(firstTwoItemsArray);

    // const lastItem = updatedReceivableAmounts.slice(2)
    // setLastHostelReceivable(lastItem);
  };

  const handleSubmit = () => {
    if (hostel == "") {
      setHostelError(true);
      return;
    }
    if (monthlyFeeInputsvalue == 0) {
      setFeeMonthError(true);
      return;
    }
    setloading(true);

    const data = {
      hostel_id: hostel,
      student_id: singleStudent.id,
      start_date: startingDate,
      fee_months: monthlyFeeInputsvalue,
      fee_amounts: hostelReceivable,
      current_fee_amount: lastHostelReceivable,
    };

    console.log(data);
    fetch(`${baseUrl}/assign-hostel/student`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        resp.json().then((data) => {
          setloading(false);
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success(data.payload);
              setHostel("");
              setHostelFee("");
              setStartingDate("");
              setmonthlyFeeInputsvalue([]);
              setHostelReceivable([]);
              setLastHostelReceivable([]);
              setTotalFee(0);
              setReceivableFee(0);
              onHide(false);
            } else {
              setHostel("");
              setHostelFee("");
              setStartingDate("");
              setmonthlyFeeInputsvalue([]);
              setHostelReceivable([]);
              setLastHostelReceivable([]);
              setTotalFee(0);
              setReceivableFee(0);
              onHide(false);
              alert.error(data.metadata.message);
            }
          }
        });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  return (
    <>
      <Modal show={show} size="lg">
        <Modal.Header>
          <h4>Admit {singleStudent.name}</h4>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <div className="form-group row">
            <div className="col-lg-4">
              <label>Hostel </label>
              <CustomSelect
                className="input"
                onChange={(evt) => {
                  hostalChange(evt.value);
                }}
                placeholder="Select Hostel"
                options={hosteloptions}
              />
              {hostelError ? (
                <lable className="text-danger">Hostel is required</lable>
              ) : null}
            </div>

            <div className="col-md-4">
              <label>Select Start Date</label>
              <input
                value={startingDate}
                className="form-control"
                onChange={(e) => {
                  setStartingDate(e.target.value);
                  getHostelFeeStructure(singleStudent, e.target.value);
                }}
                style={{ height: 40 }}
                type="date"
              />
            </div>

            <div className="col-md-4">
              <label>Fee Months</label>
              <RangePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                style={{ height: "40px" }}
                picker="month"
                onChange={(day, date) => handleChanger(day, date)}
              />
              {feeMonthError ? (
                <lable className="text-danger">Fee Month is required</lable>
              ) : null}
            </div>
          </div>

          {startingDate !== "" ? (
            <>
              <div className="generate text-center">Hostel Fee Details</div>
              <br />

              <div className="row">
                <div className="col-md-4 text-center">
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Fee Name
                  </label>
                </div>
                <div className="col-md-2 text-center">
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Amount
                  </label>
                </div>
                <div className="form-group col-md-3 flex-column d-flex text-center">
                  <label
                    className="form-control-label"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Concession
                  </label>
                </div>
                <div className="form-group col-md-3 flex-column d-flex text-center">
                  <label
                    className="form-control-label"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Receiving Amount
                  </label>
                </div>
              </div>

              {/* All Fee Details with Input Field */}
              {hostelFee &&
                hostelFee.map((feeOnStart, index) => (
                  <div className="row" key={feeOnStart.fee_type_id}>
                    <div className="col-md-4">
                      <select
                        className="form-select form-control"
                        name="fee_type"
                        style={{
                          backgroundColor: "white",
                        }}
                        aria-label="Default select example"
                        value={feeOnStart.fee_type_id}
                        disabled
                      >
                        <option value={feeOnStart.fee_type_id}>
                          {feeOnStart.fee_name}
                        </option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="number"
                        value={feeOnStart.net_amount}
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-3 flex-column d-flex">
                      <span
                        style={{
                          marginTop: "15px",
                        }}
                        disabled
                      >
                        Concession Applied{" "}
                        {feeOnStart?.concession_allowed != null
                          ? feeOnStart?.concession_allowed
                          : 0}
                      </span>
                    </div>
                    <div className="form-group col-md-3 flex-column d-flex">
                      <input
                        className="mt-3"
                        min={1}
                        value={
                          receivableAmounts[index]?.amount_after_concession || 0
                        }
                        type="number"
                        onChange={(e) => handleAmountChange(index, e)}
                      />
                    </div>
                  </div>
                ))}

              {/* Net Fee & Receiving Fee */}
              <div className="row">
                <div className="col-md-6 d-flex">
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Net Fee:-
                  </p>
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      marginLeft: "30px",
                    }}
                  >
                    {parseInt(totalFee)}
                  </p>
                </div>

                <div className="col-md-6 d-flex">
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Receiving Fee:-
                  </p>
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      marginLeft: "30px",
                    }}
                  >
                    {parseInt(receivableFee)}
                  </p>
                </div>
              </div>
            </>
          ) : null}

          {/* <div className="row">
            <div className="col-lg-4">
              <label>Concession </label>

              <CustomSelect
                required
                className="input"
                onChange={(evt) => {
                  handleConcession(evt);
                }}
                placeholder="Select Concession"
                options={concessionoption}
              />
            </div>
            <div className="col-md-4 mt-1">
              <label>Hostel Admission Fee</label>
              <input
                min={1}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setholAdmAfterConAmount(e.target.value);
                  }
                }}
                value={holAdmAfterConAmount}
                type="number"
              />
            </div>
          </div>

          <div className="row mt-11">
            <div className="col-md-3">
              <select
                class="form-select form-control"
                name="fee_type"
                style={{
                  backgroundColor: "white",
                }}
                aria-label="Default select example"
              >
                <option selected value="4">
                  HOSTEL FEE
                </option>
              </select>
            </div>
            <div className="col-md-3">
              <input
                onChange={(e) => setHostelFeeAmount(e.target.value)}
                className="form-control"
                type="number"
                value={hostelFeeAmount}
              />
            </div>
            <div className="form-group col-md-3 flex-column d-flex">
              <span
                style={{
                  marginTop: "15px",
                }}
                disabled
              >
                {" "}
                Concession Allowed is {concessionAmount}
              </span>
            </div>

            <div className="form-group col-md-3 flex-column d-flex">
              <input
                className="mt-3"
                min={1}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setholAfterConAmount(e.target.value);
                  }
                }}
                value={holAfterConAmount}
                type="number"
              />
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={error}
            onClick={handleSubmit}
            className="btn btn-primary btn-elevate"
          >
            Submit
          </button>

          <button
            type="button"
            onClick={cancelBtnHandle}
            className="btn btn-danger btn-elevate"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdmitModal;
