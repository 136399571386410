import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Content } from "./Content";
import { useSelector } from "react-redux";

export function ContentRoute({ children, component, render, ...props }) {
  const user = useSelector((state) => state.user.user);

  useEffect(() => {}, []);

  return (
    <Route {...props}>
      {(routeProps) => {
        if (props.role) {
          if (Array.isArray(props.role)) {
            console.log(props.role);

            if (props.role?.includes(user?.roles[0]?.name) == false) {
              return (
                <Redirect
                  to={{
                    pathname: "/error/error-v1",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          } else if (props.role) {
            if (props.role != user?.roles[0]?.name) {
              return (
                <Redirect
                  to={{
                    pathname: "/error/error-v1",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          }

          if (typeof children === "function") {
            return <Content>{children(routeProps)}</Content>;
          }

          if (!routeProps.match) {
            return null;
          }

          if (children) {
            return <Content>{children}</Content>;
          }

          if (component) {
            return (
              <Content>{React.createElement(component, routeProps)}</Content>
            );
          }

          if (render) {
            return <Content>{render(routeProps)}</Content>;
          }

          return null;
        }
      }}
    </Route>
  );
}
