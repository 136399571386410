// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { Spinner } from "react-bootstrap";
import { PremissionChecker } from "../../../../../../../services/PremissionChecker";

export function RolesFormatter(
  cellContent,
  row,
  rowIndex,
  { attachPermissions, openEditCustomerDialog, openDeleteCustomerDialog,user }
) {
  //console.log('ye row', row)

  return (
    <>
      {PremissionChecker(user,"role.permission_list") ? (
        <button
          title="Attach Permissions"
          className="btn btn-primary"
          // disabled={row.isLoading}
          onClick={() => attachPermissions(row, rowIndex)}
        >
          Permissions
        </button>
      ) : null}
      {PremissionChecker(user,"role.update") ? (
        <a
          title="Edit customer"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditCustomerDialog(row)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
            title="Update Role"
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      ) : null}

      {PremissionChecker(user,"role.delete") ? (
        <a
          title="Delete customer"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => openDeleteCustomerDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG title="Delete Role" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      ) : null}
    </>
  );
}
