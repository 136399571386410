import { Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { Pagination } from "../../../../_metronic/_partials/controls";
import * as uiHelpers from "../../../modules/ECommerce/pages/customers/CustomersUIHelpers";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import StaffLoandPrint from "./StaffLoandPrint";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";

const StaffLoan = () => {
  let authtoken = localStorage.getItem("userToken");
  const [sessionData, setSessionData] = useState([]);
  const [selected, setSelected] = useState("Overall");
  const [yearId, setYearId] = useState("");
  const [employeeCode, setEmployee] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setloading] = useState(false);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [sessionYear, setSessionYear] = useState("");
  const alert = useAlert();

  useEffect(() => {
    getAllSessions();
    getSettingData();
  }, []);

  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  const columns = [
    {
      dataField: "emp_code",
      text: "Code",
    },
    {
      dataField: "full_name",
      text: "Name",
    },
    {
      dataField: "pay_scale_id",
      text: "Scale",
    },
    {
      dataField: "designation.name",
      text: "Designation ",
    },
    {
      dataField: "account_no",
      text: "Account No ",
    },
    {
      dataField: "joining_date",
      text: "Joining  Date ",
    },
    {
      dataField: "qualification",
      text: "QualificationS ",
    },
    {
      dataField: "loans[0].monthly_loan_installment",
      text: "Loan Installment",
    },
    {
      dataField: "remaining_amount",
      text: "Remaining Loan",
    },
  ];
  const options = {
    custom: true,
    totalSize: reportData.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  const updatingQueryParams = (page) => {
    console.log(page);
    console.log("this is chus2");
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
            handleSessionChange(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;

    setSelected(e.target.value);
    if (value == "Overall") {
    }
    if (value == "Session") {
    }
  };

  const handleSessionChange = (id) => {
    if (yearId == "") {
      alert.error("Please Select Session");
      return;
    }
    setloading(true);
    const session = sessionData.find((item) => {
      return item.id == yearId;
    });

    setSessionYear(session.year);
    const data = {
      year_id: yearId,
    };
    fetch(`${baseUrl}/staff-loan-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        // setLoading(false);
        resp.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              setloading(false);
              setIsVisible(true);
              console.log("data", data.payload);
              setReportData(data.payload);
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* <div className="row">
          <div className="col-sm-3">
            <input
              checked={selected == "Overall"}
              onChange={(e) => onRadioButtonClick(e)}
              defaultChecked
              type="radio"
              id="Overall"
              value="Overall"
            />
            <label for="Overall">Over All</label>
          </div>

          <div className="col-sm-3">
            <input
              checked={selected == "Session"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="Session"
              value="Session"
            />
            <label for="Session">Session Wise</label>
          </div>
        </div> */}
        <div className="d-flex">
          <div className="col-sm-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option selected value="">
                  Select Session
                </option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-sm-1 mt-9">
            <button
              onClick={handleSessionChange}
              className="btn btn-primary me-2"
              id="myBtn"
            >
              Show
            </button>
          </div>
          {reportData.length > 0 ? (
            <div className="col-sm-3 mt-9 mb-5">
              <ReactToPrint
                documentTitle={`Staff Loan Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          ) : null}
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        {/* <div className="row">
          <div className="col-md-4">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => handleSessionChange(e.target.value)}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          {reportData.length > 0 ? (
            <div className="col-md-3 mt-9">
              <ReactToPrint
                documentTitle={`Staff Loan Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          ) : null} */}
        {/* <div className="col-md-4 mt-9">
            <input
              className="form-control"
              placeholder="Enter Employee Code"
              onChange={(e) => setEmployee(e.target.value)}
            />
          </div> */}
        {/* <div className="col-md-2 mt-9">
            <button className="btn btn-primary" onClick={handleclick}>
              Search
            </button>
          </div> */}
        {/* </div> */}
        {isVisible ? (
          <>
            <div className="row p-3 mt-4">
              <img style={{ height: 100, width: 110 }} src={icon} />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h5>List of Staff Loan From {sessionYear}</h5>
              </div>
            </div>
            <div className="mt-5">
              <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <>
                      <Pagination
                        updatingQueryParams={updatingQueryParams}
                        onPaginationSelectChange={onPaginationSelectChange}
                        isLoading={loading}
                        paginationProps={paginationProps}
                      >
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center overflow-hidden"
                          bootstrap4
                          keyField="id"
                          data={reportData}
                          columns={columns}
                          defaultSorted={uiHelpers.defaultSorted}
                          {...paginationTableProps}
                        ></BootstrapTable>
                      </Pagination>
                      {/* )} */}
                    </>
                  );
                }}
              </PaginationProvider>
            </div>
          </>
        ) : null}
      </Paper>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        {reportData.length > 0 ? (
          <StaffLoandPrint
            reportData={reportData}
            unitName={unitName}
            sessionYear={sessionYear}
            icon={icon}
          />
        ) : null}
      </div>
    </>
  );
};

export default StaffLoan;
