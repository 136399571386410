import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Alert } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";

import { useAlert } from "react-alert";
import EditStudent from "../ShowAllStudents/EditStudent/EditStudent";
import usePagination from "../../../components/usePagination/usePagination";
function NotActiveStudent() {
  const authtoken = localStorage.getItem("userToken");

  const alert = useAlert();
  const [students, setstudents] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [sessions, setallsessions] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [currentId, setcurrentId] = useState("");
  const [loading, setloading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);

  const [selectedcampus, setselectedcampus] = useState("");
  const [selectedyear, setselectedyear] = useState("");
  const [selectedsession, setselectedsession] = useState("");
  const [selectedStatus, setselectedStatus] = useState("");

  const [selectedStudent, setselectedStudent] = useState({});
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [stuckoffmodal, setstuckoffmodal] = useState(false);
  const [loginmodal, setloginmodal] = useState(false);

  const [loginpassword, setloginpassword] = useState("");
  const [loginemail, setloginemail] = useState("");
  const [isloginemail, setisloginemail] = useState(false);
  //pagination states

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  const [stuckoffloading, setstuckoffloading] = useState(false);

  const [loginemailloader, setloginemailloader] = useState(false);

  const history = useHistory();

  let [selectedindex, setselectedindex] = useState();

  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const STUD_STATUS = [
    {
      id: 1,
      title: "Registered Only",
    },
    {
      id: 2,
      title: "Admission Only",
    },
    {
      id: 3,
      title: "Applied For Admission",
    },
    {
      id: 4,
      title: "Struck Off",
    },
    {
      id: 5,
      title: "Passout",
    },
    {
      id: 6,
      title: "Leaving",
    },
    {
      id: 7,
      title: "Migrate",
    },
  ];
  // useEffect(() => {
  //     fetch('/student', {
  //         method: 'GET',
  //         headers: {
  //             'Authorization': `Bearer ${authtoken}`
  //         }
  //     }).then((res) => res.json())
  //         .then((data) => {
  //             console.log(data)
  //             // setstudents(data.payload)
  //         })
  //         .catch((err) => {
  //             console.log(err)
  //         })
  // }, [])

  useEffect(() => {
    // getAllStudents()

    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      getAllStudents("", campusidget);
    }
    // getAllCampus();
    getAllSession();
  }, []);

  const getAllStudents = async (e, value) => {
    console.log(e, "e");
    console.log(value, "value");

    let id;
    let data;
    if (e) {
      id = e.target.value;
      setselectedcampus(id);
    } else {
      id = value;
    }
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
    setpreviousselectedcampus(id);
    data = {
      campus_id: id,
      status: 2,
    };

    // console.log(data)
    // await fetch(`${baseUrl}/student_filter_list`, {
    //     method: "POST",
    //     headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${authtoken}`,
    //     },
    //     body: JSON.stringify(data),
    // })
    //     .then((res) =>
    //         res.json().then((data) => {
    //             // console.log(data);
    //             setstudents(data.payload);
    //             setloading(false);
    //             // dispatch({
    //             //   type: 'GET_ALL_STUDENT',
    //             //   payload: data.payload
    //             // })
    //         })
    //     )

    //     .catch((err) => {
    //         setloading(false);
    //         console.log(err);
    //     });
  };

  const getAllStudents2 = async () => {
    setloading(true);

    const data = {
      campus_id: selectedcampus,
      status: selectedStatus,
      year_id: selectedsession,
    };

    await fetch(`${baseUrl}/student_filter_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          // console.log(data);
          setstudents(data.payload);

          if (data.metadata) {
            if (data.metadata.success) {
              let res = data.payload.sort(
                (a, b) => a.admission_id - b.admission_id
              );
              let temparr = res;
              res.map((item, index) => {
                item["SrNo"] = index + 1;
                temparr[index] = item;
              });

              setstudents(temparr);
            }
          }

          setloading(false);
          // dispatch({
          //   type: 'GET_ALL_STUDENT',
          //   payload: data.payload
          // })
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const count = Math.ceil(students.length / PER_PAGE);
  const _DATA = usePagination(students, PER_PAGE);
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const getAllSession = async () => {
    await fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setselectedsession(session.id);
          }
        });
        setallsessions(data.payload);
      })
      .catch((err) => {
        console.log("sesstion", err);
      });
  };

  const gotoviewpage = (student) => {
    const id = student.id;
    // console.log(student)

    history.push({ pathname: `/viewstudent/${id}`, state: "showallstudents" });
  };

  const stuckOffFun = (student) => {
    setstuckoffmodal(true);
    setselectedStudent(student);
  };

  //@_pagination

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  const openEditDialog = (student) => {
    console.log(student);
    setselectedStudent(student);
    seteditModal(true);
  };

  const deleteDialog = (id) => {
    setdeleteModal(true);
    setcurrentId(id);
  };

  const deleteHandler = () => {
    fetch(`${baseUrl}/student/${currentId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }).then((res) => {
      res
        .json()
        .then((data) => {
          setdeleteModal(false);

          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Student Deleted success");
            } else {
              alert.error("Error Occured");
            }
          } else {
            alert.error("Error Occured");
          }
        })
        .catch((err) => {
          setdeleteModal(false);
          alert.error("Error Occured");
          console.log("err", err);
        });
    });
  };

  const stuckOffHandler = () => {
    setstuckoffloading(true);

    console.log(selectedStudent);
    let data = {
      student_id: selectedStudent.id,
    };
    fetch(`${baseUrl}/student_struct_off`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res
        .json()
        .then((data) => {
          setstuckoffloading(false);
          // previousselectedcampus
          setstuckoffmodal(false);
          if (data.metadata) {
            if (data.metadata.success) {
              getAllStudents("", previousselectedcampus);
              alert.success("Student Stuck Off Successfully");
            } else {
              alert.error("Error Occured");
            }
          } else {
            alert.error("Error Occured");
          }
        })
        .catch((err) => {
          setstuckoffloading(false);
          setstuckoffmodal(false);
          console.log("err", err);
          alert.error("Error Occured");
        });
    });
  };

  const loginHandler = () => {
    if (loginpassword == "" || loginpassword.length < 6) {
      setisloginemail(true);
      return;
    }
    setisloginemail(false);
    let data = {
      password: loginpassword,
      student_id: selectedStudent.id,
    };
    console.log(data);
    setloginemailloader(true);
    let url = "";
    if (selectedStudent.user_id == null) {
      url = "student_signup";
    } else {
      url = "student_auth_update";
    }

    fetch(`${baseUrl}/${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res
        .json()
        .then((data) => {
          console.log(data);
          setloginemailloader(false);

          if (data.metadata) {
            if (data.metadata.success) {
              if (selectedStudent.user_id == null) {
                alert.success("Password Created Successfully");
                getAllStudents("", previousselectedcampus);
              } else {
                alert.success("Password Updated Successfully");
              }
            } else {
              alert.error("Error Occured");
            }
          } else {
            alert.error("Error Occured");
          }
          setisloginemail(false);
          setloginemail("");
          setloginpassword("");
          setloginmodal(false);
        })
        .catch((err) => {
          setloginemailloader(false);

          console.log("err", err);
          alert.error("Error Occured");
          setisloginemail(false);
          setloginemail("");
          setloginpassword("");
          setloginmodal(false);
        });
    });
  };

  const handleChange2 = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  var i = 0;
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="session_id"
              value={selectedsession}
              onChange={(e) => setselectedsession(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Session
              </option>
              {sessions.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              value={selectedcampus}
              onChange={(e) => setselectedcampus(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Status</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              value={selectedStatus}
              onChange={(e) => setselectedStatus(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Status
              </option>
              {STUD_STATUS.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.title}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <button className="btn btn-primary mt-9" onClick={getAllStudents2}>
              Show
            </button>
          </div>
        </div>

        {/* stu
            +dent table  */}
        <Table responsive>
          <thead>
            <tr id="tbl">
              <th className="text-center">Sr. No</th>
              <th className="text-center">Reg No</th>
              <th>Student Name</th>
              <th>Father Name</th>
              <th className="text-center">Admission ID</th>
              {/* <th>CNIC/ B FORM</th> */}

              <th>Edit</th>
              {/* <th>Delete</th> */}
              {/* <th>SignUp/Reset</th> */}
              <th>View</th>
              {/* <th>Stuck Off</th> */}
            </tr>
          </thead>

          <tbody>
            {_DATA.currentData()?.map((student, index) => {
              return (
                // key={index}
                <tr key={index}>
                  <td className="text-center">{student.SrNo}</td>
                  <td className="text-center">{student.id}</td>

                  <td
                    title={student.name.length > 15 ? student.name : null}
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {student.name.length > 22
                      ? student.name.substring(0, 12) + "..."
                      : student.name}
                  </td>
                  <td
                    title={
                      student.father_name.length > 15
                        ? student.father_name
                        : null
                    }
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {student.father_name.length > 22
                      ? student.father_name.substring(0, 12) + "..."
                      : student.father_name}
                  </td>

                  <td className="text-center">{student.admission_id}</td>

                  <td
                    style={{ paddingLeft: "0px" }}
                    className="text_align_start"
                  >
                    <a
                      key={index}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={(e) => {
                        openEditDialog(student);
                      }}
                    >
                      <span
                        key={index}
                        className="svg-icon svg-icon-md svg-icon-primary"
                      >
                        <SVG
                          key={index}
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>

                  {/* <td className="text_align_start">
                                        <a
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm "
                                            // disabled={student.user_id == null ? false : true}
                                            onClick={
                                                // student.user_id == null ?
                                                () => {
                                                    setselectedStudent(student)
                                                    setloginemail(student.admission_id + "@arqam.edu.pk")
                                                    setloginmodal(true)
                                                }
                                                //   : null
                                            }

                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <SVG style={{ margin: "10px" }} className="logouticone"
                                                    src={student.user_id == null ? toAbsoluteUrl("/media/svg/icons/Navigation/Sign-in.svg") : toAbsoluteUrl("/media/svg/icons/Navigation/Exchange.svg")}
                                                />
                                            </span>
                                        </a>
                                    </td> */}

                  <td className="text_align_start">
                    <a
                      style={{}}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => gotoviewpage(student)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Display2.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>

                  {/* <td className="text_align_start">
                                        <a
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm "
                                            onClick={() => stuckOffFun(student)}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Code/Error-circle.svg"
                                                    )}
                                                />
                                            </span>
                                        </a>
                                    </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Pagination
          color="primary"
          count={count}
          size="medium"
          page={page}
          variant="text"
          shape="round"
          onChange={handleChange2}
        />

        <Modal size="xl" show={editModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Edit Student
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditStudent
              show={() => seteditModal()}
              reload={() => getAllStudents()}
              onUpdateStudent={getAllStudents}
              studentDetail={selectedStudent}
            />
          </Modal.Body>
        </Modal>
        {/* setstuckoffmodal(true)
        setselectedStudent(student) */}

        <Modal show={stuckoffmodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to Stuck Off Student ?
            </Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>
                    {
                        deleteLoading ? <b style={{ color: 'green' }}>Student deleting Please wait <span className="spinner-border"></span></b>
                            : <>           Are you want to delete Student?</>
                    }

                </Modal.Body> */}
          <Modal.Footer>
            {stuckoffloading ? (
              <b>
                <span className="spinner-border"></span>
              </b>
            ) : (
              <>
                <button
                  onClick={stuckOffHandler}
                  className="btn btn-success button_blue "
                >
                  Stuck Off
                </button>
                <button
                  className="btn btn-danger btn_width"
                  onClick={() => setstuckoffmodal(false)}
                >
                  Cancel
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={loginmodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            ></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-between text-left">
              <div className="form-group row">
                <div className="form-group col-sm-10 flex-column d-flex">
                  <label>
                    Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    onChange={(e) => {
                      // console.log(e.target.value)
                      // console.log(selectedStudent)
                      setloginpassword(e.target.value);
                      if (e.target.value == "") {
                        setisloginemail(true);
                      } else {
                        setisloginemail(false);
                      }
                    }}
                    placeholder="Password"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group col-sm-10 flex-column d-flex">
                  <label>Email</label>
                  <input
                    disabled
                    defaultValue={loginemail}
                    placeholder="Email"
                    type="text"
                    className="form-control"
                  />
                </div>
                {/* <div className="col-lg-10">
                <label>
                  Password{" "}
                  <span style={{ color: "red" }}>*</span>

                </label>
                <input type="text" placeholder="Password" />
              </div> */}
                {/* <div className="col-lg-10">
                <label>
                  Email{" "}


                </label>
                <input type="text" placeholder="Email" disabled />
              </div> */}
              </div>
            </div>
            {isloginemail ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisloginemail(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>Please Enter 6 digit Password</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>

          <Modal.Footer>
            {loginemailloader ? (
              <b>
                <span className="spinner-border"></span>
              </b>
            ) : (
              <>
                <button
                  onClick={loginHandler}
                  className="btn btn-success button_blue "
                >
                  {selectedStudent.user_id == null ? "Login" : "Reset"}
                </button>
                <button
                  className="btn btn-danger btn_width"
                  onClick={() => {
                    setisloginemail(false);
                    setloginemail("");
                    setloginpassword("");
                    setloginmodal(false);
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete Student ?
            </Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>
                    {
                        deleteLoading ? <b style={{ color: 'green' }}>Student deleting Please wait <span className="spinner-border"></span></b>
                            : <>           Are you want to delete Student?</>
                    }

                </Modal.Body> */}
          <Modal.Footer>
            <button
              onClick={deleteHandler}
              className="btn btn-success button_blue "
            >
              Delete
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setdeleteModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
}

export default NotActiveStudent;
