import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { Toaster, toast } from 'react-hot-toast';
import { Button, message, Space } from 'antd';
const DeleteModal = ({
    HideModal,
    visible,
    id,
    reload,
    url,
    method,
    msg
}) => {
    let authtoken = localStorage.getItem("userToken");

    const [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    console.log(url)

    const warning = (res) => {
        messageApi.open({
          type: 'warning',
          content: `${res}`,
        });
      };
    const cancelSub = (e) => {
        e.preventDefault()
        setLoading(true)
        fetch(url, {
            method,
            headers: {
                Authorization: `Bearer ${authtoken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                   
               toast.success(data.metadata.message)
                    setLoading(false)
                    HideModal()
                    reload()
                } else if (!data.metadata.success) {
                    setLoading(false)
                    toast.error(data.metadata.message)
                } else {
                    setLoading(false)
                    toast.error('Unknown error :(')
                }
            }).catch(err => {
                setLoading(false)
                console.log(err);
            })
    }



    return (
        <>
        {contextHolder}
        <Toaster/>
        <Modal
            centered
            show={visible}>
            <Modal.Header>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            {
                loading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                        <Spinner animation='border' color='green' />
                    </div>
                    :
                    <>
                        <Modal.Body>
                            {
                                msg ? msg : 'Are you sure you want to Delete?'
                            }

                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={HideModal}
                                className='btn btn-danger'>
                                Close
                            </button>

                            <button
                                onClick={cancelSub}
                                className='btn btn-primary'>
                                Delete
                            </button>
                        </Modal.Footer>
                    </>
            }
        </Modal>
        </>
    )
}

export default DeleteModal