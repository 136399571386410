import React, { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";
import { Modal } from "react-bootstrap";
import { upperCase } from "lodash";

import { baseUrl } from "../../../services/config";

const SingleStudentPrint = ({
  printmodal,
  selectedChalan,
  onHide,
  educationType,
}) => {
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const getMonthName2 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getMonthName3 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };

  const PRINTCOUNT = [1, 2, 3];

  const getFeeAmount = (challans) => {
    let sum = 0;
    challans.forEach((element) => {
      sum += parseInt(element.amount);
    });

    return sum;
    // const sum = challans.reduce((accumulator, object) => {
    //   return accumulator + object.amount;
    // }, 0);
    // console.log(sum)
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Modal show={printmodal} size="lg">
        <div id="voucher">
          <Modal.Body>
            <div className="main__voucher">
              {PRINTCOUNT.map((item, indexs) => (
                <div key={indexs} className="voucher">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <img
                      src={icon}
                      style={{ height: 100, width: 100 }}
                      alt="icon"
                    />
                    <h3 style={{ color: "#B4959D", width: "40%" }}>
                      {unitName}
                    </h3>
                  </div>
                  <div className="redline"></div>
                  <div className="bolsection">
                    <div>
                      <div>Issue Date:</div>
                      <div>Due Date :</div>
                      <div>
                        {" "}
                        {selectedChalan.student.admission_id == null
                          ? "Registration Id:"
                          : "Admission No:"}
                      </div>
                      <div>Name:</div>
                      <div>Class:</div>
                    </div>
                    <div>
                      <div>{getMonthName2(selectedChalan.issue_date)}</div>
                      <div>{getMonthName2(selectedChalan.due_date)}</div>
                      <div>
                        {selectedChalan.student.admission_id == null
                          ? selectedChalan.student.registration_id
                          : selectedChalan.student.admission_id}
                      </div>
                      <div>{selectedChalan.student?.name}</div>
                      <div>{selectedChalan.student?.student_class.name}</div>
                    </div>
                  </div>

                  {selectedChalan.fee_challan_details.map((copy, index) => {
                    return (
                      <div>
                        <div className="bolsection printchallanfeesection">
                          <div style={{ fontSize: "smaller" }}>
                            {copy.fee_type_id == "23"
                              ? "REMAINING BALANCE"
                              : copy.fee_name}
                          </div>
                          <div style={{ fontSize: "smaller" }}>
                            {copy.amount}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="lastsection">
                    <h5>Total Amount: {selectedChalan.payable} </h5>
                  </div>
                  <div>
                    <Barcode value={selectedChalan.challan_no} />,
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </div>

        <Modal.Footer>
          {/* <button
                onClick={printdoc}
                id="printButton"
                className="btn btn-success button_blue button_blue"
              >
                Print
              </button> */}
          <ReactToPrint
            documentTitle="Challan Copy"
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint={true}
            trigger={() => (
              <button
                disabled={loading ? true : false}
                className="btn btn-success button_blue button_blue"
              >
                {loading ? "Printing..." : "Print"}
              </button>
            )}
            content={() => componentRef.current}
          />
          <button
            id="cancelprintButton"
            onClick={() => onHide()}
            className="btn btn-danger btn_width"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* /////orignal print */}
      <div style={{ visibility: "hidden" }}>
        {printmodal ? (
          <>
            <div
              ref={componentRef}
              id="printSection"
              style={{ visibility: "hidden" }}
            >
              <>
                <div className="mainPrintDiv">
                  <div className="secondmain">
                    <div className="myprintBox1">
                      <div className="text-center">
                        {selectedChalan?.student.campus?.print_account_no &&
                          selectedChalan?.student.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}

                        <b className="mytextfont">
                          {selectedChalan?.student.campus.name}
                        </b>
                        <br></br>
                      </div>

                      <div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Due Date:
                            </b>
                          </div>
                          <div className="mytextfont printseconcontent">
                            <p>{getMonthName2(selectedChalan?.due_date)}</p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Issue Date:
                            </b>
                          </div>
                          <div className="mytextfont printseconcontent">
                            <p>{getMonthName2(selectedChalan?.issue_date)}</p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Admission:
                            </b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              <b>
                                {selectedChalan?.student.admission_id == null
                                  ? selectedChalan?.student.registration_id
                                  : selectedChalan?.student.admission_id}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">Name:</b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              {selectedChalan?.student.name}
                              <br></br>
                              {selectedChalan?.student.gender?.toLowerCase() ==
                              "male"
                                ? "s/o"
                                : "d/o"}{" "}
                              {selectedChalan?.student.father_name}
                            </p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">Class:</b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              {selectedChalan?.student.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedChalan?.student.student_class.name +
                                " (" +
                                selectedChalan?.student?.global_section?.name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="endContent2">
                        <div className="endconheading2">
                          <div>
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description.</b>
                          </div>
                          <div>
                            <b>Amount.</b>
                          </div>
                        </div>
                        {selectedChalan.fee_challan_details.map(
                          (copy, index) => (
                            <>
                              <div className="endcontentMain2">
                                <div>
                                  <p>{copy.fee_challan.challan_no}</p>
                                </div>

                                <div>
                                  <p>
                                    {copy.fee_type_id == "23"
                                      ? "REMAINING BALANCE"
                                      : copy.fee_name}{" "}
                                    {getMonthName3(copy?.fee_month)}
                                  </p>
                                </div>
                                <div>
                                  <p>{copy.amount}</p>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>

                      <div
                        className="my__total__bottom4"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <b className="givingFont">Total Amount</b>
                        <p
                          className="btn btn-white givingFont totalFontSizer"
                          style={{
                            border: "1px solid black",
                            marginLeft: "100px",
                          }}
                        >
                          {getFeeAmount(selectedChalan?.fee_challan_details)}
                        </p>
                      </div>
                    </div>

                    <div className="myprintBox2">
                      <div className="text-center">
                        {selectedChalan?.student.campus?.print_account_no &&
                          selectedChalan?.student.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}

                        <b className="mytextfont">
                          {selectedChalan?.student.campus.name}
                        </b>
                        <br></br>
                      </div>

                      <div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Due Date:
                            </b>
                          </div>
                          <div className="mytextfont printseconcontent">
                            <p>{getMonthName2(selectedChalan?.due_date)}</p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Issue Date:
                            </b>
                          </div>
                          <div className="mytextfont printseconcontent">
                            <p>{getMonthName2(selectedChalan?.issue_date)}</p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Admission:
                            </b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              <b>
                                {selectedChalan?.student.admission_id == null
                                  ? selectedChalan?.student.registration_id
                                  : selectedChalan?.student.admission_id}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">Name:</b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              {selectedChalan?.student.name}
                              <br></br>
                              {selectedChalan?.student.gender?.toLowerCase() ==
                              "male"
                                ? "s/o"
                                : "d/o"}{" "}
                              {selectedChalan?.student.father_name}
                            </p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">Class:</b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              {selectedChalan?.student.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedChalan?.student.student_class.name +
                                " (" +
                                selectedChalan?.student?.global_section?.name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="endContent2">
                        <div className="endconheading2">
                          <div>
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description.</b>
                          </div>
                          <div>
                            <b>Amount.</b>
                          </div>
                        </div>
                        {selectedChalan.fee_challan_details.map(
                          (copy, index) => (
                            <>
                              <div className="endcontentMain2">
                                <div>
                                  <p>{copy.fee_challan.challan_no}</p>
                                </div>

                                <div>
                                  <p>
                                    {copy.fee_type_id == "23"
                                      ? "REMAINING BALANCE"
                                      : copy.fee_name}{" "}
                                    {getMonthName3(copy?.fee_month)}
                                  </p>
                                </div>
                                <div>
                                  <p>{copy.amount}</p>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>

                      <div
                        className="my__total__bottom4"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <b className="givingFont">Total Amount</b>
                        <p
                          className="btn btn-white givingFont totalFontSizer"
                          style={{
                            border: "1px solid black",
                            marginLeft: "100px",
                          }}
                        >
                          {getFeeAmount(selectedChalan?.fee_challan_details)}
                        </p>
                      </div>
                    </div>
                    <div className="myprintBox3">
                      <div className="text-center">
                        {selectedChalan?.student.campus?.print_account_no &&
                          selectedChalan?.student.campus?.print_account_no.map(
                            (acc) => (
                              <>
                                <b className="mytextfont">{`${acc.bank_name}/${acc.account_number}`}</b>
                                <br />
                              </>
                            )
                          )}

                        <b className="mytextfont">
                          {selectedChalan?.student.campus.name}
                        </b>
                        <br></br>
                      </div>

                      <div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Due Date:
                            </b>
                          </div>
                          <div className="mytextfont printseconcontent">
                            <p>{getMonthName2(selectedChalan?.due_date)}</p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Issue Date:
                            </b>
                          </div>
                          <div className="mytextfont printseconcontent">
                            <p>{getMonthName2(selectedChalan?.issue_date)}</p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">
                              Admission:
                            </b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              <b>
                                {selectedChalan?.student.admission_id == null
                                  ? selectedChalan?.student.registration_id
                                  : selectedChalan?.student.admission_id}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">Name:</b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              {selectedChalan?.student.name}
                              <br></br>
                              {selectedChalan?.student.gender?.toLowerCase() ==
                              "male"
                                ? "s/o"
                                : "d/o"}{" "}
                              {selectedChalan?.student.father_name}
                            </p>
                          </div>
                        </div>
                        <div className="printsecondpartcontainer printcontainerheight">
                          <div style={{ width: "170px", paddingLeft: "30px" }}>
                            <b className="mytextfont printsecontpart">Class:</b>
                          </div>
                          <div
                            className="mytextfont printseconcontent"
                            style={{ textAlign: "start" }}
                          >
                            <p>
                              {selectedChalan?.student.education_type == 2
                                ? "Hifz-"
                                : ""}
                              {selectedChalan?.student.student_class.name +
                                " (" +
                                selectedChalan?.student?.global_section?.name +
                                ")"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="endContent2">
                        <div className="endconheading2">
                          <div>
                            <b>Inv No.</b>
                          </div>
                          <div>
                            <b>Description.</b>
                          </div>
                          <div>
                            <b>Amount.</b>
                          </div>
                        </div>
                        {selectedChalan.fee_challan_details.map(
                          (copy, index) => (
                            <>
                              <div className="endcontentMain2">
                                <div>
                                  <p>{copy.fee_challan.challan_no}</p>
                                </div>

                                <div>
                                  <p>
                                    {copy.fee_type_id == "23"
                                      ? "REMAINING BALANCE"
                                      : copy.fee_name}{" "}
                                    {getMonthName3(copy?.fee_month)}
                                  </p>
                                </div>
                                <div>
                                  <p>{copy.amount}</p>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>

                      <div
                        className="my__total__bottom4"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <b className="givingFont">Total Amount</b>
                        <p
                          className="btn btn-white givingFont totalFontSizer"
                          style={{
                            border: "1px solid black",
                            marginLeft: "100px",
                          }}
                        >
                          {getFeeAmount(selectedChalan?.fee_challan_details)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
            {/* <div ref={componentRef} id="printSection">
              <div className="mainDiv">
                <div className="box box1">
                  <div className="top3lines">
                    {selectedChalan?.student?.campus?.print_account_no &&
                      selectedChalan.student?.campus?.print_account_no.map(
                        (acc) => (
                          <>
                            <b>{`${acc.bank_name}/${acc.account_number}`}</b>
                            <br />
                          </>
                        )
                      )}
   

                    <b>{selectedChalan.student?.campus?.name}</b>
                    <br></br>
                  </div>
                  
                  <div>
                    <div>
                      <div className="middlecontent">
                        <span>
                          <b>Due Date:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>{getMonthName2(selectedChalan?.due_date)}</p>
                        </span>
                      </div>

              
                      <div className="middlecontent">
                        <span>
                          <b>Issue Date:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>{getMonthName2(selectedChalan?.issue_date)}</p>
                        </span>
                      </div>

                      <div className="middlecontent">
                        <span>
                          <b>Admission:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>
                            {" "}
                            {selectedChalan.student.admission_id == null
                              ? selectedChalan.student.registration_id
                              : selectedChalan.student.admission_id}
                          </p>
                        </span>
                      </div>
                  
                      <div className="middlecontent" style={{ height: "37px" }}>
                        <span>
                          <b>Name:</b>
                        </span>
                        <span
                          className="secondColoumn"
                          style={{ width: "470px" }}
                        >
                          <p>{selectedChalan.student?.name}</p>
                        </span>
                        <br></br>
                        <span className="secondColoumn">
                          <p style={{ height: "200px" }}>
                            {" "}
                            {selectedChalan.student.gender?.toLowerCase() ==
                            "male"
                              ? "s/o"
                              : "d/o"}{" "}
                            {selectedChalan.student?.father_name}
                          </p>
                        </span>
                      </div>
                      <br></br>
                      <br></br>
                      {selectedChalan.student?.father_name?.length > 15 ? (
                        <>
                          <br></br>
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}
                

                    
                      <div className="middlecontent">
                        <span>
                          <b>Class:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>
                            {selectedChalan.student?.student_class.name +
                              " (" +
                              selectedChalan?.student?.global_section?.name +
                              ")"}
                          </p>
                        </span>
                      </div>
                    </div>
               
                    <div className="endContent">
                      <div className="endconheading">
                        <div>
                          <b>Inv No.</b>
                        </div>
                        <div>
                          <b>Description.</b>
                        </div>
                        <div>
                          <b>Amount.</b>
                        </div>
                      </div>
         
                      {selectedChalan.fee_challan_details.map(
                        (copy, index) => (
                          <>
                            <div className="endcontentMain">
                              <div>
                                <p>{copy.fee_challan.challan_no}</p>
                              </div>

                              <div>
                                <p>
                                  {copy.fee_type_id == "23"
                                    ? "REMAINING BALANCE"
                                    : copy.fee_name}{" "}
                                  {getMonthName3(copy?.fee_month)}
                                </p>
                              </div>
                              <div>
                                <p>{copy.amount}</p>
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                    <div className="bottomContent">
                      <div>
                        <b>Total Amount </b>
                      </div>
                      <div>
                        <p>
                          {getFeeAmount(selectedChalan?.fee_challan_details)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box box2">
                  <div className="top3lines">
                    {selectedChalan?.student?.campus?.print_account_no &&
                      selectedChalan.student?.campus?.print_account_no.map(
                        (acc) => (
                          <>
                            <b>{`${acc.bank_name}/${acc.account_number}`}</b>
                            <br />
                          </>
                        )
                      )}


                    <b>{selectedChalan.student?.campus?.name}</b>
                    <br></br>
                  </div>

                  <div>
                    <div>
                      <div className="middlecontent">
                        <span>
                          <b>Due Date:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>{getMonthName2(selectedChalan?.due_date)}</p>
                        </span>
                      </div>

   
                      <div className="middlecontent">
                        <span>
                          <b>Issue Date:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>{getMonthName2(selectedChalan?.issue_date)}</p>
                        </span>
                      </div>

                      <div className="middlecontent">
                        <span>
                          <b>Admission:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>
                            {" "}
                            {selectedChalan.student.admission_id == null
                              ? selectedChalan.student.registration_id
                              : selectedChalan.student.admission_id}
                          </p>
                        </span>
                      </div>

                      <div className="middlecontent">
                        <span>
                          <b>Name:</b>
                        </span>
                        <span
                          className="secondColoumn"
                          style={{ width: "470px" }}
                        >
                          <p>{selectedChalan.student?.name}</p>
                        </span>
                        <br></br>
                        <span className="secondColoumn">
                          <p>
                            {" "}
                            {selectedChalan.student.gender?.toLowerCase() ==
                            "male"
                              ? "s/o"
                              : "d/o"}{" "}
                            {selectedChalan.student?.father_name}
                          </p>
                        </span>
                        <br></br>
                      </div>
                      <br></br>
                      {selectedChalan.student?.father_name?.length > 15 ? (
                        <>
                          <br></br>
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}


                      <div className="middlecontent">
                        <span>
                          <b>Class:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>
                            {selectedChalan.student?.student_class.name +
                              " (" +
                              selectedChalan?.student?.global_section?.name +
                              ")"}
                          </p>
                        </span>
                      </div>
                    </div>
       
                    <div className="endContent">
                      <div className="endconheading">
                        <div>
                          <b>Inv No.</b>
                        </div>
                        <div>
                          <b>Description.</b>
                        </div>
                        <div>
                          <b>Amount.</b>
                        </div>
                      </div>
                 
                      {selectedChalan.fee_challan_details.map(
                        (copy, index) => (
                          <>
                            <div className="endcontentMain">
                              <div>
                                <p>{copy.fee_challan.challan_no}</p>
                              </div>

                              <div>
                                <p>
                                  {copy.fee_type_id == "23"
                                    ? "REMAINING BALANCE"
                                    : copy.fee_name}{" "}
                                  {getMonthName3(copy?.fee_month)}
                                </p>
                              </div>
                              <div>
                                <p>{copy.amount}</p>
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                    <div className="bottomContent">
                      <div>
                        <b>Total Amount </b>
                      </div>
                      <div>
                        <p>
                          {getFeeAmount(selectedChalan?.fee_challan_details)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box box3">
                  <div className="top3lines">
                    {selectedChalan?.student?.campus?.print_account_no &&
                      selectedChalan.student?.campus?.print_account_no.map(
                        (acc) => (
                          <>
                            <b>{`${acc.bank_name}/${acc.account_number}`}</b>
                            <br />
                          </>
                        )
                      )}
          

                    <b>{selectedChalan.student?.campus?.name}</b>
                    <br></br>
                  </div>
         
                  <div>
                    <div>
                      <div className="middlecontent">
                        <span>
                          <b>Due Date:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>{getMonthName2(selectedChalan?.due_date)}</p>
                        </span>
                      </div>

                   
                      <div className="middlecontent">
                        <span>
                          <b>Issue Date:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>{getMonthName2(selectedChalan?.issue_date)}</p>
                        </span>
                      </div>

          
                      <div className="middlecontent">
                        <span>
                          <b>Admission:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>
                            {" "}
                            {selectedChalan.student.admission_id == null
                              ? selectedChalan.student.registration_id
                              : selectedChalan.student.admission_id}
                          </p>
                        </span>
                      </div>
         
                      <div className="middlecontent" style={{ height: "auto" }}>
                        <span>
                          <b>Name:</b>
                        </span>
                        <span
                          className="secondColoumn"
                          style={{ width: "470px" }}
                        >
                          <p>{selectedChalan.student?.name}</p>
                        </span>
                        <br></br>
                        <span className="secondColoumn">
                          <p style={{ height: "fit-content" }}>
                            {" "}
                            {selectedChalan.student.gender?.toLowerCase() ==
                            "male"
                              ? "s/o"
                              : "d/o"}{" "}
                            {selectedChalan.student?.father_name}
                          </p>
                        </span>
                      </div>
                      <br></br>
                      <br></br>
                      {selectedChalan.student?.father_name?.length > 15 ? (
                        <>
                          <br></br>
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}
             
                      <div className="middlecontent">
                        <span>
                          <b>Class:</b>
                        </span>
                        <span className="secondColoumn">
                          <p>
                            {selectedChalan.student?.student_class.name +
                              " (" +
                              selectedChalan?.student?.global_section?.name +
                              ")"}
                          </p>
                        </span>
                      </div>
                    </div>
         
                    <div className="endContent">
                      <div className="endconheading">
                        <div>
                          <b>Inv No.</b>
                        </div>
                        <div>
                          <b>Description.</b>
                        </div>
                        <div>
                          <b>Amount.</b>
                        </div>
                      </div>
  
                      {selectedChalan.fee_challan_details.map(
                        (copy, index) => (
                          <>
                            <div className="endcontentMain">
                              <div>
                                <p>{copy.fee_challan.challan_no}</p>
                              </div>

                              <div>
                                <p>
                                  {copy.fee_type_id == "23"
                                    ? "REMAINING BALANCE"
                                    : copy.fee_name}{" "}
                                  {getMonthName3(copy?.fee_month)}
                                </p>
                              </div>
                              <div>
                                <p>{copy.amount}</p>
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                    <div className="bottomContent">
                      <div>
                        <b>Total Amount </b>
                      </div>
                      <div>
                        <p>
                          {getFeeAmount(selectedChalan?.fee_challan_details)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        ) : null}
      </div>
    </>
  );
};

export default SingleStudentPrint;
