import React from 'react'
import { PeopleAltOutlined, Payment, Apartment } from '@material-ui/icons';
import { useHistory } from 'react-router-dom'
import './style.css'
const SuperAdminDashboard = () => {
    const history = useHistory()
    return (

        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div style={{ cursor: 'pointer' }} onClick={() => history.push('/headoffice')} className="card card-1 m-2">
                        <PeopleAltOutlined style={{ height: 60, width: 60 }} color="primary" fontSize="large" />
                        <h4>Head Office</h4>

                    </div>
                </div>
                <div className="col-md-4">
                    <div style={{ cursor: 'pointer' }} onClick={() => history.push('/concession')} className="card card-1 m-2">
                        <Payment style={{ height: 60, width: 60 }} color="primary" fontSize="large" />
                        <h4>Concession</h4>

                    </div>
                </div>
                <div className="col-md-4">
                    <div style={{ cursor: 'pointer' }}
                        // onClick={() => history.push('')}
                        className="card card-1 m-2">
                        <Apartment style={{ height: 60, width: 60 }} color="primary" fontSize="large" />
                        <h4>Campus</h4>

                    </div>
                </div>
            </div>


            <div className="row">

                {/* <div className="col-md-4">
                    <div style={{ cursor: 'pointer' }} onClick={() => history.push('/')} className="card card-1 m-2">
                        <Apartment style={{ height: 60, width: 60 }} color="primary" fontSize="large" />
                        <h4>Section Fee Challans</h4>

                    </div>
                </div>
                <div className="col-md-4">
                    <div style={{ cursor: 'pointer' }} onClick={() => history.push('/')} className="card card-1 m-2">
                        <PeopleAltOutlined style={{ height: 60, width: 60 }} color="primary" fontSize="large" />
                        <h4>Find Student</h4>

                    </div>
                </div> */}

            </div>



        </div>

    )
}

export default SuperAdminDashboard
