import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { PremissionChecker } from "../../../../services/PremissionChecker";
function DefineClass() {
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);
  const authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const [isallfields, setisallfields] = useState(false);

  const [classesData, setClassesData] = useState([]);
  const [addClassLoading, setAddClassLoading] = useState(false);

  const [addClassModal, setAddClassModal] = useState(false);
  const [className, setClassName] = useState("");

  const [editClassModal, setEditClassModal] = useState(false);
  const [editClassName, setEditClassName] = useState("");
  const [editClassId, setEditClassId] = useState("");

  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteClassId, setDeleteClassId] = useState(0);
  const [deleteloading, setdeleteloading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getClasses();
  }, []);

  const getClasses = async () => {
    setloading(true);
    await fetch(`${baseUrl}/studentclasses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setClassesData(data.payload);
          setloading(false);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const addClassHandler = async () => {
    if (className == "") {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    const data = {
      name: className,
    };
    setAddClassLoading(true);

    await fetch(`${baseUrl}/studentclasses`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.success("Class successfully added");
            setAddClassModal(false);
            setAddClassLoading(false);
            getClasses();
            setClassName("");
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setAddClassModal(false);
            setAddClassLoading(false);
          } else {
            alert.show("Something Went Wrong");
            setAddClassModal(false);
            setAddClassLoading(false);
          }
        })
      )
      .catch((err) => {
        setAddClassLoading(false);
        setAddClassModal(false);
        console.log(err);
      });
  };

  const getEditClass = async (id) => {
    await fetch(`${baseUrl}/studentclasses/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditClassName(data.payload?.name);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateClassHandler = async () => {
    if (editClassName == "") {
      return alert.error("Class Name Is Required");
    }

    setSubmitLoading(true);
    const data = {
      name: editClassName,
    };
    setSubmitLoading(false);
    await fetch(`${baseUrl}/studentclasses/${editClassId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.success("Campus Class Successfully Updated");
            getClasses();
            setEditClassModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setEditClassModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditClassModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const deleteClassHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/studentclasses/${deleteClassId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.payload);
            getClasses();
            setdeleteloading(false);
            setdeleteModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setdeleteloading(false);
            setdeleteModal(false);
          } else {
            alert.show("Something Went Wrong");
            setdeleteloading(false);
            setdeleteModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteModal(false);
      });
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div>
          <button
            onClick={() => setAddClassModal(true)}
            className="btn btn-primary mr-2"
          >
            Add Class
          </button>
        </div>

        <Table className="mt-5">
          <thead>
            <tr className="text-center">
              <th>ID</th>
              <th>Class Name</th>
              {PremissionChecker(user, "campus.can_change_class") && (
                <th>Update</th>
              )}
              {PremissionChecker(user, "campus.can_change_class") && (
                <th>Delete</th>
              )}
            </tr>
          </thead>
          <tbody>
            {classesData?.map((classes, index) => (
              <tr className="text-center" key={index}>
                <td>{classes.id}</td>
                <td>{classes.name}</td>
                {PremissionChecker(user, "campus.can_change_class") && (
                  <td>
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => {
                        getEditClass(classes?.id);
                        setEditClassId(classes?.id);
                        setEditClassModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-success">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                )}

                {PremissionChecker(user, "campus.can_change_class") && (
                  <td>
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => {
                        setDeleteClassId(classes?.id);
                        setdeleteModal(true);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Delete-user.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        {/* Add Class Modal */}
        <Modal show={addClassModal} size="md">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Class
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row mb-4">
              <label className="form-control-label">
                <b>
                  Enter Class Nmae <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control text-uppercase"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                placeholder="Enter Class Name"
              />
            </div>

            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>Please fill or select all fields</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>

          <Modal.Footer>
            {addClassLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={addClassHandler}
              >
                Add
              </button>
            )}
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setAddClassModal(false);
                setisallfields(false);
                setClassName("");
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Class Modal */}
        <Modal show={editClassModal} size="md">
          <Modal.Header>
            <Modal.Title>Update Class</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-4">
              <label className="form-control-label">
                <b>
                  Enter Class Nmae <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control text-uppercase"
                value={editClassName}
                onChange={(e) => setEditClassName(e.target.value)}
                placeholder="Enter Class Name"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  className="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button onClick={updateClassHandler} className="btn btn-primary">
                Update
              </button>
            )}

            <button
              onClick={() => setEditClassModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Campus Class Modal */}
        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this class?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Fee Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button onClick={deleteClassHandle} className="btn button_blue">
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setdeleteModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
}

export default DefineClass;
