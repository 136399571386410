import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Table, Modal, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Col, Alert } from "react-bootstrap";
import EditDefinedFee from "./updateDefinedFee";

import SingleFeeGenerate from "../../pages/GenrateStudentWiseFee/GenrateStudentWiseFee";

const DefineMonthlyFee = ({
  studentdetail,
  show,
  proceed,
  issue_date,
  due_date,
  onHide,
  openfeemodal,
}) => {
  const alert = useAlert();
  console.log(show);
  const [showmodal, setshowmodal] = useState(show);
  const [feemodal, setfeemodal] = useState(false);
  const authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const [feetypes, setfeetypes] = useState([]);
  const [selectedfeetype, setselectedfeetype] = useState("");
  const [amount, setamount] = useState("");
  const [isallfields, setisallfields] = useState(false);
  const [errorcreate, seterrorcreate] = useState("Plese Fill All Fields");
  const [concessionamount, setconcessionamount] = useState(0);
  const [onChangeAmount, setonChangeAmount] = useState("");
  const [allDefinedFees, setallDefinedFees] = useState([]);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteablefee, setdeleteablefee] = useState({});
  const [editmodal, seteditmodal] = useState(false);
  const [editablefee, seteditablefee] = useState({});
  const [editedamount, seteditedamount] = useState("");
  const [editedconcession, seteditedconcession] = useState(0);
  const [deleteloading, setdeleteloading] = useState(false);
  const [remarks, setremarks] = useState("");
  const [allowedconcession, setallowedconcession] = useState(0);
  const [allconcessions, setallconcessions] = useState([]);
  const [applyconcessionbtn, setapplyconcessionbtn] = useState(true);
  const [isallowedconcesstion, setisallowedconcesstion] = useState(true);

  console.log(studentdetail, "studentdetail define");

  useEffect(() => {
    getfeeTypes();
    getDefinedFees();
    getConcessionTypes();
  }, [studentdetail])

  const studenFeeGenerate = () => {
    setfeemodal(true);
    setshowmodal(false);
    // if (proceed == "feegenerate") {
    //     openfeemodal(true)
    //     // onHide(false)
    // } else {
    //     setfeemodal(true)
    //     setshowmodal(false)
    // }
  };

  const getDefinedFees = () => {
    fetch(`${baseUrl}/student_all_liable_fees/${studentdetail.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallDefinedFees([...data.payload]);
            console.log(data.payload, "all feeswes");
          } else {
            alert.show(
              "Something Went Wrong While Fetching Student Defined Fees"
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        alert.show("Something Went Wrong While Fetching Student Defined Fees");
      });
  };
  const checkerror = () => {
    if (!selectedfeetype) {
      setisallfields(true);
      seterrorcreate("Please Add Amount");
      return false;
    }
    if (onChangeAmount == "") {
      setisallfields(true);
      seterrorcreate("Please Add Fee");
      return false;
    }
    setisallfields(false);
    return true;
  };

  const feeDefine = () => {
    setisallfields(false);

    let res = checkerror();
    if (!res) {
      return;
    }

    setloading(true);
    let data = {
      student_id: studentdetail.id,
      fees_type_id: selectedfeetype,
      amount: onChangeAmount,
      concession_amount: parseInt(concessionamount),
      remarks: remarks,
    };

    console.log(data);
    fetch(`${baseUrl}/student_liable_fee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setloading(false);
            // show(false)
            alert.show("Fee Added success");
            setonChangeAmount("");
            setconcessionamount(0);
            getDefinedFees();
          } else {
            setloading(false);
            // show(false)
            alert.show("Fee Not Added success");
          }
        } else {
          alert.show("Fee Not Added success");
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        console.log("err", err);
        alert.show("Error Occured");
      });
  };
  const getfeeTypes = () => {
    console.log(studentdetail.campus_id);
    console.log(studentdetail.student_class_id);
    console.log(
      `${baseUrl}/classfeetypes/${studentdetail.campus_id}/${studentdetail.student_class_id}`
    );

    fetch(
      `${baseUrl}/classfeetypes/${studentdetail.campus_id}/${studentdetail.student_class_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setfeetypes(data.payload);
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };
  const getConcessionTypes = () => {
    fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallconcessions(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };
  const getRespctiveFee = (id) => {
    setselectedfeetype(id);
    let data = {
      campus_id: studentdetail.campus_id,
      student_class_id: studentdetail.student_class_id,
      fee_type_id: id,
    };
    console.log(data);
    fetch(`${baseUrl}/feestructure_amount`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data.payload.amount);
            setonChangeAmount(data.payload.amount);
            setamount(data.payload.amount);
            console.log(onChangeAmount);
          } else {
            console.log(data);
            // setloading(false)
            // show(false)
            // alert.show('Fee Not Added success')
          }
        }
      })
      .catch((err) => {
        alert.show("Something Went Wrong");
        console.log("err", err);
      });
  };
  const onChangeFeeAmount = (value) => {
    setconcessionamount(amount - value);
    setonChangeAmount(value);
  };
  const deleteFee = () => {
    setdeleteloading(true);

    fetch(`${baseUrl}/student_liable_fee/${deleteablefee.id}}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show(data.payload);
            getDefinedFees();
            setdeleteloading(false);
            setdeleteModal(false);
          } else {
            alert.show(data.payload);
            setdeleteloading(false);
            setdeleteModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteModal(false);
      });
  };
  const applyConcessionBtn = () => {
    // console.log(allowedconcession)
    // console.log(onChangeAmount)
    // console.log("concession applied")
    // concessionamount
    //applyconcessionbtn false
    setapplyconcessionbtn(false);
    console.log(concessionamount);
    console.log(amount);
    console.log(onChangeAmount);
    console.log(amount - allowedconcession);
    setonChangeAmount(amount - allowedconcession);
    setconcessionamount(allowedconcession);
    //  setonChangeAmount(value)
  };
  // const onChangeConcession = (value) = {

  // }

  console.log("This is now update", allDefinedFees);

  return (
    <div>
      <Modal show={showmodal} size="xl">
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Define Fee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <b style={{ color: "green" }}>
              {" "}
              Fee defining Please Wait <span className="spinner-borderd"></span>
            </b>
          ) : (
            <div>
              <div className="container-fluid">
                <div className="row d-flex justify-content-center ">
                  {/* <div className="generate text-center">Define  Fee</div> */}
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div>
                      <div className="row justify-content-between text-left">
                        <Table responsive>
                          <thead>
                            <tr id="tbl">
                              <th>Fee Name</th>
                              <th>Total Amount</th>
                              <th>Payable Amount</th>
                              <th>Concession</th>
                              <th>Remarks</th>
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allDefinedFees.map((fees, i) => {
                              return (
                                <tr key={fees.id} className="addstudentList">
                                  <td>{fees.fees_type_name}</td>
                                  <td>
                                    {parseInt(fees.amount) +
                                      parseInt(fees.concession_amount)}
                                  </td>
                                  <td>{fees.amount}</td>
                                  <td>{fees.concession_amount}</td>
                                  {/* <td  >remark?</td> */}
                                  <td
                                  //  title={fees.remarks}
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                      {fees.remarks ? (
                                        <SVG
                                          title={fees.remarks}
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Code/Info-circle.svg"
                                          )}
                                        />
                                      ) : (
                                        <SVG
                                          title="No Remarks"
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Code/Info-circle.svg"
                                          )}
                                        />
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    <a
                                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                      onClick={() => {
                                        seteditablefee(fees);
                                        seteditmodal(true);
                                        // seteditedconcession(fees.concession_amount)
                                        // seteditedamount(fees.amount)
                                        //  openEditCustomerDialog(row.id)
                                      }}
                                    >
                                      <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Communication/Write.svg"
                                          )}
                                        />
                                      </span>
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                      onClick={() => {
                                        setdeleteablefee(fees);
                                        setdeleteModal(true);
                                      }}
                                    >
                                      <span className="svg-icon svg-icon-md svg-icon-danger">
                                        <SVG
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Communication/Delete-user.svg"
                                          )}
                                        />
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="row justify-content-between text-left">
                        <div className="form-group col-sm-4 flex-column d-flex">
                          <label className="form-control-label">
                            <b>
                              Concession Type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <select
                            onChange={(e) => {
                              // onChangeConcession(e.target.value)
                              setallowedconcession(e.target.value);
                              setapplyconcessionbtn(true);
                              if (onChangeAmount == "") {
                                setisallowedconcesstion(true);
                              } else {
                                setisallowedconcesstion(false);
                              }
                            }}
                            style={{ backgroundColor: "white" }}
                            className="form-select mb-2 "
                            aria-label="Default select example"
                          >
                            <option disabled selected>
                              Select Concession Type{" "}
                            </option>
                            {allconcessions.map((feetype) => (
                              <option key={feetype.id} value={feetype.amount}>
                                {feetype.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-sm-3 flex-column d-flex">
                          <label className="form-control-label">
                            <b>Concession Allowed </b>
                          </label>

                          <span style={{ marginTop: "15px" }} disabled>
                            {" "}
                            Concession Allowed is {allowedconcession}
                          </span>

                          {/* <input disabled value={concessionamount} type="text" /> */}
                        </div>
                        <div className="form-group col-sm-4 flex-column d-flex">
                          <label className="form-control-label">
                            <b>
                              Fee Type <span style={{ color: "red" }}>*</span>{" "}
                            </b>
                          </label>
                          <select
                            // value={designation_id}
                            onChange={(e) => {
                              getRespctiveFee(e.target.value);
                              if (allowedconcession == 0) {
                                setisallowedconcesstion(true);
                              } else {
                                setisallowedconcesstion(false);
                              }
                              // checkerror()
                            }}
                            className="form-select mb-2 "
                            aria-label="Default select example"
                          >
                            <option disabled selected>
                              Select Fee Type{" "}
                            </option>
                            {feetypes.map((feetype) => (
                              <option key={feetype.id} value={feetype.id}>
                                {feetype.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-sm-4 flex-column d-flex">
                          <label className="form-control-label">
                            <b>
                              Amount <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <input
                            value={onChangeAmount}
                            defaultValue={onChangeAmount}
                            onChange={(e) => {
                              onChangeFeeAmount(e.target.value);
                              if (e.target.value == "") {
                                setisallfields(true);
                                seterrorcreate("Please Add Fee");
                              } else {
                                setisallfields(false);
                              }
                              // checkerror()
                            }}
                            type="number"
                          />
                        </div>
                        <div className="form-group col-sm-4 flex-column d-flex">
                          <label className="form-control-label">
                            <b>Concession </b>
                          </label>
                          {applyconcessionbtn ? (
                            <button
                              style={{
                                backgroundColor: "#121291",
                                color: "#ffffff",
                                borderColor: "#121291",
                                width: "165px",
                              }}
                              disabled={isallowedconcesstion}
                              onClick={applyConcessionBtn}
                              className="btn btn-success  pl-4 pr-4"
                            >
                              Apply Concession
                            </button>
                          ) : (
                            <input
                              disabled
                              value={concessionamount}
                              type="number"
                            />
                          )}
                        </div>
                        <div className="form-group col-sm-4 flex-column d-flex">
                          <label className="form-control-label">
                            <b>
                              Remarks <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <input
                            onChange={(e) => {
                              setremarks(e.target.value);
                            }}
                            type="text"
                          />
                        </div>
                      </div>
                      {isallfields ? (
                        <>
                          <Alert
                            variant="danger"
                            onClose={() => setisallfields(false)}
                            dismissible
                          >
                            <Alert.Heading>
                              Oh snap! You got an error!
                            </Alert.Heading>
                            <p>{errorcreate}</p>
                          </Alert>
                        </>
                      ) : null}
                      {/* {
                                                    proceed == "proceed" ? */}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        className="btns"
                      >
                        <button
                          onClick={feeDefine}
                          className="btn btn-success button_blue pl-4 pr-4"
                        >
                          Add
                        </button>
                        <button
                          style={{ marginLeft: "10px" }}
                          onClick={studenFeeGenerate}
                          className="btn btn-success button_blue pl-4 pr-4"
                        >
                          proceed
                        </button>
                      </div>
                      {/* :


                                                        <div style={{ display: "flex", justifyContent: "flex-end" }} className="btns">

                                                            <button
                                                                onClick={feeDefine}
                                                                className="btn btn-success button_blue pl-4 pr-4"
                                                            >
                                                                Add
                                                            </button>
                                                            <button
                                                                onClick={() => show(false)}
                                                                className="btn btn-danger btn_width  pl-4 pr-4 ml-3">
                                                                Cancle
                                                            </button>
                                                        </div>
                                                } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Modal show={deleteModal}>
            <Modal.Header className="modal_header_style" closeButton>
              <Modal.Title
                className="Modal_Heading_font"
                id="contained-modal-title-vcenter"
              >
                Are you want to delete
              </Modal.Title>
            </Modal.Header>
            {deleteloading ? (
              <div style={{ padding: "20px" }}>
                {" "}
                <b style={{ color: "green" }}>
                  {" "}
                  Fee Deleting Please Wait{" "}
                  <span className="spinner-borderd"></span>
                </b>
              </div>
            ) : (
              <Modal.Footer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button onClick={deleteFee} className="btn button_blue">
                    Delete
                  </button>
                </div>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setdeleteModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </Modal.Footer>
            )}
          </Modal>

          <EditDefinedFee
            feeDetail={editablefee}
            onHide={seteditmodal}
            show={editmodal}
            getDefinedFees={getDefinedFees}
          />
        </Modal.Body>
      </Modal>

      {feemodal ? (
        <SingleFeeGenerate
          isfeemodal={feemodal}
          studentdetail={studentdetail}
          is_issue_date={issue_date}
          is_due_date={due_date}
          proceed="proceed"
        />
      ) : null}
    </div>
  );
};

export default DefineMonthlyFee;
