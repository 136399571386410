import React from 'react'

const IssueCardModel = ({des}) => {
  return (
    <>
<div>
    <h4>{des}</h4>
</div>
    </>
  )
}

export default IssueCardModel