import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Tab, Tabs, Alert, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "./styles.css";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import FeeReceviceModal from "../../../components/FeeReceviceModal/FeeReceviceModal";
import Barcode from "react-barcode";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import Pagination from "@material-ui/lab/Pagination";
import EditCampusFeeChallan from "./EditCampusFeeChallan";
// import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import ViewModal from "./ViewFeeDetail";
import ReactToPrint from "react-to-print";
import "../../../components/PrintChallanModal/printChallan.style.css";
import "./printstyle.css";
import MonthlyChallanModal from "../../../components/MonthlyChallanModal/MonthlyChallanModal";
import usePagination from "../../../components/usePagination/usePagination";
import EditSingleFee from "./EditSingleFee/EditSingleFee";
import DeleteSingleFee from "./DeleteSingleFee/DeleteSingleFee";
import PrintChallanModal from "../../../components/PrintChallanModal/PrintChallanModal";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import { upperCase } from "lodash";

function CampusFeeChallan({ orientation = "portrait" }) {
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  //redux state
  const authtoken = localStorage.getItem("userToken");

  //alert state

  const alert = useAlert();

  //dispatching actions

  const dispatch = useDispatch();

  //router dom

  const history = useHistory();
  //states
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [feechallans, setfeechallans] = useState([]);
  const [printmodal, setprintmodal] = useState(false);
  const [selectedcard, setselectedcard] = useState({});
  const [selectedchallan, setselectedchallan] = useState({});
  const [printbtn, setprintbtn] = useState(false);
  const [feerecmodal, setfeerecmodal] = useState(false);
  const [searchtext, setsearchtext] = useState("");
  const [searchLoading, setsearchLoading] = useState(false);

  const [challanNumber, setchallanNumber] = useState("");
  const [monthlyPrintModal, setmonthlyPrintModal] = useState(false);
  const [testModal, settestModal] = useState(false);

  const [selectedtype, setselectedtype] = useState("");

  const [onlyChallan, setonlyChallan] = useState(false);
  const [postpond, setpostpond] = useState(false);

  // @pagination states
  const [activePage, setactivePage] = useState("Paid");
  const [paidLinks, setpaidLinks] = useState([]);
  const [unpaidLinks, setunpaidLinks] = useState([]);

  let localreceiveddate = localStorage.getItem("challanrecevingdate");
  // console.log(localreceiveddate, "localreceiveddate")
  // const [recevieddate, setrecevieddate] = useState(useSelector(state => state.setchallanrecevingdate))

  const [lateFine, setlatefine] = useState("");
  const [readmissionFee, setreadmissionFee] = useState("");

  const [past6monthsChallans, setpast6monthsChallans] = useState([]);
  const [challandeleteModal, setchallandeleteModal] = useState(false);
  const [currentChallanId, setcurrentChallanId] = useState("");
  const [campusId, setcampusId] = useState("");
  const [RollBackId, setRollBackId] = useState("");
  const [rollbackModal, setrollbackModal] = useState(false);
  const [paidAmount, setpaidAmount] = useState("");
  const [paidchallans, setpaidchallans] = useState([]);
  const [paidPayloadData, setpaidPayloadData] = useState({});
  const [unpaidPayloadData, setunpaidPayloadData] = useState({});
  const [feeReceviceLoading, setfeeReceviceLoading] = useState(false);
  const [rollbackLoading, setrollbackLoading] = useState(false);
  const [postPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [respectiveFeechallns, setrespectiveFeechallns] = useState([]);
  const [isfeereceivedata, setisfeereceivedata] = useState(false);
  let [checkpaidclick, setcheckpaidclick] = useState(true);
  const [educationType, setEducationType] = useState(1);
  let [checkunpaidclick, setcheckunpaidclick] = useState(false);
  const [selectedobject, setselectedobject] = useState({});
  const [editmodal, seteditmodal] = useState(false);
  const [viewmodal, setviewmodal] = useState(false);
  const [combineModal, setcombineModal] = useState(false);
  const [selecetedStudentForCombine, setselecetedStudentForCombine] = useState(
    {}
  );
  const [feechallansDetailList, setfeechallansDetailList] = useState([]);
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  // let feerecevingbank = useSelector(
  //   (state) => state.campuses.feerecevingbank
  // );
  const [feerecevingbank, setfeerecevingbank] = useState("");
  const [recevieddate, setrecevieddate] = useState(new Date());
  const [recevingdate, setrecevingdate] = useState();
  // merge into one states
  const [classess, setClassess] = useState([]);
  const [campus_class_id, setcampus_class_id] = useState("");
  const [dataloading, setdataloading] = useState(false);
  const [sections, setsections] = useState([]);
  const [campus_class_section_id, setcampus_class_section_id] = useState("");
  const [studentAdmissionId, setstudentAdmissionId] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const [allbanks, setallbanks] = useState([]);
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [reg_id, setreg_id] = useState("");
  const [selectedbankcateogry, setselectedbankcateogry] = useState("");
  const [selectedbank, setselectedbank] = useState("");
  const [totalAmount, settotalAmount] = useState(0);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (campusidget != "") {
      console.log(campusidget);
      middleFunction(campusidget);
    }
    getSettingData();
    // getAllCampus();
    getAllBanks();
  }, []);

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setPageSize(orientation);
    return () => {
      const child = document.getElementById("voucher");
      child.parentNode.removeChild(child);
    };
  }, [orientation]);

  // let campusidget = useSelector(state => state.campuses.setselectedcampusid)

  const PRINTCOUNT = [1, 2, 3];
  // let storedata = useSelector(state => state.setchallanrecevingdate);
  // console.log(storedata, "storedata");

  // console.log(recevieddate, "recevieddate")
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let campuses = [];
        data.payload.map((campus) => {
          if (user.head_office.id == campus.head_office_id) {
            campuses.push(campus);
          }
        });
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setmainchangecampus = (campusid, classid, sectionid, url) => {
    // if (sectionid != '') {
    //   url = `${baseUrl}/show_campus_section_challans/${campusid}/${classid}/${sectionid}`
    // } else if (classid != '') {
    //   url = `${baseUrl}/show_campus_class_challans/${campusid}/${classid}`
    // } else {
    //   url = `${baseUrl}/show_campus_challans/${campusid}`
    // }
    console.log("unpaid current url", url);

    setdataloading(true);
    setcampusId(campusid);
    fetch(url, {
      //unpaid data ay ga
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload, "unpaid load data");
        setdataloading(false);
        setunpaidLinks(data.payload.challans.links);
        setunpaidPayloadData(data.payload);
        setfeechallans(data.payload.challans.data);
        if (checkunpaidclick) {
          setrespectiveFeechallns(data.payload.challans.data);
        }
        dispatch({
          type: "CAMPUS_UNPAID_CHALLANS",
          payload: data.payload.challans.data,
        });

        setprintbtn(true);
      })
      .catch((err) => {
        setdataloading(false);

        console.log("setfeechallans", err);
      });
  };
  const openprinter = (feechallan) => {
    console.log(feechallan);
    setselectedcard(feechallan);
    setprintmodal(true);
  };

  const cancelPrintModal = () => {
    console.log("pakistan");
    // setprintmodal(false)
  };

  const printdoc = () => {
    function printElement(elem) {
      // console.log(elem)
      // let domClone = elem.cloneNode(true);
      // console.log(domClone)
      var $printSection = document.getElementById("printSection");

      if (!$printSection) {
        var $printSection = document.createElement("div");
        $printSection.id = "printSection";
        document.body.appendChild($printSection);
      }

      $printSection.innerHTML = elem;

      // // $printSection.appendChild(elem);
      window.print();

      // var css = '@page { size: landscape; }',
      //   head = document.head || document.getElementsByTagName('head')[0],
      //   style = document.createElement('style');

      // style.type = 'text/css';
      // style.media = 'print';

      // if (style.styleSheet) {
      //   style.styleSheet.cssText = css;
      // } else {
      //   style.appendChild(document.createTextNode(css));
      // }

      // head.appendChild(style);

      // window.print();

      // var css = '@page { size: landscape; }',
      //   head = document.head || document.getElementsByTagName('head')[0],
      //   style = document.createElement('style');

      // style.type = 'text/css';
      // style.media = 'print';

      // if (style.styleSheet) {
      //   style.styleSheet.cssText = css;
      // } else {
      //   style.appendChild(document.createTextNode(css));
      // }

      // head.appendChild(style);

      // window.print();
    }

    printElement(document.getElementById("voucher-print").innerHTML);
  };

  const openfeerecevingmodal = (feechallan) => {
    setselectedchallan(feechallan);
    setfeerecmodal(true);
  };

  const gotoviewpage = (feechallan) => {
    setselectedchallan(feechallan);
    setviewmodal(true);
  };

  const feeReceiceHandler = () => {
    if (
      recevieddate == "" ||
      recevieddate == undefined ||
      feerecevingbank == "" ||
      feerecevingbank == undefined
    ) {
      setisfeereceivedata(true);
      return;
    }
    setisfeereceivedata(false);
    setfeeReceviceLoading(true);
    const data = {
      received_date: recevieddate,
      bank_account_id: feerecevingbank,
      // only_challan_amount: selectedtype == "onlyChallan" ? 1 : 0,
      // postponed: selectedtype == "postpond" ? 1 : 0,
      // pay_fine: selectedtype == "pay_fine" ? 1 : 0,
      // late_fine: lateFine,
      // re_admission_amount: readmissionFee
    };

    console.log(data);
    fetch(`${baseUrl}/fee_receiving/${selectedchallan.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log("after submit", data);
          setfeerecevingbank("");
          setrecevieddate("");
          setfeerecmodal(false);
          setfeeReceviceLoading(false);
          setfeeReceviceLoading(false);
          if (data.metadata) {
            if (data.metadata.success) {
              getpaidChallans(
                previousselectedcampus,
                campus_class_id,
                campus_class_section_id
              );
              setmainchangecampus(
                previousselectedcampus,
                campus_class_id,
                campus_class_section_id
              );
              alert.show("Fee Received Sucessfully");
              localStorage.setItem("challanrecevingdate", recevieddate);
              dispatch({
                type: "CHALLAN_RECEVING_DATE",
                payload: {
                  setchallanrecevingdate: recevieddate,
                },
              });
              //  getpaidChallans(campusId)
              //  history.push('/campusfeechallan')
            } else {
              alert.show("Error Occured While Receiving Fee");
            }
          } else {
            alert.show("Error Occured While Receiving Fee");
          }
        });
      })
      .catch((err) => {
        // alert.show('Somthing went wrong', err)
        console.log(err);
        setfeerecevingbank("");
        setrecevieddate("");
        alert.show("Error Occured While Receiving Fee", err);
        setfeeReceviceLoading(false);
      });
  };

  const openchallandeleteModal = (feechallan) => {
    setcurrentChallanId(feechallan.id);
    setchallandeleteModal(true);
  };

  const deletechallan = () => {
    fetch(`${baseUrl}/fee-destroy/${currentChallanId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      method: "DELETE",
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.show("Challan Deleted Successfully");
            setmainchangecampus(
              previousselectedcampus,
              campus_class_id,
              campus_class_section_id
            );
            setchallandeleteModal(false);
            setcurrentChallanId("");
            getpaidChallans(
              previousselectedcampus,
              campus_class_id,
              campus_class_section_id
            );
          } else {
            alert.show("Something went wrong");
            setchallandeleteModal(false);
          }
        });
      })
      .catch((err) => {
        setchallandeleteModal(false);
        console.log(err);
      });
  };

  const openrollback = (feechallan) => {
    // console.log(feechallan)
    setRollBackId(feechallan.id);
    setrollbackModal(true);
  };

  const onchangecampus = (e) => {
    setdataloading(true);
    const id = e;
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(" className", data);
        setdataloading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setClassess(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setdataloading(false);

        console.log(" className", err);
      });
  };

  const middleFunction = (id) => {
    setcampus_class_id("");
    setcampus_class_section_id("");
    setCurrentPage(1);
    setpreviousselectedcampus(id);

    // dispatch({
    //   type: "SELECTED_CAMPUS_ID",
    //   payload: {
    //     setselectedcampusid: id,
    //   },
    // });

    // dispatch({
    //   type: "SELECTED_CAMPUS_ID",
    //   payload: {
    //     setselectedcampusid: id,
    //   },
    // });

    let unpaidurl = `${baseUrl}/show_campus_challans/${id}`;
    let url = `${baseUrl}/show_campus_challans/${id}/1`;

    setmainchangecampus(
      id,
      campus_class_id,
      campus_class_section_id,
      unpaidurl
    );
    getpaidChallans(id, campus_class_id, campus_class_section_id, url); //paid data ay ga

    onchangecampus(id);
  };

  const onClassChange = (id) => {
    let unpaidurl = `${baseUrl}/show_campus_class_challans/${campusId}/${id}/${educationType}`;
    let paidurl = `${baseUrl}/show_campus_class_challans/${campusId}/${id}/${educationType}/1`;
    setcampus_class_id(id);
    setmainchangecampus(
      previousselectedcampus,
      id,
      campus_class_section_id,
      unpaidurl
    );

    // setmainchangecampus(previousselectedcampus, id);
    getpaidChallans(
      previousselectedcampus,
      id,
      campus_class_section_id,
      paidurl
    );
    onchangeclassfunc(id);
  };

  const sectionMiddleFunction = (id) => {
    let unpaidurl = `${baseUrl}/show_campus_section_challans/${campusId}/${campus_class_id}/${id}`;
    let paidurl = `${baseUrl}/show_campus_section_challans/${campusId}/${campus_class_id}/${id}/1`;

    setmainchangecampus(previousselectedcampus, campus_class_id, id, unpaidurl);
    getpaidChallans(previousselectedcampus, campus_class_id, id, paidurl);
    setcampus_class_section_id(id);
  };

  const reloadFunction = () => {
    if (campus_class_section_id != "") {
      sectionMiddleFunction(campus_class_section_id);
    } else if (campus_class_id != "") {
      onClassChange(campus_class_id);
    } else {
      middleFunction(previousselectedcampus);
    }
  };

  //get paid list of challans

  const getpaidChallans = (campusid, classid, sectionid, url) => {
    setdataloading(true);

    // if (sectionid != '') {
    //   url = `${baseUrl}/show_campus_section_challans/${campusid}/${classid}/${sectionid}/1`
    // } else if (classid != '') {
    //   url = `${baseUrl}/show_campus_class_challans/${campusid}/${classid}/1`
    // } else {
    //   url = `${baseUrl}/show_campus_challans/${campusid}/1`
    // }

    console.log("current url", url);

    fetch(url, {
      //paid data ay ga
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload, "paid data show");
        setdataloading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setpaidLinks(data.payload.challans.links);
            setpaidPayloadData(data.payload);
            setpaidchallans(data.payload.challans.data);
            if (checkpaidclick) {
              setrespectiveFeechallns(data.payload.challans.data);
            }
            setrespectiveFeechallns(data.payload.challans.data);
            dispatch({
              type: "CAMPUS_PAID_CHALLANS",
              payload: data.payload.challans.data,
            });

            setprintbtn(true);
          }
        }
      })
      .catch((err) => {
        setdataloading(false);

        console.log("setfeechallans", err);
      });
  };
  const checkpayableamount = (e) => {
    setCurrentPage(1);
    if (e.target.innerHTML == "Unpaid") {
      setcheckpaidclick(false);
      setcheckunpaidclick(true);
      setactivePage("Unpaid");
      setrespectiveFeechallns([...feechallans]);
    }
    if (e.target.innerHTML == "Paid") {
      setcheckunpaidclick(false);
      setcheckpaidclick(true);
      setactivePage("Paid");

      setrespectiveFeechallns([...paidchallans]);
    }
  };

  const rollBackHandler = () => {
    setrollbackLoading(true);
    fetch(`${baseUrl}/fee_roleback/${RollBackId}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.show("Roll back Successfully");
            setmainchangecampus(
              previousselectedcampus,
              campus_class_id,
              campus_class_section_id
            );
            setrollbackModal(false);
            getpaidChallans(
              previousselectedcampus,
              campus_class_id,
              campus_class_section_id
            );
            setrollbackLoading(false);
          } else {
            alert.show("Something went wrong");
            setrollbackModal(false);
            setrollbackLoading(false);
          }
        });
      })
      .catch((err) => {
        setrollbackModal(false);
        setrollbackLoading(false);
        console.log(err);
      });
  };

  const onchangeclassfunc = (id) => {
    fetch(
      `${baseUrl}/class_section/${previousselectedcampus}/${educationType}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("className", data);

        if (data.metadata) {
          if (data.metadata.success) {
            setsections(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${campusId}/${id}${
        campus_class_id ? "/" + campus_class_id : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setsections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get total amount

  // const getPaidAmout = (feechallanlist) => {
  //     console.log(feechallanlist)
  //     // const total = feechallanlist.reduce((tot, arr) => {
  //     //     return tot + arr.paid
  //     // }, 0)

  //     // setpaidAmount(total)

  // }

  //@_pagination

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;
  let currentPosts = [];
  let dataSearch = [];

  if (checkpaidclick) {
    dataSearch = paidchallans?.filter((user) => {
      let text = searchtext.toLowerCase();
      return searchtext
        ? user?.challan_no?.toLowerCase().includes(text) ||
            user?.student_name?.toLowerCase().includes(text) ||
            user?.student_admission_id?.toLowerCase().includes(text)
        : true;
    });
    currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);
  } else {
    dataSearch = feechallans.filter((user) => {
      let text = searchtext.toLowerCase();
      return searchtext
        ? user?.challan_no?.toLowerCase().includes(text) ||
            user?.student_name?.toLowerCase().includes(text) ||
            user?.student_admission_id?.toLowerCase().includes(text)
        : true;
    });

    currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);
  }

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  function setPageSize(cssPageSize) {
    const style = document.createElement("style");
    style.innerHTML = `@page {size: ${cssPageSize}}`;
    style.id = "voucher";
    document.head.appendChild(style);
  }

  // function PrintIcon({ teamId, orientation = 'portrait' }) {
  //     // Set orientation of page being printed
  //     useEffect(() => {
  //         setPageSize('portrait');
  //         return () => {
  //             const child = document.getElementById('page-orientation');
  //             child.parentNode.removeChild(child);
  //         };
  //     }, [orientation]);

  //     return (
  //         <div className='print-button' onClick={() => window.print()}>
  //             Click to Print
  //         </div>
  //     );
  // }

  // export default PrintIcon;

  const searchStudent = () => {
    if (studentAdmissionId == "" && reg_id == "") {
      alert.show("Please enter a student admission Id or Registration Id");
    } else {
      setsearchLoading(true);
      console.log("finding...");
      const data = {
        admission_id: studentAdmissionId,
        registration_id: reg_id,
      };

      fetch(`${baseUrl}/student_chllan_by_admission_id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            setselecetedStudentForCombine(data.payload.student);
            setfeechallansDetailList(data.payload.fee_challan_details);
            settotalAmount(data.payload.total_amount);
            setpast6monthsChallans(data.payload.past_6_month_challan_details);
            setsearchLoading(false);
            setcombineModal(true);
          } else {
            setsearchLoading(false);
            alert.error(data.metadata.message.admission_id[0]);
          }
        })
        .catch((err) => {
          setsearchLoading(false);
          console.log("error in searchstudent", err);
        });
    }
  };
  const searchStudentByChallan = () => {
    if (challanNumber == "") {
      alert.show("Please enter a challan number");
    } else {
      const data = {
        challan_no: challanNumber,
      };

      fetch(`${baseUrl}/search_challan_no`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            setselecetedStudentForCombine(data.payload.student);
            setfeechallansDetailList(data.payload.fee_challan_details);
            settotalAmount(data.payload.total_amount);
            setcombineModal(true);
          } else {
            alert.error(data.metadata.message.admission_id[0]);
          }
        })
        .catch((err) => {
          console.log("error in searchstudent", err);
        });
    }
  };

  const submitFeeStudentWise = () => {
    if (selecetedStudentForCombine.global_section_id) {
      const data = {
        received_date: recevingdate,
        bank_account_id: selectedbank,
        student_id: selecetedStudentForCombine.id,
      };

      console.log(data);

      fetch(`${baseUrl}/student_chllan_by_admission_id_submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.metadata.success) {
            alert.show(data.metadata.message);
            setcombineModal(false);
          } else {
            alert.show(`Something went wrong. Please try again later.`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert.error("Please assign Section 1st then you can submit fee");
    }
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(dataSearch.length / PER_PAGE);
  const _DATA = usePagination(dataSearch, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const [singleFeeobject, setsingleFeeobject] = useState({});
  const [singlefeemodal, setsinglefeemodal] = useState(false);
  const [singleDeletefeemodal, setsingleDeletefeemodal] = useState(false);

  const openEditSingleFee = (item) => {
    console.log(item);
    setsingleFeeobject(item);
    setsinglefeemodal(true);
  };
  const openDeleteSingleFee = (item) => {
    setsingleFeeobject(item);
    setsingleDeletefeemodal(true);
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getMonthName2 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getMonthName3 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };

  const getFeeAmount = (challans) => {
    let sum = 0;

    challans.forEach((element) => {
      sum += parseInt(element.amount);
    });

    return sum;
    // const sum = challans.reduce((accumulator, object) => {
    //   return accumulator + object.amount;
    // }, 0);
    // console.log(sum)
  };

  // console.log('quick view',selectedcard)

  const onPaginateUnpaid = (link) => {
    setmainchangecampus(
      campusId,
      campus_class_id,
      campus_class_section_id,
      link.url
    );
    console.log("un paid next link", link);
  };

  const onPaginatePaid = (link) => {
    console.log("paid next link", link);
    getpaidChallans(
      campusId,
      campus_class_id,
      campus_class_section_id,
      link.url
    ); //paid data ay ga
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="form-group col-md-3">
            <select
              onChange={(e) => middleFunction(e.target.value)}
              className="form-select"
              value={campuses.length == 0 ? "" : previousselectedcampus}
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Campus
              </option>
              {campuses.map((campus) => (
                <option value={campus.id}>{campus.name}</option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <select
              onChange={(e) => onClassChange(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              aria-label="Default select example"
              value={campus_class_id}
            >
              <option selected value="">
                Select Class
              </option>
              {classess.map((clas) => (
                <option value={clas.id}>{clas.name}</option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <select
              onChange={(e) => sectionMiddleFunction(e.target.value)}
              className="form-select"
              value={campus_class_section_id}
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Section
              </option>
              {sections.map((campus) => (
                <option value={campus.id}>{campus.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            <input
              onChange={(e) => setreg_id(e.target.value)}
              id="searchInput"
              className="form-control"
              placeholder="Enter Registration Number"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              onChange={(e) => setstudentAdmissionId(e.target.value)}
              className="form-control"
              placeholder="Enter Addmission ID of student"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              onChange={(e) => setchallanNumber(e.target.value)}
              className="form-control"
              placeholder="Enter Challan Number"
            />
          </div>
        </div>
        {searchLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <button
            className="btn btn-primary mt-5 col-sm-2"
            onClick={(e) => searchStudent(e)}
          >
            Search
          </button>
        )}
        <button
          className="btn btn-primary mt-5 ml-3 col-sm-2"
          onClick={searchStudentByChallan}
        >
          Search By Challan
        </button>
        <div>
          <Table responsive>
            <thead>
              <tr>
                <th>Total payable</th>
                <th>Total Paid</th>
                <th>Net payable</th>
              </tr>
            </thead>
            {/* unpaidPayloadData */}

            {checkpaidclick ? (
              <tfoot>
                <tr>
                  <td style={{ textAlign: "start" }}>
                    {paidPayloadData["total payable"]}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {paidPayloadData["total paid"]}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {paidPayloadData["net payable"]}
                  </td>
                </tr>
              </tfoot>
            ) : checkunpaidclick ? (
              <tfoot>
                <tr>
                  <td style={{ textAlign: "start" }}>
                    {unpaidPayloadData["total payable"]}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {unpaidPayloadData["total paid"]}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {unpaidPayloadData["net payable"]}
                  </td>
                </tr>
              </tfoot>
            ) : null}
          </Table>
        </div>
        {/* <div style={{ display: "flex" }}></div> */}
        {/* <button
          className="btn btn-success button_blue button_blue"
          onClick={() => settestModal(true)}
        >
          Print test
        </button> */}
        <div>
          {printbtn ? (
            <div style={{ float: "right", marginRight: 50 }}>
              <button
                className="btn btn-success button_blue button_blue"
                onClick={() => setmonthlyPrintModal(true)}
              >
                Print Monthly
              </button>
              <button
                className="btn btn-success button_blue ml-5 button_blue"
                onClick={() => history.push("/printcampusunpaidchallans")}
              >
                Print Unpaid
              </button>
              <button
                className="btn btn-success button_blue ml-5 button_blue"
                onClick={() => history.push("/printcampuspaidchallans")}
              >
                Print paid
              </button>
            </div>
          ) : null}

          <Tabs
            onClick={(e) => {
              console.log(e.target);
              checkpayableamount(e);
            }}
            defaultActiveKey="active"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="active" title="Paid">
              {dataloading ? (
                <div className="row justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Admission Id</th>
                      <th>Student Class</th>
                      <th>Class Section</th>
                      <th>Challan No</th>
                      <th>Payable</th>
                      <th>Paid</th>
                      <th>Print</th>
                      <th>Delete</th>
                      <th>Receive Fee</th>
                      <th>Rollback</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((feechallan) => (
                      <tr key={feechallan.id}>
                        <td
                          title={feechallan.student_name}
                          style={{ textAlign: "start", cursor: "pointer" }}
                        >
                          {feechallan.student_name}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.student_admission_id}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.student_class}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.student_class_section}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.challan_no}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.payable}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.paid == null ? 0 : feechallan.paid}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              console.log("feechallan=>", feechallan);
                              openprinter(feechallan);
                            }}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Devices/Printer.svg"
                              )}
                            />
                          </span>
                        </td>

                        <td style={{ textAlign: "start" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => openchallandeleteModal(feechallan)}
                            className="svg-icon svg-icon-md svg-icon-danger"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </td>

                        <td style={{ textAlign: "start" }}>
                          <b
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              padding: 10,
                            }}
                          >
                            Received
                          </b>
                        </td>
                        <td style={{ textAlign: "start" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => openrollback(feechallan)}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Electric/Shutdown.svg"
                              )}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Tab>
            <Tab eventKey="unactive" title="Unpaid">
              {dataloading ? (
                <div className="row justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Student Name </th>
                      <th>Admission_id</th>
                      <th>Student Class</th>
                      <th>Class Section</th>
                      <th>Challan No</th>
                      <th>Payable</th>
                      <th>Paid</th>
                      <th>Print</th>
                      <th>Receive Fee</th>
                      <th>Edit</th>
                      <th>view</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_DATA.currentData().map((feechallan) => (
                      <tr key={feechallan.id}>
                        <td
                          title={feechallan.student_name}
                          style={{ textAlign: "start", cursor: "pointer" }}
                        >
                          {feechallan.student_name}
                        </td>

                        {/* <td style={{ textAlign: "start" }}>{feechallan.student_name}</td> */}
                        <td style={{ textAlign: "start" }}>
                          {feechallan.student_admission_id}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.student_class}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.student_class_section}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.challan_no}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.payable}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {feechallan.paid == null ? 0 : feechallan.paid}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => openprinter(feechallan)}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Devices/Printer.svg"
                              )}
                            />
                          </span>
                        </td>

                        <td style={{ textAlign: "start" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => openfeerecevingmodal(feechallan)}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Devices/Printer.svg"
                              )}
                            />
                          </span>
                        </td>
                        <td style={{ textAlign: "start" }}>
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-icon btn-light btn-hover-primary btn-sm "
                            onClick={() => {
                              setselectedobject(feechallan);
                              seteditmodal(true);
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </a>
                        </td>
                        <td>
                          <a
                            className="btn btn-icon btn-light btn-hover-primary btn-sm "
                            onClick={() => gotoviewpage(feechallan)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Devices/Display2.svg"
                                )}
                              />
                            </span>
                          </a>
                        </td>
                        <td style={{ textAlign: "start" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => openchallandeleteModal(feechallan)}
                            className="svg-icon svg-icon-md svg-icon-danger"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Tab>
          </Tabs>

          <CustomPagination
            onPaginate={(link) =>
              activePage == "Paid"
                ? onPaginatePaid(link)
                : onPaginateUnpaid(link)
            }
            links={
              activePage == "Paid"
                ? paidLinks
                : activePage == "Unpaid"
                ? unpaidLinks
                : []
            }
          />
          {/* 
          <Pagination
            color="primary"
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          /> */}
        </div>

        {/* delete challan  */}

        <Modal show={challandeleteModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete Challan
            </Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>
                    <b>You are going to delete challan</b>
                </Modal.Body> */}
          <Modal.Footer>
            <button onClick={deletechallan} className="btn button_blue">
              Delete
            </button>
            <button
              onClick={() => setchallandeleteModal(false)}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* //print modal show */}
        {printmodal ? (
          <Modal show={printmodal} size="lg">
            <div id="voucher">
              <Modal.Body>
                <div className="main__voucher">
                  {PRINTCOUNT.map((item, indexs) => (
                    <div key={indexs} className="voucher">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <img
                          src={icon}
                          style={{ height: 100, width: 100 }}
                          alt="icon"
                        />
                        <h3 style={{ color: "#B4959D", width: "40%" }}>
                          {unitName}
                        </h3>
                      </div>
                      <div className="redline"></div>
                      <div className="bolsection">
                        <div>
                          <div>Issue Date:</div>
                          <div>Due Date :</div>
                          <div>
                            {" "}
                            {selectedcard.student_admission_id == null
                              ? "Registration Id:"
                              : "Admission No:"}
                          </div>
                          <div>Name:</div>
                          <div>Class:</div>
                        </div>
                        <div>
                          <div>{getMonthName2(selectedcard.issue_date)}</div>
                          <div>{getMonthName2(selectedcard.due_date)}</div>
                          <div>
                            {selectedcard.student_admission_id == null
                              ? selectedcard.student.registration_id
                              : selectedcard.student_admission_id}
                          </div>
                          <div>{selectedcard.student_name}</div>
                          <div>{selectedcard.student_class}</div>
                        </div>
                      </div>
                      {console.log(selectedcard.fee_challan_details)}
                      {selectedcard.fee_challan_details.map((copy, index) => {
                        return (
                          <div>
                            <div className="bolsection printchallanfeesection">
                              <div style={{ fontSize: "smaller" }}>
                                {copy.fee_type_id == "23"
                                  ? "REMAINING BALANCE"
                                  : copy.fee_name}
                              </div>
                              <div style={{ fontSize: "smaller" }}>
                                {copy.amount}
                              </div>
                              {/* <div style={{ fontSize: "smaller" }}>
                                     {copy.fee_month}
                                   </div> */}
                            </div>
                          </div>
                        );
                      })}

                      <div className="lastsection">
                        <h5>Total Amount: {selectedcard.payable} </h5>
                      </div>
                      <div>
                        <Barcode value={selectedcard.challan_no} />,
                      </div>
                    </div>
                  ))}
                </div>
              </Modal.Body>
            </div>

            <Modal.Footer>
              {/* <button
                onClick={printdoc}
                id="printButton"
                className="btn btn-success button_blue button_blue"
              >
                Print
              </button> */}
              <ReactToPrint
                documentTitle="Challan Copy"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={loading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {loading ? "Printing..." : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
              <button
                id="cancelprintButton"
                onClick={() => setprintmodal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        ) : null}

        {/* print modal download */}

        <div style={{ visibility: "hidden" }}>
          {printmodal ? (
            <div ref={componentRef} id="printSection">
              <div className="my-print">
                {/* bank copy */}
                <div className="test my__print__1">
                  {selectedcard?.campus?.print_account_no &&
                    selectedcard?.campus?.print_account_no.map((acc) => (
                      <div className="text-center">
                        <b>{`${acc.bank_name}/${acc.account_number}`}</b>
                      </div>
                    ))}

                  <h6 className="text-center">{selectedcard.campus.name}</h6>

                  {/* form text content */}
                  <div className="text-form ml-2">
                    <div className="left-content">
                      <ul className="list-left">
                        <li>
                          <b>Due Date:</b>
                        </li>
                        <li>
                          <b>Issue Date:</b>
                        </li>
                        <li>
                          <b>Admission:</b>
                        </li>
                        <li>
                          <b style={{ height: 27 }}>Name:</b>
                        </li>
                        <br></br>
                        <li>
                          <b>Class:</b>
                        </li>
                      </ul>
                    </div>
                    <div className="right-content">
                      <ul className="list-right">
                        <li>{getMonthName2(selectedcard.due_date)}</li>
                        <li>{getMonthName2(selectedcard.issue_date)}</li>
                        <li className="BAL">
                          <b>
                            {selectedcard.student_admission_id == null
                              ? selectedcard.student.registration_id
                              : selectedcard.student_admission_id}
                          </b>
                        </li>
                        <li>
                          <p style={{ height: 27 }}>
                            {selectedcard.student_name}
                            <br></br>
                            {selectedcard?.student?.gender == "Male"
                              ? "s/o"
                              : "d/o"}{" "}
                            {selectedcard?.student?.father_name}
                          </p>
                        </li>
                        <li>
                          {" "}
                          {selectedcard.student_class +
                            " (" +
                            selectedcard?.student_class_section +
                            ")"}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="print-copy">
                    <table class="print__table">
                      <tr>
                        <th colspan="2">
                          <b>Inv No</b>
                        </th>
                        <th colspan="2" className="text-center">
                          <b>Description</b>
                        </th>
                        <th>
                          <b>Amount</b>
                        </th>
                      </tr>
                      <tbody>
                        {selectedcard.fee_challan_details.map((copy, index) => (
                          <tr>
                            <td>{selectedcard.challan_no}</td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td>{copy.amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="total__bottom"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <h3>Total Amount</h3>
                    <b
                      className="btn btn-white"
                      style={{ border: "1px solid black" }}
                    >
                      {selectedcard.payable}
                    </b>
                  </div>
                </div>
                {/* office copy */}
                <div className="test my__print__2">
                  {selectedcard?.campus?.print_account_no &&
                    selectedcard?.campus?.print_account_no.map((acc) => (
                      <div className="text-center">
                        <b>{`${acc.bank_name}/${acc.account_number}`}</b>
                      </div>
                    ))}

                  <h6 className="text-center">{selectedcard.campus.name}</h6>

                  <div className="text-form ml-2">
                    <div className="left-content">
                      <ul className="list-left">
                        <li>
                          <b>Due Date:</b>
                        </li>
                        <li>
                          <b>Issue Date:</b>
                        </li>
                        <li>
                          <b>Admission:</b>
                        </li>
                        <li>
                          <b style={{ height: 27 }}>Name:</b>
                        </li>
                        <br></br>
                        <li>
                          <b>Class:</b>
                        </li>
                      </ul>
                    </div>
                    <div className="right-content">
                      <ul className="list-right">
                        <li>{getMonthName2(selectedcard.due_date)}</li>
                        <li>{getMonthName2(selectedcard.issue_date)}</li>
                        <li className="BAL">
                          <b>
                            {selectedcard.student_admission_id == null
                              ? selectedcard.student.registration_id
                              : selectedcard.student_admission_id}
                          </b>
                        </li>
                        <li>
                          <p style={{ height: 27 }}>
                            {selectedcard.student_name}
                            <br></br>
                            {selectedcard?.student?.gender == "Male"
                              ? "s/o"
                              : "d/o"}{" "}
                            {selectedcard?.student?.father_name}
                          </p>
                        </li>
                        <li>
                          {" "}
                          {selectedcard.student_class +
                            " (" +
                            selectedcard?.student_class_section +
                            ")"}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="print-copy">
                    <table class="print__table">
                      <tr>
                        <th colspan="2">
                          <b>Inv No</b>
                        </th>
                        <th colspan="2" className="text-center">
                          <b>Description</b>
                        </th>
                        <th>
                          <b>Amount</b>
                        </th>
                      </tr>
                      <tbody>
                        {selectedcard.fee_challan_details.map((copy, index) => (
                          <tr>
                            <td>{selectedcard.challan_no}</td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td>{copy.amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="total__bottom"
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <h3>Total Amount</h3>

                    <b
                      className="btn btn-white"
                      style={{ border: "1px solid black" }}
                    >
                      {selectedcard.payable}
                    </b>
                  </div>
                </div>
                {/* student copy */}
                <div className="test my__print__3">
                  {selectedcard?.campus?.print_account_no &&
                    selectedcard?.campus?.print_account_no.map((acc) => (
                      <div className="text-center">
                        <b>{`${acc.bank_name}/${acc.account_number}`}</b>
                      </div>
                    ))}

                  <h6 className="text-center">{selectedcard.campus.name}</h6>

                  <div className="text-form ml-2">
                    <div className="left-content">
                      <ul className="list-left">
                        <li>
                          <b>Due Date:</b>
                        </li>
                        <li>
                          <b>Issue Date:</b>
                        </li>
                        <li>
                          <b>Admission:</b>
                        </li>
                        <li>
                          <b style={{ height: 27 }}>Name:</b>
                        </li>
                        <br></br>
                        <li>
                          <b>Class:</b>
                        </li>
                      </ul>
                    </div>
                    <div className="right-content">
                      <ul className="list-right">
                        <li>{getMonthName2(selectedcard.due_date)}</li>
                        <li>{getMonthName2(selectedcard.issue_date)}</li>
                        <li className="BAL">
                          <b>
                            {selectedcard.student_admission_id == null
                              ? selectedcard.student.registration_id
                              : selectedcard.student_admission_id}
                          </b>
                        </li>
                        <li>
                          <p style={{ height: 27 }}>
                            {selectedcard.student_name}
                            <br></br>
                            {selectedcard?.student?.gender == "Male"
                              ? "s/o"
                              : "d/o"}{" "}
                            {selectedcard?.student?.father_name}
                          </p>
                        </li>
                        <li>
                          {" "}
                          {selectedcard.student_class +
                            " (" +
                            selectedcard?.student_class_section +
                            ")"}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="print-copy">
                    <table class="print__table">
                      <tr>
                        <th colspan="2">
                          <b>Inv No</b>
                        </th>
                        <th colspan="2" className="text-center">
                          <b>Description</b>
                        </th>
                        <th>
                          <b>Amount</b>
                        </th>
                      </tr>
                      <tbody>
                        {selectedcard.fee_challan_details.map((copy, index) => (
                          <tr>
                            <td>{selectedcard.challan_no}</td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              {copy.fee_type_id == "23"
                                ? "REMAINING BALANCE"
                                : copy.fee_name}{" "}
                              {getMonthName3(copy?.fee_month)}
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td>{copy.amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="total__bottom"
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <h3>Total Amount</h3>
                    <b
                      className="btn btn-white"
                      style={{ border: "1px solid black" }}
                    >
                      {" "}
                      {selectedcard.payable}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {/* fee recive modal */}

        <Modal show={feerecmodal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Receive Fee - {selectedchallan.student_name}{" "}
              {selectedchallan.student_admission_id}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {feeReceviceLoading ? (
              <b>
                Fee Receiving Please Wait{" "}
                <span className="spinner-border"></span>{" "}
              </b>
            ) : (
              <FeeReceviceModal
                // setpostpond={(val) => setpostpond(val)}
                // setonlyChallan={(val) => setonlyChallan(val)}
                // postpond={postpond}
                // onlyChallan={onlyChallan}
                selectedtype={selectedtype}
                setselectedtype={(val) => setselectedtype(val)}
                lateFine={lateFine}
                setlatefine={setlatefine}
                setreadmissionFee={setreadmissionFee}
                readmissionFee={readmissionFee}
                recevingdate={recevieddate}
                setrecevingdate={setrecevieddate}
                selectedbank={feerecevingbank}
                setselectedbank={setfeerecevingbank}
                onchangeDate={(value) => {
                  if (value != "") {
                    setisfeereceivedata(false);
                  } else {
                    setisfeereceivedata(true);
                  }
                  setrecevieddate(value);
                }}
                data={selectedchallan}
                selectedfeerecevingbank={feerecevingbank}
                feerecevingbank={(value) => {
                  if (value != "") {
                    setisfeereceivedata(false);
                  } else {
                    setisfeereceivedata(true);
                  }
                  setfeerecevingbank(value);
                }}

                // feerecevingbank={setfeerecevingbank}
              />
            )}
          </Modal.Body>
          {isfeereceivedata ? (
            <>
              <Alert
                variant="danger"
                onClose={() => setisfeereceivedata(false)}
                dismissible
              >
                <Alert.Heading style={{ textAlign: "start" }}>
                  Oops! You got an error!
                </Alert.Heading>
                <p style={{ textAlign: "start" }}>Please Fill All Fields</p>
              </Alert>
            </>
          ) : null}
          <Modal.Footer>
            <button
              onClick={feeReceiceHandler}
              className="btn btn-success button_blue button_blue"
            >
              Received
              {feeReceviceLoading && <span className="spinner-border"></span>}
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setisfeereceivedata(false);
                // setrecevieddate("")
                setfeerecmodal(false);
                setfeerecevingbank("");
                setrecevieddate("");
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Roll Back modal */}

        <Modal show={rollbackModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure you want to Roll Back?
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <button
              onClick={rollBackHandler}
              className="btn btn-success button_blue"
            >
              Roll Back
              {rollbackLoading && <span className="spinner-border"></span>}
            </button>
            <button
              onClick={() => setrollbackModal(false)}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        {/* //edit modal */}
        <Modal show={editmodal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Edit Campus Fee Challan
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditCampusFeeChallan
              seectedchallan={selectedobject}
              show={editmodal}
              onHide={seteditmodal}
              campusId={previousselectedcampus}
              classid={campus_class_id}
              sectionid={campus_class_section_id}
              reload={reloadFunction}
            />
          </Modal.Body>
        </Modal>

        {/* <Modal show={editmodal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              View Detail
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditCampusFeeChallan
              seectedchallan={selectedobject}
              show={editmodal}
              onHide={seteditmodal}
              campusId={campusId}
              reload={middleFunction}
            />
          </Modal.Body>
        </Modal> */}

        {viewmodal ? (
          <ViewModal
            show={viewmodal}
            onHide={setviewmodal}
            feedetail={selectedchallan}
            setchallandetails={setselectedchallan}
            setfeemodal={setfeerecmodal}
            reloadFunction={reloadFunction}
          />
        ) : null}

        <Modal
          size="xl"
          onHide={() => setcombineModal(false)}
          show={combineModal}
        >
          <Modal.Header>
            <Modal.Title>Fee Receive Student Wise</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row col-lg-12">
              <Table>
                <thead>
                  <tr id="tbl">
                    <th>Name: {selecetedStudentForCombine.name}</th>
                    <th>
                      Admission ID: {selecetedStudentForCombine.admission_id}
                    </th>
                    <th>Payable: Rs.{totalAmount}/-</th>
                  </tr>
                </thead>
              </Table>

              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th>Fee Challan Id</th>
                    <th>Fee Month</th>
                    <th>Fee Name</th>
                    <th>Due Date</th>
                    <th>Amount</th>
                    <th>Edit</th>
                    {/* <th>Delete</th>
                    <th>Print</th> */}

                    {/* <th>Stuck Off</th> */}
                  </tr>
                </thead>

                <tbody>
                  {feechallansDetailList &&
                    feechallansDetailList.map((fdetail) => (
                      <tr>
                        <td className="text_align_start">
                          {fdetail?.fee_challan?.challan_no}
                        </td>

                        <td className="text_align_start">
                          {getMonthName(fdetail.fee_month)}
                        </td>
                        <td className="text_align_start">{fdetail.fee_name}</td>
                        <td className="text_align_start">
                          {fdetail?.fee_challan?.due_date}
                        </td>
                        <td className="text_align_start">{fdetail.amount}</td>

                        <td className="text_align_start">
                          <span
                            onClick={() => openEditSingleFee(fdetail)}
                            style={{ cursor: "pointer" }}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </td>
                        {/* <td style={{ textAlign: "start" }}>
                          <span

                            onClick={() => openDeleteSingleFee(fdetail)}
                            style={{ cursor: 'pointer' }}
                            className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                            />
                          </span>
                        </td> */}
                        {/* <td style={{ textAlign: "start" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // console.log("feechallan=>", feechallan);
                              // openprinter(feechallan);
                            }}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Devices/Printer.svg"
                              )}
                            />
                          </span>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </Table>

              <div className="form-group col-sm-6 flex-column d-flex">
                <label className="form-control-label">
                  <b>
                    Select Bank Category{" "}
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </b>
                </label>
                <select
                  className="form-select form-control"
                  name="sub_account_id"
                  value={selectedbankcateogry}
                  onChange={(e) => {
                    setselectedbankcateogry(e.target.value);
                    // dispatch({
                    //   type: "CHALLAN_BANK_CATEGORY",
                    //   payload: {
                    //     selectedbankcategory_red: e.target.value,
                    //   },
                    // });
                    console.log(allbanks);
                    let anserr = allbanks.filter(
                      (item) => item.id == e.target.value
                    );

                    if (anserr.length != 0) {
                      setallsubaccounts([...anserr[0].bank_accounts]);
                    } else {
                      setallsubaccounts([]);
                    }
                  }}
                  style={{ backgroundColor: "white" }}
                  aria-label="Default select example"
                >
                  <option acode="" selected value="">
                    Select...
                  </option>
                  {allbanks.map((item) => (
                    <option value={item.id}>{item.title}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-6 flex-column d-flex ">
                <label className="form-control-label">
                  <b>
                    Select Bank <span style={{ color: "red" }}>*</span>
                  </b>
                </label>
                <select
                  className="form-select form-control"
                  name="sub_account_id"
                  value={selectedbank}
                  onChange={(e) => {
                    setselectedbank(e.target.value);
                    // dispatch({
                    //   type: "CHALLAN_BANK",
                    //   payload: {
                    //     selectedbank_red: e.target.value,
                    //   },
                    // });
                    console.log(e.target.value);
                    setfeerecevingbank(e.target.value);
                  }}
                  style={{ backgroundColor: "white" }}
                  aria-label="Default select example"
                >
                  <option acode="" selected value="">
                    Select...
                  </option>
                  {allsubaccounts.map((item) => (
                    <option value={item.id}>{item.bank_name}</option>
                  ))}
                </select>
              </div>

              <div className="form-group col-sm-6 flex-column d-flex">
                <label className="form-control-label">
                  <b>
                    Received Date <span style={{ color: "red" }}>*</span>
                  </b>
                </label>
                <input
                  value={recevingdate}
                  defaultValue={recevingdate}
                  onChange={(e) => {
                    setrecevingdate(e.target.value);
                    // onchangeDate(e.target.value);
                  }}
                  type="date"
                  max="9999-11-30"
                  className="form-control"
                />
              </div>
            </div>

            <hr />
            <h3 className="text-center">
              <b>Past Challan Details</b>
            </h3>
            <div
              style={{ width: "450px", height: "400px", overflow: "scroll" }}
            >
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ fontSize: 10 }} className="text-center">
                      Fee Challan Id
                    </th>
                    <th style={{ fontSize: 10 }} className="text-center">
                      Fee Month
                    </th>
                    <th style={{ fontSize: 10 }} className="text-center">
                      Fee Name
                    </th>
                    <th style={{ fontSize: 10 }} className="text-center">
                      Amount
                    </th>
                    <th style={{ fontSize: 10 }} className="text-center">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {past6monthsChallans &&
                    past6monthsChallans.map((challan) => (
                      <tr>
                        <td style={{ fontSize: 10 }} className="text-center">
                          {challan.fee_challan_id}
                        </td>
                        <td style={{ fontSize: 10 }} className="text-center">
                          {getMonthName(challan.fee_month)}
                        </td>
                        <td style={{ fontSize: 10 }} className="text-center">
                          {challan.fee_name}
                        </td>
                        <td style={{ fontSize: 10 }} className="text-center">
                          {challan.amount}
                        </td>
                        <td
                          style={{
                            fontSize: 10,
                            color:
                              challan?.fee_challan.status == 0
                                ? "Red"
                                : challan?.fee_challan.status == 1
                                ? "orange"
                                : challan?.fee_challan.status == 2
                                ? "Green"
                                : "",
                          }}
                          className="text-center"
                        >
                          {challan?.fee_challan.status == 0 ? "UnPaid" : "Paid"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={submitFeeStudentWise} className="btn btn-primary">
              Submit Fee
            </button>
            <button
              onClick={() => setcombineModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>

      <MonthlyChallanModal
        hide={() => setmonthlyPrintModal(false)}
        show={monthlyPrintModal}
      />

      <EditSingleFee
        reload={() => searchStudent()}
        show={singlefeemodal}
        close={() => setsinglefeemodal(false)}
        feeObject={singleFeeobject}
      />

      <DeleteSingleFee
        show={singleDeletefeemodal}
        close={() => setsingleDeletefeemodal(false)}
        id={singleFeeobject.id}
      />
      <PrintChallanModal
        ref={componentRef}
        show={testModal}
        hideModal={() => settestModal(false)}
      />
    </div>
  );
}

export default CampusFeeChallan;
