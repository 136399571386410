import React, { useEffect, useState, useRef } from "react";
import { Divider } from "antd";
import { Hidden, LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { DatePicker, Space } from "antd";
import { Spinner, Table, Button, Modal } from "react-bootstrap";
import { upperCase } from "lodash";
const { RangePicker } = DatePicker;
const pageStyle = `
  @page {
    size: A4 !important;
}
`;
const StudentMissingInfo = () => {
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  const [reportClassData, setreportClassData] = useState([]);

  let authtoken = localStorage.getItem("userToken");

  var dd = "0";
  var ee = "0";
  const [missingInfoTypes, setMissingInfoTypes] = useState([1]);

  const middleFunction = (id) => {
    setMissingInfoTypes(id);
    var sel = document.getElementById("cic");
  };
  var i = 0;

  useEffect(() => {
    getSettingData();
  }, []);

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShow = () => {
    console.log(missingInfoTypes);
    setLoading(true);
    fetch(
      `${baseUrl}/student-missing-information-report?missingInfoType=${missingInfoTypes}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data.student_Details);
        console.log(data.classes_sections);
        setreportData(data.student_Details);
        setreportClassData(data.classes_sections);

        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data.payload);
            setreportData(data.hoste_fees);
            setreportClassData(data.classes_sections);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
    console.log(reportData);
  };

  const printingDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row mt-3">
          <div className="col-md-4">
            <div>
              <select
                id="cic"
                className="form-select"
                style={{ height: "40px" }}
                onChange={(e) => middleFunction(e.target.value)}
              >
                <option value={1}>Missing B-Form</option>
                <option value={2}>Missing Father CNIC</option>
                <option value={3}>Missing Father Mobile No</option>
              </select>
            </div>
          </div>
          <div className="col-md-2 d-flex">
            <button onClick={handleShow} className="btn btn-primary mr-4">
              Show
            </button>
            {reportData.length > 0 ? (
              <ReactToPrint
                documentTitle={`salary slip Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                pageStyle={pageStyle}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            padding: "15px",
          }}
        >
          <div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Admin ID</th>
                  <th>Student Name</th>
                  <th>Father Name</th>
                  <th>Mobile No</th>
                  <th>B-Form</th>
                  <th>Class</th>
                  <th>Father CNIC</th>
                  <th>E-Mail</th>
                </tr>
              </thead>

              {reportData.map((campus) => {
                return (
                  <tbody>
                    <tr
                      style={{
                        Align: "center",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      {/* {(() => {
                          var ff = campus.student_class_id;

                          if (ee !== ff) {
                            ee = campus.student_class_id;
                            return (
                              <td
                                colSpan={4}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <b>{campus.student_class.name}</b>
                              </td>
                            );
                          }
                        })()} */}

                      {(() => {
                        var cc = campus.global_section_id;

                        if (cc !== dd) {
                          dd = campus.global_section_id;
                          return (
                            <>
                              <td
                                colSpan={4}
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                              >
                                <b>
                                  {campus.student_class.name}
                                  {/* {campus.global_section.name} */}
                                </b>
                              </td>
                              <td
                                colSpan={5}
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                              >
                                <b>
                                  {/* {campus.student_class.name}- */}
                                  {campus.global_section.name}
                                </b>
                              </td>
                            </>
                          );
                        }
                      })()}
                    </tr>

                    <tr>
                      <td>{(i = i + 1)}</td>
                      <td>{campus.admission_id}</td>
                      <td>{campus.name}</td>
                      <td>{campus.father_name}</td>
                      <td>{campus.mobile_no}</td>

                      <td>{campus.cnic_no}</td>
                      <td>
                        {campus.student_class.name}-{campus.global_section.name}
                      </td>
                      <td>{campus.father_cnic}</td>
                      <td></td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </div>
        </div>

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div
            className="mainContainer"
            style={{
              margin: "10px",
              // border: "2px solid black",
              height: "1530px",
            }}
          >
            <div className="logocontainer" style={{ height: "130px" }}>
              <div className="logocontainer" style={{ height: "100px" }}>
                <div className="d-flex">
                  <div className="">
                    <img
                      style={{ height: 100, width: 110, paddingLeft: "20px" }}
                      src={icon}
                      alt="logo"
                    />
                  </div>
                  <div style={{ padding: "10px" }}>
                    <h1>{unitName}</h1>
                    <h5> Student Missing Information Report</h5>
                    <h5> Printing Date:- &nbsp; {printingDate()}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Table
                style={{
                  fontSize: "15px",
                  whiteSpace: "normal",
                  padding: "1px",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        whiteSpace: "normal",
                        padding: "1px",
                        textAlign: "center",
                      }}
                    >
                      Sr No
                    </th>
                    <th
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        whiteSpace: "normal",
                        padding: "1px",
                        textAlign: "center",
                      }}
                    >
                      Admin ID
                    </th>
                    <th
                      style={{
                        fontSize: "15px",
                        width: "20%",
                        whiteSpace: "normal",
                        padding: "1px",
                        textAlign: "center",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        fontSize: "15px",
                        width: "20%",
                        whiteSpace: "normal",
                        padding: "1px",
                        textAlign: "center",
                      }}
                    >
                      Father Name
                    </th>
                    <th
                      style={{
                        fontSize: "15px",
                        width: "10%",
                        whiteSpace: "normal",
                        padding: "1px",
                        textAlign: "center",
                      }}
                    >
                      Mobile No
                    </th>
                    <th
                      style={{
                        fontSize: "15px",
                        whiteSpace: "normal",
                        padding: "1px",
                        textAlign: "center",
                        width: "10%",
                      }}
                    >
                      B-Form
                    </th>
                    {/* <th
                      style={{
                        fontSize: "15px",
                        width: "10%",
                        whiteSpace: "normal",
                        padding: "1px",
                        Align: "center",
                      }}
                    >
                      Class
                    </th> */}
                    <th
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        whiteSpace: "normal",
                        padding: "1px",
                        textAlign: "center",
                      }}
                    >
                      Father CNIC
                    </th>
                    <th
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        whiteSpace: "normal",
                        padding: "1px",
                        textAlign: "center",
                      }}
                    >
                      E-Mail
                    </th>
                  </tr>
                </thead>
                {(() => {
                  i = 1;
                })()}
                {reportData.map((campus) => {
                  return (
                    <tbody>
                      <tr
                        style={{
                          Align: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {/* {(() => {
                          var ff = campus.student_class_id;

                          if (ee !== ff) {
                            ee = campus.student_class_id;
                            return (
                              <td
                                colSpan={4}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <b>{campus.student_class.name}</b>
                              </td>
                            );
                          }
                        })()} */}

                        {(() => {
                          var cc = campus.global_section_id;

                          if (cc !== dd) {
                            dd = campus.global_section_id;
                            return (
                              <>
                                <td
                                  colSpan={4}
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                >
                                  <b>
                                    {campus.student_class.name}
                                    {/* {campus.global_section.name} */}
                                  </b>
                                </td>
                                <td
                                  colSpan={5}
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                >
                                  <b>
                                    {/* {campus.student_class.name}- */}
                                    {campus.global_section.name}
                                  </b>
                                </td>
                              </>
                            );
                          }
                        })()}
                      </tr>

                      <tr style={{ whiteSpace: "normal", padding: "1px" }}>
                        <td style={{ whiteSpace: "normal", padding: "1px" }}>
                          {(i = i + 1)}
                        </td>
                        <td style={{ whiteSpace: "normal", padding: "1px" }}>
                          {campus.admission_id}
                        </td>
                        <td style={{ whiteSpace: "normal", padding: "1px" }}>
                          {/* {campus.name} */}
                          {campus.name.length > 20
                            ? campus.name.substring(0, 20) + "..."
                            : campus.name}
                        </td>
                        <td style={{ whiteSpace: "normal", padding: "1px" }}>
                          {/* {campus.father_name} */}
                          {campus.father_name.length > 20
                            ? campus.father_name.substring(0, 20) + "..."
                            : campus.father_name}
                        </td>
                        <td style={{ whiteSpace: "normal", padding: "1px" }}>
                          {" "}
                          {campus.mobile_no}
                        </td>

                        <td style={{ whiteSpace: "normal", padding: "1px" }}>
                          {campus.cnic_no}
                        </td>
                        {/* <td style={{ whiteSpace: "normal", padding: "1px" }}>
                          {campus.student_class.name}-
                          {/* {campus.global_section.name} */}
                        {/* {campus.global_section.name.length > 5
                            ? campus.global_section.name.substring(0, 3)
                            : campus.global_section.name}
                        </td> */}
                        <td style={{ whiteSpace: "normal", padding: "1px" }}>
                          {campus.father_cnic}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  );
                })}{" "}
              </Table>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default StudentMissingInfo;
