import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../styles.css";
import Barcode from "react-barcode";
import { baseUrl } from "../../../../services/config";
import { upperCase } from "lodash";

export default function PrintCampusPaidChallans() {
  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const feechallans = useSelector((state) => state.campuses.campuspaidchallans);
  console.log(feechallans);

  const getFeeAmount = (challans) => {
    let sum = 0;

    challans.forEach((element) => {
      sum += parseInt(element.amount);
    });

    return sum;
    // const sum = challans.reduce((accumulator, object) => {
    //   return accumulator + object.amount;
    // }, 0);
    // console.log(sum)
  };

  function printDiv() {
    var divContents = document.getElementById("printSection").innerHTML;
    var a = window.open("", "", "height=500, width=500");
    a.document.write("<html>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  }

  const printdoc = () => {
    // document.getElementById("printButton").onclick = function () {

    // }

    function printElement(elem) {
      var $printSection = document.getElementById("printSection");

      if (!$printSection) {
        var $printSection = document.createElement("div");
        $printSection.id = "printSection";
        document.body.appendChild($printSection);
      }

      $printSection.innerHTML = elem;

      window.print();
    }

    printElement(document.getElementById("printSection").innerHTML);
  };

  return (
    <div>
      <button
        onClick={printdoc}
        className="btn btn-success button_blue button_blue"
      >
        Print
      </button>

      <div>
        {feechallans.map((item) => (
          <div className="row-printer">
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">
                <h1>Bank Copy</h1>
              </div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>
            {/* 2nd voucher */}
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">
                <h1>Office Copy</h1>
              </div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>
            {/* 3rd voucher */}
            <div className="voucher">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={icon}
                  style={{ height: 100, width: 100 }}
                  alt="icon"
                />
                <p style={{ color: "#B4959D", width: "40%" }}>{unitName}</p>
              </div>
              <div className="redline"></div>
              <div className="bolsection">
                <div>
                  <h6>Issue Date:</h6>
                  <h6>Due Date :</h6>
                  <h6>Admission No:</h6>
                  <h6>Name:</h6>
                  <h6>Class:</h6>
                  <h6>Amount:</h6>
                </div>
                <div>
                  <h6>{item.issue_date}</h6>
                  <h6>{item.due_date}</h6>
                  <h6>{item.student_admission_id}</h6>
                  <h6>{item.student_name}</h6>
                  <h6>{item.student_class}</h6>
                  <h6>{item.payable}</h6>
                </div>
              </div>

              {/* //end */}
              <div className="redbox">
                <h1>Student Copy</h1>
              </div>

              <div className="lastsection">
                <h5>Total Amount: {item.payable} </h5>
              </div>
              <div>
                <Barcode option value={item.challan_no} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div id="printSection" style={{ visibility: "hidden" }}>
        {feechallans.map((selectedStudent) => (
          <div style={{ height: 1570, width: 400 }} id="voucher-print">
            <div class="main__voucher">
              <page size="A3" id="page">
                <div class="main_parent_div">
                  <div class="voucherp2 print_copy_1">
                    <div className="print_top_sec">
                      <div class="voucher-header"></div>

                      <br />
                      <br />
                      <div class="detailsection">
                        <div className="detailsection_detail_bank"></div>
                        <div className="detailsection_detail">
                          <div className="detailsection_detail_name ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Due Date:
                            </b>
                          </div>
                          <div className="detailsection_detail_value font_16 ">
                            {selectedStudent?.issue_date}
                          </div>
                        </div>

                        <div className="detailsection_detail">
                          <div className="detailsection_detail_name ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Issue Date:
                            </b>
                          </div>
                          <div className="detailsection_detail_value font_16 ">
                            {selectedStudent?.due_date}
                          </div>
                        </div>
                        <br />

                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              {selectedStudent?.student_admission_id == null
                                ? "Registration Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              {selectedStudent?.student_admission_id == null
                                ? selectedStudent?.registration_id
                                : selectedStudent?.student_admission_id}
                            </b>
                          </div>
                        </div>
                        <br />
                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Name :
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            {selectedStudent?.student_name} s/0
                            <br />
                            {selectedStudent?.student?.father_name}
                          </div>
                        </div>

                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Class :
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            {selectedStudent?.student_class +
                              " (" +
                              selectedStudent?.student_class_section +
                              ")"}
                          </div>
                        </div>

                        <div
                          style={{ paddingTop: "23px" }}
                          class="detailsection_detail"
                        >
                          <div class="fee_detail_no">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Inv No
                            </b>
                          </div>
                          <div class="fee_detail_des ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Description
                            </b>
                          </div>
                          <div class="fee_detail_amount ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Amount
                            </b>
                          </div>
                        </div>

                        {selectedStudent.fee_challan_details.map(
                          (copy, index) => {
                            return (
                              <div class="detailsection_detail">
                                <div class="fee_detail_no font_16">
                                  {copy.fee_challan_id}
                                </div>
                                <div class="fee_detail_des font_16 ">
                                  {copy.fees_type_id == "23"
                                    ? "REMAINING BALANCE"
                                    : copy.fee_name}
                                  <br />

                                  {" (" + copy?.fee_month + ")"}
                                </div>
                                <div class="fee_detail_amount font_16 ">
                                  {copy.amount}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>

                    <div className="print_footer_sec">
                      <div class="detailsection_detail_foot">
                        <div
                          class="detailsection_detail_name_foot"
                          style={{ margin: "0px", fontSize: "19px" }}
                        >
                          <b
                            className="font_16 "
                            style={{ margin: "0px", fontSize: "19px" }}
                          >
                            Total Amount:
                          </b>
                        </div>
                        <div class="detailsection_detail_value_foot">
                          <b
                            className="font_16 foot_amount_style"
                            style={{ margin: "0px", fontSize: "19px" }}
                          >
                            {getFeeAmount(selectedStudent?.fee_challan_details)}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="voucherp2 print_copy_2">
                    <div className="print_top_sec">
                      <div class="voucher-header"></div>

                      <br />
                      <br />
                      <div class="detailsection">
                        <div className="detailsection_detail_bank"></div>
                        <div className="detailsection_detail">
                          <div className="detailsection_detail_name ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Due Date:
                            </b>
                          </div>
                          <div className="detailsection_detail_value font_16 ">
                            {selectedStudent?.issue_date}
                          </div>
                        </div>

                        <div className="detailsection_detail">
                          <div className="detailsection_detail_name ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Issue Date:
                            </b>
                          </div>
                          <div className="detailsection_detail_value font_16 ">
                            {selectedStudent?.due_date}
                          </div>
                        </div>
                        <br />

                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              {selectedStudent?.student_admission_id == null
                                ? "Registration Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              {selectedStudent?.student_admission_id == null
                                ? selectedStudent?.registration_id
                                : selectedStudent?.student_admission_id}
                            </b>
                          </div>
                        </div>
                        <br />
                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Name :
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            {selectedStudent?.student_name} s/0
                            <br />
                            {selectedStudent?.father_name}
                          </div>
                        </div>

                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Class :
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            {selectedStudent?.student_class +
                              " (" +
                              selectedStudent?.student_class_section +
                              ")"}
                          </div>
                        </div>

                        <div
                          style={{ paddingTop: "23px" }}
                          class="detailsection_detail"
                        >
                          <div class="fee_detail_no">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Inv No
                            </b>
                          </div>
                          <div class="fee_detail_des ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Description
                            </b>
                          </div>
                          <div class="fee_detail_amount ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Amount
                            </b>
                          </div>
                        </div>

                        {selectedStudent.fee_challan_details.map(
                          (copy, index) => {
                            return (
                              <div class="detailsection_detail">
                                <div class="fee_detail_no font_16">
                                  {copy.fee_challan_id}
                                </div>
                                <div class="fee_detail_des font_16 ">
                                  {copy.fees_type_id == "23"
                                    ? "REMAINING BALANCE"
                                    : copy.fee_name}
                                  <br />

                                  {" (" + copy?.fee_month + ")"}
                                </div>
                                <div class="fee_detail_amount font_16 ">
                                  {copy.amount}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>

                    <div className="print_footer_sec">
                      <div class="detailsection_detail_foot">
                        <div
                          class="detailsection_detail_name_foot"
                          style={{ margin: "0px", fontSize: "19px" }}
                        >
                          <b
                            className="font_16 "
                            style={{ margin: "0px", fontSize: "19px" }}
                          >
                            Total Amount:
                          </b>
                        </div>
                        <div class="detailsection_detail_value_foot">
                          <b
                            className="font_16 foot_amount_style"
                            style={{ margin: "0px", fontSize: "19px" }}
                          >
                            {getFeeAmount(selectedStudent?.fee_challan_details)}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="voucherp2 print_copy_3">
                    <div className="print_top_sec">
                      <div class="voucher-header"></div>

                      <br />
                      <br />
                      <div class="detailsection">
                        <div className="detailsection_detail_bank"></div>
                        <div className="detailsection_detail">
                          <div className="detailsection_detail_name ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Due Date:
                            </b>
                          </div>
                          <div className="detailsection_detail_value font_16 ">
                            {selectedStudent?.issue_date}
                          </div>
                        </div>

                        <div className="detailsection_detail">
                          <div className="detailsection_detail_name ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Issue Date:
                            </b>
                          </div>
                          <div className="detailsection_detail_value font_16 ">
                            {selectedStudent?.due_date}
                          </div>
                        </div>
                        <br />

                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              {selectedStudent?.student_admission_id == null
                                ? "Registration Id:"
                                : "Admission No:"}
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              {selectedStudent?.student_admission_id == null
                                ? selectedStudent?.registration_id
                                : selectedStudent?.student_admission_id}
                            </b>
                          </div>
                        </div>
                        <br />
                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name font_16">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Name :
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            {selectedStudent?.student_name} s/0
                            <br />
                            {selectedStudent?.student?.father_name}
                          </div>
                        </div>

                        <div class="detailsection_detail">
                          <div class="detailsection_detail_name">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Class :
                            </b>
                          </div>
                          <div class="detailsection_detail_value font_16">
                            {selectedStudent?.student_class +
                              " (" +
                              selectedStudent?.student_class_section +
                              ")"}
                          </div>
                        </div>

                        <div
                          style={{ paddingTop: "23px" }}
                          class="detailsection_detail"
                        >
                          <div class="fee_detail_no">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Inv No
                            </b>
                          </div>
                          <div class="fee_detail_des ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Description
                            </b>
                          </div>
                          <div class="fee_detail_amount ">
                            <b className="font_16" style={{ margin: "0px" }}>
                              Amount
                            </b>
                          </div>
                        </div>

                        {selectedStudent.fee_challan_details.map(
                          (copy, index) => {
                            return (
                              <div class="detailsection_detail">
                                <div class="fee_detail_no font_16">
                                  {copy.fee_challan_id}
                                </div>
                                <div class="fee_detail_des font_16 ">
                                  {copy.fees_type_id == "23"
                                    ? "REMAINING BALANCE"
                                    : copy.fee_name}
                                  <br />

                                  {" (" + copy?.fee_month + ")"}
                                </div>
                                <div class="fee_detail_amount font_16 ">
                                  {copy.amount}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>

                    <div className="print_footer_sec">
                      <div class="detailsection_detail_foot">
                        <div
                          class="detailsection_detail_name_foot"
                          style={{ margin: "0px", fontSize: "19px" }}
                        >
                          <b
                            className="font_16 "
                            style={{ margin: "0px", fontSize: "19px" }}
                          >
                            Total Amount:
                          </b>
                        </div>
                        <div class="detailsection_detail_value_foot">
                          <b
                            className="font_16 foot_amount_style"
                            style={{ margin: "0px", fontSize: "19px" }}
                          >
                            {getFeeAmount(selectedStudent?.fee_challan_details)}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </page>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
