import React, { useEffect, useState } from "react";
import { Table, Modal, Tab, Tabs, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useSelector, useDispatch } from "react-redux";
import campus from "../../../components/images/campus.png";
import Pagination from "../../../components/Pagination/Pagination";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import "./style.css";
import Paper from "@material-ui/core/Paper";
import { upperCase } from "lodash";

const CardIssueStudents = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [campus, setcampus] = useState([]);
  const [campus_id, setcampus_id] = useState("");
  const [cardsdata, setcardsdata] = useState([]);
  const [notissuecardsdata, setnotissuecardsdata] = useState([]);
  const [printmodal, setprintmodal] = useState(false);
  const [selectedcard, setselectedcard] = useState({});
  const [loading, setloading] = useState(false);
  const [currentActive, setcurrentActive] = useState("cardissuestudent");
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  const [checkunpaidclick, setcheckunpaidclick] = useState(false);
  const [checkpaidclick, setcheckpaidclick] = useState(true);
  const [respectiveCards, setrespectiveCards] = useState([]);

  const [viewModel, setviewModel] = useState(false);

  const [selectedStd_view, setselectedStd_view] = useState({});

  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );
  const dispatch = useDispatch();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      middlefunc(campusidget);
    }
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setcampus(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [notissuecardsdata, cardsdata]);

  const middlefunc = (id) => {
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
    setpreviousselectedcampus(id);
    onchangefunc(id);
    getnotissuestudents(id);
  };

  const onchangefunc = async (id) => {
    setloading(true);
    setcampus_id(id);
    // console.log(id)
    await fetch(`${baseUrl}/campus/issue-cards/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        console.log(data);
        setcardsdata(data.payload);
        if (checkpaidclick) {
          setrespectiveCards([...data.payload]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getnotissuestudents = async (id) => {
    setloading(true);
    console.log(id);
    await fetch(`${baseUrl}/campus/not-issue-cards/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setcardsdata(data.payload)
        console.log(data, "notissue cards ");
        setnotissuecardsdata(data.payload);
        if (checkunpaidclick) {
          setrespectiveCards([...data.payload]);
        }

        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openprinter = (item) => {
    console.log(item);
    setselectedcard(item);
    setprintmodal(true);
  };

  const issuenow = async (item) => {
    const data = {
      campus_id: item.campus_id,
      id: item.id,
      interview_date: item.interview_date,
      issue_at: item.issue_at,
      status: "1",
      student_id: item.student_id,
      test_date: item.test_date,
      test_time: item.test_time,
    };
    const id = item.id;
    await fetch(`${baseUrl}/registrationcard/${id}?_method=PUT`, {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.show("Card Issued successfully");
            // onchangefunc(campus_id)
            middlefunc(campus_id);
            setcurrentActive("cardissuestudent");
          } else {
            alert.show("Somthing went Wrong");
          }
          // console.log('issue now log', data)
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const checkpayableamount = (e) => {
    setCurrentPage(1);
    if (e.target.innerHTML == "Not Issue Students") {
      setcheckpaidclick(false);
      setcheckunpaidclick(true);

      setrespectiveCards([...notissuecardsdata]);
    }
    if (e.target.innerHTML == "Card Issue Students") {
      setcheckunpaidclick(false);
      setcheckpaidclick(true);

      setrespectiveCards([...cardsdata]);
    }
  };

  //@_pagination

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;
  let currentPosts = [];
  if (checkpaidclick) {
    currentPosts = cardsdata.slice(indexOfFirstPost, indexOfLastPost);
  }
  if (checkunpaidclick) {
    currentPosts = notissuecardsdata.slice(indexOfFirstPost, indexOfLastPost);
  }

  // const currentPosts = cardsdata.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  const printdoc = () => {
    var doc = document.getElementById("voucher");
    var WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(doc.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
  };
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* <h1 style={{ margin: 10 }}>Card Issues student</h1> */}
        <select
          onChange={(e) => middlefunc(e.target.value)}
          // onChange={(e) => { onchangefunc(e), getnotissuestudents(e) }}
          className="form-select"
          value={campus.length == 0 ? "" : previousselectedcampus}
          style={{ height: 40, marginBottom: "15px" }}
          aria-label="Default select example"
        >
          <option selected value="">
            Select Campus
          </option>
          {campus.map((campus) => (
            <option key={campus.id} value={campus.id}>
              {campus.name}
            </option>
          ))}
          {/* {
                    campus.map(item => (

                        <option value={item.id}>{item.name}</option>
                    ))


                } */}
        </select>

        <div>
          <Tabs
            onClick={(e) => {
              checkpayableamount(e);
            }}
            variant="pills"
            defaultActiveKey={currentActive}
            id="uncontrolled-tab-example"
            className="mb-3 tabbar"
          >
            <Tab
              defaultActiveKey
              eventKey={currentActive}
              title="Card Issue Students"
            >
              <div>
                <div className="row justify-content-center">
                  {loading && (
                    <Spinner animation="grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Campus id</th>
                      <th>Issue at</th>
                      <th>Test date</th>
                      <th>Test time</th>
                      <th>Interview Date</th>

                      <th>Print</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPosts.map((item) => {
                      return (
                        <tr key={item.id}>
                          {/* <td title={feechallan.student_name.length > 15 ? feechallan.student_name : null} style={{ textAlign: "start", cursor: "pointer" }}>{feechallan.student_name.length > 15 ? feechallan.student_name.substring(0, 12) + "..." : feechallan.student_name}</td> */}
                          {item["student"] ? (
                            <td
                              title={
                                item["student"]["name"].length > 15
                                  ? item["student"]["name"]
                                  : null
                              }
                              style={{ textAlign: "start", cursor: "pointer" }}
                            >
                              {item["student"]["name"].length > 15
                                ? item["student"]["name"].substring(0, 12) +
                                  "..."
                                : item["student"]["name"]}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td style={{ textAlign: "start" }}>
                            {item.campus_id}
                          </td>
                          <td style={{ textAlign: "start" }}>
                            {item.issue_at}
                          </td>
                          <td style={{ textAlign: "start" }}>
                            {item.test_date}
                          </td>
                          <td style={{ textAlign: "start" }}>
                            {item.test_time}
                          </td>
                          <td style={{ textAlign: "start" }}>
                            {item.interview_date}
                          </td>

                          <td style={{ textAlign: "start" }}>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => openprinter(item)}
                              className="svg-icon svg-icon-md svg-icon-primary"
                            >
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Devices/Printer.svg"
                                )}
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="notissuestudent" title="Not Issue Students">
              <div>
                <div className="row justify-content-center">
                  {loading && (
                    <Spinner animation="grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Campus id</th>
                      <th>Issue at</th>
                      <th>Test date</th>
                      <th>Test time</th>
                      <th>Interview Date</th>
                      <th>View</th>
                      <th>Issue Now</th>
                    </tr>

                    {currentPosts.map((item) => {
                      return (
                        <tr key={item.id}>
                          {item["student"] ? (
                            <td
                              title={
                                item["student"]["name"].length > 15
                                  ? item["student"]["name"]
                                  : null
                              }
                              style={{ textAlign: "start", cursor: "pointer" }}
                            >
                              {item["student"]["name"].length > 15
                                ? item["student"]["name"].substring(0, 12) +
                                  "..."
                                : item["student"]["name"]}
                            </td>
                          ) : (
                            <td></td>
                          )}

                          {/* {item["student"] ?
                                                            <td style={{ textAlign: "start", fontWeight: "400" }}>{item["student"]["name"]}</td>
                                                            : <td></td>
                                                        } */}
                          <td style={{ textAlign: "start", fontWeight: "400" }}>
                            {item.campus_id}
                          </td>
                          <td style={{ textAlign: "start", fontWeight: "400" }}>
                            {item.issue_at}
                          </td>
                          <td style={{ textAlign: "start", fontWeight: "400" }}>
                            {item.test_date}
                          </td>
                          <td style={{ textAlign: "start", fontWeight: "400" }}>
                            {item.test_time}
                          </td>
                          <td style={{ textAlign: "start", fontWeight: "400" }}>
                            {item.interview_date}
                          </td>

                          <td style={{ textAlign: "start" }}>
                            <a
                              className="btn btn-icon btn-light btn-hover-primary btn-sm "
                              onClick={() => {
                                setviewModel(true);
                                console.log(item);
                                console.log(item.student);
                                console.log(item.student.fee_challans);

                                setselectedStd_view({ ...item });
                              }}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Devices/Display2.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </td>

                          <td style={{ textAlign: "start", fontWeight: "400" }}>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => issuenow(item)}
                              className="svg-icon svg-icon-md svg-icon-primary"
                            >
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Code/Done-circle.svg"
                                )}
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </thead>
                </Table>
              </div>
            </Tab>
          </Tabs>

          <Pagination
            postsPerPage={postPerPage}
            paginate={paginate}
            totalPosts={respectiveCards.length}
          />
        </div>

        <Modal size="lg" show={printmodal}>
          <div id="voucher">
            <Modal.Body>
              <div className="App">
                <h4 style={{ color: "#121291" }}>{unitName}</h4>
                <div className="stu-header text-center">
                  <h4>Student Admit Card</h4>
                </div>
                <br />
                <br />
                <table
                  border="1px"
                  style={{ width: "500px", marginLeft: "100px" }}
                >
                  <tbody>
                    <tr>
                      <td align="left" style={{ width: "174.5px" }}>
                        Student Name
                      </td>
                      <td align="right" style={{ width: "250.5px" }}>
                        {selectedcard?.student?.name}
                      </td>
                      <td align="left" style={{ width: "174.5px" }}>
                        Registration Id
                      </td>
                      <td align="right" style={{ width: "250.5px" }}>
                        {selectedcard.student?.registration_id}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ width: "174.5px" }}>
                        Campus Name
                      </td>
                      <td align="right" style={{ width: "250.5px" }}>
                        {selectedcard?.campus?.name}
                      </td>
                      <td align="left" style={{ width: "174.5px" }}>
                        Class Name
                      </td>
                      <td align="right" style={{ width: "250.5px" }}>
                        {selectedcard?.student?.student_class_name}
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ width: "174.5px" }}>
                        Issue At
                      </td>
                      <td align="right" style={{ width: "250.5px" }}>
                        {selectedcard.issue_at}
                      </td>
                      <td align="left" style={{ width: "174.5px" }}>
                        Test Date
                      </td>
                      <td align="right" style={{ width: "250.5px" }}>
                        {selectedcard.test_date}
                      </td>
                    </tr>

                    <tr>
                      <td align="left" style={{ width: "174.5px" }}>
                        Test Time
                      </td>
                      <td align="right" style={{ width: "250.5px" }}>
                        {selectedcard.test_time}
                      </td>
                      <td align="left" style={{ width: "174.5px" }}>
                        Section
                      </td>
                      <td align="right" style={{ width: "250.5px" }}>
                        {selectedcard?.student?.global_section_name}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b style={{ width: "200px", display: "table", margin: "50px" }}>
                  <span style={{ display: "table-cell", width: "100px" }}>
                    Signature:
                  </span>
                  <span
                    style={{
                      display: "table-cell",
                      borderBottom: "1px solid black",
                    }}
                  ></span>
                </b>
              </div>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <button
              style={{ cursor: "pointer" }}
              onClick={printdoc}
              className="btn btn-success button_blue"
            >
              Print
            </button>
            <button
              className="btn btn-danger btn_width  pl-4 pr-4 ml-3"
              onClick={() => setprintmodal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={viewModel}>
          <Modal.Body>
            <div className="card">
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th>Fee Name</th>
                    <th>Challan Number</th>
                    <th>Payable</th>
                    <th>Paid</th>
                  </tr>
                </thead>

                <tbody>
                  {/* <th>{selectedStd_view.name}</th>
                                   
                                   
                                    <th>{selectedStd_view.name}</th>
                                    <th>{selectedStd_view.name}</th>
                                    <th>{selectedStd_view.name}</th> */}

                  {selectedStd_view.student == undefined
                    ? null
                    : selectedStd_view.student.fee_challans.map(
                        (item, index) => (
                          <tr>
                            <td style={{ textAlign: "start" }}>
                              {item.fee_challan_details.map((name_detail) => {
                                return (
                                  <div>
                                    <span>{name_detail.fee_name}</span>
                                    <br />
                                  </div>
                                );
                              })}
                            </td>

                            <td style={{ textAlign: "start" }}>
                              {item.challan_no}
                            </td>
                            <td style={{ textAlign: "start" }}>
                              {item.payable}
                            </td>
                            <td style={{ textAlign: "start" }}>{item.paid}</td>
                          </tr>
                        )
                      )}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={() => {
                  setviewModel(false);
                }}
                style={{ width: "150px", height: "40px" }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
};

export default CardIssueStudents;
