import React from 'react'
import DatePicker from 'react-date-picker'
import { Field,ErrorMessage } from 'formik'
import moment from 'moment'
const FormikDate = (props) => {
      
    const { name,label,colCount,...rest } = props

    return (
        <div className={`${colCount}`}>
                    <label htmlFor={name} className="font-size-h6 font-size-lg-h4 col-form-label">{label}</label>

                   
            <Field 
                   name={name}
                   
                   >
                   {({form,field})=> {
                       const { setFieldValue } = form
                       const { value } = field
                       return <DatePicker  
                       className="form-control form-control-lg form-control-solid" 
                       id={name}
                        {...field}
                         {...rest} 
                         value={value}
                         selected={new Date() || value }
                         onChange = {  (val) =>   setFieldValue(name,val)
                            }
                         />
                   }}
               </Field>
        </div>
    )
}

export default FormikDate
