import React, { useState, useRef, useEffect } from "react";
import { Divider } from "antd";
import { LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";

const ExperienceCertificate = () => {
  const [code, setCode] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  const [details, setDetails] = useState("");
  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShow = () => {
    console.log(code);
    if (code == "" && date == "") {
      alert.error("please enter the employee code and select the date");
      return;
    }
    if (date == "") {
      alert.error("please select the date");
      return;
    }
    if (code == "") {
      alert.error("please enter the employee code");
      return;
    }
    setLoading(true);
    const data = {
      employee_code: code,
      date: date,
    };
    fetch(`${baseUrl}/staff-certificate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setreportData(data.payload);
            setDetails(
              "Principal / Incharge Campus \n" + data?.payload?.campus?.name
            );
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {loading ? <LinearProgress /> : null}

        <div className="row mt-3">
          <div className="col-md-4">
            <label>
              <b>Select Date</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <input
              onChange={(e) => setDate(e.target.value)}
              type="date"
              value={date}
              placeholder="Enter employee code"
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label>
              <b>Enter Employee Code</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <input
              onChange={(e) => setCode(e.target.value)}
              type="text"
              placeholder="Enter Employee Code"
              className="form-control"
            />
          </div>
          <div className="col-md-4 mt-9">
            <button onClick={handleShow} className="btn btn-primary">
              Search
            </button>

            {reportData?.emp_code ? (
              <ReactToPrint
                documentTitle={`salary slip Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary w-105px mx-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        <Divider />

        {reportData?.emp_code ? (
          <div className="mainContainer">
            <div className="d-flex">
              <div>
                <img
                  // className='col-md-2'
                  style={{ height: 110, width: 100 }}
                  src={icon}
                />
              </div>
              <div className="col-md-8 ml-4">
                <h1>{unitName}</h1>
                <h4>Printing Date :- {date}</h4>
              </div>
            </div>
            <div>
              <h2 style={{ textAlign: "center" }}>To Whome It May Concern</h2>
              <br />
              <p className="text-justify font-size-h5">
                This is to certify that{" "}
                <b>
                  {reportData?.gender == "Male" ? "Mr." : "Mrs."}{" "}
                  {reportData?.full_name},
                </b>{" "}
                {reportData?.gender == "Male" ? "S/O" : "D/O"}{" "}
                <b>{reportData?.father_name}</b>, has been employed at this
                institution under <b>{reportData?.campus?.name}</b> as a{" "}
                <b>
                  {reportData?.designation?.name} (Payscale{" "}
                  {reportData?.pay_scale?.payscale})
                </b>{" "}
                from <b>{getMonthName(reportData?.joining_date)}</b> to{" "}
                <b>{getMonthName(date)}</b>.
                <br />
                <br />
                During her tenure, we have found{" "}
                {reportData?.gender == "Male" ? "him" : "her"} to be a
                hardworking, honest, cooperative, and intelligent employee. Her
                dedication and commitment to her work have been commendable.
              </p>
              <br />
              <br />
              <p className="font-size-h5 text-center">
                <b>
                  We wish {reportData?.gender == "Male" ? "him" : "her"} all the
                  best in {reportData?.gender == "Male" ? "him" : "her"} bright
                  future.
                </b>
              </p>
              <br />
              <div className="text-right font-size-h5 font-weight-bold">
                <p>Regards,</p>
                <textarea
                  id="bits"
                  rows="3"
                  cols="0"
                  className="font-size-h5 font-weight-bold text-right"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : null}

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          {reportData?.emp_code ? (
            <div className="mainContainer">
              <div className="d-flex">
                <div>
                  <img
                    // className='col-md-2'
                    style={{ height: 110, width: 100 }}
                    src={icon}
                  />
                </div>
                <div className="col-md-8 ml-4">
                  <h1>{unitName}</h1>
                  <h4>Printing Date :- {date}</h4>
                </div>
              </div>
              <div className="p-9">
                <h2 style={{ textAlign: "center" }}>To Whome It May Concern</h2>
                <br />
                <p className="text-justify font-size-h3 p-5">
                  This is to certify that{" "}
                  <b>
                    {reportData?.gender == "Male" ? "Mr." : "Mrs."}{" "}
                    {reportData?.full_name},
                  </b>{" "}
                  {reportData?.gender == "Male" ? "S/O" : "D/O"}{" "}
                  <b>{reportData?.father_name}</b>, has been employed at this
                  institution under <b>{reportData?.campus?.name}</b> as a{" "}
                  <b>
                    {reportData?.designation?.name} (Payscale{" "}
                    {reportData?.pay_scale?.payscale})
                  </b>{" "}
                  from <b>{getMonthName(reportData?.joining_date)}</b> to{" "}
                  <b>{getMonthName(date)}</b>.
                  <br />
                  <br />
                  During her tenure, we have found{" "}
                  {reportData?.gender == "Male" ? "him" : "her"} to be a
                  hardworking, honest, cooperative, and intelligent employee.
                  Her dedication and commitment to her work have been
                  commendable.
                </p>
                <br />
                <p className="font-size-h3 text-center">
                  <b>
                    We wish {reportData?.gender == "Male" ? "him" : "her"} all
                    the best in {reportData?.gender == "Male" ? "him" : "her"}{" "}
                    bright future.
                  </b>
                </p>

                <div
                  className="text-right font-size-h2 font-weight-bold"
                  style={{ whiteSpace: "pre-line", marginTop: "150px" }}
                >
                  <p>Regards,</p>
                  <p>{details}</p>
                  <p>Date: {date}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Paper>
    </>
  );
};

export default ExperienceCertificate;
