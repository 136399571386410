import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { baseUrl } from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const MonthlyChallanModal = ({ show, hide }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const authtoken = localStorage.getItem("userToken");

  useEffect(() => {
    // getAllCampus()
  }, []);
  const [loading, setloading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [selectedcampus, setselectedcampus] = useState("");
  const [selectedclass, setselectedclass] = useState("");
  const [selectedSection, setselectedSection] = useState("");
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [classes, setclasses] = useState([]);
  const [allsections, setallsections] = useState([]);

  const [fee_month, setfee_month] = useState("");

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
    await fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setclasses(data.payload);
          // getChallans()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onClassChange = async (id) => {
    setselectedclass(id);
    await fetch(`${baseUrl}/class_section/${selectedcampus}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsections(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getChallans = (id) => {
    console.log("chala?");
    console.log(id);
    setselectedSection(id);

    const data = {
      campus_id: selectedcampus,
      student_class_id: selectedclass,
      global_section_id: id,
    };

    console.log(data);

    fetch(`${baseUrl}/unpaid_challan_combine`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          // setallstudentsList(data.payload.students)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchChallans = () => {
    if (
      selectedcampus == "" ||
      selectedclass == "" ||
      selectedSection == "" ||
      fee_month == ""
    ) {
      alert("please select all fields");
    } else {
      setloading(true);

      const data = {
        campus_id: selectedcampus,
        student_class_id: selectedclass,
        global_section_id: selectedSection,
        fee_month: `${fee_month}-01`,
        fee_status: 0,
      };
      console.log(data);
      fetch(`${baseUrl}/fee_challan_month_wise`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setloading(false);

          if (data.metadata.success) {
            dispatch({
              type: "MONTHLY_CHALLAN",
              payload: {
                monthlypaidchallans: data.payload.fee_challans,
              },
            });
            history.push("/printmonthlychallan/1");
          }
          console.log("Monthly challan", data);
        })
        .catch((err) => {
          setloading(false);

          console.log(err);
        });
    }
  };

  return (
    <Modal size="lg" show={show}>
      <Modal.Header closeButton title="Monthly Challan">
        <Modal.Title>Monthly Challan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row col-md-12">
          <div className="col-md-3">
            <select
              onChange={(e) => onCampusChange(e.target.value)}
              className="form-select"
              value={selectedcampus}
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Campus
              </option>
              {campuses.map((campus) => (
                <option value={campus.id}>{campus.name}</option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedclass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Class
              </option>
              {classes.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedSection}
              onChange={(e) => getChallans(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Section
              </option>
              {allsections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <input
              onChange={(e) => setfee_month(e.target.value)}
              type={"month"}
              className="form-select"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <button disabled={true} className="btn btn-primary">
            Searching...
          </button>
        ) : (
          <button onClick={searchChallans} className="btn btn-primary">
            Search
          </button>
        )}

        <button className="btn btn-danger" onClick={hide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MonthlyChallanModal;
