import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { CustomersPage } from "./customers/CustomersPage";

import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import Students from "../../../pages/Student/Students";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../../services/PremissionChecker";

export default function Student() {
  const user = useSelector((state) => state.user.user);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect exact={true} from="/student" to="/student/addstudent" />
        }
        <ContentRoute
          role={[
            PremissionChecker(user, "side_bar.other_students")
              ? user.roles[0].name
              : "",
          ]}
          path="/student/addstudent"
          component={Students}
        />
      </Switch>
    </Suspense>
  );
}
