import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../../services/config";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../../../_metronic/_partials/controls";
import { Table, Modal, Alert } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import { PremissionChecker } from "../../../../../services/PremissionChecker";

const EditStaff = ({ show, reload, StaffDetails, campusid }) => {
  console.log(StaffDetails);
  const cnicNumberMask = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
  ];
  //redux

  const authtoken = localStorage.getItem("userToken");
  const alert = useAlert();
  //states
  const [designations, setdesignations] = useState([]);
  const [loading, setloading] = useState(false);
  const [applied_for, setapplied_for] = useState(StaffDetails.designation_id);
  const [full_name, setfull_name] = useState(StaffDetails.full_name);
  const [father_name, setfather_name] = useState(StaffDetails.father_name);
  const [first_name, setfirst_name] = useState(StaffDetails.first_name);
  const [last_name, setlast_name] = useState(StaffDetails.last_name);
  const [nationality, setnationality] = useState(StaffDetails.nationality);
  const [religion, setreligion] = useState(StaffDetails.religion);
  const [experience, setexperience] = useState(StaffDetails.experience);
  const [cnic_no, setcnic_no] = useState(StaffDetails.cnic_no);
  const [qualification, setqualification] = useState(
    StaffDetails.qualification
  );
  const [gender, setgender] = useState(StaffDetails.gender);
  const [marital_status, setmarital_status] = useState(
    StaffDetails.marital_status
  );
  const [dob, setdob] = useState(StaffDetails.dob);
  const [remarks, setremarks] = useState(StaffDetails.remarks);
  // const [mobile_no, setmobile_no] = useState(StaffDetails.mobile_no);
  const [phone, setphone] = useState(StaffDetails.phone);
  const [address, setaddress] = useState(StaffDetails.address);
  const [email, setemail] = useState(StaffDetails.email);
  const [isformsubmitted, setisformsubmitted] = useState(false);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please fill All fields");
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [countrycode, setcountrycode] = useState("");
  useEffect(() => {
    getAlldesignations();
    // getAllCampus();
  }, []);

  const getAlldesignations = () => {
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(banks)
        setdesignations(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const UpdateStaff = (values) => {
    setshowerr(false);
    setloading(true);
    const data = {
      campus_id: values.campus_id,
      designation_id: values.designation_id,
      full_name: values.full_name,
      father_name: values.father_name,
      nationality: values.nationality,
      religion: values.religion,
      experience: values.experience,
      cnic_no: values.cnic_no.toString(),
      qualification: values.qualification,
      gender: values.gender,
      marital_status: values.marital_status,
      dob: values.dob,
      remarks: values.remarks,
      mobile_no: values.mobile_no.toString(),
      phone: values.phone.toString(),
      address: values.address,
      email: values.email,
    };
    console.log(data);
    // const data = {
    //     campus_id: user.id,
    //     designation_id: applied_for,
    //     full_name,
    //     father_name,
    //     nationality,
    //     religion,
    //     experience,
    //     cnic_no,
    //     qualification,
    //     gender,
    //     marital_status,
    //     dob,
    //     remarks,
    //     mobile_no,
    //     phone,
    //     address,
    //     email,

    // };
    fetch(`${baseUrl}/employees/${StaffDetails.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata.success) {
            setloading(false);
            show(false);
            alert.success("Updated Successfully");
            reload();
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        show(false);
        alert.error("Something went wrong", err);
      });
  };

  // let mobcode=  ''
  // if(countrycode==''){
  //     mobcode= initialValues.mobile_no
  // }else{
  //     mobcode=countrycode+ initialValues.mobile_no
  // }

  //start from here
  const initialValues = {
    designation_id: StaffDetails.designation_id,
    campus_id: StaffDetails.campus_id,
    full_name: StaffDetails.full_name,
    father_name: StaffDetails.father_name,
    nationality: StaffDetails.nationality,
    religion: StaffDetails.religion,
    experience: StaffDetails.experience,
    cnic_no: StaffDetails.cnic_no,
    qualification: StaffDetails.qualification,
    gender: StaffDetails.gender,
    marital_status: StaffDetails.marital_status,
    dob: StaffDetails.dob,
    remarks: StaffDetails.remarks,
    mobile_no: StaffDetails.mobile_no,
    phone: StaffDetails.phone,
    address: StaffDetails.address,
    email: StaffDetails.email,
  };

  if (
    StaffDetails.phone?.charAt(0) == "+" &&
    StaffDetails.phone?.charAt(1) == "9" &&
    StaffDetails.phone?.charAt(2) == "2"
  ) {
    initialValues.phone = StaffDetails.phone.substring(3);
  }
  if (
    StaffDetails.mobile_no?.charAt(0) == "+" &&
    StaffDetails.mobile_no?.charAt(1) == "9" &&
    StaffDetails.mobile_no?.charAt(2) == "2"
  ) {
    initialValues.mobile_no = StaffDetails.mobile_no.substring(3);
  }

  // initialValues.mobile_no = countrycode + initialValues.mobile_no
  // if (countrycode != '') {
  //     initialValues.mobile_no = countrycode + initialValues.mobile_no
  // }
  const StaffRegistrationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),

    father_name: Yup.string().required("Father name is required"),

    dob: Yup.string().required("Date Of Birth is required "),

    religion: Yup.string().required("Religion is required "),
    gender: Yup.string().required("Gender Is Required"),

    mobile_no: Yup.string()
      .required("Mobile Number is required ")
      .matches(
        /^(3)([0-9]{9})$/,
        "Mobile Number  Must start with 3 and must have 13 digits"
      ),

    address: Yup.string().required("Address Is Required"),

    // remarks: Yup.string().required("Remarks is required "),

    nationality: Yup.string().required("Nationality is required "),

    designation_id: Yup.string().required("Designation is required "),
    campus_id: Yup.string().required("Campus is required "),

    // experience: Yup.string().notRequired("Experience is required "),
    qualification: Yup.string().required("Qualification is required"),
    cnic_no: Yup.string()
      .required("Cnic No required field")
      .matches(
        /^[0-9]{5}-[0-9]{7}-[0-9]$/,
        "CNIC No must follow the XXXXX-XXXXXXX-X format!"
      ),
    // email: Yup.string().notRequired("Email Is required"),

    marital_status: Yup.string().required("marital Status is required "),

    // phone: Yup.string()
    //   .notRequired("Phone Number is required ")
    //   .matches(
    //     /^(3)([0-9]{9})$/,
    //     "landline Number  Must start with 3 and must have 13 digits"
    //   ),
  });
  const createEroors = (errors, touched, isSubmitting, values) => {
    if (errors.designation_id && touched.designation_id) {
      setshowerror(errors.designation_id);
      setshowerr(true);
      return;
    }
    if (errors.campus_id && touched.campus_id) {
      setshowerror(errors.campus_id);
      setshowerr(true);
      return;
    }
    if (errors.full_name && touched.full_name) {
      setshowerror(errors.full_name);
      setshowerr(true);
      return;
    }
    if (values.full_name.length > 255) {
      setshowerror("Name Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }

    if (errors.father_name && touched.father_name) {
      setshowerror(errors.father_name);
      setshowerr(true);
      return;
    }
    if (values.father_name.length > 255) {
      setshowerror("Father Name Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }
    if (errors.nationality && touched.nationality) {
      setshowerror(errors.nationality);
      setshowerr(true);
      return;
    }
    if (values.nationality.length > 255) {
      setshowerror("Nationality Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }
    if (errors.religion && touched.religion) {
      setshowerror(errors.religion);
      setshowerr(true);
      return;
    }
    if (values.religion.length > 255) {
      setshowerror("Religion Name Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }

    // if (errors.experience && touched.experience) {
    //   setshowerror(errors.experience);
    //   setshowerr(true);
    //   return;
    // }
    // if (values.experience != undefined) {
    //   if (values.experience.length > 255) {
    //     setshowerror("Experience  Must Not Be Greather Then 255 Chsrecters");
    //     setshowerr(true);
    //     return;
    //   }
    // }

    if (errors.cnic_no && touched.cnic_no) {
      setshowerror(errors.cnic_no);
      setshowerr(true);
      return;
    }
    //  if (touched.cnic_no && values.cnic_no.toString().length < 13 || values.cnic_no.toString().length > 13) {
    //     setshowerror("Please Give Valid CNIC  Number of 13  digits ")
    //     setshowerr(true)
    //     return;
    // }
    if (errors.qualification && touched.qualification) {
      setshowerror(errors.qualification);
      setshowerr(true);
      return;
    }
    if (values.religion.length > 255) {
      setshowerror("Qualification  Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }

    if (errors.gender && touched.gender) {
      setshowerror(errors.gender);
      setshowerr(true);
      return;
    }
    if (errors.marital_status && touched.marital_status) {
      setshowerror(errors.marital_status);
      setshowerr(true);
      return;
    }
    if (errors.dob && touched.dob) {
      setshowerror(errors.dob);
      setshowerr(true);
      return;
    }
    // if (errors.remarks && touched.remarks) {
    //   setshowerror(errors.remarks);
    //   setshowerr(true);
    //   return;
    // }
    // if (values.remarks.length > 255) {
    //   setshowerror("Remarks  Must Not Be Greather Then 255 Chsrecters");
    //   setshowerr(true);
    //   return;
    // }

    if (errors.mobile_no && touched.mobile_no) {
      setshowerror(errors.mobile_no);
      setshowerr(true);

      return;
    }
    //  if (touched.mobile_no && values.mobile_no.toString().length < 11 || values.mobile_no.toString().length > 12) {
    //     setshowerror("Please Give Valid Mobile  Number of 11 or 12 digits ")
    //     setshowerr(true)
    //     return;
    // }

    // if (errors.phone && touched.phone) {
    //   setshowerror(errors.phone);
    //   setshowerr(true);
    //   // if (values.phone == '') {
    //   //     values.phone = '+92'
    //   //     console.log(   values.phone)
    //   //     // setcountrycode('92')
    //   // }
    //   return;
    // }
    // if (touched.phone && values.phone.toString().length < 11 || values.phone.toString().length > 12) {
    //     setshowerror("Please Give Valid Phone Number of 11 or 12 digits ")
    //     setshowerr(true)
    //     return;
    // }

    if (errors.address && touched.address) {
      setshowerror(errors.address);
      setshowerr(true);
      return;
    }
    if (values.address.length > 255) {
      setshowerror("Address  Must Not Be Greather Then 255 Chsrecters");
      setshowerr(true);
      return;
    }
    // if (errors.email && touched.email) {
    //   setshowerror(errors.email);
    //   setshowerr(true);
    //   return;
    // }
    // if (values.email != undefined && values.email != "") {
    //   var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    //   let res = values.email.match(pattern);
    //   if (!res) {
    //     setshowerror("Please Add Valid Email");
    //     setshowerr(true);
    //     return;
    //   }
    // }

    setshowerr(false);

    if (Object.keys(errors).length === 0) {
      if (values.full_name == "") {
      } else {
        if (isSubmitting) {
          let regu = /^(3)([0-9]{9})$/;

          if (!regu.test(values.phone)) {
            setshowerror(
              "Landline Number Must start with 3 and must have 13 digits"
            );
            setshowerr(true);
            return;
          }
          if (!regu.test(values.mobile_no)) {
            setshowerror(
              "Mobile Number Must start with 3 and must have 13 digits"
            );
            setshowerr(true);
            return;
          }
          if (!isformsubmitted) {
            setisformsubmitted(true);
            UpdateStaff(values);
          }
        }
      }
    }
  };
  // const loginAccount=JSON.parse(localStorage.getItem("userDetails")).role[0]

  return (
    <div>
      {loading ? (
        <b style={{ color: "green" }}>
          Updating Staff Please Wait {`  `}{" "}
          <span className="spinner-border"></span>
        </b>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={StaffRegistrationSchema}
          onSubmit={(values) => {}}
        >
          {(props) => {
            const {
              handleSubmit,
              errors,
              touched,
              values,
              isSubmitting,
              isValid,
              handleChange,
              handleBlur,
            } = props;

            // setErrorsLis(errors)
            createEroors(errors, touched, isSubmitting, values);

            return (
              <>
                <Form onSubmit={handleSubmit} className="form form-label-right">
                  <div class="row d-flex justify-content-center">
                    <div>
                      <div class="p-3">
                        {/* <div className="text-center">
                                                        <b>APPLICANT INFORMATION  </b>
                                                    </div> */}
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Applied For{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <select
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "edit_staff.applied_for"
                                )
                              }
                              class="form-select form-control"
                              name="designation_id"
                              value={values.designation_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              aria-label="Default select example"
                            >
                              <option selected value="">
                                Select Designation
                              </option>
                              {designations?.map((item) => (
                                <option value={item.id}>{item.name}</option>
                              ))}
                            </select>
                            {errors.designation_id && touched.designation_id && (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                {errors.designation_id}
                              </div>
                            )}
                          </div>

                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Select Campus{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <select
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "edit_staff.select_campus"
                                )
                              }
                              class="form-select form-control"
                              name="campus_id"
                              value={values.campus_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              aria-label="Default select example"
                            >
                              <option selected value="">
                                Select Campus
                              </option>
                              {campusData?.map((item) => (
                                <option value={item.id}>{item.name}</option>
                              ))}
                            </select>
                            {errors.campus_id && touched.campus_id && (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                {errors.campus_id}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Full Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              //  disabled={loginAccount =="Super Admin"?false :loginAccount =="Head Office"? false :true}
                              // disable={true}
                              disabled={
                                !PremissionChecker(user, "edit_staff.full_name")
                              }
                              name="full_name"
                              component={Input}
                              placeholder="Full Name"
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Father's Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "edit_staff.father_name"
                                )
                              }
                              name="father_name"
                              component={Input}
                              placeholder="Father Name"
                            />
                          </div>

                          <div class="form-group col-sm-6 flex-column d-flex ">
                            <label class="form-control-label">
                              <b>
                                Nationality{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "edit_staff.nationality"
                                )
                              }
                              name="nationality"
                              component={Input}
                              placeholder="Nationality"
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex ">
                            <label class="form-control-label">
                              <b>
                                Religion <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(user, "edit_staff.religion")
                              }
                              name="religion"
                              component={Input}
                              placeholder="Religion"
                            />
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex ">
                            <label class="form-control-label">
                              <b>Experience </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "edit_staff.experience"
                                )
                              }
                              name="experience"
                              component={Input}
                              placeholder="Experience"
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex ">
                            <label class="form-control-label">
                              <b>
                                C.N.I.C NO{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              name="cnic_no"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={cnicNumberMask}
                                  disabled={
                                    !PremissionChecker(
                                      user,
                                      "edit_staff.cnic_no"
                                    )
                                  }
                                  id="cnic_no"
                                  placeholder="Enter Your CNIC / B-Form No"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.cnic_no && touched.cnic_no
                                      ? "is-invalid form-control"
                                      : "form-control"
                                  }
                                />
                              )}
                            />
                            {errors.cnic_no && touched.cnic_no && (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                {errors.cnic_no}
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="row justify-content-between text-left">
                          <div class="form-group col-md-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Gender <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <select
                              disabled={
                                !PremissionChecker(user, "edit_staff.gender")
                              }
                              class="form-select form-control"
                              name="gender"
                              value={values.gender}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              aria-label="Default select example"
                            >
                              <option selected value="">
                                Select Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                            {errors.gender && touched.gender && (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                {errors.gender}
                              </div>
                            )}
                          </div>

                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Marital Status{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <select
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "edit_staff.marital_status"
                                )
                              }
                              class="form-select form-control"
                              name="marital_status"
                              value={values.marital_status}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              aria-label="Default select example"
                            >
                              <option selected value="">
                                Merital Status
                              </option>
                              <option value="Married">Married</option>
                              <option value="Unmarried">Unmarried</option>
                            </select>
                            {errors.marital_status && touched.marital_status && (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                {errors.marital_status}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex ">
                            <label class="form-control-label">
                              <b>
                                Qualification{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "edit_staff.qualification"
                                )
                              }
                              name="qualification"
                              component={Input}
                              placeholder="Qualification"
                            />
                          </div>

                          <div class="form-group col-md-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Date Of Brith{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(user, "edit_staff.dob")
                              }
                              name="dob"
                              component={Input}
                              type="date"
                              max="9999-12-30"
                              placeholder="Date Of Birth"
                            />
                          </div>
                        </div>

                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-12 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Remarks <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(user, "edit_staff.remarks")
                              }
                              name="remarks"
                              component={Input}
                              placeholder="Remarks"
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <b>CONTACT INFORMATION</b>
                        </div>
                        <div class="row justify-content-between text-left mt-3">
                          <div class="form-group col-sm-6 flex-column d-flex ">
                            <label class="form-control-label">
                              <b>
                                Mobile No{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(user, "edit_staff.mobile_no")
                              }
                              name="mobile_no"
                              pattern="^(\+92)(3)([0-9]{9})$"
                              component={Input}
                              type="text"
                              placeholder="Mobile No"
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex ">
                            <label class="form-control-label">
                              <b>Landline Number</b>
                            </label>

                            <Field
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "edit_staff.landline_number"
                                )
                              }
                              maxLength={20}
                              pattern="^0[1-9]|[1-9]\d$"
                              required
                              name="phone"
                              component={Input}
                              placeholder="Enter Your Phone/LandLine No"
                            />
                          </div>

                          <div class="form-group col-sm-12 flex-column d-flex">
                            <label class="form-control-label">
                              <b>
                                Residential Address{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(user, "edit_staff.address")
                              }
                              name="address"
                              component={Input}
                              placeholder="Address"
                            />
                          </div>

                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label">
                              <b>E-Mail Address </b>
                            </label>
                            <Field
                              disabled={
                                !PremissionChecker(user, "edit_staff.email")
                              }
                              name="email"
                              type="email"
                              component={Input}
                              placeholder="Email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                {showerr ? (
                  <div id="err">
                    <Alert
                      variant="danger"
                      onClose={() => setshowerr(false)}
                      dismissible
                    >
                      <Alert.Heading>Oops You Got an Error!</Alert.Heading>
                      <p>{showerror}</p>
                    </Alert>
                  </div>
                ) : null}
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  class="btns"
                >
                  <button
                    type="submit"
                    value="Submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-success button_blue"
                  >
                    Update
                  </button>
                  <button
                    style={{ marginLeft: "15px" }}
                    onClick={() => {
                      show(false);
                      setshowerr(false);
                    }}
                    type="button"
                    class="btn btn-danger btn_width"
                  >
                    Cancel
                  </button>
                </div>
                {/* </form> */}
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EditStaff;
