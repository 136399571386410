import React, { useEffect, useState } from "react";
import { LinearProgress, Paper, Switch } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Modal as AntModal } from "antd";
import { PremissionChecker } from "../../../services/PremissionChecker";

const MessageAllocation = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [totalHeadOfficeMessages, setTotalHeadOfficeMessages] = useState(0);
  const [
    remainingHeadOfficeMessages,
    setRemainingHeadOfficeMessages,
  ] = useState(0);
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const [open, setOpen] = useState(false);
  const [addUnitModal, setAddUnitModal] = useState(false);
  const [headOfficeModal, setHeadOfficeModal] = useState(false);
  const [allowMsgModal, setAllowMsgModal] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [apiURL, setApiURL] = useState("");
  const [SMSamount, setSMSamount] = useState("");

  const [allowed, setAllowed] = useState(false);
  const [unitId, setUnitId] = useState("");

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];

  useEffect(() => {
    getHeadOfficeMessages();
    getAllowedMessages();
  }, []);

  const getHeadOfficeMessages = () => {
    setLoading(true);
    fetch(`${baseUrl}/get-head-office-messages`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setTotalHeadOfficeMessages(data.payload?.assign_sms);
        setRemainingHeadOfficeMessages(data.payload?.remaining_sms);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getAllowedMessages = () => {
    setLoading(true);
    fetch(`${baseUrl}/all-units-messages`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setData(data.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const addUnitHandler = async () => {
    if (unitName == "") {
      return alert.error("Unit Name is Required");
    }
    if (apiURL == "") {
      return alert.error("Api URL is Required");
    }
    if (SMSamount == "") {
      return alert.error("Allocated Message is Required");
    }
    setSubmitLoading(true);
    const data = {
      unit_name: unitName,
      application_url: apiURL,
      assign_sms: SMSamount,
    };

    await fetch(`${baseUrl}/create-unit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // setSubmitLoading(false);
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.show("Messages Allocated Successfully", {
              timeout: 5000,
              type: "info",
            });
            getAllowedMessages();
            setAddUnitModal(false);
          } else {
            alert.show(data.metadata.message, {
              timeout: 5000,
              type: "danger",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const headOfficeMessagesHandler = () => {
    if (totalHeadOfficeMessages == "") {
      return alert.error("Allowed Messages Field Is Required");
    }
    if (totalHeadOfficeMessages.split("").length > 8) {
      return alert.error("Invalid Input");
    }
    setSubmitLoading(true);
    const fdata = {
      assign_sms: totalHeadOfficeMessages,
    };
    console.log(data);
    setSubmitLoading(false);
    fetch(`${baseUrl}/update-head-office-messages`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.show(data.metadata.message, {
              timeout: 5000,
              type: "info",
            });
            getAllowedMessages();
            setHeadOfficeModal(false);
          } else {
            alert.show(data.metadata.message, {
              timeout: 5000,
              type: "danger",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const onAllocateMessages = () => {
    if (totalMessages == "") {
      return alert.error("Allowed Messages Field Is Required");
    }
    if (totalMessages.split("").length > 8) {
      return alert.error("Invalid Input");
    }
    setSubmitLoading(true);
    const fdata = {
      id: selectedItem.id,
      assign_sms: totalMessages,
    };
    fetch(`${baseUrl}/update-unit-messages`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          setSubmitLoading(false);
          if (data.metadata.success) {
            alert.show("Messages Allocated Successfully", {
              timeout: 5000,
              type: "info",
            });
            getAllowedMessages();
            setOpen(false);
          } else {
            alert.show(data.metadata.message, {
              timeout: 5000,
              type: "danger",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const handleMessageAllowed = () => {
    fetch(`${baseUrl}/unit-messages-permission/${unitId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        getAllowedMessages();
        setAllowMsgModal(false);
        alert.success("Updated Successfully");
      })
      .catch((err) => {
        console.log("err is re", err);
      });
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Paper style={{ margin: "10px 0", padding: 0 }}>
        <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
          <button
            disabled={
              !PremissionChecker(user, "message_allocation.can_add_unit")
            }
            className="btn btn-success button_blue button_blue"
            style={{ margin: 5 }}
            onClick={() => setAddUnitModal(true)}
          >
            Add Unit
          </button>
          <button
            disabled={
              !PremissionChecker(
                user,
                "message_allocation.can_allocate_headoffice_messages"
              )
            }
            className="btn btn-primary"
            style={{ margin: 5 }}
            onClick={() => setHeadOfficeModal(true)}
          >
            Allocate Head Office Messages
          </button>
        </div>

        <div style={{ padding: "15px" }}>
          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">SR.No</th>
                  <th>Unit Name</th>
                  <th>App URL</th>
                  <th className="text-center">Allocated Messages</th>
                  <th className="text-center">Allocated Date</th>
                  <th className="text-center">Edit/Update</th>
                  <th className="text-center">Allow SMS</th>
                </tr>
              </thead>

              <tbody>
                {!!data &&
                  data.map((item, index) => (
                    <tr id="tbl">
                      <td style={{ color: "black" }} className="text-center">
                        {index + 1}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.unit_name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.application_url}
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {item.assign_sms}
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {item.updated_at.split("T")[0]}
                      </td>
                      {/* <td style={{ color: "black" }} className="text-center">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setSelectedItem(item);
                            setTotalMessages(item.allowed_messages);
                            setOpen(true);
                          }}
                        >
                          Allocate
                        </button>
                      </td> */}
                      <td
                        style={{ paddingLeft: "0px" }}
                        className="text-center"
                      >
                        <a
                          key={index}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm "
                          onClick={() => {
                            setSelectedItem(item);
                            setTotalMessages(item.assign_sms);
                            setOpen(true);
                          }}
                        >
                          <span
                            key={index}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              key={index}
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td>

                      <td style={{ color: "black" }} className="text-center">
                        <Switch
                          size="small"
                          onChange={(e) => {
                            setAllowed(item.allowed == "0" ? false : true);
                            setAllowed(e);
                            setUnitId(item.id);
                            setAllowMsgModal(true);
                          }}
                          checked={item.allowed == "0" ? false : true}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Unit Modal */}
        <Modal show={addUnitModal} size="lg">
          <Modal.Header>
            <Modal.Title>Add New Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12">
              <label className="form-control-label">
                <b>
                  Enter Unit Name
                  <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control"
                value={unitName}
                onChange={(e) => setUnitName(e.target.value)}
                placeholder="Enter Unit Name"
              />
            </div>
            <div className="d-flex justify-content-between mt-5">
              <div className="col-md-8">
                <label className="form-control-label">
                  <b>
                    Enter API URL
                    <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control"
                  value={apiURL}
                  onChange={(e) => setApiURL(e.target.value)}
                  placeholder="Enter API URL"
                />
              </div>
              <div className="col-md-4">
                <label className="form-control-label">
                  <b>
                    Enter Allocated Message
                    <span style={{ color: "red" }}>*</span>{" "}
                  </b>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={SMSamount}
                  onChange={(e) => setSMSamount(e.target.value)}
                  placeholder="Enter Message Amount"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button
                onClick={addUnitHandler}
                className="btn btn-primary"
                style={{ width: "80px" }}
              >
                Add
              </button>
            )}
            <button
              onClick={() => setAddUnitModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Allocated Message Modal */}
        <Modal centered show={open} size="md">
          <Modal.Header>
            <Modal.Title>
              Allocate Messages to {selectedItem?.campus?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="form-control-label">
              <b>
                Enter Allocated Message <span style={{ color: "red" }}>*</span>{" "}
              </b>
            </label>
            <input
              className="form-control"
              value={totalMessages}
              onChange={(e) => setTotalMessages(e.target.value)}
              placeholder="Enter Message Amount"
            />
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button onClick={onAllocateMessages} className="btn btn-primary">
                Update
              </button>
            )}

            <button onClick={() => setOpen(false)} className="btn btn-danger">
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Assign Head Office Messages Modal */}
        <Modal show={headOfficeModal} size="md">
          <Modal.Header>
            <Modal.Title>Allocate Messages To Head Office</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-control-label">
                <b>
                  Enter Allocated Message{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </b>
              </label>
              <input
                className="form-control"
                value={totalHeadOfficeMessages}
                onChange={(e) => setTotalHeadOfficeMessages(e.target.value)}
                placeholder="Enter Message Amount"
              />
            </div>

            <div>
              <label className="form-control-label">
                <b>Remaining Allocated Messages</b>
              </label>
              <input
                className="form-control"
                value={remainingHeadOfficeMessages}
                readOnly
                disabled
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitLoading ? (
              <div
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  class="text-center"
                  variant="info"
                  animation="border"
                />
              </div>
            ) : (
              <button
                onClick={headOfficeMessagesHandler}
                className="btn btn-primary"
              >
                Allocate
              </button>
            )}

            <button
              onClick={() => setHeadOfficeModal(false)}
              className="btn btn-danger"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Change Allow SMS */}
        <Modal show={allowMsgModal} size="md">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Change Allow SMS Status
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to change allow SMS status for this unit
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => handleMessageAllowed()}
              className="btn btn-success button_blue"
            >
              Yes
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setAllowMsgModal(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
        {/* <AntModal
          title="Allow / Not Allow Unit Messages"
          centered
          open={allowMsgModal}
          onOk={() => handleMessageAllowed()}
          okText="Yes"
          cancelText="No"
          onCancel={() => setAllowMsgModal(false)}
        >
          Are You Sure You Want To Change Allow SMS Status For This Unit
        </AntModal> */}
      </Paper>
    </>
  );
};

export default MessageAllocation;
